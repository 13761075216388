import {Record} from 'immutable';
import * as uuid from "uuid";
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey, isJSON} from "../../core/validation/predicates";
import {UserId} from "../../User/model/UserInfo";

export const AGGREGATE_TYPE = 'Playshot';

export type PlayshotId = string;
export type PlayshotError = string;

export interface PlayshotProps {
  uid: PlayshotId;
  aggregateType: AggregateType;
  playshotId: string;
  boardId: string;
  name: string;
  savedAt: string;
  savedBy: UserId;
  playConfig: object;
  playData: object;
}

export const defaultPlayshotProps: PlayshotProps = {
  uid: '',
  aggregateType: AGGREGATE_TYPE,
  playshotId: '',
  boardId: '',
  name: '',
  savedAt: '',
  savedBy: '',
  playConfig: {},
  playData: {},
};

export const createPlayshotFromServerData = (data: any): Either<PlayshotError, Playshot> => {
  const validatedData: Either<PlayshotError, any> = validate(data,
    [hasKey('playshotId'), "playshotId missing"],
    [hasKey('name'), "boardId missing"],
    [hasKey('savedAt'), "boardId missing"],
    [hasKey('savedBy'), "boardId missing"],
    [hasKey('playConfig'), "playConfig missing"],
    [isJSON('playConfig'), "playConfig is not a valid JSON string"],
    [isJSON('playData'), "playData is not a valdi JSON string"],
  );

  if (isFailure(validatedData)) {
    return validatedData;
  }

  return Success(new Playshot({
    ...defaultPlayshotProps,
    ...data,
    uid: data.playshotId,
    playConfig: JSON.parse(data.playConfig),
    playData: JSON.parse(data.playData),
  }));
};

export class Playshot extends Record(defaultPlayshotProps) implements PlayshotProps, Aggregate {
  public constructor(data: Partial<PlayshotProps>) {
    if (data.uid === '') {
      data.uid = uuid.v4()
      data.playshotId = data.uid;
    }

    if(data.savedAt === '') {
      data.savedAt = (new Date()).toISOString();
    }

    super(data);
  }

  // @TODO add use case methods to change state
}
