import {List} from "immutable";
import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Dropdown} from "semantic-ui-react";
import {Node, NodeType} from "../../../model/Graph";
import {getVoMetadata} from "../../../service/cody-wizard/vo/get-vo-metadata";
import CellIcon from "../../CellIcon";

interface OwnProps {
  placeholder?: string,
  nodeOptions: List<Node>,
  selected: Node | null,
  onChange: (selected: Node | null) => void,
  disabled?: boolean,
  uniqueName?: boolean,
  withNs?: boolean,
}

type NodeSelectProps = OwnProps & WithNamespaces;

const NodeSelect = (props: NodeSelectProps) => {
  const selectedId = props.selected? props.selected.getId() : '';

  const handleSelectNode = (nodeId: string) => {
    const selectedNode = props.nodeOptions.find(node => node.getId() === nodeId);
    props.onChange(selectedNode || null);
  }

  const existingNames: string[] = [];
  const selectedNodeName = props.selected? getNodeNameForNodeSelect(props.selected, !!props.withNs) : '';
  // Filter + push of selected node (and similar nodes if uniqueName is true) ensures that selectedNode is always present in options list
  const options = props.nodeOptions.filter(n => {
    if(n.getId() === selectedId) {
      return false;
    }

    return !(props.uniqueName && getNodeNameForNodeSelect(n, !!props.withNs) === selectedNodeName);
  }).push(...(props.selected? [props.selected] : [])).map(node => ({
    key: node.getId(),
    text: getNodeNameForNodeSelect(node, !!props.withNs),
    value: node.getId(),
    icon: <CellIcon cellType={node.getType()} />
  })).filter(o => {
    if(!props.uniqueName) {
      return true;
    }

    if(existingNames.includes(o.text)) {
      return false;
    }

    existingNames.push(o.text);
    return true;
  }).toArray();

  console.log("[CodyWizard] NodeSelect", props.selected, options);


  return <Dropdown
       placeholder={props.placeholder || props.t('insp.cody_wizard.node_select.default_placeholder') as string}
       disabled={props.disabled}
       selection={true}
       clearable={true}
       options={options}
       onChange={(evt, data) => handleSelectNode(data.value as string)}
       value={props.selected ? props.selected.getId() : ''}
       search={true}
  />
};

export default withNamespaces()(NodeSelect);

export const getNodeNameForNodeSelect = (node: Node, withNs: boolean): string => {
  if(withNs && node.getType() === NodeType.document) {
    const voMeta = getVoMetadata(node);

    if(voMeta.ns) {
      return node.getName() + ` (${voMeta.ns})`;
    }
  }

  return node.getName();
}
