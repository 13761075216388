import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Checkbox, Dropdown, DropdownItemProps, FormField} from "semantic-ui-react";
import {Node} from "../../../../model/Graph";
import {WizardContext} from "../../../../service/cody-wizard/context/wizard-context";

interface OwnProps {
  command: Node | null;
  newAggregate: boolean | undefined;
  deleteState: boolean | undefined;
  ctx: WizardContext;
  onNewAggregateChanged: (newAggregate: boolean) => void;
  disabled?: boolean;
}

type NewAggregateSelectProps = OwnProps & WithNamespaces;

const NewAggregateToggle = (props: NewAggregateSelectProps) => {
  const disabled = !props.command || props.deleteState;

  return <FormField disabled={disabled} inline={true} className={disabled? 'fadeout' : 'fadein'}>
    <Checkbox value={1}
              checked={props.newAggregate}
              disabled={props.disabled || disabled}
              toggle={true}
              onChange={() => props.onNewAggregateChanged(!props.newAggregate)}
              label={props.t('insp.cody_wizard.step_command.new_aggregate_select_label') as string} />
  </FormField>
};

export default withNamespaces()(NewAggregateToggle);
