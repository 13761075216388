import * as React from 'react';
import {useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {Button, Divider, Icon, Message, MessageContent, Modal, TextArea, TextAreaProps} from "semantic-ui-react";
import {isStandalone} from "../../standalone/util";

interface OwnProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (feedbackText: string) => void;
    reset?: boolean;
}

type FeedbackModalProps = OwnProps & WithNamespaces;

const FeedbackModal = (props: FeedbackModalProps) => {
    const [feedback, setFeedback] = useState('');

    const handleChange = (event: React.FormEvent<HTMLTextAreaElement>, {value}: TextAreaProps) => {
        if(value) {
            setFeedback(value + '');
        }
    };
    const handleSendBtnClick = () => {
        props.onSubmit(feedback);
    };

    if(props.reset && feedback !== '') {
        setFeedback('');
    }

    return <Modal open={props.open} closeIcon={true} onClose={props.onClose}>
        <Modal.Header>{ props.t('insp.feedback.header') }</Modal.Header>
        <Modal.Content>
            <Message>
                <MessageContent>
                    { props.t('insp.feedback.info_text') }
                    <p>&nbsp;</p>
                    <p><a href="https://gitter.im/proophboard/community" target="_blank"><Icon name="arrow right" />&nbsp;Chat</a></p>
                    <p><a href="https://github.com/proopboard/issue-tracker/issues" target="_blank"><Icon name="arrow right" />&nbsp;Issue Tracker</a></p>
                </MessageContent>
            </Message>
            {!isStandalone() && <>
                <Divider horizontal={true}>OR</Divider>
                <p>{props.t('insp.feedback.direct_msg')}</p>
                <form className="ui form">
                    <TextArea placeholder={props.t('insp.feedback.placeholder')}
                              autoHeight={true}
                              autoFocus={true}
                              value={feedback}
                              onChange={handleChange}
                              style={{minHeight: '100px'}}/>
                </form>
            </>}
        </Modal.Content>
        {!isStandalone() && <Modal.Actions>
            <Button primary={true} onClick={handleSendBtnClick}>{props.t('insp.feedback.send')}</Button>
        </Modal.Actions>}
    </Modal>
};

export default withNamespaces()(FeedbackModal);
