import {List} from "immutable";
import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button} from "semantic-ui-react";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {useGraph} from "../../../hooks/useGraph";
import {
  Graph,
  makeNodeFromJs,
  Node,
  NodeType
} from "../../../model/Graph";
import {AggregateMetadata, getAggregateMetadata} from "../../../service/cody-wizard/aggregate/get-aggregate-metadata";
import {getCommandMetadata} from "../../../service/cody-wizard/command/get-command-metadata";
import {ImmutableDDDWizardContext} from "../../../service/cody-wizard/context/ddd-action";
import {suggestDependenciesForNode} from "../../../service/cody-wizard/dependencies/suggest-dependencies-for-node";
import {getDefaultServiceName} from "../../../service/cody-wizard/node/detect-service";
import {names} from "../../../service/cody-wizard/node/names";
import {Rule} from "../../../service/cody-wizard/rule-engine/configuration";
import {RulesDependencyMetadata} from "../../../service/cody-wizard/rule-engine/types";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import {ensureSingularNoun, isAdjective, isNoun, tagSentence} from "../../../service/cody-wizard/tagger";
import BusinessRulesEditor from "../Editor/BusinessRulesEditor";
import DependenciesEditor from "../Editor/DependenciesEditor";
import EditorDivider from "../Editor/EditorDivider";
import CodySuggestButton from "../Editor/Suggestion/CodySuggestButton";
import {WithWizardStepProps} from "../WizardModal";

interface OwnProps {

}

type AggregateProps = OwnProps & WithWizardStepProps<ImmutableDDDWizardContext> & WithNamespaces;

const Aggregate = (props: AggregateProps) => {
  const aggregate = props.ctx.aggregate;

  const [graph] = useGraph();
  const elementsTree = useElementsTree(props.ctx.board.uid);
  const [selectedAggregate, setSelectedAggregate] = useState<Node|null>(null);
  const [metadata, setMetadata] = useState<AggregateMetadata>({});
  const [autoFocusBusinessRules, setAutoFocusBusinessRules] = useState(false);
  const [dependencies, setDependencies] = useState<Record<string, RulesDependencyMetadata>>({});
  const [showDependencies, setShowDependencies] = useState(false);

  const updateMetadata = (meta: AggregateMetadata) => {
    if(props.onUpdateMetadata) {
      props.onUpdateMetadata(JSON.stringify(meta, null, 2), true);
    }

    setMetadata(meta);
  }

  const updateDependencies = (deps: Record<string, RulesDependencyMetadata>) => {
    if(props.mode === "sidebar" && selectedAggregate) {
      if(props.ctx.command) {
        const cmdMeta = getCommandMetadata(props.ctx.command);
        const currentCmdDeps = JSON.stringify(cmdMeta.dependencies || {});

        if(currentCmdDeps !== JSON.stringify(deps)) {
          cmdMeta.dependencies = deps;
          graph.changeNodeMetadata(props.ctx.command, JSON.stringify(cmdMeta, null, 2));
        }
      }
    }

    props.onCtxChanged({...props.ctx, commandDependencies: deps});

    setDependencies(deps);
  }

  useEffect(() => {
    resetForm();

    if(aggregate) {
      setSelectedAggregate(aggregate);
      const meta = getAggregateMetadata(aggregate);
      setMetadata(meta);
    }

    if(Object.keys(props.ctx.commandDependencies).length) {
      setShowDependencies(true);
    }
  }, [aggregate]);

  useEffect(() => {
    setDependencies(props.ctx.commandDependencies || {});
  }, [props.ctx.commandDependencies]);

  const resetForm = () => {
    setSelectedAggregate(null);
    setMetadata({});
    setAutoFocusBusinessRules(false);
    setDependencies({});
    setShowDependencies(false);
  }

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  const handleRulesChanged = (newRuleSet: Rule[] | undefined) => {
    if(!newRuleSet) {
      newRuleSet = [];
    }

    props.onCtxChanged({...props.ctx, commandHandler: newRuleSet});
    updateMetadata({...metadata, rules: newRuleSet});
  }

  const handleDependenciesConfigChanged = (changedDependencies: Record<string, RulesDependencyMetadata> | undefined) => {
    updateDependencies(changedDependencies || {});
  }

  const handleSuggestDependencies = () => {
    if(!selectedAggregate) {
      return;
    }

    const newDeps = suggestDependenciesForNode(selectedAggregate, getDefaultServiceName(props.ctx), dependencies);

    if(JSON.stringify(newDeps) !== JSON.stringify(dependencies)) {
      handleDependenciesConfigChanged(newDeps);
    }
  }

  const cmdSchema = props.ctx.command ? getCommandMetadata(props.ctx.command).schema || new Schema({}) : new Schema({});

  return <form className='ui form' onSubmit={handleSubmit}>
    <div className={'field'}>
      {<Button basic={true} icon={showDependencies? 'minus' : 'add'} labelPosition="left" size="mini"
               content={props.t('insp.cody_wizard.step_aggregate.manage_dependencies') + ` (${Object.keys(dependencies).length})`} color="blue" className="text"
               onClick={() => setShowDependencies(!showDependencies)}
               disabled={!selectedAggregate || !selectedAggregate.isEnabled()}/>}
    </div>
    <div className={(showDependencies? 'slidedown' : 'slideup') + ' smooth'} >
      <EditorDivider content={props.t('insp.cody_wizard.step_aggregate.dependencies_label') as string} />
      <DependenciesEditor boardId={props.ctx.board.uid}
                          dependencies={dependencies}
                          messageSchema={cmdSchema}
                          autofocus={false}
                          onDependenciesChanged={handleDependenciesConfigChanged}
                          readonly={!selectedAggregate || !selectedAggregate.isEnabled()}
                          sessionId={selectedAggregate ? selectedAggregate.getId() : undefined }
        />
      <CodySuggestButton hasSuggestions={!!(selectedAggregate && selectedAggregate.getSources().filter(s => [NodeType.document, NodeType.externalSystem, NodeType.event].includes(s.getType())).count() > 0)}
                         onSuggest={handleSuggestDependencies}
      />
    </div>
    <EditorDivider content={props.t('insp.cody_wizard.step_aggregate.rules_label') as string} />
    <BusinessRulesEditor rules={metadata.rules || suggestEventRecordingRules(props.ctx)}
                         elementId={selectedAggregate ? selectedAggregate.getId() : '' }
                         sessionId={selectedAggregate ? selectedAggregate.getId() : undefined }
                         dependencies={dependencies}
                         onRulesChanged={handleRulesChanged}
                         autofocus={autoFocusBusinessRules}
                         possibleEvents={props.ctx.events}
                         readonly={!selectedAggregate || !selectedAggregate.isEnabled()}
    />
    <div style={{marginTop: '100px'}} />
  </form>

};

export default withNamespaces()(Aggregate);

export const suggestEventRecordingRules = (ctx: ImmutableDDDWizardContext): Rule[] => {
  const rules: Rule[] = [];

  ctx.events.forEach(evt => {
    rules.push({
      rule: "always",
      then: {
        record: {
          event: evt.getName(),
          mapping: "command"
        }
      }
    })
  })

  return rules;
}

export const suggestAggregateName = (ctx: ImmutableDDDWizardContext, graph: Graph): string | undefined => {
  let name = '';

  if(ctx.state) {
    return ctx.state.getName();
  } else if(ctx.command) {
    name = ctx.command.getName();
  } else if(ctx.events.count()) {
    name = ctx.events.first(makeNodeFromJs({})).getName();
  }

  if(name === '') {
    return;
  }

  const nodeNames = names(name);

  const words = tagSentence(nodeNames.title);

  console.log("[CodyWizard] tagged words: ", words);

  return words.filter(word => isNoun(word) || isAdjective(word)).map(word =>  isNoun(word) ? ensureSingularNoun(word) : word.token).join(" ");
}
