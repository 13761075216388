import * as React from 'react';
import {useSelector} from "react-redux";
import {Label} from "semantic-ui-react";
import {makeGetCurrentUser} from "../../../User/selectors/currentUser";

interface OwnProps {
    msg: string;
}

type UserMessageProps = OwnProps;

const UserMessage = (props: UserMessageProps) => {
    const user = useSelector(makeGetCurrentUser());

    return <div className="console-message">
        <Label circular={true} className="user console-avatar" style={{backgroundImage: `url(${user.avatarUrl})`, backgroundSize: 'cover', marginLeft: '1px'}} />
        <div className="console-text">
            {props.msg === '' && <span>&nbsp;</span>}
            <span>{props.msg}</span>
        </div>
    </div>
};

export default UserMessage;
