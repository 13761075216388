import {List, Map} from "immutable";
import moment from "moment";
import { Reducer } from 'redux';
import {
    APPEND_HISTORY_ENTRIES,
    CLEAR_BOARD_HISTORY,
    PREPEND_HISTORY_ENTRIES,
    SET_IS_LOADING_HISTORY, SET_NO_MORE_HISTORY_AVAILABLE
} from "../actions/constants";
import {BoardHistory, HistoryAction, HistoryCellType, HistoryEntry} from "../model/HistoryEntry";
import {HistoryState} from "../model/HistoryState";
import {InspectioBoardsAction} from "./index";

export interface BoardHistoryState extends Map<string, HistoryState>{

}

const filterDuplicates = (history: BoardHistory): BoardHistory => {
    const filteredEntries: HistoryEntry[] = [];
    const historyArr = history.toArray();

    let skipNext: boolean = false;
    let setPrevLabel: boolean = false;
    let prevLabel: string = '';
    let prevRecordedAt: string|null = null;
    let prevAction: HistoryAction|null = null;

    historyArr.forEach((entry, i) => {

        if(!skipNext && setPrevLabel) {
            entry = entry.set('label', prevLabel);
            setPrevLabel = false;
            prevLabel = '';
        }

        if(i+1 < historyArr.length) {
            const next = historyArr[i+1];

            if(entry.userId !== next.userId) {
                filteredEntries.push(entry);
                skipNext = false;
                setPrevLabel = false;
                prevLabel = '';
                prevRecordedAt = null;
                prevAction = null;
                return true;
            }

            if(prevRecordedAt && prevAction === entry.action) {
                entry = entry.set('date', prevRecordedAt);
            }

            prevRecordedAt = null;
            prevAction = null;

            if(entry.cellId === next.cellId) {
                if(entry.action === next.action) {
                    if(entry.cellType !== HistoryCellType.multiple && entry.cellType !== HistoryCellType.misc) {
                        prevLabel = entry.label;
                        prevRecordedAt = entry.date;
                        prevAction = entry.action;
                        // Skipping current, so that two edited entries + one added result in one added with label
                        skipNext = false;
                        setPrevLabel = true;
                        return true;
                    }

                    if(!skipNext) {
                        filteredEntries.push(entry);
                    }

                    skipNext = true;
                    return true;
                }

                if(entry.action === HistoryAction.edited && next.action === HistoryAction.added) {
                    skipNext = false;
                    setPrevLabel = true;
                    prevLabel = entry.label;
                    return true;
                }
            }
        }

        if(!skipNext) {
            filteredEntries.push(entry);
        }

        skipNext = false;
        prevLabel = '';
        setPrevLabel = false;
        prevAction = null;
        prevRecordedAt = null;
    });

    const filteredEntriesList: BoardHistory = List(filteredEntries);

    return filteredEntriesList;
}

export const initialState: BoardHistoryState = Map<string, HistoryState>();

const reducer: Reducer<BoardHistoryState, InspectioBoardsAction> = (state: BoardHistoryState = initialState, action: InspectioBoardsAction): BoardHistoryState => {
    switch (action.type) {
        case APPEND_HISTORY_ENTRIES:
            let aBoardHistory: HistoryState = state.get(action.payload.boardId, new HistoryState());
            let aNewEntries = action.payload.entries;

            if(aBoardHistory.history.count() > 0) {
                const currentLastEntry: HistoryEntry = aBoardHistory.history.last();
                aBoardHistory = aBoardHistory.set("history", aBoardHistory.history.pop());
                aNewEntries = aNewEntries.unshift(currentLastEntry);
            }

            aNewEntries = filterDuplicates(aNewEntries);
            const lastEntry = action.payload.entries.last(new HistoryEntry());

            aBoardHistory = aBoardHistory.set("history", aBoardHistory.history.push(...aNewEntries.toArray()));
            aBoardHistory = aBoardHistory.set("oldestBoardVersion", lastEntry.boardVersion);

            // Stop if old history entry without cellId is found
            if(!lastEntry.cellId && lastEntry.cellType === HistoryCellType.misc) {

                const entryDate = moment(lastEntry.date, moment.ISO_8601);

                if(entryDate.isBefore(moment('2019-07-29'))) {
                    aBoardHistory = aBoardHistory.set('olderPatchesAvailable', false);
                }
            }

            return state.set(action.payload.boardId, aBoardHistory);
        case PREPEND_HISTORY_ENTRIES:
            let pBoardHistory: HistoryState = state.get(action.payload.boardId, new HistoryState());
            let pNewEntries = action.payload.entries;

            if(pBoardHistory.history.count() > 0) {
                const firstEntry: HistoryEntry = pBoardHistory.history.first();
                pBoardHistory = pBoardHistory.set("history", pBoardHistory.history.shift());
                pNewEntries = pNewEntries.push(firstEntry);
            }

            pNewEntries = filterDuplicates(pNewEntries);

            pBoardHistory = pBoardHistory.set("history", pBoardHistory.history.unshift(...pNewEntries.toArray()));

            const pLastEntry = action.payload.entries.last(new HistoryEntry());

            if(!pBoardHistory.oldestBoardVersion) {
                pBoardHistory = pBoardHistory.set("oldestBoardVersion", pLastEntry.boardVersion);
            }

            // Stop if old history entry without cellId is found
            if(!pLastEntry.cellId && pLastEntry.cellType === HistoryCellType.misc) {
                const entryDate = moment(pLastEntry.date, moment.ISO_8601);

                if(entryDate.isBefore(moment('2019-07-29'))) {
                    pBoardHistory = pBoardHistory.set('olderPatchesAvailable', false);
                }
            }

            return state.set(action.payload.boardId, pBoardHistory);
        case SET_IS_LOADING_HISTORY:
            let loadingHistoryState = state.get(action.payload.boardId, new HistoryState());
            loadingHistoryState = loadingHistoryState.set('loading', action.payload.loading);
            return state.set(action.payload.boardId, loadingHistoryState);
        case SET_NO_MORE_HISTORY_AVAILABLE:
            return state.setIn([action.payload.boardId, 'olderPatchesAvailable'], false);
        case CLEAR_BOARD_HISTORY:
            return state.remove(action.payload.boardId);
        default:
            return state;
    }

}

export {reducer as manageBoardHistory};
