export const root = '/';
export const overview = '/overview';
export const inspectio = '/inspectio';
export const userProfile = inspectio + '/user/profile';
export const inspectioBoardWorkspace = inspectio + '/board/:uid';
export const compileInspectioBoardWorkspace = (boardUid: string): string => inspectio + '/board/' + boardUid;
export const isInspectioBoardWorkspace = (pathname: string): boolean => pathname.search(inspectio + '/board/') === 0;
export const getBoardUidFromWorkspacePath = (pathname: string): string => pathname.replace(inspectio + '/board/', '');
export const getBoardUidFromWorkspaceUrl = (url: string): string => url.replace(getHostUrlPart() + inspectio + '/board/', '');
export const inspectioEditBoard = inspectio + '/boards/edit/:uid';
export const compileInspectioBoardEdit = (boardUid: string): string => inspectio + '/boards/edit/' + boardUid;
export const boardInvitation = inspectio + '/boards/invitation/:invitation';
export const boardImportLink = inspectio + '/boards/import/:link';
export const teamInvitation = inspectio + '/teams/invitation/:invitation';
export const organizationInvitation = inspectio + '/organizations/:organization/invitation/:invitation';
export const teamOverview = inspectio + '/teams';
export const organizationOverview = inspectio + '/organization';
export const Updates = inspectio + '/updates';
export const BoardRedirect = inspectio + '/boards/redirect';
export const PublicSounds = '/sound';

export type DeepLinkFactory = (cellId: string) => string;

export const makeWorkspaceDeeplinkFacory = (boardUid: string): DeepLinkFactory =>
    (cellId) => getHostUrlPart() + compileInspectioBoardWorkspace(boardUid) + `?cells=${cellId}&clicks=1`;

export const compileWorkspacePlayshotDeeplink = (boardUid: string) =>
  compileInspectioBoardWorkspace(boardUid) + `?playshots=1`;

const getHostUrlPart = (): string => {
    return  window.location.protocol + '//' + window.location.host;
}
