import * as React from 'react';
import {Divider, Grid, GridColumn, GridRow} from "semantic-ui-react";
import {isStandalone} from "../../../standalone/util";
import Filter from "./Filter";
import Sorting from "./Sorting";
import TeamFilter from "./TeamFilter";

interface OwnProps {

}

type HeaderBarProps = OwnProps;

const HeaderBar = (props: HeaderBarProps) => {
    return <Grid stackable={true} padded={true}>
        <GridRow columns={ 3 }>
            <GridColumn>
                <Sorting />
            </GridColumn>
            <GridColumn>
                {!isStandalone() && <TeamFilter/>}
            </GridColumn>
            <GridColumn>
                <Filter />
            </GridColumn>
        </GridRow>
        <Grid.Row>
            <Grid.Column>
                <Divider horizontal={true} content="Boards" />
            </Grid.Column>
        </Grid.Row>
    </Grid>
};

export default HeaderBar;
