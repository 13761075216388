import {storeOverviewSettingsFlow, syncElementsTreeFlow} from "../../InspectioBoards/sagas";
import {standaloneDeleteBoardFlow} from "./deleteBoardFlow";
import {standaloneEditBoardFlow} from "./editBoardFlow";
import {standaloneFetchBoardStickyTemplatesFlow} from "./fetchBoardStickyTemplates";
import {standaloneFetchPlayshotSummaryListFlow} from "./fetchPlayshotSummaryListFlow";
import {standaloneFetchUserBoardFlow} from "./fetchUserBoardFlow";
import {standaloneFetchUserBoardsFlow} from "./fetchUserBoardsFlow";
import {standaloneImportExistingBoardFlow} from "./importExistingBoardFlow";
import {standaloneImportNewBoardFlow} from "./importNewBoardFlow";
import {importStickyTemplates} from "./importStickyTemplates";
import {standaloneInstallUserBoardFlow} from "./installUserBoardFlow";
import {standaloneLoadBoardHistoryFlow} from "./loadBoardHistoryFlow";
import {standaloneSaveBoardFlow} from "./saveBoardFlow";
import {standaloneUpdateStickyTemplateFlow} from "./updateStickyTemplate";
import {standaloneUpsertUserSetting} from "./upsertUserSettingFlow";

const StandaloneSaga = {
    standaloneDeleteBoardFlow,
    standaloneFetchBoardStickyTemplatesFlow,
    standaloneFetchUserBoardsFlow,
    standaloneFetchUserBoardFlow,
    standaloneImportNewBoardFlow,
    standaloneImportExistingBoardFlow,
    standaloneInstallUserBoardFlow,
    standaloneLoadBoardHistoryFlow,
    standaloneSaveBoardFlow,
    standaloneUpdateStickyTemplateFlow,
    standaloneUpsertUserSetting,
    standaloneEditBoardFlow,
    syncElementsTreeFlow,
    importStickyTemplates,
    storeOverviewSettingsFlow,
    standaloneFetchPlayshotSummaryListFlow,
};

export default StandaloneSaga;
