import {call, fork, put,select, take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as Notify} from "../../NotificationSystem";
import * as Action from "../actions";
import {makeBoardByIdSelector, makeGetBoard} from "../selectors/board";

type FlowAction = ActionType<typeof Action.Command.importExistingBoard>;

function* importExistingBoard(action: FlowAction) {
    const {board} = action.payload;
    const oldBoard = yield select(makeBoardByIdSelector(board.uid));

    yield put(Action.Command.forceUpdateBoards([board]));

    const {response, error}: ResponseType = yield call(Action.Api.takeBoardSnapshot, board.uid, board.version, board.xml!);

    if(error) {
        yield put(Notify.Command.error('Server Error', 'Could not import existing board.'));
        yield put(Action.Command.forceUpdateBoards([oldBoard]));
        return;
    }

    if(response) {
        yield put(Notify.Command.info('Board imported:', board.name));
    }
}

export function* importExistingBoardFlow() {
    while (true) {
        const action: FlowAction = yield take([Action.Type.IMPORT_EXISTING_BOARD]);

        yield fork(importExistingBoard, action);
    }
}
