import moment from "moment";
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Dispatch} from "redux";
import {Accordion, AccordionContent, Card, Icon, List, Loader, Message, Transition} from "semantic-ui-react";
import {loadFirstBoardHistoryChunk, loadNextBoardHistoryChunk, toggleBoardHistory} from "../actions/commands";
import {BoardId} from "../model/Board";
import {BoardVersion} from "../model/BoardVersion";
import * as HistoryModel from "../model/HistoryEntry";
import {HistoryAction} from "../model/HistoryEntry";
import {HistoryState} from "../model/HistoryState";
import {makeBoardByIdSelector} from "../selectors/board";
import {makeBoardHistoryByIdSelector} from "../selectors/boardHistory";
import BoardHistoryEntry from "./BoardHistoryEntry";
import BoardHistoryGroup from "./BoardHistoryGroup";

interface GroupedBoardHistory {
    today: React.ReactNode[],
    yesterday: React.ReactNode[],
    twoDaysAgo: React.ReactNode[],
    threeDaysAgo: React.ReactNode[],
    fourDaysAgo: React.ReactNode[],
    fiveDaysAgo: React.ReactNode[],
    sixDaysAgo: React.ReactNode[],
    lastWeek: React.ReactNode[],
    twoWeeksAgo: React.ReactNode[],
    threeWeeksAgo: React.ReactNode[],
    fourWeeksAgo: React.ReactNode[],
    lastMonth: React.ReactNode[],
    older: React.ReactNode[],
}

interface RelativeMoments {
    today: moment.Moment;
    startOfWeek: moment.Moment;
    startOfMonth: moment.Moment;
    startOfLastMonth: moment.Moment;
}

type GroupedBoardHistoryKey = keyof GroupedBoardHistory;

const makeEmptyGroupedBoardHistory = (): GroupedBoardHistory => {
    return {
        today: [],
        yesterday: [],
        twoDaysAgo: [],
        threeDaysAgo: [],
        fourDaysAgo: [],
        fiveDaysAgo: [],
        sixDaysAgo: [],
        lastWeek: [],
        twoWeeksAgo: [],
        threeWeeksAgo: [],
        fourWeeksAgo: [],
        lastMonth: [],
        older: [],
    }
};

const marshalFeedEvent = (entry: HistoryModel.HistoryEntry, key: number, clicks: number, handleClick: () => void): React.ReactNode => {

    return  <BoardHistoryEntry entry={entry} key={key} clicks={clicks} onClick={handleClick} />;
};

export const generateRelativeMomentsFromNow = (now: moment.Moment): RelativeMoments => {
    const startOfWeek = moment(now).startOf('isoWeek');
    const startOfMonth = moment(now).startOf('month');
    const startOfLastMonth = moment(startOfMonth).subtract(1, 'days').startOf('month');
    const today = moment(now).startOf('day');

    return {today, startOfWeek, startOfMonth, startOfLastMonth}
};

export const convertToRelativeHistoryGroup = (
    entryDate: moment.Moment,
    now: moment.Moment,
    {today, startOfWeek, startOfMonth, startOfLastMonth}: RelativeMoments
): GroupedBoardHistoryKey => {

    if(entryDate.isBefore(startOfLastMonth, 'month')) {
        return 'older';
    }

    if(entryDate.isBefore(startOfMonth)) {
        return 'lastMonth';
    }

    if(entryDate.isBefore(startOfWeek)) {
        const numOfWeeks = entryDate.diff(startOfWeek, 'weeks');

        if(numOfWeeks === 0) {
            return 'lastWeek';
        }

        if(numOfWeeks === -1) {
            return 'twoWeeksAgo';
        }

        if(numOfWeeks === -2) {
            return 'threeWeeksAgo';
        }

        return 'fourWeeksAgo';
    }

    if(entryDate.isBefore(today)) {
        const numOfDays = entryDate.diff(today, 'days');

        if(numOfDays === 0) {
            return 'yesterday';
        }

        if(numOfDays === -1) {
            return 'twoDaysAgo';
        }

        if(numOfDays === -2) {
            return 'threeDaysAgo';
        }

        if(numOfDays === -3) {
            return 'fourDaysAgo';
        }

        if(numOfDays === -4) {
            return 'fiveDaysAgo';
        }

        return 'sixDaysAgo';
    }

    return 'today';
}

const loadHistory = (dispatch: Dispatch, boardId: BoardId, boardVersion?: BoardVersion): void => {
    if(boardVersion) {
        dispatch(loadNextBoardHistoryChunk(boardId, boardVersion))
    } else {
        dispatch(loadFirstBoardHistoryChunk(boardId));
    }
}

interface BoardHistoryRouteMatchParams {
    uid: BoardId;
}

interface OwnProps {
    elementId?: string;
}

type BoardHistoryProps = OwnProps &  WithNamespaces & RouteComponentProps<BoardHistoryRouteMatchParams>;

const BoardHistory = (props: BoardHistoryProps) => {

    const getBoardByIdSelector = useMemo(() => makeBoardByIdSelector(props.match.params.uid), []);
    const getBoardHistoryByIdSelector = useMemo(() => makeBoardHistoryByIdSelector(props.match.params.uid), []);
    const [clicks, setClicks] = useState(1);

    const board = useSelector(getBoardByIdSelector);
    const history: HistoryState = useSelector(getBoardHistoryByIdSelector);
    const dispatch = useDispatch();
    const visible =  (board && board.shouldShowHistory) || !!props.elementId;

    const handleClick = () => {
        setClicks(clicks + 1);
    }

    const feedEvents: GroupedBoardHistory = useMemo(() => {

        const now = moment();
        const relativeMoments = generateRelativeMomentsFromNow(now);
        const groupedHistory = makeEmptyGroupedBoardHistory();

        history.history.filter(entry => !props.elementId || entry.cellId === props.elementId).forEach((entry, key) => {
            const entryDate = moment(entry.date, moment.ISO_8601);
            const entryComponent = marshalFeedEvent(entry, key + 1, clicks, handleClick);
            const groupKey = convertToRelativeHistoryGroup(entryDate, now, relativeMoments);
            groupedHistory[groupKey].push(entryComponent);
        });

        return groupedHistory;
    }, [history, clicks, props.elementId]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const element = e.target;

        if(element instanceof HTMLDivElement) {
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                if(!history.loading && history.olderPatchesAvailable) {
                    loadHistory(dispatch, props.match.params.uid, history.oldestBoardVersion);
                }
            }
        }
    }

    useEffect(() => {
        const filteredHistory = history.history.filter(entry => !props.elementId || entry.cellId === props.elementId);
        let foundFirstElementEntry = false;

        if(props.elementId) {
            const lastHistoryEntry = filteredHistory.last(undefined);

            if(lastHistoryEntry && lastHistoryEntry.action === HistoryAction.added) {
                foundFirstElementEntry = true;
            }
        }

        if(filteredHistory.count() < 50 && !history.loading && history.olderPatchesAvailable && !foundFirstElementEntry) {
            loadHistory(dispatch, props.match.params.uid, history.oldestBoardVersion);
        }
    }, [history]);

    const handleCloseHistory = useMemo(() => {
        return () => {
            dispatch(toggleBoardHistory(props.match.params.uid, false));
        };
    }, [props.match.params.uid]);

    const boardHistoryGroups = [];
    let count = 0;
    let group: GroupedBoardHistoryKey;
    for(group in feedEvents) {
        if (feedEvents.hasOwnProperty(group)) {
            if(feedEvents[group].length > 0) {
                boardHistoryGroups.push(<BoardHistoryGroup key={group} title={props.t(`insp.history.${group}`)} index={count} entries={feedEvents[group]} isActiveByDefault={count === 0} />);
                count++;
            }
        }
    }

    if(visible && history.loading) {
        boardHistoryGroups.push(<AccordionContent active={true}>
            <List>
                <List.Item key="item-loader" style={{paddingTop: "10px"}}><Loader active={true} inline='centered' /></List.Item>
            </List>
        </AccordionContent>)
    }

    if(visible && !history.loading && count === 0) {
        boardHistoryGroups.push(<Message warning={true} content="No activity available for this element. This happens when the element was added together with other elements, e.g. by importing an entire board or pasting a group of elements. We'll remove this limitation in the future." />)
    }

    return <>
        {!props.elementId && <Transition.Group animation="slide down">{visible && <div style={{position: "absolute", paddingTop: "50px", top: "0px", right: "0px", height: "100%", overflow: "hidden", borderLeft:"5px solid #eee", zIndex: 99}}>
                <Card style={{height: "100%", borderRadius:'0'}}>
                <Card.Content>
                  <Card.Header><Icon name="close" className="right floated" onClick={handleCloseHistory} style={{cursor: "pointer"}} /> Activity</Card.Header>
                </Card.Content>
                <Card.Content onScroll={handleScroll} style={{position: "relative", height: "100%", overflowY: "scroll",}}>
                    <Accordion>
                        { boardHistoryGroups }
                    </Accordion>
                </Card.Content>
                </Card>
            </div>}
        </Transition.Group>}
        {!!props.elementId && <div onScroll={handleScroll}>
            <Accordion>
                { boardHistoryGroups }
            </Accordion>
        </div>}
    </>
};

export default withNamespaces()(withRouter(BoardHistory));
