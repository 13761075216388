import {Board} from "../../../model/Board";
import {Node, NodeType} from "../../../model/Graph";
import {RulesDependencyMetadata} from "../rule-engine/types";
import { getVoMetadata } from "../vo/get-vo-metadata";
import {WizardContext} from "./wizard-context";

export interface WithInformationNode {
  vo: Node;
  sliceApiLabel: Node | null;
  queryDependencies: Readonly<Record<string, Readonly<RulesDependencyMetadata>>>;
}

export type ImmutableInformationContext = Readonly<InformationContext>;

interface InformationContext extends WizardContext<ImmutableInformationContext>, WithInformationNode {

}

export const informationContextFromNode = (vo: Node, board: Board): ImmutableInformationContext => {
  const metadata = getVoMetadata(vo);

  const feature = getFeatureFromNode(vo);
  let sliceApiLabel: Node | null = null;

  if(feature && board.eventModelingEnabled) {
    feature.children().forEach(child => {
      if(child.getTags().contains(ispConst.TAG_SLICE_API_LABEL)) {
        sliceApiLabel = child;
      }
    })
  }

  const ctx: InformationContext = {
    board,
    type: "information",
    vo,
    sliceApiLabel,
    queryDependencies: metadata.queryDependencies || {},
    nextStep: currentStep => null,
    previousStep: currentStep => null,
    installCodyGraphSuggestions: () => { /* Do nothing */ },
  }

  return ctx;
}

export const isInformationContext = (ctx: WizardContext): ctx is ImmutableInformationContext => {
  return ctx.type === 'information';
}


const getFeatureFromNode = (node: Node): Node | null => {
  const parent = node.getParent();

  if(parent && parent.getType() === NodeType.feature) {
    return parent;
  }

  return null;
}
