import {List, Map} from 'immutable';
import {Reducer} from 'redux';
import {ActionType} from "typesafe-actions";
import {upsertAggregates} from "../../core/reducer/applyAggregateChanged";
import {USER_ORGANIZATION_INVITATION_RESENT, USER_ORGANIZATION_INVITATION_REVOKED} from "../actions/constants";
import * as Events from "../actions/events";
import {OrganizationInvitation} from "../model/OrganizationInvitation";

type Event = ActionType<typeof Events>;

export interface OrganizationInvitationState extends Map<string, OrganizationInvitation> {
}

export const initialState: OrganizationInvitationState = Map<string, OrganizationInvitation>();

const reducer: Reducer<OrganizationInvitationState, Event> = (state: OrganizationInvitationState = initialState, event: Event): OrganizationInvitationState => {
    switch (event.type) {
        case USER_ORGANIZATION_INVITATION_RESENT:
        case USER_ORGANIZATION_INVITATION_REVOKED:
            return upsertAggregates(state, List([event.payload.invitation]));
        default:
            return state;
    }
};

export {reducer as saveOrganizationInvitationReducer};
