import {List, Record} from "immutable";
import {RouteComponentProps} from "react-router";
import { createSelector } from 'reselect';
import {ApplicationState} from "../../reducer";
import {Selector as SocketSelector} from "../../Socket";
import {UserSelector} from "../../User";
import {keyLiteMode, makeUserSettingSelector} from "../../User/selectors/userSettings";
import {EditBoardProps} from "../components/EditBoard";
import {MxGraphBoardProps, PropsFromState} from "../components/MxGraphBoard";
import {BoardRouteMatchParams} from "../components/types";
import {BoardModel} from "../model";

const getBoardById = (state: Record<ApplicationState>, boardId: BoardModel.BoardId): BoardModel.Board => state.getIn(['inspectioBoards', 'boards', boardId]);

export const boardSelector = (state: Record<ApplicationState>, props: RouteComponentProps<BoardRouteMatchParams>) => getBoardById(state, props.match.params.uid);

export const makeBoardByIdSelector = (boardId: BoardModel.BoardId): (state: Record<ApplicationState>) => BoardModel.Board => (state: Record<ApplicationState>) => getBoardById(state, boardId);

export const makeGetBoard = (): (state: Record<ApplicationState>, props: RouteComponentProps<BoardRouteMatchParams>) => BoardModel.Board => {
    return createSelector(
        [boardSelector],
        (board): BoardModel.Board => board
    )
}

export const makeMapStateToPropsAddBoard = () => {
    const getCurrentUser = UserSelector.makeGetCurrentUser();

    return (state: Record<ApplicationState>, props: any) => {
        const currentUser = getCurrentUser(state, props);

        return {
            user: currentUser
        }
    }
}

export const makeMapStateToPropsEditBoard = () => {
    const getBoard = makeGetBoard();

    return (state: Record<ApplicationState>, props: EditBoardProps) => {
        const board = getBoard(state, props);

        return {
            board
        }
    }
}

export  const makeMapStateToPropsMxGraphBoard = (): (state: Record<ApplicationState>, props: MxGraphBoardProps) => PropsFromState => {
    const getBoard = makeGetBoard();
    const getCurrentUser = UserSelector.makeGetCurrentUser();
    const getSocketStatus = SocketSelector.StatusSelector.makeGetStatus();
    const getLiteModeSetting = makeUserSettingSelector(keyLiteMode(), false);

    return (state: Record<ApplicationState>, props: MxGraphBoardProps): PropsFromState => {
        const board = getBoard(state, props);
        const user = getCurrentUser(state, props);
        const socketStatus = getSocketStatus(state, props);
        const liteMode = getLiteModeSetting(state, props) as boolean;

        return {
            board,
            user,
            socketStatus,
            liteMode
        }
    }
}
