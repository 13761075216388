import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {NavLink} from "react-router-dom";
import { pure } from 'recompose';
import {Dropdown} from "semantic-ui-react";
import {
    toggleBoardHistory,
    toggleCodyConsole,
    toggleElementDetails, togglePlayshotModal,
    triggerExportBoard,
    triggerImportBoard
} from "../../../InspectioBoards/actions/commands";
import {BoardId} from "../../../InspectioBoards/model/Board";
import * as Routes from "../../../routes";
import CollaborationInfoModal from "../CollaborationInfoModal";
import {useState} from "react";

interface BoardTopMenuRouteMatchParams {
    uid: BoardId;
}

type BoardTopMenuProps =  WithNamespaces & RouteComponentProps<BoardTopMenuRouteMatchParams>;

const BoardTopMenu = pure((props: BoardTopMenuProps) => {

    const [infoModalOpen, setInfoModalOpen] = useState(false);

    const dispatch = useDispatch();

    return <><Dropdown item={true} icon="ellipsis vertical">
        <Dropdown.Menu direction="left">
            <Dropdown.Item icon="history" text="Activity" onClick={() => setInfoModalOpen(true)} />
            <Dropdown.Item
                icon={'clipboard outline'}
                text={props.t('insp.top_menu.element_details')}
                onClick={() => dispatch(toggleElementDetails(props.match.params.uid, true, false))}
            />
            <Dropdown.Item icon="code" text="Cody" onClick={() => dispatch(toggleCodyConsole(props.match.params.uid, true)) } />
            <Dropdown.Item icon="play" text="Cody Play" onClick={() => dispatch(togglePlayshotModal(props.match.params.uid, true))} />
            <Dropdown.Item icon="cog" text="Settings" to={Routes.compileInspectioBoardEdit(props.match.params.uid)} as={NavLink}/>
            <Dropdown.Item icon="download" text="Import Board" onClick={() => dispatch(triggerImportBoard(props.match.params.uid))} />
            <Dropdown.Item icon="upload" text="Export Board" onClick={() => dispatch(triggerExportBoard(props.match.params.uid))} />
            <Dropdown.Item icon="question" text="FAQs" as="a" href="https://wiki.prooph-board.com" target="_blank" />
        </Dropdown.Menu>
    </Dropdown>
        <CollaborationInfoModal open={infoModalOpen} onClose={() => setInfoModalOpen(false)} headerKey={'header_history'} />
    </>});

export default withNamespaces()(withRouter(BoardTopMenu));
