import {fork, take, call} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import * as Action from "../../InspectioBoards/actions";
import {useGraph} from "../../InspectioBoards/hooks/useGraph";
import {standaloneStorageManager} from "../util";
import {StandaloneBoard} from "../storage/types";

type FlowAction = ActionType<typeof Action.Command.saveBoard>;

function* onSaveBoard(action: FlowAction) {
    const [graph] = useGraph();

    const board: StandaloneBoard|null = yield call(standaloneStorageManager.getBoard, action.payload.board.uid);
    if (board && !action.payload.board.tempBoard) {
        yield call(standaloneStorageManager.updateBoard, {
            ...board,
            xml: graph.exportXmlSnapshot(),
            lastModified: (() => new Date())().toISOString(),
        });
    }
}

export function* standaloneSaveBoardFlow() {
    while (true) {
        const action: FlowAction = yield take(Action.Type.SAVE_BOARD);
        yield fork(onSaveBoard, action);
    }
}
