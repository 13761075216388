import React, {useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {Button, Confirm, Icon, Message, Table} from "semantic-ui-react";
import {copyToClipboard} from "../../../../core/copyToClipboard";
import {removeBoardClient, resetBoardClientSecret} from "../../../actions/commands";
import {fetchBoardClientSecret} from "../../../actions/queries";
import * as BoardModel from "../../../model/Board";
import {BoardClient} from "../../../model/BoardClient";

interface OwnProps {
    boardId: BoardModel.BoardId;
    client: BoardClient;
}

type BoardClientTableProps = OwnProps & WithNamespaces;

const BoardClientTable = (props: BoardClientTableProps) => {

    const dispatch = useDispatch();
    const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);
    const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
    const [resetLoading, setResetLoading] = useState<boolean>(false);
    const [clientSecretCopied, setClientSecretCopied] = useState<boolean>(false);

    useEffect(() => {
        if (props.client && props.client.clientSecret) {
            setResetLoading(false);
        }
    }, [props.client]);

    const retrieveClientSecret = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(fetchBoardClientSecret(props.boardId));
    };

    const openResetConfirm = () => setResetConfirmOpen(true);
    const closeResetConfirm = () => setResetConfirmOpen(false);
    const openRemoveConfirm = () => setRemoveConfirmOpen(true);
    const closeRemoveConfirm = () => setRemoveConfirmOpen(false);

    const resetClientSecret = () => {
        closeResetConfirm();
        setResetLoading(true);
        dispatch(resetBoardClientSecret(props.boardId));
    };

    const removeClientSecret = () => {
        closeRemoveConfirm();
        dispatch(removeBoardClient(props.boardId));
    };

    const copyClientSecretToClipboard = () => {
        copyToClipboard(props.client.clientSecret!);
        setClientSecretCopied(true);
        setTimeout(() => setClientSecretCopied(false), 2000);
    };

    return (<>
        <Table unstackable={true} basic={'very'}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell style={{ width: '150px' }}>
                        <strong>{props.t('insp.board.client.clientId')}:</strong>
                    </Table.Cell>
                    <Table.Cell>
                        {props.client.clientId}
                    </Table.Cell>
                    <Table.Cell style={{ width: '125px' }} />
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <strong>{props.t('insp.board.client.clientSecret')}:</strong>
                    </Table.Cell>
                    <Table.Cell>
                        {props.client.clientSecret && (
                            <>
                                {props.client.clientSecret}
                                <Icon
                                    name={clientSecretCopied ? 'clipboard check' : 'copy'}
                                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    title={props.t('insp.board.client.copyToClipboard')}
                                    onClick={copyClientSecretToClipboard}
                                />
                            </>
                        )}
                        {!props.client.clientSecret && (
                            <a href={'#'} onClick={retrieveClientSecret}>
                                {props.t('insp.board.client.retrieveSecret')}
                            </a>
                        )}
                    </Table.Cell>
                    <Table.Cell style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <Button
                            icon={true}
                            labelPosition={'left'}
                            title={props.t('app.form.reset')}
                            onClick={openResetConfirm}
                            loading={resetLoading}
                            style={props.client.clientSecret ? null : { visibility: 'hidden' }}
                            children={<><Icon name={'redo'} />{props.t('app.form.reset')}</>}
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
        <Message>
            <Table unstackable={true} basic={'very'}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            {props.t('insp.board.client.removeText')}
                        </Table.Cell>
                        <Table.Cell>
                            <Button
                                icon={true}
                                labelPosition={'left'}
                                negative={true}
                                floated={'right'}
                                title={props.t('app.form.remove')}
                                onClick={openRemoveConfirm}
                                children={<><Icon name={'delete'} />{props.t('app.form.remove')}</>}
                            />
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Message>
        <Confirm
            header={props.t('insp.board.client.resetConfirmTitle')}
            content={props.t('insp.board.client.resetConfirmText')}
            open={resetConfirmOpen}
            onCancel={closeResetConfirm}
            onConfirm={resetClientSecret}
        />
        <Confirm
            header={props.t('insp.board.client.removeConfirmTitle')}
            content={props.t('insp.board.client.removeConfirmText')}
            open={removeConfirmOpen}
            onCancel={closeRemoveConfirm}
            onConfirm={removeClientSecret}
        />
    </>);
};

export default withNamespaces()(BoardClientTable);
