import { call, actionChannel, fork, put, take } from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import * as Action from "../actions";
import {ResponseType} from "../../api/util";

type FlowAction = ActionType<typeof Action.Command.upsertUserSetting>;

function* onUpsertUserSetting(action: FlowAction) {

    // Trigger the event immediately since its not the end of the world if the request fails
    yield put(Action.Event.userSettingUpserted(action.payload.key, action.payload.value));

    const {response, error}: ResponseType = yield call(
        Action.Api.upsertUserSetting,
        action.payload.key,
        action.payload.value
    );

    if (error) {
        /* tslint:disable-next-line:no-console */
        console.log(`Failed updating user setting for ${action.payload.key} and ${action.payload.value}`);
    }
}

export function* upsertUserSetting() {
    // Execute user setting upserts one after another to prevent race conditions
    const requestChan = yield actionChannel(Action.Type.UPSERT_USER_SETTING);

    while (true) {
        const action: FlowAction = yield take(requestChan);

        yield call(onUpsertUserSetting, action)
    }
}
