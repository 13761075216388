import {List, Record} from "immutable";
import {createSelector} from "reselect";
import {TeamId} from "../../InspectioTeams/model/Team";
import {ApplicationState} from "../../reducer";
import {MODULE} from "../index";
import {BoardId} from "../model/Board";
import {BoardPermissionForTeam, PermissionsId} from "../model/BoardPermissionForTeam";
import {BoardPermissionForUser} from "../model/BoardPermissionForUser";

export const getBoardPermissionForTeamById = (state: Record<ApplicationState>, permissionId: PermissionsId): BoardPermissionForTeam =>
    state.getIn([MODULE, 'boardPermissionsForTeams', permissionId]);

export const makeBoardPermissionForTeamSelector = (boardId: BoardId, teamId: TeamId): (state: Record<ApplicationState>, props?: any) => BoardPermissionForTeam|undefined => {
    return createSelector(
        [boardPermissionForTeamListSelector],
        (boardPermissions): BoardPermissionForTeam|undefined => {
            return boardPermissions.find(permission => permission.teamId === teamId && permission.boardId === boardId);
        }
    )
};

export const boardPermissionForUserListSelector = (state: Record<ApplicationState>, props: any): List<BoardPermissionForUser> =>
    List(state.getIn([MODULE, 'boardPermissionsForUsers']).toIndexedSeq().toArray()) || List();

export const boardPermissionForTeamListSelector = (state: Record<ApplicationState>, props: any): List<BoardPermissionForTeam> =>
    List(state.getIn([MODULE, 'boardPermissionsForTeams']).toIndexedSeq().toArray()) || List();

export const makeBoardPermissionForUserListSelector = (boardId: string): (state: Record<ApplicationState>, props?: any) => List<BoardPermissionForUser> => {
    return createSelector(
        [boardPermissionForUserListSelector],
        (boardPermissions): List<BoardPermissionForUser> =>  {
            return boardPermissions.filter(boardPermission => boardPermission.boardId === boardId);
        }
    )
};

export const makeBoardPermissionForTeamListSelector = (boardId: string): (state: Record<ApplicationState>, props?: any) => List<BoardPermissionForTeam> => {
    return createSelector(
        [boardPermissionForTeamListSelector],
        (boardPermissions): List<BoardPermissionForTeam> =>  {
            return boardPermissions.filter(boardPermission => boardPermission.boardId === boardId);
        }
    )
};
