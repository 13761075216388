import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Container, Grid, Header, Menu, MenuItem, Message} from "semantic-ui-react";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeActiveUserOrganizationInfoSelector} from "../selectors/selectUserOrganizationInfo";
import OrganisationManagement from "./OrganisationManagement";

interface OwnProps {

}

type OrganizationOverviewProps = OwnProps & WithNamespaces;

const OrganizationOverview = (props: OrganizationOverviewProps) => {
    const currentUser = useSelector(makeGetCurrentUser());
    const activeOrganization = useSelector(makeActiveUserOrganizationInfoSelector());

    return <Container>
        <Grid stackable={true} padded={true}>
            <Grid.Row>
                <Grid.Column computer="1" largeScreen="2" widescreen="3" />
                <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
                    <Header as="h1" icon={true} textAlign='center'>
                        <Header.Content>
                            Manage Organisation
                            <Header.Subheader />
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            {activeOrganization && activeOrganization.userIsAdmin && <>
            <OrganisationManagement currentUser={currentUser} activeOrganization={activeOrganization} />
            </>}
            {(!activeOrganization || !activeOrganization.userIsAdmin) && <Grid.Row>
                <Grid.Column computer="1" largeScreen="2" widescreen="3" />
                <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
                    <Message content={props.t('insp.organisation.no_admin')} warning={true} />
                </Grid.Column>
            </Grid.Row>
            }
        </Grid>
    </Container>;
};

export default withNamespaces()(OrganizationOverview);
