import {Node} from "../../../model/Graph";
import {parseNodeMetadata} from "../node/metadata";
import {Rule} from "../rule-engine/configuration";

export interface AggregateMetadata {
  rules?: Rule[]
}

export const getAggregateMetadata = (aggregate: Node): AggregateMetadata => {
  const meta = parseNodeMetadata(aggregate);

  return meta;
}
