import {WithNamespaces, withNamespaces} from "react-i18next";
import {Map} from "immutable";
import {NodeType} from "../../../model/Graph";
import {StickyTemplate} from "../../../model/StickyTemplate";
import {List} from "semantic-ui-react";
import CellIcon from "../../CellIcon";
import {HistoryCellType} from "../../../model/HistoryEntry";
import * as React from "react";

interface OwnProps {
    boardStickyTemplates: Map<NodeType, StickyTemplate>;
    activeCellType: NodeType|undefined;
    onSetActiveCellType: (nodeType: NodeType) => void;
}

type BoardStickySelectionProps = OwnProps & WithNamespaces;

const isFrame = (cellType: NodeType): boolean => {
    return cellType === NodeType.boundedContext || cellType === NodeType.feature;
}

const isSpecialShape = (cellType: NodeType): boolean => {
    return cellType === NodeType.freeText || cellType === NodeType.image || cellType === NodeType.icon;
}

const isCard = (cellType: NodeType): boolean => {
    return !isFrame(cellType) && !isSpecialShape(cellType);
}

const BoardStickySelection = (props: BoardStickySelectionProps) => {
    let isFirst = true;

    const stickies = props.boardStickyTemplates
        .sort((a, b) => {
            if(isCard(a.cellType) === isCard(b.cellType)) {
                if(a.cellType < b.cellType) {
                    return -1;
                }

                if(a.cellType > b.cellType) {
                    return 1;
                }

                return 0;
            }

            if(isCard(a.cellType)) {
                return -1;
            }

            if(isCard(b.cellType)) {
                return 1;
            }

            if(isFrame(a.cellType)) {
                return -1;
            }

            if(isFrame(b.cellType)) {
                return 1;
            }

            if(a.cellType === NodeType.freeText) {
                return -1;
            }

            if(b.cellType === NodeType.freeText) {
                return 1;
            }

            return 0;
        })
        .map((stickyTemplate) => {
            const active = props.activeCellType ? props.activeCellType === stickyTemplate.cellType : isFirst;

            if(!props.activeCellType && isFirst) {
                props.onSetActiveCellType(stickyTemplate.cellType);
            }

            isFirst = false;
            return (
                <List.Item
                    key={stickyTemplate.cellType}
                    as='a' active={active}
                    onClick={() => {props.onSetActiveCellType(stickyTemplate.cellType)}}
                >
                    <CellIcon cellType={stickyTemplate.cellType as HistoryCellType} size="large" />
                    <List.Content>{props.t('app.stickyTypes.' + stickyTemplate.cellType)}</List.Content>
                </List.Item>
            );
        })
        .toIndexedSeq()
        .toArray();

    return (
        <List link={true}>
            {stickies}
        </List>
    );
};

export default withNamespaces()(BoardStickySelection);
