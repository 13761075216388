import React, {useEffect} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";
import {fetchBoardClient} from "../../actions/queries";
import * as BoardModel from "../../model/Board";
import {BoardClient} from "../../model/BoardClient";
import {makeBoardClientSelector} from "../../selectors/boardClient";
import BoardClientNotConfiguredMessage from "./BoardClientConfiguration/BoardClientNotConfiguredMessage";
import BoardClientTable from "./BoardClientConfiguration/BoardClientTable";

interface OwnProps {
    boardId: BoardModel.BoardId;
}

type BoardClientConfigurationProps = OwnProps & WithNamespaces;

const BoardClientConfiguration = (props: BoardClientConfigurationProps) => {

    const dispatch = useDispatch();
    const client: BoardClient|null|undefined = useSelector(makeBoardClientSelector(props.boardId));

    useEffect(() => {
        dispatch(fetchBoardClient(props.boardId));
    }, [props.boardId]);

    return (
        <div className="ui segments">
            <div className="ui segment">
                {client === undefined && (
                    <div className="ui active dimmer">
                        <div className="ui loader" />
                    </div>
                )}

                <h2 className="ui header">
                    {props.t('insp.board.client.headline')}
                    <HeaderSubHeader>{props.t('insp.board.client.description')}</HeaderSubHeader>
                </h2>
                <div>
                    {client === null && (
                        <BoardClientNotConfiguredMessage boardId={props.boardId} />
                    )}
                    {client && (
                        <BoardClientTable boardId={props.boardId} client={client} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(BoardClientConfiguration);
