import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Icon, Message, Modal} from "semantic-ui-react";

interface OwnProps {
    open: boolean;
    onClose: () => void;
}

type NewDomainModalProps = OwnProps & WithNamespaces;

const NewDomainModal = (props: NewDomainModalProps) => {
 return (<Modal open={ props.open} closeIcon={true} onClose={ props.onClose}>
     <Modal.Header>{  props.t("insp.hint.new_domain_header") }</Modal.Header>
     <Modal.Content>
         { <Message icon="bullhorn" info={true}
                content={
                    <div>
                        <p>{props.t('insp.hint.new_domain_text')}</p>
                        <p><Icon name="arrow right"/>&nbsp;<a href="https://github.com/event-engine/inspectio/wiki/Rebranding" target="_blank">{props.t('insp.hint.learn_more')}</a></p>
                    </div>
                } />}
     </Modal.Content>
 </Modal>);
};

export default withNamespaces()(NewDomainModal);
