import {withNamespaces} from "react-i18next";
import { connect } from 'react-redux';
import {withRouter} from "react-router";
import { compose, lifecycle } from 'recompose';
import {bindActionCreators, Dispatch} from "redux";
import {Command} from "../actions";
import {EditBoard, EditBoardProps} from "../components/EditBoard";
import {BoardSelector} from "../selectors";

const mapStateToProps = BoardSelector.makeMapStateToPropsEditBoard();

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        confirmDeleteBoard: Command.confirmDeleteBoard,
        deleteBoard: Command.deleteBoard,
        abortDeletion: Command.abortBoardDeletion,
        shareBoard: Command.shareBoard,
    },
    dispatch
);

export default withNamespaces()(withRouter<EditBoardProps, any>(compose<EditBoardProps, any>(
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({

    })
)(EditBoard)));
