import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {Team} from "../model";
import {makeTeamSelector} from "../selectors/selectTeam";

type Command = ActionType<typeof Action.Command.changeTeamDescription>

function* handleChangeTeamDescription(command: Command) {
    const orgTeam: Team.Team = yield select(makeTeamSelector(command.payload.teamId));

    const changedTeam = orgTeam.changeDescription(command.payload.newDescription);

    yield put(Action.Event.teamDescriptionChanged(changedTeam));

    const {response, error}: ResponseType = yield call(Action.Api.changeTeamDescription, command.payload.teamId, command.payload.newDescription);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not change team description.'));
        yield put(Action.Event.teamDescriptionChanged(orgTeam));
    }

    if(response) {
        yield put(NotifyAction.Command.info('Team Description Changed', 'Description saved successfully'));
    }
}

export function* changeTeamDescription() {
    while (true) {
        const command: Command = yield take([
            Action.Type.CHANGE_TEAM_DESCRIPTION
        ]);

        yield fork(handleChangeTeamDescription, command);
    }
}