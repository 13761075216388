import {List} from "immutable";
import {call, fork, put, select, take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import * as uuid from "uuid";
import * as Action from "../../InspectioBoards/actions";
import {useGraph} from "../../InspectioBoards/hooks/useGraph";
import {Board, deriveAbbreviationFromBoardName} from "../../InspectioBoards/model/Board";
import {BoardVersion} from "../../InspectioBoards/model/BoardVersion";
import {makeEmptyGraph} from "../../InspectioBoards/model/Graph";
import {TeamId} from "../../InspectioTeams/model/Team";
import {Action as NotifyAction} from "../../NotificationSystem";
import {UserSelector} from "../../User";
import {UserId, UserInfo} from "../../User/model/UserInfo";
import {standaloneStorageManager} from "../util";
import {onFetchUserBoards} from "./fetchUserBoardsFlow";
import {DEFAULT_PERMISSIONS} from "../../InspectioBoards/model/BoardPermissionForUser";

type FlowAction = ActionType<typeof Action.Command.importNewBoard>;

function* onImportNewBoard(action: FlowAction) {
    const boardId = action.payload.boardId || uuid.v4();

    if(!action.payload.importTemporary) {
        yield call(standaloneStorageManager.addBoard, {
            boardId,
            name: action.payload.boardName,
            lastModified: (new Date()).toISOString(),
            createdAt: (new Date()).toISOString(),
            xml: action.payload.boardXml,
            stickyTemplates: [],
        });

        yield put(NotifyAction.Command.info('Board created', 'Board created'));
        yield fork(onFetchUserBoards);
    } else {
        const getUser = UserSelector.makeGetCurrentUser();
        const user: UserInfo = yield select(getUser);

        const board = new Board({
            uid: boardId,
            name: action.payload.boardName,
            nameAbbreviation: deriveAbbreviationFromBoardName(action.payload.boardName),
            tags: List(),
            createdAt: (() => new Date())().toISOString(),
            xml: action.payload.boardXml,
            version: new BoardVersion({
                userId: user.uid,
                version: 1
            }),
            shared: false,
            sharedWith: List<UserId>(),
            assignedTeams: List<TeamId>(),
            owner: null,
            creator: user.uid,
            organization: null,
            tempBoard: true,
            orgaMemberPermissions: DEFAULT_PERMISSIONS,
            anyonePermissions: DEFAULT_PERMISSIONS,
            codySuggestEnabled: true,
            eventModelingEnabled: true,
        })
        yield put(Action.Command.updateBoards([board]));

        const [graph, setGraph] = useGraph();

        const emptyGraphWithXml = makeEmptyGraph(board.xml || undefined);

        setGraph(emptyGraphWithXml);

        yield put(Action.Command.saveBoard(board, emptyGraphWithXml));
    }
}

export function* standaloneImportNewBoardFlow() {
    while (true) {
        const action: FlowAction = yield take([Action.Type.IMPORT_NEW_BOARD]);

        yield fork(onImportNewBoard, action);
    }
}
