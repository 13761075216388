import {Record} from 'immutable';
import * as uuid from "uuid";
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {UserId} from "../../User/model/UserInfo";
import {OrganizationId} from "./Organization";

export const AGGREGATE_TYPE = 'UserOrganizationInfo';

export type UserOrganizationInfoError = string;

export interface UserOrganizationInfoProps {
    uid: OrganizationId;
    organizationId: OrganizationId;
    userId: UserId;
    organizationName: string;
    ownOrganization: boolean;
    explicitOrganization: boolean;
    userIsAdmin: boolean;
    userIsGuest: boolean;
    aggregateType: AggregateType;
}

export const defaultUserOrganizationInfoProps: UserOrganizationInfoProps = {
    uid: '',
    organizationId: '',
    userId: '',
    organizationName: '',
    ownOrganization: false,
    explicitOrganization: false,
    userIsAdmin: false,
    userIsGuest: true,
    aggregateType: AGGREGATE_TYPE,
};

export const createUserOrganizationInfoFromServerData = (data: any): Either<UserOrganizationInfoError, UserOrganizationInfo> => {
    const validatedData: Either<UserOrganizationInfoError, any> = validate(data,
        [hasKey('organizationId'), "organizationId missing"],
        [hasKey('userId'), "userId missing"],
        [hasKey('organizationName'), "organizationName missing"],
        [hasKey('ownOrganization'), "ownOrganization missing"],
        [hasKey('explicitOrganization'), "explicitOrganization missing"],
        [hasKey('userIsAdmin'), "userIsAdmin missing"],
        [hasKey('userIsGuest'), "userIsGuest missing"],
    );

    if (isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new UserOrganizationInfo({
        ...defaultUserOrganizationInfoProps,
        ...data,
        uid: data.organizationId,
    }));
};

export class UserOrganizationInfo extends Record(defaultUserOrganizationInfoProps) implements UserOrganizationInfoProps, Aggregate {
    public constructor(data: UserOrganizationInfoProps) {
        if (data.uid === '') {
            data.uid = uuid.v4()
        }

        super(data);
    }

    // @TODO add use case methods to change state
}
