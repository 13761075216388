import {call, put, select, take} from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ResponseType } from '../../api/util';
import { Action as NotifyAction } from '../../NotificationSystem/index';
import * as Action from '../actions/index';
import {loadUserTeams} from "./loadUserTeams";
import {makeTeamSelector} from "../selectors/selectTeam";

type FlowAction = ActionType<typeof Action.Command.inviteUserToTeam>;

export function* inviteUserToTeam() {

    while (true) {
        const action: FlowAction = yield take([
            Action.Type.INVITE_USER_TO_TEAM
        ]);

        const {response, error}: ResponseType = yield call(Action.Api.inviteUserToTeam, action.payload.invitationId, action.payload.teamId, action.payload.userEmail);

        const team = yield select(makeTeamSelector(action.payload.teamId));

        if (error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not add user to team.'));
        }
        if (response) {
            yield put(NotifyAction.Command.info('User invited', 'The user was invited to the team.'));

            yield call(loadUserTeams, team.isNew? team.uid : undefined);
        }
    }
}
