import {fromJS} from "immutable";
import { combineReducers } from 'redux-immutable';
import {ActionType} from "typesafe-actions";
import {Action as TeamAction} from "../../InspectioTeams";
import * as Commands from "../actions/commands";
import * as Events from "../actions/events";
import * as Queries from "../actions/queries";
import {ActiveGraphElementState, saveActiveGraphElementReducer} from "./applyActiveGraphElementActions";
import {BoardsState, saveBoardReducer} from "./applyBoardActions";
import {BoardClientsState, saveBoardClientsReducer} from "./applyBoardClientActions";
import {BoardGraphElementsState, saveBoardGraphElementsReducer} from "./applyBoardGraphElementActions";
import {BoardInvitationState, saveBoardInvitationReducer} from "./applyBoardInvitationActions";
import {
    BoardPermissionsForTeamsState,
    saveBoardPermissionsForTeamsReducer
} from "./applyBoardPermissionsForTeamsActions";
import {
    BoardPermissionsForUsersState,
    saveBoardPermissionsForUsersReducer
} from "./applyBoardPermissionsForUsersActions";
import {elementsTreeReducer, ElementsTreeState} from "./applyElementsTreeActions";
import {initialBoardListLoadedReducer} from "./applyInitialBoardListLoaded";
import {loadBoardsReducer} from "./applyLoadingBoards";
import {boardOverviewReducer, BoardOverviewState} from "./applyOverviewActions";
import {BoardHistoryState, manageBoardHistory} from "./manageBoardHistory";
import {RunCodyOption, saveRunCodyOnReducer} from "./RunCodyOn";
import {saveStickyTemplateReducer, StickyTemplateState} from "./StickyTemplate";
import {PlayshotState, savePlayshotReducer} from "./applyPlayshotEvents";

export type InspectioBoardsAction = ActionType<typeof Commands & typeof Events  & typeof Queries & typeof TeamAction.Event>;

export interface InspectioBoardsState {
    boards: BoardsState,
    boardPermissionsForUsers: BoardPermissionsForUsersState,
    boardPermissionsForTeams: BoardPermissionsForTeamsState,
    boardInvitations: BoardInvitationState,
    boardClients: BoardClientsState,
    boardGraphElements: BoardGraphElementsState,
    history: BoardHistoryState,
    loadingBoards: boolean,
    initialBoardListLoaded: boolean,
    overview: BoardOverviewState,
    elementsTree: ElementsTreeState,
    activeGraphElements: ActiveGraphElementState,
    StickyTemplate: StickyTemplateState,
    runCodyOn: RunCodyOption,
    playshots: PlayshotState,
}

export const inspectioBoardsReducer = combineReducers<InspectioBoardsState, InspectioBoardsAction>({
    boards: saveBoardReducer,
    boardPermissionsForUsers: saveBoardPermissionsForUsersReducer,
    boardPermissionsForTeams: saveBoardPermissionsForTeamsReducer,
    boardInvitations: saveBoardInvitationReducer,
    boardClients: saveBoardClientsReducer,
    boardGraphElements: saveBoardGraphElementsReducer,
    history: manageBoardHistory,
    loadingBoards:loadBoardsReducer,
    initialBoardListLoaded: initialBoardListLoadedReducer,
    overview: boardOverviewReducer,
    elementsTree: elementsTreeReducer,
    activeGraphElements: saveActiveGraphElementReducer,
    StickyTemplate: saveStickyTemplateReducer,
    runCodyOn: saveRunCodyOnReducer,
    playshots: savePlayshotReducer,
}, () => fromJS({
    boards: undefined,
    StickyTemplate: undefined,
    runCodyOn: 'auto',
    playshots: undefined
}));
