import {List, Map, Record} from "immutable";
import {createSelector} from "reselect";
import {ApplicationState} from "../../reducer";
import * as User from "../../User";
import {MODULE} from "../index";
import {Organization, OrganizationId} from "../model/Organization";

export const getOrganizationById = (state: Record<ApplicationState>, uid: OrganizationId): Organization|undefined =>
    state.getIn([MODULE, 'Organization', uid]);

export const makeOrganizationSelector = (organizationId: OrganizationId | null): (state: Record<ApplicationState>) => Organization|undefined =>
    (state: Record<ApplicationState>) => organizationId ? getOrganizationById(state, organizationId) : undefined;

export const getOrganizationList = (state: Record<ApplicationState>): List<Organization> => state.getIn([MODULE, 'Organization']).toList();

export const makeOrganizationListSelector = (): (state: Record<ApplicationState>) => List<Organization> =>
    (state: Record<ApplicationState>) => getOrganizationList(state);

export const makeActiveOrganizationSelector = (): (state: Record<ApplicationState>, props?: any) => Organization | undefined => {
    return createSelector(
        [getOrganizationList, User.UserSelector.currentUserSelector],
        (organizations, currentUser): Organization | undefined => {
            if(currentUser.activeOrganization === null) {
                return;
            }

            return organizations.find(orga => orga.uid === currentUser.activeOrganization);
        }
    )
}
