import {Node, NodeType} from "../../../model/Graph";

export const isValid = (node: Node): boolean => {
  switch (node.getType()) {
    case NodeType.event:
      return isValidEvent(node);
    default:
      return true;
  }
}

const isValidEvent = (node: Node): boolean => {
  const sources = node.getSources();

  if(sources.count() === 0) {
    return true;
  }

  if(sources.count() !== 1) {
    return false;
  }

  const source = sources.toArray()[0];

  return source.getType() === NodeType.aggregate || source.getType() === NodeType.command;
}
