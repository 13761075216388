import {List} from "immutable";
import {WizardStep} from "../../../components/CodyEngineWizard/WizardModal";
import {Board} from "../../../model/Board";
import {Graph, Node, NodeType} from "../../../model/Graph";
import {getAggregateMetadata} from "../aggregate/get-aggregate-metadata";
import {getCommandMetadata} from "../command/get-command-metadata";
import {
  getSingleSourceOfType,
  getSingleTargetOfType,
  getSourcesOfType,
  getTargetsOfType
} from "../node/get-single-node-of-type";
import {Rule} from "../rule-engine/configuration";
import {RulesDependency, RulesDependencyMetadata} from "../rule-engine/types";
import {getVoMetadata} from "../vo/get-vo-metadata";
import {isStateVoCandidate} from "../vo/is-state-vo-candidate";
import {
  suggestAggregate,
  suggestCommand,
  suggestDDDActionUi,
  suggestEvent,
  suggestFeature,
  suggestState,
  suggestStateList
} from "./ddd-action/cody-suggestions";
import {isEventModel} from "./feature-context";
import {WithUiNode} from "./ui";
import {WizardContext} from "./wizard-context";

export const isDDDAction = (context: WizardContext): context is ImmutableDDDWizardContext => {
  return context.type === 'dddAction';
}

interface DDDWizardContext extends WizardContext<ImmutableDDDWizardContext>, WithUiNode {
  command: Node | null;
  commandDependencies: Readonly<Record<string, Readonly<RulesDependencyMetadata>>>;
  commandHandler: Rule[];
  aggregate: Node | null;
  events: List<Node>;
  state: Node | null;
  stateList: Node | null;
  feature: Node | null;
  eventModel: Node | null;
  sliceApiLabel: Node | null;
  previousStep: (currentStep: WizardStep, ctx: ImmutableDDDWizardContext) => WizardStep | null;
  nextStep: (currentStep: WizardStep, ctx: ImmutableDDDWizardContext) => WizardStep | null;
  installCodyGraphSuggestions: (currentStep: WizardStep, ctx: ImmutableDDDWizardContext, graph: Graph) => void;
}

export type ImmutableDDDWizardContext = Readonly<DDDWizardContext>;

export const dddActionFromNode = (node: Node, board: Board): ImmutableDDDWizardContext => {
  const ctx: DDDWizardContext = {
    board,
    type: "dddAction",
    ui: null,
    parentUi: null,
    uiViews: List<Node>([]),
    command: null,
    commandDependencies: {},
    commandHandler: [],
    aggregate: null,
    events: List<Node>([]),
    state: null,
    stateList: null,
    feature: null,
    sliceApiLabel: null,
    eventModel: null,
    previousStep: determinePreviousStep,
    nextStep: determineNextStep,
    installCodyGraphSuggestions: installSuggestions,
  }

  switch (node.getType()) {
    case NodeType.feature:
      const validTypes = [NodeType.ui, NodeType.command, NodeType.aggregate, NodeType.event, NodeType.externalSystem, NodeType.document];

      const children = node.children().filter(c => validTypes.includes(c.getType()));

      if(children.count() > 0) {
        return dddActionFromNode(children.toArray()[0], board);
      }

      ctx.feature = node;
      break;
    case NodeType.ui:
      fillFromUI(node, ctx);
      break;
    case NodeType.command:
      fillFromCommand(node, ctx);
      break;
    case NodeType.aggregate:
      fillFromAggregate(node, ctx);
      break;
    case NodeType.event:
      fillFromEvent(node, ctx);
      break;
    case NodeType.externalSystem:
      const eSaR = getSingleTargetOfType(node, NodeType.aggregate);

      if(eSaR) {
        fillFromAggregate(eSaR, ctx);
      }
      break;
    case NodeType.document:
      if(isStateVoCandidate(node)) {
        fillFromStateVoCandidate(node, ctx);

        const event = getSingleSourceOfType(node, NodeType.event, true);
        if(event) {
          fillFromEvent(event, ctx);
          break;
        }
      }
      const aggregate = getSingleTargetOfType(node, NodeType.aggregate);

      if(aggregate) {
        fillFromAggregate(aggregate, ctx);
        break;
      }

      const ui = getSingleTargetOfType(node, NodeType.ui);

      if(ui) {
        fillFromUI(ui, ctx);
      }

      break;
  }

  return ctx;
}

const determinePreviousStep = (currentStep: WizardStep, ctx: ImmutableDDDWizardContext): WizardStep | null => {
  switch (currentStep) {
    case "feature":
      return null;
    case "ui":
      return "feature";
    case "command":
      return 'ui';
    case "aggregate":
      return 'command';
    case "dddEvent":
      return (ctx.eventModel)? "command" : "aggregate";
    case "state":
      return "dddEvent";
    default:
      return null;
  }
}

const determineNextStep = (currentStep: WizardStep, ctx: ImmutableDDDWizardContext): WizardStep | null => {
  switch (currentStep) {
    case "feature":
      return "ui";
    case "ui":
      return "command";
    case "command":
      return (ctx.eventModel)? 'dddEvent' : 'aggregate';
    case "aggregate":
      return 'dddEvent';
    case "dddEvent":
      return "state";
    case "state":
      return null;
    default:
      return null;
  }
}

const installSuggestions = (currentStep: WizardStep, ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  const prevStep = determinePreviousStep(currentStep, ctx);
  const nextStep = determineNextStep(currentStep, ctx);

  if(prevStep) {
    installUpSuggestion(prevStep, ctx, graph);
  }

  if(nextStep) {
    installDownSuggestion(nextStep, ctx, graph);
  } else if (currentStep === 'state') {
    installDownSuggestion('anyVO', ctx, graph);
  }
}

const installUpSuggestion = (prevStep: WizardStep, ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  switch (prevStep) {
    case "feature":
      if(ctx.feature) {
        return;
      }
      graph.setCodyUpCb(() => suggestFeature(ctx, graph));
      break;
    case "ui":
      if(ctx.ui) {
        return;
      }
      graph.setCodyUpCb(() => suggestDDDActionUi(ctx, graph));
      break;
    case "command":
      if(ctx.command) {
        return;
      }
      graph.setCodyUpCb(() => suggestCommand(ctx, graph));
      break;
    case "aggregate":
      if(ctx.aggregate) {
        return;
      }
      graph.setCodyUpCb(() => suggestAggregate(ctx, graph));
      break;
    case "dddEvent":
      if(ctx.events.count() > 0) {
        return;
      }
      graph.setCodyUpCb(() => suggestEvent(ctx, graph));
      break;
  }
}

const installDownSuggestion = (nextStep: WizardStep, ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  switch (nextStep) {
    case "command":
      if(ctx.command) {
        return;
      }
      graph.setCodyDownCb(() => suggestCommand(ctx, graph));
      break;
    case "aggregate":
      if(ctx.aggregate) {
        return;
      }
      graph.setCodyDownCb(() => suggestAggregate(ctx, graph));
      break;
    case "dddEvent":
      if(ctx.events.count() > 0) {
        return;
      }
      graph.setCodyDownCb(() => suggestEvent(ctx, graph));
      break;
    case "state":
      if(ctx.state) {
        return;
      }
      if(graph.isEventModelingEnabled()) {
        graph.setCodyRightCb(() => suggestState(ctx, graph));
      } else {
        graph.setCodyDownCb(() => suggestState(ctx, graph));
      }

      break;
    case "anyVO":
      if(ctx.stateList) {
        return;
      }

      if(graph.isEventModelingEnabled()) {
        graph.setCodyRightCb(() => suggestStateList(ctx, graph));
      } else {
        graph.setCodyDownCb(() => suggestStateList(ctx, graph));
      }
      break;
  }
}

const fillFromEvent = (node: Node, ctx: DDDWizardContext) => {
  if(ctx.events.map(e => e.getId()).includes(node.getId())) {
    return;
  }

  ctx.events = ctx.events.push(node);

  fillFeatureFromNode(node, ctx);
  fillEventModelFromNode(node, ctx);

  const aggregate = getSingleSourceOfType(node, NodeType.aggregate);

  if(aggregate && !ctx.aggregate) {
    fillFromAggregate(aggregate, ctx);
  }

  const command = getSingleSourceOfType(node, NodeType.command);

  if(command && !ctx.command) {
    fillFromCommand(command, ctx);
  }

  if(!ctx.state) {
    const vos = getTargetsOfType(node, NodeType.document);

    for (const vo of vos) {
      if(isStateVoCandidate(vo)) {
        fillFromStateVoCandidate(vo, ctx);
        return;
      }
    }
  }
}

const isParentUi = (node: Node, ctx: DDDWizardContext): boolean => {
  if(node.getType() !== NodeType.ui) {
    return false;
  }

  const children = getTargetsOfType(node, NodeType.ui);

  return children.length > 0;
}

const fillFromUI = (node: Node, ctx: DDDWizardContext) => {
  if(isParentUi(node, ctx)) {
    const childUi = getTargetsOfType(node, NodeType.ui)[0];
    if(childUi) {
      fillFromUI(childUi, ctx);
      return;
    }
  }

  if(ctx.ui) {
    return;
  }

  ctx.ui = node;

  ctx.uiViews = List(getSourcesOfType(node, NodeType.document, true));

  if(!ctx.command) {
    const command = getSingleTargetOfType(node, NodeType.command, false, true);

    if(command) {
      fillFromCommand(command, ctx);
    }
  }

  if(!ctx.parentUi) {
    const parent = getSingleSourceOfType(node, NodeType.ui, true);

    if(parent) {
      ctx.parentUi = parent;
    }
  }

  fillFeatureFromNode(node, ctx);
  fillEventModelFromNode(node, ctx);
}

const fillFromCommand = (node: Node, ctx: DDDWizardContext) => {
  if(ctx.command) {
    return;
  }

  ctx.command = node;

  const commandMetadata = getCommandMetadata(node);

  ctx.commandDependencies = commandMetadata.dependencies || {};
  ctx.commandHandler = commandMetadata.rules || [];

  fillFeatureFromNode(node, ctx);
  fillEventModelFromNode(node, ctx);

  if(!ctx.ui) {
    const ui = getSingleSourceOfType(node, NodeType.ui);

    if(ui) {
      fillFromUI(ui, ctx);
    }
  }

  if(commandMetadata.aggregateCommand && !ctx.aggregate) {
    const aggregate = getSingleTargetOfType(node, NodeType.aggregate);

    if(aggregate) {
      fillFromAggregate(aggregate, ctx);
    }
  }

  getTargetsOfType(node, NodeType.event).forEach(evt => fillFromEvent(evt, ctx));
}

const fillFromAggregate = (node: Node, ctx: DDDWizardContext) => {
  if(ctx.aggregate) {
    return;
  }

  ctx.aggregate = node;

  fillFeatureFromNode(node, ctx);
  fillEventModelFromNode(node, ctx);

  if(!ctx.command) {
    const command = getSingleSourceOfType(node, NodeType.command);

    if(command) {
      fillFromCommand(command, ctx);
    }
  }

  getTargetsOfType(node, NodeType.event).forEach(evt => fillFromEvent(evt, ctx));

  const commandMeta = (ctx.command)? getCommandMetadata(ctx.command) : {dependencies: {}, rules: []};
  const aggregateMeta = getAggregateMetadata(node);
  ctx.commandDependencies = commandMeta.dependencies || {};
  ctx.commandHandler = aggregateMeta.rules || commandMeta.rules || [];
}

const fillFromStateVoCandidate = (node: Node, ctx: DDDWizardContext) => {
  if(ctx.state) {
    return;
  }

  ctx.state = node;

  fillFeatureFromNode(node, ctx);
  fillEventModelFromNode(node, ctx);

  node.getTargets().filter(t => t.getType() === NodeType.document).forEach(t => {
    const tMeta = getVoMetadata(t);

    if(tMeta.schema && tMeta.schema.isList()) {
      ctx.stateList = t;
    }
  })

  getSourcesOfType(node, NodeType.event).forEach(evt => fillFromEvent(evt, ctx));
}

const fillFeatureFromNode = (node: Node, ctx: DDDWizardContext) => {
  if(ctx.feature) {
    return;
  }

  const parent = node.getParent();

  if(parent && parent.getType() === NodeType.feature) {
    ctx.feature = parent;

    if(ctx.board.eventModelingEnabled) {
      ctx.feature.children().forEach(child => {
        if(child.getTags().contains(ispConst.TAG_SLICE_API_LABEL)) {
          ctx.sliceApiLabel = child;
        }
      })
    }
  }
}

const fillEventModelFromNode = (node: Node, ctx: DDDWizardContext) => {
  if(ctx.eventModel) {
    return;
  }

  let parent = node.getParent();
  let children = List<Node>();

  if(parent) {
    children = parent.children();
  }

  if(parent && parent.getType() === NodeType.feature) {
    parent = parent.getParent();
  }

  if(parent && parent.getType() === NodeType.boundedContext && parent.getTags().contains(ispConst.TAG_EVENT_MODEL)) {
    ctx.eventModel = parent;

    if(ctx.board.eventModelingEnabled) {
      children.forEach(child => {
        if(child.getTags().contains(ispConst.TAG_SLICE_API_LABEL)) {
          ctx.sliceApiLabel = child;
        }
      })
    }
  }
}
