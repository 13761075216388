import {Node} from "../../../model/Graph";
import {Schema} from "./schema";
import {SchemaFromNodeDescriptionParser} from "./schema-from-node-description-parser";

export const getSchemaFromNodeDescription = (node: Node): Schema => {
  const desc = node.getDescription();

  if(desc === '') {
    return new Schema({});
  }

  const parser = new SchemaFromNodeDescriptionParser(desc);

  return parser.parse();
}
