import {List} from "immutable";
import {WizardStep} from "../../../components/CodyEngineWizard/WizardModal";
import {Board} from "../../../model/Board";
import {Graph, Node, NodeType} from "../../../model/Graph";
import {getPolicyMetadata} from "../policy/get-policy-metadata";
import {RulesDependencyMetadata} from "../rule-engine/types";
import {suggestFeature, suggestPolicy, suggestPolicyEvent} from "./policy-context/cody-suggestions";
import {WizardContext} from "./wizard-context";

export interface WithPolicyNode {
  feature: Node | null;
  eventModel: Node | null;
  event: Node | null;
  policy: Node | null;
  sliceApiLabel: Node | null;
  policyDependencies: Readonly<Record<string, Readonly<RulesDependencyMetadata>>>;
  policyTargets: List<Node>;
}

export type ImmutablePolicyContext = Readonly<PolicyContext>;

interface PolicyContext extends WizardContext<ImmutablePolicyContext>, WithPolicyNode {}



export const policyContextFromNode = (node: Node, board: Board): ImmutablePolicyContext => {
  switch (node.getType()) {
    case NodeType.event:
      return fillFromSourceEvent(node, board);
    case NodeType.policy:
      return fillFromPolicy(node, board);
    case NodeType.externalSystem:
    case NodeType.command:
      return fillFromTarget(node, board);
    case NodeType.feature:
      return fillFromFeature(node, board);
    default:
      throw new Error(`Policy context cannot be created from this node type: ${node.getType()}. This seems to be a developer bug. Please contact the prooph board team!`);
  }
}

export const isPolicyContext = (ctx: WizardContext): ctx is ImmutablePolicyContext => {
  return ctx.type === 'policy';
}

export const isPartOfPolicyContext = (node: Node): boolean => {
  switch (node.getType()) {
    case NodeType.policy:
      return true;
    case NodeType.event:
      return node.getTargets().filter(t => t.getType() === NodeType.policy).count() > 0;
    case NodeType.command:
    case NodeType.externalSystem:
      return node.getSources().filter(s => s.getType() === NodeType.policy).count() > 0;
    case NodeType.feature:
      return node.children().filter(c => c.getType() === NodeType.policy).count() > 0;
    default:
      return false;
  }
}

const fillFromPolicy = (policy: Node, board: Board): ImmutablePolicyContext => {
  const event = policy.getSources().filter(s => s.getType() === NodeType.event).first(null);
  const policyTargets = policy.getTargets().filter(t => t.getType() === NodeType.command || t.getType() === NodeType.externalSystem);

  const policyMeta = getPolicyMetadata(policy);

  const tmpCtx = {
    board,
    type: "policy",
    policy,
    event,
    policyDependencies: {},
    policyTargets,
    nextStep: determineNextStep,
    previousStep: determinePreviousStep,
    installCodyGraphSuggestions: installSuggestions,
  } as WizardContext<PolicyContext>;

  const eventModel = getEventModelFromNode(policy);
  let sliceApiLabel: Node | null = null;

  if(eventModel && board.eventModelingEnabled) {
    eventModel.children().forEach(child => {
      if(child.getTags().contains(ispConst.TAG_SLICE_API_LABEL)) {
        sliceApiLabel = child;
      }
    })
  }

  return {
    board,
    type: "policy",
    feature: getFeatureFromNode(policy),
    eventModel,
    policy,
    event,
    policyDependencies: policyMeta.dependencies || {},
    policyTargets,
    sliceApiLabel,
    nextStep: determineNextStep,
    previousStep: determinePreviousStep,
    installCodyGraphSuggestions: installSuggestions,
  }
}

const installSuggestions = (currentStep: WizardStep, ctx: ImmutablePolicyContext, graph: Graph): void => {
  const prevStep = determinePreviousStep(currentStep, ctx);
  const nextStep = determineNextStep(currentStep, ctx);

  if(prevStep) {
    installLeftSuggestions(prevStep, ctx, graph);
  }

  if(nextStep) {
    installRightSuggestions(nextStep, ctx, graph);
  }
}

const installLeftSuggestions = (prevStep: WizardStep, ctx: ImmutablePolicyContext, graph: Graph): void => {
  switch (prevStep) {
    case "policy":
      if(ctx.policy) {
        return;
      }
      graph.setCodyLeftCb(() => suggestPolicy(ctx, graph));
      break;
    case "policyEvent":
      if(ctx.event) {
        return;
      }
      graph.setCodyLeftCb(() => suggestPolicyEvent(ctx, graph));
      break;
    case "feature":
      if(ctx.feature || graph.isEventModelingEnabled()) {
        return;
      }
      graph.setCodyLeftCb(() => suggestFeature(ctx, graph));
      break;
  }
}

const installRightSuggestions = (nextStep: WizardStep, ctx: ImmutablePolicyContext, graph: Graph): void => {
  switch (nextStep) {
    case "policy":
      if(ctx.policy) {
        return;
      }
      graph.setCodyRightCb(() => suggestPolicy(ctx, graph));
      break;
  }
}

const determinePreviousStep = (currentStep: WizardStep, ctx: ImmutablePolicyContext): WizardStep | null => {
  switch (currentStep) {
    case "command":
    case "externalSystem":
      return 'policy';
    case "policy":
      return "policyEvent";
    case "policyEvent":
      return "feature";
    default:
      return null;
  }
}

const determineNextStep = (currentStep: WizardStep, ctx: ImmutablePolicyContext): WizardStep | null => {
  switch (currentStep) {
    case "feature":
      return "policyEvent";
    case "policyEvent":
      return "policy";
    default:
      return null;
  }
}

const fillFromSourceEvent = (event: Node, board: Board): ImmutablePolicyContext => {
  const policy = event.getTargets().filter(t => t.getType() === NodeType.policy).first(undefined);

  if(policy) {
    return fillFromPolicy(policy, board);
  }

  const feature = getFeatureFromNode(event);
  const eventModel = getEventModelFromNode(event);
  let sliceApiLabel: Node | null = null;

  if(eventModel && board.eventModelingEnabled) {
    eventModel.children().forEach(child => {
      if(child.getTags().contains(ispConst.TAG_SLICE_API_LABEL)) {
        sliceApiLabel = child;
      }
    })
  }

  return {
    board,
    type: "policy",
    policy: null,
    feature,
    eventModel,
    event,
    policyDependencies: {},
    policyTargets: List([]),
    sliceApiLabel,
    nextStep: determineNextStep,
    previousStep: determinePreviousStep,
    installCodyGraphSuggestions: installSuggestions,
  }
}

const fillFromTarget = (node: Node, board: Board): ImmutablePolicyContext => {
  const policy = node.getSources().filter(s => s.getType() === NodeType.policy).first(undefined);

  if(policy) {
    return fillFromPolicy(policy, board);
  }

  throw new Error(`Cannot find policy source of ${node.getType()}: ${node.getName()}. Please connect a plicy with the card`);
}

const fillFromFeature = (feature: Node, board: Board): ImmutablePolicyContext => {
  const matchingNode = feature.children().filter(c => c.getType() === NodeType.policy || c.getType() === NodeType.event).first(undefined);

  if(matchingNode) {
    return policyContextFromNode(matchingNode, board);
  }

  const eventModel = getEventModelFromNode(feature);

  let sliceApiLabel: Node | null = null;

  if(eventModel && board.eventModelingEnabled) {
    eventModel.children().forEach(child => {
      if(child.getTags().contains(ispConst.TAG_SLICE_API_LABEL)) {
        sliceApiLabel = child;
      }
    })
  }

  return {
    board,
    type: "policy",
    policy: null,
    feature,
    eventModel,
    event: null,
    policyDependencies: {},
    policyTargets: List([]),
    sliceApiLabel,
    nextStep: determineNextStep,
    previousStep: determinePreviousStep,
    installCodyGraphSuggestions: installSuggestions,
  }
}

const getFeatureFromNode = (node: Node): Node | null => {
  const parent = node.getParent();

  if(parent && parent.getType() === NodeType.feature) {
    return parent;
  }

  return null;
}

const getEventModelFromNode = (node: Node): Node | null => {
  let parent = node.getParent();

  if(parent && parent.getType() === NodeType.feature) {
    parent = parent.getParent();
  }

  if(parent && parent.getType() === NodeType.boundedContext && parent.getTags().contains(ispConst.TAG_EVENT_MODEL)) {
    return parent;
  }

  return null;
}
