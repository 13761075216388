import {BoardModel} from "../../InspectioBoards/index";
import {UserModel} from "../../User/index";
import {BoardTimerListener, MouseSync} from "../hooks/useMouseSync";
import {BoardTimer, BoardTimerProps, createBoardTimerFromServerData} from "../model/BoardTimer";
import ConfiguredSocket from '../../api/ConfiguredSocket';

export const MOUSE_MOVE_CHAN = 'MouseMove';
export const MOUSE_SCROLL_CHAN = 'MouseScroll';
export const MOUSE_JOINED_CHAN = 'MouseJoined';
export const MOUSE_LEFT_CHAN = 'MouseLeft';
export const GET_CURRENT_MOUSES = 'GetCurrentMouses';
export const GESTURE_CHANGED_CHAN = 'GestureChanged';
export const AUTO_SCROLL_CHAN = 'AutoScrolling';

export const BOARD_TIMER_STARTED_CHAN = 'BoardTimerStarted';
export const BOARD_TIMER_PAUSED_CHAN = 'BoardTimerPaused';
export const BOARD_TIMER_STOPPED_CHAN = 'BoardTimerStopped';
export const GET_ACTIVE_BOARD_TIMER = 'GetActiveBoardTimer';

export interface GraphPoint {
    x: number;
    y: number;
}

export enum Gesture {
    Default = 'Default',
    Moving = 'Moving',
    Typing = 'Typing',
}


export interface UserMouseMove {
    userId: UserModel.UserId;
    boardId: BoardModel.BoardId;
    point: GraphPoint;
    vT: GraphPoint;
    scale: number;
    isPanning: boolean;
}

export interface UserMouseScroll {
    userId: UserModel.UserId;
    boardId: BoardModel.BoardId;
    vT: GraphPoint;
    scale: number;
}

export interface UserMouseJoined {
    userId: UserModel.UserId;
    boardId: BoardModel.BoardId;
    name: UserModel.DisplayName;
    avatar: UserModel.AvatarUrl;
}

export interface UserMouseLeft {
    userId: UserModel.UserId;
    boardId: BoardModel.BoardId;
}

export interface GestureChanged {
    userId: UserModel.UserId;
    boardId: BoardModel.BoardId;
    gesture: Gesture;
}

export interface AutoScrollEvent {
    userId: UserModel.UserId;
    boardId: BoardModel.BoardId;
    scale: number;
    translate: GraphPoint;
    isPanning: boolean;
}

export interface MouseDelta {
    dx: number;
    dy: number;
    startX: number;
    startY: number;
}

export interface ActiveBoardTimerResponse {
    timer: BoardTimerProps | null,
    isPaused: boolean,
}

export type UserJoinedListener = (userId: UserModel.UserId, name: UserModel.DisplayName, avatar: UserModel.AvatarUrl, color: Color) => void;
export type UserLeftListener = (userId: UserModel.UserId) => void;

type ConfiguredSocket = typeof ConfiguredSocket;

export type Color = string;

const ownColor = '#423fbc';

const colors = [
    '#15A2B0',
    '#715671',
    '#CF3241',
    '#ED6842',
    '#EECA51',
    '#1F8A6D',
    '#dd3cd3',
    '#1114dd',
    '#7add3a',
    '#dd8794',
    '#b16fdd',
    '#9fa0bb',
    '#bad63c',
    '#7886dd',
    '#8510dd',
    '#dd796d',
];

let nextPointerColor = 0;

const makeNewPointer = (userName: UserModel.DisplayName): [HTMLElement, HTMLElement, Color] => {

    const color = colors[nextPointerColor];
    nextPointerColor++;

    if(nextPointerColor === colors.length) {
        nextPointerColor = 0;
    }

    const pointerDiv = document.createElement('div');
    pointerDiv.className = 'geRemoteMouse';
    pointerDiv.style.position = 'absolute';
    pointerDiv.style.display = 'none';

    const pointerP = document.createElement('p');

    const pointer = document.createElement('i');
    pointer.className = getPointerIconFromGesture(Gesture.Default) + ' icon';
    pointer.style.color = color;
    pointerP.appendChild(pointer)
    pointerDiv.appendChild(pointerP);

    const labelP = document.createElement('p');
    labelP.className = 'geMouseLabel';
    labelP.innerHTML = userName;
    labelP.style.color = color;
    pointerDiv.appendChild(labelP);
    return [pointerDiv, pointer, color];
};

const getPointerIconFromGesture = (gesture: Gesture): string => {
    switch (gesture) {
        case Gesture.Moving:
            return 'hand rock';
        case Gesture.Typing:
            return 'keyboard';
        default:
            return 'user';
    }
};

export class RemoteMouseSync implements MouseSync {
    private socket: ConfiguredSocket;
    private container: HTMLElement;
    private registry: {[key: string]: {userName: UserModel.Username, pointerEl: HTMLElement, pointerIcon: HTMLElement, color: Color, scale: number, translate: GraphPoint, lastKnownAbsolutePoint: GraphPoint}} = {};
    private translateGraphPoint: (point: GraphPoint) => GraphPoint;
    private graph: any;
    private userJoinedListeners: UserJoinedListener[];
    private userLeftListeners: UserLeftListener[];
    private observedUser: UserModel.UserId | null;
    private observingFrame: HTMLElement | null;
    private lastMoveWasPanning: boolean;

    private boardTimerStartedListeners: BoardTimerListener[];
    private boardTimerPausedListeners: BoardTimerListener[];
    private boardTimerStoppedListeners: BoardTimerListener[];
    private activeBoardTimer: BoardTimer | undefined;
    private isBoardTimerPaused: boolean = false;

    private disconnectListener: any = null;
    private reconnectListener: any = null;

    private syncIntervalListener: any = null;

    public constructor(
        socket: ConfiguredSocket,
        container: HTMLElement,
        graph: any,
        translateGraphPoint: (point: GraphPoint) => GraphPoint
    ) {
        this.socket = socket;
        this.container = container;
        this.translateGraphPoint = translateGraphPoint;
        this.graph = graph;
        this.userJoinedListeners = [];
        this.userLeftListeners = [];
        this.boardTimerStartedListeners = [];
        this.boardTimerPausedListeners = [];
        this.boardTimerStoppedListeners = [];
        this.observedUser = null;
        this.observingFrame = null;
        this.lastMoveWasPanning = false;
    }

    public startSync(joined: UserMouseJoined) {
        this.socket.on(MOUSE_JOINED_CHAN, (evt: UserMouseJoined) => {
            console.log("[mousesync]: Start tracking of remote user: ", evt.userId);
            this.trackMouseOfRemoteUser(evt.userId, evt.name, evt.avatar);
        });

        this.socket.on(MOUSE_LEFT_CHAN, (evt: UserMouseLeft) => {
            console.log("[mousesync]: Stop tracking of remote user: ", evt.userId);
            this.stopMouseTrackingOfUser(evt.userId);

            // Verify that mouse really left chan and did not have network issues
            this.socket.emit(
                GET_CURRENT_MOUSES,
                joined,
                (evts: UserMouseJoined[]) => evts.forEach(joinedEvt => {
                    if(!this.registry.hasOwnProperty(joinedEvt.userId)) {
                        this.trackMouseOfRemoteUser(joinedEvt.userId, joinedEvt.name, joinedEvt.avatar)
                    }
                }));
        });

        this.socket.on(MOUSE_MOVE_CHAN, (move: UserMouseMove) => {
            const {userId, point, vT, scale} = move;

            if(this.registry.hasOwnProperty(userId)) {
                const {pointerEl} = this.registry[userId];

                pointerEl.style.display = 'block';

                if(this.observedUser === userId) {
                    this.graph.view.setScale(scale);
                    this.graph.panGraph(move.vT.x, move.vT.y);
                    this.graph.panningListeners.forEach((l: (translate: typeof move.vT) => void) => l(move.vT));
                }

                if(move.isPanning && this.observedUser === userId) {
                    const absolutePoint = this.translateGraphPoint(point);
                    pointerEl.style.left = absolutePoint.x + "px";
                    pointerEl.style.top = absolutePoint.y + "px";
                    this.registry[userId].lastKnownAbsolutePoint = absolutePoint;
                }

                if(!move.isPanning) {
                    const absolutePoint = this.translateGraphPoint(point);
                    pointerEl.style.left = absolutePoint.x + "px";
                    pointerEl.style.top = absolutePoint.y + "px";
                    this.registry[userId].lastKnownAbsolutePoint = absolutePoint;
                }

                this.registry[userId].translate = vT;
                this.registry[userId].scale = scale;
            }
        });

        this.socket.on(MOUSE_SCROLL_CHAN, (scroll: UserMouseScroll) => {
            const {userId, scale, vT} = scroll;

            if(this.registry.hasOwnProperty(userId)) {
                if(this.observedUser === userId) {
                    this.graph.view.setScale(scale);
                    this.graph.panGraph(vT.x, vT.y);
                    this.graph.triggerZoomListeners();
                }

                this.registry[userId].translate = vT;
                this.registry[userId].scale = scale;
            }
        });

        this.socket.on(GESTURE_CHANGED_CHAN, (gestureChanged: GestureChanged) => {
            const {userId, gesture} = gestureChanged;

            if(this.registry.hasOwnProperty(userId)) {
                const {pointerIcon} = this.registry[userId];
                pointerIcon.className = getPointerIconFromGesture(gestureChanged.gesture) + ' icon';
            }
        });

        this.socket.on(AUTO_SCROLL_CHAN, (autoScrollEvent: AutoScrollEvent) => {
            const {userId, translate, scale, isPanning} = autoScrollEvent;

            if(this.registry.hasOwnProperty(userId)) {
                if(this.observedUser === userId) {
                    this.graph.view.setScale(scale);
                    this.graph.panGraph(translate.x, translate.y);
                }

                this.registry[userId].translate = translate;
                this.registry[userId].scale = scale;
            }
        });

        this.socket.on(BOARD_TIMER_STARTED_CHAN, (timer: BoardTimerProps) => {
            const boardTimer = new BoardTimer(timer);
            console.log("[mousesync] Received board timer started: ", timer);
            this.boardTimerStartedListeners.forEach(l => l(boardTimer));
            this.activeBoardTimer = boardTimer;
            this.isBoardTimerPaused = false;
        })

        this.socket.on(BOARD_TIMER_PAUSED_CHAN, (timer: BoardTimerProps) => {
            const boardTimer = new BoardTimer(timer);
            console.log("[mousesync] Received board timer paused: ", timer);
            this.boardTimerPausedListeners.forEach(l => l(boardTimer));
            this.activeBoardTimer = boardTimer;
            this.isBoardTimerPaused = true;
        })

        this.socket.on(BOARD_TIMER_STOPPED_CHAN, (timer: BoardTimerProps) => {
            const boardTimer = new BoardTimer(timer);
            console.log("[mousesync] Received board timer stopped: ", timer);
            this.activeBoardTimer = undefined;
            this.isBoardTimerPaused = false;
            this.boardTimerStoppedListeners.forEach(l => l(boardTimer));
        })

        this.disconnectListener = () => {
            console.log("[mousesync] MouseSync socket disconnected");
            for(const uid in this.registry) {
                if(this.registry.hasOwnProperty(uid)) {
                    this.stopMouseTrackingOfUser(uid);
                }
            }

        };

        this.socket.on('disconnect', this.disconnectListener);

        const syncWithServer = (triggerJoinedListeners: boolean) => {
            this.socket.emit(
              GET_CURRENT_MOUSES,
              joined,
              (evts: UserMouseJoined[]) => evts.forEach(joinedEvt => this.trackMouseOfRemoteUser(joinedEvt.userId, joinedEvt.name, joinedEvt.avatar)));

            this.socket.emit(
              GET_ACTIVE_BOARD_TIMER,
              {board: joined.boardId},
              (res: ActiveBoardTimerResponse) => {
                  this.activeBoardTimer = res.timer? new BoardTimer(res.timer) : undefined;
                  this.isBoardTimerPaused = res.isPaused;

                  if(triggerJoinedListeners && this.boardTimerStartedListeners.length && this.activeBoardTimer) {
                      console.log("[mousesync] Calling board timer started listeners with: ", res);
                      this.boardTimerStartedListeners.forEach(l => l(this.activeBoardTimer!))
                  }

                  if(this.isBoardTimerPaused && triggerJoinedListeners && this.boardTimerPausedListeners.length && this.activeBoardTimer) {
                      this.boardTimerPausedListeners.forEach(l => l(this.activeBoardTimer!))
                  }
              }
            )
        }

        const init = (triggerJoinedListeners: boolean) => {
            if(triggerJoinedListeners) {
                this.userJoinedListeners.forEach(li => li(joined.userId, joined.name, joined.avatar, ownColor));
            }

            console.log("[mousesync]: Sending mouse joined event: ", joined);
            this.socket.emit(MOUSE_JOINED_CHAN, joined);

            syncWithServer(triggerJoinedListeners);
        };

        this.reconnectListener = () => {
            console.log("[mousesync] MouseSync socket reconnected");

            if(this.activeBoardTimer && this.activeBoardTimer.moderator === joined.userId) {
                this.startBoardTimer(this.activeBoardTimer);
                if(this.isBoardTimerPaused) {
                    this.pauseBoardTimer(this.activeBoardTimer);
                }
            }

            init(false);
        }

        this.socket.io.on('reconnect', this.reconnectListener);

        if(this.socket.connected) {
            init(true);
        } else {
            this.socket.once('connect', () => {
                init(true);
            })
        }

        this.syncIntervalListener = setInterval(() => {
            if(this.socket.connected) {
                syncWithServer(false);
            }
        }, 30000);
    }

    public stopSync(left: UserMouseLeft) {
        console.log("[mousesync] stop sync");
        this.socket.emit(MOUSE_LEFT_CHAN, left);
        this.socket.off(MOUSE_JOINED_CHAN);
        this.socket.off(MOUSE_MOVE_CHAN);
        this.socket.off(MOUSE_SCROLL_CHAN);
        this.socket.off(MOUSE_LEFT_CHAN);
        this.socket.off(GESTURE_CHANGED_CHAN);
        this.socket.off(AUTO_SCROLL_CHAN);
        this.socket.off(BOARD_TIMER_STARTED_CHAN);
        this.socket.off(BOARD_TIMER_PAUSED_CHAN);
        this.socket.off(BOARD_TIMER_STOPPED_CHAN);

        if(this.reconnectListener) {
            this.socket.off('reconnect', this.reconnectListener);
        }
        if(this.disconnectListener) {
            this.socket.off('disconnect', this.disconnectListener);
        }

        if(this.syncIntervalListener) {
            clearInterval(this.syncIntervalListener);
            this.syncIntervalListener = null;
        }

        this.registry = {};
        this.observedUser = null;
        this.lastMoveWasPanning = false;
        this.activeBoardTimer = undefined;
        this.isBoardTimerPaused = false;
        this.userJoinedListeners = [];
        this.userLeftListeners = [];
        this.boardTimerStartedListeners = [];
        this.boardTimerPausedListeners = [];
        this.boardTimerStoppedListeners = [];
        document.getElementById('topmenu')!.style.borderColor = null;
    }

    public keepMousePositionsInPlaceOnPanning(move: UserMouseMove, mouseDelta: MouseDelta): void
    {
        for(const userId in this.registry) {
            if(this.registry.hasOwnProperty(userId)) {
                const {pointerEl, lastKnownAbsolutePoint} = this.registry[userId];
                pointerEl.style.left = lastKnownAbsolutePoint.x + Math.round(mouseDelta.dx) + 'px';
                pointerEl.style.top = lastKnownAbsolutePoint.y + Math.round(mouseDelta.dy) + 'px';
            }
        }
    }

    public syncLastKnownAbsolutePointsAfterPanning(): void
    {
        for(const userId in this.registry) {
            if(this.registry.hasOwnProperty(userId)) {
                const {pointerEl} = this.registry[userId];

                this.registry[userId].lastKnownAbsolutePoint = {
                    x: parseInt(pointerEl.style.left as string, undefined),
                    y: parseInt(pointerEl.style.top as string, undefined)
                };
            }
        }
    }

    public emitUserMouseMove(move: UserMouseMove, mouseDelta?: MouseDelta): void
    {
        if(move.isPanning && mouseDelta) {
            this.keepMousePositionsInPlaceOnPanning(move, mouseDelta);
            this.lastMoveWasPanning = true;
        } else {
            if(this.lastMoveWasPanning) {
                this.syncLastKnownAbsolutePointsAfterPanning();
            }
        }
        this.socket.emit(MOUSE_MOVE_CHAN, move);
    }

    public emitUserMouseScroll(scroll: UserMouseScroll): void
    {
        this.socket.emit(MOUSE_SCROLL_CHAN, scroll);
    }

    public emitUserGestureChanged(gestureChanged: GestureChanged): void
    {
        this.socket.emit(GESTURE_CHANGED_CHAN, gestureChanged);
    }

    public emitUserIsAutoScrolling(autoScrollEvent: AutoScrollEvent): void
    {
        this.socket.emit(AUTO_SCROLL_CHAN, autoScrollEvent);
    }

    public trackMouseOfRemoteUser(userId: UserModel.UserId, userName: UserModel.Username, avatar: UserModel.AvatarUrl): void {
        console.log("[mousesync] track mouse of remote user: ", userId, " in registry ", this.registry.hasOwnProperty(userId));
        // Return if we already know that user
        if(this.registry.hasOwnProperty(userId)) {
            if(this.observedUser === userId) {
                this.observeUser(userId);
            }
            return;
        }

        const [pointerDiv, pointerI, color] = makeNewPointer(userName);
        this.registry[userId] = {
            userName,
            pointerEl: pointerDiv,
            pointerIcon: pointerI,
            color,
            scale: 0.2,
            translate: {x: 0, y: 0},
            lastKnownAbsolutePoint: {x: 0, y: 0}
        };
        this.container.appendChild(pointerDiv);

        if(this.observedUser === userId) {
            this.observeUser(userId);
        }

        this.userJoinedListeners.forEach(li => li(userId, userName, avatar, color));
    }

    public stopMouseTrackingOfUser(userId: UserModel.UserId): void {
        console.log("[mousesync] stop mouse tracking of user", userId, "in regsitry ", this.registry.hasOwnProperty(userId));
        if(this.registry.hasOwnProperty(userId)) {
            const {pointerEl} = this.registry[userId];
            this.container.removeChild(pointerEl);
            delete this.registry[userId];
            if(this.observedUser === userId) {
                this.hideObservingFrame();
            }
            this.userLeftListeners.forEach(li => li(userId));
        }
    }

    public observeUser(userId: UserModel.UserId): void {
        if(this.observingFrame) {
            this.observingFrame.remove();
        }

        console.log("[mousesync] observe user", userId, "in registry", this.registry.hasOwnProperty(userId));
        if(this.registry.hasOwnProperty(userId)) {
            this.observedUser = userId;
            const {userName, color, translate, scale} = this.registry[userId];

            document.getElementById('topmenu')!.style.borderColor = color;
            const frame = document.createElement('div');
            frame.classList.add('observing', 'frame');
            frame.style.borderColor = color;

            const label = document.createElement('div');
            label.classList.add('label');
            label.innerText = 'Observing ' + userName;
            label.style.backgroundColor = color;
            label.style.color = '#fff';
            frame.append(label);
            this.graph.container.append(frame);
            this.observingFrame = frame;

            if(translate.x !== 0 && translate.y !== 0) {
                this.graph.view.setScale(scale);
                this.graph.panGraph(translate.x, translate.y);
            }
        }
    }

    public hideObservingFrame(): void {
        if(this.observingFrame) {
            this.observingFrame.remove();
            this.observingFrame = null;
            document.getElementById('topmenu')!.style.borderColor = null;
        }
    }

    public stopObservingUser(): void {
        console.log("[mousesync] stop oberving user");
        this.observedUser = null;
        this.hideObservingFrame();
    }

    public onUserJoined(listener: UserJoinedListener): void {
        console.log("[mousesync] attach user joined listener");
        this.userJoinedListeners.push(listener);
    }

    public offUserJoined(listener: UserJoinedListener): void {
        this.userJoinedListeners = this.userJoinedListeners.filter(l => l !== listener);
    }

    public onUserLeft(listener: UserLeftListener): void {
        console.log("[mousesync] attach user left listener");
        this.userLeftListeners.push(listener);
    }

    public offUserLeft(listener: UserLeftListener): void {
        this.userLeftListeners = this.userLeftListeners.filter(l => l !== listener);
    }

    public onBoardTimerPaused(listener: BoardTimerListener): void {
        this.boardTimerPausedListeners.push(listener);

        if(this.activeBoardTimer && this.isBoardTimerPaused) {
            listener(this.activeBoardTimer);
        }
    }

    public offBoardTimerPaused(listener: BoardTimerListener) {
        this.boardTimerPausedListeners = this.boardTimerPausedListeners.filter(l => l !== listener);
    }

    public onBoardTimerStarted(listener: BoardTimerListener): void {
        this.boardTimerStartedListeners.push(listener);
        if(this.activeBoardTimer) {
            console.log("[mousesync] Trigger board timer started listener with active timer: ", this.activeBoardTimer.toJS())
            listener(this.activeBoardTimer);
        }
    }

    public offBoardTimerStarted(listener: BoardTimerListener) {
        this.boardTimerStartedListeners = this.boardTimerStartedListeners.filter(l => l !== listener);
    }

    public onBoardTimerStopped(listener: BoardTimerListener): void {
        this.boardTimerStoppedListeners.push(listener);
    }

    public offBoardTimerStopped(listener: BoardTimerListener) {
        this.boardTimerStoppedListeners = this.boardTimerStoppedListeners.filter(l => l !== listener);
    }

    public pauseBoardTimer(timer: BoardTimer): void {
        this.socket.emit(BOARD_TIMER_PAUSED_CHAN, timer.toJS());
        this.activeBoardTimer = timer;
        this.isBoardTimerPaused = true;
    }

    public startBoardTimer(timer: BoardTimer): void {
        this.socket.emit(BOARD_TIMER_STARTED_CHAN, timer.toJS());
        this.activeBoardTimer = timer;
        this.isBoardTimerPaused = false;
    }

    public stopBoardTimer(timer: BoardTimer): void {
        this.socket.emit(BOARD_TIMER_STOPPED_CHAN, timer.toJS());
        this.activeBoardTimer = undefined;
        this.isBoardTimerPaused = false;
    }
}



