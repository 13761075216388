import * as React from 'react';
import {useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Dropdown, DropdownProps, Modal} from "semantic-ui-react";
import * as Routes from "../../routes";
import {BoardId} from "../model/Board";
import {GraphElementType} from "../model/BoardGraphElement";
import {HistoryCellType} from "../model/HistoryEntry";
import {makeBoardGraphElementsSelector} from "../selectors/boardGraphElement";
import {makeGetAllBoards} from "../selectors/boardList";
import CellIcon from "./CellIcon";


interface OwnProps {
    boardId: BoardId
    openHandle: (handle: (open: boolean, currentLink: null|string) => void) => void;
    onCancel: () => void;
    onLinkChosen: (link: string) => void;
}

type MxGraphBoardLinkProps = OwnProps & WithNamespaces;

const MxGraphBoardLink = (props: MxGraphBoardLinkProps) => {
    const dispatch = useDispatch();
    const [selectedBoard, setSelectedBoard] = useState('');
    const [selectedEvent, setSelectedEvent] = useState<string|undefined>(undefined);
    const [open, setOpen] = useState(false);
    const boards = useSelector(makeGetAllBoards());
    const boardGraphElements = useSelector(makeBoardGraphElementsSelector(selectedBoard, GraphElementType.event));
    const featureToggle = false;

    const boardChoices = boards.filter(board => board.uid !== props.boardId).map(board => ({
        key: board.uid,
        value: board.uid,
        text: board.name,
    })).toArray();
    const eventChoices = boardGraphElements ? boardGraphElements.toArray().map(element => ({
        text: element.name,
        value: element.uid,
        icon: <CellIcon cellType={element.type as string as HistoryCellType} />
    })) : [];

    props.openHandle((openFromHandle, currentLink) => {
        if(currentLink) {
            selectBoard(Routes.getBoardUidFromWorkspaceUrl(currentLink));
        }
        setOpen(openFromHandle);
    });

    const selectBoard = (value: string) => {
        setSelectedBoard(value);
        setSelectedEvent(undefined);

        if (value.length === 36) {
            // dispatch(fetchBoardGraphElements(value, GraphElementType.event));
        }
    };

    const handleBoardChange = (e: React.SyntheticEvent, dProps: DropdownProps) => {
        selectBoard(dProps.value as string);
    };

    const handleEventChange = (e: React.SyntheticEvent, dProps: DropdownProps) => {
        setSelectedEvent(dProps.value as string)
    };

    const cancel = () => {
        props.onCancel();
        resetModal();
    };

    const resetModal = () => {
        setSelectedBoard('');
        setSelectedEvent(undefined);
    };

    const chooseLink = () => {
        if(selectedBoard !== '') {
            props.onLinkChosen(Routes.compileInspectioBoardWorkspace(selectedBoard));
        } else {
            props.onLinkChosen('');
        }

        resetModal();
    };

    // @ts-ignore
    // @ts-ignore
    return (
        <Modal open={open} onClose={cancel} closeIcon={true}>
            <Modal.Header>{props.t('insp.board.link.headline')}</Modal.Header>
            <Modal.Content image={true}>
                <Dropdown
                    placeholder={props.t('insp.board.link.select_board') as string}
                    fluid={true}
                    search={true}
                    selection={true}
                    options={boardChoices}
                    onChange={handleBoardChange}
                    value={selectedBoard}
                    clearable={true}
                    style={{ marginRight: '10px' }}
                />
                {featureToggle && <Dropdown
                    placeholder={props.t('insp.board.link.select_event') as string}
                    fluid={true}
                    search={true}
                    selection={true}
                    clearable={true}
                    options={eventChoices}
                    value={selectedEvent}
                    onChange={handleEventChange}
                    style={{ marginLeft: '10px' }}
                />}

            </Modal.Content>
            <Modal.Actions>
                <Button
                    children={props.t('app.form.cancel')}
                    onClick={cancel}
                />
                <Button
                    primary={true}
                    children={props.t('app.form.save')}
                    onClick={chooseLink}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default withNamespaces()(MxGraphBoardLink);
