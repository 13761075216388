import {action} from "typesafe-actions";
import {BoardModel} from '../model';
import {GraphElementType} from "../model/BoardGraphElement";
import {
    FETCH_BOARD_CLIENT,
    FETCH_BOARD_CLIENT_SECRET,
    FETCH_BOARD_GRAPH_ELEMENTS,
    FETCH_BOARD_PERMISSIONS_FOR_TEAMS,
    FETCH_BOARD_PERMISSIONS_FOR_USERS,
    FETCH_BOARD_STICKY_TEMPLATES,
    FETCH_OPEN_BOARD_INVITATIONS,
    FETCH_PLAYSHOT_SUMMARY_LIST,
    FETCH_USER_BOARD,
    FETCH_USER_BOARDS,
    START_FETCH_USER_BOARDS
} from './constants';

export const startFetchUserBoards = () => action(START_FETCH_USER_BOARDS);

export const fetchUserBoards = () => action(FETCH_USER_BOARDS);

export const fetchUserBoard = (boardId: BoardModel.BoardId) => action(FETCH_USER_BOARD, {boardId});

export const fetchBoardPermissionsForUsers = (boardId: BoardModel.BoardId) => action(FETCH_BOARD_PERMISSIONS_FOR_USERS, {boardId});

export const fetchBoardPermissionsForTeams = (boardId: BoardModel.BoardId) => action(FETCH_BOARD_PERMISSIONS_FOR_TEAMS, {boardId});

export const fetchOpenBoardInvitations = (boardId: BoardModel.BoardId) => action(FETCH_OPEN_BOARD_INVITATIONS, {boardId});

export const fetchBoardGraphElements = (boardId: BoardModel.BoardId, type: GraphElementType) => action(FETCH_BOARD_GRAPH_ELEMENTS, {boardId, type});

export const fetchBoardClient = (boardId: BoardModel.BoardId) => action(FETCH_BOARD_CLIENT, {boardId});

export const fetchBoardClientSecret = (boardId: BoardModel.BoardId) => action(FETCH_BOARD_CLIENT_SECRET, {boardId});

export const fetchBoardStickyTemplates = (boardId: BoardModel.BoardId) => action(FETCH_BOARD_STICKY_TEMPLATES, {boardId});

export const fetchPlayshotSummaryList = (boardId: BoardModel.BoardId) => action(FETCH_PLAYSHOT_SUMMARY_LIST, {boardId});
