import * as React from 'react';
import {SyntheticEvent, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Confirm, Dropdown} from "semantic-ui-react";
import * as BoardModule from "../../InspectioBoards";
import {makeGetTeamBoards} from "../../InspectioBoards/selectors/boardList";
import {makeIioFilteredUserListSelector} from "../../User/selectors/iioUserInfo";
import {Action} from "../index";
import {Team} from "../model";
import AddBoardToTeamModal from "./AddBoardToTeamModal";
import BoardEntry from "./BoardEntry";
import EditableTeamHeader from "./EditableTeamHeader";
import InviteUserToTeamModal from "./InviteUserToTeamModal";
import MemberEntry from "./MemberEntry";
import {List} from "immutable";
import {UserOrganizationInfo} from "../../Organization/model/UserOrganizationInfo";
import MoveTeamMenu from "./MoveTeamMenu";

interface OwnProps {
    team: Team.Team,
    blueprint?: boolean,
    onTeamAdded?: (team: Team.TeamId) => void,
    onCancelName?: (team: Team.TeamId) => void,
    onCancelDescription?: (team: Team.TeamId) => void,
    isMovable: boolean,
    targetOrganizations: List<UserOrganizationInfo>,
}

type TeamCardProps = OwnProps & WithNamespaces;

const TeamCard = (props: TeamCardProps) => {
    const members = useSelector(makeIioFilteredUserListSelector(props.team.members));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const [showAddBoardToTeamModal, setShowAddBoardToTeamModal] = useState(false);
    const boards = useSelector(makeGetTeamBoards(props.team.uid));

    const dispatch = useDispatch();

    // Team Edit

    const handleTeamNameChanged = (teamName: Team.TeamName) => {
        if(props.blueprint && props.onTeamAdded) {
            props.onTeamAdded(props.team.uid);
        }
    }

    const handleCancelName = () => {
        if(props.onCancelName) {
            props.onCancelName(props.team.uid);
        }
    }

    const handleCancelDescription = () => {
        if(props.onCancelDescription) {
            props.onCancelDescription(props.team.uid);
        }
    }

    const handleDeleteClick = (e: SyntheticEvent) => {
        e.preventDefault();
        setShowDeleteModal(true);
    }

    const deleteTeam = () => {
        dispatch(Action.Command.deleteTeam(props.team.uid));
    }

    // Add User Modal

    const handleAddUserClick = (e: SyntheticEvent) => {
        e.preventDefault();
        setShowAddUserModal(true);
    }

    const closeAddUserModal = () => {
        setShowAddUserModal(false);
    };

    // Add Board To Team Modal#

    const handleAddBoardClick = (e: SyntheticEvent) => {
        e.preventDefault();
        setShowAddBoardToTeamModal(true);
    };

    const closeAddBoardToTeamModal = () => {
        setShowAddBoardToTeamModal(false);
    };

    const handleAddBoardToTeamSubmit = (selectedBoard: BoardModule.BoardModel.BoardId) => {
        dispatch(Action.Command.adminGrantTeamAccessToBoard(selectedBoard, [props.team.uid]));
        setShowAddBoardToTeamModal(false);
    };
    // Lists

    const memberList = members.sortBy(m => m.username).sort((mA, mB) => {
        if(mA.userId === props.team.ownerId) {
            return  -1;
        }

        if(mB.userId === props.team.ownerId) {
            return 1;
        }

        return 0;
    }).map(member => <MemberEntry 
        key={member.userId} 
        member={member} 
        isOwner={props.team.ownerId === member.userId} 
        teamId={props.team.uid} />);

    const boardList = boards.map(board => <BoardEntry board={board} team={props.team} key={board.uid}/>);

    return <div className="ui segment">
                {!props.blueprint && <a  href="#" className="ui small right floated basic button noborder" onClick={handleDeleteClick}>
                Delete
                </a>}

                <EditableTeamHeader
                    team={props.team}
                    addTeamMode={props.blueprint}
                    onNameChanged={handleTeamNameChanged}
                    onCancelName={handleCancelName}
                    onCancelDescription={handleCancelDescription}
                />
                <div className="ui horizontal divider">Teammember</div>               
               <div className="ui horizontal list">
                   <Button basic={true} icon={true} color="blue" size="large" onClick={handleAddUserClick} disabled={props.blueprint}>
                       <i className="blue user icon" />
                   </Button>
                    {!props.blueprint && memberList}
                </div>
                <div className="ui horizontal divider">Teamboards</div>
                <div className="ui horizontal list">
                    <Button basic={true} icon={true} color="orange" size="large" onClick={handleAddBoardClick} disabled={props.blueprint}>
                        <i className="orange sticky note icon" />
                    </Button>
                    {!props.blueprint && boardList}
                </div>
                {props.isMovable && <div className="ui horizontal divider">{props.t('insp.team.move_to_organization_title')}</div>}
                {props.isMovable && <MoveTeamMenu organizations={props.targetOrganizations} teamId={props.team.uid}/>}
            <InviteUserToTeamModal
                team={props.team}
                open={showAddUserModal}
                onClose={closeAddUserModal}
            />
            <AddBoardToTeamModal
                teamId={props.team.uid}
                open={showAddBoardToTeamModal}
                onClose={closeAddBoardToTeamModal}
                onSubmit={handleAddBoardToTeamSubmit}
            />
            <Confirm
                open={showDeleteModal}
                header={props.t('insp.team.remove_team_confirm_title')}
                confirmButton={props.t('app.form.remove')}
                cancelButton={<Button content={props.t('app.form.cancel')} className="basic noborder" />}
                content={props.t('insp.team.remove_team_confirm_text', {team_name: props.team.name})}
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={deleteTeam}
                />
        </div>
};

export default withNamespaces()(TeamCard);
