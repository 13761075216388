import {List, Record} from "immutable";
import {ApplicationState} from "../../reducer";
import {createSelector} from "reselect";
import {MODULE} from "../index";
import {BoardId} from "../model/Board";
import {ActiveGraphElement} from "../reducers/applyActiveGraphElementActions";

export const activeGraphElementsSelector = (state: Record<ApplicationState>, props: any): Map<string, ActiveGraphElement> =>
    state.getIn([MODULE, 'activeGraphElements']);

export const makeActiveGraphElementSelector = (boardId: BoardId): (state: Record<ApplicationState>, props?: any) => ActiveGraphElement|null => {
    return createSelector(
        [activeGraphElementsSelector],
        (activeGraphElements): ActiveGraphElement|null =>  {
            return activeGraphElements.has(boardId) ? activeGraphElements.get(boardId)! : null;
        }
    )
};
