import {Map} from "immutable";
import { Reducer } from 'redux';
import {ActionType} from "typesafe-actions";
import {Action as BoardAction} from "../../InspectioBoards";
import {Action as TeamAction} from "../../InspectioTeams";
import {ACTIVE_ORGANIZATION_SWITCHED, SIGN_IN, UPDATE_PROFILE} from "../actions/constants";
import {UserModel} from '../model';
import {InspectioUserAction} from "./index";

export interface UserState extends Map<string, UserModel.UserInfo> {

}

export const initialState = Map<string, UserModel.UserInfo>().set('currentUser', new UserModel.UserInfo(UserModel.defaultUserProps));

type ReducerActionType = ActionType<typeof BoardAction.Command.addBoard> | ActionType<typeof BoardAction.Command.deleteBoard> | ActionType<InspectioUserAction>;

const reducer: Reducer<UserState, ReducerActionType> = (state: UserState = initialState, action: ReducerActionType): UserState => {
    switch (action.type) {
        case SIGN_IN:
            return state.set('currentUser', action.payload.user);
        case BoardAction.Type.ADD_BOARD:
        case BoardAction.Type.IMPORT_NEW_BOARD:
            return state.setIn(['currentUser', 'quota'], state.getIn(['currentUser', 'quota']) - 1);
        case BoardAction.Type.DELETE_BOARD:
            return state.setIn(['currentUser', 'quota'], state.getIn(['currentUser', 'quota']) + 1);
        case TeamAction.Type.TEAM_ADDED:
            return state.setIn(['currentUser', 'teamQuota'], state.getIn(['currentUser', 'teamQuota']) - 1);
        case TeamAction.Type.TEAM_DELETED:
            return state.setIn(['currentUser', 'teamQuota'], state.getIn(['currentUser', 'teamQuota']) + 1);
        case UPDATE_PROFILE:
            return state.setIn(['currentUser'], action.payload.user);
        case ACTIVE_ORGANIZATION_SWITCHED:
            return state.setIn(['currentUser', 'activeOrganization'], action.payload.activeOrganization);
        default:
            return state;
    }
};

export {reducer as userReducer}
