import * as React from 'react';
import {Icon} from "semantic-ui-react";
import {NodeType} from "../model/Graph";
import CellIcon from "./CellIcon";

interface OwnProps {
    type: NodeType;
    open?: boolean;
    className?: string;
    title?: string;
}

type LeafIconProps = OwnProps;

const LeafIcon = (props: LeafIconProps) => {
    const open = !!props.open;

    switch (props.type) {
        case NodeType.layer:
            const iconName = open ? 'folder open' : 'folder';
            return <Icon name={iconName}  style={{cursor: 'pointer'}} className={props.className} title={props.title} />;
        default:
            return <CellIcon cellType={props.type} size="normal" title={props.title} className={props.className} />;
    }

};

export default LeafIcon;
