import {List, Record} from "immutable";
import {createSelector} from "reselect";
import {ApplicationState} from "../../reducer";
import {MODULE} from "../index";
import {BoardId} from "../model/Board";
import {BoardGraphElement, GraphElementType} from "../model/BoardGraphElement";
import {BoardGraphElementsState} from "../reducers/applyBoardGraphElementActions";

export const boardGraphElementsSelector = (state: Record<ApplicationState>, props: any): BoardGraphElementsState =>
    state.getIn([MODULE, 'boardGraphElements']);

export const makeBoardGraphElementsSelector = (boardId: BoardId, type?: GraphElementType): (state: Record<ApplicationState>, props?: any) => List<BoardGraphElement>|null => {
    return createSelector(
        [boardGraphElementsSelector],
        (boardGraphElements): List<BoardGraphElement>|null => {
            if (!boardGraphElements.has(boardId)) {
                return null;
            }

            const elements = List(boardGraphElements.get(boardId)!.toIndexedSeq().toArray());
            return type ? elements.filter(element => element.type === type) : elements;
        }
    )
};
