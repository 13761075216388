import * as React from 'react';
import {useEffect, useState} from "react";
import {WithNamespaces, withNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ButtonGroup, Grid, GridColumn, GridRow, Icon, ListItem} from "semantic-ui-react";
import SecondaryButton from "../../core/components/SecondaryButton";
import {isSuccess} from "../../core/validation/either";
import * as UserModule from "../../User";
import UserAvatar from "../../User/components/UserAvatar";
import {createAnonymous, IioUserInfo} from "../../User/model/IioUserInfo";
import * as Action from "../actions";
import {Status, TeamInvitation} from "../model/TeamInvitation";
import {makeTeamInvitationListSelector, makeTeamInvitationSelector} from "../selectors/TeamInvitation";

interface OwnProps {
    teamInvitation: TeamInvitation;
}

type OpenInvitationProps = OwnProps & WithNamespaces;

const OpenInvitation = (props: OpenInvitationProps) => {

    const [userInfo, setUserInfo] = useState(createAnonymous());
    const [isResending, setIsResending] = useState(false);
    const [isRevoking, setIsRevoking] = useState(false);
    const [isRevoked, setIsRevoked] = useState(false);
    const dispatch = useDispatch();
    const cachedInvitation = useSelector(makeTeamInvitationSelector(props.teamInvitation.uid));

    useEffect(() => {
        UserModule.Action.Api.getInspectioUserInfoByEmail(props.teamInvitation.invitedUser).then(({response, error}) => {
            if(response) {
                const result = UserModule.IioUserInfo.createIioUserInfoFromServerData(response.data);

                if(isSuccess(result)) {
                    setUserInfo(result.value);
                }
            }
        })
    }, [props.teamInvitation.invitedUser]);

    useEffect(() => {
        if(isResending) {
            setIsResending(false);
        }

        if(isRevoking && cachedInvitation && cachedInvitation.status === Status.revoked) {
            setIsRevoked(true);
        }
    }, [cachedInvitation]);

    const handleResendClick = () => {
        setIsResending(true);
        dispatch(Action.Command.resendTeamInvitation(props.teamInvitation.uid));
    };

    const handleRevokeClick = () => {
        setIsRevoking(true);
        dispatch(Action.Command.revokeTeamInvitation(props.teamInvitation.uid));
    }

    if(isRevoked) {
        return <></>;
    };

    return <ListItem>
        <UserAvatar user={userInfo} size="mini" />
        <span style={{paddingLeft: "10px"}}>{props.teamInvitation.invitedUser}</span>
        <ButtonGroup floated="right">
            <SecondaryButton content={props.t('app.form.resend')} icon="paper plane" loading={isResending} onClick={handleResendClick} />
            <SecondaryButton content={props.t('app.form.revoke')} icon="delete" loading={isRevoking} onClick={handleRevokeClick} />
        </ButtonGroup>
    </ListItem>
};

export default withNamespaces()(OpenInvitation);