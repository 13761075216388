import * as React from 'react';
import {Grid, Label, Message} from "semantic-ui-react";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {
    makeUserSettingSelector,
    makeUserSettingsLoadedSelector,
    keyUpdateViewed
} from "../../User/selectors/userSettings";
import {useEffect} from "react";
import {upsertUserSetting} from "../../User/actions/commands";

interface OwnProps {
    date: string;
    children: React.ReactNode;
}

type UpdateListEntryProps = OwnProps;

const UpdateListEntry = (props: UpdateListEntryProps) => {

    const dispatch = useDispatch();
    const settingsLoaded = useSelector(makeUserSettingsLoadedSelector());
    const viewed = useSelector(makeUserSettingSelector(keyUpdateViewed(props.date), false));

    useEffect(
        () => {
            window.setTimeout(() => {
                if (settingsLoaded && !viewed) {
                    dispatch(upsertUserSetting(keyUpdateViewed(props.date), true));
                }
            }, 5000);
        },
        [viewed, settingsLoaded]
    );

    return (
        <Grid.Row>
            <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
                <Message>
                    <Label className={`${viewed ? 'black' : 'red'} top left attached`}>
                        New Features released {moment(props.date).format('LL')}
                    </Label>
                    {props.children}
                </Message>
            </Grid.Column>
        </Grid.Row>
    );
};

export default UpdateListEntry;
