import { Record } from 'immutable';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducer';
import { StatusModel } from '../model';

export const statusSelector = (state: Record<ApplicationState>) => state.getIn(['socket', 'status', 'status']);

export const makeGetStatus = (): (state: Record<ApplicationState>, props?: any) => StatusModel.Status => {
    return createSelector(
        [statusSelector],
        (status: StatusModel.SocketStatus): StatusModel.Status => StatusModel.fromtString(status),
    );
};
