import {List} from "immutable";
import {call, delay, fork, put, select, take} from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {ResponseType} from "../../api/util";
import { Action as NotifyAction } from '../../NotificationSystem';
import {UserId} from "../../User/model/UserInfo";
import * as Action from '../actions';
import {setBoardToSnapshot} from "../actions/commands";
import {BoardModel, BoardSnapshot, BoardSync, BoardVersioning} from '../model';
import {BoardId} from "../model/Board";
import {Permissions} from "../model/BoardPermissionForUser";
import {makeBoardByIdSelector} from "../selectors/board";

type FlowAction = ActionType<typeof Action.Query.fetchUserBoard>;

interface BoardAccessCheck {
    access: 'allowed' | 'denied' | 'requestable';
    permissions: {
        permissionsId: string,
        userId: UserId,
        boardId: BoardId,
        permissions: Permissions
    };
    denyReason: string;
}

const retryFetchUserBoard = function* (boardId: BoardId) {
    let retry = 0;

    while(retry < 4) {
        yield delay(1000 * retry);

        const response: ResponseType = yield call(Action.Api.fetchUserBoard, boardId);

        if(response.response) {
            return response
        }

        if(retry === 3) {
            return response;
        }

        retry++;
    }
}

const onFetchUserBoard = function* (action: FlowAction) {
    const tempBoard = yield select(makeBoardByIdSelector(action.payload.boardId))

    if(tempBoard && tempBoard.tempBoard) {
        yield put(Action.Command.setBoardDetailsFetched(action.payload.boardId, false));
        return;
    }

    const fetchUserBoardResponse: ResponseType = yield call(Action.Api.fetchUserBoard, action.payload.boardId);
    const error = fetchUserBoardResponse.error;
    let response = fetchUserBoardResponse.response;

    if (error) {
        const accessCheck: ResponseType = yield call(Action.Api.checkBoardAccess, action.payload.boardId);

        if (accessCheck.response) {
            const accessCheckData: BoardAccessCheck = accessCheck.response.data;

            switch (accessCheckData.access) {
                case "allowed":
                    const retryResponse = yield call(retryFetchUserBoard, action.payload.boardId);
                    if(retryResponse.response) {
                        response = retryResponse.response;
                    } else {
                        yield put(NotifyAction.Command.error("Request Error", "Failed to load board"));
                    }
                    break;
                case "denied":
                    yield put(NotifyAction.Command.error("Access Error", "You cannot access the board. Either you do not have permissions or the organization has not enough user quota. Please contact the board owner for details."));
                    break;
                case "requestable":
                    console.warn('@TODO: Implement request access to board');
                    yield put(NotifyAction.Command.error("Request Error", "Failed to load board"));
                    break;
            }

        } else {
            yield put(NotifyAction.Command.error("Request Error", "Failed to load board"));
        }
    }

    if (response) {
        const board = new BoardModel.Board({
            uid: response.data.boardId,
            name: response.data.name,
            nameAbbreviation: response.data.nameAbbreviation,
            tags: List(response.data.tags),
            createdAt: response.data.createdAt,
            lastModified: response.data.lastModified,
            version: new BoardVersioning.BoardVersion(response.data.boardVersion),
            shared: response.data.shared,
            sharedWith: List(response.data.sharedWith),
            assignedTeams: List(response.data.assignedTeams),
            responsibleTeam: response.data.responsibleTeam? response.data.responsibleTeam : undefined,
            writeAccess: response.data.writeAccess,
            owner: response.data.owner,
            creator: response.data.creator,
            organization: response.data.organization,
            tempBoard: false,
            orgaMemberPermissions: response.data.orgaMemberPermissions,
            anyonePermissions: response.data.anyonePermissions,
            codySuggestEnabled: response.data.codySuggestEnabled,
            eventModelingEnabled: response.data.eventModelingEnabled,
        });

        let snapshot = null;

        if(response.data.snapshot) {
            snapshot = new BoardSnapshot.BoardSnapshot({
                boardId: response.data.boardId,
                xml: response.data.snapshot.content,
                boardVersion: new BoardVersioning.BoardVersion(response.data.snapshot.boardVersion)
            });
        }

        yield put(Action.Command.updateBoards([board]));

        if(snapshot) {
            yield put(setBoardToSnapshot(snapshot));
        }

        yield put(Action.Command.setBoardDetailsFetched(board.uid, board.writeAccess as boolean));
    }
}

export function* fetchUserBoardFlow() {

    while (true) {
        const action: FlowAction = yield take([
            Action.Type.FETCH_USER_BOARD
        ]);

        yield fork(onFetchUserBoard, action);
    }
}
