import {List} from "immutable";
import {call, fork, put, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../../InspectioBoards/index";
import {StandaloneBoard} from "../storage/types";
import {standaloneStorageManager} from "../util";
import {createStickyTemplateFromServerData} from "../../InspectioBoards/model/StickyTemplate";
import {execForEach, execValidate, isFailure, isSuccess, pipe} from "../../core/validation/either";
import {isArray} from "../../core/validation/predicates";
import {Action as NotifyAction} from "../../NotificationSystem";

type Query = ActionType<typeof Action.Query.fetchBoardStickyTemplates>

function* handleFetchBoardStickyTemplates(query: Query) {
    const boardData: StandaloneBoard|null = yield call(standaloneStorageManager.getBoard, query.payload.boardId);

    if (!boardData) {
        return;
    }

    const result = pipe(
        boardData.stickyTemplates as any,
        execValidate([isArray(), "Response data is not of type Array"]),
        execForEach((stickyTemplateData: any) => {
            return createStickyTemplateFromServerData({
                boardId: query.payload.boardId,
                cellType: stickyTemplateData.cellType,
                template: stickyTemplateData.template,
                templateType: stickyTemplateData.templateType,
                templateSchema: stickyTemplateData.templateSchema,
            });
        }),
    );

    if (isFailure(result)) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not load sticky templates'));
    }

    if (isSuccess(result)) {
        const stickyTemplates = List(result.value);
        yield put(Action.Event.boardStickyTemplatesFetched(stickyTemplates));
    }
}

export function* standaloneFetchBoardStickyTemplatesFlow() {
    while (true) {
        const query: Query = yield take([
            Action.Type.FETCH_BOARD_STICKY_TEMPLATES
        ]);

        yield fork(handleFetchBoardStickyTemplates, query);
    }
}
