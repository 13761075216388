import {List, Map, Record} from "immutable";
import {createSelector} from "reselect";
import {ApplicationState} from "../../reducer";
import {MODULE} from "../index";
import {BoardId} from "../model/Board";
import {DEFAULT_TEMPLATE} from "../model/DefaultStickyTemplate";
import {NodeType} from "../model/Graph";
import {createStickyTemplateFromDefault, StickyTemplate, StickyTemplateId} from "../model/StickyTemplate";

export const getStickyTemplateById = (state: Record<ApplicationState>, uid: StickyTemplateId): StickyTemplate | undefined => state.getIn([MODULE, 'StickyTemplate', uid]);

export const makeStickyTemplateSelector = (uid: StickyTemplateId): (state: Record<ApplicationState>) => StickyTemplate | undefined => (state: Record<ApplicationState>) => getStickyTemplateById(state, uid);

export const stickyTemplateListSelector = (state: Record<ApplicationState>, props: any): List<StickyTemplate> =>
    List(state.getIn([MODULE, 'StickyTemplate']).toIndexedSeq().toArray()) || List();

export const makeStickyTemplateListSelector = (): (state: Record<ApplicationState>, props?: any) => List<StickyTemplate> => {
    return createSelector(
        [stickyTemplateListSelector],
        (StickyTemplateList): List<StickyTemplate> => {
            return StickyTemplateList;
        }
    )
};

export const boardStickyTemplateMapSelector = (state: Record<ApplicationState>, props: {boardId: BoardId}): Map<NodeType, StickyTemplate> => {
    let stickyMap = Map<NodeType, StickyTemplate>();

    (state.getIn([MODULE, 'StickyTemplate']) || List()).toIndexedSeq()
        .filter((stickyTemplate: StickyTemplate) => {
            return stickyTemplate.boardId === props.boardId;
        })
        .forEach((stickyTemplate: StickyTemplate) => {
            stickyMap = stickyMap.set(stickyTemplate.cellType, stickyTemplate);
        });

    return stickyMap;
};
    List();

export const makeBoardStickyTemplateMapSelector = (boardId: BoardId): (state: Record<ApplicationState>) => Map<NodeType, StickyTemplate> => {
    return (state: Record<ApplicationState>): Map<NodeType, StickyTemplate> => {
        let stickyTemplateMap = boardStickyTemplateMapSelector(state, {boardId});

        for (const cellType in DEFAULT_TEMPLATE) {
            if(!stickyTemplateMap.has(cellType as NodeType)) {
                stickyTemplateMap = stickyTemplateMap.set(
                    cellType as NodeType,
                    createStickyTemplateFromDefault(boardId, cellType as NodeType, DEFAULT_TEMPLATE[cellType] as string)
                )
            }
        }
        return stickyTemplateMap;
    }
};
