import {Record} from "immutable";
import {Reducer} from "redux";
import {
    SET_CODY_CONSOLE_HEIGHT,
    SET_RIGHT_SIDEBAR_WIDTH,
    SET_SIDEBAR_WIDTH,
    TOGGLE_SIDEBAR_COLLAPSED
} from "../actions/constants";
import {InspectioLayoutAction} from "./index";


export interface SidebarStateProps {
    collapsed: boolean;
    width: number;
    rightSidebarWidth: number;
    codyConsoleHeight: number;
}

export const defaultSidebarStateProps: SidebarStateProps = {
    collapsed: false,
    width: 250,
    rightSidebarWidth: 450,
    codyConsoleHeight: 200,
};

export class SidebarState extends Record(defaultSidebarStateProps) implements SidebarStateProps {

}

export const initialState: SidebarState = new SidebarState();

const reducer: Reducer<SidebarState, InspectioLayoutAction> = (state: SidebarState = initialState, action: InspectioLayoutAction): SidebarState => {
    switch (action.type) {
        case TOGGLE_SIDEBAR_COLLAPSED:
            return state.set('collapsed', action.payload.collapsed);
        case SET_SIDEBAR_WIDTH:
            return state.set('width', action.payload.width);
        case SET_RIGHT_SIDEBAR_WIDTH:
            return state.set('rightSidebarWidth', action.payload.width);
        case SET_CODY_CONSOLE_HEIGHT:
            return state.set('codyConsoleHeight', action.payload.height);
        default:
            return state;
    }
};

export {reducer as sidebarReducer}
