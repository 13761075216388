import * as React from 'react';
import {useState} from "react";
import {Accordion, Menu} from "semantic-ui-react";

interface OwnProps {
    label: string;
    maxLength?: number;
    onClick?: (showAll: boolean) => void;
}

const MaxLengthLabel: React.FC<OwnProps> = (props: OwnProps) => {
    const maxLength = props.maxLength || 50;

    const [active, setActive] = useState(false);

    const showFullLabel = active && !props.onClick;

    const handleClick = (evt: React.MouseEvent) => {
        evt.preventDefault();
        if(props.onClick) {
            props.onClick(!active);
        }
        setActive(!active);
    }

    if(showFullLabel || props.label.length <= maxLength) {
        return <>{ props.label }</>
    } else {
        return <>{ props.label.slice(0, maxLength) + ' ' }<span onClick={handleClick}>...</span></>
    }
};

export default MaxLengthLabel;
