import {ElementsTree} from "../../../model/ElementsTree";
import {Node, NodeType} from "../../../model/Graph";
import {Schema} from "../schema/schema";
import {isPropertyRef} from "../schema/shorthand";
import {getSchemaRefName} from "./get-schema-ref-name";
import {getVoMetadata} from "./get-vo-metadata";

export const getRefVo = (refSchema: Schema, elementsTree: ElementsTree): Node | undefined => {
  if(!refSchema.isRef()) {
    return;
  }

  const vos = elementsTree.getFilteredElementsByType(NodeType.document, true);

  for (const vo of vos.toArray()) {
    const voMeta = getVoMetadata(vo);

    const voRefName = getSchemaRefName(vo, voMeta, isPropertyRef(refSchema.getRef('')));

    if(voRefName === refSchema.getRef()) {
      return vo;
    }
  }
}
