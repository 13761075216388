import {List, Record} from "immutable";
import {createSelector} from "reselect";
import {ApplicationState} from "../../reducer";
import {MODULE} from "../index";
import {OrganizationInvitation, OrganizationInvitationId} from "../model/OrganizationInvitation";

export const getOrganizationInvitationById = (state: Record<ApplicationState>, uid: OrganizationInvitationId): OrganizationInvitation | undefined => state.getIn([MODULE, 'OrganizationInvitation', uid]);

export const makeOrganizationInvitationSelector = (uid: OrganizationInvitationId): (state: Record<ApplicationState>) => OrganizationInvitation | undefined => (state: Record<ApplicationState>) => getOrganizationInvitationById(state, uid);

export const organizationInvitationListSelector = (state: Record<ApplicationState>, props: any): List<OrganizationInvitation> =>
    List(state.getIn([MODULE, 'OrganizationInvitation']).toIndexedSeq().toArray()) || List();

export const makeOrganizationInvitationListSelector = (): (state: Record<ApplicationState>, props?: any) => List<OrganizationInvitation> => {
    return createSelector(
        [organizationInvitationListSelector],
        (OrganizationInvitationList): List<OrganizationInvitation> => {
            return OrganizationInvitationList;
        }
    )
};
