import { action } from 'typesafe-actions';
import {
    CONNECT_SOCKET, DEFAULT_SOCKET_CMD_TIMEOUT,
    DISCONNECT_SOCKET,
    LISTEN_ON_SOCKET_CHANNEL, SEND_SOCKET_CMD, SOCKET_CONNECTED,
    SOCKET_CONNECTION_LOST,
    SOCKET_DISCONNECTED, STOP_LISTENING_ON_SOCKET_CHANNEL,
} from './constants';

export const connectSocket = () => action(CONNECT_SOCKET);
export const disconnectSocket = () => action(DISCONNECT_SOCKET);
export const socketConnected = () => action(SOCKET_CONNECTED);
export const socketDisconnected = () => action(SOCKET_DISCONNECTED);
export const socketConnectionLost = (reason: string) => action(SOCKET_CONNECTION_LOST, {reason});

export const sendSocketCmd = <D, TMeta>(name: string, data: D, responseActionType: string, metadata?: TMeta, timeout: number = DEFAULT_SOCKET_CMD_TIMEOUT) => action(SEND_SOCKET_CMD, {
    name,
    data,
    responseActionType,
    metadata,
    timeout,
});
export const notifySocketCmdSent = <D, TMeta>(responseActionType: string, socketCmd: SocketCmd<D>, metadata?: TMeta) => action(responseActionType, {
    socketCmd,
    metadata,
});
export const ackSocketCmd = <D, TMeta>(responseActionType: string, socketCmd: SocketCmd<D>, metadata?: TMeta) => action(responseActionType, {
    socketCmd,
    metadata,
});
export const rejectSocketCmd = <D, TMeta>(responseActionType: string, socketCmd: SocketCmd<D>, metadata?: TMeta) => action(responseActionType, {
    socketCmd,
    metadata,
});

export const listenOnSocketChannel = <D, TMeta>(channelName: string, emitActionType: string, metadata?: TMeta) => action(LISTEN_ON_SOCKET_CHANNEL, {
    channelName,
    emitActionType,
    metadata,
});
export const stopListeningOnSocketChannel = (channelName: string) => action(STOP_LISTENING_ON_SOCKET_CHANNEL, {channelName});
export const emitChannelEvent = <D, TMeta>(emitActionType: string, event: D, metadata?: TMeta) => action(emitActionType, {
    event,
    metadata,
});


interface SocketCmdPending<D> {
    name: string;
    type: 'pending';
    payload: D;
    reason?: undefined;
}

interface SocketCmdError<D> {
    name: string;
    type: 'error';
    payload: D;
    reason: string;
}

interface SocketCmdSuccess<D> {
    name: string;
    type: 'success';
    payload: D;
    reason?: undefined;
}

export type SocketCmd<D> = SocketCmdPending<D> | SocketCmdError<D> | SocketCmdSuccess<D>;

export const isAcknowledged = <D>(cmd: SocketCmd<D>): boolean => {
    return cmd.type === 'success';
};

export const isNotAcknowledged = <D>(cmd: SocketCmd<D>): boolean => {
    return cmd.type === 'pending';
};

export const isError = <D>(cmd: SocketCmd<D>): boolean => {
    return cmd.type === 'error';
};





