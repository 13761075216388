import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {makeOrganizationSelector} from "../selectors/selectOrganization";

type Command = ActionType<typeof Action.Command.renameOrganization>

function* handleRenameOrganization(command: Command) {
    const {response, error} = yield call(Action.Api.renameOrganization, command.payload.organizationId, command.payload.newName);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not rename organization.'));
    }

    if(response) {
        const organization = yield select(makeOrganizationSelector(command.payload.organizationId));

        yield put(Action.Event.organizationRenamed(organization.withNewName(command.payload.newName)));

        yield put(NotifyAction.Command.info('Organization renamed', 'Renaming organization was successful.'));
    }
}

export function* renameOrganization() {
    while (true) {
        const command: Command = yield take([
            Action.Type.RENAME_ORGANIZATION
        ]);

        yield fork(handleRenameOrganization, command);
    }
}
