import {List, Map} from 'immutable';
import {forEach} from 'lodash';
import {Reducer} from 'redux';
import {Action as TeamAction} from "../../InspectioTeams";
import {Team} from "../../InspectioTeams/model";
import {UserModel} from "../../User/model";
import {
    ABORT_BOARD_DELETION,
    ADD_BOARD,
    BOARD_ACCESS_REVOKED,
    BOARD_EXPORTED,
    BOARD_IMPORT_STARTED,
    BOARD_IMPORTED,
    BOARD_SHARED_WITH_ANYONE,
    BOARD_SHARED_WITH_ORGANIZATION_MEMBERS,
    CHANGE_BOARD_ABBREVIATION,
    CHANGE_RESPONSIBLE_TEAM,
    CLEAR_BOARD_HISTORY,
    CONFIRM_DELETE_BOARD,
    DELETE_BOARD,
    FORCE_UPDATE_BOARDS,
    RENAME_BOARD,
    SAVE_BOARD,
    SET_BOARD_DETAILS_FETCHED,
    SET_BOARD_TAGS,
    SET_BOARD_TO_SNAPSHOT,
    TOGGLE_BOARD_HISTORY,
    TOGGLE_CODY_CONSOLE,
    TOGGLE_CODY_SUGGEST_ENABLED,
    TOGGLE_ELEMENT_DETAILS,
    TOGGLE_EVENT_MODELING_ENABLED,
    TOGGLE_PLAYSHOT_MODAL,
    TOGGLE_TIMER_MODAL,
    TOGGLE_VIDEO_SESSION,
    TRIGGER_EXPORT_BOARD,
    TRIGGER_IMPORT_BOARD,
    UPDATE_BOARDS,
    USER_BOARD_MOVED_TO_ORGANIZATION,
} from '../actions/constants';
import {BoardModel} from '../model';
import {InspectioBoardsAction} from './index';

export interface BoardsState extends Map<string, BoardModel.Board> {
}

export const initialState: BoardsState = Map<string, BoardModel.Board>();

const reducer: Reducer<BoardsState, InspectioBoardsAction> = (state: BoardsState = initialState, action: InspectioBoardsAction): BoardsState => {
    switch (action.type) {
        case ADD_BOARD:
            const newBoard = action.payload.board;
            return state.set(newBoard.uid, newBoard);
        case UPDATE_BOARDS:
            const boards = action.payload;
            forEach(boards, (board: BoardModel.Board) => {
                // Do not override already fetched boards
                if(!state.get(board.uid)) {
                    state = state.set(board.uid, board)
                } else {
                    const existingBoard = state.get(board.uid);
                    state = state.set(board.uid, existingBoard!.syncSummaryInfo(board));
                }
            });
            return state;
        case FORCE_UPDATE_BOARDS:
            forEach(action.payload, (board: BoardModel.Board) => {
                state = state.set(board.uid, board)
            });
            return state;
        case SET_BOARD_TO_SNAPSHOT:
            let boardToUpdate: BoardModel.Board | undefined = state.get(action.payload.snapshot.boardId);

            if(boardToUpdate) {
                boardToUpdate = boardToUpdate.updateXmlFromSnapshot(action.payload.snapshot);
                boardToUpdate = boardToUpdate.set('detailsFetched', true);
                state = state.set(action.payload.snapshot.boardId, boardToUpdate);
            }
            return state;
        case SET_BOARD_DETAILS_FETCHED:
            let boardToUpdateFetched: BoardModel.Board | undefined = state.get(action.payload.boardId);

            if(boardToUpdateFetched) {
                boardToUpdateFetched = boardToUpdateFetched.set('detailsFetched', true);
                boardToUpdateFetched = boardToUpdateFetched.set('writeAccess', action.payload.writeAccess);
                state = state.set(action.payload.boardId, boardToUpdateFetched);
            }
            return state;
        case CLEAR_BOARD_HISTORY:
            // We use this action to also reset the detailsFetched flag, it is triggered by MxGraphBoard on component will unmount
            let boardToResetFetched: BoardModel.Board | undefined = state.get(action.payload.boardId);

            if(boardToResetFetched) {
                boardToResetFetched = boardToResetFetched.set('detailsFetched', false);
                state = state.set(action.payload.boardId, boardToResetFetched);
            }
            return state;
        case SAVE_BOARD:
            let boardToSave = state.get(action.payload.board.uid);

            if(boardToSave) {
                boardToSave = boardToSave.updateXmlAndVersionFromBoard(action.payload.board);
                state = state.set(action.payload.board.uid, boardToSave);
            }
            return state;
        case RENAME_BOARD:
        case CHANGE_BOARD_ABBREVIATION:
        case SET_BOARD_TAGS:
        case CHANGE_RESPONSIBLE_TEAM:
        case TOGGLE_CODY_SUGGEST_ENABLED:
        case TOGGLE_EVENT_MODELING_ENABLED:
            return  state.setIn([action.payload.board.uid], action.payload.board);
        case CONFIRM_DELETE_BOARD:
            return state.setIn([action.payload.boardId, 'inDeletion'], true);
        case ABORT_BOARD_DELETION:
            return state.setIn([action.payload.boardId, 'inDeletion'], false);
        case DELETE_BOARD:
            return state.removeIn([action.payload.boardId]);
        case TRIGGER_IMPORT_BOARD:
            return state.setIn([action.payload.boardId, 'shouldImport'], true);
        case BOARD_IMPORTED:
        case BOARD_IMPORT_STARTED:
            return state.setIn([action.payload.boardId, 'shouldImport'], false);
        case TRIGGER_EXPORT_BOARD:
            return state.setIn([action.payload.boardId, 'shouldExport'], true);
        case BOARD_EXPORTED:
            return state.setIn([action.payload.boardId, 'shouldExport'], false);
        case TOGGLE_BOARD_HISTORY:
            if (action.payload.visible) {
                state = state.setIn([action.payload.boardId, 'shouldShowElementDetails'], false);
            }

            return state.setIn([action.payload.boardId, 'shouldShowHistory'], action.payload.visible);
        case TOGGLE_ELEMENT_DETAILS:
            if (action.payload.visible) {
                state = state.setIn([action.payload.boardId, 'shouldShowHistory'], false);
            }

            state = state.setIn([action.payload.boardId, 'shouldFocusMetadataEditor'], action.payload.focusEditor);

            return state.setIn([action.payload.boardId, 'shouldShowElementDetails'], action.payload.visible);
        case TOGGLE_CODY_CONSOLE:
            return state.setIn([action.payload.boardId, 'shouldShowCodyConsole'], action.payload.visible);
        case TOGGLE_PLAYSHOT_MODAL:
            return state.setIn([action.payload.boardId, 'shouldShowPlayshotModal'], action.payload.visible)
        case TOGGLE_TIMER_MODAL:
            state = state.setIn([action.payload.boardId, 'shouldShowTimerModal'], action.payload.visible);
            return state;
        case TOGGLE_VIDEO_SESSION:
            state = state.setIn([action.payload.boardId, 'videoSessionActive'], action.payload.active);
            return state;
        case BOARD_ACCESS_REVOKED:
            const members: List<UserModel.UserId> = state.getIn([action.payload.boardId, 'sharedWith'], List());
            return state.setIn([action.payload.boardId, 'sharedWith'], members.filter(m => m !== action.payload.userId));
        case USER_BOARD_MOVED_TO_ORGANIZATION:
            return state.set(action.payload.uid, action.payload);
        // Team Events
        case TeamAction.Type.TEAM_ACCESS_TO_BOARD_GRANTED:
            const teamsAdd: List<Team.TeamId> = state.getIn([action.payload.boardId, 'assignedTeams'], List());
            return state.setIn([action.payload.boardId, 'assignedTeams'], teamsAdd.push(action.payload.teamId));
        case TeamAction.Type.TEAM_ACCESS_TO_BOARD_REVOKED:
            const teamsRevoke: List<Team.TeamId> = state.getIn([action.payload.boardId, 'assignedTeams'], List());
            return state.setIn([action.payload.boardId, 'assignedTeams'], teamsRevoke.filter(t => t !== action.payload.teamId));
        case BOARD_SHARED_WITH_ORGANIZATION_MEMBERS:
            state = state.setIn([action.payload.boardId, 'orgaMemberPermissions'], action.payload.permissions);
            return state;
        case BOARD_SHARED_WITH_ANYONE:
            state = state.setIn([action.payload.boardId, 'anyonePermissions'], action.payload.permissions);
            return state;
        default:
            return state;
    }
};

export { reducer as saveBoardReducer };
