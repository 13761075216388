import {Node, NodeType} from "../../../model/Graph";
import {getVoMetadata} from "./get-vo-metadata";
import {isListVo} from "./is-list-vo";
import {isQueryableVo} from "./is-queryable-vo";

export const isQueryableItemVo = (node: Node): boolean => {
  if(node.getType() !== NodeType.document) {
    return false;
  }

  if(isListVo(node)) {
    return false;
  }

  const meta = getVoMetadata(node);

  return isQueryableVo(node);
}
