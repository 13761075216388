import {fromJS, List} from "immutable";
import {useEffect} from "react";
import * as React from 'react';
import {WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Container, Dimmer, Grid, Header, Icon, Loader, Message} from 'semantic-ui-react';
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";
import {
    makeActiveUserOrganizationInfoSelector,
    makeUserOrganizationInfoListSelector
} from "../../Organization/selectors/selectUserOrganizationInfo";
import * as Routes from "../../routes";
import {isStandalone} from "../../standalone/util";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {Command} from "../actions";
import {
    fetchBoardPermissionsForTeams,
    fetchBoardPermissionsForUsers,
    fetchOpenBoardInvitations,
    fetchUserBoard
} from "../actions/queries";
import {BoardModel} from "../model";
import {default as ConfirmBoardDeletionForm, ImFormData as DeletionImFormData} from './ConfirmBoardDeletionForm';
import BoardClientConfiguration from "./EditBoard/BoardClientConfiguration";
import BoardPermissionsMenu from "./EditBoard/BoardPermissionsMenu";
import BoardStickyTemplates from "./EditBoard/BoardStickyTemplates";
import MoveBoardMenu from "./EditBoard/MoveBoardMenu";
import {default as EditBoardForm} from './EditBoardForm';
import {BoardRouteMatchParams} from "./types";
import BoardTags from "./EditBoard/BoardTags";
import CodySuggestEnabled from "./EditBoard/CodySuggestEnabled";
import EventModelingEnabled from "./EditBoard/EventModelingEnabled";

interface PropsFromState {
    board?: BoardModel.Board;
}

interface PropsFromDispatch {
    confirmDeleteBoard: typeof Command.confirmDeleteBoard;
    deleteBoard: typeof Command.deleteBoard;
    abortDeletion: typeof Command.abortBoardDeletion;
    shareBoard: typeof Command.shareBoard;
}

interface OwnProps {

}

export type EditBoardProps = PropsFromState & PropsFromDispatch & OwnProps & WithNamespaces & RouteComponentProps<BoardRouteMatchParams>;

const handleConfirmDeletion = (data: DeletionImFormData, props: EditBoardProps) => {
    props.deleteBoard(data.get('uid') as string);
    props.history.replace(Routes.inspectio);
}


export const EditBoard = (props: EditBoardProps) => {

    const dispatch = useDispatch();
    const boardId = props.board ? props.board.uid : undefined;
    const activeOrganization = useSelector(makeActiveUserOrganizationInfoSelector());
    const currentUser = useSelector(makeGetCurrentUser());
    const organizations = useSelector(makeUserOrganizationInfoListSelector(currentUser.uid));

    useEffect(
        () => {
            if (props.board) {
                dispatch(fetchBoardPermissionsForUsers(props.board.uid));
                dispatch(fetchBoardPermissionsForTeams(props.board.uid));
                dispatch(fetchOpenBoardInvitations(props.board.uid));
            }
        },
        [boardId]
    );

    if(!props.board) {
        dispatch(fetchUserBoard(props.match.params.uid));
        return <Dimmer className="page"><Loader size="large">Loading Board</Loader></Dimmer>;
    }

    const isPrivateWorkspace = !activeOrganization || !activeOrganization.explicitOrganization;
    const validTargetOrganizations = isPrivateWorkspace? organizations.filter(orga => orga.explicitOrganization && !orga.userIsGuest) : List();
    const isMovableBoard = isPrivateWorkspace
        && !props.board.organization
        && validTargetOrganizations.count() > 0;

    return <Container>
    <Grid stackable={true} padded={true}>
    <Grid.Row>
        <Grid.Column computer="1" largeScreen="2" widescreen="3" />
        <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
            <Header as="h1" icon={true} textAlign='center'>
            <Icon name='settings' />
            <Header.Content>
                { props.t("insp.board.edit").replace('%name%', props.board.name) }
                <Header.Subheader>{ props.t("insp.board.edit_header")}</Header.Subheader>
            </Header.Content>
            </Header>
        </Grid.Column>
    </Grid.Row>
    <Grid.Row>
        <Grid.Column computer="1" largeScreen="2" widescreen="3" />
        <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
        <div className="ui segments">
            <div className="ui segment">
            <EditBoardForm board={props.board} />
            </div>
            <div className="ui segment">
                {!props.board.inDeletion &&
                <Message>
                    {props.t('insp.board.delete_txt1')}<a href="#" onClick={e => {e.preventDefault(); e.stopPropagation(); props.confirmDeleteBoard(props.board!.uid)}}>{props.t('insp.board.delete_link')}</a>{props.t('insp.board.delete_txt2')}
                </Message>
                }
                {props.board.inDeletion &&
                <ConfirmBoardDeletionForm
                    boardName={props.board.name}
                    initialValues={fromJS({confirmedName: '', uid: props.board.uid})}
                    t={props.t}
                    onSubmit={(data) => {handleConfirmDeletion(data, props)}}
                    onCancel={() => props.abortDeletion(props.board!.uid)}/>
                }
            </div>
        </div>
        </Grid.Column>
    </Grid.Row>
    <Grid.Row>
        <Grid.Column computer="1" largeScreen="2" widescreen="3" />
        <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
            <div className="ui segments">
                <div className="ui segment">
                    <h2 className="ui header">
                        {props.t('insp.board.form.cody_suggest')}
                        <HeaderSubHeader>{ props.t('insp.board.cody_suggest_sub') }</HeaderSubHeader>
                    </h2>
                    <CodySuggestEnabled board={props.board} />
                </div>
            </div>
        </Grid.Column>
    </Grid.Row>
    <Grid.Row>
        <Grid.Column computer="1" largeScreen="2" widescreen="3" />
        <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
            <div className="ui segments">
                <div className="ui segment">
                    <h2 className="ui header">
                        {props.t('insp.board.form.event_modeling')}
                        <HeaderSubHeader>{ props.t('insp.board.event_modeling_sub') }</HeaderSubHeader>
                    </h2>
                    <EventModelingEnabled board={props.board} />
                </div>
            </div>
        </Grid.Column>
    </Grid.Row>
    <Grid.Row>
        <Grid.Column computer="1" largeScreen="2" widescreen="3" />
        <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
            <div className="ui segments">
                <div className="ui segment">
                    <h2 className="ui header">
                        Tags
                        <HeaderSubHeader>{ props.t('insp.board.tags_sub') }</HeaderSubHeader>
                    </h2>
                    <BoardTags board={props.board} />
                </div>
            </div>
        </Grid.Column>
    </Grid.Row>
    {!isStandalone() && (
        <Grid.Row>
            <Grid.Column computer="1" largeScreen="2" widescreen="3" />
            <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
                <div className="ui segments">
                    <div className="ui segment">
                        <h2 className="ui header">
                            {props.t('insp.board.share')}
                            <HeaderSubHeader>
                                { props.t('insp.board.share_sub') }
                                <a href="https://wiki.prooph-board.com/access_management/Access-Rights.html"
                                   target="_blank" style={{float: 'right'}}
                                   title="Learn more in the wiki"
                                ><Icon name="question circle outline" /></a>
                            </HeaderSubHeader>
                        </h2>
                        <BoardPermissionsMenu board={props.board} />
                    </div>
                </div>
            </Grid.Column>
        </Grid.Row>
    )}
    <Grid.Row>
        <Grid.Column computer="1" largeScreen="2" widescreen="3" />
        <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
            {props.board && <BoardStickyTemplates boardId={props.board.uid} />}
        </Grid.Column>
    </Grid.Row>
    {!isStandalone() && isMovableBoard && (
        <Grid.Row>
            <Grid.Column computer="1" largeScreen="2" widescreen="3" />
            <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
                <div className="ui segments">
                    <div className="ui segment">
                        <h2 className="ui header">
                            {props.t('insp.board.move_to_organization_title')}
                            <HeaderSubHeader>{ props.t('insp.board.move_to_organization_sub_title') }</HeaderSubHeader>
                        </h2>
                        <MoveBoardMenu boardId={props.board.uid} organizations={validTargetOrganizations} />
                    </div>
                </div>
            </Grid.Column>
        </Grid.Row>
    )}
    {!isStandalone() && (
        <Grid.Row>
            <Grid.Column computer="1" largeScreen="2" widescreen="3" />
            <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
                {props.board && <BoardClientConfiguration boardId={props.board.uid} />}
            </Grid.Column>
        </Grid.Row>
    )}
</Grid>
</Container>};


