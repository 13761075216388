import * as React from 'react';
import {useSelector} from "react-redux";
import {NavLink, RouteComponentProps, withRouter} from "react-router-dom";
import {Icon} from "semantic-ui-react";
import {makeTeamListSelector} from "../../InspectioTeams/selectors/selectTeam";
import * as Routes from "../../routes";

interface OwnProps {

}

type TeamOverviewProps = OwnProps & RouteComponentProps;

const TeamOverview = (props: TeamOverviewProps) => {
    const teamList = useSelector(makeTeamListSelector(false));

    let badgeClasses = "ui small label";

    if(props.location.pathname === Routes.teamOverview) {
        badgeClasses += " teal";
    }

    return (
        <NavLink exact={true} className="item" activeClassName="active" to={Routes.teamOverview}>
            <Icon className="ui group icon"/>
            <div className={badgeClasses}>{teamList.count()}</div>
            Teams
        </NavLink>
    );
};

export default withRouter(TeamOverview);
