import {JSONSchema7, JSONSchema7Definition} from "json-schema";

export interface Definitions {[key: string]: JSONSchema7Definition}

export const makeDefinitions = (...rest: Array<Record<string, JSONSchema7>>): Definitions => {
  const schema: JSONSchema7 = {
    definitions: {
      rule_definition: {
        oneOf: [
          {
            type: "object",
            properties: {
              rule: {
                type: "string",
                enum: ["always"]
              },
              then: {
                $ref: "#/definitions/rule_then"
              }
            },
            required: ["rule", "then"],
            additionalProperties: false
          },
          {
            type: "object",
            properties: {
              rule: {
                type: "string",
                enum: ["condition"]
              },
              then: {
                $ref: "#/definitions/rule_then"
              },
              if: {
                type: "string"
              },
              else: {
                $ref: "#/definitions/rule_then"
              },
              stop: {
                type: "boolean"
              }
            },
            required: ["rule", "then", "if"],
            additionalProperties: false
          },
          {
            type: "object",
            properties: {
              rule: {
                type: "string",
                enum: ["condition"]
              },
              then: {
                $ref: "#/definitions/rule_then"
              },
              if_not: {
                type: "string"
              },
              else: {
                $ref: "#/definitions/rule_then"
              },
              stop: {
                type: "boolean"
              }
            },
            required: ["rule", "then", "if_not"],
            additionalProperties: false
          }
        ]
      },
      rule_then: {
        oneOf: []
      },
      ...makeRuleThenPropMapping()
    }
  }

  rest.forEach(rec => {
    // tslint:disable-next-line:forin
    for (const recKey in rec) {
      schema.definitions![recKey] = rec[recKey];
      (schema.definitions!.rule_then as JSONSchema7).oneOf!.push({$ref: `#/definitions/${recKey}`})
    }
  })

  return schema.definitions!;
}

export const withSuggestionPropMapping = (definitions: Definitions, possibleProps: string[], fullMergeSuggestions: string[], mappingSuggestions: string[]): Definitions => {
  return {
    ...definitions,
    ...makeRuleThenPropMapping(possibleProps, fullMergeSuggestions, mappingSuggestions),
  }
}

export const makeRuleThenPropMapping = (possibleProps?: string[], fullMergeSuggestions?: string[], mappingSuggestions?: string[]): {rule_then_prop_mapping: JSONSchema7} => {
  const fullMerge: JSONSchema7 = fullMergeSuggestions
    ? { anyOf: [{type: "string"}, {enum: fullMergeSuggestions}] } : {type: "string"};

  const mapping: JSONSchema7 = mappingSuggestions
    ? { anyOf: [{type: "string"}, {enum: mappingSuggestions}] } : {type: "string"};

  const mergeSchema: JSONSchema7 = {
    oneOf: [
      fullMerge,
      {type: "array", items: fullMerge}
    ]
  };

  if(possibleProps) {
    const props: Record<string, JSONSchema7> = {};
    possibleProps.forEach(prop => {
      props[prop] = mapping;
    })

    props.$merge = mergeSchema;
    props.$not = {type: "string", enum: possibleProps}

    return {
      rule_then_prop_mapping: {
        oneOf: [
          fullMerge,
          {
            type: "object",
            properties: props,
          }
        ]
      }
    }
  }

  return {
    rule_then_prop_mapping: {
      oneOf: [
        fullMerge,
        {
          type: "object",
          patternProperties: {
            "^\$merge$": mergeSchema,
            "^\$not$": {type: "string"},
            "^[^\$]+$": mapping
          }
        }
      ]
    }
  }
}

export const makeThenRecordEvent = (availableEvents: string[]): {rule_then_record_event: JSONSchema7} => {
  return {
    rule_then_record_event: {
      type: "object",
      properties: {
        record: {
          type: "object",
          properties: {
            event: {
              type: "string",
              enum: availableEvents
            },
            mapping: {
              $ref: "#/definitions/rule_then_prop_mapping"
            }
          },
          additionalProperties: false,
          required: ["event", "mapping"]
        }
      },
      additionalProperties: false,
      required: ["record"]
    }
  }
}

export const makeThenTriggerCommand = (availableCommands: string[]): {rule_then_trigger_command: JSONSchema7} => {
  return {
    rule_then_trigger_command: {
      type: "object",
      properties: {
        trigger: {
          type: "object",
          properties: {
            command: {
              type: "string",
              enum: availableCommands,
            },
            mapping: {
              $ref: "#/definitions/rule_then_prop_mapping"
            }
          },
          additionalProperties: false,
          required: ["command", "mapping"]
        }
      },
      additionalProperties: false,
      required: ["trigger"]
    }
  }
}

export const makeThenChangeInformation = (possibleStateRefs: string[]): {
  rule_then_insert_information: JSONSchema7,
  rule_then_upsert_information: JSONSchema7,
  rule_then_update_information: JSONSchema7,
  rule_then_replace_information: JSONSchema7,
  rule_then_delete_information: JSONSchema7,
} => {
  return {
    rule_then_insert_information: {
      type: "object",
      additionalProperties: false,
      required: ["insert"],
      properties: {
        insert: {
          type: "object",
          additionalProperties: false,
          required: ["information", "id", "set"],
          properties: {
            information: {
              type: "string",
              enum: possibleStateRefs
            },
            id: {type: "string", minLength: 1},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_upsert_information: {
      type: "object",
      additionalProperties: false,
      required: ["upsert"],
      properties: {
        upsert: {
          type: "object",
          additionalProperties: false,
          required: ["information", "id", "set"],
          properties: {
            information: {
              type: "string",
              enum: possibleStateRefs
            },
            id: {type: "string", minLength: 1},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_update_information: {
      type: "object",
      additionalProperties: false,
      required: ["update"],
      properties: {
        update: {
          type: "object",
          additionalProperties: false,
          required: ["information", "filter", "set"],
          properties: {
            information: {
              type: "string",
              enum: possibleStateRefs
            },
            filter: {$ref: "#/definitions/resolve_filter_types"},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_replace_information: {
      type: "object",
      additionalProperties: false,
      required: ["replace"],
      properties: {
        replace: {
          type: "object",
          additionalProperties: false,
          required: ["information", "filter", "set"],
          properties: {
            information: {
              type: "string",
              enum: possibleStateRefs
            },
            filter: {$ref: "#/definitions/resolve_filter_types"},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_delete_information: {
      type: "object",
      additionalProperties: false,
      required: ["update"],
      properties: {
        update: {
          type: "object",
          additionalProperties: false,
          required: ["information", "filter", "set"],
          properties: {
            information: {
              type: "string",
              enum: possibleStateRefs
            },
            filter: {$ref: "#/definitions/resolve_filter_types"}
          }
        }
      }
    }
  }
}

export const makeThenProjectionOperations = (): {
  rule_then_insert_projection: JSONSchema7,
  rule_then_upsert_projection: JSONSchema7,
  rule_then_update_projection: JSONSchema7,
  rule_then_replace_projection: JSONSchema7,
  rule_then_delete_projection: JSONSchema7,
} => {
  return {
    rule_then_insert_projection: {
      type: "object",
      additionalProperties: false,
      required: ["insert"],
      properties: {
        insert: {
          type: "object",
          additionalProperties: false,
          required: ["id", "set"],
          properties: {
            id: {type: "string", minLength: 1},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_upsert_projection: {
      type: "object",
      additionalProperties: false,
      required: ["upsert"],
      properties: {
        upsert: {
          type: "object",
          additionalProperties: false,
          required: ["id", "set"],
          properties: {
            id: {type: "string", minLength: 1},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_update_projection: {
      type: "object",
      additionalProperties: false,
      required: ["update"],
      properties: {
        update: {
          type: "object",
          additionalProperties: false,
          required: ["filter", "set"],
          properties: {
            filter: {$ref: "#/definitions/resolve_filter_types"},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_replace_projection: {
      type: "object",
      additionalProperties: false,
      required: ["replace"],
      properties: {
        replace: {
          type: "object",
          additionalProperties: false,
          required: ["filter", "set"],
          properties: {
            filter: {$ref: "#/definitions/resolve_filter_types"},
            set: {$ref: "#/definitions/rule_then_prop_mapping"},
            meta: {$ref: "#/definitions/rule_then_prop_mapping"},
            version: {type: "number"}
          }
        }
      }
    },
    rule_then_delete_projection: {
      type: "object",
      additionalProperties: false,
      required: ["update"],
      properties: {
        update: {
          type: "object",
          additionalProperties: false,
          required: ["filter", "set"],
          properties: {
            filter: {$ref: "#/definitions/resolve_filter_types"}
          }
        }
      }
    }
  }
}

export const makeThenThrowError = (): {rule_then_throw_error: JSONSchema7} => {
  return {
    rule_then_throw_error: {
      type: "object",
      additionalProperties: false,
      required: ["throw"],
      properties: {
        throw: {
          type: "object",
          additionalProperties: false,
          required: ["error"],
          properties: {
            "error": {type: "string", default: "''"},
          }
        }
      }
    }
  }
}

export const makeAssignVariable = (fixedVariableName?: string): {rule_then_assign_variable: JSONSchema7} => {
  return {
    rule_then_assign_variable: {
      type: "object",
      additionalProperties: false,
      required: ["assign"],
      properties: {
        assign: {
          type: "object",
          additionalProperties: false,
          required: ["variable", "value"],
          properties: {
            "variable":  (fixedVariableName? {const: fixedVariableName} : {type: "string", minLength: 1}),
            "value": {$ref: "#/definitions/rule_then_prop_mapping"}
          }
        }
      }
    }
  }
}

export const makeTriggerCommand = (possibleCommands: string[]): {rule_then_trigger_command: JSONSchema7} => {
  return {
    rule_then_trigger_command: {
      type: "object",
      additionalProperties: false,
      required: ["trigger"],
      properties: {
        trigger: {
          type: "object",
          additionalProperties: false,
          required: ["command", "mapping"],
          properties: {
            command: {
              type: "string",
              enum: possibleCommands,
            },
            mapping: {
              $ref: "#/definitions/rule_then_prop_mapping"
            },
            meta: {
              $ref: "#/definitions/rule_then_prop_mapping"
            }
          }
        }
      }
    }
  }
}

export const makeCallService = (possibleServices: string[]): {rule_then_call_service: JSONSchema7} => {
  return {
    rule_then_call_service: {
      type: "object",
      additionalProperties: false,
      required: ["call"],
      properties: {
        call: {
          type: "object",
          additionalProperties: false,
          required: ["service", "result"],
          properties: {
            service: {type: "string", enum: possibleServices},
            arguments: {$ref: "#/definitions/rule_then_prop_mapping"},
            method: {type: "string", minLength: 1},
            async: {type: "boolean", default: true},
            result: {
              type: "object",
              additionalProperties: false,
              required: ["variable"],
              properties: {
                variable: {type: "string", minLength: 1},
                mapping: {$ref: "#/definitions/rule_then_prop_mapping"}
              }
            }
          }
        }
      }
    }
  }
}

export const makeForEach = (): {rule_then_for_each: JSONSchema7} => {
  return {
    rule_then_for_each: {
      type: "object",
      additionalProperties: false,
      required: ["forEach"],
      properties: {
        forEach: {
          type: "object",
          additionalProperties: false,
          required: ["variable", "then"],
          properties: {
            variable: {type: "string", minLength: 1},
            then: {$ref: "#/definitions/rule_then"}
          }
        }
      }
    }
  }
}

export const makeExecuteRules = (): {rule_then_execute_rules: JSONSchema7} => {
  return {
    rule_then_execute_rules: {
      type: "object",
      additionalProperties: false,
      required: ["execute"],
      properties: {
        execute: {
          type: "object",
          additionalProperties: false,
          required: ["rules"],
          properties: {
            rules: {type: "array", items: {$ref: "#/definitions/rule_definition"}, minItems: 1}
          }
        }
      }
    }
  }
}

export const withFilterTypes = (definitions: Definitions): Definitions => {
  return {
    ...definitions,
    ...makeFilter()
  }
}

export const withProjectionOperations = (definitions: Definitions): Definitions => {
  return {
    ...definitions,
    ...makeThenProjectionOperations()
  }
}

export const makeFilter = (): {
  resolve_filter_types: JSONSchema7,
  resolve_filter_expr: JSONSchema7,
  resolve_filter_and: JSONSchema7,
  resolve_filter_or: JSONSchema7,
  resolve_filter_not: JSONSchema7,
  resolve_filter_any: JSONSchema7,
  resolve_filter_any_of_doc_id: JSONSchema7,
  resolve_filter_any_of: JSONSchema7,
  resolve_filter_doc_id: JSONSchema7,
  resolve_filter_eq: JSONSchema7,
  resolve_filter_exists: JSONSchema7,
  resolve_filter_gte: JSONSchema7,
  resolve_filter_gt: JSONSchema7,
  resolve_filter_in_array: JSONSchema7,
  resolve_filter_like: JSONSchema7,
  resolve_filter_lte: JSONSchema7,
  resolve_filter_lt: JSONSchema7
} => {
  return {
    resolve_filter_types: {
      oneOf: [
        {$ref: "#/definitions/resolve_filter_and"},
        {$ref: "#/definitions/resolve_filter_or"},
        {$ref: "#/definitions/resolve_filter_not"},
        {$ref: "#/definitions/resolve_filter_any"},
        {$ref: "#/definitions/resolve_filter_any_of_doc_id"},
        {$ref: "#/definitions/resolve_filter_any_of"},
        {$ref: "#/definitions/resolve_filter_doc_id"},
        {$ref: "#/definitions/resolve_filter_eq"},
        {$ref: "#/definitions/resolve_filter_exists"},
        {$ref: "#/definitions/resolve_filter_gte"},
        {$ref: "#/definitions/resolve_filter_gt"},
        {$ref: "#/definitions/resolve_filter_in_array"},
        {$ref: "#/definitions/resolve_filter_like"},
        {$ref: "#/definitions/resolve_filter_lte"},
        {$ref: "#/definitions/resolve_filter_lt"},
      ]
    },
    resolve_filter_expr: {
      anyOf: [
        {type: "string"}
      ]
    },
    resolve_filter_and: {
      type: "object",
      additionalProperties: false,
      required: ["and"],
      properties: {
        and: {type: "array", items: {$ref: "#/definitions/resolve_filter_types"}, minItems: 2}
      }
    },
    resolve_filter_or: {
      type: "object",
      additionalProperties: false,
      required: ["or"],
      properties: {
        or: {type: "array", items: {$ref: "#/definitions/resolve_filter_types"}, minItems: 2}
      }
    },
    resolve_filter_not: {
      type: "object",
      additionalProperties: false,
      required: ["not"],
      properties: {
        not: {$ref: "#/definitions/resolve_filter_types"}
      }
    },
    resolve_filter_any: {
      type: "object",
      additionalProperties: false,
      required: ["any"],
      properties: {
        any: {type: "boolean", default: true}
      }
    },
    resolve_filter_any_of_doc_id: {
      type: "object",
      additionalProperties: false,
      required: ["anyOfDocId"],
      properties: {
        anyOfDocId: {$ref: "#/definitions/resolve_filter_expr"}
      }
    },
    resolve_filter_any_of: {
      type: "object",
      additionalProperties: false,
      required: ["anyOf"],
      properties: {
        anyOf: {
          type: "object",
          additionalProperties: false,
          required: ["prop", "valueList"],
          properties: {
            prop: {type: "string"},
            valueList: {$ref: "#/definitions/resolve_filter_expr"}
          }
        }
      }
    },
    resolve_filter_doc_id: {
      type: "object",
      additionalProperties: false,
      required: ["docId"],
      properties: {
        docId: {$ref: "#/definitions/resolve_filter_expr"}
      }
    },
    resolve_filter_eq: {
      type: "object",
      additionalProperties: false,
      required: ["eq"],
      properties: {
        eq: {
          type: "object",
          additionalProperties: false,
          required: ["prop", "value"],
          properties: {
            prop: {type: "string"},
            value: {$ref: "#/definitions/resolve_filter_expr"}
          }
        }
      }
    },
    resolve_filter_exists: {
      type: "object",
      additionalProperties: false,
      required: ["exists"],
      properties: {
        exists: {
          type: "object",
          additionalProperties: false,
          required: ["prop"],
          properties: {
            prop: {type: "string"},
          }
        }
      }
    },
    resolve_filter_gte: {
      type: "object",
      additionalProperties: false,
      required: ["gte"],
      properties: {
        gte: {
          type: "object",
          additionalProperties: false,
          required: ["prop", "value"],
          properties: {
            prop: {type: "string"},
            value: {$ref: "#/definitions/resolve_filter_expr"}
          }
        }
      }
    },
    resolve_filter_gt: {
      type: "object",
      additionalProperties: false,
      required: ["gt"],
      properties: {
        gt: {
          type: "object",
          additionalProperties: false,
          required: ["prop", "value"],
          properties: {
            prop: {type: "string"},
            value: {$ref: "#/definitions/resolve_filter_expr"}
          }
        }
      }
    },
    resolve_filter_in_array: {
      type: "object",
      additionalProperties: false,
      required: ["inArray"],
      properties: {
        inArray: {
          type: "object",
          additionalProperties: false,
          required: ["prop", "value"],
          properties: {
            prop: {type: "string"},
            value: {$ref: "#/definitions/resolve_filter_expr"}
          }
        }
      }
    },
    resolve_filter_like: {
      type: "object",
      additionalProperties: false,
      required: ["like"],
      properties: {
        like: {
          type: "object",
          additionalProperties: false,
          required: ["prop", "value"],
          properties: {
            prop: {type: "string"},
            value: {$ref: "#/definitions/resolve_filter_expr"}
          }
        }
      }
    },
    resolve_filter_lte: {
      type: "object",
      additionalProperties: false,
      required: ["lte"],
      properties: {
        lte: {
          type: "object",
          additionalProperties: false,
          required: ["prop", "value"],
          properties: {
            prop: {type: "string"},
            value: {$ref: "#/definitions/resolve_filter_expr"}
          }
        }
      }
    },
    resolve_filter_lt: {
      type: "object",
      additionalProperties: false,
      required: ["lt"],
      properties: {
        lt: {
          type: "object",
          additionalProperties: false,
          required: ["prop", "value"],
          properties: {
            prop: {type: "string"},
            value: {$ref: "#/definitions/resolve_filter_expr"}
          }
        }
      }
    }
  }
}
