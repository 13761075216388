import * as React from 'react';
import {Route, RouteComponentProps, Switch, withRouter} from "react-router";
import BoardOverviewSearch from "../../InspectioBoards/components/BoardOverviewSearch";
import MxGraphBoardSearch from "../../InspectioBoards/components/MxGraphBoardSearch";
import * as Routes from '../../routes';

type SearchProps = RouteComponentProps;

const Search = (props: SearchProps) => {
    return <Switch>
        <Route exact={true} path={Routes.inspectioBoardWorkspace} component={MxGraphBoardSearch} />
    </Switch>
};

export default withRouter(Search);
