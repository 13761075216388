import v4 from "uuid/v4";
import {BoardId} from "../../model/Board";
import {CodyResponse} from "../Cody";
import {savePlayshot} from "../playshot-storage";
import {CodyServer} from "./CodyServer";
import {Either, execValidate, pipe} from "../../../core/validation/either";
import {hasKey} from "../../../core/validation/predicates";

interface Message {
  messageId: string;
  messageName: string;
  payload: any;
}

interface Response {
  responseTo: string;
  codyResponse: CodyResponse;
}

interface PlayCommand {
  command: 'SavePlayshot';
  payload: any;
}

export interface Playshot {
  playshotId: string;
  boardId: string;
  name: string;
  playConfig: object;
  playData: {
    streams: object;
    documents: object;
  }
}

export const validatePlayshot = (playshot: Playshot): Either<string, Playshot> => {
  return pipe(
    playshot,
    execValidate([hasKey('playshotId'), "playshotId is missing in playshot"]),
    execValidate([hasKey('boardId'), "boardId is missing in playshot"]),
    execValidate([hasKey('name'), "name is missing in playshot"]),
    execValidate([hasKey('playConfig'), "playConfig is missing in playshot"]),
    execValidate([hasKey('playData'), "playData is missing in playshot"]),
  )
}

type PlayCommandListener = (msg: MessageEvent) => void;

export class PlayServer implements CodyServer {
  public readonly name: string;
  private playTab: typeof window | undefined;
  private sync: boolean = false;
  private playUrl: string;
  private boardId: BoardId;
  private playCommandListener: PlayCommandListener | undefined;
  private connected = false;
  private pendingMessages: Message[] = [];

  public constructor(name: string, playUrl: string, boardId: BoardId) {
    this.name = name;
    this.playUrl = playUrl;
    this.boardId = boardId;
  }

  public isSyncEnabled(): boolean {
    return this.sync;
  }

  public enableSync(): void {
    this.sync = true;
  }

  public async sendMessage(messageName: string, payload: any): Promise<CodyResponse> {
    return this.postMessage(messageName, payload);
  }

  public async syncDeletedNodes(messageName: string, payload: any): Promise<CodyResponse> {
    return this.postMessage(messageName, payload);
  }

  public async syncNodes(messageName: string, payload: any): Promise<CodyResponse> {
    return this.postMessage(messageName, payload);
  }

  public async fullSync(messageName: string, payload: any): Promise<CodyResponse> {
    return this.postMessage('FullSync', payload);
  }

  private async postMessage(messageName: string, payload: any): Promise<CodyResponse> {
    await this.reconnectIfNeeded();

    const message = this.makeMessage(messageName, payload);

    const promise = this.attachListener(message);

    if(this.playTab && this.connected) {
      this.playTab.postMessage(JSON.stringify(message), this.playUrl);
    } else {
      console.log("[CodyPlayServer] server not ready. Going to queue message: ", message);
      this.pendingMessages.push(message);
    }

    return promise;
  }

  private async reconnectIfNeeded(): Promise<void> {
    if(this.playTab && !this.playTab.closed) {
      return;
    }

    this.connected = false;

    if(this.playCommandListener) {
      window.removeEventListener('message', this.playCommandListener);
    } else {
      this.playCommandListener = (playCmd: MessageEvent) => {
        if(playCmd.origin !== this.playUrl) {
          // Ignore unknown
          return;
        }

        try {
          const parsedCmd: PlayCommand = JSON.parse(playCmd.data);

          if(parsedCmd.command && parsedCmd.command === "SavePlayshot") {
            this.handleSavePlayshot(parsedCmd.payload);
          }
        } catch (e) {
          console.error(`[CodyPlayServer] Failed to parse Cody Play command`, e);
        }
      }
    }

    const playTab = window.open(this.playUrl + `?board=${this.boardId}`, 'CodyPlay');

    if(!playTab) {
      throw new Error(`Failed to open Cody Play tab. Probably blocked by browser. Please check your browser settings and plugins like AdBlock.`);
    }

    this.playTab = playTab;

    return new Promise(resolve => {
      const handshakeListener = (msg: MessageEvent) => {
        if(msg.origin === this.playUrl) {
          try {
            const ping = JSON.parse(msg.data);

            if(!ping.ping) {
              return;
            }

            if(ping.ping !== '[CodyMessageServer]') {
              return;
            }
          } catch (e) {
            // ignore
            return;
          }

          console.log("[CodyPlayServer] connected");
          this.connected = true;

          const pendingMessages = this.pendingMessages;
          this.pendingMessages = [];
          pendingMessages.forEach(m => {
            console.log("[CodyPlayServer] Sending queued message: ", m);
            this.playTab!.postMessage(JSON.stringify(m), this.playUrl)
          })

          window.removeEventListener('message', handshakeListener);

          if(this.playCommandListener) {
            window.addEventListener('message', this.playCommandListener);
          }

          resolve();
        }
      }

      window.addEventListener('message', handshakeListener);
    })
  }

  private makeMessage(messageName: string, payload: any): Message {
    return {
      messageId: v4(),
      messageName,
      payload,
    }
  }

  private handleSavePlayshot(playshot: Playshot): void {
    console.log("[CodyPlayServer] Received a playshot: ", playshot);

    savePlayshot(playshot).then(success => {
      const playshotSaved = {
        playshotId: playshot.playshotId,
        success,
      }

      this.postMessage('PlayshotSaved', playshotSaved)
        .then(codyResponse => console.log(codyResponse))
        .catch(e => {console.error(e)});
    })
      .catch(e => {console.error(e)});
  }

  private attachListener(message: Message): Promise<CodyResponse> {
    return new Promise<CodyResponse>(resolve => {
      const listener = (res: MessageEvent) => {
        if(res.origin !== this.playUrl) {
          // Ignore unknown
          return;
        }

        const parsedRes: Response = JSON.parse(res.data);

        if(parsedRes.responseTo && parsedRes.responseTo === message.messageId) {
          window.removeEventListener('message', listener);
          resolve(parsedRes.codyResponse);
        }
      }

      window.addEventListener('message', listener);
    });
  }
}
