import {List, Record} from "immutable";
import {BoardVersion} from "./BoardVersion";
import {BoardHistory} from "./HistoryEntry";

export interface HistoryStateData {
    history: BoardHistory;
    loading: boolean;
    olderPatchesAvailable: boolean;
    oldestBoardVersion?: BoardVersion;
}

const defaultHistoryStateData: HistoryStateData = {
    history: List(),
    loading: false,
    oldestBoardVersion: undefined,
    olderPatchesAvailable: true,
};

export class HistoryState extends Record(defaultHistoryStateData) implements HistoryStateData {}