import * as React from 'react';
import {NavLink, RouteComponentProps, withRouter} from "react-router-dom";
import * as Routes from "../../routes";
import {makeUserSettingsSelector} from "../../User/selectors/userSettings";
import {useSelector} from "react-redux";
import {UpdateList} from "../../Updates/components/UpdatePage";


interface OwnProps {

}

type UpdatesProps = OwnProps & RouteComponentProps;

const Updates = (props: UpdatesProps) => {

    const userSettings = useSelector(makeUserSettingsSelector());

    const count = UpdateList
        .map(update => update.date)
        .filter(date => !userSettings[`update_${date}_viewed`])
        .length;

    return <NavLink className="item" to={Routes.Updates}>
        <div className={'ui small label' + (props.location.pathname === Routes.Updates? ' teal' : (count > 0? ' red' : ''))}>{count}</div>
        Updates
    </NavLink>
};

export default withRouter(Updates);
