import * as React from 'react';
import {Breadcrumb} from "semantic-ui-react";

interface OwnProps {

}

type OrganizationOverviewCrumbProps = OwnProps;

const OrganizationOverviewCrumb = (props: OrganizationOverviewCrumbProps) => {
    return <Breadcrumb.Section>Organization</Breadcrumb.Section>
};

export default OrganizationOverviewCrumb;
