import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import {teamMovedToOrganization} from "../actions/events";
import {Action} from "../index";
import {Team} from "../model";
import {makeTeamSelector} from "../selectors/selectTeam";

type Command = ActionType<typeof Action.Command.moveTeamToOrganization>

function* handleMoveTeamToOrganization(command: Command) {
    const team: Team.Team|undefined = yield select(makeTeamSelector(command.payload.teamId));

    if(!team) {
        yield put(NotifyAction.Command.error('Internal Error', 'Team could not be loaded from local store. Please contact support.'));
        return;
    }

    const {response, error}: ResponseType = yield call(Action.Api.moveTeamToOrganization, command.payload.teamId, command.payload.organizationId);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not move team to organization'));
    }
    if (response) {
        yield put(teamMovedToOrganization(team.moveToOrganization(command.payload.organizationId)));
        yield put(NotifyAction.Command.info('Team moved', 'Moved team successfully to organization.'));
    }
}

export function* moveTeamToOrganization() {
    while (true) {
        const command: Command = yield take([
            Action.Type.MOVE_TEAM_TO_ORGANIZATION
        ]);

        yield fork(handleMoveTeamToOrganization, command);
    }
}
