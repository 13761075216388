import {call, fork, put,take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {deleteInspectioBoard} from "../../core/localStorage";
import {Action as Notify, Action as NotifyAction} from "../../NotificationSystem";
import * as Action from "../actions";

type FlowAction = ActionType<typeof Action.Command.deleteBoard>;

function* deleteBoard(action: FlowAction) {
    let localError = null;

    deleteInspectioBoard(action.payload.boardId, (err) => {
        localError = err;
    })

    if (localError) {
        const errorNotification = Notify.Command.error('Failed to delete board in local storage', localError);
        yield put(errorNotification);
    }

    const {response, error}: ResponseType = yield call(Action.Api.deleteBoard, action.payload.boardId);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not delete board.'));
    }
    if (response) {
        yield put(NotifyAction.Command.info('Board deleted', 'Board deleted'));
    }
}

export function* deleteBoardFlow() {
    while (true) {
        const action: FlowAction = yield take([
            Action.Type.DELETE_BOARD
        ]);

        yield fork(deleteBoard, action);
    }
}
