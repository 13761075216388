import * as React from "react";
import {useSelector} from "react-redux";
import {Table} from "semantic-ui-react";
import {Board} from "../../model/Board";
import {makeOpenBoardInvitationListSelector} from "../../selectors/boardInvitation";
import OpenBoardInvitationsTableEntry from "./OpenBoardInvitationsTableEntry";
import {List} from "immutable";
import {BoardInvitation} from "../../model/BoardInvitation";

interface PropsFromState {
}

interface PropsFromDispatch {

}

interface OwnProps {
    board: Board;
    openInvitations: List<BoardInvitation>;
}

export type OpenBoardInvitationsTableProps = PropsFromState & PropsFromDispatch & OwnProps;

export const OpenBoardInvitationsTable = (props: OpenBoardInvitationsTableProps) => {

    return (
        <Table unstackable={true} basic="very">
            <Table.Body>
                {props.openInvitations.map(boardInvitation => (
                    <OpenBoardInvitationsTableEntry
                        key={boardInvitation.uid}
                        board={props.board}
                        boardInvitation={boardInvitation}
                    />
                ))}
            </Table.Body>
        </Table>
    );
};
