import * as React from 'react';
import {useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import {Dimmer, Loader} from "semantic-ui-react";
import {ResponseType} from "../../api/util";
import {exec, isFailure, isSuccess, pipe} from "../../core/validation/either";
import {Action as Notify, Action as NotifyAction} from "../../NotificationSystem";
import * as Routes from "../../routes";
import * as UserModule from "../../User";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {acceptInvitationToOrganization, fetchOrganization} from "../actions/api";
import {createOrganizationFromServerData} from "../model/Organization";
import {makeActiveUserOrganizationInfoSelector} from "../selectors/selectUserOrganizationInfo";
import {Action} from "../index";

interface OwnProps {

}

interface RouteMatchParams {
    organization: string;
    invitation: string;
}

type AcceptOrganizationInvitationProps = OwnProps & RouteComponentProps<RouteMatchParams> & WithNamespaces;

const AcceptOrganizationInvitation = (props: AcceptOrganizationInvitationProps) => {
    const organizationId = props.match.params.organization;
    const invitationId = props.match.params.invitation;

    const currentUserSelector = makeGetCurrentUser();

    const currentUser = useSelector(currentUserSelector);
    const activeOrganization = useSelector(makeActiveUserOrganizationInfoSelector());
    const [doRedirect, setDoRedirect] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if(currentUser.signedIn && activeOrganization) {
            let retries = 0;

            const handleAckResponse = (ackResponse: ResponseType) => {
                if(ackResponse.error) {
                    // Invited user is new and username sync seems still be in progress
                    if(retries < 2) {
                        retries++;
                        window.setTimeout(() => {
                            acceptInvitationToOrganization(organizationId, invitationId).then(handleAckResponse);
                        }, 100);
                        return;
                    }

                    dispatch(Notify.Command.error('Command failed', 'Failed to process organization invitation'));
                    setDoRedirect(true);
                    return;
                }

                fetchOrganization(organizationId)
                    .then(({response, error}) => {
                        if(error) {
                            dispatch(Notify.Command.info(
                                props.t('insp.organisation.joined.title'),
                                ''
                            ));
                            setDoRedirect(true);
                            return;
                        }

                        if(response) {
                            const result = pipe(
                                response.data,
                                exec((data: any) => createOrganizationFromServerData(data)),
                            );

                            if (isFailure(result)) {
                                console.error("Invalid organization response: ", result.value);
                                dispatch(NotifyAction.Command.error('Request Error', 'Could not load organization'));
                            }

                            if (isSuccess(result)) {
                                dispatch(Action.Query.fetchUserOrganizations());
                                dispatch(UserModule.Action.Command.switchActiveOrganization(organizationId));
                                dispatch(Notify.Command.info(
                                    props.t('insp.organisation.joined.title'),
                                    props.t('insp.organisation.joined.details', {name: result.value.name}))
                                );
                            }

                            setDoRedirect(true);
                        }
                    });
            };

            acceptInvitationToOrganization(organizationId, invitationId)
                .then(handleAckResponse);
        }
    }, [currentUser.signedIn, activeOrganization]);


    if(doRedirect) {
        return <Redirect to={Routes.inspectio} />
    }

    return <Dimmer active={true} className="page"><Loader size="large">Processing Organization Invitation</Loader></Dimmer>;
};

export default withNamespaces()(AcceptOrganizationInvitation);
