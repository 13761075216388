import {Graph, Node} from "../../../model/Graph";

export const isConnected = (node: Node): boolean => {
  return node.getTags().contains(ispConst.TAG_CONNECTED);
}

export const markAsConnected = (node: Node, graph: Graph): Node => {
  if(isConnected(node)) {
    return node;
  }

  const changedTags = node.getTags().push(ispConst.TAG_CONNECTED);

  graph.changeNodeTags(node, changedTags);

  return graph.getNode(node.getId()) as Node;
}

