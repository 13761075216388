import {fromJS} from "immutable";
import * as React from 'react';
import {ChangeEvent, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Icon, Message, Modal} from "semantic-ui-react";
import * as Action from "../../InspectioBoards/actions";
import {BoardModel} from "../../InspectioBoards/model";
import {makeGetAllBoards} from "../../InspectioBoards/selectors/boardList";
import {UserSelector} from "../../User/selectors";
import BoardQuotaReachedModal from "../../InspectioBoards/components/BoardQuotaReachedModal";
import {BoardId} from "../../InspectioBoards/model/Board";
import * as uuid from "uuid";
import {Redirect} from "react-router";
import * as Routes from "../../routes";

interface OwnProps {};

export enum ImportMode {
    newBoard = 'new_board',
    replaceBoard = 'replace_board',
    tempBoard = 'temp_board',
}

type ImportBoardProps = WithNamespaces & OwnProps;

const readFileContent = (file: File, handleFileContent: (content: string) => void) => {
    const reader = new FileReader();
    reader.onload = () => {
        let content = reader.result as string|null;

        if(content === null) {
            content = '';
        }

        handleFileContent(content);
    }
    reader.readAsText(file);
};

const extractBoardName = (file: File): string => {
    return file.name.split('.').slice(0, -1).join('.');
}

const ImportBoard = (props: ImportBoardProps) => {
    const uploadEl = useRef<HTMLInputElement>(null);
    const [disabled, setDisabled] = useState(false);
    const [showQuotaModal, setShowQuotaModal] = useState(false);
    const [askForImportMode, setAskForImportMode] = useState(false);
    const [currentFile, setCurrentFile] = useState<File|null>(null);
    const getUserQuota = UserSelector.makeGetCurrentBoardQuota();
    const userQuota = useSelector(getUserQuota);
    const getUser = UserSelector.makeGetCurrentUser();
    const user = useSelector(getUser);
    const getBoards = makeGetAllBoards();
    const boards = useSelector(getBoards);
    const [existingBoardName, setExistingBoardName] = useState('undefined');
    const [boardId, setBoardId] = useState<BoardId|undefined>(undefined);
    const dispatch = useDispatch();

    const handleClick = () => {
        uploadEl.current!.click();
    };

    const processFile = (boardName: string, file: File, importMode: ImportMode) => {
        if(importMode === ImportMode.newBoard) {
            if (userQuota === 0) {
                setShowQuotaModal(true);
                setDisabled(false);
                return;
            }

            readFileContent(file, (content) => {
                const newBoardId = uuid.v4();
                dispatch(Action.Command.importNewBoard(boardName, content, newBoardId));
                setDisabled(false);
                setBoardId(newBoardId);
            });
        } else {
            const existingBoardList = boards.filter((board) => {
                return board.name === boardName;
            });

            if(existingBoardList.count() > 0) {
                const existingBoard: BoardModel.Board = existingBoardList.first();

                readFileContent(file, (content) => {
                    const updatedBoard = existingBoard.updateXml(content, user.uid);
                    dispatch(Action.Command.importExistingBoard(updatedBoard));
                    // wait a moment, so that board xml gets updated before redirect to board workspace happens
                    window.setTimeout(() => {
                        setBoardId(updatedBoard.uid);
                    }, 50);
                    setDisabled(false);
                });
            }
        }

        uploadEl.current!.value = '';
    };

    const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
        setDisabled(true);

        const file = e.target.files![0] || null;

        const boardName = extractBoardName(file);

        const existingBoardList = boards.filter((board) => {
            return board.name === boardName;
        });

        if(existingBoardList.count() !== 0) {
            setCurrentFile(file);
            setExistingBoardName(boardName);
            setAskForImportMode(true);
            return;
        }

        processFile(extractBoardName(file), file, ImportMode.newBoard);
    }

    const handleQuotaModalClose = () => {
        setShowQuotaModal(false);
    }

    const handleImportModalClose = () => {
        setAskForImportMode(false);
    }

    const handleImportCopyClick = () => {
        setAskForImportMode(false);
        if(currentFile) {
            processFile(extractBoardName(currentFile) + " (Copy)", currentFile, ImportMode.newBoard);
        }
    };

    const handleOverrideClick = () => {
        setAskForImportMode(false);
        if(currentFile) {
            processFile(extractBoardName(currentFile), currentFile, ImportMode.replaceBoard);
        }
    }

    if(boardId && boards.filter(board => board.uid === boardId).count() === 1) {
        return <Redirect to={Routes.compileInspectioBoardWorkspace(boardId)} />;
    }

    return <div className="item">
        <Button className="large basic" onClick={handleClick} disabled={disabled}><i className="download icon" /> Import&nbsp;Board</Button>
        <input type="file" ref={uploadEl} style={{display: 'none'}} onChange={handleFileSelected} />
        <BoardQuotaReachedModal open={showQuotaModal} onClose={handleQuotaModalClose} />
        <Modal open={askForImportMode} closeIcon={true} onClose={handleImportModalClose}>
            <Modal.Header>{ props.t("insp.board.import") }</Modal.Header>
            <Modal.Content>
                <Message icon="warning" warning={true} content={props.t('insp.board.import_existing_board').replace('%name%', existingBoardName)} />
            </Modal.Content>
            <Modal.Actions>
                <Button primary={true} onClick={handleImportCopyClick}><Icon name='plus' /> { props.t('insp.board.btn_import_as_new') }</Button>
                <Button basic={true} onClick={handleOverrideClick}>{ props.t('insp.board.btn_replace_board') }</Button>
            </Modal.Actions>
        </Modal>
    </div>
};

export default withNamespaces()(ImportBoard);
