import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../index";
import {createStickyTemplateFromDefault, deriveStickTemplateId} from "../model/StickyTemplate";
import {makeStickyTemplateSelector} from "../selectors/StickyTemplate";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";

type SetStickyCommand = ActionType<typeof Action.Command.setStickyTemplate>;
type UpdateStickyAdvancedConfigCommand = ActionType<typeof Action.Command.updateStickyAdvancedConfig>;
type Command = SetStickyCommand & UpdateStickyAdvancedConfigCommand;

function* handleSetStickyTemplate(command: SetStickyCommand) {
    let stickyTemplate = yield select(makeStickyTemplateSelector(deriveStickTemplateId(command.payload.boardId, command.payload.cellType)));

    const {boardId, cellType, template} = command.payload;

    if (!stickyTemplate) {
        stickyTemplate = createStickyTemplateFromDefault(
            boardId,
            cellType,
            template
        )
    }

    const {response, error}: ResponseType = yield call(Action.Api.setStickyTemplate,
        stickyTemplate.boardId,
        stickyTemplate.cellType,
        template
    );

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not set sticky template.'));
    }
    if (response) {
        if(!command.payload.disableNotification) {
            yield put(NotifyAction.Command.info('Sticky Template set', 'Sticky template for ' + cellType + ' set'));
        }

        yield put(Action.Event.stickyTemplateSet(stickyTemplate.setTemplate(template)));
    }
}

function* handleUpdateStickyAdvancedConfig(command: UpdateStickyAdvancedConfigCommand) {
    let stickyTemplate = yield select(makeStickyTemplateSelector(deriveStickTemplateId(command.payload.boardId, command.payload.cellType)));

    if (!stickyTemplate) {
        stickyTemplate = createStickyTemplateFromDefault(
            command.payload.boardId,
            command.payload.cellType,
            ''
        );
    }

    const {response, error}: ResponseType = yield call(Action.Api.updateStickyAdvancedConfig,
        stickyTemplate.boardId,
        stickyTemplate.cellType,
        command.payload.templateType,
        command.payload.templateSchema || undefined
    );

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not update sticky advanced config.'));
    }
    if (response) {
        if(!command.payload.disableNotification) {
            yield put(NotifyAction.Command.info('Advanced Sticky Config updated', 'Advanced Sticky Config for ' + stickyTemplate.cellType + ' updated'));
        }
        stickyTemplate = stickyTemplate.setTemplateType(command.payload.templateType);

        if (command.payload.templateSchema) {
            stickyTemplate = stickyTemplate.setTemplateSchema(command.payload.templateSchema);
        }

        yield put(Action.Event.stickyAdvancedConfigUpdated(stickyTemplate));
    }
}

export function* updateStickyTemplate() {
    while (true) {
        const command: Command = yield take([
            Action.Type.SET_STICKY_TEMPLATE,
            Action.Type.UPDATE_STICKY_ADVANCED_CONFIG
        ]);

        if (command.type === Action.Type.SET_STICKY_TEMPLATE) {
            yield fork(handleSetStickyTemplate, command);
        } else {
            yield fork(handleUpdateStickyAdvancedConfig, command);
        }
    }
}
