import {Map} from 'immutable';
import {Reducer} from 'redux';
import {ActionType} from "typesafe-actions";
import {updateAggregate, upsertAggregates} from "../../core/reducer/applyAggregateChanged";
import {TEAM_INVITATION_RESENT, TEAM_INVITATION_REVOKED, TEAM_INVITATIONS_LOADED} from "../actions/constants";
import * as Events from "../actions/events";
import {TeamInvitation} from "../model/TeamInvitation";

type Event = ActionType<typeof Events>;

export interface TeamInvitationState extends Map<string, TeamInvitation> {
}

export const initialState: TeamInvitationState = Map<string, TeamInvitation>();

const reducer: Reducer<TeamInvitationState, Event> = (state: TeamInvitationState = initialState, event: Event): TeamInvitationState => {
    switch (event.type) {
        case TEAM_INVITATIONS_LOADED:
            return upsertAggregates(state, event.payload);
        case TEAM_INVITATION_RESENT:
            return updateAggregate(state, event.payload);
        case TEAM_INVITATION_REVOKED:
            return updateAggregate(state, event.payload);
        default:
            return state;
    }
};

export {reducer as saveTeamInvitationReducer};