import {List, Record} from 'immutable';
import * as uuid from 'uuid';
import {TeamId} from "../../InspectioTeams/model/Team";
import {OrganizationId} from "../../Organization/model/Organization";
import {UserModel} from "../../User";
import {UserId} from "../../User/model/UserInfo";
import {names} from "../service/cody-wizard/node/names";
import {DEFAULT_PERMISSIONS, Permissions} from "./BoardPermissionForUser";
import {BoardSnapshot} from "./BoardSnapshot";
import {BoardVersion, defaultBoardVersionProps} from "./BoardVersion";

export type BoardId = string;
export type BoardName = string;
export type ServiceName = string;
export type BoardXml = string;

export interface BoardProps {
    uid: BoardId;
    name: string;
    nameAbbreviation: string;
    tags: List<string>,
    version: BoardVersion;
    createdAt: string;
    xml?: BoardXml | null;
    lastModified?: string;
    inDeletion?: boolean;
    shouldImport?: boolean;
    shouldExport?: boolean;
    shouldShowHistory?: boolean;
    shouldShowElementDetails?: boolean;
    shouldFocusMetadataEditor?: boolean;
    shouldShowCodyConsole?: boolean;
    shouldShowTimerModal?: boolean;
    shouldShowPlayshotModal?: boolean;
    videoSessionActive?: boolean;
    detailsFetched?: boolean;
    shared: boolean;
    sharedWith: List<UserId>;
    assignedTeams: List<TeamId>;
    responsibleTeam?: TeamId;
    writeAccess?: boolean;
    example?: boolean;
    owner: UserId | null;
    creator: UserId | null;
    organization: OrganizationId | null;
    tempBoard: boolean;
    orgaMemberPermissions: Permissions;
    anyonePermissions: Permissions;
    codySuggestEnabled: boolean;
    eventModelingEnabled: boolean;
}

export const defaultBoardProps: BoardProps = {
    uid: '',
    name: '',
    nameAbbreviation: '',
    tags: List(),
    version: new BoardVersion(defaultBoardVersionProps),
    xml: null,
    createdAt: (() => new Date())().toISOString(),
    lastModified: (() => new Date())().toISOString(),
    inDeletion: false,
    shouldImport: false,
    shouldExport: false,
    shouldShowHistory: false,
    shouldShowElementDetails: false,
    shouldFocusMetadataEditor: false,
    shouldShowCodyConsole: false,
    shouldShowTimerModal: false,
    shouldShowPlayshotModal: false,
    videoSessionActive: false,
    shared: false,
    detailsFetched: false,
    sharedWith: List<UserId>(),
    assignedTeams: List<TeamId>(),
    responsibleTeam: undefined,
    writeAccess: false,
    example: false,
    owner: null,
    creator: null,
    organization: null,
    tempBoard: false,
    orgaMemberPermissions: DEFAULT_PERMISSIONS,
    anyonePermissions: DEFAULT_PERMISSIONS,
    codySuggestEnabled: true,
    eventModelingEnabled: true,
};

export const deriveAbbreviationFromBoardName = (name: BoardName): string => {
    const words = name.split(" ");

    let abbr = '';

    if(words.length === 1) {
        const firstWord = words[0];

        if(firstWord.length <= 3) {
            return firstWord.toLocaleUpperCase();
        }

        return firstWord.slice(0, 3).toLocaleUpperCase();
    }

    if(words.length > 1) {
        words.forEach(word => abbr+= word.charAt(0));
    }

    return abbr.slice(0, 3).toLocaleUpperCase();
}

export const createUserBoard = (userId: UserModel.UserId, name: BoardName, abbr?: string, tags?: string[], responsibleTeam?: TeamId, codySuggestEnabled = true, eventModelingEnabled = true): Board => {
    console.log("create user board", responsibleTeam);

    return new Board({
        uid: '',
        name,
        nameAbbreviation: abbr || deriveAbbreviationFromBoardName(name),
        tags: tags? List(tags) : List(),
        version: new BoardVersion({userId, version: 1}),
        xml: null,
        createdAt: (() => new Date())().toISOString(),
        lastModified: undefined,
        inDeletion: false,
        shared: false,
        detailsFetched: true,
        sharedWith: List<UserId>(),
        assignedTeams: List<TeamId>(),
        responsibleTeam,
        writeAccess: true,
        example: false,
        owner: null,
        creator: userId,
        organization: null,
        tempBoard: false,
        orgaMemberPermissions: DEFAULT_PERMISSIONS,
        anyonePermissions: DEFAULT_PERMISSIONS,
        codySuggestEnabled,
        eventModelingEnabled,
    });
};

export const createTempBoard = (userId: UserModel.UserId, name: BoardName): Board => {
    return new Board({
        uid: '',
        name,
        nameAbbreviation: deriveAbbreviationFromBoardName(name),
        tags: List(),
        version: new BoardVersion({userId, version: 1}),
        xml: null,
        createdAt: (() => new Date())().toISOString(),
        lastModified: undefined,
        inDeletion: false,
        shared: false,
        detailsFetched: true,
        sharedWith: List<UserId>(),
        assignedTeams: List<TeamId>(),
        writeAccess: true,
        example: false,
        owner: null,
        creator: userId,
        organization: null,
        tempBoard: true,
        orgaMemberPermissions: DEFAULT_PERMISSIONS,
        anyonePermissions: DEFAULT_PERMISSIONS,
        codySuggestEnabled: true,
        eventModelingEnabled: true,
    });
};

export class Board extends Record(defaultBoardProps) implements BoardProps {
    public constructor(data: BoardProps) {
        if(data.uid === '') {
            data.uid = uuid.v4()
        }

        if(data.xml === undefined) {
            data.xml = null;
        }

        if(data.lastModified === undefined) {
            data.lastModified = (() => new Date())().toISOString();
        }

        super(data);
    }

    public updateXmlAndVersionFromBoard(board: Board): Board
    {
        let changed = this.set('xml', board.xml);
        changed = changed.set('version', board.version);
        changed = changed.set('lastModified', board.lastModified);
        return changed;
    }

    public updateXmlFromSnapshot(snapshot: BoardSnapshot): Board
    {
        let changed = this.set('xml', snapshot.xml);
        changed = changed.set('version', snapshot.boardVersion);
        return changed;
    }

    public updateXml(xml: string, userId: UserModel.UserId): Board
    {
        let changed = this.set('xml', xml);
        changed = changed.set('lastModified', (() => new Date())().toISOString());
        // Note: we set a new version that is not the HEAD version, even if current version is marked as head
        // The local change needs to be acknowledged first
        changed = changed.set('version', new BoardVersion({userId, version: this.version.version + 1}));
        return changed;
    }

    public updateVersion(version: BoardVersion): Board
    {
        return this.set('version', version);
    }

    public syncSummaryInfo(other: Board): Board
    {
        let changed = this.set('sharedWith', other.sharedWith);
        changed = changed.set('assignedTeams', other.assignedTeams);
        changed = changed.set('owner', other.owner);
        return changed.set('example', other.example);
    }

    public isCreator(userId: UserId): boolean
    {
        return this.get('creator') === userId;
    }

    public moveToOrganization(organizationId: OrganizationId): Board
    {
        let changed = this.set('creator', this.get('owner'));
        changed = changed.set('owner', null);
        return changed.set('organization', organizationId);
    }

    public codyEngineServiceName(): ServiceName
    {
        return names(this.get('name')).className;
    }
}
