import * as React from "react";
import {useSelector} from "react-redux";
import {Table} from "semantic-ui-react";
import {Board} from "../../model/Board";
import {AGGREGATE_TYPE, BoardPermissionForUser} from "../../model/BoardPermissionForUser";
import {makeBoardPermissionForUserListSelector} from "../../selectors/boardPermission";
import {BoardPermissionTableEntry} from "./BoardPermissionsTableEntry";
import {makeGetCurrentUser} from "../../../User/selectors/currentUser";

interface PropsFromState {
}

interface PropsFromDispatch {

}

interface OwnProps {
    board: Board;
}

export type BoardPermissionsTableProps = PropsFromState & PropsFromDispatch & OwnProps;

export const BoardPermissionTable = (props: BoardPermissionsTableProps) => {

    const currentUser = useSelector(makeGetCurrentUser());
    const boardPermissions = useSelector(makeBoardPermissionForUserListSelector(props.board.uid));

    const onwerId = props.board.owner || props.board.creator;

    return (
        <Table unstackable={true} basic="very">
            <Table.Body>
                <BoardPermissionTableEntry
                    board={props.board}
                    boardPermission={new BoardPermissionForUser({
                        uid: '',
                        aggregateType: AGGREGATE_TYPE,
                        permissions: { readAccess: true, writeAccess: true, adminAccess: true },
                        userId: onwerId as string,
                        boardId: props.board.uid
                    })}
                />
                {boardPermissions.map(boardPermission => (
                    <BoardPermissionTableEntry
                        key={boardPermission.uid}
                        board={props.board}
                        boardPermission={boardPermission}
                    />
                ))}
            </Table.Body>
        </Table>
    );
};
