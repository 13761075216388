import {OpenVidu, Stream, StreamEvent} from 'openvidu-browser';
import React, {useCallback} from "react";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import OpenViduApi from "../../../api/ConfiguredViduApi";
import WebSocketConnection from "../../../api/ConfiguredViduSocket";
import {makeGetSidebarSelector} from "../../../Layout/selectors/sidebar";
import {useGraph} from "../../hooks/useGraph";
import {Graph, GraphPoint} from "../../model/Graph";
import {OpenViduUser as User, OpenViduScreenShare as ScreenShare} from "../../model/OpenViduUser";
import OpenViduVideo, {NewPosition, PositionChange} from "./OpenViduVideo";
import OpenViduScreenShare from "./OpenViduScreenShare";
import {on} from "cluster";

interface MeetingProps {
    sessionId: string;
    id: string;
    name: string;
    avatar: string;
    initialPosition: { left: number, top: number };
    videoDevice?: string;
    audioDevice?: string;
}

interface RemotePositions {[userId: string]: { position: { left: number, top: number } }}

let localPositionChanged = 0;

const Meeting = (props: MeetingProps) => {
    const openVidu = useRef<OpenVidu>();
    const session = useRef<any>();
    const screenShareSession = useRef<any>();
    const graphTranslateRef = useRef<GraphPoint>({x: 0, y: 0});
    const graphScaleRef = useRef<number>(1);
    const localUserAccessAllowed = useRef<boolean>(false);
    const remotePositions = useRef<RemotePositions>({})
    const [remoteUsersCount, setRemoteUsersCount] = useState(0);
    const [positionChanged, setPositionChanged] = useState(localPositionChanged); // only used by incrementing the variable to force a render cycle of this component after changes where it does not do so automatically
    const [graph] = useGraph();
    const [graphTranslate, setGraphTranslate] = useState<GraphPoint>({x: 0, y: 0});
    const [graphScale, setGraphScale] = useState(1);
    const [localUser, setLocalUser] = useState<User>();
    const [localScreenShare, setLocalScreenShare] = useState<ScreenShare>();
    const sidebar = useSelector(makeGetSidebarSelector());

    const remoteUsersRef = useRef<User[]>([]);
    const speakingStreamsRef = useRef<string[]>([]);
    const remoteScreenSharesRef = useRef<ScreenShare[]>([]);

    // Used to get access to the current localUser state value in callbacks
    // @todo maybe use this and get rid of the other refs? Or switch to class component even?
    const stateRef = useRef({ localUser });
    stateRef.current = { localUser };

    const timeoutRef = useRef<number>();

    graphTranslateRef.current = graphTranslate;
    graphScaleRef.current = graphScale;

    const sendSignalUserChanged = (data: any) => {
        session.current!.signal({
            data: JSON.stringify(data),
            type: 'userChanged',
        });
    }

    const subscribeToStream = (stream: Stream) => {
        const data = JSON.parse(stream.connection.data);

        /* Prevent subscribing to your own screen share */
        if (data.type === 'screenShare' && stateRef.current.localUser && data.userId === stateRef.current.localUser.id) {
            console.log('[vidu] Subscribe to local screen share prevented')
            return;
        }

        const subscriber = session.current.subscribe(stream, undefined);
        subscriber.on('streamPlaying', (e: any /* @todo ??? */) => {
            console.log('[vidu] STREAM PLAYING', e, subscriber);


        });

        console.log("[vidu] Remote Positions: ", remotePositions.current);

        if (data.type === 'screenShare') {
            const screenSharePositionId = 'screen_' + data.userId;
            remoteScreenSharesRef.current.push({
                userId: data.userId,
                streamManager: subscriber,
                position: remotePositions.current[screenSharePositionId]
                    ? remotePositions.current[screenSharePositionId].position // Try screen position first
                    : remotePositions.current[data.userId]
                        ? remotePositions.current[data.userId].position  // Then the users position the screen belongs to
                        : {
                            left: 200,
                            top: 500
                        },
            });

            console.log("[vidu] pushed remote screen share", remoteScreenSharesRef.current);
        } else {
            remoteUsersRef.current.push({
                id: data.id,
                name: data.name,
                avatar: data.avatar,
                streamManager: subscriber,
                connectionId: stream.connection.connectionId,
                position: remotePositions.current[data.id] ? remotePositions.current[data.id].position : {
                    left: 200,
                    top: 500
                },
                cameraOn: data.isVideoActive,
                micOn: data.isAudioActive,
                megaphoneOn: data.isMegaphoneActive,
                handUpOn: data.isHandUpActive,
                thumbUpOn: data.isThumbUpActive,
                showIconName: data.selectedIconName,
            })

            console.log("[vidu] pushed remote user", remoteUsersRef.current);
            setRemoteUsersCount(remoteUsersCount + 1);
        }

        setPositionChanged(++localPositionChanged);
    }

    const subscribeToStreamCreated = () => {
        session.current.on('streamCreated', (event: any) => {
            console.log('[vidu] STREAM CREATED');

            subscribeToStream(event.stream);
        });
    };

    const subscribeToUserChanged = () => {
        session.current.on('signal:userChanged', (event: any) => {
            remoteUsersRef.current.forEach(user => {
                if (user.connectionId === event.from.connectionId) {
                    const data = JSON.parse(event.data);
                    console.log("[vidu] Updating user data: ", data);

                    if(data.hasOwnProperty('isVideoActive')) {
                        user.cameraOn = data.isVideoActive;
                        setPositionChanged(++localPositionChanged);
                    }

                    if(data.hasOwnProperty('isAudioActive')) {
                        user.micOn = data.isAudioActive;
                        setPositionChanged(++localPositionChanged);
                    }

                    if (data.hasOwnProperty('isMegaphoneActive')) {
                        user.megaphoneOn = data.isMegaphoneActive;
                        setPositionChanged(++localPositionChanged);
                    }

                    if (data.hasOwnProperty('isHandUpActive')) {
                        user.handUpOn = data.isHandUpActive;
                        setPositionChanged(++localPositionChanged);
                    }

                    if (data.hasOwnProperty('isThumbUpActive')) {
                        user.thumbUpOn = data.isThumbUpActive;
                        setPositionChanged(++localPositionChanged);
                    }

                    if (data.hasOwnProperty('selectedIconName')) {
                        user.showIconName = data.selectedIconName;
                        setPositionChanged(++localPositionChanged);
                    }

                }
            });
        });
    };

    const subscribeToDebuggingEvents = () => {
        session.current.on('reconnecting', () => console.warn('[vidu] Oops! Trying to reconnect to the session'));
        session.current.on('reconnected', () => console.log('[vidu] Hurray! You successfully reconnected to the session'));
        session.current.on('sessionDisconnected', (event: any) => {
            if (event.reason === 'networkDisconnect') {
                console.warn('[vidu] Dang-it... You lost your connection to the session', event);
                session.current = null;
                screenShareSession.current = null;
                openViduConnect();
            } else {
                // Disconnected from the session for other reason than a network drop
                console.warn('[vidu] Vidu session disconnect! Reason: ', event.reason, event);
            }
        });

        session.current.on('exception', (event: any) => {
            if (event.name === 'ICE_CONNECTION_FAILED') {
                const stream = event.origin;
                console.warn('[vidu] Stream ' + stream.streamId + ' broke!');
                console.warn('[vidu] Reconnection process automatically started');
                return;
            }
            if (event.name === 'ICE_CONNECTION_DISCONNECTED') {
                const stream = event.origin;
                console.warn('[vidu] Stream ' + stream.streamId + ' disconnected!');
                console.warn('[vidu] Giving it some time to be restored. If not possible, reconnection process will start');
                return;
            }
            if(event.name === 'NO_STREAM_PLAYING_EVENT') {
                const stream = event.origin.stream;
                console.warn(`[vidu] No stream playing event recevied. Trying it again for ${stream.streamId}`);
                deleteSubscriber(stream);
                subscribeToStream(stream);
            }


            console.error("[vidu] OpenVidu session exception occurred: ", event);
        });
    }

    const deleteSubscriber = (stream: any) => {
        remoteUsersRef.current = remoteUsersRef.current.filter(user => user.streamManager.stream !== stream);
        setRemoteUsersCount(remoteUsersCount - 1);

        // might also be a screen share subscription that was terminated
        remoteScreenSharesRef.current = remoteScreenSharesRef.current.filter(
            screenShare => screenShare.streamManager.stream !== stream
        );

        setPositionChanged(++localPositionChanged);
    }

    const subscribeToStreamDestroyed = () => {
        // On every Stream destroyed...
        session.current.on('streamDestroyed', (event: any) => {
            console.log('[vidu] Stream destroyed');

            // Remove the stream from 'subscribers' array
            deleteSubscriber(event.stream);
            event.preventDefault();
        });
    };


    const connectWebCam = () => {
        const publisher = openVidu.current!.initPublisher(undefined as any, {
            audioSource: props.audioDevice,
            videoSource: props.videoDevice,
            publishAudio: true,
            publishVideo: true,
            resolution: '320x240',
            frameRate: 30,
            insertMode: 'APPEND',
            mirror: false,
        });

        if (session.current!.capabilities.publish) {
            publisher.on('accessAllowed' , () => {
                session.current.publish(publisher).then(() => {
                    localUserAccessAllowed.current = true;
                });
            });
        }

        subscribeToUserChanged();
        subscribeToStreamDestroyed();
        sendSignalUserChanged({ isScreenShareActive: false });

        setLocalUser({
            id: props.id,
            name: props.name,
            avatar: props.avatar,
            streamManager: publisher,
            connectionId: session.current.connection.connectionId,
            position: props.initialPosition,
            cameraOn: true,
            micOn: true,
            megaphoneOn: false,
            handUpOn: false,
            thumbUpOn: false,
            showIconName: "",
        });

        publisher.on('streamPlaying', (e) => {
            console.log('[vidu] publisher stream playing', e);
            // console.info('I stream playing');
            // @todo
            // publisher.videos[0].video.parentElement!.classList.remove('custom-class');
        });
    };

    const connectToSession = async () => {
        try {
            const _session = await OpenViduApi.createSession(props.sessionId);
            const token = await OpenViduApi.getToken(_session);

            await session.current.connect(token, {
                type: 'user',
                id: props.id,
                name: props.name,
                avatar: props.avatar,
                position: props.initialPosition,
                isVideoActive: true,
                isAudioActive: true,
            });
            connectWebCam();
        } catch (error) {
            alert('There was an error connecting to the session: ' + error.message);
            console.log('[vidu] There was an error connecting to the session:', error.code, error.message);
        }
    };

    const updateRemoteUserPosition = (id: string, position: {left: number, top: number}) => {
        remotePositions.current[id] = {position};

        if (id.includes('screen_')) {
            // @todo screen position update. Relevant for us?
        } else {
            remoteUsersRef.current.forEach(remoteUser => {
                if (remoteUser.id === id) {
                    remoteUser.position = position;
                }
            });
        }

        setPositionChanged(++localPositionChanged);
    }

    const handleLocalUserPositionChanged = (positionChange: PositionChange): NewPosition => {
        const scale = graph.getCurrentScale();

        const mouseDeltaX = positionChange.currentPositionMouse.x - positionChange.startPositionMouse.x;
        const mouseDeltaY = positionChange.currentPositionMouse.y - positionChange.startPositionMouse.y;

        const newPosition: NewPosition = {
            left: positionChange.startPositionAvatar.left + (mouseDeltaX / scale),
            top: positionChange.startPositionAvatar.top + (mouseDeltaY / scale),
        }


        if(localUser) {
            setLocalUser({...localUser, position: newPosition});
        }
        return newPosition;
    };

    const handleScreenSharePositionChanged = (positionChange: PositionChange, userId?: string): NewPosition|undefined => {
        const scale = graph.getCurrentScale();

        const mouseDeltaX = positionChange.currentPositionMouse.x - positionChange.startPositionMouse.x;
        const mouseDeltaY = positionChange.currentPositionMouse.y - positionChange.startPositionMouse.y;

        const newPosition: NewPosition = {
            left: positionChange.startPositionAvatar.left + (mouseDeltaX / scale),
            top: positionChange.startPositionAvatar.top + (mouseDeltaY / scale),
        }

        if (!userId && localScreenShare) {
            setLocalScreenShare({...localScreenShare, position: newPosition});
            return newPosition;
        } else {
            const remoteScreenShare = remoteScreenSharesRef.current.find(screenShare => screenShare.userId === userId);
            if (remoteScreenShare) {
                remoteScreenShare.position = newPosition;
                setPositionChanged(++localPositionChanged);
            }
        }
    };

    const toggleCamera = () => {
        if (!localUser) {
            return;
        }

        const isCameraNowActive = !localUser.cameraOn;
        localUser.streamManager.publishVideo(isCameraNowActive);
        setLocalUser({...localUser, cameraOn: isCameraNowActive});
        sendSignalUserChanged({ isVideoActive: isCameraNowActive });
    };

    const toggleMic = () => {
        if (!localUser) {
            return;
        }

        const isMicNowActive = !localUser.micOn;
        localUser.streamManager.publishAudio(isMicNowActive);
        setLocalUser({...localUser, micOn: isMicNowActive});
        sendSignalUserChanged({ isAudioActive: isMicNowActive });
    };

    const toggleMegaphone = () => {
        if (!localUser) {
            return;
        }

        const isMegaphoneNowActive = !localUser.megaphoneOn;
        setLocalUser({...localUser, megaphoneOn: isMegaphoneNowActive});
        sendSignalUserChanged({ isMegaphoneActive: isMegaphoneNowActive });
    };

    const toggleHandUp = () =>{
        if (!localUser) {
            return;
        }

        const isHandUpNowActive = !localUser.handUpOn;
        setLocalUser({...localUser, handUpOn: isHandUpNowActive});
        sendSignalUserChanged({ isHandUpActive: isHandUpNowActive });
    };

    const toggleEmoji = (iconName: string) =>{
        if (!localUser) {
            return;
        }
        const iconInfo = (localUser.showIconName === '') ? iconName : '';
        const isThumbUpNowActive = !localUser.thumbUpOn;

        setLocalUser({...localUser, thumbUpOn: isThumbUpNowActive, showIconName: iconInfo});
        sendSignalUserChanged({ isThumbUpActive: isThumbUpNowActive, selectedIconName: iconInfo});

        clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(() => {
            setLocalUser({...stateRef.current.localUser!, thumbUpOn: false, showIconName: ""});
            sendSignalUserChanged({ isThumbUpActive: false, selectedIconName: ""});
        }, 3000);
    };

    const shareScreen = async () => {
        if (!localUser) {
            return;
        }

        screenShareSession.current = openVidu.current!.initSession();

        const _session = await OpenViduApi.createSession(props.sessionId);
        const token = await OpenViduApi.getToken(_session);

        await screenShareSession.current.connect(token, {
            type: 'screenShare',
            userId: props.id,
        });

        const screenStreamManager = openVidu.current!.initPublisher(undefined as any, {
            videoSource: 'screen',
            publishVideo: true,
            publishAudio: true,
            mirror: false,
        }, console.log);

        screenStreamManager.once('accessAllowed', (event: any) => {
            screenStreamManager.stream.getMediaStream().getVideoTracks()[0].addEventListener('ended', () => {
                // @todo is there something else we need to do in this case?
                setLocalScreenShare(undefined);
            });

            setLocalScreenShare({
                userId: localUser.id,
                streamManager: screenStreamManager,
                position: { ...localUser.position },
            });

            screenShareSession.current.publish(screenStreamManager);
        });
    };

    const stopScreenShare = () => {
        if (screenShareSession.current) {
            if (localScreenShare) {
                screenShareSession.current.unpublish(localScreenShare.streamManager);
            }

            setLocalScreenShare(undefined);
            screenShareSession.current.disconnect();
        }

        screenShareSession.current = undefined;
    };

    const toggleScreenShare = () => {
        if (!localScreenShare) {
            shareScreen();
        } else {
            stopScreenShare();
        }
    };

    const openViduConnect = () => {
        openVidu.current = new OpenVidu();
        openVidu.current!.setAdvancedConfiguration({
            // Always reconnect after network drop, to avoid frozen media streams
            forceMediaReconnectionAfterNetworkDrop: true,
            // Increase timeout, because on prooph board heavy rendering tasks can slow down stream playing
            noStreamPlayingEventExceptionTimeout: 15000
        })
        session.current = openVidu.current.initSession();

        session.current.on('publisherStartSpeaking', (e: any) => {
            if (!speakingStreamsRef.current.includes(e.streamId)) {
                speakingStreamsRef.current.push(e.streamId);
                setPositionChanged(++localPositionChanged);
            }
        });

        session.current.on('publisherStopSpeaking', (e: any) => {
            const index = speakingStreamsRef.current.indexOf(e.streamId);
            if (index !== -1) {
                speakingStreamsRef.current.splice(index, 1);
                setPositionChanged(++localPositionChanged);
            }
        });

        subscribeToStreamCreated();
        subscribeToDebuggingEvents();
        connectToSession();

        WebSocketConnection.listenForPositionUpdates(props.id, props.sessionId, updateRemoteUserPosition);
    }

    const onBeforeUnload = useCallback(() => {
        if (session.current) {
            session.current.disconnect();
        }

        if (screenShareSession.current) {
            screenShareSession.current.disconnect();
        }

        session.current = undefined;
        screenShareSession.current = undefined;
        openVidu.current = undefined;
        WebSocketConnection.stopListeningForPositionUpdates();
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', onBeforeUnload);

        openViduConnect();
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
            onBeforeUnload();
        };
    }, []);

    useEffect(() => {
        const panningListener = (translate: GraphPoint) => {
            setGraphTranslate({x: translate.x, y: translate.y})
        }

        const zoomListener = (scale: number, translate: GraphPoint) => {
            setGraphScale(scale);
            setGraphTranslate(translate);
        }

        if(graph) {
            const translate = graph.getCurrentTranslate();
            setGraphTranslate({x: translate.x, y: translate.y});
            setGraphScale(graph.getCurrentScale());

            graph.onPanning(panningListener);
            graph.onZoom(zoomListener);
        }

        return () => {
            if(graph) {
                graph.offPanning(panningListener);
                graph.offZoom(zoomListener);
            }
        }
    }, [graph]);

    const localUserNotNull = !!localUser;

    useEffect(() => {
        if(graph && localUser) {
            const scale = graph.getCurrentScale();
            const translatedPoint = {
                left: (-1 * graph.getCurrentTranslate().x) + (props.initialPosition.left / scale),
                top: (-1 * graph.getCurrentTranslate().y) + (props.initialPosition.top / scale),
            }
            console.log("[vidu] local user", translatedPoint, scale);
            setLocalUser({...localUser, position: translatedPoint});
        }
    }, [graph, localUserNotNull]);

    const isAnyMegaphoneActive = Boolean(
        localUser && localUser.megaphoneOn ||
        remoteUsersRef.current.find(remoteUser => remoteUser.megaphoneOn)
    );

    const remoteVideos = remoteUsersRef.current.map(remoteUser => (
        <div>
            <OpenViduVideo
                key={remoteUser.id}
                user={remoteUser}
                isLocalUser={false}
                sessionId={props.sessionId}
                volume={
                    !localUser ? 0 : Math.max(Math.min((700 - Math.sqrt(
                        Math.pow((remoteUser.position.top - localUser.position.top), 2) +
                        Math.pow((remoteUser.position.left - localUser.position.left), 2)
                    )) / 500, 1), 0)
                }
                isSpeaking={speakingStreamsRef.current.includes(remoteUser.streamManager.stream.streamId)}
                isAnyMegaphoneActive={isAnyMegaphoneActive}
            />
        </div>
    ));

    const remoteScreenShares = remoteScreenSharesRef.current.map(remoteScreenShare => (
        <div>
            <OpenViduScreenShare
                screenShare={remoteScreenShare}
                sessionId={props.sessionId}
                onPositionChange={positionChange => handleScreenSharePositionChanged(positionChange, remoteScreenShare.userId)}
            />
        </div>
    ))

    return (
        <div className="diagram-overlay" style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none"
        }}>
            <div style={{
                transform: `scale(${graphScale}) translate(${graphTranslate.x}px, ${graphTranslate.y}px)`,
                transformOrigin: '0 0'
            }}>
                {localUser && (
                    <div>
                        <OpenViduVideo
                            user={localUser}
                            isLocalUser={true}
                            sessionId={props.sessionId}
                            onPositionChange={handleLocalUserPositionChanged}
                            volume={0}
                            isSpeaking={speakingStreamsRef.current.includes(localUser.streamManager.stream.streamId)}
                            isAnyMegaphoneActive={isAnyMegaphoneActive}
                        />
                    </div>
                )}
                {remoteVideos}
                {localScreenShare && (
                    <div>
                        <OpenViduScreenShare
                            screenShare={localScreenShare}
                            sessionId={props.sessionId}
                            onPositionChange={handleScreenSharePositionChanged}
                        />
                    </div>
                )}
                {remoteScreenShares}
            </div>

            {localUser && (<>
                <button
                    onClick={toggleCamera}
                    style={{ position: 'absolute', bottom: 20, left: sidebar.width + 20 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={localUser.cameraOn? "video icon" : "icon-fas-video-slash-solid"}
                        style={localUser.cameraOn ? { color: '#c10303' } : { color: '#878787' }}
                    />}
                />
                <button
                    onClick={toggleMic}
                    style={{ position: 'absolute', bottom: 20, left: sidebar.width + 70 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={localUser.micOn ? 'icon microphone' : 'icon microphone slash'}
                        style={localUser.micOn ? { color: '#c10303' } : { color: '#878787' }}
                    />}
                />
                <button
                    onClick={toggleMegaphone}
                    style={{ position: 'absolute', bottom: 20, left: sidebar.width + 120 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={'icon bullhorn'}
                        style={localUser.megaphoneOn ? { color: '#c10303' } : { color: '#878787' }}
                    />}
                />
                <button
                    onClick={toggleScreenShare}
                    style={{ position: 'absolute', bottom: 20, left: sidebar.width + 170 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={'icon desktop'}
                        style={localScreenShare ? { color: '#c10303' } : { color: '#878787' }}
                    />}
                />
                <button
                    onClick={toggleHandUp}
                    style={{ position: 'absolute', bottom: 20, left: sidebar.width + 220 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={'icon hand paper'}
                        style={localUser.handUpOn ? { color: '#21AA21' } : { color: '#878787'}}
                    />}
                />

                <div className={'show_buttons'}>
                    <button
                        onClick={() => toggleEmoji("thumbs up")}
                        style={{ position: 'absolute', bottom: 20, left: sidebar.width + 270 + "px", pointerEvents: 'all' }}
                        className={'ui circular icon button big video-control'}
                        children={<i
                            className={'icon thumbs up'}
                            style={localUser.showIconName == "thumbs up" ? { color: '#21AA21' } : { color: '#878787'}}
                        />}
                    />

                    <button
                        onClick={() => toggleEmoji("thumbs down")}
                        style={{ position: 'absolute', bottom: 60, left: sidebar.width + 270 + "px", pointerEvents: 'all' }}
                        className={'ui circular icon button big video-control icon_button_menue'}
                        children={<i
                            className={'icon thumbs down icon_button_menue'}
                            style={localUser.showIconName == "thumbs down" ? { color: '#CC1B1B' } : { color: '#878787'}}
                        />}
                    />

                    <button
                        onClick={() => toggleEmoji("beer")}
                        style={{ position: 'absolute', bottom: 100, left: sidebar.width + 270 + "px", pointerEvents: 'all' }}
                        className={'ui circular icon button big video-control icon_button_menue'}
                        children={<i
                            className={'icon beer icon_button_menue'}
                            style={localUser.showIconName == "beer" ? { color: '#FFFF00' } : { color: '#878787'}}
                        />}
                    />
                </div>
            </>)}
        </div>
    );
};

export default Meeting;
