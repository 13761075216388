import React, {useEffect, useRef, useState} from "react";
import WebSocketConnection from "../../../api/ConfiguredJitsiSocket";
import {JitsiTrack as Track} from "./JitsiMeetInterfaces";
import { JitsiUser as User, JitsiScreenShareUser as ScreenShare } from "../../model/JitsiUser";
import './JitsiVideo.css';

export interface Position {
    left: number;
    top: number;
}

export interface MousePoint {
    x: number;
    y: number;
}

export type NewPosition = Position;

export interface PositionChange {
    startPositionAvatar: Position,
    startPositionMouse: MousePoint,
    currentPositionMouse: MousePoint,
}

interface JitsiScreenShareProps {
    user: ScreenShare;
    originUser: User | undefined;
    volume: number;
    sessionId: string; 
    onPositionChange?: (change: PositionChange) => NewPosition|undefined;
    onScaleScreenShare?: (change: PositionChange) => NewPosition|undefined;
}

const defaultPositionChange: PositionChange = {
    startPositionAvatar: {left: 0, top: 0},
    startPositionMouse: {x: 0, y: 0},
    currentPositionMouse: {x: 0, y: 0}
}

const JitsiScreenShare = (props: JitsiScreenShareProps) => {
    const videoRef = useRef<any>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const positionChange = useRef<PositionChange>(defaultPositionChange);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isScaling, setIsScaling] = useState<boolean>(false);
    const scalingChange = useRef<PositionChange>(defaultPositionChange);


    const changePosition = (x: number, y: number) => {
        positionChange.current.currentPositionMouse.x = x;
        positionChange.current.currentPositionMouse.y = y;

        if (props.onPositionChange) {
            const newPosition = props.onPositionChange(positionChange.current);
        }
    }
    const scaleScreenShare = (x: number, y: number) => {
        scalingChange.current.currentPositionMouse.x = x;
        scalingChange.current.currentPositionMouse.y = y;

        if (props.onScaleScreenShare) {
            const newPosition = props.onScaleScreenShare(scalingChange.current);
        }
    }

    useEffect(() => {
        if(props.user.screenShareTrack == null){return};
        (videoRef.current as any).volume = 0;
        props.user.screenShareTrack.attach(videoRef.current);
    }, []);

    useEffect(() => {
        const mouseUpListener = () => {
            setIsDragging(false);
            setIsScaling(false);
        }

        const mouseMoveListener = (e: MouseEvent) => {
            if (isDragging) {
                changePosition(e.clientX, e.clientY);
            }
            if (isScaling) {
                scaleScreenShare(e.clientX, e.clientY);
            }
        }

        const touchMoveListener = (e: TouchEvent) => {
            if(isDragging) {
                changePosition(e.touches[0].clientX, e.touches[0].clientY);
            }
            if(isScaling) {
                scaleScreenShare(e.touches[0].clientX, e.touches[0].clientY);
            }
        }

        document.addEventListener('mouseup', mouseUpListener);
        document.addEventListener('touchend', mouseUpListener);
        window.addEventListener('mouseup', mouseUpListener);
        document.addEventListener('mousemove', mouseMoveListener);
        document.addEventListener('touchmove', touchMoveListener);

        return () => {
            document.removeEventListener('mouseup', mouseUpListener);
            document.removeEventListener('touchend', mouseUpListener);
            window.removeEventListener('mouseup', mouseUpListener);
            document.removeEventListener('mousemove', mouseMoveListener);
            document.removeEventListener('touchmove', touchMoveListener);
        }
    })

    const toggleIsFocused = () => {
        setIsFocused(!isFocused);
    }

    return (
        <div // position change on board
            style={{
                height: props.user.height * (isFocused ? 2 : 1), // * (props.volume > 0.5 ? props.volume : 0.5) 
                padding: 5,
                boxSizing: 'border-box',
                position: 'absolute',
                left: props.user.position.left - props.user.height,
                top: props.user.position.top,
                pointerEvents: 'auto',
                cursor: 'move',
                zIndex: 2,
                backgroundColor: 'black',
                borderRadius: 6,
            }}
            onMouseDown={(e) => {
                    positionChange.current.startPositionAvatar = props.user.position;
                    positionChange.current.startPositionMouse = {
                        x: e.clientX,
                        y: e.clientY,
                    }
                    setIsDragging(true);
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
            onTouchStart={(e) => {
                positionChange.current.startPositionAvatar = props.user.position;
                positionChange.current.startPositionMouse = {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY,
                }
                setIsDragging(true);
                e.stopPropagation();
                e.preventDefault();
            }}
            onClick={(e) => {
                e.preventDefault();
            }}

            onDoubleClick={toggleIsFocused}
        >
            {/*
             // @ts-ignore */}
            <video
                autoPlay={true}
                disablePictureInPicture={true /* Not working in FF .... */}
                ref={videoRef}
                muted={true}
                style={{ height: '100%', pointerEvents: 'auto', display: 'block', borderRadius: '3px' }}
            />
            <div // scaling screensahre on board unten rechts
                style={{ position: "absolute", bottom: "-5px", right: "-5px", width: '25px', height: '25px', pointerEvents: 'auto', cursor:"se-resize" }}
                onMouseDown={(e) => {
                    scalingChange.current.startPositionAvatar = {left: 1, top: props.user.height};
                    scalingChange.current.startPositionMouse = {
                        x: e.clientX,
                        y: e.clientY,
                    }
                    setIsScaling(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onTouchStart={(e) => {
                    scalingChange.current.startPositionAvatar = props.user.position;
                    scalingChange.current.startPositionMouse = {
                        x: e.touches[0].clientX,
                        y: e.touches[0].clientY,
                    }
                    setIsScaling(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onClick={(e) => {
                    e.preventDefault();
                }}
            >
            </div>
            <div // scaling screensahre on board unten links
                style={{ position: "absolute", bottom: "-5px", left: "-5px", width: '25px', height: '25px', pointerEvents: 'auto', cursor:"sw-resize" }}
                onMouseDown={(e) => {
                    scalingChange.current.startPositionAvatar = {left: 2, top: props.user.height};
                    scalingChange.current.startPositionMouse = {
                        x: e.clientX,
                        y: e.clientY,
                    }
                    setIsScaling(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onTouchStart={(e) => {
                    scalingChange.current.startPositionAvatar = props.user.position;
                    scalingChange.current.startPositionMouse = {
                        x: e.touches[0].clientX,
                        y: e.touches[0].clientY,
                    }
                    setIsScaling(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onClick={(e) => {
                    e.preventDefault();
                }}
            >
            </div>
            <div // scaling screensahre on board oben links
                style={{ position: "absolute", top: "-5px", left: "-5px", width: '25px', height: '25px', pointerEvents: 'auto', cursor:"se-resize" }}
                onMouseDown={(e) => {
                    scalingChange.current.startPositionAvatar = {left: 3, top: props.user.height};
                    scalingChange.current.startPositionMouse = {
                        x: e.clientX,
                        y: e.clientY,
                    }
                    setIsScaling(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onTouchStart={(e) => {
                    scalingChange.current.startPositionAvatar = props.user.position;
                    scalingChange.current.startPositionMouse = {
                        x: e.touches[0].clientX,
                        y: e.touches[0].clientY,
                    }
                    setIsScaling(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onClick={(e) => {
                    e.preventDefault();
                }}
            >
            </div>
            <div // scaling screensahre on board oben rechts
                style={{ position: "absolute", top: "-5px", right: "-5px", width: '25px', height: '25px', pointerEvents: 'auto', cursor:"sw-resize" }}
                onMouseDown={(e) => {
                    scalingChange.current.startPositionAvatar = {left: 4, top: props.user.height};
                    scalingChange.current.startPositionMouse = {
                        x: e.clientX,
                        y: e.clientY,
                    }
                    setIsScaling(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onTouchStart={(e) => {
                    scalingChange.current.startPositionAvatar = props.user.position;
                    scalingChange.current.startPositionMouse = {
                        x: e.touches[0].clientX,
                        y: e.touches[0].clientY,
                    }
                    setIsScaling(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onClick={(e) => {
                    e.preventDefault();
                }}
            >
            </div>
            <div className={'disable-selection'} style={{ position: "absolute", bottom: '-26px', left: 0, width: '100%', textAlign: 'center', fontSize: '16px', pointerEvents: 'auto' }}>{props.originUser ? props.originUser.name : ""}</div>
        </div>
    );
};


export default JitsiScreenShare;