import * as React from 'react';
import {Node} from "../../../../model/Graph";
import {WizardContext} from "../../../../service/cody-wizard/context/wizard-context";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Checkbox, FormField} from "semantic-ui-react";

interface OwnProps {
  vo: Node | null;
  isProjection: boolean;
  onProjectionChanged: (isProjection: boolean) => void;
  ctx: WizardContext;
  disabled?: boolean;
}

type ProjectionToggleProps = OwnProps & WithNamespaces;

const ProjectionToggle = (props: ProjectionToggleProps) => {
  const disabled = props.disabled || !props.vo;

  return <FormField disabled={disabled} inline={true}>
    <Checkbox value={1}
              checked={props.isProjection}
              disabled={disabled}
              toggle={true}
              onChange={() => props.onProjectionChanged(!props.isProjection)}
              label={props.t('insp.cody_wizard.step_any_vo.is_projection_label') as string} />
  </FormField>
};

export default withNamespaces()(ProjectionToggle);
