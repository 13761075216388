import {isStandalone} from "../standalone/util";

let client: WebSocket|null = null;
let reconnectTrys = 0;
let positionListener: (e: MessageEvent) => void = () => { /* no op */};
let currentUserId: string | null = null;
let currentSessionId: string | null = null;
let pingTimeout: number|null = null;
let heartbeatTimeout: number|null = null;

enum MessageType {
    GetUserPositions = 'GetUserPositions',
    GetUserPositionsResponse = 'GetUserPositionsResponse',
    UserPositionChanged = 'UserPositionChanged',
}

interface GetUserPositions {
    type: MessageType.GetUserPositions,
    id: string;
    sessionId: string;
}

interface GetUserPositionsResponse {
    type: MessageType.GetUserPositionsResponse,
    users: {[userId: string]: {
        position: {left: number, top: number},
    }}
}

interface GetUserPositions {
    type: MessageType.GetUserPositions,
}

interface UserPositionChanged {
    type: MessageType.UserPositionChanged,
    id: string;
    sessionId: string;
    left: number;
    top: number;
}

type Message = GetUserPositions | GetUserPositionsResponse | UserPositionChanged;

const isConnected = () => {
    return client && client.readyState === client.OPEN;
}

const ping = () => {
    if(client && isConnected() && currentUserId && currentSessionId) {
        client.send(JSON.stringify({type: MessageType.GetUserPositions, id: currentUserId, sessionId: currentSessionId}));
    } else {
        reconnectTrys++;

        if(client) {
            client.close();
        }

        console.log("[vidu] trying to reconnect to socket. Attempt: " + reconnectTrys);
        connect();
    }

    pingTimeout = window.setTimeout(() => {
        ping();
    }, 30000);
}

const heartbeat = () => {
    if(heartbeatTimeout) {
        window.clearTimeout(heartbeatTimeout);
    }

    heartbeatTimeout = window.setTimeout(() => {
        if(client && isConnected()) {
            console.log("[vidu] Closing connection due to heartbeat timeout");
            client.close()
        }
        // ping interval + latency
    }, 30000 + 1000);
}



const connect = () => {
    client = new WebSocket('wss://mephisto-video.inseciacloud.com:3001');
    //client = new WebSocket('wss://localhost:3001');

    client.onopen = (evt) => {
        console.log("[vidu] heartbeat triggered on open")
        heartbeat();
        reconnectTrys = 0;
    }
    client.onclose = (evt) => {
        if(heartbeatTimeout) {
            console.log("[vidu] heartbeat timeout cleared on close")
            window.clearTimeout(heartbeatTimeout);
        }
    }

    client.onmessage = positionListener;
}

if(!isStandalone()) {
    connect();
}


const WebSocketConnection = {
    sendPositionUpdate: (id: string, sessionId: string, left: number, top: number) => {
        if(client && isConnected()) {
            client.send(JSON.stringify({ type: 'UserPositionChanged', id, sessionId, left, top, }));
        }
    },
    listenForPositionUpdates: (id: string, sessionId: string, onChange: (id: string, position: { left: number, top: number }) => void) => {
        currentUserId = id;
        currentSessionId = sessionId;

        positionListener = (e) => {
            const data: Message = JSON.parse(e.data);

            switch (data.type) {
                case MessageType.GetUserPositionsResponse:
                    console.log("[vidu] Got user positions from socket server: ", data);
                    heartbeat();

                    Object.keys(data.users).forEach(userId => {
                        if(id === userId) {
                            return;
                        }

                        const {position} = data.users[userId];

                        onChange(userId, position);
                    })
                    break;
                case MessageType.UserPositionChanged:
                    onChange(data.id, { left: data.left, top: data.top });
                    break;
            }
        }


        if(client) {
            client.onmessage = positionListener;
            ping();
        }
    },
    stopListeningForPositionUpdates: () => {
        if(pingTimeout) {
            window.clearTimeout(pingTimeout);
        }

        currentUserId = null;
        currentSessionId = null;
    }
};

export default WebSocketConnection;
