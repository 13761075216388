import { call, fork, put, select, take } from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as Action from "../actions";
import {UserInfo} from "../model/UserInfo";
import {UserSelector} from "../selectors";

type FlowAction = ActionType<typeof Action.Command.updateProfile>;
type UpdatePasswordFlowAction = ActionType<typeof Action.Command.updatePassword>;

function* onUpdateProfile(action: FlowAction) {
    const user: UserInfo = yield select(UserSelector.currentUserSelector, undefined);

    if(user.name) {
        const {response, error}: ResponseType = yield call(Action.Api.updateProfile, user);

        if (error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not update profile with new profile picture.'));
        }

        if(response) {
            if(user.displayName) {
                yield call(Action.Api.syncUsername, user.uid, user.displayName, user.email, user.avatarUrl, user.jobTitle);
            }
            yield put(NotifyAction.Command.info('Profile Saved', 'Your profile has been saved'));
            action.payload.successCb();
        }
    }
}

export function* updateProfile() {
    while (true) {
        const action: FlowAction = yield take([
            Action.Type.UPDATE_PROFILE
        ]);

        yield fork(onUpdateProfile, action)
    }
}

function* onUpdatePassword(action: UpdatePasswordFlowAction) {
    const user: UserInfo = yield select(UserSelector.currentUserSelector, undefined);
    const pass: string = action.payload.password;

    if (user.uid) {
        const {response, error}: ResponseType = yield call(Action.Api.updatePassword, user.uid, pass);

        if (error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not change password.'));
        }
        if (response) {
            yield put(NotifyAction.Command.info('Password changed', 'Password was changed successfully.'));
        }
    }
}

export function* updatePassword() {
    while (true) {
        const action: UpdatePasswordFlowAction = yield take([
            Action.Type.UPDATE_PASSWORD
        ]);

        yield fork(onUpdatePassword, action)
    }
}