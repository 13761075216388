import {List, Record} from 'immutable';
import * as uuid from 'uuid';
import { AccountModel, RoleMapModel } from './index';
import {OrganizationId} from "../../Organization/model/Organization";

export type UserId = string;
export type Username = string;
export type Email = string;
export type Account = AccountModel.AccountProps;
export type Roles = RoleMapModel.RoleMap;
export type GivenName = string;
export type FamilyName = string;
export type Quota = number;
export type TeamQuota = number;
export type DisplayName = string;
export type AvatarUrl = string;
export type CompanyName = string;
export type JobTitle = string;

export interface UserProps {
    uid: UserId;
    name: Username;
    email: Email;
    roles: Roles;
    account: Account;
    givenName: GivenName;
    familyName: FamilyName;
    quota: Quota;
    teamQuota: TeamQuota,
    displayName: DisplayName;
    avatarUrl: AvatarUrl;
    companyName: CompanyName;
    jobTitle: JobTitle;
    activeOrganization: OrganizationId | null;
    signedIn?: boolean;
}

export const defaultUserProps: UserProps = {
    uid: '',
    name: '',
    email: '',
    givenName: '',
    familyName: '',
    roles: RoleMapModel.withAnonymous(),
    account: AccountModel.freeAccount(),
    quota: 0,
    teamQuota: 0,
    displayName: '',
    avatarUrl: '',
    companyName: '',
    jobTitle: '',
    activeOrganization: null,
    signedIn: false,
};

export class UserInfo extends Record(defaultUserProps) implements UserProps {
    public constructor(data: UserProps) {
        if (data.uid === '') {
            data.uid = uuid.v4();
        }

        super(data);
    }

    public generateDefaultAvatarUrl(): AvatarUrl {
        return `/avatar/${this.uid}?displayName=${this.displayName}`;
    }
}


