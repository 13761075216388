import {call, fork, take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import * as Action from "../../InspectioBoards/actions";
import {StandaloneBoard} from "../storage/types";
import {standaloneStorageManager} from "../util";

type FlowAction = ActionType<typeof Action.Command.renameBoard | typeof Action.Command.changeBoardAbbreviation | typeof Action.Command.setBoardTags>;

function* onSaveBoard(action: FlowAction) {
    const board: StandaloneBoard|null = yield call(standaloneStorageManager.getBoard, action.payload.board.uid);
    if (board && !action.payload.board.tempBoard) {
        yield call(standaloneStorageManager.updateBoard, {
            ...board,
            name: action.payload.board.name,
            nameAbbreviation: action.payload.board.nameAbbreviation,
            tags: action.payload.board.tags.toJS(),
            codySuggestEnabled: action.payload.board.codySuggestEnabled,
            eventModelingEnabled: action.payload.board.eventModelingEnabled,
        });
    }
}

export function* standaloneEditBoardFlow() {
    while (true) {
        const action: FlowAction = yield take([
            Action.Type.RENAME_BOARD,
            Action.Type.CHANGE_BOARD_ABBREVIATION,
            Action.Type.SET_BOARD_TAGS,
            Action.Type.TOGGLE_CODY_SUGGEST_ENABLED,
            Action.Type.TOGGLE_EVENT_MODELING_ENABLED,
        ]);
        yield fork(onSaveBoard, action);
    }
}
