import { fork, put, take } from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {
    loadFirstBoardHistoryChunk,
    loadNextBoardHistoryChunk,
    setIsLoadingHistory,
    setNoMoreHistoryAvailable
} from "../../InspectioBoards/actions/commands";
import {LOAD_FIRST_BOARD_HISTORY_CHUNK} from "../../InspectioBoards/actions/constants";

type FlowAction = ActionType<typeof loadNextBoardHistoryChunk> & ActionType<typeof loadFirstBoardHistoryChunk>;

function* onLoadFirst(action: ActionType<typeof loadFirstBoardHistoryChunk>) {
    yield put(setIsLoadingHistory(action.payload.boardId, true));
    yield put(setNoMoreHistoryAvailable(action.payload.boardId));
    yield put(setIsLoadingHistory(action.payload.boardId, false));
}

export function* standaloneLoadBoardHistoryFlow() {
    while (true) {
        const action: FlowAction = yield take(LOAD_FIRST_BOARD_HISTORY_CHUNK);
        yield fork(onLoadFirst, action);
    }
}
