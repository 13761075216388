import * as H from 'history';
import copy from "copy-to-clipboard";

interface EditorUi {
    editor: Editor;
}

interface Editor {
    graph: Graph;
}

interface Graph {
    setHistory: (history: H.History) => void;
    enableCopyToClipboard: (copy: CopyToClipboardApi) => void;
    setDeeplinkFactory: (factory: WorkspaceDeeplinkFactory) => void;
    enableNotifications: (func: Notify) => void;
}

type CopyToClipboardApi = typeof copy;

type WorkspaceDeeplinkFactory = (cellId: string) => string;

type Notify = (title: string, msg: string, type: 'info' | 'error') => void;

export const initializeGraphEditor = (
    editorUi: EditorUi,
    history: H.History<any>,
    copyApi: CopyToClipboardApi,
    deeplinkFactory: WorkspaceDeeplinkFactory,
    notifyFunc: Notify,
) => {
    const graph = editorUi.editor.graph;

    graph.setHistory(history);
    graph.enableCopyToClipboard(copyApi);
    graph.setDeeplinkFactory(deeplinkFactory);
    graph.enableNotifications(notifyFunc);
};




