import {List} from "immutable";
import { map } from 'lodash';
import { call, delay, fork, put, race, select, take } from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import * as Action from "../actions";
import {
    loadFirstBoardHistoryChunk,
    loadNextBoardHistoryChunk,
    setIsLoadingHistory,
    setNoMoreHistoryAvailable
} from "../actions/commands";
import {LOAD_FIRST_BOARD_HISTORY_CHUNK, LOAD_NEXT_BOARD_HISTORY_CHUNK} from "../actions/constants";
import {Board} from "../model/Board";
import {BoardChanged} from "../model/BoardChanged";
import {BoardHistory, HistoryAction, HistoryCellType} from "../model/HistoryEntry";
import {HistoryState} from "../model/HistoryState";
import {makeBoardByIdSelector} from "../selectors/board";
import {makeBoardHistoryByIdSelector} from "../selectors/boardHistory";
import {onBoardChanged} from "./prepareBoardHistoryFlow";
import {BoardVersion} from "../model/BoardVersion";

type FlowAction = ActionType<typeof loadNextBoardHistoryChunk> & ActionType<typeof loadFirstBoardHistoryChunk>;

interface ServerPatch {
    action: string;
    boardId: string;
    boardVersion: {
        version: number;
        userId: string;
        head: null|boolean;
    },
    cellId: null|string;
    cellLabel: string;
    cellType: string;
    patch: string[];
    patchId: string;
    recordedAt: string;
    userId: string;
}

export const marshalBoardChanged = (data: ServerPatch): BoardChanged => {
    return new BoardChanged({
        board: data.boardId,
        cellId: data.cellId || undefined,
        cellLabel: data.cellLabel,
        cellType: data.cellType as HistoryCellType,
        action: data.action as HistoryAction,
        recordedAt: data.recordedAt,
        userId: data.boardVersion.userId,
        version: data.boardVersion.version,
        changeSet: List(data.patch),
        name: 'BoardChanged',
        uid: data.patchId,
    })
};

function* onLoadFirst(action: ActionType<typeof loadFirstBoardHistoryChunk>) {
    const getBoardById = makeBoardByIdSelector(action.payload.boardId);
    const getBoardHistory = makeBoardHistoryByIdSelector(action.payload.boardId);

    const board: Board = yield select(getBoardById);
    const history: HistoryState = yield select(getBoardHistory);

    if(board && !history.loading) {
        yield put(setIsLoadingHistory(action.payload.boardId, true));

        const {response, error}: ResponseType = yield call(Action.Api.fetchOlderPatches, action.payload.boardId, board.version);

        if(error) {
            yield put(setNoMoreHistoryAvailable(action.payload.boardId));
            yield put(setIsLoadingHistory(action.payload.boardId, false));
            return;
        }

        if(response) {
            const patches = map(response.data as any, (data: any) => marshalBoardChanged(data));

            if(patches.length <= 1) {
                yield put(setNoMoreHistoryAvailable(action.payload.boardId));
            }

            yield call(onBoardChanged, patches);
        }

        yield put(setIsLoadingHistory(action.payload.boardId, false));
    }
}

function* onLoadNext(action: ActionType<typeof loadNextBoardHistoryChunk>) {

    yield put(setIsLoadingHistory(action.payload.boardId, true));

    const {response, error}: ResponseType = yield call(Action.Api.fetchOlderPatches, action.payload.boardId, action.payload.boardVersion);

    if(error) {
        yield put(setNoMoreHistoryAvailable(action.payload.boardId));
        yield put(setIsLoadingHistory(action.payload.boardId, false));
        return;
    }

    if(response) {
        const patches = map(response.data as any, (data: any) => marshalBoardChanged(data));

        if(patches.length <= 1) {
            yield put(setNoMoreHistoryAvailable(action.payload.boardId));
        }

        yield call(onBoardChanged, patches)
    }

    yield put(setIsLoadingHistory(action.payload.boardId, false));
}


export function* loadBoardHistoryFlow() {
    while (true) {
        const action: FlowAction = yield take([LOAD_FIRST_BOARD_HISTORY_CHUNK, LOAD_NEXT_BOARD_HISTORY_CHUNK]);

        switch (action.type) {
            case LOAD_NEXT_BOARD_HISTORY_CHUNK:
                yield fork(onLoadNext, action);
                break;
            case LOAD_FIRST_BOARD_HISTORY_CHUNK:
                yield fork(onLoadFirst, action);
        }
    }
}
