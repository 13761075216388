import {Node} from "../../../model/Graph";
import {names} from "../node/names";
import {getVoMetadata, ValueObjectMetadata} from "./get-vo-metadata";
import {isStateVo} from "./is-state-vo";

export const getSchemaRefName = (vo: Node, voMeta: ValueObjectMetadata, asPropertyRef?: boolean): string => {
  const ns = voMeta.ns || '/';
  const voClassName = names(vo.getName()).className;

  const propRef = isStateVo(vo) && asPropertyRef ? ':' + voMeta.identifier : '';

  return ns + (ns[ns.length - 1] === "/" ? voClassName : '/' + voClassName) + propRef;
}
