import {JSONSchema7} from "json-schema";
import {makeAssignVariable, makeRuleThenPropMapping} from "./rule-engine-definitions";
import {makeSidebarSchema, makeTabSchema} from "./ui-schema";

export const makeUiConfigSchema = (possibleStateRefs: string[], possibleQueryableRefs: string[]): string => {
  const routeSchema: JSONSchema7 = {type: "string", minLength: 1, pattern: "^/[a-z]+"};

  const uiConfig: JSONSchema7 = {
    type: "object",
    additionalProperties: false,
    required: ["route"],
    properties: {
      route: routeSchema,
      routeParams: {type: "array", items: {type: "string"}},
      sidebar: makeSidebarSchema(possibleQueryableRefs),
      tab: makeTabSchema(),
      breadcrumb: {
        oneOf: [
          {type: "string", minLength: 1},
          {
            type: "object",
            additionalProperties: false,
            required: ["data", "label"],
            properties: {
              data: {type: "string", enum: possibleStateRefs},
              label: {
                oneOf: [
                  {type: "string", minLength: 1},
                  {$ref: "#/definitions/rule_then_assign_variable"}
                ]
              }
            }
          }
        ]
      }
    },
    definitions: {
      ...makeAssignVariable('value'),
      ...makeRuleThenPropMapping()
    }
  }

  return JSON.stringify(uiConfig);
}
