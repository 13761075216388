import {Node, NodeType} from "../../../model/Graph";

export const getConnectedEventIfPossible = (vo: Node): Node | undefined => {
  const sources = vo.getSources();

  if(sources.count() === 1) {
    const source = sources.first(undefined);

    if(source && source.getType() === NodeType.event) {
      return source;
    }
  }
}
