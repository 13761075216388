import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {createOrganizationInvitation, Status} from "../model/OrganizationInvitation";
import {makeOrganizationSelector} from "../selectors/selectOrganization";

type Command = ActionType<typeof Action.Command.revokeUserOrganizationInvitation>

function* handleRevokeUserOrganizationInvitation(command: Command) {
    const {response, error} = yield call(Action.Api.revokeUserOrganizationInvitation, command.payload.organizationId, command.payload.invitedUserEmail);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not revoke invitation.'));
    }

    if(response) {
        const invitation = createOrganizationInvitation({
            invitationId: command.payload.invitationId,
            invitedUserEmail: command.payload.invitedUserEmail,
        });
        const organization = yield select(makeOrganizationSelector(command.payload.organizationId));

        yield put(Action.Event.userOrganizationInvitationRevoked(
            invitation.set('status', Status.revoked),
            organization.revokeInvitation(invitation.invitationId)
        ));
    }
}

export function* revokeUserOrganizationInvitation() {
    while (true) {
        const command: Command = yield take([
            Action.Type.REVOKE_USER_ORGANIZATION_INVITATION
        ]);

        yield fork(handleRevokeUserOrganizationInvitation, command);
    }
}
