import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {makeOrganizationSelector} from "../selectors/selectOrganization";

type Command = ActionType<typeof Action.Command.demoteUserToOrganizationMember>

function* handleDemoteUserToOrganizationMember(command: Command) {
    const {response, error} = yield call(Action.Api.demoteUserToOrganizationMember, command.payload.organizationId, command.payload.userId);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not demote user to organization member.'));
    }

    if(response) {
        const organization = yield select(makeOrganizationSelector(command.payload.organizationId));

        yield put(Action.Event.userDemotedToOrganizationMember(organization.demoteToMember(command.payload.userId)));

        yield put(NotifyAction.Command.info('User demoted', 'The user is no longer an admin of the organization.'));
    }
}

export function* demoteUserToOrganizationMember() {
    while (true) {
        const command: Command = yield take([
            Action.Type.DEMOTE_USER_TO_ORGANIZATION_MEMBER
        ]);

        yield fork(handleDemoteUserToOrganizationMember, command);
    }
}
