import {List} from "immutable";
import {action} from "typesafe-actions";
import {Organization} from "../model/Organization";
import {OrganizationInvitation} from "../model/OrganizationInvitation";
import {UserOrganizationInfo} from "../model/UserOrganizationInfo";
import {
    EXPLICIT_ORGANIZATION_SET_UP,
    EXPLICIT_ORGANIZATION_SET_UP_FAILED,
    GUEST_REMOVED_FROM_ORGANIZATION,
    GUEST_TURNED_INTO_MEMBER,
    INVITATION_TO_ORGANIZATION_ACCEPTED,
    ORGANIZATION_FETCHED,
    ORGANIZATION_RENAMED,
    USER_DEMOTED_TO_ORGANIZATION_MEMBER,
    USER_INVITED_TO_ORGANIZATION,
    USER_ORGANIZATION_INVITATION_RESENT,
    USER_ORGANIZATION_INVITATION_REVOKED,
    USER_ORGANIZATIONS_FETCHED,
    USER_PROMOTED_TO_ORGANIZATION_ADMIN,
    USER_REMOVED_FROM_ORGANIZATION
} from "./constants";

export const organizationFetched = (organization: Organization) => action(ORGANIZATION_FETCHED, organization);
export const explicitOrganizationSetUp = (organization: Organization) => action(EXPLICIT_ORGANIZATION_SET_UP, organization);
export const explicitOrganizationSetUpFailed = (organization: Organization) => action(EXPLICIT_ORGANIZATION_SET_UP_FAILED, organization);
export const userOrganizationsFetched = (userOrganizations: List<UserOrganizationInfo>) => action(USER_ORGANIZATIONS_FETCHED, userOrganizations);
export const userInvitedToOrganization = (organization: Organization) => action(USER_INVITED_TO_ORGANIZATION, organization);
export const userOrganizationInvitationRevoked = (invitation: OrganizationInvitation, organization: Organization) => action(USER_ORGANIZATION_INVITATION_REVOKED, {invitation, organization});
export const userOrganizationInvitationResent = (invitation: OrganizationInvitation, organization: Organization) => action(USER_ORGANIZATION_INVITATION_RESENT, {invitation, organization});
export const invitationToOrganizationAccepted = (organization: Organization) => action(INVITATION_TO_ORGANIZATION_ACCEPTED, organization);
export const userPromotedToOrganizationAdmin = (organization: Organization) => action(USER_PROMOTED_TO_ORGANIZATION_ADMIN, organization);
export const userDemotedToOrganizationMember = (organization: Organization) => action(USER_DEMOTED_TO_ORGANIZATION_MEMBER, organization);
export const userRemovedFromOrganization = (organization: Organization) => action(USER_REMOVED_FROM_ORGANIZATION, organization);
export const organizationRenamed = (organization: Organization) => action(ORGANIZATION_RENAMED, organization);
export const guestRemovedFromOrganization = (organization: Organization) => action(GUEST_REMOVED_FROM_ORGANIZATION, organization);
export const guestTurnedIntoMember = (organization: Organization) => action(GUEST_TURNED_INTO_MEMBER, organization);
