import {Map} from 'immutable';
import {Reducer} from 'redux';
import {upsertAggregates} from "../../core/reducer/applyAggregateChanged";
import {PLAYSHOT_SUMMARY_LIST_FETCHED} from "../actions/constants";
import {PlayshotSummary} from "../model/PlayshotSummary";
import {InspectioBoardsAction} from './index';


export interface PlayshotState extends Map<string, PlayshotSummary> {
}

export const initialState: PlayshotState = Map<string, PlayshotSummary>();

const reducer: Reducer<PlayshotState, InspectioBoardsAction> = (state: PlayshotState = initialState, event: InspectioBoardsAction): PlayshotState => {
  switch (event.type) {
    case PLAYSHOT_SUMMARY_LIST_FETCHED:
      return upsertAggregates(state, event.payload.summaries);
    default:
      return state;
  }
};

export {reducer as savePlayshotReducer};
