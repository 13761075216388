import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {makeGetTeamBoards} from "../../InspectioBoards/selectors/boardList";
import {makeIioFilteredUserListSelector} from "../../User/selectors/iioUserInfo";
import {Team} from "../model/Team";
import BoardEntry from "./BoardEntry";
import MemberEntry from "./MemberEntry";

interface OwnProps {
    team: Team;
}

type JoinedTeamCardProps = OwnProps & WithNamespaces;

const JoinedTeamCard = (props: JoinedTeamCardProps) => {
    const members = useSelector(makeIioFilteredUserListSelector(props.team.members));
    const boards = useSelector(makeGetTeamBoards(props.team.uid));

    const memberList = members.sortBy(m => m.username).sort((mA, mB) => {
        if(mA.userId === props.team.ownerId) {
            return  -1;
        }

        if(mB.userId === props.team.ownerId) {
            return 1;
        }

        return 0;
    }).map(member => <MemberEntry
        key={member.userId}
        member={member}
        isOwner={props.team.ownerId === member.userId}
        teamId={props.team.uid}
        readonly={true}
    />);

    const boardList = boards.map(board => <BoardEntry board={board} team={props.team} key={board.uid} readonly={true}/>);

    return <div className="ui segment">
        <h3 style={{margin:0}}>
            {props.team.name}
            <span> &mdash; </span>
            <span className="sub header">
                {props.team.description}
            </span>
        </h3>
        <div className="ui horizontal divider">Teammember</div>
        <div className="ui horizontal list">
            {memberList}
        </div>
        <div className="ui horizontal divider">Teamboards</div>
        <div className="ui horizontal list">
            {boardList}
        </div>
    </div>;
};

export default withNamespaces()(JoinedTeamCard);