import {List} from "immutable";
import {call, fork, put,select, take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import * as uuid from "uuid";
import {ResponseType} from "../../api/util";
import {TeamId} from "../../InspectioTeams/model/Team";
import {Action as NotifyAction} from "../../NotificationSystem";
import {makeActiveUserOrganizationInfoSelector} from "../../Organization/selectors/selectUserOrganizationInfo";
import {UserId, UserInfo} from "../../User/model/UserInfo";
import {UserSelector} from "../../User/selectors";
import * as Action from "../actions";
import {Board, deriveAbbreviationFromBoardName} from "../model/Board";
import {BoardVersion} from "../model/BoardVersion";
import {makeEmptyGraph} from "../model/Graph";
import {useGraph} from "../hooks/useGraph";
import {DEFAULT_PERMISSIONS} from "../model/BoardPermissionForUser";

type FlowAction = ActionType<typeof Action.Command.importNewBoard>;

function* importNewBoard(action: FlowAction) {
    const boardId = action.payload.boardId || uuid.v4();

    const organization = yield select(makeActiveUserOrganizationInfoSelector());

    if(!organization) {
        yield put(NotifyAction.Command.error('Board Import Failed', 'No organization selected.'));
        return;
    }

    let response: ResponseType | null = null;

    if(!action.payload.importTemporary){
        response = yield call(
            Action.Api.installOrganizationBoard,
            organization.organizationId,
            boardId,
            action.payload.boardName,
            deriveAbbreviationFromBoardName(action.payload.boardName),
            []
        );
    }

    if (response && response.error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not add board.'));
    }
    if ((response && response.response) || action.payload.importTemporary) {
        if(!action.payload.importTemporary) {
            yield put(NotifyAction.Command.info('Board created', 'Board created'));
        }


        const getUser = UserSelector.makeGetCurrentUser();

        const user: UserInfo = yield select(getUser);

        const board = new Board({
            uid: boardId,
            name: action.payload.boardName,
            nameAbbreviation: deriveAbbreviationFromBoardName(action.payload.boardName),
            tags: List(),
            createdAt: (() => new Date())().toISOString(),
            xml: action.payload.boardXml,
            version: new BoardVersion({
                userId: user.uid,
                version: 1
            }),
            shared: false,
            sharedWith: List<UserId>(),
            assignedTeams: List<TeamId>(),
            owner: null,
            creator: user.uid,
            organization: organization.organizationId,
            tempBoard: !!action.payload.importTemporary,
            orgaMemberPermissions: DEFAULT_PERMISSIONS,
            anyonePermissions: DEFAULT_PERMISSIONS,
            codySuggestEnabled: true,
            eventModelingEnabled: true,
        });

        yield put(Action.Command.updateBoards([board]));

        const [graph, setGraph] = useGraph();

        const emptyGraphWithXml = makeEmptyGraph(board.xml || undefined);

        setGraph(emptyGraphWithXml);

        yield put(Action.Command.saveBoard(board, emptyGraphWithXml));
    }
}

export function* importNewBoardFlow() {
    while (true) {
        const action: FlowAction = yield take([Action.Type.IMPORT_NEW_BOARD]);

        yield fork(importNewBoard, action);
    }
}
