import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../../InspectioBoards/index";
import {StandaloneBoard} from "../storage/types";
import {standaloneStorageManager} from "../util";
import {Action as NotifyAction} from "../../NotificationSystem";
import {List} from "immutable";

type Command = ActionType<typeof Action.Command.importStickyTemplates>

function* handleImportStickyTemplates(command: Command) {
    const board: StandaloneBoard|null = yield call(standaloneStorageManager.getBoard, command.payload.boardId);
    if (!board) {
        return;
    }

    const templates = command.payload.stickyTemplates.map(tpl => ({
        cellType: tpl.cellType,
        template: tpl.template,
        templateType: tpl.templateType,
        templateSchema: tpl.templateSchema || null
    }));

    yield call(standaloneStorageManager.updateBoard, {
        ...board,
        stickyTemplates: templates.toArray()
    });

    yield put(NotifyAction.Command.info('Card Templates imported', 'You can now use them on the board!'));
    yield put(Action.Event.stickyTemplatesImported(List(command.payload.stickyTemplates)));
    command.payload.finishedCb();
}

export function* importStickyTemplates() {
    while (true) {
        const command: Command = yield take([
            Action.Type.IMPORT_STICKY_TEMPLATES
        ]);

        yield fork(handleImportStickyTemplates, command);
    }
}
