import {List} from "immutable";
import * as _ from 'lodash';
import React from "react";
import {withNamespaces} from "react-i18next";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { compose, lifecycle } from 'recompose';
import { bindActionCreators, Dispatch } from 'redux';
import { Command, Event } from '../actions';
import { Notifications, NotificiationsProps } from '../components/Notifications';
import { NotificationModel } from '../model';
import { makeMapStateTopPropsNotifications } from "../selectors/message";

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = makeMapStateTopPropsNotifications();

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        sendNotify: Command.info,
        onHandled: Event.publishMessageHandled
    },
    dispatch,
);

let currentlyVisible: string[] = [];

const showUnhandledMessages = (messages: List<NotificationModel.Message>, onHandled: (msg: NotificationModel.Message) => void, props: NotificiationsProps) => {
    messages.filter((value?: NotificationModel.Message) => {
        if (!value) {
            return false;
        }
        return !value.handled;
    })
        .forEach((msg?: NotificationModel.Message) => {
            if (!msg) {
                return;
            }

            if(msg.uid && currentlyVisible.includes(msg.uid)) {
                return;
            }

            let message: any = msg.message;

            if(message instanceof Error) {
                message = message.message;
            }
            if(msg.uid) {
                currentlyVisible.push(msg.uid);
            }

            toast(
                <div><p><strong>{ props.t(msg.title+'')}</strong></p>{props.t(message)}</div>,
                {
                    type: msg.level,
                    autoClose: msg.level === 'error'? false : 2000,
                    hideProgressBar: msg.hideProgressBar,
                    onClose: () => {
                        if(msg.uid) {
                            currentlyVisible = currentlyVisible.filter(uid => uid !== msg.uid);
                        }
                        onHandled(msg.markAsHandled())
                    }
                });
        });
};

export default withNamespaces()(compose<NotificiationsProps, any>(
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
        componentDidMount() {
            const { messages, onHandled } =
                this.props as NotificiationsProps;

            if (!messages) {
                return;
            }

            showUnhandledMessages(messages, onHandled, this.props as NotificiationsProps);
        },
        componentDidUpdate() {
            const { messages, onHandled } =
                this.props as NotificiationsProps;

            if (!messages) {
                return;
            }

            showUnhandledMessages(messages, onHandled, this.props as NotificiationsProps);
        }
    }),
)(Notifications));
