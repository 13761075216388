import * as React from 'react';
import {useEffect, useMemo, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Message, Modal, Table} from "semantic-ui-react";
import {isFailure} from "../../../core/validation/either";
import {Action as NotifyAction} from "../../../NotificationSystem";
import {makeGetCurrentUser} from "../../../User/selectors/currentUser";
import {makeIioFilteredUserListSelector} from "../../../User/selectors/iioUserInfo";
import * as Action from "../../actions";
import {togglePlayshotModal} from "../../actions/commands";
import {BoardId} from "../../model/Board";
import {Playshot} from "../../model/Playshot";
import {PlayshotId, PlayshotSummary} from "../../model/PlayshotSummary";
import {makeBoardByIdSelector} from "../../selectors/board";
import {makePlayshotSummaryListSelector} from "../../selectors/PlayshotSummary";
import {CODY_PLAY_SERVER_NAME, useIIO} from "../../service/Cody";
import {loadPlayshot} from "../../service/playshot-storage";
import PlayshotTableRow from "./PlayshotTableRow";

interface PlayshotModalRouteMatchParams {
  uid: BoardId;
}

interface OwnProps {

}

type PlayshotModalProps = OwnProps & RouteComponentProps<PlayshotModalRouteMatchParams> & WithNamespaces;

const PlayshotModal = (props: PlayshotModalProps) => {
  const dispatch = useDispatch();

  const getBoardByIdSelector = useMemo(() => makeBoardByIdSelector(props.match.params.uid), []);
  const board = useSelector(getBoardByIdSelector);
  const visible = board && board.shouldShowPlayshotModal;
  const playshots = useSelector(makePlayshotSummaryListSelector(props.match.params.uid));
  const [loadingPlayshotId, setLoadingPlayshotId] = useState('');

  const userIds = playshots.map(playshot => playshot.savedBy);
  const users = useSelector(makeIioFilteredUserListSelector(userIds));
  const iio = useIIO();
  const currentUser = useSelector(makeGetCurrentUser());

  useEffect(() => {
    if(visible) {
      dispatch(Action.Query.fetchPlayshotSummaryList(props.match.params.uid));
    }
  }, [visible]);

  const handleClose = () => {
    setLoadingPlayshotId('');
    dispatch(togglePlayshotModal(props.match.params.uid, false));
  }

  const initCodyPlay = (playshot: Playshot): void => {
    if(!board) {
      dispatch(NotifyAction.Command.error('Failed to load Board', 'Please contact the prooph board team!'))
      return;
    }

    if(!iio.isConnected(CODY_PLAY_SERVER_NAME)) {
      iio.connect.Cody('play', currentUser.name, board.uid, CODY_PLAY_SERVER_NAME);
    }

    iio.runCommand(board.uid, board.name, currentUser.uid, 'InitPlayshot', playshot, CODY_PLAY_SERVER_NAME);

    window.setTimeout(handleClose, 500);
  }

  const handleInitPlayshot = (playshotId: PlayshotId) => {
    setLoadingPlayshotId(playshotId);

    loadPlayshot(board.uid, playshotId).then(playshot => {
      initCodyPlay(playshot);
    }).catch((e: Error) => {
      dispatch(NotifyAction.Command.error('Failed to load playshot', e.message));
    })
  }

  const handleFromScratch = () => {
    initCodyPlay(new Playshot({
      name: 'From Scratch Playshot',
      playConfig: {},
      playData: {streams: {}, documents: {}},
      savedBy: currentUser.uid,
      boardId: board.uid,
    }))
  }

  return <Modal open={visible} closeIcon={true} onClose={handleClose}>
    <Modal.Header>Cody Playshots</Modal.Header>
    <Modal.Content scrolling={true}>
      <p>{props.t('insp.playshots.select_playshot')}</p>
      {playshots.count() === 0 && <Message info={true}>{props.t('insp.playshots.empty')}</Message>}
      <Table unstackable={true} basic="very">
        <Table.Body>
          {playshots.sort((pA, pB) => pA.savedAt < pB.savedAt? 1 : -1 ).map(playshot => <PlayshotTableRow playshot={playshot} users={users} key={playshot.uid} loading={loadingPlayshotId === playshot.uid} onInitPlayshot={handleInitPlayshot} />).toArray()}
        </Table.Body>
      </Table>
    </Modal.Content>
    <Modal.Actions>
      <Button basic={true} className="noborder" icon="play circle" content={props.t('insp.playshots.start_from_scratch')} onClick={handleFromScratch} />
    </Modal.Actions>
  </Modal>
};

export default withRouter(withNamespaces()(PlayshotModal));
