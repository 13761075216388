import {JSONSchema7} from "json-schema";
import {ElementsTree} from "../../../../model/ElementsTree";
import {Node, NodeType} from "../../../../model/Graph";
import {RulesDependency, RulesDependencyMetadata} from "../../../../service/cody-wizard/rule-engine/types";
import {Schema} from "../../../../service/cody-wizard/schema/schema";
import {getVoMetadata} from "../../../../service/cody-wizard/vo/get-vo-metadata";

export const makeDependenciesSchema = (dependencies: Record<string, RulesDependencyMetadata>, messageSchema: Schema, elementsTree: ElementsTree): string => {

  const properties: Record<string, JSONSchema7> = {};

  Object.keys(dependencies).forEach(depName => {
    const dep = dependencies[depName];

    properties[depName] = makeDependencySchema(dep, messageSchema, elementsTree);
  })

  const schema: JSONSchema7 = {
    type: "object",
    additionalProperties: true,
    required: Object.keys(dependencies),
    properties,
  }

  return JSON.stringify(schema);
}

const makeDependencySchema = (dep: RulesDependencyMetadata, messageSchema: Schema, elementsTree: ElementsTree): JSONSchema7 => {
  return {
    type: "object",
    additionalProperties: false,
    properties: {
      alias: {type: "string"},
      type: {type: "string", enum: ["query", "service", "events"]},
      options: dep.type === "query"
        ? {type: "object"} /* @TODO: fix dep is no longer a Node {
          type: "object",
          additionalProperties: false,
          properties: {
            mapping: makeMappingSchema(dep, messageSchema, elementsTree),
            query: makeMappingSchema(dep, messageSchema, elementsTree)
          }
        } */
        : {type: "object"}
    }
  }
}

const makeMappingSchema = (node: Node, messageSchema: Schema, elementsTree: ElementsTree): JSONSchema7 => {
  if(node.getType() === NodeType.document) {
    const voMeta = getVoMetadata(node);
    let querySchema = voMeta.querySchema || new Schema({});

    if(querySchema.isRef()) {
      querySchema = querySchema.resolveRef(elementsTree);
    }

    if(!querySchema.isObject()) {
      return {type: "object"};
    }

    const mappingSchema: JSONSchema7 = {
      type: "string",
      enum: messageSchema.getObjectProperties()
    }

    const properties: Record<string, JSONSchema7> = {};

    querySchema.getObjectProperties().forEach(prop => {
      properties[prop] = mappingSchema;
    })

    properties.$not = {type: "string", enum: querySchema.getObjectProperties()};

    return {
      type: "object",
      additionalProperties: false,
      properties,
    }
  }

  return {type: "object"}
}
