import {fromJS} from "immutable";
import { combineReducers } from 'redux-immutable';
import {ActionType} from "typesafe-actions";
import * as Events from "../actions/events";
import {saveOrganizationReducer} from "./applyOrganizationEvents";
import {saveOrganizationInvitationReducer} from "./applyOrganizationInvitationEvents";
import {saveUserOrganizationInfoReducer} from "./applyUserOrganizationInfoEvents";

export type InspectioOrganizationEvent = ActionType<typeof Events>;

export interface InspectioOrganizationsState {
}

export const inspectioOrganizationsReducer = combineReducers<InspectioOrganizationsState, InspectioOrganizationEvent>({
    Organization: saveOrganizationReducer,
    UserOrganizationInfo: saveUserOrganizationInfoReducer,
    OrganizationInvitation: saveOrganizationInvitationReducer,
}, () => fromJS({
    Organization: undefined,
    UserOrganizationInfo: undefined,
    OrganizationInvitation: undefined,
}));
