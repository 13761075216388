import {Record} from 'immutable';
import * as uuid from "uuid";
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {Email, UserId} from "../../User/model/UserInfo";
import {BoardId} from "./Board";
import {Permissions} from "./BoardPermissionForUser";

export const AGGREGATE_TYPE = 'BoardInvitation';

export type BoardInvitationId = string;
export type BoardInvitationError = string;
export type BoardInvitationStatus = 'pending'|'acknowledged'|'deactivated'|'revoked';

export interface BoardInvitationProps {
    uid: BoardInvitationId;
    aggregateType: AggregateType;
    boardId: BoardId;
    invitedUserEmail: Email;
    invitingUser: UserId;
    permissions: Permissions;
    status: BoardInvitationStatus;
    resendAttempts: number;
}

export const defaultBoardInvitationProps: BoardInvitationProps = {
    uid: '',
    aggregateType: AGGREGATE_TYPE,
    boardId: '',
    invitedUserEmail: '',
    invitingUser: '',
    permissions: {adminAccess: false, readAccess: false, writeAccess: false},
    status: 'pending',
    resendAttempts: 0,
};

export const createBoardInvitationFromServerData = (data: any): Either<BoardInvitationError, BoardInvitation> => {
    const validatedData: Either<BoardInvitationError, any> = validate(data,
        [hasKey('invitationId'), "invitationId missing"],
        [hasKey('boardId'), "boardId missing in " + data.permissionsId],
        [hasKey('invitedUserEmail'), "invitedUserEmail missing in " + data.permissionsId],
        [hasKey('invitingUser'), "invitingUser missing in " + data.permissionsId],
        [hasKey('permissions'), "permissions missing in " + data.permissionsId],
        [hasKey('status'), "status missing in " + data.permissionsId],
    );

    if (isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new BoardInvitation({
        ...defaultBoardInvitationProps,
        ...data,
        uid: data.invitationId,
    }));
};

export class BoardInvitation extends Record(defaultBoardInvitationProps) implements BoardInvitationProps, Aggregate {
    public constructor(data: BoardInvitationProps) {
        if (data.uid === '') {
            data.uid = uuid.v4()
        }

        super(data);
    }

    public resend(): BoardInvitation
    {
        return this.set('resendAttempts', this.resendAttempts + 1);
    }

    public revoke(): BoardInvitation
    {
        return this.set('status', 'revoked');
    }
}
