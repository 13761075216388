import * as React from 'react';
import {SyntheticEvent, useEffect, useRef, useState} from "react";
import {Input, Message} from "semantic-ui-react";

interface OwnProps {
    text: string;
    textSuggestion?: string;
    charSize: number;
    widthUnit: string;
    onTextChanged: (text: string) => void;
    placeholder?: string;
    padding?: number;
    minWidth?: number;
    fixedWidth?: boolean;
    required?: boolean;
    maxLength?: number;
    border?: string;
    focus?: boolean;
    onCancel?: () => void;
    disabled?: boolean;
    textStyle?: React.CSSProperties;
    validate?: (text: string) => boolean | string;
}

type EditableTextlineProps = OwnProps;

const EditableTextline = (props: EditableTextlineProps) => {
    const [enterText, setEnterText] = useState(props.focus || false);
    const [hasError, setHasError] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [value, setValue] = useState(props.text || props.textSuggestion || '');
    const inputEl = useRef<Input>(null);

    useEffect(() => {
        setValue(props.text || props.textSuggestion || '');

        if(!props.text && props.textSuggestion && props.validate) {
            handleChange(props.textSuggestion);
        }

    }, [props.text, props.textSuggestion]);

    const handleTextClick = () => {
        setEnterText(true);
        window.setTimeout(() => {
            if(inputEl && inputEl.current) {
                inputEl.current.focus();
            }
        }, 200);
    };

    const handleChange = (newVal: string) => {
        if(newVal !== '' && hasError) {
            setHasError(false);
        }

        if(newVal === '' && props.required && !hasError) {
            setHasError(true);
        }

        if(props.maxLength && newVal.length > props.maxLength) {
            setHasError(true);
        }

        if(props.validate) {
            const result = props.validate(newVal);

            if(result === false) {
                setHasError(true);
            } else if (result === true) {
                setHasError(false);
                setErrMsg('');
            } else {
                setHasError(true);
                setErrMsg(result);
            }
        }

        setValue(newVal);
    }

    const handleKeyDown = (e: KeyboardEvent) => {
        if(e.key === 'Enter') {
            handleBlur();
        } else if (e.key === 'Escape') {
            setValue(props.text);
            setHasError(false);
            setEnterText(false);
            if(props.onCancel) {
                props.onCancel();
            }
        }
    }

    const handleBlur = () => {
        if(props.required && value === '') {
            setHasError(true);
            refocus();
        } else {
            if(value !== props.text && !hasError) {
                props.onTextChanged(value);
            }

            if(!hasError) {
                setEnterText(false);
            } else {
                refocus();
            }
        }
    }

    const refocus = () => {
        window.setTimeout(() => {
            if(inputEl && inputEl.current) {
                inputEl.current.focus();
            }
        }, 200);
    }

    const valueLength = value !== '' ? value.length : (props.placeholder? props.placeholder.length : 0);
    const padding = props.padding || 0;
    let width = (props.charSize * valueLength + padding);
    if(props.minWidth && (props.fixedWidth || width < props.minWidth)) {
        width = props.minWidth;
    }

    let border = props.border || '2px solid #4593CE';

    if(hasError) {
        border = '4px solid #E0B4B4';
    }

    const text = props.text === ''? props.placeholder || '' : props.text;

    const showInput = enterText && !props.disabled;

    return <>
        {showInput && <Input
                            transparent={true}
                            error={hasError}
                            value={value}
                            ref={inputEl}
                            onBlur={handleBlur}
                            onChange={(e, data) => handleChange(data.value)}
                            onKeyDown={handleKeyDown}
                            placeholder={props.placeholder}
                            autoFocus={props.focus}
                            style={{width: width + props.widthUnit, borderBottom: border}}/>}
        {showInput && hasError && errMsg && <Message error={true} content={errMsg} style={{display: 'block'}} />}
        {!showInput && <span onClick={handleTextClick} style={props.textStyle}>{ text }</span>}
        </>;
};

export default EditableTextline;
