export const SERVER_CONTEXT = 'InspectIO.';

export const SIGN_IN = '@@InspectioUsers/SignIn';

// Queries
export const FETCH_USER = '@@InspectioBoards/User';
export const LOAD_IIO_USERS_INFO = '@@InspectioBoards/IioUsersInfo';

// Events
export const IIO_USERS_INFO_LOADED = '@@InspectioBoards/IioUsersInfoLoaded';
export const USER_SETTINGS_LOADED = '@@InspectioBoard/UserSettingsLoaded';
export const USER_SETTING_UPSERTED = '@@InsecptioBoard/UserSettingUpserted';

// User Update
export const UPDATE_PROFILE = '@@InspectioBoards/UserUpdate';
export const UPDATE_PASSWORD = '@@InspectioBoards/PasswordUpdate';
export const UPSERT_USER_SETTING = '@@InsecptioBoards/UpsertUserSetting';

// Active Organization
export const SWITCH_ACTIVE_ORGANIZATION = '@@InspectioBoards/SwitchActiveOrganization';
export const ACTIVE_ORGANIZATION_SWITCHED = '@@InspectioBoards/ActiveOrganizationSwitched';
