import * as _ from 'lodash';
import * as React from 'react';
import {ChangeEvent, useRef, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Dropdown, DropdownDivider, DropdownItem, DropdownMenu, Input, Search} from "semantic-ui-react";
import {Palette} from "../../theme/palette";
import {filterElementsTree} from "../actions/commands";
import {BoardId} from "../model/Board";
import {parseFilter} from "../model/ElementsTree";
import {NodeType} from "../model/Graph";
import {HistoryCellType} from "../model/HistoryEntry";
import {makeElementsTreeByBoardIdSelector} from "../selectors/elementsTree";
import CellIcon from "./CellIcon";

interface OwnProps {

}

interface SearchRouteProps {
    uid: BoardId;
}

type MxGraphBoardSearchProps = OwnProps & RouteComponentProps<SearchRouteProps> & WithNamespaces;

let lastFilterVal = '';
let lastSelectedType: HistoryCellType | undefined;

const withoutSelectedType = (searchVal: string, selectedType: HistoryCellType | undefined): string => {
    if(!selectedType) {
        return searchVal;
    }

    return searchVal.replace(`type:${selectedType};`, '');
}

const withSelectedType = (searchVal: string, selectedType: HistoryCellType | undefined): string => {
    if(!selectedType) {
        return searchVal;
    }

    return `type:${selectedType};` + searchVal;
}

const validFilterType = (type: HistoryCellType): boolean => {
    switch (type) {
        case NodeType.event:
        case NodeType.command:
        case NodeType.role:
        case NodeType.aggregate:
        case NodeType.document:
        case NodeType.policy:
        case NodeType.externalSystem:
        case NodeType.hotSpot:
        case NodeType.ui:
        case NodeType.boundedContext:
        case NodeType.feature:
        case NodeType.icon:
        case NodeType.image:
        case NodeType.freeText:
            return true;
        default:
            return false;
    }
}

let filterIsOpen = false;

const mapLowercaseTypeToNodeType = (type: string): HistoryCellType => {
    switch (type) {
        case HistoryCellType.boundedContext.toLowerCase():
            return HistoryCellType.boundedContext;
        case HistoryCellType.freeText.toLowerCase():
            return HistoryCellType.freeText;
        case HistoryCellType.hotSpot.toLowerCase():
            return HistoryCellType.hotSpot;
        case HistoryCellType.externalSystem.toLowerCase():
            return HistoryCellType.externalSystem;
        default:
            return type as HistoryCellType;
    }
}

const MxGraphBoardSearch = (props: MxGraphBoardSearchProps) => {
    const dispatch = useDispatch();

    const elementsTreeSelector = makeElementsTreeByBoardIdSelector(props.match.params.uid);
    const elementsTree = useSelector(elementsTreeSelector);
    const [value, setValue] = useState(elementsTree.filter);
    const searchInput = useRef<HTMLInputElement>(null);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedType, setSelectedType] = useState<HistoryCellType|undefined>();
    const [searchHasFocus, setSearchHasFocus] = useState(false);

    // Sync filter val from redux store with local state and focus search input
    if(lastFilterVal !== elementsTree.filter) {
        lastFilterVal = elementsTree.filter;
        lastSelectedType = selectedType;

        const parsedFilter = parseFilter(elementsTree.filter);

        if(parsedFilter.nodeTypes.length) {
            lastSelectedType = parsedFilter.nodeTypes[0] as HistoryCellType;

            if(Object.values(HistoryCellType).map(type => type.toLowerCase()).includes(lastSelectedType)) {
                setSelectedType(lastSelectedType);
            } else {
                lastSelectedType = selectedType;
            }
        }

        setValue(withoutSelectedType(elementsTree.filter, lastSelectedType));
    } else if(lastSelectedType && !selectedType) {
        const parsedFilter = parseFilter(elementsTree.filter);

        if(parsedFilter.nodeTypes.length && parsedFilter.nodeTypes[0] === lastSelectedType) {
            setValue(withoutSelectedType(elementsTree.filter, lastSelectedType));
            setSelectedType(lastSelectedType);
        }
    }

    if(elementsTree.focus) {
        if(searchInput && searchInput.current) {
            searchInput.current.focus();
            dispatch(filterElementsTree(props.match.params.uid, elementsTree.filter, false));
        }
    }

    const dispatchFilterChange = _.debounce((val) => {
        lastFilterVal = withSelectedType(val, selectedType);
        dispatch(filterElementsTree(props.match.params.uid, lastFilterVal))
    }, 500);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        dispatchFilterChange(e.target.value);
    };

    const handleFilterMouseDown = () => {
        filterIsOpen = showFilter;
    }

    const handleFilterClick = () => {
        if(!filterIsOpen) {
            setShowFilter(true);
        }
    }

    const handleSelectType = (type: HistoryCellType) => {
        lastFilterVal = withoutSelectedType(lastFilterVal, selectedType);
        setSelectedType(type);
        lastSelectedType = type;
        lastFilterVal = withSelectedType(lastFilterVal, type);
        dispatch(filterElementsTree(props.match.params.uid, lastFilterVal));
    }

    const handleClearFilter = () => {
        lastFilterVal = withoutSelectedType(lastFilterVal, selectedType);
        lastSelectedType = undefined;
        setSelectedType(undefined);
        dispatch(filterElementsTree(props.match.params.uid, lastFilterVal));
    }

    const handleClear = () => {
        setValue('');
        setSelectedType(undefined);
        lastFilterVal = '';
        lastSelectedType = undefined;
        dispatch(filterElementsTree(props.match.params.uid, ''))
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if(e.keyCode === 27) {
            handleClear();
        }
    }

    const filterColor = selectedType ? Palette.stickyColors[selectedType] : '#909090';
    const focusClass = searchHasFocus ? ' focus' : '';

    return <div className="item">
        <div className="ui left action right icon input">
            <Dropdown open={showFilter} icon={false} onClose={() => {
                setShowFilter(false)
            }} scrolling={true}>
                <DropdownMenu style={{zIndex: 99000}}>
                    <DropdownItem text="Clear Filter" icon="close" disabled={!selectedType} onClick={handleClearFilter}/>
                    <DropdownDivider />
                    {Object.values(HistoryCellType).filter(validFilterType).map(type => {
                        return <DropdownItem
                            key={type}
                            onClick={() => handleSelectType(type.toLowerCase())}
                            icon={<CellIcon cellType={type} />}
                            text={props.t('app.stickyTypes.'+type)  } />;
                    })}
                </DropdownMenu>
            </Dropdown>
            <div className={"ui left action input search filter"+focusClass} style={{
                borderTop: "1px solid #dedede",
                borderBottom: "1px solid #dedede",
                borderLeft: "1px solid #dedede",
                borderTopLeftRadius: "0.28571429rem",
                borderBottomLeftRadius: "0.28571429rem",
            }}>
                <button className="ui basic icon button" onMouseDown={handleFilterMouseDown} onClick={handleFilterClick}>
                    {!selectedType && <i className="filter icon" style={{color: filterColor}}/>}
                    {selectedType && <CellIcon cellType={mapLowercaseTypeToNodeType(selectedType)} size="normal" />}
                </button>
            </div>
            <input
                type="text"
                placeholder="Search..."
                onChange={handleChange}
                onFocus={() => setSearchHasFocus(true)}
                onBlur={() => setSearchHasFocus(false)}
                className="tree search" value={value}
                ref={searchInput}
                onKeyDown={handleKeyDown}
                style={{width: '80%'}}  />
            {!elementsTree.isFiltered() && !selectedType && <i className="search icon"/>}
            {(elementsTree.isFiltered() || selectedType) &&
            <i className="close link icon" style={{cursor: "pointer"}} onClick={handleClear}/>}
        </div>
    </div>
};

export default withNamespaces()(MxGraphBoardSearch);
