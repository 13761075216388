import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button} from "semantic-ui-react";

interface OwnProps {
  showAdvancedSettings: boolean;
  onShowChanged: (showAdvancedSettings: boolean) => void;
  disabled?: boolean;
}

type AdvancedButtonProps = OwnProps & WithNamespaces;

const AdvancedButton = (props: AdvancedButtonProps) => {
  return <div className={'field'} style={{marginTop: '30px'}}>
    {<Button basic={true} icon={props.showAdvancedSettings? 'minus' : 'add'} labelPosition="left" size="medium"
             content={props.t('insp.cody_wizard.advanced_settings.button_label')} color="blue" className="text"
             onClick={() => props.onShowChanged(!props.showAdvancedSettings)}
             disabled={props.disabled}/>}
  </div>
};

export default withNamespaces()(AdvancedButton);
