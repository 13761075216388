import * as React from 'react';
import {Button, Image, Label, Popup, SemanticSIZES} from "semantic-ui-react";
import {IioUserInfo} from "../model";

interface OwnProps {
    user: IioUserInfo.IioUserInfo,
    size?: SemanticSIZES,
    namePopup?: boolean,
}

type UserAvatarProps = OwnProps;

const UserAvatar = (props: UserAvatarProps) => {
    if(props.user.anonymous) {
        // Label looks ugly with an Icon only, hence we use a disabled button here. Icon alone does not work, because icons use other size options :-(
        return <Button circular={true} size={props.size || 'small'} className="user" icon="user" disabled={true} style={{cursor: "default"}}/>
    } else {
        const avatar = <Image size={props.size || 'small'} src={props.user.avatar()} avatar={true} verticalAlign="middle" />;

        if(props.namePopup) {
            return <Popup   trigger={avatar}
                            content={props.user.username}
                            position="bottom center" />
        }

        return avatar;
    }

};

export default UserAvatar;