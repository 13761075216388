import {List} from "immutable";
import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Grid, Header, Message} from "semantic-ui-react";
import {UserOrganizationInfo} from "../../../Organization/model/UserOrganizationInfo";
import {
    makeActiveUserOrganizationInfoSelector,
    makeUserOrganizationInfoListSelector
} from "../../../Organization/selectors/selectUserOrganizationInfo";
import {UserId, UserInfo} from "../../../User/model/UserInfo";
import {Team} from "../../model/Team";
import AddTeamCard from "../AddTeamCard";
import JoinedTeamCard from "../JoinedTeamCard";
import TeamCard from "../TeamCard";
import {makeGetCurrentUser} from "../../../User/selectors/currentUser";

interface OwnProps {
    teams: List<Team>,
    currentUser: UserInfo,
}

type TeamTabProps = OwnProps & WithNamespaces;

const TeamTab = (props: TeamTabProps) => {
    const activeOrganizationInfo = useSelector(makeActiveUserOrganizationInfoSelector());
    const organizations = useSelector(makeUserOrganizationInfoListSelector(props.currentUser.uid));

    const isPrivateWorkspace = !activeOrganizationInfo || !activeOrganizationInfo.explicitOrganization;
    const validTargetOrganizations = isPrivateWorkspace? organizations.filter(orga => orga.explicitOrganization && !orga.userIsGuest) : List();
    const teamsAreMovable = isPrivateWorkspace && validTargetOrganizations.count() > 0;


    const isAdmin = (team: Team, userId: UserId, orgaInfo?: UserOrganizationInfo) => {
        if(team.organizationId && orgaInfo && team.organizationId === orgaInfo.organizationId) {
            if(orgaInfo.userIsAdmin) {
                return true;
            }
        }

        return team.isAdmin(userId);
    }

    // First sort by name, then check if a team is marked as new and move it on top of the list
    const teamCards = props.teams.filter(team => isAdmin(team, props.currentUser.uid, activeOrganizationInfo))
        .sortBy(team => team.name)
        .sortBy(team => !team.isNew)
        .map((team) => {
            return <TeamCard isMovable={teamsAreMovable} targetOrganizations={validTargetOrganizations} team={team} key={team.uid}/>
        });

    const joinedTeamCards = props.teams.filter(team => !isAdmin(team, props.currentUser.uid, activeOrganizationInfo))
        .sortBy(team => team.name)
        .map((team) => {
            return <JoinedTeamCard team={team} key={team.uid}/>
        });

    return <>
        <Grid.Row>
            <Grid.Column computer="1" largeScreen="2" widescreen="3" />
            <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
                <Header as="h1" icon={true} textAlign='center'>
                    <Header.Content>
                        Manage Your Teams
                        <Header.Subheader />
                    </Header.Content>
                </Header>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <div>
                    <AddTeamCard disabled={false} />
                    {teamCards}
                </div>
            </Grid.Column>
        </Grid.Row>
        {teamCards.count() === 0 && joinedTeamCards.count() === 0 && <Grid.Row>
            <Grid.Column>
                <Message info={true} floating={true} content={props.t('insp.team.no_teams')} />
            </Grid.Column>
        </Grid.Row>}
        {joinedTeamCards.count() > 0 && <Grid.Row>
            <Grid.Column computer="1" largeScreen="2" widescreen="3"/>
            <Grid.Column mobile="16" tablet="16" computer="14" largeScreen="12" widescreen="10">
                <Header as="h2" icon={true} textAlign='center'>
                    <Header.Content>
                        Joined Teams
                        <Header.Subheader />
                    </Header.Content>
                </Header>
            </Grid.Column>
        </Grid.Row>}
        <Grid.Row>
            <Grid.Column>
                {joinedTeamCards}
            </Grid.Column>
        </Grid.Row>
    </>
};

export default withNamespaces()(TeamTab);
