import {List, Record} from "immutable";
import {createSelector} from "reselect";
import {makeActiveOrganizationSelector} from "../../Organization/selectors/selectOrganization";
import {ApplicationState} from "../../reducer";
import * as UserModule from "../../User";
import {MODULE} from "../index";
import {Team, TeamId} from "../model/Team";

export const getTeamById = (state: Record<ApplicationState>, uid: TeamId): Team|undefined => state.getIn([MODULE, 'Team', uid]);

export const makeTeamSelector = (teamId: TeamId): (state: Record<ApplicationState>) => Team|undefined => (state: Record<ApplicationState>) => getTeamById(state, teamId);

export const teamListSelector = (state: Record<ApplicationState>, props: any): List<Team> =>
    List(state.getIn([MODULE, 'Team']).toIndexedSeq().toArray()) || List();

export const makeTeamListSelector = (withAdminAccess?: boolean): (state: Record<ApplicationState>, props?: any) => List<Team> => {
    const currentUserSelector = UserModule.UserSelector.makeGetCurrentUser();
    const activeOrganizationSelector = makeActiveOrganizationSelector();

    return createSelector(
        [teamListSelector, currentUserSelector, activeOrganizationSelector],
        (teams, user, activeOrganization): List<Team> =>  {
            teams = teams.filter(team => !team.deleted).filter(team => activeOrganization
                ? team.organizationId === activeOrganization.uid || (!activeOrganization.explicit && team.organizationId === null)
                : team.organizationId === null);
            if(withAdminAccess) {
                teams = teams.filter(team => team.isAdmin(user.uid) || (activeOrganization && activeOrganization.admins.includes(user.uid)));
            }

            return teams;
        }
    )
};
