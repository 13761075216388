import * as React from 'react';
import {useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {Checkbox} from "semantic-ui-react";
import {toggleEventModelingEnabled} from "../../actions/commands";
import {Board} from "../../model/Board";

interface OwnProps {
  board: Board;
}

type EventModelingEnabledProps = OwnProps & WithNamespaces;

const EventModelingEnabled = (props: EventModelingEnabledProps) => {
  const [eventModelingEnabled, setEventModelingEnabled] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setEventModelingEnabled(props.board.eventModelingEnabled);
  }, [props.board.uid]);

  const handleToggle = (enabled: boolean) => {
    setEventModelingEnabled(enabled);
    dispatch(toggleEventModelingEnabled(props.board.merge({eventModelingEnabled: enabled})))
  }

  return <Checkbox toggle={true} checked={eventModelingEnabled} value={eventModelingEnabled? '1' : ''} onChange={(e, data) => handleToggle(data.value !== '1')} />
};

export default withNamespaces()(EventModelingEnabled);
