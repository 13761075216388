import * as React from 'react';
import {useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import {withNamespaces, WithNamespaces} from "react-i18next";
import ReactCrop from "react-image-crop";
import {useDispatch} from "react-redux";
import {Button, Modal} from "semantic-ui-react";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Api} from "../actions";
import {BoardId} from "../model/Board";
import {isStandalone} from "../../standalone/util";
import {readFile, readFileArrayBuffer, readFileBase64} from "../../core/readFile";
import {Crop, getCroppedImageArrayBuffer, getCroppedImageBase64} from "../../core/imageCrop";

const INITIAL_CROP = { aspect: 0, unit: '%' as '%', x: 0, y: 0, width: 100, height: 100 };
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];
const CROP_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];
const MAX_UPLOAD_SIZE = 5 * 1024 * 1024; // 5MB

interface OwnProps {
    boardId: BoardId;
    openHandle: (handle: (open: boolean, imageId: string|null) => void) => void;
    onCancel: () => void;
    onFileUploaded: (imgName: string, imgUrl: string) => void;
}

export interface CropData {
    x: number;
    y: number;
    width: number;
    height: number;
}

type MxGraphImageUploadProps = OwnProps & WithNamespaces;

const MxGraphImageUpload = (props: MxGraphImageUploadProps) => {
    const [imageId, setImageId] = useState<string|null>(null);
    const [crop, setCrop] = useState<ReactCrop.PercentCrop>(INITIAL_CROP);
    const [file, setFile] = useState<undefined|{ file: File, src: string }>(undefined);
    const [image, setImage] = useState<undefined|HTMLImageElement>(undefined);
    const [open, setOpen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        focusPasteInput();

        // Make sure that the mxGraph cell does not steal the focus
        setTimeout(focusPasteInput, 1000);
    });

    props.openHandle((openFromHandle, currentImageId) => {
        setImageId(typeof currentImageId === 'string' ? currentImageId : null);
        setOpen(openFromHandle);
    });

    const onFileSelected = (acceptedFiles: File[]) => {
        if (acceptedFiles.length < 1) {
            dispatch(NotifyAction.Command.error(
                props.t('insp.board.upload.upload_failed_title'),
                props.t('insp.board.upload.upload_failed_text')
            ));
            return;
        }

        setFile({ file: acceptedFiles[0], src: URL.createObjectURL(acceptedFiles[0]) });
    };

    const cancel = () => {
        props.onCancel();
        resetModal();
    };

    const resetModal = () => {
        setFile(undefined);
        setImage(undefined);
        setCrop(INITIAL_CROP);
        setUploading(false);
    };

    const upload = async () => {
        if (!file) {
            return;
        }

        setUploading(true);
        const isCroppedImage = CROP_IMAGE_TYPES.includes(file.file.type);
        const isVectorGraphic = file.file.type === 'image/svg+xml';

        if (isStandalone()) {
            // mxGraph seems to add the `;base64` internally which is why they are removed here
            const imageDataURL = isCroppedImage
                ? getCroppedImageBase64(image!, crop as Crop, file.file.type).replace(';base64', '')
                : isVectorGraphic
                    ? 'data:image/svg+xml,' + await readFile(file.file)
                    : (await readFileBase64(file.file)).replace(';base64', '');

            props.onFileUploaded(file!.file.name, imageDataURL);
            resetModal();
            return;
        }

        const imageData = isCroppedImage
            ? await getCroppedImageArrayBuffer(image!, crop as Crop, file.file.type)
            : isVectorGraphic ? await readFile(file.file) : await readFileArrayBuffer(file.file);

        (imageId ? replaceImage : uploadNewImage)(imageData, file.file.type);
    };

    const uploadNewImage = (data: string|ArrayBuffer, mimeType: string) => {
        Api.uploadBoardImage(props.boardId, data, mimeType)
            .then((response) => {
                props.onFileUploaded(file!.file.name, `/buckets/boards/${props.boardId}/${response.data.imageId}`);
            })
            .catch(imageUploadErrorHandler)
            .finally(resetModal);
    };

    const replaceImage = (data: string|ArrayBuffer, mimeType: string) => {
        Api.replaceBoardImage(props.boardId, imageId!, data, mimeType)
            .then((response) => {
                props.onFileUploaded(file!.file.name, `/buckets/boards/${props.boardId}/${imageId}`);
            })
            .catch(imageUploadErrorHandler)
            .finally(resetModal);
    };

    const imageUploadErrorHandler = () => {
        dispatch(NotifyAction.Command.error(
            props.t('insp.board.upload.upload_failed_title'),
            props.t('insp.board.upload.upload_failed_text')
        ));
        cancel();
    };

    const handlePaste = (event: React.ClipboardEvent) => {
        event.preventDefault();
        const items: DataTransferItemList = event.clipboardData.items;
        let blob = null;

        /* tslint:disable-next-line:prefer-for-of */
        for (let i = 0; i < items.length; i++) {
            const item: DataTransferItem = items[i];

            if (item.type === 'image/jpeg' || item.type === 'image/png') {
                blob = item.getAsFile();
                break;
            }
        }

        if (null === blob) {
            dispatch(NotifyAction.Command.error(
                props.t('insp.board.upload.paste_failed'),
                props.t('insp.board.upload.invalid_file_pasted')
            ));
            return;
        }

        onFileSelected([blob]);
    };

    const focusPasteInput = () => {
        const element = document.getElementById('imageUploadPasteInput');
        if (element) {
            element.focus();
        }
    };

    return (
        <Modal open={open} onClose={cancel} closeIcon={true} onClick={focusPasteInput}>
            <Modal.Header>{props.t('insp.board.upload.select_image')}</Modal.Header>
            <Modal.Content image={true} onPaste={handlePaste}>
                <Dropzone onDrop={onFileSelected} disabled={!!file} accept={ACCEPTED_IMAGE_TYPES} maxFiles={1} maxSize={MAX_UPLOAD_SIZE} multiple={false}>
                    {({getRootProps, getInputProps}) => (
                        <section style={{ height: '410px', width: '100%' }}>
                            <div {...getRootProps()} style={{ width: '100%', height: '100%', padding: '1px' }}>
                                {(file && CROP_IMAGE_TYPES.includes(file.file.type)) && (
                                    <ReactCrop
                                        style={{ maxWidth: "100%", maxHeight: "400px" }}
                                        imageStyle={{ maxWidth: "100%", maxHeight: "400px" }}
                                        src={file.src as any}
                                        crop={crop}
                                        onChange={(newCrop: any, newPercentCrop: any) => setCrop(newPercentCrop)}
                                        onImageLoaded={setImage}
                                    />
                                )}
                                {(file && !CROP_IMAGE_TYPES.includes(file.file.type)) && (
                                    <img src={URL.createObjectURL(file.file)} />
                                )}
                                {!file && (
                                    <div>
                                        <input id={'imageUploadPasteInput'} type={'text'} style={{ position: 'fixed', left: '-10000px' }} />
                                        <input {...getInputProps()} />
                                        <p>{props.t('insp.board.upload.drag_and_drop_info')}</p>
                                    </div>
                                )}
                            </div>
                        </section>
                    )}
                </Dropzone>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    children={props.t('app.form.cancel')}
                    onClick={cancel}
                />
                <Button
                    primary={true}
                    children={props.t('app.form.save')}
                    onClick={upload}
                    loading={uploading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default withNamespaces()(MxGraphImageUpload);
