import { call, fork, put, select, take } from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import { Action as NotifyAction } from '../../NotificationSystem';
import * as Action from "../actions";

type FlowAction = ActionType<typeof Action.Command.shareBoard>;

function* onShareBoard(action: FlowAction) {
    const {response, error}: ResponseType = yield call(
        Action.Api.shareBoard,
        action.payload.boardId,
        action.payload.email,
        action.payload.permissions
    );

    if (error && error.status === 404) {
        yield put(NotifyAction.Command.error("User not found", "Please check the entered username. Also the user needs to sign in at least once before you can share your board with them."))
    }

    if(error) {
        yield put(NotifyAction.Command.error("Action Failed", error.data.error.message))
    }

    if(response) {
        yield put(NotifyAction.Command.info("Board shared with " + action.payload.email, ''));
        yield put(Action.Query.fetchOpenBoardInvitations(action.payload.boardId));
        yield put(Action.Query.fetchBoardPermissionsForUsers(action.payload.boardId));
    }
}

export function* shareBoardFlow() {
    while (true) {
        const action: FlowAction = yield take([
            Action.Type.SHARE_BOARD,
        ]);

        yield fork(onShareBoard, action);
    }
}
