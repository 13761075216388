import {call, fork, put, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Api} from "../actions";
import {ADMIN_GRANT_TEAM_ACCESS_TO_BOARD, ADMIN_REVOKE_TEAM_ACCESS_TO_BOARD} from "../actions/constants";
import {Action} from "../index";

type FlowAction = ActionType<typeof Action.Command.adminGrantTeamAccessToBoard> & ActionType<typeof Action.Command.adminRevokeTeamAccessToBoard>;

function* onAdminGrantTeamAccessToBoard(action: ActionType<typeof Action.Command.adminGrantTeamAccessToBoard>) {
    let success = false;

    for (const teamId of action.payload.teamIds) {
        const {response, error}: ResponseType = yield call(
            Api.adminGrantTeamAccessToBoard,
            action.payload.boardId,
            teamId,
            action.payload.permissions
        );

        if(error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not add team to board.'));
        }

        if(response) {
            success = true;
            yield put(Action.Event.teamAccessToBoardGranted(action.payload.boardId, teamId));

        }
    }


    if(success) {
        const plural = action.payload.teamIds.length > 1 ? 's' : '';
        yield put(NotifyAction.Command.info('Team Added', `Team${plural} successfully added to board`));
    }

}

function* onAdminRevokeTeamAccessToBoard(action: ActionType<typeof Action.Command.adminRevokeTeamAccessToBoard>) {
    const {response, error}: ResponseType = yield call(Api.adminRevokeTeamAccessToBoard, action.payload.boardId, action.payload.teamId);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not revoke team access.'));
    }

    if(response) {
        yield put(Action.Event.teamAccessToBoardRevoked(action.payload.boardId, action.payload.teamId));
        yield put(NotifyAction.Command.info('Team Access Revoked', 'The team has no longer access to the board.'))
    }
}

export function* adminGrantTeamAccessToBoard() {
    while (true) {
        const action: FlowAction = yield take([ADMIN_GRANT_TEAM_ACCESS_TO_BOARD, ADMIN_REVOKE_TEAM_ACCESS_TO_BOARD]);

        switch (action.type) {
            case ADMIN_GRANT_TEAM_ACCESS_TO_BOARD:
                yield fork(onAdminGrantTeamAccessToBoard, action);
                break;
            case ADMIN_REVOKE_TEAM_ACCESS_TO_BOARD:
                yield fork(onAdminRevokeTeamAccessToBoard, action);
                break;
        }
    }
}
