import {Node} from "../../../model/Graph";
import {parseNodeMetadata} from "../node/metadata";
import {Rule} from "../rule-engine/configuration";
import {RulesDependenciesMetadata} from "../rule-engine/types";

export interface PolicyMetadata {
  rules?: Rule[];
  dependencies?: RulesDependenciesMetadata;
}

export const getPolicyMetadata = (policy: Node): PolicyMetadata => {
  const metadata = parseNodeMetadata(policy);

  return metadata;
}
