import {Map} from 'immutable';
import {Reducer} from 'redux';
import {ActionType} from "typesafe-actions";
import {updateAggregate, upsertAggregates} from "../../core/reducer/applyAggregateChanged";
import {
    EXPLICIT_ORGANIZATION_SET_UP,
    EXPLICIT_ORGANIZATION_SET_UP_FAILED,
    ORGANIZATION_RENAMED,
    USER_ORGANIZATIONS_FETCHED
} from "../actions/constants";
import * as Events from "../actions/events";
import {UserOrganizationInfo} from "../model/UserOrganizationInfo";

type Event = ActionType<typeof Events>;

export interface UserOrganizationInfoState extends Map<string, UserOrganizationInfo> {
}

export const initialState: UserOrganizationInfoState = Map<string, UserOrganizationInfo>();

const reducer: Reducer<UserOrganizationInfoState, Event> = (state: UserOrganizationInfoState = initialState, event: Event): UserOrganizationInfoState => {
    switch (event.type) {
        case USER_ORGANIZATIONS_FETCHED:
            return upsertAggregates(state, event.payload);
        case EXPLICIT_ORGANIZATION_SET_UP:
            const implOrga = state.get(event.payload.uid);
            if(implOrga) {
                return updateAggregate(state, implOrga.set('organizationName', event.payload.name).set('explicitOrganization', true))
            } else {
                return state;
            }
        case EXPLICIT_ORGANIZATION_SET_UP_FAILED:
            const resetOrga = state.get(event.payload.uid);
            if(resetOrga) {
                return updateAggregate(state, resetOrga.set('organizationName', event.payload.name).set('explicitOrganization', false))
            } else {
                return state;
            }
        case ORGANIZATION_RENAMED:
            const renamedOrga = state.get(event.payload.uid);
            if(renamedOrga) {
                return updateAggregate(state, renamedOrga.set('organizationName', event.payload.name));
            } else {
                return state;
            }
        default:
            return state;
    }
};

export {reducer as saveUserOrganizationInfoReducer};
