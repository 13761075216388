import { action } from 'typesafe-actions';
import {OrganizationId} from "../../Organization/model/Organization";
import {UserModel} from '../model';
import {UserSettingsValue} from "../model/UserSettings";
import {SIGN_IN, UPDATE_PASSWORD, UPDATE_PROFILE, UPSERT_USER_SETTING} from "./constants";
import {SWITCH_ACTIVE_ORGANIZATION} from "./constants";

export const signIn = (user: UserModel.UserInfo) => action(SIGN_IN, {user});

export const updateProfile = (user: UserModel.UserInfo, successCb: () => void) => action(UPDATE_PROFILE, {user, successCb});

export const updatePassword = (password: string) => action(UPDATE_PASSWORD, {password});

export const upsertUserSetting = (key: string, value: UserSettingsValue) => action(UPSERT_USER_SETTING, {key, value});

export const switchActiveOrganization = (organizationId: OrganizationId | null) => action(SWITCH_ACTIVE_ORGANIZATION, {organizationId});
