import {ServiceName} from "../../../model/Board";
import {Node, NodeType} from "../../../model/Graph";
import {isAggregateEvent} from "../event/is-aggregate-event";
import {getVoMetadata} from "../vo/get-vo-metadata";
import {detectService} from "./detect-service";
import {names} from "./names";

export const fqcn = (node: Node, defaultServiceName: ServiceName, forQuery?: boolean) => {
  const service = detectService(node, defaultServiceName);

  if(node.getType() === NodeType.document) {
    if(forQuery) {
      return `${service}.Get${names(node.getName()).className}`;
    }

    const voMeta = getVoMetadata(node);

    if(voMeta.ns) {
      const ns = voMeta.ns.charAt(0) === "/" ? voMeta.ns.slice(1) : voMeta.ns;
      return `${service}.${ns.split("/").map(n => names(n).className).join(".")}.${names(node.getName()).className}`;
    }
  }

  if(node.getType() === NodeType.event) {
    if(isAggregateEvent(node)) {
      const aggregate = node.getSources().first(undefined);

      if(aggregate && aggregate.getType() === NodeType.aggregate) {
        return `${service}.${names(aggregate.getName()).className}.${names(node.getName()).className}`;
      }
    }
  }

  return `${service}.${names(node.getName()).className}`;
}
