import {Map} from 'immutable';
import {Reducer} from 'redux';
import {updateAggregate, upsertAggregates} from "../../core/reducer/applyAggregateChanged";
import {BOARD_PERMISSIONS_FOR_TEAMS_FETCHED, TEAM_BOARD_PERMISSIONS_CHANGED} from '../actions/constants';
import {BoardPermissionForTeam} from "../model";
import {InspectioBoardsAction} from './index';

export interface BoardPermissionsForTeamsState extends Map<string, BoardPermissionForTeam.BoardPermissionForTeam> {
}

export const initialState: BoardPermissionsForTeamsState = Map<string, BoardPermissionForTeam.BoardPermissionForTeam>();

const reducer: Reducer<BoardPermissionsForTeamsState, InspectioBoardsAction> = (state: BoardPermissionsForTeamsState = initialState, action: InspectioBoardsAction): BoardPermissionsForTeamsState => {
    switch (action.type) {
        case BOARD_PERMISSIONS_FOR_TEAMS_FETCHED:
            return upsertAggregates(state, action.payload.boardPermissions);
        case TEAM_BOARD_PERMISSIONS_CHANGED:
            return updateAggregate(state, action.payload);
        default:
            return state;
    }
};

export { reducer as saveBoardPermissionsForTeamsReducer };
