import React, {useState} from 'react'
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Button, Dropdown, DropdownProps, Label, Table} from "semantic-ui-react";
import {Model as TeamModel} from "../../../InspectioTeams";
import {makeTeamListSelector} from "../../../InspectioTeams/selectors/selectTeam";
import {UserInfo} from "../../../User/model/UserInfo";
import {BoardId} from "../../model/Board";
import {Permissions} from "../../model/BoardPermissionForUser";
import {makeBoardPermissionForTeamListSelector} from "../../selectors/boardPermission";

export interface ShareBoardWithTeamFormData {
    boardId: BoardId,
    teamIds: TeamModel.Team.TeamId[],
    permissions: Permissions,
}

export interface OwnProps {
    boardId: BoardId,
    user: UserInfo,
    permissions: Permissions,
    handleSubmit: (data: ShareBoardWithTeamFormData) => void,
}

export type ShareBoardWithTeamFormProps = OwnProps & WithNamespaces;

const PAGE_TEAM_NAME_INPUT = 1;
const PAGE_PERMISSIONS = 2;

const ShareBoardWithTeamForm = (props: ShareBoardWithTeamFormProps) => {

    const teams = useSelector(makeTeamListSelector());
    const [page, setPage] = useState<number>(PAGE_TEAM_NAME_INPUT);
    const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
    const [writeAccess, setWriteAccess] = useState<boolean>(props.permissions.writeAccess);

    const boardPermissions = useSelector(makeBoardPermissionForTeamListSelector(props.boardId));
    const teamBlacklist = boardPermissions.map(boardPermission => boardPermission.teamId);

    const filteredTeams = teams.filter(team => !teamBlacklist.contains(team.uid));

    const ownTeamsOptions = teams.map(team => ({
        key: team.uid,
        value: team.uid,
        text: team.name
    })).toArray();

    const reset = () => {
        setWriteAccess(props.permissions.writeAccess);
        setSelectedTeams([]);
    };

    const handleChange = (e: React.SyntheticEvent, dProps: DropdownProps) => setSelectedTeams(dProps.value as string[]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        props.handleSubmit({
            boardId: props.boardId,
            teamIds: selectedTeams,
            permissions: { ...props.permissions, writeAccess }
        });
        reset();
    };

    return (
        <form className="ui form" onSubmit={handleSubmit}>
            <div className="fields" style={{margin: "0"}}>
                <Table unstackable={true} basic="very">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{width: "310px", verticalAlign: "top"}}>
                                <div className="inline field" style={{width: '100%'}}>
                                    <Dropdown
                                        placeholder={props.t('insp.board.form.team_name') as string}
                                        search={true}
                                        selection={true}
                                        multiple={true}
                                        options={ownTeamsOptions}
                                        value={selectedTeams}
                                        onChange={handleChange}
                                        style={{width: '100%'}}
                                    />
                                </div>
                            </Table.Cell>
                            <Table.Cell style={{verticalAlign: "top"}}>
                                <Button.Group>
                                    <Button
                                        type={'button'}
                                        className={'positive'}
                                        children={'Read'}
                                        onClick={() => setWriteAccess(false)}
                                    />
                                    <Button.Or text="+" />
                                    <Button
                                        type={'button'}
                                        className={writeAccess ? 'positive' : ''}
                                        children={'Write'}
                                        onClick={() => setWriteAccess(!writeAccess)}
                                    />
                                </Button.Group>
                            </Table.Cell>
                            <Table.Cell style={{verticalAlign: "top"}}>
                                <Button primary={true} type="submit" disabled={selectedTeams.length === 0}>
                                    {props.t('insp.board.form.btn_share')}
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        </form>
    );
};

export default withNamespaces()(ShareBoardWithTeamForm);
