import {List} from "immutable";
import {call, fork, put, take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {execForEach, execValidate, isFailure, isSuccess, pipe} from "../../core/validation/either";
import {isArray} from "../../core/validation/predicates";
import * as Action from "../../InspectioBoards/actions";
import {createPlayshotSummaryFromServerData, PlayshotSummary} from "../../InspectioBoards/model/PlayshotSummary";
import {loadLocalPlayshots} from "../../InspectioBoards/service/playshot-storage";
import {Action as NotifyAction} from "../../NotificationSystem";

type FlowAction = ActionType<typeof Action.Query.fetchPlayshotSummaryList>;

const onFetchPlayshotSummaryList = function* (action: FlowAction) {
  const shots = loadLocalPlayshots(action.payload.boardId);

    const result = pipe(
      shots as unknown as PlayshotSummary[],
      execValidate([isArray(), "Local storage playshot item is not of type Array"]),
      execForEach((data: any) => {
        console.log("shot data", data);
        return createPlayshotSummaryFromServerData(data);
      }),
    );

    if (isFailure(result)) {
      // tslint:disable-next-line:no-console
      console.error("Failed to process playshot summaries from local storage", result.value, shots);
      yield put(NotifyAction.Command.error('Request Error', 'Could not load playshots'));
    }

    if (isSuccess(result)) {
      const summaries = List(result.value);
      yield put(Action.Event.playshotSummaryListFetched(summaries));
    }
}

export function* standaloneFetchPlayshotSummaryListFlow() {

  while (true) {
    const action: FlowAction = yield take([Action.Type.FETCH_PLAYSHOT_SUMMARY_LIST]);

    yield fork(onFetchPlayshotSummaryList, action);
  }
}
