import {Node, NodeType} from "../../../model/Graph";

export const findParentByType = (node: Node | null, type: NodeType): Node | null => {
  if(node && node.getType() === type) {
    return node;
  }

  if(!node) {
    return null;
  }

  return findParentByType(node.getParent(), type);
}
