import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {BoardPermissionForTeam} from "../model/BoardPermissionForTeam";
import {makeBoardPermissionForTeamSelector} from "../selectors/boardPermission";

type Command = ActionType<typeof Action.Command.adminChangeTeamBoardPermissions>

function* handleAdminChangeTeamBoardPermissions(command: Command) {
    let boardPermission: BoardPermissionForTeam = yield select(makeBoardPermissionForTeamSelector(command.payload.boardId, command.payload.teamId));

    if(boardPermission) {
        boardPermission = boardPermission.changePermissions(command.payload.permissions);

        const {response, error} = yield call(Action.Api.adminChangeTeamBoardPermissions, command.payload.boardId, command.payload.teamId, command.payload.permissions);

        if(error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not change team permissions.'));
        }

        if(response) {
            yield put(Action.Event.teamBoardPermissionsChanged(boardPermission));
            yield put(NotifyAction.Command.info('Permissions Changed', 'Team permissions changed successfully.'));
        }
    }
}

export function* adminChangeTeamBoardPermissions() {
    while (true) {
        const command: Command = yield take([
            Action.Type.ADMIN_CHANGE_TEAM_BOARD_PERMISSIONS
        ]);

        yield fork(handleAdminChangeTeamBoardPermissions, command);
    }
}