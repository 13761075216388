import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../index";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";

type Command = ActionType<typeof Action.Command.toggleCodySuggestEnabled>

function* handleToggleCodySuggestEnabled(command: Command) {
    const {response, error}: ResponseType = yield call(Action.Api.toggleCodySuggest, command.payload.board.uid, command.payload.board.codySuggestEnabled);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not toggle cody suggest.'));
        yield put(Action.Query.fetchUserBoards());
    }

    if(response) {
        yield put(NotifyAction.Command.info('Cody Suggestions ' + (command.payload.board.codySuggestEnabled ? 'enabled' : 'disabled'), ''));
    }
}

export function* toggleCodySuggestEnabled() {
  while (true) {
    const command: Command = yield take([
      Action.Type.TOGGLE_CODY_SUGGEST_ENABLED
    ]);

    yield fork(handleToggleCodySuggestEnabled, command);
  }
}
