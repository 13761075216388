import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Message} from "semantic-ui-react";
import {Node} from "../../../model/Graph";

interface OwnProps {
  node: Node
}

type UnsupportedNodeProps = OwnProps & WithNamespaces;

const UnsupportedNode = (props: UnsupportedNodeProps) => {
  return <Message error={true}>
    {props.t('insp.cody_wizard.unsupported_node', {name: props.node.getName(), type: props.node.getType()})}
  </Message>
};

export default withNamespaces()(UnsupportedNode);
