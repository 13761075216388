import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown, DropdownItemProps} from "semantic-ui-react";
import {TeamId} from "../../../InspectioTeams/model/Team";
import {makeTeamListSelector} from "../../../InspectioTeams/selectors/selectTeam";
import * as Action from "../../actions";
import {makeGetBoardOverview} from "../../selectors/boardList";

interface OwnProps {

}

type ResponsibleTeamFilterProps = OwnProps & WithNamespaces;

const TeamFilter = (props: ResponsibleTeamFilterProps) => {
    const boardOverviewState = useSelector(makeGetBoardOverview());
    const teams = useSelector(makeTeamListSelector());
    const dispatch = useDispatch();

    const options: DropdownItemProps[] = teams.map(team => ({key: team.uid, text: team.name, value: team.uid })).toJS();
    options.unshift({key: '_all_', text: props.t('insp.overview.teamFilter.all'), value: '_all_'});

    const handleFilterChange = (teamId: TeamId | undefined) => {
        if(teamId === '_all_') {
            teamId = undefined;
        }
        dispatch(Action.Command.filterBoardOverviewByResponsibleTeam(teamId));
    }

    return <Dropdown
        selection={true}
        placeholder={props.t('insp.board.form.responsible_team') as string}
        options={options}
        fluid={true}
        value={boardOverviewState.responsibleTeam || '_all_'}
        onChange={(e,data) => handleFilterChange(data.value as string | undefined)}
        />
};

export default withNamespaces()(TeamFilter);
