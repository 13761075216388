
// Taken from: https://github.com/dariusk/pos-js
import {
  BrillPOSTaggedWord,
  BrillPOSTagger,
  Lexicon,
  NounInflector,
  PorterStemmer,
  PresentVerbInflector,
  RuleSet,
  Stemmer
} from "natural";

const COORD_CONJUNCN = 'CC';        // and,but,or
const CARDINAL_NUMBER = 'CD';       // one,two
const DETERMINER = 'DT';            // the,some
const EXISTENTIAL_THERE = 'EX';     // there
const FOREIGN_WORD = 'FW';          // mon,dieu
const PREPOSTION = 'IN';            // of,in,by
const ADJECTIVE = 'JJ';             // big
const ADJECTIVE_COMARATIVE = 'JJR'; // bigger
const ADJECTIVE_SUPERLATIVE = 'JJS' // biggest
const LIST_ITEM_MARKER = 'LS';      // 1,One
const MODAL = 'MD';                 // can,should
const NOUN_SINGULAR = 'NN';         // dog
const NOUN_PLURAL = 'NNS';          // dogs
const PROPER_NOUN_SINGULAR = 'NNP'; // Edingburgh
const PROPER_NOUN_PLURAL = 'NNPS';  // Smiths
const POSSESSIVE_ENDING = 'POS';    // 's
const PREDETERMINER = 'PDT';        // all,both
const POSSESSIVE_PRONOUN = 'PRP$';  // my,one's
const PERSONAL_PRONOUN = 'PRP';     // I,you,she
const ADVERB = 'RB';                // quickly
const ADVERB_COMPARSTIVE = 'RBR';   // faster
const ADVERB_SUPERLATIVE = 'RBS';   // fastest
const PARTICLE = 'RP';              // up,off
const SYMBOL = 'SYM';               // +,%,&
const TO = 'TO';                    // to
const INTERJECTION = 'UH';          // oh, oops
const VERB_BASE_FORM = 'VB';        // eat
const VERB_PAST_TENSE = 'VBD';      // ate
const VERB_GERUND = 'VBG';          // eating
const VERB_PAST_PART = 'VBN';       // eaten
const VERB_PRESENT = 'VBP';         // eat
const VERB_PRESENT_SINGULAR = 'VBZ';// eats
const WH_DETERMINER = 'WDT';        // which,that
const WH_PRONOUN = 'WP';            // who,what
const WH_POSSESSIVE = 'WP$';        // whose
const WH_ADVERB = 'WRB';            // whose
const COMMA = ',';                  // ,
const SENT_FINAL_PUNCT = '.';       // . ! ?
const MID_SENT_PUNCT = ':';         // : ; Ñ
const DOLLAR_SIGN = '$';            // $
const POUND_SIGN = '#';             // #
const QUOTE = '"';                  // "
const LEFT_PAREN = '(';             // (
const RIGHT_PAREN = ')';            // )

const VERB_TAGS = [VERB_BASE_FORM, VERB_PRESENT, VERB_PAST_TENSE, VERB_GERUND, VERB_PAST_PART, VERB_PRESENT_SINGULAR];
const NOUN_TAGS = [NOUN_SINGULAR, NOUN_PLURAL, PROPER_NOUN_SINGULAR, PROPER_NOUN_PLURAL];
const ADJECTIVE_TAGS = [ADJECTIVE, ADJECTIVE_COMARATIVE, ADJECTIVE_SUPERLATIVE];
const ADVERB_TAGS = [ADVERB, ADVERB_COMPARSTIVE, ADVERB_SUPERLATIVE];

let tagger: BrillPOSTagger;
let nounInflector: NounInflector;
let presentVerbInflector: PresentVerbInflector;
// tslint:disable-next-line:no-var-requires
const verbutils = require('verbutils')();

export const getConfiguredTagger = (): BrillPOSTagger => {
  if(!tagger) {
    const lexicon = new Lexicon("EN", "N", "NNP");

    const ruleSet = new RuleSet("EN");

    tagger = new BrillPOSTagger(lexicon, ruleSet);
  }

  return tagger;
}

export const getConfiguredNounInflector = (): NounInflector => {
  if(!nounInflector) {
    nounInflector = new NounInflector();
  }

  return nounInflector;
}

export const getConfiguredPresentVerbInflector = (): PresentVerbInflector => {
  if(!presentVerbInflector) {
    presentVerbInflector = new PresentVerbInflector();
  }

  return presentVerbInflector;
}

let stemmer: Stemmer;

export const getConfiguredStemmer = (): Stemmer => {
  if(!stemmer) {
    stemmer = PorterStemmer;
  }

  return stemmer;
}

export const tagSentence = (sentence: string): BrillPOSTaggedWord[] => {
  return getConfiguredTagger().tag(sentence.split(" ")).taggedWords;
}

export const stemWord = (word: BrillPOSTaggedWord): string => {
  return PorterStemmer.stem(word.token);
}

export const isVerb = (word: BrillPOSTaggedWord): boolean => {
  return VERB_TAGS.includes(word.tag);
}

export const isNoun = (word: BrillPOSTaggedWord): boolean => {
  return NOUN_TAGS.includes(word.tag);
}

export const isAdjective = (word: BrillPOSTaggedWord): boolean => {
  return ADJECTIVE_TAGS.includes(word.tag);
}

export const isAdverb = (word: BrillPOSTaggedWord): boolean => {
  return ADVERB_TAGS.includes(word.tag);
}

export const ensureSingularNoun = (word: BrillPOSTaggedWord): string => {
  if(!isNoun(word)) {
    return '';
  }

  return getConfiguredNounInflector().singularize(word.token);
}

export const ensurePluralNoun = (word: BrillPOSTaggedWord): string => {
  if(!isNoun(word)) {
    return '';
  }

  return getConfiguredNounInflector().pluralize(word.token);
}



export const ensurePresentTense = (verb: BrillPOSTaggedWord): string => {
  if(!isVerb(verb)) {
    return '';
  }

  const baseForm = verbutils.toBaseForm(verb.token);

  // For verbs ending on "e" (like: change -> changed) base form conversion does not work, but we can test if base form is a known verb
  // and if not double-check that gerund form works (changing), to be more safe that we can add an "e" to the end
  if(!isPresentTenseVerb(baseForm) && testStemToGerund(baseForm)) {
    return baseForm + "e";
  }

  return baseForm;
}

const testStemToGerund = (verbStem: string): boolean => {
  const testSentence = `She is ${verbStem.toLowerCase()}ing a thing.`;

  console.log("[CodyWizard] tagged test sentence (stemmed): ", tagSentence(testSentence));

  return tagSentence(testSentence)[2].tag === VERB_GERUND;
}

const isPresentTenseVerb = (verb: string): boolean => {
  // Create a test sentence to see if verb is tagged correctly
  const singularizedVerb = getConfiguredPresentVerbInflector().singularize(verb);

  const testSentence = `She ${singularizedVerb.toLowerCase()} a thing.`;

  const sentence = tagSentence(testSentence);

  console.log("[CodyWizard] tagged test sentence: ", sentence);

  // Verbs like "tests" are incorrectly flagged as plural nouns :(, so we perform a second test against the stem
  return isVerb(sentence[1]) || (sentence[1] && sentence[1].tag === NOUN_PLURAL && testStemToGerund(verb));
}

const pastTenseExceptions: Record<string, string> = {
  "are": "were",
  "eat": "ate",
  "go": "went",
  "have": "had",
  "inherit": "inherited",
  "is": "was",
  "run": "ran",
  "sit": "sat",
  "visit": "visited"
};

/* Taken from https://gist.github.com/letsgetrandy/1e05a68ea74ba6736eb5 */
export const toPastTense = (verb: BrillPOSTaggedWord): string => {
  if(!isVerb(verb)) {
    return '';
  }

  const verbStr = verb.token;


  if (pastTenseExceptions[verbStr]) {
    return pastTenseExceptions[verbStr];
  }
  if ((/e$/i).test(verbStr)) {
    return verbStr + 'd';
  }
  if ((/[aeiou]c/i).test(verbStr)) {
    return verbStr + 'ked';
  }
  // for american english only
  if ((/el$/i).test(verbStr)) {
    return verbStr + 'ed';
  }
  if ((/[aeio][aeiou][dlmnprst]$/).test(verbStr)) {
    return verbStr + 'ed';
  }
  if ((/[aeiou][bdglmprst]$/i).test(verbStr)) {
    return verbStr.replace(/(.+[aeiou])([bdglmnprst])/, '$1$2$2ed');
  }
  return verbStr + 'ed';
}
