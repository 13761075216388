import * as React from 'react';
import {Icon} from "semantic-ui-react";

export const LoadingBoardCard = () => {
    return <div className="ui card event">
        <div className="content" >
            <div className="header" />
            <div className="description"><Icon loading={true} name="spinner" size="big" /></div>
        </div>
    </div>
};