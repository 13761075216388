import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Dropdown, DropdownItemProps, FormField, Icon} from "semantic-ui-react";
import {useElementsTree} from "../../../../hooks/useElementsTree";
import {BoardId} from "../../../../model/Board";
import {ElementsTree} from "../../../../model/ElementsTree";
import {Graph, Node, NodeType} from "../../../../model/Graph";
import {WizardContext} from "../../../../service/cody-wizard/context/wizard-context";
import CellIcon from "../../../CellIcon";

export type AccessibleFrom = Node | 'sidebar' | undefined;

interface OwnProps {
  boardId: BoardId;
  graph: Graph;
  ui: Node | null;
  accessibleFrom: AccessibleFrom;
  ctx: WizardContext;
  onAccessibleFromChanged: (accessibleFrom: AccessibleFrom) => void;
  disabled?: boolean;
}

type UiAccessibleFromSelectProps = OwnProps & WithNamespaces;

const UiAccessibleFromSelect = (props: UiAccessibleFromSelectProps) => {

  const elementsTree = useElementsTree(props.boardId);

  const handleAccessibleFromChanged = (accessibleFromOptionValue: string | undefined) => {
    if(!accessibleFromOptionValue) {
      props.onAccessibleFromChanged(undefined);
      return;
    }

    if(accessibleFromOptionValue === '$sidebar') {
      props.onAccessibleFromChanged('sidebar');
      return;
    }

    const selectedUI = props.graph.getNode(accessibleFromOptionValue);

    if(selectedUI) {
      props.onAccessibleFromChanged(selectedUI);
    }
  }

  return <FormField disabled={!props.ui || props.disabled} inline={true}>
    <label>{props.t('insp.cody_wizard.step_ui.accessible_from_select_label') as string}</label>
    <Dropdown inline={true}
              disabled={!props.ui || props.disabled}
              value={getOptionValue(props.accessibleFrom)}
              onChange={(e, data) => handleAccessibleFromChanged(data.value as string | undefined)}
              placeholder={props.t('insp.cody_wizard.step_command.new_aggregate_select_placeholder') as string}
              options={prepareOptions(props.ui, props.accessibleFrom, elementsTree)} />
  </FormField>
};

export default withNamespaces()(UiAccessibleFromSelect);

const getOptionValue = (accessibleFrom: AccessibleFrom): string | undefined => {
  if(!accessibleFrom) {
    return undefined;
  }

  if(typeof accessibleFrom === "string") {
    return '$sidebar';
  }

  return accessibleFrom.getId();
}

const prepareOptions = (ui: Node | null, accessibleFrom: AccessibleFrom, elementsTree: ElementsTree): DropdownItemProps[] => {
  const options: DropdownItemProps[] = [];

  options.push({
    key: '$sidebar',
    text: 'App Sidebar',
    value: '$sidebar',
    icon: <Icon name="sidebar" />
  });

  const isNode = accessibleFrom && typeof accessibleFrom !== "string";
  const nodeName = accessibleFrom && typeof accessibleFrom !== "string" ? accessibleFrom.getName() : '';

  elementsTree.getFilteredElementsByType(NodeType.ui, true).forEach(ele => {
    if(isNode && nodeName === ele.getName()) {
      return;
    }

    if(ui && ui.getName() === ele.getName()) {
      return;
    }

    options.push({
      key: ele.getId(),
      text: ele.getName(),
      value: ele.getId(),
      icon: <CellIcon cellType={NodeType.ui} />
    })
  });

  if(accessibleFrom && typeof accessibleFrom !== "string") {
    options.push({
      key: accessibleFrom.getId(),
      text: accessibleFrom.getName(),
      value: accessibleFrom.getId(),
      icon: <CellIcon cellType={NodeType.ui} />
    })
  }

  return options;
}
