import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import AddBoard from "../../../../Layout/Sidebar/AddBoard";
import BoardList from "../../../../Layout/Sidebar/BoardList";
import BoardOverview from "../../../../Layout/Sidebar/BoardOverview";
import Feedback from "../../../../Layout/Sidebar/Feedback";
import ImportBoard from "../../../../Layout/Sidebar/ImportBoard";
import Logo from "../../../../Layout/Sidebar/Logo";
import Search from "../../../../Layout/Sidebar/Search";
import Updates from "../../../../Layout/Sidebar/Updates";
import Upgrade from "../../../../Layout/Sidebar/Upgrade";
import {makeGetCurrentBoardQuota} from "../../../../User/selectors/currentUser";

interface OwnProps {

}

type OverviewSidebarProps = OwnProps & WithNamespaces;

const OverviewSidebar = (props: OverviewSidebarProps) => {
    return <>
        <Logo collapsed={false} />
        <Upgrade />
        <Search />
        <ImportBoard />
        <AddBoard />
        <BoardOverview />
        <BoardList />
        <Updates />
        <Feedback />
    </>;
};

export default withNamespaces()(OverviewSidebar);
