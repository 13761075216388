import { call, fork, put, select, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ResponseType } from '../../api/util';
import { Action as NotifyAction } from '../../NotificationSystem/index';
import {memberRemovedFromTeam} from "../actions/events";
import * as Action from '../actions/index';
import {Team} from "../model";
import {makeTeamSelector} from "../selectors/selectTeam";

type Command = ActionType<typeof Action.Command.removeMemberFromTeam>;

function* handleRemoveMemberFromTeam(command: Command) {
    const team: Team.Team|undefined = yield select(makeTeamSelector(command.payload.teamId));

    if(!team) {
        yield put(NotifyAction.Command.error('Internal Error', 'Team could not be loaded from local store. Please contact support.'));
        return;
    }

    const {response, error}: ResponseType = yield call(Action.Api.removeMemberFromTeam, command.payload.teamId, command.payload.memberId);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not remove member from team.'));
    }
    if (response) {
        yield put(memberRemovedFromTeam(team.removeMember(command.payload.memberId)));
        yield put(NotifyAction.Command.info('Member removed', 'The member was removed from the team.'));
    }
}

export function* removeMemberFromTeam() {

    while (true) {
        const command: Command = yield take([
            Action.Type.REMOVE_MEMBER_FROM_TEAM
        ]);

        yield fork(handleRemoveMemberFromTeam, command);
    }
}
