import * as React from 'react';
import {useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Button, ButtonGroup, Modal} from "semantic-ui-react";
import {makeGetSidebarSelector} from "../../../Layout/selectors/sidebar";
import {useGraph} from "../../hooks/useGraph";
import {Board, BoardId, defaultBoardProps} from "../../model/Board";
import {Node, NodeType} from "../../model/Graph";
import {makeBoardByIdSelector} from "../../selectors/board";
import {dddActionFromNode, isDDDAction} from "../../service/cody-wizard/context/ddd-action";
import {featureContextFromNode, isFeatureContext} from "../../service/cody-wizard/context/feature-context";
import {informationContextFromNode, isInformationContext} from "../../service/cody-wizard/context/information-context";
import {
  isPartOfPolicyContext,
  isPolicyContext,
  policyContextFromNode
} from "../../service/cody-wizard/context/policy-context";
import {isUiContext, uiContextFromNode} from "../../service/cody-wizard/context/ui";
import {makeCodySession, WizardContext, WizardContextType} from "../../service/cody-wizard/context/wizard-context";
import {isAggregateEvent, isDddActionEvent} from "../../service/cody-wizard/event/is-aggregate-event";
import {isDddActionOrPolicyFeature} from "../../service/cody-wizard/feature/is-ddd-action-or-policy-feature";
import {isValid} from "../../service/cody-wizard/node/is-valid";
import {stepHeader} from "../../service/cody-wizard/step/step-header";
import {stepWikiLink} from "../../service/cody-wizard/step/step-wiki-link";
import {isDddActionUi} from "../../service/cody-wizard/ui/is-ddd-action-ui";
import {isStateVo} from "../../service/cody-wizard/vo/is-state-vo";
import {isStateVoCandidate} from "../../service/cody-wizard/vo/is-state-vo-candidate";
import RunCody from "./CodyMonitor/RunCody";
import RunCodyOnSelect from "./CodyMonitor/RunCodyOnSelect";
import ActiveStepIcon from "./Step/ActiveStepIcon";
import Aggregate from "./Step/Aggregate";
import AnyVo from "./Step/AnyVo";
import DddCommand from "./Step/DddCommand";
import DddEvent from "./Step/DddEvent";
import Feature from "./Step/Feature";
import NextStep from "./Step/NextStep";
import Policy from "./Step/Policy";
import PolicyEvent from "./Step/PolicyEvent";
import PreviousStep from "./Step/PreviousStep";
import SelectContext from "./Step/SelectContext";
import StateVO from "./Step/StateVO";
import Ui from "./Step/Ui";
import UnsupportedNode from "./Unsupported/UnsupportedNode";

export const DEFAULT_CONNECT_MARGIN = 80;

export const DEFAULT_CARD_HEIGHT = 100;
export const SLICE_LANE_HEIGHT = 300;
export const SLICE_LANE_MARGIN = SLICE_LANE_HEIGHT - DEFAULT_CARD_HEIGHT;
export const DOUBLE_CONNECT_MARGIN = DEFAULT_CONNECT_MARGIN * 2;
export const API_TO_MODULE_MARGIN = SLICE_LANE_HEIGHT - DEFAULT_CARD_HEIGHT;
export const INPUT_CHAR_SIZE = 9;
export const CHAR_SIZE_UNIT = 'px';
export const ADDITIONAL_CHAR_PADDING = 10;

export type WizardStep = 'state' | 'command' | 'aggregate' | 'dddEvent' | 'policyEvent' | 'publicEvent' | 'ui'
  | 'feature' | 'anyVO' | 'policy' | 'externalSystem' | 'runCody' | {step: 'selectContext', contexts: WizardContextType[], node: Node};

export const isSelectContextStep = (step: WizardStep | null): step is {step: 'selectContext', contexts: WizardContextType[], node: Node} => {
  return !!step && typeof step === 'object' && step.step === 'selectContext';
}

export type FinishStep = () => WizardContext;

export interface WithWizardStepProps<CTX extends WizardContext>{
  ctx: CTX;
  mode: 'modal' | 'sidebar';
  readonly: boolean;
  onCtxChanged: (ctx: CTX) => void;
  onUpdateMetadata?: (value: string, force?: boolean) => void;
}

interface OwnProps {
  boardId: BoardId;
  openHandle: (handle: (open: boolean, selectedNode: Node | null, runCody?: boolean) => void) => void;
  onClose: () => void;
}

type WizardModalProps = OwnProps & WithNamespaces;

let currentFinishStepAction: FinishStep | null = null;

export const EMPTY_CONTEXT: WizardContext = {board: new Board(defaultBoardProps), type: "empty", nextStep: () => null, previousStep: () => null, installCodyGraphSuggestions: () => {/*Do Nothing*/}};

const WizardModal = (props: WizardModalProps) => {
  const [open, setOpen] = useState(false);
  const board = useSelector(makeBoardByIdSelector(props.boardId));
  const sidebar = useSelector(makeGetSidebarSelector());
  const defaultCtx = {...EMPTY_CONTEXT, board};
  const [ctx, setCtx] = useState<WizardContext>(defaultCtx);
  const [codySession, setCodySession] = useState<WizardContext>(defaultCtx);
  const [codyErrored, setCodyErrored] = useState(false);
  const [unsupportedNode, setUnsupportedNode] = useState<Node|null>(null);
  const [activeStep, setActiveStep] = useState<WizardStep | null >(null);
  const [forcedPreviousStep, setForcedPreviousStep] = useState<WizardStep | undefined>(undefined);
  const [graph] = useGraph();
  const [readonly, setReadonly] = useState(false);

  const sidebarWidth = sidebar.width + 10 /* collapse handle */;

  const memoizeNode = (node: Node, setWizardStep: boolean = false, currentContext?: WizardContext): WizardContext => {
    let wizardStep: WizardStep | null = null;
    let newCtx: WizardContext = currentContext || defaultCtx;

    setReadonly(!node.isEnabled());

    switch (node.getType()) {
      case NodeType.command:
        if(isPartOfPolicyContext(node)) {
          newCtx = policyContextFromNode(node, board);
          wizardStep = 'policy';
        } else {
          newCtx = dddActionFromNode(node, board);
          wizardStep = 'command';
        }
        break;
      case NodeType.aggregate:
        newCtx = dddActionFromNode(node, board);
        wizardStep = 'aggregate';
        break;
      case NodeType.event:
        if(isDDDAction(newCtx)) {
          wizardStep = 'dddEvent';
        } else if (isDddActionEvent(node)) {
          wizardStep = 'dddEvent';
          newCtx = dddActionFromNode(node, board);
        } else if (isPolicyContext(newCtx)) {
          wizardStep = 'policyEvent';
        } else if (isPartOfPolicyContext(node)) {
          wizardStep = 'policyEvent';
          newCtx = policyContextFromNode(node, board);
        } else {
          wizardStep = {step: "selectContext", contexts: ['dddAction', 'policy'], node};
        }
        break;
      case NodeType.ui:
        if(isDddActionUi(node)) {
          newCtx = dddActionFromNode(node, board);
        } else {
          newCtx = uiContextFromNode(node, board);
        }
        wizardStep = 'ui';
        break;
      case NodeType.document:
        if(isStateVoCandidate(node)) {
          newCtx = dddActionFromNode(node, board)
          wizardStep = 'state';
          break;
        }

        newCtx = informationContextFromNode(node, board);
        wizardStep = 'anyVO';
        break;
      case NodeType.feature:
        if(isDddActionOrPolicyFeature(node)) {
          newCtx = isPartOfPolicyContext(node)? policyContextFromNode(node, board) : dddActionFromNode(node, board);
        } else {
          newCtx = featureContextFromNode(node, board);
        }
        wizardStep = 'feature';
        break;
      case NodeType.policy:
        newCtx = policyContextFromNode(node, board);
        wizardStep = 'policy';
        break;
      case NodeType.externalSystem:
        if(isPartOfPolicyContext(node)) {
          newCtx = policyContextFromNode(node, board);
          wizardStep = 'policy';
        } else {
          setUnsupportedNode(node);
        }
        break;
      default:
        setUnsupportedNode(node);
    }

    setCtx(newCtx);
    setCodySession(makeCodySession(newCtx));

    if(setWizardStep) {
      setActiveStep(wizardStep);
    }

    return newCtx;
  }

  props.openHandle((openFromHandle, node, shouldRunCody) => {

    setOpen(openFromHandle);
    if(node) {
      if(isValid(node)) {
        const currentCtx = memoizeNode(node, !activeStep);

        if(shouldRunCody) {
          if(node.getType() === NodeType.feature) {
            setCodySession(currentCtx);
          } else {
            setCodySession(makeCodySession(currentCtx, node));
          }
          setActiveStep('runCody');
        }
      } else {
        setUnsupportedNode(node);
        setCtx(defaultCtx);
        setCodySession(defaultCtx);
      }
    }
  });

  const resetWizard = () => {
    setCtx(defaultCtx);
    setCodySession(defaultCtx);
    setCodyErrored(false);
    setUnsupportedNode(null);
    setActiveStep(null);
    currentFinishStepAction = null;
  }

  const handleCtxChanged = (changedCtx: WizardContext) => {

    let currentSession = {...codySession};
    for (const ctxKey in changedCtx) {
      // @ts-ignore
      if(ctx[ctxKey] !== changedCtx[ctxKey]) {
        // If user visited feature step, all ctx nodes should be passed to cody
        if(ctxKey === 'feature') {
          currentSession = changedCtx;
        } else {
          // @ts-ignore
          currentSession[ctxKey] = changedCtx[ctxKey];
        }
      }
    }

    setCodySession(currentSession);

    if(isSelectContextStep(activeStep)) {
      memoizeNode(activeStep.node, true, changedCtx);
    } else {
      setCtx(changedCtx);
    }
  }

  const handleCodyFinished = (success: boolean) => {
    if(!success) {
      setCodyErrored(true);
    }
  }

  const closeWizard = () => {
    resetWizard();
    props.onClose();
  }

  const saveCurrentStep = () => {
    if(currentFinishStepAction) {
      handleCtxChanged(currentFinishStepAction());
    }
    currentFinishStepAction = null;
  }

  const changeStep = (step: WizardStep) => {
    if(activeStep === 'runCody') {
      if(!codyErrored) {
        setCodySession(makeCodySession(ctx));
      }
    } else {
      saveCurrentStep();
    }
    setActiveStep(step);
    setForcedPreviousStep(undefined);
  }

  const runCody = () => {
    if(activeStep === 'runCody') {
      // Copying the cody session triggers a rerun
      setCodySession({...codySession});
    } else {
      saveCurrentStep();
    }

    setCodyErrored(false);

    if(activeStep === 'feature') {
      // Run Cody on Feature causes all steps (entire ctx) to be generated
      setCodySession({...ctx})
    }

    setActiveStep('runCody');
  }

  const close = () => {
    saveCurrentStep();
    closeWizard();
  }

  const helpLink = stepWikiLink(activeStep);

  return <Modal open={open}
                closeIcon={true}
                onClose={close}
                size="large"
                style={{left: `calc(5% + ${sidebarWidth}px)`, width: `calc(90% - ${sidebarWidth}px)`, height: "calc(80% - 64px)"}}
                closeOnEscape={false} >
    <Modal.Header>
      Cody Wizard{activeStep? ': ' + stepHeader(activeStep, props.t) : ''}&nbsp;<ActiveStepIcon activeStep={activeStep} />
      {helpLink && <Button as={'a'} href={helpLink} target={'_blank'} floated="right" circular={true} basic={true} icon="question" size="mini"/>}
      <p style={{float: 'right', fontSize: '1rem'}}>
        <RunCodyOnSelect />
      </p>
    </Modal.Header>
    <Modal.Content scrolling={true} style={{height: "calc(80vh - 10em)"}}>
      {unsupportedNode && <UnsupportedNode node={unsupportedNode} />}
      {!unsupportedNode
        && activeStep === 'runCody'
        && <RunCody ctx={codySession} onBackToStepChanged={step => setForcedPreviousStep(step)} onCodyFinished={handleCodyFinished}/>
      }
    </Modal.Content>
    <Modal.Actions>
      <ButtonGroup floated="left">
        <PreviousStep activeStep={activeStep} ctx={ctx} forcedPreviousStep={forcedPreviousStep} onPreviousStep={step => changeStep(step)} />
        {activeStep && <Button className='noborder' basic={true} content={stepHeader(activeStep, props.t)} style={{cursor: 'default'}} />}
        <NextStep activeStep={activeStep} ctx={ctx} onNextStep={step => changeStep(step)} />
      </ButtonGroup>
      <Button basic={true} className="noborder" content={props.t('app.form.close') as string} onClick={close} />
      <Button primary={true} icon="play circle" content={props.t('insp.cody_wizard.run_cody') as string} onClick={runCody} disabled={(activeStep === 'runCody' && !codyErrored) || isSelectContextStep(activeStep)} />
    </Modal.Actions>
  </Modal>
};

export default withNamespaces()(WizardModal);
