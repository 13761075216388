import { call, take, fork } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as Routes from '../../routes';
import * as Action from '../../InspectioBoards/actions';
import {standaloneStorageManager} from "../util";

type FlowAction = ActionType<typeof Action.Command.addBoard>;

function* onInstallUserBoard(action: FlowAction) {
    yield call(standaloneStorageManager.addBoard, {
        boardId: action.payload.board.uid,
        name: action.payload.board.name,
        createdAt: (new Date()).toISOString(),
        lastModified: (new Date()).toISOString(),
        xml: '',
        stickyTemplates: [],
        codySuggestEnabled: action.payload.board.codySuggestEnabled,
        eventModelingEnabled: action.payload.board.eventModelingEnabled,
    });

    yield call(action.payload.history.push, Routes.compileInspectioBoardWorkspace(action.payload.board.uid) as any);
}

export function* standaloneInstallUserBoardFlow() {

    while (true) {
        const action: FlowAction = yield take(Action.Type.ADD_BOARD);
        yield fork(onInstallUserBoard, action);
    }
}
