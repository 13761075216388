import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../index";
import {Action as NotifyAction} from "../../NotificationSystem";
import {createOrganizationInvitation, Status} from "../model/OrganizationInvitation";
import {makeOrganizationSelector} from "../selectors/selectOrganization";

type Command = ActionType<typeof Action.Command.resendUserOrganizationInvitation>

function* handleResendUserOrganizationInvitation(command: Command) {
    const {response, error} = yield call(Action.Api.resendUserOrganizationInvitation, command.payload.organizationId, command.payload.invitedUserEmail);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not resend invitation.'));
    }

    if(response) {
        const invitation = createOrganizationInvitation({
            invitationId: command.payload.invitationId,
            invitedUserEmail: command.payload.invitedUserEmail,
        });
        const organization = yield select(makeOrganizationSelector(command.payload.organizationId));

        yield put(Action.Event.userOrganizationInvitationResent(
            invitation,
            organization
        ));
        yield put(NotifyAction.Command.info('User invited', 'Invitation was sent again.'));
    }
}

export function* resendUserOrganizationInvitation() {
    while (true) {
        const command: Command = yield take([
            Action.Type.RESEND_USER_ORGANIZATION_INVITATION
        ]);

        yield fork(handleResendUserOrganizationInvitation, command);
    }
}
