import {List} from "immutable";
import { call, delay, fork, put, race, select, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {Action as UserAction, IioUserInfo, IioUserInfoSelector, UserModel} from "../../User";
import {} from "../../User";
import * as Action from '../actions';
import {appendHistoryEntries, prependHistoryEntries} from "../actions/commands";
import {LOCAL_BOARD_CHANGED, REMOTE_BOARD_CHANGED} from "../actions/constants";
import {BoardId} from "../model/Board";
import {BoardChanged} from "../model/BoardChanged";
import {BoardHistory, HistoryEntry, makeHistoryEntry} from "../model/HistoryEntry";
import {BoardModel} from "../model";

type FlowAction = ActionType<typeof Action.Event.remoteBoardChanged> & ActionType<typeof Action.Event.localBoardChanged> & ActionType<typeof Action.Event.boardInitialized>;
type UserInfoLoaded = ActionType<typeof UserAction.Event.iioUsersInfoLoaded>;

export function* onBoardChanged(boardChangedList: BoardChanged[], prependEntries = false) {
    let history: BoardHistory = List<HistoryEntry>();
    let boardId: BoardId|undefined;

    for(const boardChanged of boardChangedList) {
        let iioUserInfo: IioUserInfo.IioUserInfo = yield select(IioUserInfoSelector.getIioUserInfoById, boardChanged.userId);
        boardId = boardChanged.board;

        if(!iioUserInfo) {
            yield put(UserAction.Query.loadUsersInfo([boardChanged.userId]));

            let noMatchingInfo = true;

            while (noMatchingInfo) {
                const {infoLoaded, timeout}: {infoLoaded: UserInfoLoaded, timeout: boolean} = yield race({
                    infoLoaded: take([UserAction.Type.IIO_USERS_INFO_LOADED]),
                    timeout: delay(5000)
                });

                if(timeout) {
                    return;
                }

                infoLoaded.payload.usersInfo.forEach(loadedInfo => {
                    if(loadedInfo.userId === boardChanged.userId) {
                        iioUserInfo = loadedInfo;
                        noMatchingInfo = false;
                    }
                });
            }
        }

        const historyEntry = makeHistoryEntry(boardChanged, iioUserInfo);
        if(prependEntries) {
            history = history.unshift(historyEntry);
        } else {
            history = history.push(historyEntry);
        }
    }

    if(boardId) {
        if(prependEntries) {
            yield put(prependHistoryEntries(boardId, history));
        } else {
            yield put(appendHistoryEntries(boardId, history));
        }
    }
}

export function* prepareBoardHistoryFlow() {
    let  latestBoardId: BoardModel.BoardId = '';

    while (true) {
        const action: FlowAction = yield take([Action.Type.REMOTE_BOARD_CHANGED, Action.Type.LOCAL_BOARD_CHANGED, Action.Type.BOARD_INITIALIZED]);

        switch (action.type) {
            case Action.Type.BOARD_INITIALIZED:
                latestBoardId = action.payload.board.uid;
                break;
            case REMOTE_BOARD_CHANGED:
            case LOCAL_BOARD_CHANGED:
                if(latestBoardId === action.payload.boardChanged.board) {
                    yield fork(onBoardChanged, [action.payload.boardChanged], true);
                }
                break;

        }
    }
}
