import {ServiceName} from "../../../../model/Board";
import {ElementsTree} from "../../../../model/ElementsTree";
import {NodeType} from "../../../../model/Graph";
import {fqcn} from "../../../../service/cody-wizard/node/fqcn";
import {names} from "../../../../service/cody-wizard/node/names";
import {Schema} from "../../../../service/cody-wizard/schema/schema";
import {getSchemaRefName} from "../../../../service/cody-wizard/vo/get-schema-ref-name";
import {getVoMetadata} from "../../../../service/cody-wizard/vo/get-vo-metadata";
import {isListVo} from "../../../../service/cody-wizard/vo/is-list-vo";
import {isQueryableItemVo} from "../../../../service/cody-wizard/vo/is-queryable-item-vo";
import {isStateVo} from "../../../../service/cody-wizard/vo/is-state-vo";

export const getPossibleStateRefs = (elementsTree: ElementsTree): string[] => {
  return elementsTree.getFilteredElementsByType(NodeType.document)
    .filter(vo => isStateVo(vo))
    .map(vo => {
      const voMeta = getVoMetadata(vo);
      return getSchemaRefName(vo, voMeta);
    })
    .toArray()
}

export const getPossibleQueryableItemRefs = (elementsTree: ElementsTree): string[] => {
  return elementsTree.getFilteredElementsByType(NodeType.document)
    .filter(vo => isQueryableItemVo(vo))
    .map(vo => {
      const voMeta = getVoMetadata(vo);
      return getSchemaRefName(vo, voMeta);
    })
    .toArray()
}

export const getPossibleListVOs = (elementsTree: ElementsTree): string[] => {
  const possibleRefs: string[] = [];

  elementsTree.getFilteredElementsByType(NodeType.document).forEach(ele => {
    const voMeta = getVoMetadata(ele);

    if(!voMeta.querySchema) {
      return;
    }

    if(!isListVo(ele)) {
      return;
    }

    const possibleRef = getSchemaRefName(ele, voMeta);

    if(!possibleRefs.includes(possibleRef)) {
      possibleRefs.push(possibleRef);
    }
  });

  return possibleRefs;
}

export const getPossibleQueryableVORefsWithoutQueryParams = (elementsTree: ElementsTree): string[] => {
  const possibleRefs: string[] = [];

  elementsTree.getFilteredElementsByType(NodeType.document).forEach(ele => {
    const voMeta = getVoMetadata(ele);

    if(!voMeta.querySchema) {
      return;
    }

    if(!voMeta.querySchema.isEmpty()) {
      return;
    }

    const possibleRef = getSchemaRefName(ele, voMeta);

    if(!possibleRefs.includes(possibleRef)) {
      possibleRefs.push(possibleRef);
    }
  });

  return possibleRefs;
}

export const getItemProperties = (nodeSchema: Schema, elementsTree: ElementsTree): string[] => {
  if(!nodeSchema.isList()) {
    return [];
  }

  let listItemSchema = nodeSchema.getListItemsSchema(new Schema({}));

  if(listItemSchema.isRef()) {
    listItemSchema =  listItemSchema.resolveRef(elementsTree);
  }

  return listItemSchema.getObjectProperties();
}

export const getPossiblePages = (elementsTree: ElementsTree): string[] => {
  return elementsTree.getFilteredElementsByType(NodeType.ui).map(ui => names(ui.getName()).className).toArray();
}

export const getPossibleCommands = (elementsTree: ElementsTree, boardName: ServiceName): string[] => {
  return elementsTree.getFilteredElementsByType(NodeType.command).map(cmd => fqcn(cmd, boardName)).toArray();
}

export const getPossibleViews = (elementsTree: ElementsTree, boardName: ServiceName): string[] => {
  return elementsTree.getFilteredElementsByType(NodeType.document).map(view => fqcn(view, boardName)).toArray();
}
