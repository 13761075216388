import {Either, isFailure, isSuccess} from "../../core/validation/either";
import {Graph, makeNodeFromJs} from "../../InspectioBoards/model/Graph";
import {AddElementAtPosition, createAddElementAtPositionFromServerData} from "../model/tasks/AddElementAtPosition";
import {
    AddElementNextToAnother,
    createAddElementNextToAnotherFromServerData
} from "../model/tasks/AddElementNextToAnother";
import {Task} from "../model/tasks/Task";
import {ConnectElements, createConnectElementsFromServerData} from "../model/tasks/ConnectElements";
import {createRenameElementFromServerData, RenameElement} from "../model/tasks/RenameElement";
import {createRemoveElementFromServerData, RemoveElement} from "../model/tasks/RemoveElement";
import {
    ChangeElementDescription,
    createChangeElementDescriptionFromServerData
} from "../model/tasks/ChangeElementDescription";
import {ChangeElementMetadata, createChangeElementMetadataFromServerData} from "../model/tasks/ChangeElementMetadata";
import ConfiguredSocket from '../../api/ConfiguredSocket';
import {ChangeElementLabel, createChangeElementLabelFromServerData} from "../model/tasks/ChangeElementLabel";

type ConfiguredSocket = typeof ConfiguredSocket;
const BOARD_AGENT_TASK_SCHEDULED = 'BoardAgentTaskScheduled';

function callTaskFactory<R>(data: any, taskFactory: (data: any) => Either<string, R>): R | undefined {
    const result = taskFactory(data);

    if(isFailure(result)) {
        console.error("[BoardAgent] Failed to create task from server data.", result.value, data, taskFactory);
        return;
    }

    if(isSuccess(result)) {
        return result.value;
    }

    return;
}

export class BoardAgent {
    private graph: Graph;
    private socket: ConfiguredSocket;


    constructor(graph: Graph, socket: ConfiguredSocket) {
        this.graph = graph;
        this.socket = socket;
        this.handleTask = this.handleTask.bind(this);
    }

    public startWatching(): void {
        console.log("[BoardAgent] Start watching for tasks");
        this.socket.on(BOARD_AGENT_TASK_SCHEDULED, (task: Task, ack: (success: boolean) => void) => {
            ack(this.handleTask(task));
        })
    }

    public handleTask(task: Task): boolean {
        console.log("[BoardAgent] Going to handle task: ", task);
        switch (task.command.name) {
            case 'AddElementAtPosition':
                const addElementAtPosition = callTaskFactory(task.command.payload, createAddElementAtPositionFromServerData);

                if(addElementAtPosition) {
                    return this.addElementAtPosition(addElementAtPosition);
                }

                return false;
            case 'AddElementNextToAnother':
                const addElementNextToAnother = callTaskFactory(task.command.payload, createAddElementNextToAnotherFromServerData);

                if(addElementNextToAnother) {
                    return this.addElementNextToAnother(addElementNextToAnother);
                }

                return false;
            case 'RenameElement':
                const renameElement = callTaskFactory(task.command.payload, createRenameElementFromServerData);

                if(renameElement) {
                    return this.renameElement(renameElement);
                }

                return false;
            case 'RemoveElement':
                const removeElement = callTaskFactory(task.command.payload, createRemoveElementFromServerData);

                if(removeElement) {
                    return this.removeElement(removeElement);
                }

                return false;
            case 'ChangeElementDescription':
                const changeElementDescription = callTaskFactory(task.command.payload, createChangeElementDescriptionFromServerData);

                if(changeElementDescription) {
                    return this.changeElementDescription(changeElementDescription);
                }

                return false;
            case 'ChangeElementLabel':
                const changeElementLabel = callTaskFactory(task.command.payload, createChangeElementLabelFromServerData);

                if(changeElementLabel) {
                    return this.changeElementLabel(changeElementLabel);
                }

                return false;
            case 'ChangeElementMetadata':
                const changeElementMetadata = callTaskFactory(task.command.payload, createChangeElementMetadataFromServerData);

                if(changeElementMetadata) {
                    return this.changeElementMetadata(changeElementMetadata);
                }

                return false;
            case 'ConnectElements':
                const connectElements = callTaskFactory(task.command.payload, createConnectElementsFromServerData);

                if(connectElements) {
                    return this.connectElements(connectElements);
                }

                return false;
            default:
                console.warn("[BoardAgent] Unknown task received for board agent. Task was: " + task.command.name);
                return false;
        }
    }

    public destroy() {
        console.log("[BoardAgent] Stop watching for Tasks");
        this.socket.off(BOARD_AGENT_TASK_SCHEDULED);
    }

    private addElementAtPosition(task: AddElementAtPosition): boolean {
        try {
            const node = makeNodeFromJs({
                id: task.elementId,
                name: task.name,
                type: task.type,
                description: task.content,
                geometry: task.position,
                parent: task.parent? this.graph.getNode(task.parent) : null,
                metadata: task.metadata || '',
                size: task.size,
            })

            this.graph.addNode(node);

            return true;
        } catch (e) {
            console.error("[BoardAgent] Failed to add element at position.", e, task);
        }

        return false;
    }

    private addElementNextToAnother(task: AddElementNextToAnother): boolean {
        const otherElement = this.graph.getNode(task.nextToElementId);

        if(!otherElement) {
            console.warn("[BoardAgent] Skipping task. Next to element cannot be found!", task);
            return true;
        }

        const margin = task.margin || 20;

        try {
            const node = makeNodeFromJs({
                id: task.elementId,
                name: task.name,
                type: task.type,
                description: task.content,
                geometry: {x: 0, y: 0},
                parent: task.parent? this.graph.getNode(task.parent) : null,
                metadata: task.metadata || '',
                size: task.size,
            })

            this.graph.addNodeNextToAnother(node, otherElement, task.position, margin, task.center);

            return true;
        } catch (e) {
            console.error("[BoardAgent] Failed to add element next to another.", e, task);
        }

        return false;
    }


    private connectElements(connectElements: ConnectElements): boolean {
        const source = this.graph.getNode(connectElements.source);

        if(!source) {
            console.warn("[BoardAgent] Cannot connect elements. Source not found.", connectElements);
            // return success, because won't make sense here
            return true;
        }

        const target = this.graph.getNode(connectElements.target);

        if(!target) {
            console.warn("[BoardAgent] Cannot connect elements. Target not found.", connectElements);
            // return success, because won't make sense here
            return true;
        }

        try {
            this.graph.connectNodes(source, target, connectElements.connectionLabel);
            return true;
        } catch (e) {
            console.error("[BoardAgent] Failed to connect elements.", e, connectElements);
        }


        return false;
    }

    private renameElement(renameElement: RenameElement): boolean {
        const ele = this.graph.getNode(renameElement.elementId);

        if(!ele) {
            console.warn(`[BoardAgent] Cannot rename element. No element found with id ${renameElement.elementId}`);
            return true;
        }

        try {
            this.graph.changeNodeName(ele, renameElement.name);
            return true;
        } catch (e) {
            console.error("[BoardAgent] Failed to rename element.", e, renameElement);
        }

        return false;
    }

    private changeElementLabel(changeElementLabel: ChangeElementLabel): boolean {
        const ele = this.graph.getNode(changeElementLabel.elementId);

        if (!ele) {
            console.warn(`[BoardAgent] Cannot change element label. No element found with id ${changeElementLabel.elementId}`);
            return true;
        }

        try {
            this.graph.changeNodeLabel(ele, changeElementLabel.label);
            return true;
        } catch (e) {
            console.error("[BoardAgent] Failed to change element label.", e, changeElementLabel);
        }

        return false;
    }

    private removeElement(removeElement: RemoveElement) {
        const ele = this.graph.getNode(removeElement.elementId);

        if(!ele) {
            console.warn(`[BoardAgent] Cannot remove element. No element found with id ${removeElement.elementId}`);
            return true;
        }

        try {
            this.graph.deleteNode(ele);
            return true;
        } catch (e) {
            console.error("[BoardAgent] Failed to remove element.", e, removeElement);
        }

        return false;
    }

    private changeElementDescription(changeElementDescription: ChangeElementDescription) {
        const ele = this.graph.getNode(changeElementDescription.elementId);

        if(!ele) {
            console.warn(`[BoardAgent] Cannot change element description. No element found with id ${changeElementDescription.elementId}`);
            return true;
        }

        try {
            this.graph.changeNodeDescription(ele, changeElementDescription.content);
            return true;
        } catch (e) {
            console.error("[BoardAgent] Failed to change element description.", e, changeElementDescription)
        }

        return false;
    }

    private changeElementMetadata(changeElementMetadata: ChangeElementMetadata) {
        const ele = this.graph.getNode(changeElementMetadata.elementId);

        if(!ele) {
            console.warn(`[BoardAgent] Cannot change element metadata. No element found with id ${changeElementMetadata.elementId}`);
            return true;
        }

        try {
            this.graph.changeNodeMetadata(ele, changeElementMetadata.metadata);
            return true;
        } catch (e) {
            console.error("[BoardAgent] Failed to change element metadata.", e, changeElementMetadata)
        }

        return false;
    }
}
