import {useState} from "react";
import * as React from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, ButtonGroup, Icon, Input, List as SemanticList, ListItem, Modal} from "semantic-ui-react";
import SecondaryButton from "../../core/components/SecondaryButton";
import {
    isSuccess,
} from "../../core/validation/either";
import UserAvatar from "../../User/components/UserAvatar";
import {createIioUserInfoFromServerData} from "../../User/model/IioUserInfo";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import * as Action from "../actions";
import {Organization} from "../model/Organization";
import OpenOrganizationInvitation from "./Invitations/OpenOrganizationInvitation";

interface OwnProps {
    open: boolean;
    hasEnoughQuota: boolean;
    onClose: () => void;
    organization: Organization
}

type InviteUserToOrganizationModalProps = WithNamespaces & OwnProps;

const InviteUserToOrganizationModal = (props: InviteUserToOrganizationModalProps) => {
    const [email, setEmail] = useState('');
    const currentUser = useSelector(makeGetCurrentUser());

    const dispatch = useDispatch();

    const success = createIioUserInfoFromServerData({
        userId: currentUser.uid,
        username: currentUser.displayName,
        avatarUrl: currentUser.avatarUrl,
        position: ''
    });
    let userInfo;

    if(isSuccess(success)) {
        userInfo = success.value;
    }

    const handleSubmit = () => {
        dispatch(Action.Command.inviteUserToOrganization(props.organization.uid, email))
        setEmail('');
        props.onClose();
    };

    const hasInvitations = props.organization.invitations.count() > 0;

    return (
        <Modal open={props.open} closeIcon={true} onClose={props.onClose}>
            <Modal.Header>{props.t('insp.organisation.invite_user')}</Modal.Header>
            <Modal.Content>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: '10px' }}>
                        <Input
                            style={{ width: '100%' }}
                            value={email}
                            onChange={(e, data) => setEmail(data.value)}
                            placeholder={props.t('insp.organisation.user_email')}
                            autoFocus={true}
                        />
                    </div>
                    <Button primary={true} type="submit" disabled={!isValidEmail(email) || !props.hasEnoughQuota}>
                        {props.t('insp.organisation.send_invitation')}
                    </Button>
                </form>
                <div className="ui horizontal divider">{ props.t('insp.organisation.open_invitations') }</div>
                {hasInvitations && <SemanticList relaxed={true} divided={true}>
                    {props.organization.invitations.map(
                        invitation => <OpenOrganizationInvitation
                            invitation={invitation}
                            organization={props.organization}
                            key={invitation.invitationId} />
                    )}
                </SemanticList>}
                {!hasInvitations && <p className="infotextSecondary">{ props.t('insp.organisation.empty_invitations') }</p>}
            </Modal.Content>
        </Modal>
    );
};

/**
 * Validates the basic email structure; not a perfect validation
 *
 * @param value
 */
const isValidEmail = (value: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};

export default withNamespaces()(InviteUserToOrganizationModal);
