import {UserId} from "./UserInfo";
import {List, Record as ImmutableRecord} from "immutable";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";

export type UserSettingsValidationError = string;
export type UserSettingsValue = number|boolean;
export type UserSettingsValues = Record<string, UserSettingsValue>;

export interface UserSettingsProps {
    userId: UserId,
    values: UserSettingsValues;
}

export const defaultUserSettingsProps: UserSettingsProps = {
    userId: '',
    values: {},
};

export const createUserSettingsFromServerData = (data: any): Either<UserSettingsValidationError, UserSettings> => {
    const validatedData: Either<string, any> = validate(data,
        [hasKey('userId'), "userId missing"],
        [hasKey('values'), "values missing in "+data.userId],
    );

    if(isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new UserSettings({
        userId: data.userId,
        values: data.values,
    }));
};


export class UserSettings extends ImmutableRecord(defaultUserSettingsProps) implements UserSettingsProps {

    public constructor(data: UserSettingsProps) {
        super(data);
    }
}
