import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {keyLiteMode, makeUserSettingSelector} from "../selectors/userSettings";
import {Icon, MenuItem, Popup} from "semantic-ui-react";
import {upsertUserSetting} from "../actions/commands";
import {SemanticICONS} from "semantic-ui-react/dist/commonjs/generic";
import {withNamespaces, WithNamespaces} from "react-i18next";

interface OwnProps {

}

type TopmenuLiteModeProps = OwnProps & WithNamespaces;

const TopmenuLiteMode = (props: TopmenuLiteModeProps) => {
    const liteMode = useSelector(makeUserSettingSelector(keyLiteMode(), false));
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(upsertUserSetting(keyLiteMode(), !liteMode));
    }

    const color = liteMode? '#2eba46' : 'inherit';
    const icon = liteMode? 'high' : 'half';
    const translateKey = liteMode? 'lite_mode_is_active' : 'activate_lite_mode';

    return <Popup trigger={<MenuItem onClick={handleClick}>
        <Icon name={ `battery ${icon}` as SemanticICONS } style={{color}} />
    </MenuItem>} content={<>
        <p>{props.t('insp.top_menu.'+translateKey)}</p>
        {liteMode && <p><small>{props.t('insp.top_menu.lite_mode_is_active_details')}</small></p>}
    </>} />

};

export default withNamespaces()(TopmenuLiteMode);
