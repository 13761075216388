import * as React from 'react';
import {useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    Confirm,
    Container,
    Dropdown,
    DropdownItemProps,
    Grid,
    Header,
    Message,
    Search,
    Select
} from "semantic-ui-react";
import EditableTextline from "../../core/components/EditableTextline";
import {UserInfo} from "../../User/model/UserInfo";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeIioFilteredUserListSelector} from "../../User/selectors/iioUserInfo";
import * as Action from "../actions";
import {UserOrganizationInfo} from "../model/UserOrganizationInfo";
import {makeOrganizationSelector} from "../selectors/selectOrganization";
import InviteUserToOrganizationModal from "./InviteUserToOrganisationModal";
import OrganizationMemberEntry from "./OrganizationMemberEntry";
import {List} from "immutable";

interface OwnProps {
    activeOrganization: UserOrganizationInfo,
    currentUser: UserInfo
}

type OrganisationManagementProps = OwnProps & WithNamespaces;

interface Orga {
    name: string,
    description: string,
}

const INPUT_CHAR_SIZE = 9;
const CHAR_SIZE_UNIT = 'px';
const ADDITIONAL_CHAR_PADDING = 20;

const OrganisationManagement = (props: OrganisationManagementProps) => {
    const [showNewOrga, setShowNewOrga] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const currentUser = useSelector(makeGetCurrentUser());
    const organizationData = useSelector(makeOrganizationSelector(props.activeOrganization.organizationId));
    const [mailFilter, setMailFilter] = useState('');
    const dispatch = useDispatch();
    let members = useSelector(
        organizationData
            ? makeIioFilteredUserListSelector(organizationData.members.push(organizationData.ownerId))
            : makeIioFilteredUserListSelector(List())
    );
    let guests = useSelector(
        organizationData
            ? makeIioFilteredUserListSelector(organizationData.guests)
            : makeIioFilteredUserListSelector(List())
    );

    if(!organizationData) {
        return <Message error={true}>Could not load organization data. Please refresh the page to try again!</Message>;
    }

    const hasEnoughQuota = (organizationData.memberQuota - organizationData.invitations.count()) > 0;

    const handleDeleteClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setShowDeleteModal(true);
    }

    const handleAddUserClick = () => {
        setShowAddUserModal(true);
    }

    const closeAddUserModal = () => {
        setShowAddUserModal(false);
    }

    const handleOrgaNameChanged = (name: string) => {
        dispatch(Action.Command.renameOrganization(props.activeOrganization.organizationId, name));
    }

    const handleNewOrgaEntered = (name: string) => {
        dispatch(Action.Command.setUpExplicitOrganization(props.activeOrganization.organizationId, name));
        setShowNewOrga(false);
    }

    const emailSearchOptions: DropdownItemProps[] = members.map(m => ({text: m.email, key: m.uid, value: m.email})).toJS();

    const handleMailFilterChanged = (newMailFilter: string) => {
        setMailFilter(newMailFilter);
    }

    if(mailFilter !== '') {
        members = members.filter(m => m.email === mailFilter);
        guests = guests.filter(g => g.email === mailFilter);
    }


    const memberList = members.sortBy(m => m.username).sort((mA, mB) => {
        if(mA.userId === organizationData.ownerId) {
            return  -1;
        }

        if(mB.userId === organizationData.ownerId) {
            return 1;
        }

        if(organizationData.admins.contains(mA.userId)) {
            return -1;
        }

        if(organizationData.admins.contains(mB.userId)) {
            return 1;
        }

        return 0;
    }).map(member => <OrganizationMemberEntry
        key={member.userId}
        member={member}
        currentUserIsOwner={organizationData.ownerId === currentUser.uid}
        isOwner={organizationData.ownerId === member.userId}
        isAdmin={organizationData.admins.contains(member.userId)}
        readonly={organizationData.ownerId === member.userId || (organizationData.ownerId !== currentUser.uid && organizationData.admins.contains(member.userId))}
        organizationId={organizationData.uid} />);

    const guestList = guests.sortBy(m => m.username).map(member => <OrganizationMemberEntry
        key={member.userId}
        member={member}
        currentUserIsOwner={organizationData.ownerId === currentUser.uid}
        isOwner={organizationData.ownerId === member.userId}
        isAdmin={organizationData.admins.contains(member.userId)}
        isGuest={true}
        readonly={organizationData.ownerId === member.userId || (organizationData.ownerId !== currentUser.uid && organizationData.admins.contains(member.userId))}
        organizationId={organizationData.uid} />);

    return <>
            {props.activeOrganization.explicitOrganization && !hasEnoughQuota && <Grid.Row>
                <Grid.Column>
                    <Message warning={true} floating={true} content={props.t('insp.organisation.no_member_quota')} />
                </Grid.Column>
            </Grid.Row>}
            <Grid.Row>
                <Grid.Column>
                    <div>
                        {!props.activeOrganization.explicitOrganization && props.activeOrganization.ownOrganization
                            && <button className="ui huge basic button" onClick={() => setShowNewOrga(!showNewOrga)}>
                            <i className="plus icon"/>
                            {props.t('insp.organisation.create_btn')}
                        </button>}
                        {showNewOrga && <div className="ui segment">
                            <EditableTextline
                                text={''}
                                charSize={INPUT_CHAR_SIZE}
                                widthUnit={CHAR_SIZE_UNIT}
                                padding={ADDITIONAL_CHAR_PADDING}
                                minWidth={200}
                                required={true}
                                onTextChanged={handleNewOrgaEntered}
                                focus={true}
                                onCancel={() => {
                                    setShowNewOrga(false)
                                }}
                                placeholder={props.t('insp.organisation.name') as string}
                            />
                        </div>
                        }
                        {props.activeOrganization.explicitOrganization && <div className="ui segment">
                            {<a href="#" className="ui small right floated basic button noborder"
                                onClick={handleDeleteClick} style={{display: "none"}}>
                                Delete
                            </a>}

                            <h3 style={{margin: 0, cursor: props.activeOrganization.ownOrganization? "pointer" : "default"}} id={'1234'}>
                                <EditableTextline
                                    text={props.activeOrganization.organizationName}
                                    charSize={INPUT_CHAR_SIZE}
                                    widthUnit={CHAR_SIZE_UNIT}
                                    padding={ADDITIONAL_CHAR_PADDING}
                                    minWidth={200}
                                    required={true}
                                    onTextChanged={handleOrgaNameChanged}
                                    focus={false}
                                    onCancel={() => {
                                        console.log("cancel name change")
                                    }}
                                    placeholder={props.t('insp.organisation.name') as string}
                                    disabled={!props.activeOrganization.ownOrganization}
                                />
                            </h3>
                            <Select options={emailSearchOptions} defaultValue={mailFilter} onChange={(e,data) => handleMailFilterChanged(data.value as string)} placeholder="Email Filter" search={true} clearable={true} basic={true} floating={true} style={{float: "right", height: "20px", marginTop: "-30px"}} />
                            <div className="ui horizontal divider">{props.t('insp.organisation.member')}</div>
                            <div className="ui horizontal list">
                                <Button basic={true} icon={true} color="blue" size="large" onClick={handleAddUserClick} disabled={!hasEnoughQuota && organizationData.invitations.count() === 0}>
                                    <i className="blue user icon"/>
                                </Button>
                                {memberList}
                            </div>
                            {guestList.count() > 0 &&  <div className="ui horizontal divider">{props.t('insp.organisation.guests')}</div>}
                            {guestList.count() > 0 && <div className="ui horizontal list">
                                {guestList}
                            </div>}
                            <InviteUserToOrganizationModal
                                open={showAddUserModal}
                                onClose={closeAddUserModal}
                                organization={organizationData}
                                hasEnoughQuota={hasEnoughQuota}
                            />
                            <Confirm
                                open={showDeleteModal}
                                header={props.t('insp.team.remove_team_confirm_title')}
                                confirmButton={props.t('app.form.remove')}
                                cancelButton={<Button content={props.t('app.form.cancel')} className="basic noborder" />}
                                content={props.t('insp.team.remove_team_confirm_text', {team_name: props.activeOrganization.organizationName})}
                                onCancel={() => setShowDeleteModal(false)}
                                onConfirm={() => {alert("confirmed!")}}
                            />
                        </div>}
                    </div>
                </Grid.Column>
            </Grid.Row>
            {!props.activeOrganization.explicitOrganization && props.activeOrganization.ownOrganization && <Grid.Row>
                <Grid.Column>
                    <Message info={true} floating={true} content={props.t('insp.organisation.not_explicit')}/>
                </Grid.Column>
            </Grid.Row>}
            <Grid.Row>
                <Grid.Column />
            </Grid.Row>
        </>;
};

export default withNamespaces()(OrganisationManagement);
