import * as React from 'react';
import {FormEvent, useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {useGraph} from "../../../hooks/useGraph";
import {Node} from "../../../model/Graph";
import {ImmutablePolicyContext } from "../../../service/cody-wizard/context/policy-context";
import {
  EventMetadata,
  getEventMetadata
} from "../../../service/cody-wizard/event/get-event-metadata";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import {ValueObjectMetadata} from "../../../service/cody-wizard/vo/get-vo-metadata";
import EditorDivider from "../Editor/EditorDivider";
import SchemaEditor from "../Editor/SchemaEditor";
import {WithWizardStepProps} from "../WizardModal";
import {cloneDeep} from "lodash";

interface OwnProps {

}

type PolicyEventProps = OwnProps & WithWizardStepProps<ImmutablePolicyContext> & WithNamespaces;

const PolicyEvent = (props: PolicyEventProps) => {
  const event = props.ctx.event;

  const [graph] = useGraph();
  const elementsTree = useElementsTree(props.ctx.board.uid);
  const [selectedEvent, setSelectedEvent] = useState<Node|null>(null);
  const [metadata, setMetadata] = useState<EventMetadata>({});
  const [autoFocusSchema, setAutoFocusSchema] = useState(false);

  const updateMetadata = (meta: ValueObjectMetadata) => {
    if(props.onUpdateMetadata) {
      props.onUpdateMetadata(JSON.stringify(meta, null, 2), true);
    }

    setMetadata(meta);
  }

  useEffect(() => {
    resetForm();

    if(event) {
      setSelectedEvent(event);
      const meta = getEventMetadata(event);

      setMetadata(meta);
    }

  }, [event])

  const resetForm = () => {
    setSelectedEvent(null);
    setMetadata({});
    setAutoFocusSchema(false);
  }

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  const handleSchemaChanged = (newSchema: Schema | undefined) => {
    if(!newSchema) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.schema;
      updateMetadata(clonedMeta);
      return;
    }

    updateMetadata({...metadata, schema: newSchema});
  }

  return <form className='ui form' onSubmit={handleSubmit}>
    <EditorDivider content="Schema" />
    <SchemaEditor boardId={props.ctx.board.uid}
                  sessionId={ selectedEvent? selectedEvent.getId() : undefined }
                  schema={metadata.schema || Schema.fromString('{}')}
                  readonly={!!(selectedEvent && !selectedEvent.isEnabled())}
                  onSchemaChanged={handleSchemaChanged}
                  autofocus={autoFocusSchema}
    />
  </form>
};

export default withNamespaces()(PolicyEvent);
