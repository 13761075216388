import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {makeOrganizationSelector} from "../selectors/selectOrganization";

type Command = ActionType<typeof Action.Command.promoteUserToOrganizationAdmin>

function* handlePromoteUserToOrganizationAdmin(command: Command) {
    const {response, error} = yield call(Action.Api.promoteUserToOrganizationAdmin, command.payload.organizationId, command.payload.userId);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not promote user to organization admin.'));
    }

    if(response) {
        const organization = yield select(makeOrganizationSelector(command.payload.organizationId));

        yield put(Action.Event.userPromotedToOrganizationAdmin(organization.promoteAdmin(command.payload.userId)));

        yield put(NotifyAction.Command.info('User promoted', 'The user is now an admin of the organization.'));
    }
}

export function* promoteUserToOrganizationAdmin() {
    while (true) {
        const command: Command = yield take([
            Action.Type.PROMOTE_USER_TO_ORGANIZATION_ADMIN
        ]);

        yield fork(handlePromoteUserToOrganizationAdmin, command);
    }
}
