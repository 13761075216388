import {List} from "immutable";
import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button} from "semantic-ui-react";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {useGraph} from "../../../hooks/useGraph";
import {
  Graph,
  makeNodeFromJs,
  NextToPosition,
  Node,
  NodeType
} from "../../../model/Graph";
import {ImmutablePolicyContext, policyContextFromNode} from "../../../service/cody-wizard/context/policy-context";
import {updateContext} from "../../../service/cody-wizard/context/wizard-context";
import {suggestDependenciesForNode} from "../../../service/cody-wizard/dependencies/suggest-dependencies-for-node";
import {getEventMetadata} from "../../../service/cody-wizard/event/get-event-metadata";
import {getDefaultServiceName} from "../../../service/cody-wizard/node/detect-service";
import {fqcn} from "../../../service/cody-wizard/node/fqcn";
import {markAsConnected} from "../../../service/cody-wizard/node/mark-as-connected";
import {getPolicyMetadata, PolicyMetadata} from "../../../service/cody-wizard/policy/get-policy-metadata";
import {
  isExecuteRules,
  isForEach,
  isIfConditionRule,
  isIfNotConditionRule,
  isTriggerCommand,
  Rule,
  ThenType
} from "../../../service/cody-wizard/rule-engine/configuration";
import {RulesDependencyMetadata} from "../../../service/cody-wizard/rule-engine/types";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import DependenciesEditor from "../Editor/DependenciesEditor";
import EditorDivider from "../Editor/EditorDivider";
import PolicyRulesEditor from "../Editor/PolicyRulesEditor";
import CodySuggestButton from "../Editor/Suggestion/CodySuggestButton";
import MultipleNodeSelect from "../Node/MultipleNodeSelect";
import {
  DEFAULT_CARD_HEIGHT,
  DEFAULT_CONNECT_MARGIN,
  SLICE_LANE_MARGIN,
  WithWizardStepProps
} from "../WizardModal";
import {repositionFeature} from "./Feature";

interface OwnProps {

}

type PolicyProps = OwnProps & WithWizardStepProps<ImmutablePolicyContext> & WithNamespaces;

const Policy = (props: PolicyProps) => {
  const policy = props.ctx.policy;

  const [graph] = useGraph();
  const elementsTree = useElementsTree(props.ctx.board.uid);
  const [selectedPolicy, setSelectedPolicy] = useState<Node|null>(null);
  const [metadata, setMetadata] = useState<PolicyMetadata>({});
  const [autoFocusPolicyRules, setAutoFocusPolicyRules] = useState(false);
  const [dependencies, setDependencies] = useState<Record<string, RulesDependencyMetadata>>({});
  const [showDependencies, setShowDependencies] = useState(false);
  const [policyTargets, setPolicyTargets] = useState(props.ctx.policyTargets);

  const updateMetadata = (meta: PolicyMetadata) => {
    if(props.onUpdateMetadata) {
      props.onUpdateMetadata(JSON.stringify(meta, null, 2), true);
    }

    setMetadata(meta);
  }

  const updatePolicyTargets = (targets: List<Node>) => {
    if(props.mode === "sidebar" && selectedPolicy) {
      const shouldRepositionFeature = updateTargetsOnGraph(selectedPolicy, props.ctx, targets, graph);
      // Refresh context
      const changedCtx = policyContextFromNode(graph.getNode(selectedPolicy.getId())!, props.ctx.board);

      if(shouldRepositionFeature && props.ctx.feature) {
        const repositionedFeature = repositionFeature(
          props.ctx.feature,
          policyContextFromNode(graph.getNode(selectedPolicy.getId())!, props.ctx.board),
          graph
        );

        graph.resizeNode(repositionedFeature, repositionedFeature.getSize());
      }

      props.onCtxChanged(changedCtx);

    }

    setPolicyTargets(targets);
  }

  const updateDependencies = (deps: Record<string, RulesDependencyMetadata>) => {
    setDependencies(deps);
  }

  useEffect(() => {
    resetForm();

    if(policy) {
      setSelectedPolicy(policy);
      const meta = getPolicyMetadata(policy);
      setMetadata(meta);
    }

    if(Object.keys(props.ctx.policyDependencies).length) {
      setShowDependencies(true);
    }

    setDependencies(props.ctx.policyDependencies);
    setPolicyTargets(props.ctx.policyTargets);
  }, [policy])

  const resetForm = () => {
    setSelectedPolicy(null);
    setMetadata({});
    setAutoFocusPolicyRules(false);
    setDependencies({});
    setShowDependencies(false);
    setPolicyTargets(List([]));
  }

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  const handleRulesChanged = (newRuleSet: Rule[] | undefined) => {
    if(!newRuleSet) {
      newRuleSet = [];
    }

    updateMetadata({...metadata, rules: newRuleSet});
  }

  const handleDependenciesConfigChanged = (changedDependencies: Record<string, RulesDependencyMetadata> | undefined) => {
    if(!changedDependencies) {
      changedDependencies = {};
    }

    updateDependencies(changedDependencies);
    updateMetadata({...metadata, dependencies: changedDependencies});
  }

  const handleSuggestDependencies = () => {
    if(!selectedPolicy) {
      return;
    }

    const newDeps = suggestDependenciesForNode(selectedPolicy, getDefaultServiceName(props.ctx), dependencies, false);

    if(JSON.stringify(newDeps) !== JSON.stringify(dependencies)) {
      handleDependenciesConfigChanged(newDeps);
    }
  }

  const handlePolicyTargetsChanged = (changedPolicyTargets: List<Node>) => {
    updatePolicyTargets(changedPolicyTargets);
    handleRulesChanged(suggestTriggerRules(changedPolicyTargets, metadata, props.ctx));
  }

  const targetOptions = elementsTree.getFilteredElementsByTypes([NodeType.command, NodeType.externalSystem]);
  const eventSchema = props.ctx.event ? getEventMetadata(props.ctx.event).schema || new Schema({}) : new Schema({});

  return <form className='ui form' onSubmit={handleSubmit}>
    <div className={'field'}>
      {<Button basic={true} icon={showDependencies? 'minus' : 'add'} labelPosition="left" size="mini"
               content={props.t('insp.cody_wizard.step_policy.manage_dependencies') + ` (${Object.keys(dependencies).length})`} color="blue" className="text"
               onClick={() => setShowDependencies(!showDependencies)}
               disabled={!selectedPolicy}/>}
    </div>
    <div className={(showDependencies? 'slidedown' : 'slideup') + ' smooth'} >
      <EditorDivider content={props.t('insp.cody_wizard.step_policy.dependencies_label') as string} />
      <DependenciesEditor boardId={props.ctx.board.uid}
                          sessionId={selectedPolicy ? selectedPolicy.getId() : undefined }
                          dependencies={dependencies}
                          messageSchema={eventSchema}
                          autofocus={false}
                          readonly={!!(selectedPolicy && !selectedPolicy.isEnabled())}
                          onDependenciesChanged={handleDependenciesConfigChanged}
      />
      <CodySuggestButton hasSuggestions={!!(selectedPolicy && selectedPolicy.getSources().filter(s => [NodeType.document, NodeType.externalSystem].includes(s.getType())).count() > 0)}
                         onSuggest={handleSuggestDependencies}
      />
    </div>
    <EditorDivider content={props.t('insp.cody_wizard.step_policy.rules_label') as string} />
    <MultipleNodeSelect selected={policyTargets}
                        nodeOptions={targetOptions}
                        placeholder={props.t('insp.cody_wizard.step_policy.targets_placeholder') as string}
                        uniqueName={true}
                        onChange={handlePolicyTargetsChanged}
                        disabled={!selectedPolicy || !selectedPolicy.isEnabled()} />
    <PolicyRulesEditor rules={metadata.rules || suggestTriggerRules(policyTargets, metadata, props.ctx)}
                       boardId={props.ctx.board.uid}
                       elementId={selectedPolicy ? selectedPolicy.getId() : ''}
                       sessionId={selectedPolicy ? selectedPolicy.getId() : undefined }
                       policyTargets={policyTargets}
                       dependencies={dependencies}
                       onRulesChanged={handleRulesChanged}
                       autofocus={autoFocusPolicyRules}
                       ctx={props.ctx}
                       readonly={!!(selectedPolicy && !selectedPolicy.isEnabled())}
    />
    <div style={{marginTop: '100px'}} />
  </form>
};

export default withNamespaces()(Policy);

export const suggestPolicyName = (ctx: ImmutablePolicyContext): string => {
  let name = 'On ';

  if(ctx.event) {
    name += ctx.event.getName();
  }

  return name;
}


export const suggestTriggerRules = (policyTargets: List<Node>, metadata: PolicyMetadata, ctx: ImmutablePolicyContext): Rule[] => {
  const rules: Rule[] = metadata.rules ? [...metadata.rules] : [];

  policyTargets.forEach(t => {
    if(t.getType() === NodeType.command) {
      const cmdFQCN = fqcn(t, ctx.board.codyEngineServiceName());
      if(!hasTriggerCommandRule(cmdFQCN, rules)) {
        rules.push({
          rule: "always",
          then: {
            trigger: {
              command: fqcn(t, ctx.board.codyEngineServiceName()),
              mapping: "event"
            }
          }
        })
      }
    }
  })

  return rules;
}

const hasTriggerCommandRule = (command: string, rules: Rule[]): boolean => {
  for (const rule of rules) {
    if(isThenTriggerCommand(command, rule.then)) {
      return true
    }

    if(isExecuteRules(rule.then) && hasTriggerCommandRule(command, rule.then.execute.rules)) {
      return true;
    }

    if(isIfConditionRule(rule) || isIfNotConditionRule(rule)) {
      if(rule.else && isThenTriggerCommand(command, rule.else)) {
        return true;
      }
    }
  }

  return false;
}

const isThenTriggerCommand = (command: string, then: ThenType): boolean => {
  if(isTriggerCommand(then) && then.trigger.command === command) {
    return true
  }

  if(isForEach(then) && isThenTriggerCommand(command, then.forEach.then)) {
    return true;
  }

  return false;
}

const updateTargetsOnGraph = (policy: Node, ctx: ImmutablePolicyContext, targets: List<Node>, graph: Graph): boolean => {
  const existingTargets = ctx.policyTargets;
  const existingTargetIds = existingTargets.map(dep => dep.getId());
  const changedTargetIds = targets.map(dep => dep.getId());

  const targetsToDelete = existingTargets.filter(dep => !changedTargetIds.contains(dep.getId()));
  const targetsToAdd = targets.filter(dep => !existingTargetIds.contains(dep.getId()));

  targetsToDelete.forEach(t => graph.deleteNode(t));

  const lastExistingTarget = existingTargets.last(undefined);
  let anchorNode = lastExistingTarget || null;
  let position = NextToPosition.below;
  let margin: number | {x: number, y: number} = DEFAULT_CONNECT_MARGIN;

  if(graph.isEventModelingEnabled()) {
    position = NextToPosition.right;

    if(!anchorNode) {
      anchorNode = policy;
      position = NextToPosition.belowRight;
      const uiToApiMarign = ctx.sliceApiLabel
        ? ctx.sliceApiLabel.getGeometry().y - policy.getGeometry().y - DEFAULT_CARD_HEIGHT
        : SLICE_LANE_MARGIN;
      margin = {x: DEFAULT_CONNECT_MARGIN, y: uiToApiMarign};
    }
  } else {
    if(!anchorNode) {
      anchorNode = policy;
      position = NextToPosition.right;
    }
  }


  targetsToAdd.forEach(t => {
    const newTarget = makeNodeFromJs({
      id: graph.createCellId(),
      type: t.getType(),
      name: t.getName(),
      metadata: t.getMetadata(),
    });

    graph.addNodeNextToAnother(newTarget, anchorNode as Node, position, margin);
    graph.connectNodes(policy, newTarget);
    markAsConnected(newTarget, graph);
    anchorNode = graph.getNode(newTarget.getId());
    position = NextToPosition.below;
  })

  return targetsToAdd.count() > 0 || targetsToDelete.count() > 0;
}
