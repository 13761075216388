import {JSONSchema7} from "json-schema";
import {ImmutableDDDWizardContext} from "../../../../service/cody-wizard/context/ddd-action";
import {Schema} from "../../../../service/cody-wizard/schema/schema";
import {makeAssignVariable, makeDefinitions, withSuggestionPropMapping} from "./rule-engine-definitions";

export const makeEventApplyRulesSchema = (eventSchema: Schema, informationSchema: Schema, ctx: ImmutableDDDWizardContext): string => {
  const schema: JSONSchema7 = {
    type: "array",
    items: {
      $ref: "#/definitions/rule_definition"
    },
    definitions: withSuggestionPropMapping(
      makeDefinitions(
        makeAssignVariable('information')
      ),
      informationSchema.getObjectProperties(),
      ['information', 'event', 'meta'],
      mappingSuggestions(eventSchema)
    )
  }

  return JSON.stringify(schema);
}

const mappingSuggestions = (eventSchema: Schema): string[] => {
  const suggestions: string[] = ['meta.user.userId'];

  eventSchema.getObjectProperties().forEach(prop => {
    suggestions.push(`event.${prop}`);
  })

  return suggestions;
}
