import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {makeOrganizationSelector} from "../selectors/selectOrganization";

type Command = ActionType<typeof Action.Command.removeUserFromOrganization>

function* handleRemoveUserFromOrganization(command: Command) {
    const {response, error} = yield call(Action.Api.removeUserFromOrganization, command.payload.organizationId, command.payload.userId);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not remove user from organization.'));
    }

    if(response) {
        const organization = yield select(makeOrganizationSelector(command.payload.organizationId));

        yield put(Action.Event.userRemovedFromOrganization(organization.removeUser(command.payload.userId)));

        yield put(NotifyAction.Command.info('User removed', 'The user is no longer member of the organization.'));
    }
}

export function* removeUserFromOrganization() {
    while (true) {
        const command: Command = yield take([
            Action.Type.REMOVE_USER_FROM_ORGANIZATION
        ]);

        yield fork(handleRemoveUserFromOrganization, command);
    }
}
