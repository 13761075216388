import * as React from 'react';
import {Icon, Label} from "semantic-ui-react";
import {IconSizeProp} from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import {Palette} from "../../theme/palette";
import {HistoryCellType} from "../model/HistoryEntry";

interface OwnProps {
    cellType: HistoryCellType;
    size?: IconSizeProp | 'normal';
    title?: string;
    className?: string;
}

type CellIconProps = OwnProps;

const CellIcon = (props: CellIconProps) => {
    let size: IconSizeProp | undefined;

    if(props.size !== 'normal') {
        size = props.size || 'large';
    }

    switch (props.cellType) {
        case HistoryCellType.event:
        case HistoryCellType.command:
        case HistoryCellType.role:
        case HistoryCellType.projection:
        case HistoryCellType.aggregate:
        case HistoryCellType.document:
        case HistoryCellType.policy:
        case HistoryCellType.hotSpot:
        case HistoryCellType.externalSystem:
        case HistoryCellType.ui:
            return <Icon name='sticky note' size={size} style={{color: Palette.stickyColors[props.cellType]}} title={props.title} className={props.className} />;
        case HistoryCellType.icon:
            return <Icon name='heart' size={size} title={props.title} className={props.className} />;
        case HistoryCellType.image:
            return <Icon name='image' size={size} title={props.title} className={props.className} />;
        case HistoryCellType.feature:
            return <Icon name="square" size={size} style={{Color: Palette.stickyColors.feature}} title={props.title} className={props.className} >
                <span style={{
                    position: "absolute",
                    marginTop: size? "0.17em" : "0.03em",
                    marginLeft: "-0.84em",
                    fontFamily: 'sans-serif',
                    fontSize: '0.8em',
                    color: 'white',
                }}>F</span>
            </Icon>;
        case HistoryCellType.slice:
            return <Icon name="square" size={size} style={{Color: Palette.stickyColors.feature}} title={props.title} className={props.className} >
                <span style={{
                    position: "absolute",
                    marginTop: size? "0.17em" : "0.03em",
                    marginLeft: "-0.86em",
                    fontFamily: 'sans-serif',
                    fontSize: '0.8em',
                    color: 'white',
                }}>S</span>
            </Icon>;
        case HistoryCellType.swimlanes:
            return <Icon name="square" size={size} style={{Color: Palette.stickyColors.feature}} title={props.title} className={props.className} >
                <span style={{
                    position: "absolute",
                    marginTop: size? "0.17em" : "0.03em",
                    marginLeft: "-0.86em",
                    fontFamily: 'sans-serif',
                    fontSize: '0.8em',
                    color: 'white',
                }}>S</span>
            </Icon>;
        case HistoryCellType.boundedContext:
            return <Icon name="square" size={size} style={{Color: Palette.stickyColors.feature}} title={props.title} className={props.className}>
                <span style={{
                    position: "absolute",
                    marginTop: size? "0.17em" : "0.03em",
                    marginLeft: "-0.89em",
                    fontFamily: 'sans-serif',
                    fontSize: '0.8em',
                    color: 'white',
                }}>C</span>
            </Icon>;
        case HistoryCellType.multiple:
            return <Icon name="sitemap" size={size} style={{fontSize: '1.25em'}} title={props.title} className={props.className} />;
        case HistoryCellType.edge:
            return <Icon size={size} title={props.title} className={props.className}><img
                style={{width: '1em', height: '1em'}}
                src={`data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="${encodeURIComponent(Palette.stickyColors.edge)}" d="M476 480H324a36 36 0 0 1-36-36V96h-96v156a36 36 0 0 1-36 36H16a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h112V68a36 36 0 0 1 36-36h152a36 36 0 0 1 36 36v348h96V260a36 36 0 0 1 36-36h140a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H512v156a36 36 0 0 1-36 36z"></path></svg>`}/>
            </Icon>;
        case HistoryCellType.textCard:
            return <Icon name="square" size={size} style={{color: Palette.stickyColors.textCard}} title={props.title} className={props.className}>
                <span style={{
                    position: "absolute",
                    marginTop: "0.17em",
                    marginLeft: "-0.84em",
                    fontFamily: 'sans-serif',
                    color: '#494949',
                    fontSize: '0.8em'
                }}>
                    T
                </span>
            </Icon>;
        case HistoryCellType.freeText:
            return <Icon name="align center" size={size} title={props.title} className={props.className} />;
        default:
            return <Icon name='square' size={size} title={props.title} className={props.className} />;
    }
};

export default CellIcon;
