import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Route} from "react-router";
import BoardTreeView from "../../InspectioBoards/components/BoardTreeView";
import * as Routes from "../../routes";
import {makeGetCurrentBoardQuota} from "../../User/selectors/currentUser";
import AddBoard from "./AddBoard";
import AddTeam from "./AddTeam";
import BoardList from "./BoardList";
import BoardOverview from "./BoardOverview";
import Feedback from "./Feedback";
import ImportBoard from "./ImportBoard";
import Logo from "./Logo";
import Search from "./Search";
import TeamList from "./TeamList";
import TeamOverview from "./TeamOverview";
import Updates from "./Updates";
import Upgrade from "./Upgrade";
import Organization from "./Organization";
import {makeActiveUserOrganizationInfoSelector} from "../../Organization/selectors/selectUserOrganizationInfo";

interface OwnProps {

}

type OverviewSidebarProps = OwnProps & WithNamespaces;

const OverviewSidebar = (props: OverviewSidebarProps) => {

    const boardQuota: number = useSelector(makeGetCurrentBoardQuota());
    const activeOrganization = useSelector(makeActiveUserOrganizationInfoSelector());

    return <>
        <Logo collapsed={false} />
        <Organization />
        <Search />
        <ImportBoard />
        <AddBoard />
        <BoardOverview />
        <BoardList />
        {boardQuota < 2 && activeOrganization && activeOrganization.explicitOrganization && !activeOrganization.userIsGuest && (
            <div className="item">
                <div className="ui icon negative message">
                    <i className="warning icon" />
                    <div className="content">
                        <div className="header">
                            {boardQuota === 0
                                ? props.t('insp.sidebar.no_boards_left_warning')
                                : props.t('insp.sidebar.boards_left_warning', { board_count: boardQuota })
                            }
                        </div>
                        <p>{props.t('insp.sidebar.boards_left_warning_upgrade')}</p>
                    </div>
                </div>
            </div>
        )}
        <AddTeam />
        <TeamOverview />
        <TeamList />
        <Updates />
        <Feedback/>
    </>;
};

export default withNamespaces()(OverviewSidebar);
