import * as React from 'react';
import {Button} from "semantic-ui-react";
import {WizardContext} from "../../../service/cody-wizard/context/wizard-context";
import {stepHeader} from "../../../service/cody-wizard/step/step-header";
import {WizardStep} from "../WizardModal";
import {withNamespaces, WithNamespaces} from "react-i18next";

interface OwnProps {
  activeStep: WizardStep | null;
  forcedPreviousStep?: WizardStep;
  ctx: WizardContext;
  onPreviousStep: (step: WizardStep) => void;
}

type PreviousStepProps = OwnProps & WithNamespaces;

let lastActiveStep: WizardStep | null = null;

const PreviousStep = (props: PreviousStepProps) => {
  let prevStep = props.forcedPreviousStep || (props.activeStep ? props.ctx.previousStep(props.activeStep, props.ctx) : null);

  if(!prevStep && props.activeStep && props.activeStep === 'runCody' && lastActiveStep) {
    prevStep = lastActiveStep;
  }

  if(props.activeStep !== 'runCody') {
    lastActiveStep = props.activeStep;
  }


  if(!prevStep) {
    return <></>
  }

  return <Button icon="left chevron"
                 labelPosition="left"
                 basic={true}
                 className="noborder rightborder"
                 content={stepHeader(prevStep, props.t)}
                 onClick={() => props.onPreviousStep(prevStep!)}
  />
};

export default withNamespaces()(PreviousStep);
