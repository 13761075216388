import {fromJS} from "immutable";
import { combineReducers } from 'redux-immutable';
import {ActionType} from "typesafe-actions";
import * as Events from "../actions/events";
import {saveTeamReducer, TeamsState} from "./applyTeamEvents";
import {saveTeamInvitationReducer, TeamInvitationState} from "./applyTeamInvitationEvents";

export type InspectioTeamEvent = ActionType<typeof Events>;

export interface InspectioTeamsState {
    Team: TeamsState,
    TeamInvitation: TeamInvitationState,
}

export const inspectioTeamsReducer = combineReducers<InspectioTeamsState, InspectioTeamEvent>({
    Team: saveTeamReducer,
    TeamInvitation: saveTeamInvitationReducer,
}, () => fromJS({
    Team: undefined,
    TeamInvitation: undefined
}));