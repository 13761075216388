import * as React from 'react';
import {useEffect, useState} from "react";
import {WithNamespaces, withNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ButtonGroup, ListItem} from "semantic-ui-react";
import SecondaryButton from "../../../core/components/SecondaryButton";
import {isSuccess} from "../../../core/validation/either";
import * as UserModule from "../../../User";
import UserAvatar from "../../../User/components/UserAvatar";
import {createAnonymous, IioUserInfo} from "../../../User/model/IioUserInfo";
import * as Action from "../../actions";
import {OrganizationInvitation, Status} from "../../model/OrganizationInvitation";
import {makeOrganizationInvitationSelector} from "../../selectors/selectOrganizationInvitation";
import {Organization} from "../../model/Organization";

interface OwnProps {
    invitation: OrganizationInvitation,
    organization: Organization,
}

type OpenOrganizationInvitationProps = OwnProps & WithNamespaces;

const OpenOrganizationInvitation = (props: OpenOrganizationInvitationProps) => {

    const [userInfo, setUserInfo] = useState(createAnonymous());
    const [isResending, setIsResending] = useState(false);
    const [isRevoking, setIsRevoking] = useState(false);
    const [isRevoked, setIsRevoked] = useState(false);
    const cachedInvitation = useSelector(makeOrganizationInvitationSelector(props.invitation.invitationId));
    const dispatch = useDispatch();

    useEffect(() => {
        UserModule.Action.Api.getInspectioUserInfoByEmail(props.invitation.invitedUserEmail).then(({response, error}) => {
            if(response) {
                const result = UserModule.IioUserInfo.createIioUserInfoFromServerData(response.data);

                if(isSuccess(result)) {
                    setUserInfo(result.value);
                }
            }
        })
    }, [props.invitation.invitedUserEmail]);

    useEffect(() => {
        if(isResending) {
            setIsResending(false);
        }

        if(isRevoking && cachedInvitation && cachedInvitation.status === Status.revoked) {
            setIsRevoked(true);
        }
    }, [cachedInvitation]);

    const handleResendClick = () => {
        setIsResending(true);
        dispatch(Action.Command.resendUserOrganizationInvitation(props.organization.uid, props.invitation.invitationId, props.invitation.invitedUserEmail));
    };

    const handleRevokeClick = () => {
        setIsRevoking(true);
        dispatch(Action.Command.revokeUserOrganizationInvitation(props.organization.uid, props.invitation.invitationId, props.invitation.invitedUserEmail));
    }

    if(isRevoked) {
        return <></>;
    };

    return <ListItem>
        <UserAvatar user={userInfo} size="mini" />
        <span style={{paddingLeft: "10px"}}>{props.invitation.invitedUserEmail}</span>
        <ButtonGroup floated="right">
            <SecondaryButton content={props.t('app.form.resend')} icon="paper plane" loading={isResending} onClick={handleResendClick} />
            <SecondaryButton content={props.t('app.form.revoke')} icon="delete" loading={isRevoking} onClick={handleRevokeClick} />
        </ButtonGroup>
    </ListItem>
};

export default withNamespaces()(OpenOrganizationInvitation);
