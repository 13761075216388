import {action} from "typesafe-actions";
import * as ApiAction from '../../api';
import {Email, UserId} from "../model/UserInfo";
import {FETCH_USER, LOAD_IIO_USERS_INFO, SERVER_CONTEXT} from './constants';

export const fetchUser = (userId: string) => {
    return ApiAction.Action.sendHttpRequest<{}>(ApiAction.MessageBox.getMessageBoxRequestConfig(
        SERVER_CONTEXT + 'User',
        {userId},
    ), FETCH_USER);
};

export const loadUsersInfo = (userIds: UserId[]) => action(LOAD_IIO_USERS_INFO, {userIds});
