import {Node, NodeType} from "../../../model/Graph";
import {List} from "immutable";

export const getSingleSourceOfType = (node: Node, type: NodeType, allowMultiple?: boolean, includeChildren?: boolean): Node | null => {
  let sources = node.getSources().filter(s => s.getType() === type);

  if(includeChildren) {
    node.children().forEach(child => {
      const childSource = getSingleSourceOfType(child, type, allowMultiple, true);
      if(childSource) {
        sources = sources.push(childSource);
      }
    })
  }

  if(sources.count() === 0) {
    return null;
  }

  if(sources.count() === 1 || allowMultiple) {
    return sources.toArray()[0];
  }

  return null;
}

export const getSingleTargetOfType = (node: Node, type: NodeType, allowMultiple?: boolean, includeChildren?: boolean): Node | null => {
  let targets = node.getTargets().filter(t => t.getType() === type);

  if(includeChildren) {
    node.children().forEach(child => {
      const childTarget = getSingleTargetOfType(child, type, allowMultiple, true);
      if(childTarget) {
        targets = targets.push(childTarget)
      }
    })
  }

  if(targets.count() === 0) {
    return null;
  }

  if(targets.count() === 1 || allowMultiple) {
    return targets.toArray()[0];
  }

  return null;
}

export const getSourcesOfType = (node: Node, type: NodeType, includeChildren?: boolean): Node[] => {
  let sources = List<Node>();

  if(includeChildren) {
    node.children().forEach(child => sources = sources.merge(getSourcesOfType(child, type, includeChildren)));
  }


  return sources.merge(node.getSources().filter(s => s.getType() === type)).toArray();
}

export const getTargetsOfType = (node: Node, type: NodeType, includeChildren?: boolean): Node[] => {
  let targets = List<Node>();

  if(includeChildren) {
    node.children().forEach(child => targets = targets.merge(getTargetsOfType(child, type, includeChildren)));
  }

  return targets.merge(node.getTargets().filter(t => t.getType() === type)).toArray();
}
