import * as React from 'react';
import {Container, Grid, MessageHeader} from "semantic-ui-react";
import UpdateListEntry from "./UpdateListEntry";

export const UpdateList = [
  {
    date: '2024-07-10',
    content: <>
      <MessageHeader>Cody goes Event Modeling first</MessageHeader>
      <p>
        The next major update has landed in prooph board. The whole Cody specific design and configuration as well as Cody Play is now focused on an Event Modeling first approach.
        This means, that you can freely design your software using Event Modeling and in a second step make it work in Cody Play. No more design limitations to make Cody happy.
        It can handle a whole new bunch of scenarios like information projections, pure command handlers, aggregateless events, and more. This update also marks the end of the Cody beta phase. We're going to work on the Cody Play tutorial and wiki next,
        so that you get detailed instructions of how to develop prototypes and software with it. Stay tuned!
      </p>
    </>
  },
  {
    date: '2024-05-19',
    content: <>
      <MessageHeader>Laser Focus on Event Modeling</MessageHeader>
      <p>
        Three months ago we started our journey to shift the primary focus of prooph board towards Event Modeling. The first update introduced Slices, which worked similar to Feature boxes, but had Swimlanes built-in. With this update, we are moving Swimlanes into a dedicated element (available in sidebar) and bring the feature boxes back. The Swimlanes element is similar to a Context box and is the container for an entire Event Model (or part of it). With the feature boxes back in the game, you can mark Vertical Slices of the Event Model.
      </p>
    </>
  },
  {
    date: '2024-03-04',
    content: <>
      <MessageHeader>Draggable Tree View Elements</MessageHeader>
      <p>
        You can now drag elements from the Tree View in the left sidebar on the board. Dragged elements are copied so
        you're not moving the original element. This is very useful when you want to reuse an element in another place.
        Especially events and information cards are often reused across the board.
      </p>
    </>
  },
  {
    date: '2024-02-25',
    content: <>
      <MessageHeader>Event Modeling Mode</MessageHeader>
      <p>
        prooph board supports <a href="https://eventmodeling.org/posts/what-is-event-modeling/" target="_blank">Event
        Modeling</a> from day one, but with this new update we are shifting focus, and make Event Modeling the
        recommended format on prooph board. For this, an Event Modeling Mode has been introduced. It's activated by
        default for all new boards. You can activate the mode for an existing board in the board settings. In Event
        Modeling mode feature frames become "Slices" with typical Event Modeling swim lanes. Cody suggestions are also
        tuned to place cards in the correct lanes. Cody looks for slices and only provides suggestions for cards that
        are in a Slice. The card types itself are the same and Cody Play and Cody Engine continue to work as before.
        With the next updates we'll make a few more backwards compatible changes to further align with ideas from Event
        Modeling. Tell us if you like or don't like the direction we are heading to! We hope, you'll jump on the Event
        Modeling train and do the journey together with us!
      </p>
    </>
  },
  {
    date: '2023-11-20',
    content: <>
      <MessageHeader>Playshots</MessageHeader>
      <p>
        So far, it was only possible to save Cody Play in the browser's local storage. With this update, Cody Play
        snapshots (short Playshots) are saved on the server (only SaaS version). You can start Cody Play from a Playshot
        listed in the new Cody Play dialog. The dialog can be accessed from the prooph board top menu.
      </p>
    </>
  },
  {
    date: '2023-11-17',
    content: <>
      <MessageHeader>Collaborative Schema Design</MessageHeader>
      <p>
        When building software systems, data structures always play an important role. Cody reads the schema of a data
        structure from the metadata of Information/Command/Event Cards. You can define the schema in the metadata
        sidebar. That works good when working alone on an Event Map. In an Event Storming session it is better to use
        Information Cards for all properties of a data structure. This way, it is easier to collaborate as a group. Now
        you can combine both approaches. First brainstorm with Information Cards, then drag & drop them on Commands,
        Events or other Information Cards to let Cody fill the schema from dropped Information Cards.
      </p>
    </>
  },
  {
    date: '2023-11-16',
    content: <>
      <MessageHeader>Disable Cody Suggestions</MessageHeader>
      <p>
        Cody Suggestions are useful for Design-Level Event Storming. Cody helps you to prepare the Event Map for Cody
        Play or Cody Engine. But it can get in your way on architecture or high-level boards. Therefor, we added an
        option to deactivate Cody Suggestions. You can find the option in the board settings.
      </p>
    </>
  },
  {
    date: '2023-10-06',
    content: <>
      <MessageHeader>Cody Play</MessageHeader>
      <p>
        We're striving for instant feedback-loops to avoid hand overs at all costs. To achieve that goal, a browser-only
        version of Cody Engine is now available. You can use it for ultra rapid prototyping. No installation needed, no
        servers, nothing that might get in the way between you and the feedback-loop. It's hosted on <a
        href="https://play.prooph-board.com" target="cody_play">https://play.prooph-board.com</a>. prooph board can
        connect to Cody Play by using <strong>/connect play</strong> in the Cody Console. However, the easiest way is to
        run Cody from the Cody Wizard (modal or metadata sidebar). You'll find a new <strong>run on:</strong> dropdown
        that let's you select the Cody environment. The default setting is <strong>auto</strong>, which means that Cody
        Wizard first tries to connect to a locally running Cody Engine and if that fails switches to Cody Play.
      </p>
    </>
  },
  {
    date: '2023-09-23',
    content: <>
      <MessageHeader>
        improophed Cody Wizard (beta)
      </MessageHeader>
      <p>
        We ran a couple of design sessions using the new Cody Wizard and while it is working great, we noticed one
        drawback: the Cody Wizard forces you to leave the collaboration space and switch to a configuration-driven
        workflow. That's not what we aim for. On prooph board it should be possible to design software together. Hence,
        we redesigned the Cody Wizard, so that it integrates seamlessly into the collaboration workflow. You'll notice
        that Cody appears on the collaboration space to suggest Cards. Cody also found a way into the toolbar. A click
        will open the metadata sidebar, which has a new "Cody" tab. Everything you could do in the Cody Wizard is now
        possible as a combination of Cody suggestions on the collaboration space + settings in the Cody tab.
      </p>
    </>
  },
  {
    date: '2023-08-21',
    content: <>
      <MessageHeader>
        Element Activity
      </MessageHeader>
      <p>
        You might know the board activity sidebar, that you can open from the board top menu. It shows the history of
        each action done on the board. While this is useful to get an overview of what happened, it's not that easy to
        find actions related to a specific element. Who wrote that card and when? Now you can answer such questions with
        the new "Activity" tab in the metadata sidebar. Select a card, open the sidebar from the top or context menu and
        switch to the tab to get activity filtered by the selected card.
      </p>
    </>
  },
  {
    date: '2023-07-16',
    content: <>
      <MessageHeader>
        Cody wizard (beta)
      </MessageHeader>
      <p>
        prooph board and Cody are a powerful combination for code assistance. With <a
        href="https://github.com/proophboard/cody-engine" target="_blank">Cody Engine</a> we push the limits of Cody's
        capabilities to a new level. Cody Engine is a ready-to-use, open source application stack to support
        cross-functional teams in product development. And the new integrated "Cody Wizard" is the counterpart on prooph
        board to boost Design-Level Event Storming and prepare everything for Cody to generate fully working application
        source code. You can start the wizard by right clicking on a card on the board and selecting "Cody Wizard" from
        the context menu. The wizard is available as a beta version at the moment. We're adding more features in the
        next weeks and document everything.
      </p>
    </>
  },
  {
    date: '2023-02-26',
    content: <>
      <MessageHeader>
        Tagging
      </MessageHeader>
      <p>
        Tagging cards and other elements is now possible by prefixing words with <strong>#</strong>. Tags are detected
        automatically and added to the element. They can be used in the Tree-View search to filter elements and also
        Cody can make use of them. More about tagging is documented in the <a
        href="https://wiki.prooph-board.com/board_workspace/Cards.html#tagging" target="_blank">wiki</a>.
      </p>
    </>
  },
  {
    date: '2022-07-27',
    content: <>
      <MessageHeader>
        Anyone Permissions
      </MessageHeader>
      <p>
        We've added a new permissions level that allows you to grant access to a board to all organization members or
        even anyone with a valid prooph board account. More about all available permission levels is documented in
        the <a href="https://wiki.prooph-board.com/access_management/Access-Rights.html" target="_blank">wiki</a>.
      </p>
    </>
  },
  {
    date: '2022-04-04',
    content: <>
      <MessageHeader>
        Sort & Filter Overview
      </MessageHeader>
      <p>
        The board overview can now be sorted by name, created at and last modified date. You can change sort direction
        by clicking on the sort icon. A team dropdown lets you quickly filter all boards of a team. Your sort and filter
        settings are stored in the browser's local storage. Additionally, board search supports tags now. Add tags to
        existing boards on the board edit page or in the advanced settings while adding a new board.
      </p>
    </>
  },
  {
    date: '2022-01-31',
    content: <>
      <MessageHeader>
        Rearranged Editing Toolbar
      </MessageHeader>
      <p>
        <ul>
          <li>Moved "font color" into main toolbar</li>
          <li>Moved "background color" into main toolbar</li>
          <li>Moved "clear formatting" into align menu</li>
          <li>Removed formatting menu</li>
          <li>Added a "wiki link" button</li>
        </ul>
      </p>
    </>
  },
  {
    date: '2021-11-20',
    content: <>
      <MessageHeader>
        More Styling Options
      </MessageHeader>
      <p>
        New styling options are available for advanced modelling and different workshop formats. The update includes:
      </p>
      <p>
        <ul>
          <li>Stickies
            <ul>
              <li>Change sticky type via context menu or shortcut (check context menu for available shortcuts)</li>
              <li>Align a group of stickies vertically and horizontally (context menu + shortcuts)</li>
            </ul>
          </li>
          <li>Contexts, Features, Textboxes
            <ul>
              <li>Change Style
                <ul>
                  <li>Ellipse</li>
                  <li>Triangle</li>
                  <li>Rhombus</li>
                  <li>Hexagon</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>Arrows
            <ul>
              <li>Change Color</li>
              <li>Dotted Style</li>
              <li>Thickness</li>
            </ul>
          </li>
          <li>Comic Style
            <ul>
              <li>Select a group of elements and turn "Comic Style" on or off in the context menu</li>
            </ul>
          </li>
          <li>Keyboard Controls
            <ul>
              <li>Move selected element with arrow keys (keep key pressed for continued move + SHIFT key pressed for
                even faster move)
              </li>
              <li>Resize selected element with CTRL/CMD + arrow keys (CTRL/CMD+SHIFT for bigger resize steps)</li>
              <li>ALT+SHIFT + arrow keys: Copy selected element in direction of arrow key and connect it
                <ul>
                  <li>You can model a complete flow via keyboard in combination with switch sticky type shortcuts</li>
                </ul>
              </li>
              <li>ENTER: Start text editing on selected sticky with</li>
              <li>ESC: Finish text editing</li>
              <li>CTRL/CMD+M: Start metadata editing</li>
              <li>ESC: pressed in metadata editor finishes editing and focuses cell on board again</li>
            </ul>
          </li>
        </ul>
      </p>
    </>
  },
  {
    date: '2021-11-19',
    content: <>
      <MessageHeader>
        Copy & Paste
      </MessageHeader>
      <p>
        Copy & Pasting prooph board content is now possible across browsers. Next time you paste something, prooph board
        will ask you for access to your system's clipboard. If you grant access, the feature gets activated. Please
        note: this security check is built into modern browsers. By default, JavaScript is not allowed to access the
        system clipboard without explicit permission.
      </p>
    </>
  },
  {
    date: '2021-11-12',
    content: <>
      <MessageHeader>
        Performance and Handling Updates
      </MessageHeader>
      <p>We sat down last week to benchmark and improve performance on lager prooph boards. We disabled a couple of fade
        in/out animations in favor of performance and added a little physics effect when moving a board around. The
        latter gives a more natural feeling to compensate the deactivated fade effects. The Lite Mode still gives you
        best performance in case your system is under heavy load, but you should less likely have a need to activate
        it.</p>
    </>
  },
  {
    date: '2021-11-02',
    content: <>
      <MessageHeader>
        Synchronized Timer
      </MessageHeader>
      <p>You'll find a new timer feature in the board top menu (click on the hour glass icon). Setting a timer enables
        it for all users on the current board. Everybody will see the time ticking and a red blinking timer, when the
        set time is over. This is especially useful for time-boxed collaboration sessions. The person who sets the timer
        has control over it (pause, stop, reset).</p>
    </>
  },
  {
    date: '2021-02-13',
    content: <>
      <MessageHeader>
        Direct Import
      </MessageHeader>
      <p>A new import functionality allows you to prepare a link that opens prooph board and imports a board fetched
        from an URL. This is especially useful for demo boards in combination with the new free version of prooph board.
        For example you could upload a board export on Github and prepare a link to Free Version like this: <a
          href="https://free.prooph-board.com/inspectio/boards/import/https%3A%2F%2Fraw.githubusercontent.com%2Fevent-engine%2Finspectio%2Fmaster%2Fassets%2FExample%2520Boards%2FThe%2520Picture.xml">https://free.prooph-board.com/inspectio/boards/import/https%3A%2F%2Fraw.githubusercontent.com%2Fevent-engine%2Finspectio%2Fmaster%2Fassets%2FExample%2520Boards%2FThe%2520Picture.xml</a>
      </p>
    </>
  },
  {
    date: '2021-01-18',
    content: <>
      <MessageHeader>
        Organization
      </MessageHeader>
      <p>We've added a new administrative layer called "Organization" to make user and board management easier. With an
        organization you can share your board and user quota with colleagues. They don't need to worry about
        limitations, can easily create boards and share them with members. <a
          href="https://wiki.prooph-board.com/access_management/Managing-an-Organization.html" target="_blank">Learn
          about the details</a>.</p>
    </>
  }
];

interface OwnProps {

}

type UpdatePageProps = OwnProps;

const UpdatePage = (props: UpdatePageProps) => {
  return (
    <Container>
      <Grid stackable={true} padded={true}>
        {UpdateList.map((update, updateIndex) => (
          <UpdateListEntry key={updateIndex} date={update.date}>
            {update.content}
          </UpdateListEntry>
        ))}
      </Grid>
    </Container>
  );
};

export default UpdatePage;
