import {List, Map} from 'immutable';
import {Reducer} from 'redux';
import {
    FILTER_ELEMENTS_TREE,
    UPDATE_BOARD_ELEMENTS_TREE
} from "../actions/constants";
import {ElementsTree} from "../model/ElementsTree";
import {InspectioBoardsAction} from './index';

export interface ElementsTreeState extends Map<string, ElementsTree>{

}

export const initialState: ElementsTreeState = Map<string, ElementsTree>();

const reducer: Reducer<ElementsTreeState, InspectioBoardsAction> = (state: ElementsTreeState = initialState, action: InspectioBoardsAction): ElementsTreeState => {
    switch (action.type) {
        case UPDATE_BOARD_ELEMENTS_TREE:
            const oldElementsTree = state.get(action.payload.boardId, new ElementsTree({elements: List()}));
            return state.set(action.payload.boardId, oldElementsTree.mergeTree(new ElementsTree({elements: action.payload.elements})));
        case FILTER_ELEMENTS_TREE:
            const oldFilteredTree = state.get(action.payload.boardId, new ElementsTree({elements: List()}));
            return state.set(action.payload.boardId, oldFilteredTree.set('filter', action.payload.filter).set('focus', action.payload.focus || false));
        default:
            return state;
    }
};

export {reducer as elementsTreeReducer};
