import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { ActionType } from 'typesafe-actions';
import * as Commands from '../actions/commands';
import { applyAuth, AuthState } from './applyAuth';

export type AuthAction = ActionType<typeof Commands>;

export interface InspectioAuthState {
    auth: AuthState
}

export const authReducer = combineReducers<InspectioAuthState, AuthAction>({
    auth: applyAuth,
}, () => fromJS({}));