/**
 * Specifies the current version of the insp_standalone IndexedDB. This should be incremented whenever the structure of
 * the DB has changed and a migration is required. Take a look at StandaloneIndexedDB::updateDatabaseToLatestVersion.
 * Note: This must be an integer!
 */
const DB_VERSION: number = 1

export const OBJECT_STORE_BOARDS = 'boards';
export const OBJECT_STORE_USER_SETTINGS = 'userSettings';

class StandaloneIndexedDB {
    private initializationPromise: Promise<void>;
    private db?: IDBDatabase;

    constructor() {
        this.initializationPromise = new Promise((resolve, reject) => {
            const openRequest = indexedDB.open('insp_standalone', DB_VERSION);

            openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                this.updateDatabaseToLatestVersion(openRequest.result, event.oldVersion);
            }

            openRequest.onerror = () => {
                reject('IndexedDB could not be initialized');
            }

            openRequest.onsuccess = () => {
                this.db = openRequest.result;
                resolve();
            };
        });
    }

    public async get(objectStore: string, key: string): Promise<any> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readonly');
            const request = await transaction.objectStore(objectStore).get(key);

            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject();
        });
    }

    public async getAll(objectStore: string): Promise<any[]> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readonly');
            const request = await transaction.objectStore(objectStore).getAll();

            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject();
        });
    }

    public async add(objectStore: string, data: object): Promise<void> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readwrite');
            const request = transaction.objectStore(objectStore).add(data);

            request.onsuccess = () => resolve();
            request.onerror = () => reject();
        });
    }

    public async put(objectStore: string, data: object): Promise<void> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readwrite');
            const request = transaction.objectStore(objectStore).put(data);

            request.onsuccess = () => resolve();
            request.onerror = () => reject();
        });
    }

    public async delete(objectStore: string, key: string): Promise<void> {
        await this.assertDBInitialized();

        return new Promise(async (resolve, reject) => {
            const transaction = this.db!.transaction(objectStore, 'readwrite');
            const request = transaction.objectStore(objectStore).delete(key);

            request.onsuccess = () => resolve();
            request.onerror = () => reject();
        });
    }

    public async assertDBInitialized(): Promise<void> {
        await this.initializationPromise;

        if (!this.db) {
            throw new Error('IndexedDB is not initialized');
        }
    }

    private updateDatabaseToLatestVersion(db: IDBDatabase, oldVersion: number): void {
        // tslint:disable-next-line
        console.log(`Starting upgrade for DB insp_standalone from version ${oldVersion} to ${DB_VERSION}`);

        /**
         * Specify what migrations need to be done for each version to the next one. Make sure to not use any "break"
         * statements since fallthrough is actually what we want here in order to handle larger version jumps.
         */
        switch (oldVersion) {
            case 0: // Initialization (migration from version 0 to 1)
                db.createObjectStore(OBJECT_STORE_BOARDS, { keyPath: 'boardId' });
                db.createObjectStore(OBJECT_STORE_USER_SETTINGS, { keyPath: 'key' });
        }
    }
}

export default StandaloneIndexedDB;
