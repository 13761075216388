import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import v4 from "uuid/v4";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {makeOrganizationSelector} from "../selectors/selectOrganization";

type Command = ActionType<typeof Action.Command.inviteUserToOrganization>

function* handleInviteUserToOrganization(command: Command) {
    const invitationId = v4();
    const {response, error} = yield call(Action.Api.inviteUserToOrganization, command.payload.organizationId, invitationId, command.payload.invitedUserEmail);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not invite user to organization.'));
    }

    if(response) {
        const organization = yield select(makeOrganizationSelector(command.payload.organizationId));

        yield put(Action.Event.userInvitedToOrganization(organization.inviteUser(invitationId, command.payload.invitedUserEmail)));

        yield put(NotifyAction.Command.info('User invited', 'The user was invited to the organization.'));
    }
}

export function* inviteUserToOrganization() {
    while (true) {
        const command: Command = yield take([
            Action.Type.INVITE_USER_TO_ORGANIZATION
        ]);

        yield fork(handleInviteUserToOrganization, command);
    }
}
