import {Record} from 'immutable';
import * as uuid from 'uuid';
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {UserId} from "../../User/model/UserInfo";
import {BoardId} from "./Board";

export type PermissionsId = string;
export interface Permissions {adminAccess: boolean; readAccess: boolean; writeAccess: boolean}

export const DEFAULT_PERMISSIONS = {adminAccess: false, readAccess: false, writeAccess: false}

export type BoardPermissionValidationError = string;

export const AGGREGATE_TYPE = 'BoardPermissionForUser';

export interface BoardPermissionForUserProps {
    uid: PermissionsId;
    aggregateType: AggregateType;
    permissions: Permissions;
    userId: UserId;
    boardId: BoardId;
}

export const defaultBoardPermissionProps: BoardPermissionForUserProps = {
    uid: '',
    aggregateType: AGGREGATE_TYPE,
    permissions: DEFAULT_PERMISSIONS,
    userId: '',
    boardId: '',
};

export const createBoardPermissionForUserFromServerData = (data: any): Either<BoardPermissionValidationError, BoardPermissionForUser> => {
    const validatedData: Either<string, any> = validate(data,
        [hasKey('permissionsId'), "permissionsId missing"],
        [hasKey('permissions'), "permissions missing in " + data.permissionsId],
        [hasKey('userId'), "userId missing in " + data.permissionsId],
        [hasKey('boardId'), "boardId missing in " + data.permissionsId],
    );

    if (isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new BoardPermissionForUser({
        uid: data.permissionsId,
        aggregateType: AGGREGATE_TYPE,
        permissions: data.permissions,
        userId: data.userId,
        boardId: data.boardId
    }));
};

export class BoardPermissionForUser extends Record(defaultBoardPermissionProps) implements BoardPermissionForUserProps, Aggregate {
    public constructor(data: BoardPermissionForUserProps) {
        if(data.uid === '') {
            data.uid = uuid.v4()
        }

        super(data);
    }
}
