import * as React from 'react';
import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useElementsTree} from "../../hooks/useElementsTree";
import {useGraph} from "../../hooks/useGraph";
import {BoardId} from "../../model/Board";
import {ActiveGraphElement} from "../../reducers/applyActiveGraphElementActions";
import {makeBoardByIdSelector} from "../../selectors/board";
import {dddActionFromNode, isDDDAction} from "../../service/cody-wizard/context/ddd-action";
import {suggestCommand} from "../../service/cody-wizard/context/ddd-action/cody-suggestions";
import {isFeatureContext} from "../../service/cody-wizard/context/feature-context";
import {isInformationContext} from "../../service/cody-wizard/context/information-context";
import {isPolicyContext, policyContextFromNode} from "../../service/cody-wizard/context/policy-context";
import {suggestPolicy} from "../../service/cody-wizard/context/policy-context/cody-suggestions";
import {isUiContext} from "../../service/cody-wizard/context/ui";
import {WizardContext} from "../../service/cody-wizard/context/wizard-context";
import {
  determineWizardContextAndStep
} from "../../service/cody-wizard/graph-suggestions/initialize-cody-graph-suggestions";
import Aggregate from "../CodyEngineWizard/Step/Aggregate";
import AnyVo from "../CodyEngineWizard/Step/AnyVo";
import DddCommand from "../CodyEngineWizard/Step/DddCommand";
import AggregateEvent from "../CodyEngineWizard/Step/DddEvent";
import Feature from "../CodyEngineWizard/Step/Feature";
import Policy from "../CodyEngineWizard/Step/Policy";
import PolicyCommand from "../CodyEngineWizard/Step/PolicyCommand";
import PolicyEvent from "../CodyEngineWizard/Step/PolicyEvent";
import SelectContext from "../CodyEngineWizard/Step/SelectContext";
import StateVO from "../CodyEngineWizard/Step/StateVO";
import Ui from "../CodyEngineWizard/Step/Ui";
import UnsupportedNode from "../CodyEngineWizard/Unsupported/UnsupportedNode";
import {EMPTY_CONTEXT, isSelectContextStep, WizardStep} from "../CodyEngineWizard/WizardModal";

interface OwnProps {
  boardId: BoardId;
  activeGraphElement: ActiveGraphElement;
  onUpdateMetadata: (value: string, force?: boolean) => void;
}

type MetadataCodyWizardProps = OwnProps;

const MetadataCodyWizard = (props: MetadataCodyWizardProps) => {
  const getBoardByIdSelector = useMemo(() => makeBoardByIdSelector(props.boardId), [props.boardId]);
  const board = useSelector(getBoardByIdSelector);
  const elementsTree = useElementsTree(props.boardId);
  const [graph, ] = useGraph();
  const [ctx, setCtx] = useState<WizardContext>(EMPTY_CONTEXT);
  const [activeStep, setActiveStep] = useState<WizardStep | null>(null);
  const node = graph.getNode(props.activeGraphElement.id)!;
  const readonly = !!props.activeGraphElement.locked;

  useEffect(() => {
    const result = determineWizardContextAndStep(node, board, graph);

    setCtx(result.ctx);
    setActiveStep(result.step);
  }, [props.activeGraphElement.id, props.activeGraphElement.metadata, props.boardId]);


  const unsupportedNode = !activeStep ? node : null;

  const handleCtxChanged = (changedCtx: WizardContext) => {
    if(isSelectContextStep(activeStep)) {
      if(isDDDAction(changedCtx)) {
        suggestCommand(changedCtx, graph);
        setCtx(dddActionFromNode(activeStep.node, board));
        setActiveStep('dddEvent');
      }

      if(isPolicyContext(changedCtx)) {
        suggestPolicy(changedCtx, graph);
        setCtx(policyContextFromNode(activeStep.node, board));
        setActiveStep('policyEvent');
      }
    } else {
      setCtx(changedCtx);
    }
  }

  const updateMetadata = (value: string, force?: boolean): void => {
    if(value !== node.getMetadata()) {
      props.onUpdateMetadata(value, force);
    }
  }

  return <>
    {unsupportedNode && <UnsupportedNode node={unsupportedNode} />}
    {!unsupportedNode
      && activeStep === 'state'
      && isDDDAction(ctx)
      && <StateVO ctx={ctx} mode="sidebar" readonly={readonly} onCtxChanged={handleCtxChanged} onUpdateMetadata={updateMetadata} />}
    {!unsupportedNode
      && activeStep === 'dddEvent'
      && isDDDAction(ctx)
      && <AggregateEvent ctx={ctx} mode="sidebar" readonly={readonly} onCtxChanged={handleCtxChanged} onUpdateMetadata={updateMetadata} />}
    {!unsupportedNode
      && activeStep === 'policyEvent'
      && isPolicyContext(ctx)
      && <PolicyEvent ctx={ctx} mode="sidebar" readonly={readonly} onCtxChanged={handleCtxChanged} onUpdateMetadata={updateMetadata} />
    }
    {!unsupportedNode
      && activeStep === 'aggregate'
      && isDDDAction(ctx)
      && <Aggregate ctx={ctx} mode="sidebar" readonly={readonly} onCtxChanged={handleCtxChanged} onUpdateMetadata={updateMetadata} />}
    {!unsupportedNode
      && activeStep === 'command'
      && (isDDDAction(ctx))
      && <DddCommand ctx={ctx} mode="sidebar" readonly={readonly} onCtxChanged={handleCtxChanged} onUpdateMetadata={updateMetadata} />}
    {!unsupportedNode
      && activeStep === 'command'
      && (isPolicyContext(ctx))
      && <PolicyCommand command={node} ctx={ctx} mode="sidebar" readonly={readonly} onCtxChanged={handleCtxChanged} onUpdateMetadata={updateMetadata} />}
    {!unsupportedNode
      && activeStep === 'ui'
      && (isDDDAction(ctx) || isUiContext(ctx))
      && <Ui ctx={ctx} mode="sidebar" readonly={readonly} onCtxChanged={handleCtxChanged} onUpdateMetadata={updateMetadata} />}
    {!unsupportedNode
      && activeStep === 'feature'
      && (isDDDAction(ctx) || isPolicyContext(ctx) || isFeatureContext(ctx))
      && <Feature ctx={ctx} mode="sidebar" readonly={readonly} onCtxChanged={handleCtxChanged} onUpdateMetadata={updateMetadata} />}
    {!unsupportedNode
      && activeStep === 'anyVO'
      && (isInformationContext(ctx))
      && <AnyVo ctx={ctx} mode="sidebar" readonly={readonly} onCtxChanged={handleCtxChanged} onUpdateMetadata={updateMetadata} />}
    {!unsupportedNode
      && activeStep === 'policy'
      && (isPolicyContext(ctx))
      && <Policy ctx={ctx} mode="sidebar" readonly={readonly} onCtxChanged={handleCtxChanged} onUpdateMetadata={updateMetadata} />
    }
    {!unsupportedNode
      && isSelectContextStep(activeStep)
      && <SelectContext ctx={ctx} mode="sidebar" readonly={readonly} contexts={activeStep.contexts} node={activeStep.node} onCtxChanged={handleCtxChanged} />
    }
  </>
};

export default MetadataCodyWizard;
