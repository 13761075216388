import {List, Record} from "immutable";
import {createSelector} from "reselect";
import {ApplicationState} from "../../reducer";
import {MODULE} from "../index";
import {BoardInvitation, BoardInvitationId} from "../model/BoardInvitation";

export const getBoardInvitationById = (state: Record<ApplicationState>, uid: BoardInvitationId): BoardInvitation | undefined =>
    state.getIn([MODULE, 'boardInvitations', uid]);

export const makeBoardInvitationSelector = (uid: BoardInvitationId): (state: Record<ApplicationState>) => BoardInvitation | undefined =>
    (state: Record<ApplicationState>) => getBoardInvitationById(state, uid);

export const boardInvitationListSelector = (state: Record<ApplicationState>, props: any): List<BoardInvitation> =>
    List(state.getIn([MODULE, 'boardInvitations']).toIndexedSeq().toArray()) || List();

export const makeOpenBoardInvitationListSelector = (boardId: string): (state: Record<ApplicationState>, props?: any) => List<BoardInvitation> => {
    return createSelector(
        [boardInvitationListSelector],
        (boardInvitations): List<BoardInvitation> =>  {
            return boardInvitations.filter(boardInvitation =>
                boardInvitation.boardId === boardId && boardInvitation.status === 'pending'
            );
        }
    )
};
