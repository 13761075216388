import {List} from "immutable";
import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {execForEach, execValidate, isFailure, isSuccess, pipe} from "../../core/validation/either";
import {isArray} from "../../core/validation/predicates";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {createStickyTemplateFromServerData} from "../model/StickyTemplate";

type Query = ActionType<typeof Action.Query.fetchBoardStickyTemplates>

function* handleFetchBoardStickyTemplates(query: Query) {
    const {response, error}: ResponseType = yield call(Action.Api.fetchBoardStickyTemplates, query.payload.boardId);

    if (error) {
        yield put(NotifyAction.Command.error("Request Error", "Failed to load sticky templates"));
    }

    if (response) {

        const result = pipe(
            response!.data,
            execValidate([isArray(), "Response data is not of type Array"]),
            execForEach((data: any) => {
                return createStickyTemplateFromServerData(data);
            }),
        );

        if (isFailure(result)) {
            // tslint:disable-next-line:no-console
            console.error("Failed to process sticky templates response from server", result.value, response.data);
            yield put(NotifyAction.Command.error('Request Error', 'Could not load sticky templates'));
        }

        if (isSuccess(result)) {
            const stickyTemplates = List(result.value);
            yield put(Action.Event.boardStickyTemplatesFetched(stickyTemplates));
        }
    }
}

export function* fetchBoardStickyTemplates() {
    while (true) {
        const query: Query = yield take([
            Action.Type.FETCH_BOARD_STICKY_TEMPLATES
        ]);

        yield fork(handleFetchBoardStickyTemplates, query);
    }
}
