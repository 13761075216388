import { AxiosResponse } from 'axios';
import {List} from "immutable";
import { map } from 'lodash';
import {call, delay, fork, put, select, take} from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import uuid from 'uuid';
import { ResponseType } from '../../api/util';
import * as Auth from '../../Auth';
import {loadUserTeams} from "../../InspectioTeams/sagas/loadUserTeams";
import { Action as NotifyAction } from '../../NotificationSystem';
import * as Organization from '../../Organization';
import { Action as UserAction } from '../../User';
import * as User from '../../User';
import { UserSelector } from '../../User';
import {loadUserSettings} from "../../User/saga/LoadUserSettingsFlow";
import * as Action from '../actions';
import {updateBoards} from '../actions/commands';
import {initialBoardListLoaded} from "../actions/events";
import {fetchUserBoards} from "../actions/queries";
import { BoardModel } from '../model';
import { BoardVersion } from '../model/BoardVersion';

type FlowAction = ActionType<typeof Auth.Action.Event.userAuthenticated> & ActionType<typeof fetchUserBoards>;

function* updateUserData(response: AxiosResponse ) {
    let user = yield select(UserSelector.currentUserSelector, undefined);
    user = user.set('quota', response.data.quota);
    user = user.set('teamQuota', response.data.teamQuota);
    user = user.set('signedIn', true);
    user = user.set('activeOrganization', response.data.activeOrganization || null);

    yield put(UserAction.Command.signIn(user));
}

function* loadUserBoards() {
    yield put(Action.Query.startFetchUserBoards());

    const {response, error}: ResponseType = yield call(Action.Api.fetchUserBoards);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not load boards'));
    }

    if(response) {
        const boards = map(response.data as any, (data: any) => new BoardModel.Board({
            uid: data.boardId,
            name: data.name,
            nameAbbreviation: data.nameAbbreviation,
            tags: List(data.tags),
            createdAt: data.createdAt,
            lastModified: data.lastModified,
            version: new BoardVersion(data.version),
            shared: data.shared,
            sharedWith: List(data.sharedWith),
            assignedTeams: List(data.assignedTeams),
            responsibleTeam: data.responsibleTeam || undefined,
            example: data.example || false,
            owner: data.ownerId,
            creator: data.creatorId,
            organization: data.organization,
            tempBoard: false,
            orgaMemberPermissions: data.orgaMemberPermissions,
            anyonePermissions: data.anyonePermissions,
            codySuggestEnabled: data.codySuggestEnabled,
            eventModelingEnabled: data.eventModelingEnabled,
        }));

        yield put(updateBoards(boards));
    }
}

function* onUserAuthenticated(action: FlowAction) {
    const {response, error}: ResponseType = yield call(User.Action.Api.getUser, action.payload.kc.tokenParsed!.sub!);

    if (error && error.status === 404) {
        const organizationId = uuid.v4();
        const installUserOrganization: ResponseType = yield call(Organization.Action.Api.installUserOrganization, organizationId);

        if (installUserOrganization.error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not initialize organization.'));
        }

        if (installUserOrganization.response) {
            // TODO error handling
            const getUserReponse: ResponseType = yield call(User.Action.Api.getUser, action.payload.kc.tokenParsed!.sub!);
            yield updateUserData(getUserReponse.response!);
        }
    } else {
        yield updateUserData(response!);
    }

    yield call(loadUserSettings);
    yield put(Organization.Action.Query.fetchUserOrganizations());
    yield delay(500);
    yield call(loadUserBoards);
    yield call(loadUserTeams);
    yield put(initialBoardListLoaded());
}

function* onFetchUserBoards() {
    yield loadUserBoards();
}

export function* fetchUserBoardsFlow() {
    while (true) {
        const action: FlowAction = yield take([
            Auth.Action.Type.USER_AUTHENTICATED,
            Action.Type.FETCH_USER_BOARDS,
        ]);

        switch (action.type) {
            case Auth.Action.Type.USER_AUTHENTICATED:
                yield fork(onUserAuthenticated, action);
                break;
            case Action.Type.FETCH_USER_BOARDS:
                yield fork(onFetchUserBoards);
                break;
        }

    }
}
