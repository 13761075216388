import * as React from 'react';

interface OwnProps {
    board: {
        link: string;
        name: string;
    };
}

type StandaloneExampleBoardCardProps = OwnProps;

const StandaloneExampleBoardCard = (props: StandaloneExampleBoardCardProps) => {
    return <div className="ui card event">
        <a href={props.board.link}>
            <div className="content">
                <div className="header"/>
                <div className="description">
                    <span style={{ width: '100%', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        {props.board.name}
                    </span>
                </div>
            </div>
        </a>
        <div className="extra">
            <span style={{color: "rgb(160, 156, 156)", fontSize: "0.9em"}}>Example</span>
        </div>
    </div>
};

export default StandaloneExampleBoardCard;
