import {Record} from 'immutable';
import * as uuid from "uuid";
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {UserId} from "../../User/model/UserInfo";
import {BoardId} from "./Board";

export const AGGREGATE_TYPE = 'BoardTimer';

export type BoardTimerId = string;
export type BoardTimerError = string;

export interface BoardTimerProps {
    uid: BoardTimerId;
    board: BoardId
    aggregateType: AggregateType;
    deadline: number;
    duration: number;
    moderator: UserId;
    pausedTime?: [number, number, number];
}

export const defaultBoardTimerProps: BoardTimerProps = {
    uid: '',
    board: '',
    aggregateType: AGGREGATE_TYPE,
    deadline: 0,
    duration: 0,
    moderator: '',
    pausedTime: undefined,
};

export const createBoardTimer = (board: BoardId, deadline: number, duration: number, moderator: UserId, pausedTime?: [number, number, number]): BoardTimer => {
    return new BoardTimer({
        uid: '',
        aggregateType: AGGREGATE_TYPE,
        board,
        deadline,
        duration,
        moderator,
        pausedTime
    })
}

export const createBoardTimerFromServerData = (data: any): Either<BoardTimerError, BoardTimer> => {
    const validatedData: Either<BoardTimerError, any> = validate(data,
        [hasKey('boardTimerId'), "boardTimerId missing"],
    );

    if (isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new BoardTimer({
        ...defaultBoardTimerProps,
        ...data,
        uid: data.boardTimerId,
    }));
};

export class BoardTimer extends Record(defaultBoardTimerProps) implements BoardTimerProps, Aggregate {
    public constructor(data: BoardTimerProps) {
        if (data.uid === '') {
            data.uid = uuid.v4()
        }

        super(data);
    }

    // @TODO add use case methods to change state
}
