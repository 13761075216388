import {action} from "typesafe-actions";
import {
    SET_CODY_CONSOLE_HEIGHT,
    SET_RIGHT_SIDEBAR_WIDTH,
    SET_SIDEBAR_WIDTH,
    TOGGLE_SIDEBAR_COLLAPSED
} from "./constants";

export const toggleSidebarCollapsed = (collapsed: boolean) => action(TOGGLE_SIDEBAR_COLLAPSED, {collapsed});
export const setSidebarWidth = (width: number) => action(SET_SIDEBAR_WIDTH, {width});
export const setRightSidebarWidth = (width: number) => action(SET_RIGHT_SIDEBAR_WIDTH, {width});
export const setCodyConsoleHeight = (height: number) => action(SET_CODY_CONSOLE_HEIGHT, {height});
