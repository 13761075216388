import {AxiosPromise} from "axios";
import {
    axiosApiPromise, boardImageDeletionApiPromise, boardImageReplacementApiPromise,
    boardImageUploadApiPromise,
    ResponseType
} from '../../api/util';
import * as TeamModel from "../../InspectioTeams/model/Team";
import {TeamId} from "../../InspectioTeams/model/Team";
import {OrganizationId} from "../../Organization/model/Organization";
import {UserModel} from "../../User";
import { BoardModel, BoardVersioning } from '../model';
import {BoardId, BoardXml} from "../model/Board";
import {GraphElementType} from "../model/BoardGraphElement";
import {BoardInvitationId} from "../model/BoardInvitation";
import {Permissions} from "../model/BoardPermissionForUser";
import {NodeType} from "../model/Graph";
import {TemplateSchema, TemplateType} from "../model/StickyTemplate";
import { SERVER_CONTEXT } from './constants';
import {List} from "immutable";
import {Playshot, validatePlayshot} from "../service/Cody/PlayServer";
import {PlayshotId} from "../model/PlayshotSummary";
import {isFailure} from "../../core/validation/either";

export const installOrganizationBoard = (
    organizationId: OrganizationId,
    boardId: BoardModel.BoardId,
    boardName: BoardModel.BoardName,
    boardAbbreviation: string,
    tags: string[],
    responsibleTeam?: TeamId,
    codySuggestEnabled = true,
    eventModelingEnabled = true
): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'InstallOrganizationBoard', {organizationId, boardId, boardName, boardAbbreviation, tags, responsibleTeam, codySuggestEnabled, eventModelingEnabled});
}

export const renameBoard = (boardId: BoardModel.BoardId, name: BoardModel.BoardName): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'RenameBoard', {boardId, name});
};

export const toggleCodySuggest = (boardId: BoardModel.BoardId, codySuggestEnabled: boolean): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'ToggleCodySuggest', {boardId, codySuggestEnabled});
}

export const toggleEventModeling = (boardId: BoardModel.BoardId, eventModelingEnabled: boolean): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'ToggleEventModeling', {boardId, eventModelingEnabled});
}

export const changeBoardAbbreviation = (boardId: BoardModel.BoardId, nameAbbreviation: string): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'ChangeBoardAbbreviation', {boardId, nameAbbreviation});
};

export const setBoardTags = (boardId: BoardModel.BoardId, tags: List<string>): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'SetBoardTags', {boardId, tags: tags.toJSON()});
};

export const changeResponsibleTeam = (boardId: BoardModel.BoardId, responsibleTeam?: TeamId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'ChangeResponsibleTeam', {boardId, responsibleTeam: responsibleTeam || null});
};

export const deleteBoard = (boardId: BoardModel.BoardId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'DeleteBoard', {boardId});
};

export const takeBoardSnapshot = (boardId: BoardModel.BoardId, boardVersion: BoardVersioning.BoardVersion, content: BoardModel.BoardXml) => {
    return axiosApiPromise(
        SERVER_CONTEXT + 'TakeBoardSnapshot',
        {boardId, boardVersion: {userId: boardVersion.userId, version: boardVersion.version}, content});
};

export const shareBoard = (boardId: BoardModel.BoardId, email: UserModel.Email, permissions?: Permissions): Promise<ResponseType> => {
    return axiosApiPromise(
        SERVER_CONTEXT + 'ShareBoardWithUser',
        {boardId, invitedUser: email, boardPermissions: (permissions || {writeAccess: true, readAccess: true, adminAccess: false})}
    )
};

export const resendBoardInvitation = (invitationId: BoardInvitationId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'ResendBoardInvitation', { invitationId });
};

export const revokeBoardInvitation = (invitationId: BoardInvitationId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'RevokeBoardInvitation', { invitationId });
};

export const ackBoardInvitation = (invitationId: BoardInvitationId): Promise<ResponseType> => {
    return axiosApiPromise(
        SERVER_CONTEXT + 'AcknowledgeInvitation',
        {invitationId}
    )
};

export const revokeAccessToBoard = (boardId: BoardModel.BoardId, userId: UserModel.UserId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'RevokeAccessToBoard', {boardId, userId});
};

export const changeBoardPermissions = (boardId: BoardModel.BoardId, userId: UserModel.UserId, permissions: Permissions): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'ChangeBoardPermissions', {boardId, userId, boardPermissions: permissions});
};

export const adminChangeTeamBoardPermissions = (boardId: BoardModel.BoardId, teamId: TeamModel.TeamId, permissions: Permissions): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'AdminChangeTeamBoardPermissions', {boardId, teamId, boardPermissions: permissions});
}

export const fetchUserBoard = (boardId: BoardModel.BoardId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'UserBoard', {boardId});
};

export const fetchUserBoards = () => {
    return axiosApiPromise(SERVER_CONTEXT + 'UserBoards', {});
};

export const fetchOlderPatches = (boardId: BoardModel.BoardId, boardVersion: BoardVersioning.BoardVersion) => {
    return axiosApiPromise(SERVER_CONTEXT + 'BoardHistory', {boardId, fromBoardVersion: boardVersion.toJSON()});
};

export const fetchNewerPatches = (boardId: BoardModel.BoardId, boardVersion: BoardVersioning.BoardVersion) => {
    return axiosApiPromise(SERVER_CONTEXT + 'BoardHistory', {boardId, fromBoardVersion: boardVersion.toJSON(), mode: "newer"});
};

export const fetchBoardPermissionsForUsers = (boardId: BoardModel.BoardId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'BoardPermissionsForUsers', { boardId });
};

export const fetchBoardPermissionsForTeams = (boardId: BoardModel.BoardId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'BoardPermissionsForTeams', { boardId });
};

export const fetchOpenBoardInvitations = (boardId: BoardModel.BoardId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'OpenBoardInvitations', { boardId });
};

export const fetchBoardOfInvitation = (invitationId: string) => {
    return axiosApiPromise(SERVER_CONTEXT + 'InvitationBoard', {invitationId});
};

export const fetchBoardXmlFromLink = (link: string): Promise<BoardXml> => {
    return fetch(link).then(response => response.text());
}

export const fetchPlayshotFromLink = (link: string): Promise<Playshot> => {
    return fetch(link).then(response => response.json()).then((playshot: Playshot) => {
        return new Promise<Playshot>((resolve, reject) => {
            const result = validatePlayshot(playshot);

            if(isFailure(result)) {
                reject(result.value)
            } else {
                resolve(result.value);
            }
        })
    });
}

export const uploadBoardImage = (boardId: BoardId, imageContent: string|ArrayBuffer, mimeType: string): AxiosPromise<{imageId: string}> => {
    return boardImageUploadApiPromise(boardId, imageContent, mimeType);
};

export const replaceBoardImage = (boardId: BoardId, imageId: string, imageContent: string|ArrayBuffer, mimeType: string): AxiosPromise<{imageId: string}> => {
    return boardImageReplacementApiPromise(boardId, imageId, imageContent, mimeType);
};

export const deleteBoardImage = (imageUrl: string): AxiosPromise<any> => {
    return boardImageDeletionApiPromise(imageUrl);
};

export const fetchBoardGraphElements = (boardId: BoardId, type: GraphElementType) => {
    return axiosApiPromise(SERVER_CONTEXT + 'BoardGraphElements', { boardId, type });
};

export const fetchBoardClient = (boardId: BoardId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'BoardClient', { boardId });
};

export const fetchBoardClientSecret = (boardId: BoardId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'BoardClientSecret', { boardId });
};

export const createBoardClient = (boardId: BoardId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'CreateBoardClient', { boardId });
};

export const removeBoardClient = (boardId: BoardId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'RemoveBoardClient', { boardId });
};

export const resetBoardClientSecret = (boardId: BoardId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'ResetBoardClientSecret', { boardId });
};

export const fetchBoardStickyTemplates = (boardId: BoardModel.BoardId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'StickyTemplates', { boardId });
};

export const setStickyTemplate = (boardId: BoardModel.BoardId, cellType: NodeType, template: string) => {
    return axiosApiPromise(SERVER_CONTEXT + 'SetStickyTemplate', {boardId, cellType, template});
}

export const updateStickyAdvancedConfig = (boardId: BoardModel.BoardId, cellType: NodeType, templateType: TemplateType, templateSchema?: TemplateSchema) => {
    return axiosApiPromise(SERVER_CONTEXT + 'UpdateStickyAdvancedConfig', {boardId, cellType, templateType, ...(templateSchema ? {templateSchema} : {})});
}

export const moveUserBoardToOrganization = (organizationId: OrganizationId, boardId: BoardId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'MoveUserBoardToOrganization', {organizationId, boardId});
}

export const shareBoardWithOrganizationMembers = (boardId: BoardModel.BoardId, permissions: Permissions): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'ShareBoardWithOrganizationMembers', {boardId, permissions});
}

export const shareBoardWithAnyone = (boardId: BoardModel.BoardId, permissions: Permissions): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'ShareBoardWithAnyone', {boardId, permissions});
}

export const checkBoardAccess = (boardId: BoardModel.BoardId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'CheckBoardAccess', {boardId});
}

export const savePlayshot = (playshot: Playshot) => {
    return axiosApiPromise(SERVER_CONTEXT + 'SavePlayshot', {
        playshotId: playshot.playshotId,
        boardId: playshot.boardId,
        name: playshot.name,
        playConfig: JSON.stringify(playshot.playConfig),
        playData: JSON.stringify(playshot.playData),
    })
}

export const fetchPlayshots = (boardId: BoardId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'PlayshotSummaryList', {boardId});
}

export const fetchPlayshot = (playshotId: PlayshotId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'Playshot', {playshotId});
}
