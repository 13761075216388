import * as React from 'react';
import {FormField, Input} from "semantic-ui-react";
import EditableTextline from "../../../../../core/components/EditableTextline";
import {ADDITIONAL_CHAR_PADDING, CHAR_SIZE_UNIT, INPUT_CHAR_SIZE} from "../../WizardModal";

interface OwnProps {
  collection: string;
  onCollectionChanged: (collection: string) => void;
  disabled?: boolean;
}

type CollectionInputProps = OwnProps;

const CollectionInput = (props: CollectionInputProps) => {
  const style = props.collection === '' ? {color: 'rgba(51, 51, 51, 0.6)'} : undefined;

  return <FormField>
    <EditableTextline
      required={true}
      text={props.collection}
      minWidth={20}
      charSize={INPUT_CHAR_SIZE}
      widthUnit={CHAR_SIZE_UNIT}
      padding={ADDITIONAL_CHAR_PADDING}
      placeholder='my_collection'
      textStyle={style}
      disabled={props.disabled}
      onTextChanged={props.onCollectionChanged} />
  </FormField>
};

export default CollectionInput;
