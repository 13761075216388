import {fork, put, take, call} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as Action from "../../InspectioBoards/actions";
import {standaloneStorageManager} from "../util";

type FlowAction = ActionType<typeof Action.Command.deleteBoard>;

function* onDeleteBoard(action: FlowAction) {
    yield call(standaloneStorageManager.deleteBoard, action.payload.boardId);
    yield put(NotifyAction.Command.info('Board deleted', 'Board deleted'));
}

export function* standaloneDeleteBoardFlow() {
    while (true) {
        const action: FlowAction = yield take(Action.Type.DELETE_BOARD);
        yield fork(onDeleteBoard, action);
    }
}
