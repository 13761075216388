import { call, fork, put, select, take } from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import { Action as NotifyAction } from '../../NotificationSystem';
import * as Action from "../actions";
import {makeBoardInvitationSelector} from "../selectors/boardInvitation";

type FlowAction = ActionType<typeof Action.Command.revokeBoardInvitation>;

function* onRevokeBoardInvitation(action: FlowAction) {
    const boardInvitation = yield select(makeBoardInvitationSelector(action.payload.invitationId));

    if (boardInvitation) {
        const {response, error}: ResponseType = yield call(
            Action.Api.revokeBoardInvitation,
            action.payload.invitationId
        );

        if (error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not revoke board invitation'));
        }

        if (response) {
            yield put(NotifyAction.Command.info(
                'insp.board.notify.invitation_revoke.success.title',
                'insp.board.notify.invitation_revoke.success.msg'
            ));

            yield put(Action.Event.boardInvitationRevoked(boardInvitation.revoke()));
        }
    }
}

export function* revokeBoardInvitationFlow() {
    while (true) {
        const action: FlowAction = yield take([
            Action.Type.REVOKE_BOARD_INVITATION,
        ]);

        yield fork(onRevokeBoardInvitation, action);
    }
}