import { Record } from 'immutable';
import * as uuid from 'uuid';

export interface NotificationActionType {
    label: string,
    callback: () => void
}

export interface MessageProps {
    title?: string,
    message: string,
    tOpts?: object,
    level?: 'success' | 'error' | 'warning' | 'info',
    position?: 'tr' | 'tl' | 'tc' | 'br' | 'bl' | 'bc',
    autoDismiss?: number,
    dismissible?: boolean,
    handled?: boolean,
    uid?: string,
    action?: NotificationActionType | null,
    hideProgressBar?: boolean,
}

const defaultMessageProps: MessageProps = {
    title: '',
    message: '',
    level: 'info',
    position: 'bl',
    autoDismiss: 5,
    dismissible: true,
    handled: false,
    uid: undefined,
    action: null,
    hideProgressBar: true,
};

export class Message extends Record(defaultMessageProps) implements MessageProps {

    public constructor(data: Partial<MessageProps>) {
        if (data.uid === undefined) {
            data.uid = uuid.v4();
        }
        super(data);
    }

    public markAsHandled(): Message {
        return this.set('handled', true) as Message;
    }

    public hasAction(): boolean {
        return null !== this.action;
    }

}
