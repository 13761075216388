import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../index";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";

type Command = ActionType<typeof Action.Command.shareBoardWithOrganizationMembers>

function* handleShareBoardWithOrganizationMembers(command: Command) {
  const {response, error}: ResponseType = yield call(Action.Api.shareBoardWithOrganizationMembers, command.payload.boardId, command.payload.permissions);

  if(error) {
    yield put(NotifyAction.Command.error('Request Error', 'Failed to share board with organization members.'));
  }

  if(response) {
    yield put(NotifyAction.Command.info('Access Granted', 'Organization members now have access to the board.'));

    yield put(Action.Event.boardSharedWithOrganizationMembers(command.payload.boardId, command.payload.permissions));
  }
}

export function* shareBoardWithOrganizationMembersFlow() {
  while (true) {
    const command: Command = yield take([
      Action.Type.SHARE_BOARD_WITH_ORGANIZATION_MEMBERS
    ]);

    yield fork(handleShareBoardWithOrganizationMembers, command);
  }
}
