import {List} from "immutable";
import {call, fork, put, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {isFailure, isSuccess} from "../../core/validation/either";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as Action from "../actions";
import {LOAD_IIO_USERS_INFO} from "../actions/constants";
import {createIioUserInfoFromServerData, IioUserInfo} from "../model/IioUserInfo";

type LoadIioUsersInfo = ActionType<typeof Action.Query.loadUsersInfo>;

type FlowAction = LoadIioUsersInfo;

function* onLoadUsersInfo(action: LoadIioUsersInfo) {
    const response = yield call(Action.Api.getInspectioUsersInfo, action.payload.userIds);

    yield call(onResponse, response);
}

function* onResponse({response, error}: ResponseType) {
    if (error) {
        // tslint:disable-next-line:no-console
        console.error("Request Error", "Failed to laod user info", error);
        yield put(NotifyAction.Command.error("Request Error", "Failed to load user info"));
    }

    if (response) {
        const rawUserInfoList: Array<{userId: string, username: string, avatarUrl: string}> = response.data;
        let userInfoList = List<IioUserInfo>();

        for (const rawInfo of rawUserInfoList) {
            const result = createIioUserInfoFromServerData(rawInfo);

            if(isFailure(result)) {
                // tslint:disable-next-line:no-console
                console.error("Failed to process IioUserInfo loaded from server", result.value, rawInfo);
                yield put(NotifyAction.Command.error("Request Error", "Failed to load user info"));
            }

            if(isSuccess(result)) {
                userInfoList = userInfoList.push(result.value);
            }
        }

        yield put(Action.Event.iioUsersInfoLoaded(userInfoList));
    }
}

export function* lazyLoadInspectioUserInfo() {
    while (true) {
        const action: FlowAction = yield take([
            Action.Type.LOAD_IIO_USERS_INFO,
        ]);

        switch (action.type) {
            case LOAD_IIO_USERS_INFO:
                yield fork(onLoadUsersInfo, action);
                break;
        }
    }
}