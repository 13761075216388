import * as React from 'react';
import {useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button, Dropdown, Icon, Input, InputOnChangeData, Modal} from "semantic-ui-react";
import * as Routes from "../../routes";

interface OwnProps {
    openHandle: (handle: (open: boolean, currentLink: null|string) => void) => void;
    onCancel: () => void;
    onLinkChosen: (link: string) => void;
}

type MxGraphTaskLinkProps = OwnProps & WithNamespaces;

const validateUrl = (url: string): boolean => {
    if(url === "") {
        return true;
    }
    return url.match(/^https?:\/\/.+$/) !== null;
};

const MxGraphTaskLink = (props: MxGraphTaskLinkProps) => {
    const [open, setOpen] = useState(false);
    const [link, setLink] = useState('');
    const [error, setError] = useState(false);

    props.openHandle((openFromHandle, currentLink) => {
        if(currentLink) {
            setLink(currentLink);
        } else {
            setLink('');
            setError(false);
        }
        setOpen(openFromHandle);
    });

    const handleChange = (e: React.SyntheticEvent, dProps: InputOnChangeData) => {
        setLink(dProps.value as string);
        setError(!validateUrl(dProps.value as string));
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if(e.keyCode === 13) {
            chooseLink();
        }
    }

    const cancel = () => {
        props.onCancel();
        resetModal();
    };

    const resetModal = () => {
        setLink('');
        setError(false);
    };

    const chooseLink = () => {
        props.onLinkChosen(link);
        resetModal();
    }

    return (
        <Modal open={open} onClose={cancel} closeIcon={true}>
            <Modal.Header>{props.t('insp.board.tasklink.headline')}</Modal.Header>
            <Modal.Content>
                <Input type="url"
                       onChange={handleChange}
                       value={link}
                       fluid={true}
                       placeholder="https://issue.tracker.com/tasks/1234"
                       required={true}
                       error={error}
                       autoFocus={true}
                       onKeyDown={handleKeyDown}
                       icon={<Icon name="close" link={true} onClick={resetModal} />}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    children={props.t('app.form.cancel')}
                    onClick={cancel}
                />
                <Button
                    primary={true}
                    children={props.t('app.form.save')}
                    onClick={chooseLink}
                    disabled={error}
                />
            </Modal.Actions>
        </Modal>);
};

export default withNamespaces()(MxGraphTaskLink);