import * as React from 'react';
import {ChangeEvent, FormEvent, SyntheticEvent, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Input, Message, Modal, Transition} from "semantic-ui-react";
import * as Action from "../../InspectioBoards/actions";
import {BoardModel} from "../../InspectioBoards/model";
import {UserSelector} from "../../User/selectors";
import BoardQuotaReachedModal from "../../InspectioBoards/components/BoardQuotaReachedModal";

type AddBoardProps = WithNamespaces & RouteComponentProps;

const AddBoard = (props: AddBoardProps) => {
    const [visible, setVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [boardName, setBoardName] = useState('');
    const [hasError, setHasError] = useState(false);
    const inputEl = useRef<Input>(null);
    const getUserQuota = UserSelector.makeGetCurrentBoardQuota();
    const userQuota = useSelector(getUserQuota);
    const getUser = UserSelector.makeGetCurrentUser();
    const user = useSelector(getUser);
    const dispatch = useDispatch();

    const handleClick = () => {
        setVisible(!visible);
        if(!visible) {
            window.setTimeout(() => {
                inputEl.current!.focus();
            }, 200);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if(boardName === '') {
            setHasError(true);
            return;
        }

        if(userQuota === 0) {
            setShowModal(true);
            return;
        }

        const board = BoardModel.createUserBoard(user.uid, boardName as BoardModel.BoardName);

        dispatch(Action.Command.addBoard(board, props.history));
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setBoardName(e.target.value);
        setHasError(e.target.value === '');
    };

    const handleCloseModal = () => {
        setBoardName('');
        setVisible(false);
        setShowModal(false);
    };

    return <div className="item">
            <Button className="large basic" onClick={handleClick}><i className="plus icon" /> Add&nbsp;Board</Button>
            <Transition animation="fade down" visible={visible} duration={200} >
                <div className="ui icon input">
                    <form onSubmit={handleSubmit}>
                        <Input placeholder="Board name ..." fluid={true} ref={inputEl} error={hasError} value={boardName} onChange={handleChange} />
                    </form>
                </div>
            </Transition>
            <BoardQuotaReachedModal open={showModal} onClose={handleCloseModal} />
    </div>;
};

export default withNamespaces()(withRouter(AddBoard));
