import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../index";
import {makeOrganizationSelector} from "../selectors/selectOrganization";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";

type Command = ActionType<typeof Action.Command.setUpExplicitOrganization>

function* handleSetUpExplicitOrganization(command: Command) {
    const organization = yield select(makeOrganizationSelector(command.payload.organizationId));

    if(organization) {
        const explicitOrganization = organization.setUpAsExplicit(command.payload.name);

        yield put(Action.Event.explicitOrganizationSetUp(explicitOrganization));

        const {response, error}: ResponseType = yield call(Action.Api.setUpExplicitOrganization, command.payload.organizationId, command.payload.name);

        if(error) {
            yield put(Action.Event.explicitOrganizationSetUpFailed(organization));
            yield put(NotifyAction.Command.error('Request Error', 'Could not create organization.'));
        }

        if(response) {
            yield put(NotifyAction.Command.info('Organization created', 'Organization created successfully'));
        }
    }
}

export function* setUpExplicitOrganization() {
    while (true) {
        const command: Command = yield take([
            Action.Type.SET_UP_EXPLICIT_ORGANIZATION
        ]);

        yield fork(handleSetUpExplicitOrganization, command);
    }
}
