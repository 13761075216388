import {WizardStep} from "../../../components/CodyEngineWizard/WizardModal";
import {names} from "../node/names";

export const stepWikiLink = (step: WizardStep | null): string | null => {
  switch (step) {
    case "command":
      return 'https://wiki.prooph-board.com/event_storming/basic-concepts.html#command';
    case "aggregate":
      return 'https://wiki.prooph-board.com/event_storming/basic-concepts.html#aggregate';
    case "state":
    case "anyVO":
      return 'https://wiki.prooph-board.com/event_storming/basic-concepts.html#information';
    case "dddEvent":
    case "publicEvent":
    case "policyEvent":
      return 'https://wiki.prooph-board.com/event_storming/basic-concepts.html#domain-event';
    default:
      return null;
  }
}
