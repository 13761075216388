import * as React from 'react';
import {useState} from "react";
import {Button, Icon, MenuItem} from "semantic-ui-react";
import CollaborationInfoModal from "./CollaborationInfoModal";

interface OwnProps {

}

type ShareBoardButtonProps = OwnProps;

const ShareBoardButton = (props: ShareBoardButtonProps) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    return <>
        <MenuItem>
            <Button icon={true} className="share" onClick={() => setOpen(true)}><Icon name="share alternate"/>&nbsp;share</Button>
        </MenuItem>
        <CollaborationInfoModal open={open} onClose={handleClose} headerKey='header_team' />
    </>
};

export default ShareBoardButton;
