import * as React from 'react';
import {useState} from "react";
import {WithNamespaces, withNamespaces} from "react-i18next";
import {Button} from "semantic-ui-react";
import {AddBoard} from "../index";

interface OwnProps {
    disabled: boolean;
    primary?: boolean;
}

type AddBoardButtonProps = OwnProps & WithNamespaces;

// Needed for typescript to compile
const AddBoardModal: any = AddBoard;

const AddBoardButton = (props: AddBoardButtonProps) => {
    const [addBoard, setAddBoard] = useState(false);

    return  <>
        <Button primary={props.primary} onClick={e => {e.preventDefault(); setAddBoard(true)}}>Add Board</Button>
        <AddBoardModal open={addBoard} onClose={() => {setAddBoard(false)}} disabled={props.disabled} />
    </>;
};

export default withNamespaces()(AddBoardButton);
