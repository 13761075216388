import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {makeOrganizationSelector} from "../selectors/selectOrganization";

type Command = ActionType<typeof Action.Command.removeGuestFromOrganization>

function* handleRemoveGuestFromOrganization(command: Command) {
    const {response, error} = yield call(Action.Api.removeGuestFromOrganization, command.payload.organizationId, command.payload.userId);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not remove guest from organization.'));
    }

    if(response) {
        const organization = yield select(makeOrganizationSelector(command.payload.organizationId));

        yield put(Action.Event.guestRemovedFromOrganization(organization.removeGuest(command.payload.userId)));

        yield put(NotifyAction.Command.info('Guest removed', 'The guest no longer has access to organization boards and teams.'));
    }
}

export function* removeGuestFromOrganization() {
    while (true) {
        const command: Command = yield take([
            Action.Type.REMOVE_GUEST_FROM_ORGANIZATION
        ]);

        yield fork(handleRemoveGuestFromOrganization, command);
    }
}
