import * as React from 'react';
import { WithNamespaces, withNamespaces } from "react-i18next";
import { Node } from "../../../../model/Graph";
import { WizardContext } from "../../../../service/cody-wizard/context/wizard-context";
import { Checkbox, FormField } from "semantic-ui-react";

interface OwnProps {
    vo: Node | null;
    isStored: boolean;
    onStoredStateChanged: (isStored: boolean) => void;
    ctx: WizardContext;
    disabled?: boolean;
}

type StoredVoToggleProps = OwnProps & WithNamespaces;

const StoredVoToggle = (props: StoredVoToggleProps) => {
    const disabled = props.disabled || !props.vo;

    return <FormField disabled={disabled} inline={true}>
        <Checkbox value={1}
              checked={props.isStored}
              disabled={disabled}
              toggle={true}
              onChange={() => props.onStoredStateChanged(!props.isStored)}
              label={props.t('insp.cody_wizard.step_any_vo.is_stored_in_db_label') as string} />
  </FormField>
}

export default withNamespaces()(StoredVoToggle);