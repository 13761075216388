import * as React from "react";
import {useSelector} from "react-redux";
import {makeTeamListSelector, teamListSelector} from "../../InspectioTeams/selectors/selectTeam";
import TeamListItem from "./TeamListItem";

interface TeamListProps {

}

const TeamList = (props: TeamListProps) => {
    const teamList = useSelector(makeTeamListSelector(true));

    return (
        <>
            {teamList.sortBy(team => team.name).sortBy(team => !team.isNew).map(team => <TeamListItem key={team.uid} team={team} />)}
        </>
    );
};

export default TeamList;
