import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dropdown, FormField, Label} from "semantic-ui-react";
import {runCodyOnChanged} from "../../../actions/events";
import {RunCodyOption} from "../../../reducers/RunCodyOn";
import {makeRunCodyOnSelector} from "../../../selectors/RunCodyOn";
import {useIIO} from "../../../service/Cody";

interface OwnProps {

}

type RunCodyOnSelectProps = OwnProps;

const options = [{
  key: 'auto',
  text: 'auto',
  value: 'auto'
}, {
  key: 'play',
  text: 'Cody Play',
  value: 'play'
}, {
  key: 'engine',
  text: 'Cody Engine',
  value: 'engine'
}]

const RunCodyOnSelect = (props: RunCodyOnSelectProps) => {
  const runCodyOn = useSelector(makeRunCodyOnSelector());
  const dispatch = useDispatch();
  const iio = useIIO();

  const handleTargetChanged = (newTarget: RunCodyOption) => {
    if(iio.isConnected()) {
      iio.disconnect('Cody');
    }
    dispatch(runCodyOnChanged(newTarget));
  }

  return <FormField inline={true}>
    <label>run on:&nbsp;&nbsp;</label>
    <Dropdown inline={true}
              placeholder={'Select target'}
              value={runCodyOn}
              options={options}
              onChange={(e, data) => handleTargetChanged(data.value as RunCodyOption)}
              className={'cody-wizard-inline-select'} />
  </FormField>
};

export default RunCodyOnSelect;
