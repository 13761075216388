import {Map} from "immutable";
import React, {FormEvent, useRef, useState} from 'react'
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import { Field, reduxForm } from 'redux-form/immutable'
import {
    Button, Checkbox,
    Divider,
    Dropdown,
    DropdownItemProps,
    Header,
    Icon, Input,
    Transition,
    TransitionGroup
} from "semantic-ui-react";
import {makeTeamListSelector} from "../../InspectioTeams/selectors/selectTeam";
import {isStandalone} from "../../standalone/util";
import {deriveAbbreviationFromBoardName} from "../model/Board";
import {makeGetTags} from "../selectors/tags";
import {ADD_BOARD_FORM} from "./constants";

interface FormData  {
    uid: string,
    name: string;
    nameAbbreviation: string;
    tags: string[];
    responsibleTeam: string;
    codySuggestEnabled: string;
    eventModelingEnabled: string;
}

export interface ImFormData extends Map<string, string | string[]> {
    toJS(): FormData
}

export interface OwnProps {
    onSubmit: (data: ImFormData) => void;
    isAddingBoard?: boolean;
}

export interface PropsFromDispatch {

}


export type AddBoardFormProps = OwnProps & PropsFromDispatch & WithNamespaces;

const required = (val: string|undefined) => val? undefined : 'Required';

const AddBoardForm = (props: AddBoardFormProps) => {
    const [showSettings, setShowSettings] = useState(false);
    const existingTags = useSelector(makeGetTags());
    const availableTags = useRef<DropdownItemProps[]>(existingTags.map(tag => ({key: tag, text: '#'+tag, value: tag})).toJS());
    const teams = useSelector(makeTeamListSelector());
    const teamOptions: DropdownItemProps[] = [];

    const [boardName, setBoardName] = useState('');
    const [boardNameInvalid, setBoardNameInvalid] = useState(false);

    const [abbr, setAbbr] = useState('');
    const [abbrTouched, setAbbrTouched] = useState(false);
    const [abbrInvalid, setAbbrInvalid] = useState(false);

    const [codySuggestEnabled, setCodySuggestEnabled] = useState(true);
    const [eventModelingEnabled, setEventModelingEnabled] = useState(true);

    const [tags, setTags] = useState<string[]>([]);
    const [responsibleTeam, setResponsibleTeam] = useState<string|undefined>(undefined);

    teamOptions.push({
        key: 'none',
        text: 'No Team',
        value: undefined
    });

    teams.forEach(team => teamOptions.push({key: team.uid, text: team.name, value: team.uid}));

    const addTagOption = (tag: string) => {
        if(tag.length === 0) {
            return;
        }

        if(tag.charAt(0) !== '#') {
            tag = '#'+tag;
        }

        const tagVal = tag.slice(1);

        availableTags.current.push({
            key: tagVal,
            text: tag,
            value: tagVal
        })
    }

    const handleChangeBoardName = (val: string) => {
        setBoardNameInvalid(false);
        setBoardName(val);

        if(!abbrTouched) {
            setAbbr(deriveAbbreviationFromBoardName(val));
        }
    }

    const handleChangeAbbr = (val: string) => {
        setAbbr(val);
        setAbbrTouched(true);

        if(val === '' || val.length > 3) {
            setAbbrInvalid(true);
        } else if(abbrInvalid) {
            setAbbrInvalid(false);
        }
    }

    const handleCodySuggestEnabledChanged = (enabled: boolean) => {
        setCodySuggestEnabled(enabled);
    }

    const handleEventModelingEnabledChanged = (enabled: boolean) => {
        setEventModelingEnabled(enabled);
    }

    const handleSubmit = (evt: FormEvent) => {
        evt.preventDefault();
        evt.stopPropagation();

        if(!boardName) {
            setBoardNameInvalid(true);
            return;
        }

        if(abbrInvalid) {
            return;
        }

        props.onSubmit(Map({
            name: boardName,
            nameAbbreviation: abbr,
            tags,
            responsibleTeam,
            codySuggestEnabled: codySuggestEnabled? '1' : '',
            eventModelingEnabled: eventModelingEnabled? '1': '',
        }) as ImFormData)
    }

    return <form className="ui form" onSubmit={handleSubmit}>
        <div className={'field' + (boardNameInvalid? ' error' : '')}>
            <Input name="name"
                   type="text"
                   placeholder={props.t('insp.board.form.board_name') as string}
                   autoFocus={true}
                   onChange={(evt, data) => handleChangeBoardName(data.value)}
                   error={boardNameInvalid}
                   value={boardName}
            />
        </div>
        <Divider horizontal={true}>
            <Header as="h4" onClick={() => setShowSettings(!showSettings)} style={{cursor: 'pointer'}}>
                {props.t('insp.board.advanced_settings')}
                &nbsp;
                {showSettings? '-' : '+'}
            </Header>
        </Divider>
        <div className={showSettings ? 'slidedown' : 'slideup'}>
            <div className="field">
                <Checkbox toggle={true}
                          label={<label>{props.t('insp.board.form.cody_suggest')}&nbsp;&nbsp;<Icon
                            name="question circle outline" title={props.t('insp.board.cody_suggest_sub')}/></label>}
                          name="codySuggestEnabled"
                          checked={codySuggestEnabled}
                          value={codySuggestEnabled ? '1' : ''}
                          onChange={(e, data) => handleCodySuggestEnabledChanged(data.value !== '1')}
                />
            </div>
            <div className="field">
                <Checkbox toggle={true}
                          label={<label>{props.t('insp.board.form.event_modeling')}&nbsp;&nbsp;<Icon
                            name="question circle outline" title={props.t('insp.board.event_modeling_sub')}/></label>}
                          name="eventModelingEnabled"
                          checked={eventModelingEnabled}
                          value={eventModelingEnabled ? '1' : ''}
                          onChange={(e, data) => handleEventModelingEnabledChanged(data.value !== '1')}
                />
            </div>
            <div className={'field' + (abbrInvalid ? ' error' : '')}>
                <Input name="nameAbbreviation"
                       type="text"
                       placeholder={props.t('insp.board.form.board_abbr') as string}
                       value={abbr}
                       onChange={(evt, data) => handleChangeAbbr(data.value)}
                       error={abbrInvalid}
                />
            </div>
            <div className="field">
                <Dropdown
                  multiple={true}
                  allowAdditions={true}
                  name="tags"
                  placeholder="Tags"
                  fluid={true}
                  search={true}
                  selection={true}
                  options={availableTags.current}
                  value={tags}
                  onAddItem={(evt, data) => addTagOption(data.value as string)}
                  onChange={(evt, data) => setTags(data.value as string[])}
                />
            </div>
            {!isStandalone() && <div className="field">
                <Dropdown
                  name="responsibleTeam"
                  placeholder={props.t('insp.board.form.responsible_team') as string}
                  fluid={true}
                  selection={true}
                  options={teamOptions}
                  value={responsibleTeam}
                  onChange={(evt, data) => setResponsibleTeam(data.value as string | undefined)}
                />
            </div>}
            <p>&nbsp;</p>
        </div>
        <Button primary={true} type="submit"
                disabled={props.isAddingBoard}>{props.t('insp.board.form.btn_add_save')}</Button>
    </form>;
}

export default withNamespaces()(reduxForm<ImFormData, OwnProps & WithNamespaces>({form: ADD_BOARD_FORM})(AddBoardForm));
