import {List, Record} from "immutable";
import {createSelector} from "reselect";
import {ApplicationState} from "../../reducer";
import {boardListSelector} from "./boardList";

export const makeGetTags = (): (state: Record<ApplicationState>, props?: any) => List<string> => {
    return createSelector(
        [boardListSelector],
        (boards) => {
            const tags: string[] = [];

            boards.forEach(board => {
                board.tags.forEach(tag => {
                    if(!tags.includes(tag)) {
                        tags.push(tag);
                    }
                })
            })

            return List(tags);
        }
    )
}
