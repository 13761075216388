import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {Team} from "../model";
import {makeTeamSelector} from "../selectors/selectTeam";

type Command = ActionType<typeof Action.Command.renameTeam>

function* handleRenameTeam(command: Command) {
    let team: Team.Team = yield select(makeTeamSelector(command.payload.teamId));

    const oldName = team.name;

    team = team.rename(command.payload.newName);

    yield put(Action.Event.teamRenamed(team));

    const {response, error}: ResponseType = yield call(Action.Api.renameTeam, command.payload.teamId, command.payload.newName);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not rename team.'));
        team = team.rename(oldName);
        yield put(Action.Event.teamRenamed(team));
    }
    if (response) {
        yield put(NotifyAction.Command.info('Team renamed', 'Team renamed to: ' + command.payload.newName));
    }
}

export function* renameTeam() {
    while (true) {
        const command: Command = yield take([
            Action.Type.RENAME_TEAM
        ]);

        yield fork(handleRenameTeam, command);
    }
}