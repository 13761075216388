import {List, Record} from "immutable";
import {createSelector} from "reselect";
import {ApplicationState} from "../../reducer";
import {MODULE} from "../index";
import {TeamInvitation, TeamInvitationId} from "../model/TeamInvitation";

export const getTeamInvitationById = (state: Record<ApplicationState>, uid: TeamInvitationId): TeamInvitation | undefined => state.getIn([MODULE, 'TeamInvitation', uid]);

export const makeTeamInvitationSelector = (uid: TeamInvitationId): (state: Record<ApplicationState>) => TeamInvitation | undefined => (state: Record<ApplicationState>) => getTeamInvitationById(state, uid);

export const teamInvitationListSelector = (state: Record<ApplicationState>, props: any): List<TeamInvitation> =>
    List(state.getIn([MODULE, 'TeamInvitation']).toIndexedSeq().toArray()) || List();

export const makeTeamInvitationListSelector = (): (state: Record<ApplicationState>, props?: any) => List<TeamInvitation> => {
    return createSelector(
        [teamInvitationListSelector],
        (TeamInvitationList): List<TeamInvitation> => {
            return TeamInvitationList;
        }
    )
};