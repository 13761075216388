import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Message} from "semantic-ui-react";
import * as Action from "../../actions";
import {makeGetBoardOverview} from "../../selectors/boardList";

interface OwnProps {

}

type MaintenanceMessageProps = OwnProps;

const MaintenanceMessage = (props: MaintenanceMessageProps) => {
  const boardOverviewSelector = makeGetBoardOverview();
  const boardOverviewState = useSelector(boardOverviewSelector);
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(Action.Command.dismissMaintenanceMessage());
  }

  return <>{boardOverviewState.showMaintenanceMessage && <Message
    header="Scheduled Maintenance Downtime"
    content="Please note a scheduled maintenance downtime on Friday, 12th July, 2024 starting at 2 pm (CEST). The service will be unavailable for 2-4 hours due to a database migration. If this causes trouble, please contact support. We might be able to move the time window."
    color="blue"
    onDismiss={handleDismiss}
  />}</>
};

export default MaintenanceMessage;
