import {Map} from 'immutable';
import {Reducer} from 'redux';
import {addAggregate, updateAggregate} from "../../core/reducer/applyAggregateChanged";
import {
    EXPLICIT_ORGANIZATION_SET_UP,
    EXPLICIT_ORGANIZATION_SET_UP_FAILED,
    GUEST_REMOVED_FROM_ORGANIZATION,
    GUEST_TURNED_INTO_MEMBER,
    ORGANIZATION_FETCHED,
    ORGANIZATION_RENAMED,
    USER_DEMOTED_TO_ORGANIZATION_MEMBER,
    USER_INVITED_TO_ORGANIZATION,
    USER_ORGANIZATION_INVITATION_REVOKED,
    USER_PROMOTED_TO_ORGANIZATION_ADMIN,
    USER_REMOVED_FROM_ORGANIZATION
} from "../actions/constants";
import {Organization} from '../model';
import {InspectioOrganizationEvent} from './index';

export interface OrganizationsState extends Map<string, Organization.Organization> {
}

export const initialState: OrganizationsState = Map<string, Organization.Organization>();

const reducer: Reducer<OrganizationsState, InspectioOrganizationEvent> =
    (state: OrganizationsState = initialState, event: InspectioOrganizationEvent): OrganizationsState => {
    switch (event.type) {
        case ORGANIZATION_FETCHED:
            return addAggregate(state, event.payload);
        case EXPLICIT_ORGANIZATION_SET_UP:
        case EXPLICIT_ORGANIZATION_SET_UP_FAILED:
        case USER_INVITED_TO_ORGANIZATION:
        case USER_PROMOTED_TO_ORGANIZATION_ADMIN:
        case USER_DEMOTED_TO_ORGANIZATION_MEMBER:
        case USER_REMOVED_FROM_ORGANIZATION:
        case GUEST_REMOVED_FROM_ORGANIZATION:
        case ORGANIZATION_RENAMED:
        case GUEST_TURNED_INTO_MEMBER:
            return updateAggregate(state, event.payload);
        case USER_ORGANIZATION_INVITATION_REVOKED:
            return updateAggregate(state, event.payload.organization);
        default:
            return state;
    }
};

export { reducer as saveOrganizationReducer };
