import * as React from 'react';
import {useDispatch} from "react-redux";
import {Button} from "semantic-ui-react";
import {setSidebarWidth, toggleSidebarCollapsed} from "../actions/commands";

interface OwnProps {
    collapsed: boolean;
}

type CollapseToggleProps = OwnProps;

const CollapseToggle = (props: CollapseToggleProps) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        const newCollapsed = !props.collapsed;
        dispatch(toggleSidebarCollapsed(newCollapsed));
        dispatch(setSidebarWidth(newCollapsed? 85 : 250));
    };

    const iconClass = props.collapsed? 'angle double right icon' : 'angle double left icon';

    return <span className="collaps-handle" onClick={handleClick} style={
        {}
    }><i className={ iconClass } /></span>
};

export default CollapseToggle;
