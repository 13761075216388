import { Map, Record } from 'immutable';
import { RoleModel } from './index';

export type RoleMapType = Map<RoleModel.RoleType, RoleModel.Role>;

const anonymous = () => Map<RoleModel.RoleType, RoleModel.Role>().set(RoleModel.TYPE_ANONYMOUS, RoleModel.anonymousRole());

export const withAnonymous = () => new RoleMap({roleMap: Map<RoleModel.RoleType, RoleModel.Role>().set(RoleModel.TYPE_ANONYMOUS, RoleModel.anonymousRole())});

export const fromToken = (roles: string[]): RoleMap => {
    let map: RoleMapType = Map<RoleModel.RoleType, RoleModel.Role>();

    roles.forEach((value: string) => {
        if (null === value.match(/-account/)) {
            const role = new RoleModel.Role({type: value as RoleModel.RoleType});
            map = map.set(role.type, role);
        }
    });

    return new RoleMap({roleMap: map});
};

export interface RoleMapProps {
    roleMap: RoleMapType;
}

export interface RoleMapMethods {
    isAnonymous(): boolean;
    isUser(): boolean;
    isAdmin(): boolean;
    equals(other: RoleMap): boolean;
}

export interface RoleMapRecord extends RoleMapProps, RoleMapMethods {}

export const defaultUserProps: RoleMapProps = {
    roleMap: anonymous(),
};

export class RoleMap extends Record(defaultUserProps) implements RoleMapRecord {
    public constructor(data: RoleMapProps) {
        if (data.roleMap.isEmpty()) {
            data.roleMap = data.roleMap.set(RoleModel.TYPE_ANONYMOUS, RoleModel.anonymousRole());
        }
        super(data);
    }

    public isAnonymous(): boolean {
        return this.roleMap.has(RoleModel.TYPE_ANONYMOUS);
    }

    public isUser(): boolean {
        return this.roleMap.has(RoleModel.TYPE_USER);
    }

    public isAdmin(): boolean {
        return this.roleMap.has(RoleModel.TYPE_ADMIN);
    }

    public equals(other: RoleMap): boolean {
        return this.roleMap.equals(other);
    }
}
