import {List} from "immutable";
import {call, fork, put, take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {execForEach, execValidate, isFailure, isSuccess, pipe} from "../../core/validation/either";
import {isArray} from "../../core/validation/predicates";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as Action from "../actions";
import {createPlayshotSummaryFromServerData} from "../model/PlayshotSummary";

type FlowAction = ActionType<typeof Action.Query.fetchPlayshotSummaryList>;

const onFetchPlayshotSummaryList = function* (action: FlowAction) {
  const {response, error}: ResponseType = yield call(Action.Api.fetchPlayshots, action.payload.boardId);

  if (error) {
    yield put(NotifyAction.Command.error("Request Error", "Failed to load playshots"));
  }

  if (response) {
    const result = pipe(
      response!.data,
      execValidate([isArray(), "Response data is not of type Array"]),
      execForEach((data: any) => {
        return createPlayshotSummaryFromServerData(data);
      }),
    );

    if (isFailure(result)) {
      // tslint:disable-next-line:no-console
      console.error("Failed to process playshot summary response from server", result.value, response.data);
      yield put(NotifyAction.Command.error('Request Error', 'Could not load playshots'));
    }

    if (isSuccess(result)) {
      const summaries = List(result.value);
      yield put(Action.Event.playshotSummaryListFetched(summaries));
    }
  }
}

export function* fetchPlayshotSummaryListFlow() {

  while (true) {
    const action: FlowAction = yield take([Action.Type.FETCH_PLAYSHOT_SUMMARY_LIST]);

    yield fork(onFetchPlayshotSummaryList, action);
  }
}
