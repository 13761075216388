import {List, Map} from "immutable";
import {Aggregate, AggregateType} from "../model/Aggregate";

export interface AggregateMap<T> extends Map<string, Aggregate & T>{}

export function initialAggregateState<T>(): AggregateMap<T> {
    return Map<string, Aggregate & T>();
}

export function addAggregate<T>(state: AggregateMap<T>, ar: Aggregate & T): AggregateMap<T> {
    return state.set(ar.uid, ar);
}

export function updateAggregate<T>(state: AggregateMap<T>, ar: Aggregate & T): AggregateMap<T> {
    return state.set(ar.uid, ar);
}

export function removeAggregate<T>(state: AggregateMap<T>, ar: Aggregate & T): AggregateMap<T> {
    return state.remove(ar.uid);
}

export function upsertAggregates<T>(state: AggregateMap<T>, arList: List<Aggregate & T>): AggregateMap<T> {
    for(const ar of arList.toArray()) {
        state = updateAggregate(state, ar);
    }
    return state;
}

export function removeAggregates<T>(state: AggregateMap<T>, arList: List<Aggregate & T>): AggregateMap<T> {
    for(const ar of arList.toArray()) {
        state = removeAggregate(state, ar);
    }

    return state;
}

export function removeAllAggregates<T>(state: AggregateMap<T>): AggregateMap<T> {
    return initialAggregateState();
}






