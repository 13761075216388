import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown, DropdownItemProps, Icon} from "semantic-ui-react";
import * as Action from "../../actions";
import {makeGetBoardOverview} from "../../selectors/boardList";
import {makeUserSettingSelector} from "../../../User/selectors/userSettings";
import {useEffect, useState} from "react";

export enum SORT_OPTIONS {
    NAME_ASC = 'NAME_ASC',
    NAME_DESC = 'NAME_DESC',
    CREATED_AT_ASC = 'CREATED_AT_ASC',
    CREATED_AT_DESC = 'CREATED_AT_DESC',
    LAST_MODIFIED_ASC = 'LAST_MODIFIED_ASC',
    LAST_MODIFIED_DESC = 'LAST_MODIFIED_DESC'
}

enum SORT_BY {
    NAME = 'NAME',
    CREATED_AT = 'CREATED_AT',
    LAST_MODIFIED = 'LAST_MODIFIED'
}

type SORT_ORDER = "up" | "down";

interface OwnProps {

}

type SortingProps = OwnProps & WithNamespaces;

const convertSortOptionToSortValues = (option: SORT_OPTIONS): [SORT_BY, SORT_ORDER] => {
    switch (option) {
        case SORT_OPTIONS.NAME_ASC:
            return [SORT_BY.NAME, "down"];
        case SORT_OPTIONS.NAME_DESC:
            return [SORT_BY.NAME, "up"];
        case SORT_OPTIONS.CREATED_AT_ASC:
            return [SORT_BY.CREATED_AT, "down"];
        case SORT_OPTIONS.CREATED_AT_DESC:
            return [SORT_BY.CREATED_AT, "up"];
        case SORT_OPTIONS.LAST_MODIFIED_ASC:
            return [SORT_BY.LAST_MODIFIED, "down"];
        case SORT_OPTIONS.LAST_MODIFIED_DESC:
            return [SORT_BY.LAST_MODIFIED, "up"];
    }
}

const convertSortValuesToSortOption = (sortBy: SORT_BY, sortOrder: SORT_ORDER): SORT_OPTIONS => {
    return sortBy + '_' + (sortOrder === "up"? "DESC" : "ASC") as SORT_OPTIONS;
}

const Sorting = (props: SortingProps) => {
    const boardOverviewState = useSelector(makeGetBoardOverview());
    const [currentSortBy, currentSortOrder] = convertSortOptionToSortValues(boardOverviewState.sortBy);
    const [sort, setSort] = useState<SORT_ORDER>(currentSortOrder);

    const dispatch = useDispatch();

    useEffect(() => {
        if(currentSortOrder !== sort) {
            setSort(currentSortOrder);
        }
    }, [currentSortOrder]);

    const options: DropdownItemProps[] = [
        {key: SORT_BY.NAME, text: props.t('insp.overview.sort.'+SORT_BY.NAME), value: SORT_BY.NAME},
        {key: SORT_BY.CREATED_AT, text: props.t('insp.overview.sort.'+SORT_BY.CREATED_AT), value: SORT_BY.CREATED_AT},
        {key: SORT_BY.LAST_MODIFIED, text: props.t('insp.overview.sort.'+SORT_BY.LAST_MODIFIED), value: SORT_BY.LAST_MODIFIED},
    ]

    const handleSortChanged = (sortBy: SORT_BY): void => {
        dispatch(Action.Command.sortBoardOverview(convertSortValuesToSortOption(sortBy, sort)));
    }

    const handleSortOrderChanged = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if(sort === "up") {
            setSort("down")
        } else {
            setSort("up")
        }

        dispatch(Action.Command.sortBoardOverview(convertSortValuesToSortOption(currentSortBy, sort === "up"? "down" : "up")));
    }

    const checkIconClick = (e: React.KeyboardEvent<HTMLElement>) => {
        const clickedEl: HTMLElement = e.target as HTMLElement;
        if(clickedEl.tagName === 'I') {
            clickedEl.parentElement!.blur();
        }
    }

    return <Dropdown
        selection={true}
        options={options}
        fluid={true}
        value={currentSortBy}
        onClick={checkIconClick}
        onChange={(e, data) => handleSortChanged(data.value as SORT_BY)}
        icon={<Icon name={"sort amount "+sort as any} style={{float: "right", cursor: "pointer"}} onClick={handleSortOrderChanged} />}
        style={{paddingRight: "5px"}}
    />
};

export default withNamespaces()(Sorting);
