import {JSONSchema7} from "json-schema";
import {
  makeAssignVariable, makeCallService,
  makeDefinitions, makeExecuteRules, makeFilter,
  makeForEach, makeThenChangeInformation,
  makeThenRecordEvent,
  makeThenThrowError, makeThenTriggerCommand, withFilterTypes
} from "./rule-engine-definitions";

export const makePolicyRulesSchema = (possibleCommands: string[], possibleExternalSystems: string[], possibleStateRefs: string[]): string => {
  const PolicyRulesSchema: JSONSchema7 = {
    type: "array",
    items: {
      $ref: "#/definitions/rule_definition"
    },
    definitions: withFilterTypes(makeDefinitions(
      makeThenTriggerCommand(possibleCommands),
      makeCallService(possibleExternalSystems),
      makeThenThrowError(),
      makeAssignVariable(),
      makeForEach(),
      makeExecuteRules(),
      makeThenChangeInformation(possibleStateRefs),
    ))
  }

  return JSON.stringify(PolicyRulesSchema);
}
