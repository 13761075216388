import React, {useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {Confirm, Message} from "semantic-ui-react";
import {createBoardClient} from "../../../actions/commands";
import * as BoardModel from "../../../model/Board";

interface OwnProps {
    boardId: BoardModel.BoardId;
}

type BoardClientNotConfiguredMessageProps = OwnProps & WithNamespaces;

const BoardClientNotConfiguredMessage = (props: BoardClientNotConfiguredMessageProps) => {

    const dispatch = useDispatch();
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    const handleCreateBoardClientClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        setConfirmOpen(true);
    };

    const handleCreateBoardClient = () => {
        dispatch(createBoardClient(props.boardId));
        setConfirmOpen(false);
    };

    return (<>
        <Message>
            <p>
                {props.t('insp.board.client.notConfigured')}
                <a href={'#'} onClick={handleCreateBoardClientClick}> {props.t('insp.board.client.createClient')}</a>
            </p>
        </Message>
        <Confirm
            header={props.t('insp.board.client.createConfirmTitle')}
            content={props.t('insp.board.client.createConfirmText')}
            open={confirmOpen}
            onCancel={() => setConfirmOpen(false)}
            onConfirm={handleCreateBoardClient}
        />
    </>);
};

export default withNamespaces()(BoardClientNotConfiguredMessage);
