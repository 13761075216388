import {Record} from 'immutable';
import * as uuid from "uuid";
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {Email, UserId} from "../../User/model/UserInfo";
import {TeamId} from "./Team";

export const AGGREGATE_TYPE = 'TeamInvitation';

export type TeamInvitationId = string;
export type TeamInvitationError = string;

export enum Status {
    open = 'open',
    acknowledged = 'acknowledged',
    revoked = 'revoked',
}

export interface TeamInvitationProps {
    uid: TeamInvitationId;
    teamId: TeamId;
    admin: UserId;
    invitedUser: Email;
    status: Status;
    resendAttempts: number;
    aggregateType: AggregateType;
}

export const defaultTeamInvitationProps: TeamInvitationProps = {
    uid: '',
    teamId: '',
    admin: '',
    invitedUser: 'unknown@acme.com',
    status: Status.open,
    resendAttempts: 0,
    aggregateType: AGGREGATE_TYPE,
};

export const createTeamInvitationFromServerData = (data: any): Either<TeamInvitationError, TeamInvitation> => {
    const validatedData: Either<string, any> = validate(data,
        [hasKey('invitationId'), "invitationId missing"],
        [hasKey('teamId'), "teamId missing in "+data.invitationId],
        [hasKey('admin'), "admin missing in "+data.invitationId],
        [hasKey('invitedUser'), "invitedUser missing in "+data.invitationId],
        [hasKey('status'), "status missing in "+data.invitationId],
    );

    if(isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new TeamInvitation({
        ...data,
        uid: data.invitationId,
        aggregateType: AGGREGATE_TYPE,
    }));
};

export class TeamInvitation extends Record(defaultTeamInvitationProps) implements TeamInvitationProps, Aggregate {
    public constructor(data: TeamInvitationProps) {
        if (data.uid === '') {
            data.uid = uuid.v4()
        }

        super(data);
    }

    public resend(): TeamInvitation
    {
        return this.set("resendAttempts", this.resendAttempts + 1);
    }

    public revoke(): TeamInvitation
    {
        return this.set("status", Status.revoked);
    }
}