import * as React from 'react';
import {useState, useRef} from "react";
import JoinMeetingDialog from "./JitsiJoinMeetingDialog";
import Meeting from "./JitsiMeeting";

import {useSelector} from "react-redux";
import {makeGetSidebarSelector} from "../../../Layout/selectors/sidebar";
import {useGraph} from "../../hooks/useGraph";

interface OwnProps {
    sessionId: string;
    id: string;
    name: string;
    avatar: string;
    initialPosition: {left: number, top: number};
    loadingMeetingRoom: boolean;
    usageHints?: string[];
    graph: any;
    sidebar: any;
}

type MeetingLobbyProps = OwnProps;

const MeetingLobby = (props: MeetingLobbyProps) => {
    const [modalOpen, setModalOpen] = useState(true);
    const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>('');
    const [selectedAudioDevice, setSelectedAudioDevice] = useState<string>('');

    const [graph] = useGraph();
    const sidebar = useSelector(makeGetSidebarSelector());
    const timeoutRefEmoji = useRef<number>();
    const timeoutRefIsSpeaking = useRef<number>();

    const [customName, setCustomName] = useState<string>(props.name);

    const canJoin = () => {
        return selectedVideoDevice !== '' && selectedAudioDevice !== '';
    }

    const handleDevicesSelected = (devices: {audio: string, video: string}) => {
        setSelectedAudioDevice(devices.audio);
        setSelectedVideoDevice(devices.video);
        setModalOpen(false);
    }

    const handleCancel = () => {
        setModalOpen(false);
    }

    const handleNameChange = (name: string) => {
        setCustomName(name);
    }

    return (
        <>
            {canJoin() && <Meeting 
                sessionId={props.sessionId} 
                id={props.id} 
                name={customName} 
                avatar={props.avatar} 
                initialPosition={props.initialPosition} 
                graph={graph} sidebar={sidebar} 
                timeoutRefEmoji={timeoutRefEmoji}
                timeoutRefIsSpeaking={timeoutRefIsSpeaking}
                videoDevice={selectedVideoDevice}
                audioDevice={selectedAudioDevice}
            />}
            <JoinMeetingDialog open={modalOpen}
                name={props.name}
                onDevicesSelected={handleDevicesSelected}
                onCancel={handleCancel}
                onNameChange={handleNameChange}
                loadingMeetingRoom={props.loadingMeetingRoom}
                usageHints={props.usageHints || []}
           />
        </>
    )
};

export default MeetingLobby;
/* 
sessionId={props.sessionId}
id={props.id}
name={props.name}
avatar={props.avatar}
initialPosition={props.initialPosition}
audioDevice={selectedAudioDevice}
videoDevice={selectedVideoDevice} */
