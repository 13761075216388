import { Record } from 'immutable';

export const STATUS_CONNECTED = 'connected';
export const STATUS_CONNECTING = 'connecting';
export const STATUS_DISCONNECTED = 'disconnected';
export const STATUS_DISCONNECTING = 'disconnecting';
export const STATUS_CONNECTION_LOST = 'connectionLost';

export type SocketStatus = 'connected' | 'connecting' | 'disconnected' | 'connectionLost' | 'disconnecting';

export interface StatusProps {
    status: SocketStatus;
}

export interface StatusMethods {
    isConnected(): boolean;

    isDisconnected(): boolean;

    isConnecting(): boolean;

    isDisconnecting(): boolean;

    hasConnectionLost(): boolean;

    equals(other: Status): boolean;
}

export interface StatusRecord extends StatusProps, StatusMethods {
}

export const defaultUserProps: StatusProps = {
    status: STATUS_DISCONNECTED,
};

export const fromtString = (status: SocketStatus): Status => new Status({status});

export const disconnectedStatus = (): Status => new Status({status: STATUS_DISCONNECTED});

export const assertSocketStatus = (status: string): void => {
    switch (status) {
        case STATUS_CONNECTED:
        case STATUS_CONNECTING:
        case STATUS_DISCONNECTED:
        case STATUS_CONNECTION_LOST:
        case STATUS_DISCONNECTING:
            break;
        default:
            throw new Error('Invalid socket status "' + status + '" provided');
    }
};

export class Status extends Record(defaultUserProps) implements StatusRecord {
    public constructor(data: StatusProps) {
        assertSocketStatus(data.status);
        super(data);
    }

    public isConnected(): boolean {
        return this.status === STATUS_CONNECTED;
    }

    public isDisconnected(): boolean {
        return this.status === STATUS_DISCONNECTED;
    }

    public isConnecting(): boolean {
        return this.status === STATUS_CONNECTING;
    }

    public isDisconnecting(): boolean {
        return this.status === STATUS_DISCONNECTING;
    }

    public hasConnectionLost(): boolean {
        return this.status === STATUS_CONNECTION_LOST;
    }

    public equals(other: Status): boolean {
        return this.status === other.status;
    }
}
