import {AxiosPromise} from "axios";
import {
    avatarDeletionApiPromise, avatarUploadApiPromise,
    axiosApiPromise,
    ResponseType,
    userManagementApiPromise
} from '../../api/util';
import { UserModel } from '../../User';
import {Email, UserId} from "../model/UserInfo";
import {UserSettingsValue} from "../model/UserSettings";
import {OrganizationId} from "../../Organization/model/Organization";
import {SERVER_CONTEXT} from "./constants";

export const getUser = (userId: string): Promise<ResponseType> => {
    return axiosApiPromise('InspectIO.User', {userId});
};

export const syncUsername = (
    userId: string,
    username: string,
    email: string|null,
    avatarUrl: string|null,
    position: string|null
): Promise<ResponseType> => {
    email = email || null;
    avatarUrl = avatarUrl || null;
    position = position || null;

    return  axiosApiPromise(
        'InspectIO.SyncUserName',
        {userId, name: username, email, avatarUrl, position}
    );
};

export const getInspectioUsersInfo = (userIds: UserId[]): Promise<ResponseType> => {
    return axiosApiPromise('InspectIO.Users', {userIds});
};

export const getInspectioUserInfoByEmail = (email: Email): Promise<ResponseType> => {
    return axiosApiPromise('InspectIO.UserInfo', {email});
};

export const getUserSettings = (): Promise<ResponseType> => {
    return axiosApiPromise('InspectIO.UserSettings', {});
};

export const upsertUserSetting = (key: string, value: UserSettingsValue): Promise<ResponseType> => {
    return axiosApiPromise('InspectIO.UpsertUserSetting', {key, value: String(value)});
};

export const updateProfile = (user: UserModel.UserInfo): Promise<ResponseType> => {
    const data = {
        userId: user.uid,
        email: user.email,
        username: user.name,
        avatarUrl: user.avatarUrl,
        displayName: user.displayName,
        companyName: user.companyName,
        jobTitle: user.jobTitle
    };

    return userManagementApiPromise('update-profile', data);
};

export const updatePassword = (userId: string, password: string): Promise<ResponseType> => {
    const data = {
        userId,
        temporary: false,
        password
    };

    return userManagementApiPromise('reset-password', data);
};

export const uploadAvatar = (userId: string, file: FormData): AxiosPromise<any> => {
    return avatarUploadApiPromise(userId, file);
};

export const deleteAvatar = (userId: string): AxiosPromise<any> => {
    return avatarDeletionApiPromise(userId);
};

export const switchActiveOrganization = (userId: UserId, organizationId: OrganizationId | null): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'SwitchActiveOrganization', { userId, organizationId });
}
