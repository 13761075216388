import { call, fork, put, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ResponseType } from '../../api/util';
import { Action as NotifyAction } from '../../NotificationSystem/index';
import * as Action from '../actions/index';
import {createNewTeam} from "../model/Team";
import {loadUserTeams} from "./loadUserTeams";

type Command = ActionType<typeof Action.Command.addTeam>;

function* handleAddTeam(command: Command) {
    const {response, error}: ResponseType = yield call(Action.Api.addTeam, command.payload.teamId, command.payload.teamName, command.payload.organizationId);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not add team.'));
    }
    if (response) {
        const newTeam = createNewTeam(command.payload.teamId);
        yield put(Action.Event.teamAdded(newTeam.rename(command.payload.teamName)));

        yield put(NotifyAction.Command.info('Team created', 'Team created'));
        yield call(loadUserTeams, command.payload.teamId);
    }
}

export function* addTeam() {

    while (true) {
        const command: Command = yield take([
            Action.Type.ADD_TEAM
        ]);

        yield fork(handleAddTeam, command);
    }
}
