import {Device, OpenVidu, Publisher} from "openvidu-browser";
import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button, DropdownProps, Form, Modal, Select} from "semantic-ui-react";

interface OwnProps {
    onDevicesSelected: (devices: {audio: string, video: string}) => void;
    onCancel: () => void;
    open: boolean;
    loadingMeetingRoom: boolean;
}

type JoinMeetingDialogProps = OwnProps & WithNamespaces;

const JoinMeetingDialog = (props: JoinMeetingDialogProps) => {
    const openViduRef = useRef<OpenVidu>();
    const publisherRef = useRef<Publisher>();
    const videoRef = useRef(null);
    const [videoDevices, setVideoDevices] = useState<Device[]>([]);
    const [audioDevices, setAudioDevices] = useState<Device[]>([]);
    const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>('');
    const [selectedAudioDevice, setSelectedAudioDevice] = useState<string>('');

    useEffect(() => {
        openViduRef.current = new OpenVidu();

        publisherRef.current = openViduRef.current.initPublisher(undefined as any, {
            audioSource: undefined,
            publishAudio: false,
            publishVideo: true,
            resolution: '320x240',
            frameRate: 30,
            insertMode: 'APPEND',
            mirror: false,
        });

        publisherRef.current.addVideoElement(videoRef.current!);

        publisherRef.current.on('streamPlaying', () => {
            openViduRef.current!.getDevices().then(devices => {
                const tmpVideoDevices = devices.filter(device => device.kind === 'videoinput');
                const tmpAudioDevices = devices.filter(device => device.kind === 'audioinput');

                setVideoDevices(tmpVideoDevices);
                setAudioDevices(tmpAudioDevices);

                if (tmpVideoDevices.length > 0) {
                    setSelectedVideoDevice(tmpVideoDevices[0].deviceId);
                }

                if (tmpAudioDevices.length > 0) {
                    setSelectedAudioDevice(tmpAudioDevices[0].deviceId);
                }
            });
        });
    }, []);

    useEffect(() => {
        if (!openViduRef.current || !publisherRef.current || !selectedVideoDevice) {
            return;
        }

        openViduRef.current!.getUserMedia({
            videoSource: selectedVideoDevice,
            audioSource: undefined,
            publishAudio: false,
            publishVideo: true,
            resolution: '320x240',
            frameRate: 30,
            insertMode: 'APPEND',
            mirror: false,
        }).then(mediaStream => {
            const videoTrack = mediaStream.getVideoTracks()[0];
            publisherRef.current!.replaceTrack(videoTrack);
        });
    }, [selectedVideoDevice]);

    const joinMeeting = () => {
        if (props.loadingMeetingRoom) {
            return;
        }

        props.onDevicesSelected({audio: selectedAudioDevice, video: selectedVideoDevice});
    }

    const videoDeviceChoices = videoDevices.map(device => {
        return {key: device.deviceId, value: device.deviceId, text: device.label}
    });

    const audoDeviceChoices = audioDevices.map(device => {
        return {key: device.deviceId, value: device.deviceId, text: device.label}
    });

    return (
        <Modal open={props.open} onClose={props.onCancel} closeIcon={true}>
            <Modal.Header>Join Meeting</Modal.Header>
            <Modal.Content>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Form>
                        <Form.Field>
                            <label>Camera</label>
                            <Select
                                required={true}
                                value={selectedVideoDevice}
                                options={videoDeviceChoices}
                                onChange={(e, data) => setSelectedVideoDevice(data.value as string)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Microphone</label>
                            <Select
                                required={true}
                                value={selectedAudioDevice}
                                options={audoDeviceChoices}
                                onChange={(e, data) => setSelectedAudioDevice(data.value as string)}
                            />
                        </Form.Field>
                    </Form>
                    <div>
                        <video
                            autoPlay={true}
                            muted={true}
                            ref={videoRef}
                        />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    children={props.t('app.form.cancel')}
                    onClick={props.onCancel}
                />
                <Button
                    loading={props.loadingMeetingRoom}
                    primary={true}
                    children={'Join'}
                    onClick={joinMeeting}
                />
            </Modal.Actions>
        </Modal>);
};

export default withNamespaces()(JoinMeetingDialog);