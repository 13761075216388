import {default as UserProfile} from "../User/components/UserProfile";
import * as Action from "./actions";
import {default as AddBoard} from "./components/AddBoardModal";
import {default as BoardOverview} from "./components/BoardOverview";
import {default as EditBoard} from "./containers/EditBoardContainer";
import {default as MxGraphBoard} from "./containers/MxGraphBoardContainer";
import {BoardModel, BoardVersioning} from "./model";
import * as Saga from "./sagas";
import * as Selector from "./selectors";

export const MODULE = 'inspectioBoards';

export {
    MxGraphBoard,
    BoardOverview,
    AddBoard,
    EditBoard,
    Action,
    Saga,
    Selector,
    BoardModel,
    BoardVersioning,
    UserProfile
}
