const OBJ_SCHEMA = `{
    "type": "object",
    "properties": {
      
    },
    "required": [],
    "additionalProperties": false
  }`;

export const DEFAULT_TEMPLATE: {[index: string]: string} = {
    command: `\
{
  "newAggregate": false,
  "schema": ${OBJ_SCHEMA} 
}
`,
    event: `\
{
  "public": false,
  "schema": ${OBJ_SCHEMA}
}
`,
    aggregate: `\
{
  "schema": ${OBJ_SCHEMA}
}  
`,
    document: `\
{
  "schema": ${OBJ_SCHEMA}
}  
`,
    policy: `\
{
  "streams": []
}
`,
    role: `\
{
  
}
`,
    hotSpot: `\
{
  
}
`,
    externalSystem: `\
{
  
}
`,
    ui: `\
{
  
}
`,
    feature: `\
{
  
}
`,
    boundedContext: `\
{
  
}
`,
    siwmlanes: `\
{
  
}
`,
    icon: `\
{
  
}
`,
    image: `\
{
  
}
`,
    freeText: `\
{
  
}
`,
};
