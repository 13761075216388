import {Node, NodeType} from "../../../model/Graph";
import {isPartOfPolicyContext} from "../context/policy-context";
import {isDddActionUi} from "../ui/is-ddd-action-ui";

export const isDddActionOrPolicyFeature = (feature: Node): boolean => {
  let atLeastOneChildIsPartOfDDDActionOrPolicy = false;

  feature.children().forEach(child => {
    if(isPartOfPolicyContext(child)) {
      atLeastOneChildIsPartOfDDDActionOrPolicy = true;
    }

    switch (child.getType()) {
      case NodeType.ui:
        if(isDddActionUi(child)) {
          atLeastOneChildIsPartOfDDDActionOrPolicy = true;
        }
        break;
      case NodeType.command:
      case NodeType.aggregate:
      case NodeType.event:
        atLeastOneChildIsPartOfDDDActionOrPolicy = true;
        break;
    }
  })

  return atLeastOneChildIsPartOfDDDActionOrPolicy;
}
