import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {Button, Container, Select} from "semantic-ui-react";
import {convertJsonSchemaToEditorValue} from "../../../../core/convertJsonSchemaToEditorValue";
import * as Action from "../../../actions";
import {BoardId} from "../../../model/Board";
import {StickyTemplate, TemplateType} from "../../../model/StickyTemplate";
import BoardStickySchemaEditor from "./BoardStickySchemaEditor";
import {BoardStickyTemplateEditorRef} from "./BoardStickyTemplateEditor";

interface OwnProps {
    boardId: BoardId;
    boardStickyTemplate: StickyTemplate;
    onClose: () => void;
    onTemplateGenerated: (template: string) => void;
}

type BoardStickyAdvancedConfigProps = OwnProps & WithNamespaces;

const BoardStickyAdvancedConfig = (props: BoardStickyAdvancedConfigProps) => {

    const dispatch = useDispatch();
    const [templateType, setTemplateType] = useState<TemplateType>(props.boardStickyTemplate.templateType);
    const boardStickySchemaEditorRef = useRef<BoardStickyTemplateEditorRef|null>(null);

    useEffect(
        () => {
            setTemplateType(props.boardStickyTemplate.templateType);
        },
        [props.boardStickyTemplate]
    );

    const handleUpdateStickyAdvancedConfig = async () => {
        dispatch(Action.Command.updateStickyAdvancedConfig(
            props.boardId,
            props.boardStickyTemplate.cellType,
            templateType,
            templateType === 'json' && boardStickySchemaEditorRef.current
                ? boardStickySchemaEditorRef.current!.retrievePayload()
                : undefined
        ));
    };

    const handleGenerateDefaultValue = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        try {
            const rawSchema = templateType === 'json' && boardStickySchemaEditorRef.current
                ? boardStickySchemaEditorRef.current!.retrievePayload()
                : undefined;

            if(!rawSchema) {
                return;
            }

            const parsedSchema = JSON.parse(rawSchema);
            const newTemplate = JSON.stringify(convertJsonSchemaToEditorValue(parsedSchema), null, 2);

            props.onTemplateGenerated(newTemplate);

            window.setTimeout(() => {
                dispatch(Action.Command.updateStickyAdvancedConfig(
                    props.boardId,
                    props.boardStickyTemplate.cellType,
                    templateType,
                    rawSchema,
                    true
                ));
            }, 1000)
        } catch (e) {
            // tslint:disable-next-line
            console.error(e);
            return;
        }
    }

    return (<>
        <Container style={{ height: '333px' }}>
            <Select
                options={[
                    { key: 'json', value: 'json', text: 'JSON' },
                    { key: 'xml', value: 'xml', text: 'XML' },
                    { key: 'yaml', value: 'yaml', text: 'YAML' }
                ]}
                value={templateType}
                onChange={(event, data) => setTemplateType(data.value as TemplateType)}
            />
            {templateType === 'json' && (
                <BoardStickySchemaEditor
                    ref={boardStickySchemaEditorRef}
                    boardStickyTemplate={props.boardStickyTemplate}
                />
            )}
        </Container>
        {props.boardStickyTemplate.templateSchema && (
            <a href={'#'} onClick={handleGenerateDefaultValue}>
                {props.t('app.form.generateTemplate')}
            </a>

        )}
        <Button primary={true} onClick={handleUpdateStickyAdvancedConfig} content={props.t('app.form.save')} floated="right" />
        <Button onClick={props.onClose} content={props.t('app.form.back')} floated="right" />
    </>);
};

export default withNamespaces()(BoardStickyAdvancedConfig)
