import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import EditableTextline from "../../core/components/EditableTextline";
import {Action} from "../index";
import {Team} from "../model";
import {makeActiveUserOrganizationInfoSelector} from "../../Organization/selectors/selectUserOrganizationInfo";

interface OwnProps {
    team: Team.Team;
    addTeamMode?: boolean;
    onNameChanged?: (newName: Team.TeamName) => void;
    onCancelName?: () => void;
    onCancelDescription?: () => void;
}

type EditableTeamHeaderProps = OwnProps & WithNamespaces;

const INPUT_CHAR_SIZE = 9;
const CHAR_SIZE_UNIT = 'px';
const ADDITIONAL_CHAR_PADDING = 20;

const EditableTeamHeader = (props: EditableTeamHeaderProps) => {
    const activeOrganization = useSelector(makeActiveUserOrganizationInfoSelector());
    const dispatch = useDispatch();

    const handleNameChanged = (teamName: Team.TeamName) => {
        if(props.addTeamMode) {
            dispatch(Action.Command.addTeam(props.team.uid, teamName, activeOrganization? activeOrganization.uid : null));
            if(props.onNameChanged) {
                props.onNameChanged(teamName);
            }
        } else {
            dispatch(Action.Command.renameTeam(props.team.uid, teamName));
        }
    }

    const handleDescriptionChanged = (desc: Team.TeamDescription) => {
        dispatch(Action.Command.changeTeamDescription(props.team.uid, desc));
    }


    return <h3 style={{margin:0, cursor:"pointer"}} id={props.team.uid}>
        <EditableTextline
            text={props.team.name}
            charSize={INPUT_CHAR_SIZE}
            widthUnit={CHAR_SIZE_UNIT}
            padding={ADDITIONAL_CHAR_PADDING}
            required={true}
            onTextChanged={handleNameChanged}
            focus={props.addTeamMode}
            onCancel={props.onCancelName}
            placeholder={props.t('insp.team.team_name') as string}
        />
        <span> &mdash; </span>
        <span className="sub header" style={{margin:0, cursor:"pointer"}}><EditableTextline
            text={props.team.description}
            charSize={INPUT_CHAR_SIZE}
            widthUnit={CHAR_SIZE_UNIT}
            padding={ADDITIONAL_CHAR_PADDING}
            placeholder={props.t('insp.team.team_description') as string}
            onTextChanged={handleDescriptionChanged}
            onCancel={props.onCancelDescription}
        /></span>
    </h3>;
};

export default withNamespaces()(EditableTeamHeader);
