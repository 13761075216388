import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {NavLink} from "react-router-dom";
import {Icon, List, Menu, MenuItem, Popup} from "semantic-ui-react";
import * as Routes from "../../../routes";
import {useGraph} from "../../hooks/useGraph";
import {BoardId} from "../../model/Board";
import {Node, NodeType} from "../../model/Graph";
import {isValidLeaf} from "../BoardTreeView";
import LeafIcon from "../LeafIcon";
import MaxLengthLabel from "../MaxLengthLabel";
import BoardTree from "./BoardTree";
import {isEventModel} from "../../service/cody-wizard/context/feature-context";
import {HistoryCellType} from "../../model/HistoryEntry";

interface OwnProps {
    element: Node;
    boardId: BoardId;
    expand: boolean;
}

type BoardTreeLeafProps = OwnProps & WithNamespaces;

let dblClickTimer: any = null;

const BoardTreeLeaf = (props: BoardTreeLeafProps) => {

    const [clicks, setClicks] = useState(1);
    const [showChildren, setShowChildren] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [graph] = useGraph();
    const labelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setShowChildren(props.expand)
    }, [props.expand]);

    useEffect(() => {
        if(labelRef.current && !props.element.isLayer()) {
            const div = labelRef.current;
            const listItem = div.closest('.item');
            if(listItem) {
                const itemIcon = listItem.querySelector('i');
                if(itemIcon) {
                    graph.makeDraggable(itemIcon, props.element);
                }
            }

            graph.makeDraggable(div, props.element);


        }
    }, [props.element.getId()]);

    const locked = graph.isLocked(props.element);
    const visible = graph.isVisible(props.element);
    const visibleIcon = visible ? 'eye' : 'eye slash';
    const lockIcon = locked ? 'lock' : 'lock open';

    const handleItemClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        if(null !== dblClickTimer) {
            window.clearTimeout(dblClickTimer);
            dblClickTimer = null;
            setClicks(clicks + 1);
            return;
        }

        dblClickTimer = window.setTimeout(() => {

            setShowChildren(!showChildren);
            if(showSettings) {
                setShowSettings(false);
            }
            dblClickTimer = null;
        }, 200);


        if(visible && props.element.getType() !== NodeType.boundedContext && props.element.getType() !== NodeType.feature) {
            setClicks(clicks + 1);
        } else {
            e.preventDefault();
        }
    };

    const handleItemContextMenu = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setShowSettings(true);
    }

    const handleLockClick = () => {
        graph.setLocked(props.element, !locked);
        setClicks(clicks + 1);
    }

    const handleVisibleClick = () => {
        graph.setUserVisible(props.element, !visible);
        setClicks(clicks + 1);
    }

    const handleDeleteClick = () => {
        setDeleteMode(true);
    }

    const handleAbortDelete = () => {
        setDeleteMode(false);
        setShowSettings(false);
    }

    const handleConfirmDelete = () => {
        graph.deleteNode(props.element);
        setDeleteMode(false);
    }


    const canHaveChildren = props.element.getType() === NodeType.boundedContext || props.element.getType() === NodeType.feature;
    const validChildren = props.element.children().filter(isValidLeaf);
    const hiddenClass = visible? '' : ' hidden';


    return <>
        <List.Item as={ NavLink }
                          to={{pathname: Routes.compileInspectioBoardWorkspace(props.boardId), search: `?cells=${props.element.getId()}&clicks=${clicks}`}}
                          onClick={handleItemClick}
                          onContextMenu={handleItemContextMenu}
                          isActive={() => false}
                          style={{padding: "5px"}}
                          className={showSettings? 'edit' + hiddenClass : hiddenClass}
        >
            <LeafIcon type={isEventModel(props.element)? HistoryCellType.swimlanes : props.element.getType()} open={showChildren} />
            {locked && <Icon name={lockIcon} className="corner left" size="mini" fitted={true}/>}
            {!visible && <Icon name={visibleIcon} className="corner right" size="mini" fitted={true}/>}
            <List.Content>
                <Popup
                    position='bottom center'
                    closeOnTriggerBlur={false}
                    closeOnTriggerMouseLeave={false}
                    hideOnScroll={true}
                    open={showSettings}
                    onClose={() => setShowSettings(false)}
                    trigger={<div ref={labelRef}><MaxLengthLabel label={props.element.getName()} maxLength={30} /></div>}
                    style={{padding: "0px"}}
                    content={
                        <>
                            {!deleteMode && <Menu secondary={true} compact={true}>
                                <MenuItem
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleLockClick();
                                    }}
                                >
                                    <Icon name={lockIcon}/>
                                </MenuItem>
                                <MenuItem
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleVisibleClick();
                                    }}
                                >
                                    <Icon name={visibleIcon}/>
                                </MenuItem>
                                <MenuItem className="delete-ele"
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleDeleteClick();
                                    }}
                                >
                                    <Icon name="trash"/>
                                </MenuItem>
                            </Menu>
                            }
                            {deleteMode && <Menu secondary={true} compact={true}>
                                <MenuItem>
                                    {props.t('insp.board.layers.delete_confirmation')}
                                </MenuItem>
                                <MenuItem
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleConfirmDelete();
                                    }}
                                >
                                    {props.t('app.form.yes')}
                                </MenuItem>
                                <MenuItem
                                    onClick={e => {
                                        e.stopPropagation();
                                        handleAbortDelete();
                                    }}
                                >
                                    {props.t('app.form.no')}
                                </MenuItem>
                            </Menu>
                            }
                        </>
                    }
                />
                {validChildren.count() > 0
                    && showChildren
                    && <BoardTree leafs={validChildren} boardId={props.boardId} expandLeafs={props.expand} />}
                {validChildren.count() === 0
                    && canHaveChildren
                    && showChildren
                && <List><List.Item><span style={{color: '#747474'}}>(empty)</span></List.Item></List>}
            </List.Content>
        </List.Item>
    </>;
};

export default withNamespaces()(BoardTreeLeaf);
