import { call, fork, put, take } from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import * as Action from "../../User/actions";
import {standaloneStorageManager} from "../util";

type FlowAction = ActionType<typeof Action.Command.upsertUserSetting>;

function* onUpsertUserSetting(action: FlowAction) {
    yield call(standaloneStorageManager.setUserSetting, action.payload);
    yield put(Action.Event.userSettingUpserted(action.payload.key, action.payload.value));
}

export function* standaloneUpsertUserSetting() {
    while (true) {
        const action: FlowAction = yield take(Action.Type.UPSERT_USER_SETTING);

        yield fork(onUpsertUserSetting, action)
    }
}
