import * as React from 'react';
import {Table} from "semantic-ui-react";
import {BoardId} from "../../model/Board";
import BoardPermissionsForAnyoneTableEntry from "./BoardPermissionsForAnyoneTableEntry";
import BoardPermissionsForOrganizationMembersTableEntry from "./BoardPermissionsForOrganizationMembersTableEntry";

interface OwnProps {
  boardId: BoardId
}

type BoardPermissionsForAnyoneTableProps = OwnProps;

const BoardPermissionsForAnyoneTable = (props: BoardPermissionsForAnyoneTableProps) => {
  return <Table unstackable={true} basic="very">
    <Table.Body>
      <BoardPermissionsForOrganizationMembersTableEntry boardId={props.boardId} />
      <BoardPermissionsForAnyoneTableEntry boardId={props.boardId} />
    </Table.Body>
  </Table>
};

export default BoardPermissionsForAnyoneTable;
