import * as React from 'react';
import {useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {Redirect} from "react-router";
import {Button, Confirm, Dropdown, Menu, Popup} from "semantic-ui-react";
import * as BoardModule from "../../InspectioBoards";
import * as Route from "../../routes";
import * as Action from "../actions";
import {Team} from "../model/Team";


interface OwnProps {
    board: BoardModule.BoardModel.Board;
    team: Team;
    readonly?: boolean;
}

type BoardEntryProps = OwnProps & WithNamespaces;

const BoardEntry = (props: BoardEntryProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showRemoveBoardConfirm, setShowRemoveBoardConfirm] = useState(false);
    const [redirectToBoard, setRedirectToBoard] = useState(false);
    const dispatch = useDispatch();

    const openPopup = () => {
        setShowDropdown(true);
    };

    const closePopup = () => {
        setShowDropdown(false);
    };

    const removeBoardFromTeam = () => {
        if(!props.readonly) {
            dispatch(Action.Command.adminRevokeTeamAccessToBoard(props.board.uid, props.team.uid));
        }
        setShowRemoveBoardConfirm(false);
    };

    const handleBoardClick = () => {
        if(props.readonly) {
            setRedirectToBoard(true);
        }
    };

    if(redirectToBoard) {
        return <Redirect push={true} to={Route.compileInspectioBoardWorkspace(props.board.uid)} />
    }

    return <>
        <Popup
            open={showDropdown}
            onOpen={openPopup}
            onClose={closePopup}
            on="click"
            position="bottom center"
            disabled={props.readonly}
            content={<Menu secondary={true} vertical={true} compact={true}>
                <Menu.Item
                    content={props.t('app.open') as string}
                    onClick={() => setRedirectToBoard(true)}
                />
                <Menu.Item
                    content={props.t('app.form.remove') as string}
                    onClick={() => {
                        setShowDropdown(false);
                        setShowRemoveBoardConfirm(true);
                    }}
                />
            </Menu>}
            trigger={<div className="ui large basic orange button" key={props.board.uid} onClick={handleBoardClick}>
                <div>{ props.board.name }</div>
            </div>}
        />
        <Confirm
            open={showRemoveBoardConfirm}
            header={props.t('insp.team.remove_board_confirm_title')}
            content={props.t(
                'insp.team.remove_board_confirm_text',
                { board_name: props.board.name }
            )}
            confirmButton={props.t('insp.team.remove_board_confirm_button')}
            cancelButton={<Button content={props.t('app.form.cancel')} className="basic noborder" />}
            onCancel={() => setShowRemoveBoardConfirm(false)}
            onConfirm={removeBoardFromTeam}
        />
    </>;
};

export default withNamespaces()(BoardEntry);