import {Record} from 'immutable';
import {UserModel} from '../../User';

export interface BoardVersionProps {
    userId: UserModel.UserId;
    version: number;
    head?: boolean;
}

export const defaultBoardVersionProps: BoardVersionProps = {
    userId: '',
    version: 0,
    head: false,
}

export class BoardVersion extends Record(defaultBoardVersionProps) implements BoardVersionProps {
    public constructor(data: BoardVersionProps) {
        if(data.head === undefined || data.head === null) {
            data.head = false;
        }

        super(data);
    }

    public isHead(): boolean {
        return this.head || false;
    }

    public asHead(): BoardVersion {
        return this.set('head', true);
    }

    public equals(other: BoardVersion): boolean {
        return this.userId === other.userId && this.version === other.version;
    }

    public isNewerThan(other: BoardVersion): boolean {
        if(this.userId !== other.userId) {
            return true;
        }

        return this.version > other.version;
    }
}
