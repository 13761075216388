import * as React from 'react';
import {useState} from "react";
import {Link} from "react-router-dom";
import {Icon, Label, List} from "semantic-ui-react";
import * as Routes from "../../routes";
import {Palette} from "../../theme/palette";
import {HistoryCellType, HistoryEntry} from "../model/HistoryEntry";
import CellIcon from "./CellIcon";
import MaxLengthLabel from "./MaxLengthLabel";

interface OwnProps {
    entry: HistoryEntry;
    key: number;
    clicks: number;
    onClick: () => void;
}

const BoardHistoryEntry = (props: OwnProps) => {

    const [showAll, setShowAll] = useState(false);

    return <List.Item as={ Link }
                      key={props.key}
                      className="ui vertical segment"
                      style={{borderRadius:'0'}}
                      onClick={props.onClick}
                      to={{pathname: Routes.compileInspectioBoardWorkspace(props.entry.boardId), search: `?cells=${props.entry.cellId}&clicks=${props.clicks}`}}>
        <CellIcon cellType={props.entry.cellType} />
        <List.Content>
            <List.Header><MaxLengthLabel label={props.entry.label} maxLength={24} onClick={s => setShowAll(s)} /></List.Header>
            <List.Description>
                {`${props.entry.action} by ${props.entry.username}`}
            </List.Description><List.Description>
            <span className="date" style={{color:"grey", fontSize:"10px"}}>{ (new Date(props.entry.date)).toLocaleString() }</span>
        </List.Description>
        </List.Content>
        {showAll && <List.List style={{padding:'0'}}><List.Item><List.Description>{ props.entry.label }</List.Description></List.Item></List.List>}
    </List.Item>
};

export default BoardHistoryEntry;