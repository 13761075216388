import * as Action from "./actions";
import * as Model from "./model";
import * as Reducer from "./reducers";
import * as Saga from "./sagas";
import * as Selector from "./selectors";

const MODULE = 'inspectioTeams';

export {
    MODULE,
    Reducer,
    Action,
    Model,
    Saga,
    Selector
};
