import copy from "copy-to-clipboard";
import {List} from "immutable";
import * as _ from 'lodash';
import * as mxFactory from "mxgraph/javascript/dist/build";
import React, {Component} from "react";
import {RouteComponentProps} from "react-router";
import {Dimmer, Loader} from "semantic-ui-react";
import shortUUID from "short-uuid";
import uuid from "uuid";
import {default as socket} from "../../api/ConfiguredSocket";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as Routes from "../../routes";
import {Model as SocketModel} from "../../Socket";
import {UserModel} from "../../User";
import * as Action from "../actions";
import {useGraph} from "../hooks/useGraph";
import {MouseSync, useMouseSync} from "../hooks/useMouseSync";
import {BoardModel, BoardSync} from "../model";
import {BoardChanged} from "../model/BoardChanged";
import {BoardVersion} from "../model/BoardVersion";
import {
    deriveGraphFromMxgraph,
    flattenNodeChildren,
    Graph as IioGraph,
    MxGraphElement,
    Node,
    NodeType
} from "../model/Graph";
import {HistoryAction, HistoryCellType} from "../model/HistoryEntry";
import {IIO} from "../service/Cody";
import {RealtimeBoardSync} from "../service/RealtimeBoardSync";
import {Gesture, RemoteMouseSync} from "../service/RemoteMouseSync";
import {initializeGraphEditor} from "./MxGraphBoard/initializeGraphEditor";
import MxGraphBoardLink from "./MxGraphBoardLink";
import MxGraphImageUpload from "./MxGraphImageUpload";
import MxGraphTaskLink from "./MxGraphTaskLink";
import JitsiMeetingLobby from "./OpenVidu/JitsiMeetingLobby";
// import JitsiMeetingLobby from "@insecia/swarply-react"
import {makeGetSidebarSelector} from "../../Layout/selectors/sidebar";
import OpenViduMeetingLobby from "./OpenVidu/MeetingLobby";
import {BoardRouteMatchParams} from "./types";
import {isStandalone} from "../../standalone/util";
import {BoardAgent} from "../../BoardAgent/services/BoardAgent";
import WizardModal from "./CodyEngineWizard/WizardModal";
import {
    initializeCodyGraphSuggestions
} from "../service/cody-wizard/graph-suggestions/initialize-cody-graph-suggestions";

// Todo: Customized mxgraph js files are currently imported as scripts because they rely on globals. We need to change that!
declare const inspectioUtils: {getTypeInclEdge: (cell: any) => HistoryCellType | false, getLabelText: (cell: any) => string, isContainer: (cell: any) => boolean, isSlice: (cell: any) => boolean, isEventModel: (cell: any) => boolean, getMetadata: (cell: any) => string | undefined, getTags: (cell: any) => string[]};
declare const ispConst: any;
declare const EditorUi: any;
declare const Editor: any;
declare const RESOURCE_BASE: string;
declare const STYLE_PATH: string;
declare const Graph: any;
declare const urlParams: any;
declare const mxLanguage: string;
declare const mxResources: any;
declare const mxUtils: any;
declare const mxGraphModel: any;
declare const mxAutoSaveManager: any;
declare const mxCodec: any;
declare const mxUndoableEdit: any;
declare const mxEventObject: any;
declare const mxEvent: any;
declare const MXGRAPH_ROOT_UUIDS: string[];
declare const mxGeometryChange: any;
declare const mxChildChange: any;
declare const mxValueChange: any;
declare const mxTerminalChange: any;
declare const mxStyleChange: any;
declare const mxRectangle: any;
declare const mxPoint: any;

// Separate state props + dispatch props to their own interfaces.
export interface PropsFromState {
    board: BoardModel.Board,
    user: UserModel.UserInfo,
    socketStatus: SocketModel.StatusModel.Status,
    liteMode: boolean,
}

// We can use `typeof` here to map our dispatch types to the props, like so.
export interface PropsFromDispatch {
    fetchUserBoard: typeof Action.Query.fetchUserBoard,
    onError: typeof NotifyAction.Command.error,
    onInfo: typeof NotifyAction.Command.info,
    saveBoard: typeof Action.Command.saveBoard,
    onRemoteBoardChanged: typeof Action.Event.remoteBoardChanged,
    onLocalBoardChanged: typeof Action.Event.localBoardChanged,
    onBoardImported: typeof Action.Event.boardImported,
    onBoardImportStarted: typeof Action.Event.boardImportStarted,
    onBoardExported: typeof Action.Event.boardExported,
    onBoardInitialized: typeof Action.Event.boardInitialized,
    onLocalBoardUpdatedWithRemoteChange: typeof Action.Event.localBoardUpdatedWithRemoteChange,
    onActiveGraphElementChanged: typeof Action.Event.activeGraphElementChanged,
    onActiveGraphElementLabelChanged: typeof Action.Event.activeGraphElementLabelChanged,
    onActiveGraphElementMetadataChanged: typeof Action.Event.activeGraphElementMetadataChanged,
    clearBoardHistory: typeof Action.Command.clearBoardHistory,
    filterElementsTree: typeof Action.Command.filterElementsTree,
    toggleElementDetails: typeof Action.Command.toggleElementDetails,
    toggleCodyConsole: typeof Action.Command.toggleCodyConsole,
    togglePlayshotModal: typeof Action.Command.togglePlayshotModal,
}

interface OwnProps {}

export type MxGraphBoardProps = PropsFromState & PropsFromDispatch & OwnProps & RouteComponentProps<BoardRouteMatchParams>;

type ImageUploadedListener = (imageName: string, imageUrl: string) => void;
type BoardLinkListener = (link: string) => void;
type TaskLinkListener = (link: string) => void;

class MxGraphBoard extends Component<MxGraphBoardProps, {readyToJoinVidu: boolean}> {
    private mxGraph: any;
    private divGraph: React.RefObject<any>;
    private divDimmer: React.RefObject<any>;
    private editorUi: any;
    private codec: any;
    private initialized: boolean = false;
    private mouseSync: RemoteMouseSync | undefined;
    private boardSync: RealtimeBoardSync | undefined;
    private boardAgent: BoardAgent | undefined;
    private iioCody: IIO | undefined;
    private lastFocusedCells: string = '';
    private lastClicks: string|null = null;
    private uploadOpenHandle: any;
    private boardLinkHandle: any;
    private taskLinkHandle: any;
    private codyEngineWizardHandle: any;
    private imageUploadedListener: null | ImageUploadedListener = null;
    private boardLinkListener: null | BoardLinkListener = null;
    private taskLinkListener: null | TaskLinkListener = null;
    private setUsedGraph: (graph: IioGraph | undefined) => void;
    private setUsedMouseSync: (mouseSync: MouseSync | undefined) => void;
    private activeGraphElement: any;
    private viduEnabled = false;
    private jitsiEnabled = false;
    private viduUser: string | null = null;

    // Internal copy of the Board passed via props, to modify copy when editing the board without rerendering of the component
    private board: BoardModel.Board | undefined;

    private mxGraphWrapper: IioGraph | undefined;

    constructor(props: MxGraphBoardProps) {
        super(props);
        this.state = {
            readyToJoinVidu: false
        };
        this.LoadGraph = this
            .LoadGraph
            .bind(this);

        this.divGraph = React.createRef();
        this.divDimmer = React.createRef();
        this.codec = new mxCodec();
        this.codec.lookup = (id: string) => {
            const cell = this.editorUi.editor.graph.model.getCell(id);

            if(!cell) {
                return null;
            }

            return cell;
        }

        const [, setGraph] = useGraph();

        this.setUsedGraph = setGraph;

        const [, setMouseSync] = useMouseSync();

        this.setUsedMouseSync = setMouseSync;

        if(props.location.search) {
            const params = new URLSearchParams(props.location.search);
            if(params.get('vidu')) {
                this.viduEnabled = true;
                this.jitsiEnabled = false;
            }
            if(params.get('jitsi')) {
                this.jitsiEnabled = true;
                this.viduEnabled = false;
            }

            if(params.get('user')) {
                this.viduUser = params.get('user');
            }
        }
    }

    public componentDidMount() {
        this.props.fetchUserBoard(this.props.match.params.uid);
    }

    public shouldComponentUpdate(nextProps: MxGraphBoardProps, nextState: any): boolean {
        if(!this.board && nextProps.board && nextProps.user.name !== "") {
            if(!nextProps.board.detailsFetched) {
                // Fetching user board triggered in componentDidMount is not finished yet
                return false;
            }
            this.initGraphBoard(nextProps);
            return true;
        }

        if(this.board && this.board.uid !== nextProps.board.uid) {
            // Back btn was used to navigate back to a linking board. The BoardRedirect component forces remounting of MxGraphBoard
            nextProps.history.replace(Routes.BoardRedirect, {href: Routes.compileInspectioBoardWorkspace(nextProps.board.uid)});
        }

        if(this.editorUi && !this.board!.writeAccess && nextProps.board.writeAccess) {
            // Disable read only mode
            this.editorUi.editor.graph.setEnabled(true);
        }

        if(this.editorUi && this.props.liteMode !== nextProps.liteMode) {
            this.setLiteMode(nextProps);
        }

        if(this.board && this.board.videoSessionActive !== nextProps.board.videoSessionActive) {
            this.jitsiEnabled = !!nextProps.board.videoSessionActive;
            if(this.initialized && !this.state.readyToJoinVidu) {
                this.setState({readyToJoinVidu: true});
            }
            this.board = this.board.set('videoSessionActive', nextProps.board.videoSessionActive);
            return true;
        }

        if(this.editorUi && nextProps.board.shouldImport) {
            this.editorUi.actions.get('import').funct((graph: any) => {
                const xml = mxUtils.getXml(this.editorUi.editor.getGraphXml());
                this.board = this.board!.updateXml(xml, nextProps.user.uid);

                nextProps.onBoardImported(nextProps.board.uid, this.board, deriveGraphFromMxgraph(this.editorUi.editor.graph, this.editorUi));
            });
            nextProps.onBoardImportStarted(this.board!.uid);
        }

        if(this.editorUi && nextProps.board.shouldExport) {
            this.editorUi.actions.get('export').funct();
            nextProps.onBoardExported(nextProps.board.uid);
        }

        if(this.editorUi) {
            this.checkScrollToCells(nextProps);
        }

        if(!this.state.readyToJoinVidu && nextState.readyToJoinVidu) {
            console.log("Component should update since it is initialized now!");
            return true;
        }

        return false;
    }

    public componentWillUnmount() {
        if(this.editorUi) {
            this.editorUi.destroy();
        }
        this.setUsedGraph(undefined);
        this.mxGraphWrapper = undefined;
        this.setUsedMouseSync(undefined);
        document.body.style.overflow = 'auto';
        document.body.classList.remove('workspace');
        document.title = 'prooph board';

        const activeUsers = document.getElementById('topmenuUsers');
        if (activeUsers) {
            activeUsers.innerHTML = '';
        }

        if(this.props.board) {
            // Please note: this also resets the detailsFetched flag of the board to force the component to wait for
            // fresh details fetched from server when user revisits the board within the same session
            this.props.clearBoardHistory(this.props.board.uid);

        }

        if(this.boardSync) {
            this.boardSync.stopSync();
        }

        if(this.mouseSync) {
            this.mouseSync.stopSync({
                userId: this.props.user.uid,
                boardId: this.props.board.uid,
            });
        }

        if(this.boardAgent) {
            this.boardAgent.destroy();
        }

        if(this.iioCody) {
            this.iioCody.onSyncRequired(undefined);
            this.iioCody.disconnect();
        }
    }



    public render() {
        return (
            <>
                <div className="graph-container disable-user-select" ref={this.divGraph} id="divGraph" />
                <div ref={this.divDimmer} >
                    <Dimmer active={true} className="page"><Loader size="large">Loading Board</Loader></Dimmer>
                </div>
                {this.props.board && <MxGraphImageUpload boardId={this.props.board.uid}
                                    openHandle={openHandle => this.uploadOpenHandle = openHandle}
                                    onCancel={() => {
                                        this.uploadOpenHandle(false);
                                    }}
                                    onFileUploaded={(imageName, imageUrl) => {
                                        if(this.imageUploadedListener) {
                                            this.imageUploadedListener(imageName, imageUrl);
                                        }
                                    }}
                />}
                {this.props.board && <MxGraphBoardLink boardId={this.props.board.uid}
                                    openHandle={openHandle => this.boardLinkHandle = openHandle}
                                    onCancel={() => {
                                        this.boardLinkHandle(false);
                                    }}
                                    onLinkChosen={(link) => {
                                        if(this.boardLinkListener) {
                                            this.boardLinkListener(link);
                                        }
                                    }}
                />}
                {this.props.board && <MxGraphTaskLink
                                   openHandle={openHandle => this.taskLinkHandle = openHandle}
                                   onCancel={() => {
                                       this.taskLinkHandle(false);
                                   }}
                                   onLinkChosen={(link) => {
                                       if(this.taskLinkListener) {
                                           this.taskLinkListener(link);
                                       }
                                   }}
                />}
                {this.props.board && <WizardModal
                                    boardId={this.props.board.uid}
                                    openHandle={openHandle => this.codyEngineWizardHandle = openHandle}
                                    onClose={() => this.codyEngineWizardHandle(false, null)}
                />}
                {this.props.board && this.viduEnabled && <OpenViduMeetingLobby
                    sessionId={this.props.board.uid}
                    id={this.viduUser || this.props.user.uid}
                    name={this.props.user.name}
                    avatar={this.props.user.avatarUrl}
                    initialPosition={{left: Math.floor(window.innerWidth / 2), top: Math.floor(window.innerHeight / 2)}}
                    loadingMeetingRoom={!this.state.readyToJoinVidu}
                />}
                {this.props.board && this.jitsiEnabled && <JitsiMeetingLobby
                    sessionId={this.props.board.uid}
                    id={this.viduUser || this.props.user.uid}
                    name={this.props.user.name}
                    avatar={this.props.user.avatarUrl}
                    initialPosition={{left: Math.floor(window.innerWidth / 2), top: Math.floor(window.innerHeight / 2)}}
                    loadingMeetingRoom={!this.state.readyToJoinVidu}
                    graph={useGraph}
                    sidebar={makeGetSidebarSelector}
                    usageHints={[
                        'Keep Shift key pressed while moving the board to also move your video avatar.',
                        'The board context menu (right click anywhere on the board) offers quick video avatar navigation.',
                        'Use the megaphone to reach out to everybody no matter where they are on the board.',
                        'You get the most out of the video avatars when collaborating in small groups in parallel.'
                    ]}
                />}
            </>
        );
    }

    private initGraphBoard(props: MxGraphBoardProps) {
        window.scroll(0,0);
        document.body.style.overflow = 'hidden';
        document.body.classList.add('workspace');
        document.title = props.board.name + ' - prooph board';

        this.mxGraph = mxFactory();
        this.LoadGraph(props,{}, this.mxGraph);

        this.board = props.board;
    }

    private saveBoard() {
        if(this.board) {
            this.props.saveBoard(this.board, deriveGraphFromMxgraph(
                this.editorUi.editor.graph,
                this.editorUi
            ));
        }
    }

    private LoadGraph(props: MxGraphBoardProps, data: any, mxGraph: any) {
        const container = this.divGraph.current;

        container.innerHTML = '';

        mxUtils.alert = (e: string) => {
            this.props.onError('Board Error', e);
        };

        // The first two cells always get the same ids, because they are internal cells (board root and model root)
        // They are equal on all boards so that remote syncs work properly as well as imports and exports
        let uuidPool = _.clone(MXGRAPH_ROOT_UUIDS);
        const uuidT = shortUUID();

        mxGraphModel.prototype.createId = (cell: any): string => {
            const id = uuidPool.pop();

            if(id) {
                return id;
            }

            return uuidT.fromUUID(uuid.v4());
        }

        const editorUiInit = EditorUi.prototype.init;

        EditorUi.prototype.init = function()
        {
            editorUiInit.apply(this, arguments);
            this.actions.get('export').setEnabled(false);
        };

        // Adds required resources (disables loading of fallback properties, this can only
        // be used if we know that all keys are defined in the language specific file)
        mxResources.loadDefaultBundle = false;

        const bundle = mxResources.getDefaultBundle(RESOURCE_BASE, mxLanguage) ||
            mxResources.getSpecialBundle(RESOURCE_BASE, mxLanguage);

        // Fixes possible asynchronous requests
        mxGraph.mxUtils.getAll([bundle, STYLE_PATH + '/'+ispConst.THEME+'/style.xml'], (xhr: any) =>
        {
            // Adds bundle text to resources
            mxResources.parse(xhr[0].getText());

            // Configures the default graph theme
            const themes: any = {};

            themes[Graph.prototype.defaultThemeName] = xhr[1].getDocumentElement();

            // Main
            this.editorUi = new EditorUi(
              new Editor(urlParams.chrome === '0', themes, undefined, undefined, true, props.board.eventModelingEnabled),
              container,
              false,
              props.board.name,
              'boardSidebarContainer',
              props.board.eventModelingEnabled
            );

            initializeGraphEditor(
                this.editorUi,
                props.history,
                copy,
                Routes.makeWorkspaceDeeplinkFacory(props.board.uid),
                (title, msg, type) => {
                    if(type === 'info') {
                        props.onInfo(title, msg);
                    } else {
                        props.onError(title, msg);
                    }
                }
            );

            this.attachImageUploadListener();
            this.attachLinkBoardListener();
            this.attachLinkTaskListener();
            this.attachCodyEngineWizardListener();
            this.attachImageReplaceListener();
            this.attachCodySuggestionsListener();
            this.attachChangeActiveGraphElementListener();
            this.attachSyncLabelChangedListener();
            this.attachLookupElementListener(props);
            this.attachShowElementDetailsListener();
            this.attachCellConnectedListener();
            this.attachCodyListener();
            this.initializeAutoSaveManager(props);
            this.setLiteMode(props);
            this.setCodySuggestEnabled(props);
            this.setEventModelingEnabled(props);

            if(props.board.xml) {
                const graphDoc = mxUtils.parseXml(props.board.xml);
                this.editorUi.editor.graph.model.beginUpdateWithoutChangeNotifications();
                try {
                    this.editorUi.editor.graph.enableLazyTextPaint();
                    this.editorUi.editor.graph.importGraphModel(graphDoc.documentElement, undefined, undefined, undefined, false);
                }
                finally {
                    this.editorUi.editor.graph.model.endUpdateWithoutChangeNotifications();
                    this.editorUi.editor.graph.disableLazyTextPaint();
                }

                // Read only mode
                this.editorUi.editor.graph.setEnabled(props.board.writeAccess);
                this.editorUi.editor.graph.initContainerStyles();

                if(this.viduEnabled || this.jitsiEnabled) {
                    this.editorUi.editor.graph.zoom(1, true, false, false);
                } else {
                    this.editorUi.editor.graph.zoom(0.19, true, false, false);
                    this.editorUi.editor.graph.showAll();
                }

                if(props.location.search) {
                    const params = new URLSearchParams(props.location.search);
                    if(params.get('clicks')) {
                        this.lastClicks = params.get('clicks');
                    } else {
                        this.lastClicks = '0';
                    }
                }

                window.setTimeout(() => {
                    this.initialized = true;

                    this.setLiteMode(this.props);
                    this.setCodySuggestEnabled(this.props);
                    this.setEventModelingEnabled(this.props);
                    const cellsFocused = this.checkScrollToCells(props);
                    this.checkPlayshotsModal(props);

                    if(!cellsFocused && !this.viduEnabled && !this.jitsiEnabled) {
                        this.editorUi.editor.graph.center();
                    }

                    this.divDimmer.current.innerHTML = '';
                    this.initBoardAgent();
                }, props.location.search? 3000 : 1000);
            } else {
                this.initialized = true;
                if((this.viduEnabled || this.jitsiEnabled) && !this.state.readyToJoinVidu) {
                    console.log("set state initialized without timeout func");
                    this.setState({readyToJoinVidu: true});
                }
                this.divDimmer.current.innerHTML = '';
                this.initBoardAgent();
            }

            this.editorUi.editor.graph.setDefaultParent(this.editorUi.editor.graph.model.getCell(MXGRAPH_ROOT_UUIDS[0]));
            this.mxGraphWrapper = deriveGraphFromMxgraph(this.editorUi.editor.graph, this.editorUi);
            this.setUsedGraph(this.mxGraphWrapper);

            uuidPool = [];

            if(!isStandalone()) {
                this.initRealtimeBoardSync(props);
            }

            props.onBoardInitialized(props.board, deriveGraphFromMxgraph(
                this.editorUi.editor.graph,
                this.editorUi
            ));

            if(!isStandalone()) {
                this.initMouseSync(props);
            }

            this.initCody();
        })
    }

    private attachImageUploadListener (): void {
        this.editorUi.editor.graph.onInsertImage((processImageUrl: (imgName: string, imgUrl: string) => void) => {
            this.imageUploadedListener = (imgName: string, imgUrl: string) => {
                processImageUrl(imgName, imgUrl);
                this.uploadOpenHandle(false);
                this.imageUploadedListener = null;
            };
            this.uploadOpenHandle(true);
        });
    }

    private attachLinkBoardListener (): void {
        this.editorUi.editor.graph.onLinkBoard((currentLink: null|string, processBoardLink: (boardLink: string) => void) => {
            this.boardLinkListener = (boardLink: string) => {
                processBoardLink(boardLink);
                this.boardLinkHandle(false);
                this.boardLinkListener = null;
            };
            this.boardLinkHandle(true, currentLink);
        });
    }

    private attachLinkTaskListener (): void {
        this.editorUi.editor.graph.onLinkTask((currentLink: null|string, processTaskLink: (taskLink: string) => void) => {
            this.taskLinkListener = (taskLink: string) => {
                processTaskLink(taskLink);
                this.taskLinkHandle(false);
                this.taskLinkListener = null;
            };
            this.taskLinkHandle(true, currentLink);
        });
    }

    private attachCodyEngineWizardListener (): void {
        this.editorUi.editor.graph.onCodyWizard((selectedCell: any, runCody?: boolean) => {
            const deepLinkFactory = Routes.makeWorkspaceDeeplinkFacory(this.props.board!.uid);
            this.codyEngineWizardHandle(
              true,
              new MxGraphElement(selectedCell, this.editorUi.editor.graph.getModel(), this.editorUi.editor.graph, false, deepLinkFactory),
              runCody
            );
        });
    }

    private attachImageReplaceListener (): void {
        this.editorUi.editor.graph.onReplaceImage((imageId: null|string, processImageUrl: (imgName: string, imgUrl: string) => void) => {
            this.imageUploadedListener = (imgName, imgUrl: string) => {
                processImageUrl(imgName, imgUrl);
                this.uploadOpenHandle(false);
                this.imageUploadedListener = null;
            };
            this.uploadOpenHandle(true, imageId);
        });
    }

    private attachCodySuggestionsListener (): void {
        this.editorUi.editor.graph.setUpdateCodySuggestionsCb((cell: any) => {
            if(this.mxGraphWrapper) {
                const graphNode = this.mxGraphWrapper.getNode(cell.getId());
                if(graphNode) {
                    initializeCodyGraphSuggestions(graphNode, this.props.board, this.mxGraphWrapper);
                }
            }
        });
    }

    private attachChangeActiveGraphElementListener (): void {
        this.editorUi.editor.graph.onChangeActiveGraphElement((
            cell: any,
            updateMetadata: (schema: string, force?: boolean) => void,
            updateSimilarElements: (elementIds: string[], schema: string) => void,
            replaceTags: (tags: string[]) => void
        ) => {
            if(!cell) {
                this.activeGraphElement = undefined;
                this.props.onActiveGraphElementChanged(this.props.board.uid, undefined);
                return;
            }

            if(cell.isEdge()) {
                let edgeLabel = 'Edge';
                let edgeData;

                if(cell.source && cell.target) {
                    edgeLabel = inspectioUtils.getLabelText(cell.source) + ' -> ' + inspectioUtils.getLabelText(cell.target);
                    edgeData = {
                        source: cell.source.getId(),
                        target: cell.target.getId(),
                    };
                }

                this.props.onActiveGraphElementChanged(this.props.board.uid, {
                    id: cell.getId(),
                    type: NodeType.edge,
                    label: edgeLabel,
                    metadata: inspectioUtils.getMetadata(cell),
                    edgeData,
                    updateMetadata,
                    updateSimilarElements,
                    replaceTags,
                    locked: !this.editorUi.editor.graph.isCellEnabled(cell)
                });
                return;
            }

            if(cell.isVertex()) {
                this.props.onActiveGraphElementChanged(this.props.board.uid, {
                    id: cell.getId(),
                    type: inspectioUtils.isEventModel(cell)? HistoryCellType.swimlanes : inspectioUtils.getTypeInclEdge(cell) as NodeType,
                    label: inspectioUtils.getLabelText(cell),
                    metadata: inspectioUtils.getMetadata(cell),
                    tags: inspectioUtils.getTags(cell),
                    updateMetadata,
                    updateSimilarElements,
                    replaceTags,
                    locked: !this.editorUi.editor.graph.isCellEnabled(cell)
                });
            }

            this.activeGraphElement = cell;
        });
    }

    private attachSyncLabelChangedListener (): void {
        this.editorUi.editor.graph.addListener(mxEvent.LABEL_CHANGED, (sender: any, evt: any) => {
            const cell = evt.getProperty('cell');
            if(cell.isVertex() && this.activeGraphElement && this.activeGraphElement.getId() === cell.getId()) {
                this.props.onActiveGraphElementLabelChanged(this.props.board.uid, cell.getId(), inspectioUtils.getLabelText(cell));
            }
        });
    }

    private syncActiveGraphElementWithRemoteChange (change: any): void {
        if(change instanceof mxValueChange) {
            const cell = change.cell;
            if(cell && cell.isVertex() && this.activeGraphElement && this.activeGraphElement.getId() === cell.getId()) {
                this.props.onActiveGraphElementLabelChanged(this.props.board.uid, cell.getId(), inspectioUtils.getLabelText(cell));
                this.props.onActiveGraphElementMetadataChanged(this.props.board.uid, cell.getId(), inspectioUtils.getMetadata(cell));
            }
        }
    }

    private attachLookupElementListener (props: MxGraphBoardProps): void {
        this.editorUi.editor.graph.onLookupElement((elementLabel: string, elementType?: string) =>  {
            if(elementType) {
                elementLabel = `type:${elementType.toLowerCase()};${elementLabel}`;
            }
            props.filterElementsTree(props.board.uid, elementLabel, true)
        });
    }

    private initializeAutoSaveManager (props: MxGraphBoardProps): void {
        const mxAutoSaveGraphModelChanged = mxAutoSaveManager.prototype.graphModelChanged;

        const localAutoSave = new mxAutoSaveManager(this.editorUi.editor.graph);

        localAutoSave.autoSaveDelay = 1;
        localAutoSave.autoSaveThrottle = 1;
        localAutoSave.autoSaveThreshold = 1;
        localAutoSave.graphModelChanged = (changes: any) => {
            const nodes: any[] = [];


            const sortedChanges = this.sortChanges(changes);

            sortedChanges.forEach((change: any) => nodes.push(mxUtils.getXml(this.codec.encode(change))));

            if(this.boardSync) {
                this.board = this.board!.updateVersion(new BoardVersion({userId: props.user.uid, version: this.boardSync.currentVersion()}));
            } else {
                this.board = this.board!.updateVersion(new BoardVersion({userId: props.user.uid, version: this.board!.version.version + 1}));
            }

            const {action, type, label, cellId} = this.detectHistoryAttributesFromChanges(changes);

            const boardChanged = BoardSync.localBoardChanged(
                props.board.uid,
                List(nodes),
                this.boardSync? this.boardSync.currentVersion() : this.board!.version.version,
                props.user.uid,
                action,
                type,
                label,
                cellId
            );

            if(this.boardSync) {
                this.boardSync.publishChanges(boardChanged);
            } else {
                this.saveBoard();
            }

            this.syncChangesWithCody(boardChanged, changes).catch(err => console.error(err));

            props.onLocalBoardChanged(boardChanged);

            mxAutoSaveGraphModelChanged.call(localAutoSave, changes);
        };
    }

    private setLiteMode (props: MxGraphBoardProps): void {
        if(props.liteMode) {
            this.editorUi.editor.graph.enableLiteMode();
        } else {
            this.editorUi.editor.graph.disableLiteMode();
        }
    }

    private setCodySuggestEnabled (props: MxGraphBoardProps): void {
        if(props.board) {
            this.editorUi.editor.graph.setCodySuggestEnabled(props.board.codySuggestEnabled);
        }
    }

    private setEventModelingEnabled (props: MxGraphBoardProps): void {
        if(props.board) {
            this.editorUi.editor.graph.setEventModelingEnabled(props.board.eventModelingEnabled);
        }
    }

    private attachShowElementDetailsListener(): void {
        this.editorUi.editor.graph.onShowMetadata((focusMetadataEditor: boolean) => {
            this.props.toggleElementDetails(this.props.board.uid, true, focusMetadataEditor);
        })
    }

    private attachCellConnectedListener(): void {
        this.editorUi.editor.graph.addListener(mxEvent.CELL_CONNECTED, (sender: any, evt: any) => {
            const isTarget = !evt.getProperty('source');
            if(isTarget) {
                window.setTimeout(() => {
                    this.editorUi.editor.graph.setSelectionCell(evt.getProperty('terminal'));
                }, 10);
            }
        });
    }

    private checkPlayshotsModal (props: MxGraphBoardProps) {
        if(!this.initialized) {
            return false;
        }

        if(props.board.xml && props.location.search) {
            const params = new URLSearchParams(props.location.search);

            if(params.has('playshots') && !props.board.shouldShowPlayshotModal) {
                params.delete('playshots');
                props.togglePlayshotModal(props.board.uid, true);
            }
        }
    }

    private checkScrollToCells (props: MxGraphBoardProps): boolean {
        if(!this.initialized) {
            return false;
        }

        let cellsFocused = false;

        if(props.board.xml && props.location.search) {
            const params = new URLSearchParams(props.location.search);

            const graph = this.editorUi.editor.graph;
            const cellsToFocus = params.get('cells');

            if(cellsToFocus) {
                if(this.lastFocusedCells === cellsToFocus && this.lastClicks === params.get('clicks')) {

                    return  false;
                }

                const animateScroll = this.lastClicks !== null;

                this.lastFocusedCells = cellsToFocus;
                this.lastClicks = params.get('clicks');

                const cellIds = cellsToFocus.split(";");
                const cells: any[] = [];

                cellIds.forEach(cellId => {
                    const cell = graph.model.getCell(cellId);
                    if(cell) {
                        cells.push(cell);
                    }
                });

                if(cells.length > 0) {
                    graph.scrollCellsIntoView(cells, true, true, animateScroll);
                    cellsFocused = true;

                    // tslint:disable-next-line:radix
                    if(parseInt(params.get('select') || '0') === 1) {
                        graph.setSelectionCells(cells);
                    }
                }
            }
        }

        window.setTimeout(() => {
            if((this.viduEnabled || this.jitsiEnabled) && !this.state.readyToJoinVidu) {
                console.log("set state initialized in timout func");
                this.setState({readyToJoinVidu: true});
            }
        }, 1000);

        return cellsFocused;
    }

    private attachCodySyncListener (): void {
        if(this.iioCody) {
            this.iioCody.onSyncRequired(async () => {
                if(this.iioCody) {
                    const graph = this.editorUi.editor.graph;
                    const cells = graph.getAllVertexElementsOfActiveLayer();
                    let chunk: Node[] = [];
                    const deepLinkFactory = Routes.makeWorkspaceDeeplinkFacory(this.props.board!.uid);

                    if(cells && Array.isArray(cells)) {
                        for(const cell of cells) {
                            chunk.push(...flattenNodeChildren([new MxGraphElement(cell, graph.getModel(), graph, false, deepLinkFactory)]))
                            if(chunk.length >= 10) {
                                const syncSuccess = await this.iioCody!.syncNodes(this.props.board.uid, chunk);
                                if(!syncSuccess) {
                                    return;
                                }

                                chunk = [];
                            }
                        }

                        if(chunk.length > 0) {
                            const syncLastChunkSuccess = await this.iioCody!.syncNodes(this.props.board.uid, chunk);
                            if(!syncLastChunkSuccess) {
                                return false;
                            }
                        }
                    }

                    this.iioCody.syncFinished();
                }
            })
        }
    }

    private attachCodyListener (): void {
        const graph = this.editorUi.editor.graph;
        graph.onTriggerCody((cells: any[]) => {
            if(this.props.board && !this.props.board.shouldShowCodyConsole) {
                this.props.toggleCodyConsole(this.props.board.uid, true);
            }

            if(this.iioCody) {
                const nodes = cells.map(cell => this.makeGraphElement(cell));
                this.iioCody.trigger.Cody(
                  this.props.board.uid,
                  this.props.board.name,
                  this.props.user.uid,
                  flattenNodeChildren(nodes).filter(node => node.getType() !== NodeType.edge)
                );
            }
        })

        graph.onMergeSchema((sourceCell: any, targetCell: any) => {
            if(this.mxGraphWrapper) {
                this.mxGraphWrapper.mergeIntoSchema(
                  this.makeGraphElement(sourceCell),
                  this.makeGraphElement(targetCell)
                )
            }
        });
    }

    private makeGraphElement(cell: any): MxGraphElement {
        const graph = this.editorUi.editor.graph;
        return new MxGraphElement(cell, graph.getModel(), graph, false, Routes.makeWorkspaceDeeplinkFacory(this.props.board!.uid));
    }

    private initRealtimeBoardSync(props: MxGraphBoardProps) {
        this.boardSync = new RealtimeBoardSync(
            socket,
            props.board,
            props.user.uid,
            props.user.displayName,
            (boardChanged, syncInitialized) => {
                const changes: any[] = [];

                if(boardChanged.action === HistoryAction.added && this.editorUi.editor.graph.model.getCell(boardChanged.cellId)) {
                    console.log("[BoardSync] skipping patch, because it wants to add a cell that already exists: ", boardChanged.toJS());
                    return;
                }

                boardChanged.changeSet.forEach(patch => {
                    const node = mxUtils.parseXml(patch);
                    const change = this.codec.decode(node.documentElement);
                    change.model = this.editorUi.editor.graph.model;
                    change.execute();
                    changes.push(change);
                    this.syncActiveGraphElementWithRemoteChange(change);
                });

                const edit = new mxUndoableEdit(this.editorUi.editor.graph.model, false);
                edit.changes = changes;

                edit.notify = () =>
                {
                    edit.source.fireEvent(new mxEventObject(mxEvent.CHANGE,
                        'edit', edit, 'changes', edit.changes));

                }

                this.editorUi.editor.graph.model.fireEvent(new mxEventObject(mxEvent.CHANGE,
                    'edit', edit, 'changes', changes));

                this.syncChangesWithCody(boardChanged, changes).catch(err => console.error(err));

                console.log("[BoardSync] Applied remote board change: ", boardChanged.toBoardVersion().version);

                this.board = this.board!.updateVersion(new BoardVersion({userId: props.user.uid, version: this.boardSync!.currentVersion()}));

                props.onLocalBoardUpdatedWithRemoteChange(this.board.uid, this.board, deriveGraphFromMxgraph(this.editorUi.editor.graph, this.editorUi));

                if(syncInitialized) {
                    // If sync is not initialized, the patch is not a new remote change, but only a patch newer than latest snapshot
                    props.onRemoteBoardChanged(boardChanged);
                }
            },
            () => {
                this.board = this.board!.updateVersion(new BoardVersion({userId: props.user.uid, version: this.boardSync!.currentVersion()}));
                this.saveBoard();
            }
        );

        this.boardSync.startSync();
    }

    private initBoardAgent() {
        if(!isStandalone()) {
            console.log("[BoardAgent] Init board agent")
            this.boardAgent = new BoardAgent(deriveGraphFromMxgraph(this.editorUi.editor.graph, this.editorUi), socket);
            this.boardAgent.startWatching();
        }
    }

    private initCody() {
        this.iioCody = IIO;
        this.attachCodySyncListener();
    }

    private initMouseSync(props: MxGraphBoardProps) {
        let topMenuHeight = 0;
        const graph = this.editorUi.editor.graph;
        const topMenu = document.getElementById('topmenu');

        if(topMenu) {
            topMenuHeight = topMenu.clientHeight;
        }

        this.mouseSync = new RemoteMouseSync(socket, this.divGraph.current, graph, (point) => {
            const scale = graph.view.scale;
            const tr = graph.view.translate;

            const x = (point.x + tr.x) * scale;
            const y = (point.y + tr.y) * scale + topMenuHeight;

            return {x,y};
        });

        this.mouseSync.onUserJoined((userId, name, avatar, color) => this.addActiveUser(props, userId, name, avatar, color));
        this.mouseSync.onUserLeft(userId => this.removeActiveUser(userId));

        this.mouseSync.startSync({
            userId: props.user.uid,
            name: props.user.displayName,
            avatar: props.user.avatarUrl,
            boardId: props.board.uid,
        });

        graph.addListener(mxEvent.FIRE_MOUSE_EVENT, (sender: any, evt: any) => {
            const evtName = evt.getProperty('eventName');
            const me = evt.getProperty('event');

            if(!me) {
                return;
            }

            const scale = graph.view.scale;
            const tr = graph.view.translate;
            const x = me.getGraphX() / scale - tr.x;
            const y = me.getGraphY() / scale - tr.y;

            if(evtName === mxEvent.MOUSE_MOVE && this.mouseSync && !graph.panningHandler.isActive() && !graph.panningHandler.physicsActive) {
                this.mouseSync.emitUserMouseMove(   {
                    userId: props.user.uid,
                    boardId: props.board.uid,
                    point: {x,y},
                    vT: tr,
                    scale,
                    isPanning: false,
                })
            }
        });

        graph.addListener(graph.EVT_USER_IS_PANNING, (sender: any, evt: any) => {
            if(this.mouseSync) {
                const me = evt.getProperty('me');
                const scale = evt.getProperty('scale');
                const tr = evt.getProperty('translate');
                const mouseDelta = evt.getProperty('mouseDelta');

                if(!me) {
                    return;
                }

                const x = me.getGraphX() / scale - tr.x;
                const y = me.getGraphY() / scale - tr.y;

                this.mouseSync.emitUserMouseMove({
                    userId: props.user.uid,
                    boardId: props.board.uid,
                    point: {x,y},
                    vT: tr,
                    scale,
                    isPanning: true,
                }, mouseDelta)
            }
        });

        graph.addListener(graph.EVT_PANNING_PHYSICS_CLEARED, () => {
            if(this.mouseSync) {
                this.mouseSync.syncLastKnownAbsolutePointsAfterPanning();
            }
        })


        graph.addListener(graph.EVT_USER_IS_ZOOMING, (sender: any, evt: any) => {
            this.mouseSync!.emitUserMouseScroll({
                userId: props.user.uid,
                boardId: props.board.uid,
                vT: graph.view.translate,
                scale: graph.view.scale,
            })
        });

        graph.addListener(graph.EVT_USER_IS_TYPING, (sender: any, evt: any) => {
            const isTyping = evt.getProperty('typing');
            this.mouseSync!.emitUserGestureChanged({
                userId: props.user.uid,
                boardId: props.board.uid,
                gesture: isTyping? Gesture.Typing : Gesture.Default,
            });
        });

        graph.addListener(graph.EVT_USER_IS_MOVING, (sender: any, evt: any) => {
            const isMoving = evt.getProperty('moving');
            this.mouseSync!.emitUserGestureChanged({
                userId: props.user.uid,
                boardId: props.board.uid,
                gesture: isMoving? Gesture.Moving : Gesture.Default,
            });
        });

        graph.addListener(graph.EVT_USER_IS_AUTO_SCROLLING, (sender: any, evt: any) => {
           if(this.mouseSync) {
               this.mouseSync.emitUserIsAutoScrolling({
                   userId: props.user.uid,
                   boardId: props.board.uid,
                   scale: evt.getProperty('scale'),
                   translate: evt.getProperty('translate'),
                   isPanning: evt.getProperty('isPanning'),
               });
           }
        });

        this.setUsedMouseSync(this.mouseSync);
    }

    private addActiveUser(props: MxGraphBoardProps, userId: UserModel.UserId, name: UserModel.DisplayName, avatar: UserModel.AvatarUrl, color: string) {
        if(userId === props.user.uid) {
            return;
        }

        console.log("[mousesync] Adding active user to topmenu: ", userId);
        const topMenuUsers = document.getElementById('topmenuUsers');

        const item = document.createElement('div');
        item.classList.add('item');

        const obsClass = 'observable';

        const label = document.createElement('div');
        label.id = 'topmenuUsers-' + userId;
        label.classList.add('ui', 'circular', 'label', obsClass, 'user');
        label.style.backgroundColor = color;

        label.style.backgroundSize = 'cover';
        if(avatar !== ""){
            label.style.backgroundImage = "url('"+avatar+"')";
        } else {
            label.innerText = name[0].toUpperCase();
        }
        label.setAttribute('data-tooltip', name);
        label.setAttribute('data-position', 'bottom center');

        label.addEventListener('click', e => this.toggleObserveUser(e.target!));

        item.append(label);
        topMenuUsers!.append(item);
    }

    private removeActiveUser(userId: UserModel.UserId): void {
        console.log("[mousesync] Removing active user from topmenu: ", userId);
        const label = document.getElementById('topmenuUsers-'+userId);

        if(label) {
            label.parentElement!.remove();
        }
    }

    private toggleObserveUser(label: EventTarget) {
        if(label instanceof HTMLElement) {
            if(label.classList.contains('observed')) {
                label.classList.remove('observed');
                this.mouseSync!.stopObservingUser();
            } else {
                this.unsetCurrentObservedUser();
                this.mouseSync!.stopObservingUser();
                label.classList.add('observed');
                this.mouseSync!.observeUser(this.getUserIdFromLabelId(label.id))
            }
        }
    }

    private unsetCurrentObservedUser() {
        const topMenuUsers = document.getElementById('topmenuUsers');

        topMenuUsers!.childNodes.forEach(child => {
            if(child.firstChild instanceof HTMLElement) {
                child.firstChild.classList.remove('observed');
            }
        })
    }

    private getUserIdFromLabelId(labelId: string): UserModel.UserId {
        return labelId.replace('topmenuUsers-', '');
    }

    private syncChangesWithCody = async (transaction: BoardChanged, changes: any[]): Promise<void> => {
        if(!this.iioCody || !this.iioCody.syncRequired()) {
            console.log("Skipping sync with cody, because no server is listening for syncs at the moment", transaction)
            return;
        }

        console.log("syncing changes with cody", transaction.toJS());

        const graph = this.editorUi.editor.graph;
        const boardId = this.props.board.uid;
        const deepLinkFactory = Routes.makeWorkspaceDeeplinkFacory(boardId);

        if(transaction.action === HistoryAction.deleted) {
            const deletedCellIds: string[] = [];
            const deletedEdges: any[] = [];
            for(const change of changes) {
                const deletedCell = change.child;

                if(deletedCell) {
                    if(inspectioUtils.getTypeInclEdge(deletedCell) === HistoryCellType.edge) {
                        deletedEdges.push(deletedCell);
                        continue;
                    }

                    const deletedCells = graph.getVerticesTree(deletedCell);

                    deletedCellIds.push(...deletedCells.map((cell: any) => cell.getId()));

                    if(deletedCells.length) {
                        await this.iioCody!.syncDeletedNodes(boardId, deletedCells.map(
                            (cell: any) => new MxGraphElement(cell, graph.getModel(), graph, false, deepLinkFactory)
                            )
                        )
                    }
                }
            }

            for(const deletedEdge of deletedEdges) {
                const edgeNodes = [];
                if(deletedEdge.source && !deletedCellIds.includes(deletedEdge.source.getId())) {
                    edgeNodes.push(new MxGraphElement(deletedEdge.source, graph.getModel(), graph, false, deepLinkFactory));
                }
                if(deletedEdge.target && !deletedCellIds.includes(deletedEdge.target.getId())) {
                    edgeNodes.push(new MxGraphElement(deletedEdge.target, graph.getModel(), graph, false, deepLinkFactory));
                }
                if(edgeNodes.length) {
                    await this.iioCody!.syncChangedNodes(boardId, edgeNodes)
                }
            }

            return;
        } // End of HistoryAction.deleted

        if(!transaction.cellId) {
            console.log("no cellId given for transaction")
            return;
        }

        const transactionCellIds = transaction.cellId.split(";");
        const effectedCells: Node[] = [];
        const effectedCellIds: string[] = [];

        for(const cellId of transactionCellIds) {
            const cell = graph.model.getCell(cellId);

            if(!cell) {
                console.log("skipping cellId, cause not found in graph model", cellId)
                continue;
            }

            if(inspectioUtils.getTypeInclEdge(cell) === HistoryCellType.edge) {
                console.log("change effected edge", cell);
                if(cell.source && !effectedCellIds.includes(cell.source.getId())) {
                    console.log("adding edge source to effected cells");
                    effectedCellIds.push(cell.source.getId());
                    effectedCells.push(new MxGraphElement(cell.source, graph.getModel(), graph, false, deepLinkFactory));
                }

                if(cell.target && !effectedCellIds.includes(cell.target.getId())) {
                    console.log("adding edge target to effected cells")
                    effectedCellIds.push(cell.target.getId());
                    effectedCells.push(new MxGraphElement(cell.target, graph.getModel(), graph, false, deepLinkFactory));
                }

                continue;
            }

            if(!graph.isVertex(cell)) {
                const vertexChildren = graph.getVerticesTree(cell);

                for(const vertexChild of vertexChildren) {
                    if(!effectedCellIds.includes(vertexChild.getId())) {
                        effectedCellIds.push(vertexChild.getId());
                        effectedCells.push(new MxGraphElement(vertexChild, graph.getModel(), graph, false, deepLinkFactory));
                    }
                }

                continue;
            }

            if(!effectedCellIds.includes(cell.getId())) {
                effectedCellIds.push(cell.getId());
                effectedCells.push(new MxGraphElement(cell, graph.getModel(), graph, false, deepLinkFactory));
            }
        }

        await this.iioCody!.syncChangedNodes(boardId, effectedCells)
    }

    private sortChanges(changes: any[]): any[] {
        if(changes.length <= 1) {
            return changes;
        }

        const otherChanges: any[] = [];
        const edgeChanged: any[] = [];

        changes.forEach((change) => {
            if(change instanceof mxGeometryChange) {
                // Workaround for mxGraph bug, newly created edges have edge = true, after xml serialization it's edge = 1
                if(change.cell.edge === 1 || change.cell.edge === true) {
                    edgeChanged.push(change);
                } else {
                    otherChanges.push(change);
                }
            } else if(change instanceof mxChildChange) {
                if(change.child.edge === 1 || change.child.edge === true) {
                    edgeChanged.push(change);
                } else {
                    otherChanges.push(change);
                }
            } else {
                otherChanges.push(change);
            }
        });

        otherChanges.push(...edgeChanged);
        return otherChanges;
    }

    private detectHistoryAttributesFromChanges(changes: any[]): {action: HistoryAction, type: HistoryCellType, label: string, cellId?: string} {
        changes = this.filterOutMxStyleChange(changes);

        // Sticky added via Board Agent (graph.importCells())
        if(changes.length === 2 && changes[0] instanceof mxGeometryChange && changes[1] instanceof mxChildChange) {
            return {
                action: changes[1].previous === null? HistoryAction.added : HistoryAction.moved,
                ...this.detectHistoryCellTypeAndLabelFromChange(changes[1])
            }
        }

        // Sticky added or moved out of container
        if(changes.length === 3 && changes[0] instanceof mxGeometryChange
            && changes[1] instanceof mxGeometryChange && changes[2] instanceof mxChildChange) {
            return {
                action: changes[2].previous === null? HistoryAction.added : HistoryAction.moved,
                ...this.detectHistoryCellTypeAndLabelFromChange(changes[2])
            }
        }

        // Element moved
        if(changes.length === 1 && changes[0] instanceof mxGeometryChange) {
            return {
                action: HistoryAction.moved,
                ...this.detectHistoryCellTypeAndLabelFromChange(changes[0])
            }
        }

        // Element deleted
        if(changes.length === 1 && changes[0] instanceof mxChildChange && changes[0].parent === null) {
            return  {
                action: HistoryAction.deleted,
                ...this.detectHistoryCellTypeAndLabelFromChange(changes[0])
            }
        }

        // Element edited
        if(changes.length === 1 && changes[0] instanceof mxValueChange) {
            return  {
                action: HistoryAction.edited,
                ...this.detectHistoryCellTypeAndLabelFromChange(changes[0])
            }
        }

        // Element edited and autosized
        if(changes.length === 2 && changes[0] instanceof mxValueChange && changes[1] instanceof mxGeometryChange) {
            return  {
                action: HistoryAction.edited,
                ...this.detectHistoryCellTypeAndLabelFromChange(changes[0])
            }
        }

        // Edge added (source and target have different parents -> 4 changes, source and target have same parent -> 5 changes, different parents, but same grand parent -> 6 changes)
        if((changes.length >= 4) && changes[1] instanceof mxTerminalChange && changes[2] instanceof mxTerminalChange) {
            return  {
                action: HistoryAction.added,
                // First change is the new edge added to the board
                ...this.detectHistoryCellTypeAndLabelFromChange(changes[0])
            }
        }

        // All moved
        let allMoved = true;
        let cellIds: string[] = [];

        changes.forEach(change => {
            if(!(change instanceof mxGeometryChange)) {
                allMoved = false;
            } else {
                cellIds.push(change.cell.id);
            }
        });

        if(allMoved) {
            return {
                action: HistoryAction.moved,
                type: HistoryCellType.multiple,
                label: '',
                cellId: cellIds.join(";")
            }
        }

        // All deleted
        let allDeleted = true;
        cellIds = [];

        changes.forEach(change => {
            if(!(change instanceof mxChildChange && change.parent === null)) {
                allDeleted = false;
            } else {
                cellIds.push(change.child.id);
            }
        });

        if(allDeleted) {
            return {
                action: HistoryAction.deleted,
                type: HistoryCellType.multiple,
                label: '',
                cellId: cellIds.join(";")
            }
        }

        // All added to container
        let allAddedToContainer = true;
        let parent: any = null;

        changes.forEach((change, i) => {
            if(i % 2 === 0) {
                if(!(change instanceof mxChildChange && changes[i+1] instanceof mxGeometryChange
                    && ((parent === null || parent === change.parent) || change.child.edge === 1) // <-- Edge parent is not the container
                    && (inspectioUtils.isContainer(change.parent) || change.child.edge === 1))) {
                    allAddedToContainer = false;
                } else {
                    if(change.child.edge !== 1) {
                        parent = change.parent;
                    }
                }
            }
        });

        if(parent && allAddedToContainer) {
            return {
                action: HistoryAction.edited,
                type: inspectioUtils.getTypeInclEdge(parent) || HistoryCellType.misc,
                label: inspectioUtils.getLabelText(parent),
                cellId: parent.id
            }
        }

        // Container deleted that contains edges
        if(changes[changes.length -1] instanceof mxChildChange && changes[changes.length - 1].parent === null) {
            const deletedContainer = changes[changes.length -1].child;
            return {
                action: HistoryAction.deleted,
                type: inspectioUtils.getTypeInclEdge(deletedContainer) || HistoryCellType.misc,
                label: inspectioUtils.getLabelText(deletedContainer),
                cellId: deletedContainer.id
            }
        }

        // All moved out of container
        let allMovedOutOfContainer = true;
        cellIds = [];

        changes.forEach((change, i) => {
            if(!(change instanceof mxGeometryChange || change instanceof mxChildChange)) {
                allMovedOutOfContainer = false;
            } else {
                if(change instanceof mxGeometryChange) {
                    cellIds.push(change.cell.id);
                }
            }
        });

        if(allMovedOutOfContainer) {
            return {
                action: HistoryAction.moved,
                type: HistoryCellType.multiple,
                label: '',
                cellId: cellIds.join(";")
            }
        }

        // Default
        return {
            action: HistoryAction.edited,
            type: HistoryCellType.misc,
            label: '',
        };
    }

    private detectHistoryCellTypeAndLabelFromChange(change: any): {type: HistoryCellType, label: string, cellId?: string} {
        let type: HistoryCellType | boolean = false;
        let label: string = '';
        let cellId: undefined;

        if(change.cell) {
            type = inspectioUtils.getTypeInclEdge(change.cell);
            label = inspectioUtils.getLabelText(change.cell);
            cellId = change.cell.id;
        } else if(change.child) {
            type = inspectioUtils.getTypeInclEdge(change.child);
            label = inspectioUtils.getLabelText(change.child);
            cellId = change.child.id;
        }

        if(!type) {
            type = HistoryCellType.misc;
        }

        return {
            type,
            label,
            cellId,
        }
    }

    private filterOutMxStyleChange(changes: any[]): any[] {
        return changes.filter(change => !(change instanceof mxStyleChange));
    }
}

export default MxGraphBoard;
