import {call, delay, put, select, take} from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ResponseType } from '../../api/util';
import { Action as NotifyAction } from '../../NotificationSystem';
import {makeActiveUserOrganizationInfoSelector} from "../../Organization/selectors/selectUserOrganizationInfo";
import * as Routes from '../../routes';
import * as Action from '../actions';
import {BoardModel, BoardVersioning} from "../index";
import {List} from "immutable";

type FlowAction = ActionType<typeof Action.Command.addBoard>;

export function* installUserBoardFlow() {

    while (true) {
        const action: FlowAction = yield take([
            Action.Type.ADD_BOARD
        ]);

        const organization = yield select(makeActiveUserOrganizationInfoSelector());

        console.log("install user board flow: ", action.payload.board.toJS());

        if(organization) {
            const {response, error}: ResponseType = yield call(
                Action.Api.installOrganizationBoard,
                organization.organizationId,
                action.payload.board.uid,
                action.payload.board.name,
                action.payload.board.nameAbbreviation,
                action.payload.board.tags.toJS(),
                action.payload.board.responsibleTeam,
                action.payload.board.codySuggestEnabled,
                action.payload.board.eventModelingEnabled
            );

            if (error) {
                yield put(NotifyAction.Command.error('Request Error', 'Could not add board.'));
            }
            if (response) {
                // Give async BE processing a bit time
                yield delay(300);

                yield put(NotifyAction.Command.info('Board created', 'Board created'));

                const boardResponse: ResponseType = yield call(Action.Api.fetchUserBoard, action.payload.board.uid);

                yield call(action.payload.history.push, Routes.compileInspectioBoardWorkspace(action.payload.board.uid) as any);
                yield delay(1000);

                if (boardResponse.error) {
                    yield put(NotifyAction.Command.error("Request Error", "Failed to load board"));
                }

                if (boardResponse.response) {
                    const board = new BoardModel.Board({
                        uid: boardResponse.response.data.boardId,
                        name: boardResponse.response.data.name,
                        nameAbbreviation: response.data.nameAbbreviation,
                        tags: List(boardResponse.response.data.tags),
                        createdAt: boardResponse.response.data.createdAt,
                        lastModified: boardResponse.response.data.lastModified,
                        version: new BoardVersioning.BoardVersion(boardResponse.response.data.boardVersion),
                        shared: boardResponse.response.data.shared,
                        sharedWith: List(boardResponse.response.data.sharedWith),
                        assignedTeams: List(boardResponse.response.data.assignedTeams),
                        responsibleTeam: boardResponse.response.data.responsibleTeam ? boardResponse.response.data.responsibleTeam : undefined,
                        writeAccess: boardResponse.response.data.writeAccess,
                        owner: boardResponse.response.data.owner,
                        creator: boardResponse.response.data.creator,
                        organization: boardResponse.response.data.organization,
                        tempBoard: false,
                        orgaMemberPermissions: boardResponse.response.data.orgaMemberPermissions,
                        anyonePermissions: boardResponse.response.data.anyonePermissions,
                        codySuggestEnabled: boardResponse.response.data.codySuggestEnabled,
                        eventModelingEnabled: boardResponse.response.data.eventModelingEnabled,
                    });

                    yield put(Action.Command.forceUpdateBoards([board]));
                }
            }
        } else {
            yield put(NotifyAction.Command.error('Board Installation Failed', 'No organization selected.'));
        }
    }
}
