import {Record} from 'immutable';
import * as uuid from "uuid";
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {Email} from "../../User/model/UserInfo";

export const AGGREGATE_TYPE = 'OrganizationInvitation';

export type OrganizationInvitationId = string;
export type OrganizationInvitationError = string;
export enum Status {
    open = 'open',
    acknowledged = 'acknowledged',
    revoked = 'revoked',
}

export interface OrganizationInvitationProps {
    uid: OrganizationInvitationId;
    invitationId: OrganizationInvitationId;
    invitedUserEmail: Email;
    status: Status;
    aggregateType: AggregateType;
}

export const defaultOrganizationInvitationProps: OrganizationInvitationProps = {
    uid: '',
    invitationId: '',
    invitedUserEmail: '',
    status: Status.open,
    aggregateType: AGGREGATE_TYPE,
};

export const createOrganizationInvitation = (data: {invitationId: OrganizationInvitationId, invitedUserEmail: Email}) => {
    return new OrganizationInvitation({
        uid: data.invitationId,
        invitationId: data.invitationId,
        invitedUserEmail: data.invitedUserEmail,
        status: Status.open,
        aggregateType: AGGREGATE_TYPE
    });
}

export const createOrganizationInvitationFromServerData = (data: any): Either<OrganizationInvitationError, OrganizationInvitation> => {
    const validatedData: Either<OrganizationInvitationError, any> = validate(data,
        [hasKey('invitationId'), "invitationId missing"],
        [hasKey('invitedUserEmail'), "invitedUserEmail missing"],
    );

    if (isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new OrganizationInvitation({
        ...defaultOrganizationInvitationProps,
        ...data,
        uid: data.invitationId,
    }));
};

export class OrganizationInvitation extends Record(defaultOrganizationInvitationProps) implements OrganizationInvitationProps, Aggregate {
    public constructor(data: OrganizationInvitationProps) {
        if (data.uid === '') {
            data.uid = uuid.v4()
        }

        super(data);
    }

    // @TODO add use case methods to change state
}
