import { fromJS } from 'immutable';
import { Action, AnyAction, Dispatch } from 'redux';
import { FormStateMap } from 'redux-form';
import { reducer as formReducer } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import { ActionType } from 'typesafe-actions';
import { authReducer, InspectioAuthState } from './Auth/reducers';
import { inspectioBoardsReducer, InspectioBoardsState } from './InspectioBoards/reducers';
import {Reducer as InspectioTeams} from "./InspectioTeams";
import {Reducer as InspectioOrganizations} from "./Organization";
import {inspectioSidebarReducer, InspectioSidebarState} from "./Layout/reducers";
import { NotificationSystemReducer, NotificationSystemState } from './NotificationSystem/reducers';
import { socketReducer, SocketState } from './Socket/reducers';
import { inspectioUserReducer, InspectioUsersState } from './User/reducers';

export interface ApplicationState {
    notificationSystem: NotificationSystemState,
    inspectioBoards: InspectioBoardsState,
    inspectioUsers: InspectioUsersState,
    inspectioTeams: InspectioTeams.InspectioTeamsState,
    inspectioOrganizations: InspectioOrganizations.InspectioOrganizationsState,
    inspectioSidebar: InspectioSidebarState,
    form: FormStateMap,
    auth: InspectioAuthState,
    socket: SocketState,
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const rootReducer = combineReducers<ApplicationState, ActionType<any>>(
    {
        notificationSystem: NotificationSystemReducer,
        inspectioBoards: inspectioBoardsReducer,
        inspectioUsers: inspectioUserReducer,
        inspectioTeams: InspectioTeams.inspectioTeamsReducer,
        inspectioOrganizations: InspectioOrganizations.inspectioOrganizationsReducer,
        inspectioSidebar: inspectioSidebarReducer,
        form: formReducer,
        auth: authReducer,
        socket: socketReducer,
    },
    () => fromJS({
        notificationSystem: undefined,
        inspectioBoards: undefined,
        inspectioUsers: undefined,
        inspectioTeams: undefined,
        inspectioOrganizations: undefined,
        inspectioSidebar: undefined,
        auth: undefined,
        socket: undefined,
    }),
);
