import {List} from "immutable";
import {Board} from "../../../model/Board";
import {Node, NodeType} from "../../../model/Graph";
import {getSingleSourceOfType, getSourcesOfType} from "../node/get-single-node-of-type";
import {WizardContext} from "./wizard-context";

export interface WithUiNode {
  ui: Node | null;
  parentUi: Node | null;
  uiViews: List<Node>;
  sliceApiLabel: Node | null;
}

export type ImmutableUiContext = Readonly<UiContext>;

interface UiContext extends WizardContext<ImmutableUiContext>, WithUiNode {

}

export const uiContextFromNode = (ui: Node, board: Board): ImmutableUiContext => {
  const ctx: UiContext = {
    board,
    type: "ui",
    ui,
    parentUi: getParentUi(ui),
    uiViews: List(getUiViews(ui)),
    sliceApiLabel: getSliceApiLabelFromNode(ui),
    nextStep: currentStep => null,
    previousStep: currentStep => null,
    installCodyGraphSuggestions: () => { /* Do nothing */ },
  };

  return ctx;
}

export const isUiContext = (ctx: WizardContext): ctx is ImmutableUiContext => {
  return ctx.type === 'ui';
}

const getParentUi = (ui: Node): Node | null => {
  return getSingleSourceOfType(ui, NodeType.ui, true);
}

const getUiViews = (ui: Node): Node[] => {
  return getSourcesOfType(ui, NodeType.document, true);
}

const getSliceApiLabelFromNode = (node: Node): Node | null => {
  const parent = node.getParent();

  let sliceApiLabel: Node | null = null;

  if(parent && (parent.getType() === NodeType.feature || parent.getType() === NodeType.boundedContext)) {
    parent.children().forEach(child => {
      if(child.getTags().contains(ispConst.TAG_SLICE_API_LABEL)) {
        sliceApiLabel = child;
      }
    })
  }

  return sliceApiLabel;
}
