import {List} from "immutable";
import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Divider, Tab} from "semantic-ui-react";
import {Action as TeamAction} from "../../../InspectioTeams";
import {makeOrganizationSelector} from "../../../Organization/selectors/selectOrganization";
import {UserId} from "../../../User/model/UserInfo";
import {makeGetCurrentUser} from "../../../User/selectors/currentUser";
import {makeIioFilteredUserListSelector} from "../../../User/selectors/iioUserInfo";
import * as Action from "../../actions";
import {BoardModel} from "../../model";
import {BoardPermissionsForTeamsTable} from "./BoardPermissionsForTeamsTable";
import {BoardPermissionTable} from "./BoardPermissionsTable";
import {OpenBoardInvitationsTable} from "./OpenBoardInvitationsTable";
import {default as ShareBoardForm, ShareBoardWithUserFormData} from "./ShareBoardForm";
import ShareBoardWithTeamForm, {ShareBoardWithTeamFormData} from "./ShareBoardWithTeamForm";
import {makeOpenBoardInvitationListSelector} from "../../selectors/boardInvitation";
import BoardPermissionsForAnyoneTable from "./BoardPermissionsForAnyoneTable";

interface OwnProps {
    board?: BoardModel.Board;
}

type BoardPermissionsMenuProps = OwnProps & WithNamespaces;

const BoardPermissionsMenu = (props: BoardPermissionsMenuProps) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(makeGetCurrentUser());
    const organization = useSelector(makeOrganizationSelector(currentUser.activeOrganization));
    const organizationUserIds = List<UserId>(organization ? [organization.ownerId, ...organization.members.toJS()] : []);
    const organizationUsers = useSelector(makeIioFilteredUserListSelector(organizationUserIds));
    const openBoardInvitations = useSelector(makeOpenBoardInvitationListSelector(props.board ? props.board.uid : ''));

    const shareBoard = (data: ShareBoardWithUserFormData): void => {
        dispatch(Action.Command.shareBoard(data.boardId, data.email, data.permissions));
    };

    const shareBoardWithTeams = (data: ShareBoardWithTeamFormData): void => {
        dispatch(TeamAction.Command.adminGrantTeamAccessToBoard(data.boardId, data.teamIds, data.permissions));
    };

    if (!props.board || !organization) {
        return <></>;
    }

    const usersPane = <>
        <ShareBoardForm
            boardId={props.board.uid}
            email={''}
            permissions={{ readAccess: true, writeAccess: true, adminAccess: false }}
            handleSubmit={shareBoard}
            users={organizationUsers.map(user => ({
                userId: user.userId,
                name: user.username,
                avatar: user.avatarUrl,
                email: user.email
            }))}
        />
        <Divider />
        <BoardPermissionTable board={props.board} />
        {openBoardInvitations.count() > 0 && <>
            <Divider horizontal={true}>Open Invitations</Divider>
            <OpenBoardInvitationsTable openInvitations={openBoardInvitations} board={props.board} />
        </>}
    </>;

    const teamsPane = <>
        <ShareBoardWithTeamForm
            boardId={props.board.uid}
            user={currentUser}
            permissions={{ readAccess: true, writeAccess: true, adminAccess: false }}
            handleSubmit={shareBoardWithTeams}
        />
        <Divider />
        <BoardPermissionsForTeamsTable board={props.board} />
    </>;

    const anyonePane = <>
        <BoardPermissionsForAnyoneTable boardId={props.board.uid} />
    </>;

    const panes = [
        {
            "menuItem": "User",
            render: () => usersPane,
        },
        {
            "menuItem": "Teams",
            render: () => teamsPane,
        },
        {
            "menuItem": props.t('insp.board.permissions.all'),
            render: () => anyonePane,
        }
    ];

    return <Tab menu={{ secondary: true, pointing: true, color: 'orange' }} panes={panes} />
};

export default withNamespaces()(BoardPermissionsMenu);
