import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://mephisto-video.inseciacloud.com:3001', 
});

const sendApiRequest = async (request: AxiosRequestConfig): Promise<AxiosResponse> => {
    try {
        return await axiosInstance(request);
    } catch(error) {
        console.error(request.url);
        throw error;
    }
};


const Api = {
    createSession: async (sessionId: string): Promise<string> => {
        const response = await sendApiRequest({
            method: 'post',
            url: `sessions/${sessionId}`,
        });

        return response.data as string;
    },
    getToken: async (sessionId: string): Promise<string> => {
        const response = await sendApiRequest({
            method: 'get',
            url: `sessions/${sessionId}/token`,
        });

        return response.data as string;
    },
};

export default Api;
