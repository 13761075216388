import {ElementsTree} from "../../../model/ElementsTree";
import {Schema} from "../schema/schema";
import {getRefVo} from "./get-ref-vo";
import {isStateVo} from "./is-state-vo";

export const isEntityCollection = (schema: Schema, elementsTree: ElementsTree): boolean => {
  if(schema.isList() && schema.getListItemsSchema(new Schema({}).isRef())) {
    const refVo = getRefVo(schema.getListItemsSchema(new Schema({})), elementsTree);

    if(refVo && isStateVo(refVo)) {
      return true;
    }
  }

  return false;
}
