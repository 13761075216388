import * as React from 'react';
import {TeamId} from "../model/Team";
import {List} from "immutable";
import {UserOrganizationInfo} from "../../Organization/model/UserOrganizationInfo";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {OrganizationId} from "../../Organization/model/Organization";
import {Button, Grid, GridColumn, Message, Select} from "semantic-ui-react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import * as Action from "../actions";

interface OwnProps {
    teamId: TeamId;
    organizations: List<UserOrganizationInfo>;
}

type MoveTeamMenuProps = OwnProps & WithNamespaces;

const MoveTeamMenu = (props: MoveTeamMenuProps) => {
    const dispatch = useDispatch();
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationId|undefined>();

    const options = props.organizations.map(orga => ({
        key: orga.uid,
        value: orga.uid,
        text: orga.organizationName
    }));

    const handleMoveTeam = () => {
        if(selectedOrganization) {
            dispatch(Action.Command.moveTeamToOrganization(props.teamId, selectedOrganization));
        }
    }

    return <Grid columns={2}>
        <GridColumn mobile={16} tablet={16} computer={12} largeScreen={12} widescreen={12}>
            <Select options={options.toJS()}
                    fluid={true}
                    placeholder={props.t('insp.team.move_to_organization_select') as string}
                    onChange={(e, data) => setSelectedOrganization(data.value as OrganizationId)}
            />
        </GridColumn>
        <GridColumn mobile={16} tablet={16} computer={4} largeScreen={4} widescreen={4}>
            <Button primary={true} floated="right" onClick={() => handleMoveTeam()}>{props.t('insp.team.move_to_organization_submit')}</Button>
        </GridColumn>
        <Message size="small" info={true}>{props.t('insp.team.move_to_organization_desc')}</Message>
    </Grid>;
};

export default withNamespaces()(MoveTeamMenu);
