import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Header, Icon, TableCell, TableRow} from "semantic-ui-react";
import * as Action from "../../actions";
import {BoardId} from "../../model/Board";
import {makeBoardByIdSelector} from "../../selectors/board";
import {DEFAULT_PERMISSIONS} from "../../model/BoardPermissionForUser";

interface OwnProps {
  boardId: BoardId
}

type BoardPermissionsForOrganizationMembersTableEntryProps = OwnProps & WithNamespaces;

const BoardPermissionsForOrganizationMembersTableEntry = (props: BoardPermissionsForOrganizationMembersTableEntryProps) => {

  const board = useSelector(makeBoardByIdSelector(props.boardId));
  const dispatch = useDispatch();

  const hasReadAccess = board.orgaMemberPermissions.readAccess;
  const hasWriteAccess = board.orgaMemberPermissions.writeAccess;

  const handleChangeReadBoardPermissions = () => {
    const permissions = board.orgaMemberPermissions;
    const changedPermissions = {...permissions, readAccess: !hasReadAccess};
    if(hasReadAccess) {
      // If read access gets revoked write access should be revoked, too
      changedPermissions.writeAccess = false;
    }
    dispatch(Action.Command.shareBoardWithOrganizationMembers(board.uid, changedPermissions));
  }

  const handleChangeWriteBoardPermissions = () => {
    const permissions = board.orgaMemberPermissions;
    const changedPermissions = {...permissions, writeAccess: !hasWriteAccess};
    if(!hasWriteAccess) {
      // If write access is granted, read access needs to be granted as well
      changedPermissions.readAccess = true;
    }
    dispatch(Action.Command.shareBoardWithOrganizationMembers(board.uid, changedPermissions));
  }

  return <TableRow>
    <TableCell style={{width: "310px"}}>
      <Header as='h4' image={true}>
        <Icon circular={true} name='building outline' />
        <Header.Content>
          {props.t('insp.board.permissions.anyonePane.orgaMembers')}
        </Header.Content>
      </Header>
    </TableCell>
    <TableCell>
      <Button.Group>
        <Button
          className={hasReadAccess ? 'positive' : ''}
          children={'Read'}
          onClick={handleChangeReadBoardPermissions}
        />
        <Button.Or text="+" />
        <Button
          className={hasWriteAccess ? 'positive' : ''}
          children={'Write'}
          onClick={handleChangeWriteBoardPermissions}
        />
      </Button.Group>
    </TableCell>
  </TableRow>
};

export default withNamespaces()(BoardPermissionsForOrganizationMembersTableEntry);
