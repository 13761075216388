import {List, Map} from 'immutable';
import {Reducer} from 'redux';
import {ActionType} from "typesafe-actions";
import {upsertAggregates} from "../../core/reducer/applyAggregateChanged";
import {
    BOARD_STICKY_TEMPLATES_FETCHED,
    STICKY_ADVANCED_CONFIG_UPDATED,
    STICKY_TEMPLATE_SET,
    STICKY_TEMPLATES_IMPORTED
} from "../actions/constants";
import * as Events from "../actions/events";
import {StickyTemplate} from "../model/StickyTemplate";
import {InspectioBoardsAction} from './index';

type Event = ActionType<typeof Events>;

export interface StickyTemplateState extends Map<string, StickyTemplate> {
}

export const initialState: StickyTemplateState = Map<string, StickyTemplate>();

const reducer: Reducer<StickyTemplateState, InspectioBoardsAction> = (state: StickyTemplateState = initialState, event: InspectioBoardsAction): StickyTemplateState => {
    switch (event.type) {
        case BOARD_STICKY_TEMPLATES_FETCHED:
        case STICKY_TEMPLATES_IMPORTED:
            return upsertAggregates(state, event.payload.stickyTemplates);
        case STICKY_TEMPLATE_SET:
            return upsertAggregates(state, List([event.payload.stickyTemplate]));
        case STICKY_ADVANCED_CONFIG_UPDATED:
            return upsertAggregates(state, List([event.payload.stickyTemplate]));
        default:
            return state;
    }
};

export {reducer as saveStickyTemplateReducer};
