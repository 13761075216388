import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {FormField, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {WizardContext} from "../../../service/cody-wizard/context/wizard-context";
import {ApplyEventRule} from "../../../service/cody-wizard/event/get-event-metadata";
import {Rule} from "../../../service/cody-wizard/rule-engine/configuration";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import ExpandButton from "./ExpandButton";
import {makeInitializeRulesSchema} from "./Validation/initialize-rules-schema";

interface OwnProps {
  elementId: string;
  initializeRules: Rule[];
  dataSchema: Schema;
  ctx: WizardContext;
  onRulesChanged: (rules: ApplyEventRule[] | undefined) => void;
  autofocus?: boolean;
  readonly?: boolean;
  sessionId?: string;
}

type InitializeRulesEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};

const InitializeRulesEditor = (props: InitializeRulesEditorProps) => {
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [editorExpanded, setEditorExpanded] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let value = JSON.stringify(props.initializeRules, null, 2);
    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `initialize-rules.json`,
      language: "json",
      value,
      schema: makeInitializeRulesSchema(props.dataSchema, props.ctx),
    });

    setInvalidJson(isInvalidJson);
  }, [props.elementId, props.dataSchema]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {
    if(editorStr === '') {
      props.onRulesChanged(undefined);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedRules = JSON.parse(editorStr);
      props.onRulesChanged(changedRules);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true;
    } catch (e) {
      console.error("[CodyWizard] Initialize Rules Editor invalid JSON: ", e);
      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorExpanded} onExpandChanged={expand => setEditorExpanded(expand)}/>
    <CodeEditor
      containerId={'cody-event-apply-rules-editor'}
      ref={codeEditorRef}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorExpanded ? ' expanded' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedRulesStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedRulesStr);

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedRulesStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(InitializeRulesEditor);
