import * as React from "react";
import {useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, RouteComponentProps, withRouter} from "react-router";
import {NavHashLink} from "react-router-hash-link";
import {Confirm, Icon} from "semantic-ui-react";
import * as Action from "../../InspectioTeams/actions";
import InviteUserToTeamModal from "../../InspectioTeams/components/InviteUserToTeamModal";
import {Team} from "../../InspectioTeams/model";
import * as Route from "../../routes";
import {IioUserInfo} from "../../User/model/IioUserInfo";
import {makeIioFilteredUserListSelector, makeIioUserListSelector} from "../../User/selectors/iioUserInfo";

interface OwnProps {
    team: Team.Team
}

type TeamListItemProps = WithNamespaces & RouteComponentProps & OwnProps;

const TeamListItem = (props: TeamListItemProps) => {
    const [visible, setVisible] = useState(false);
    const [iconUp, setIconUp] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const [showRemoveMemberConfirm, setShowRemoveMemberConfirm] = useState<boolean>(false);
    const [memberToRemove, setMemberToRemove] = useState<string|undefined>(undefined);

    const users = useSelector(makeIioFilteredUserListSelector(props.team.members));
    const dispatch = useDispatch();

    const angleIcon = iconUp ? 'angle up icon' : 'angle down icon';
    const contentClass = 'ui labels ' + (visible ? 'slidedown' : 'slideup');

    const getUsernameByUserId = (userId: string) => {
        const user = users.find((userInfo: IioUserInfo) => userInfo.userId === userId);
        return user ? user.username : ''
    };

    const handleVisibleClick = () => {
        setVisible(!visible);

        if (iconUp) {
            window.setTimeout(() => {
                setIconUp(false);
            }, 150);
        } else {
            setIconUp(true);
        }
    };

    const openAddUserModal = (event: React.MouseEvent) => {
        stopPropagation(event);
        setShowAddUserModal(true);
    };

    const closeAddUserModal = () => {
        setShowAddUserModal(false);
    };

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const removeMemberFromTeam = () => {
        if (!memberToRemove) {
            return;
        }

        dispatch(Action.Command.removeMemberFromTeam(props.team.uid, memberToRemove));
        setShowRemoveMemberConfirm(false);
    };

    const handleScrollToTeam = (el: HTMLHeadElement) => {
        const headerOffset = 60;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = el.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - headerOffset;
        window.scrollTo({behavior: "smooth", top: offsetPosition})
    }

    return (
        <div className="ui item"  style={{cursor: "pointer"}}>
            <Icon className={angleIcon} onClick={handleVisibleClick} />
            <NavHashLink scroll={handleScrollToTeam} to={Route.teamOverview + '#' + props.team.uid} className="team-list team">{props.team.name}</NavHashLink>
            <Icon className="eye icon" style={{visibility: "hidden"}}/>
            <div className={contentClass}>
                <a className="ui blue basic label" onClick={openAddUserModal}>
                    <i className="white plus icon" />
                    {props.t('insp.team.add_user')}
                </a>
                {props.team.members.filter(m => m !== props.team.ownerId).map(member => (
                    <div key={member} className="ui basic label">
                        {getUsernameByUserId(member)}
                        <i className="red delete icon" onClick={(event: React.MouseEvent) => {
                            stopPropagation(event);
                            setMemberToRemove(member);
                            setShowRemoveMemberConfirm(true);
                        }} />
                    </div>
                ))}
            </div>
            <div onClick={stopPropagation}>
                <InviteUserToTeamModal
                    team={props.team}
                    open={showAddUserModal}
                    onClose={closeAddUserModal}
                />
                <Confirm
                    open={showRemoveMemberConfirm}
                    header={props.t('insp.team.remove_user_confirm_title')}
                    content={props.t(
                        'insp.team.remove_user_confirm_text',
                        { user_name: memberToRemove ? getUsernameByUserId(memberToRemove) : '' }
                    )}
                    confirmButton={props.t('insp.team.remove_user_confirm_button')}
                    onCancel={() => setShowRemoveMemberConfirm(false)}
                    onConfirm={removeMemberFromTeam}
                />
            </div>
        </div>
    );
};

export default withNamespaces()(withRouter(TeamListItem));
