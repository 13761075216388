import {JSONSchema7TypeName} from "json-schema";
import {names} from "../node/names";
import {Schema} from "./schema";
import {isShorthand} from "./shorthand";

const removeOneNestingLevel = (lines: string[]): string[] => {
  const modifiedLines: string[] = [];

  lines.forEach(line => {
    if(line.charAt(0) !== "-") {
      line = line.slice(1);
    }

    if(line.charAt(0) !== "-") {
      line = line.slice(1);
    }

    modifiedLines.push(line);
  })

  return modifiedLines;
}

interface Line {
  isSchemaLine: boolean;
  isSubSchema: boolean;
  type: JSONSchema7TypeName;
  propName: string;
  raw: string;
}

const INVALID_LINE: Line = {
  isSchemaLine: false,
  isSubSchema: false,
  type: "null",
  propName: '',
  raw: ''
};

export class SchemaFromNodeDescriptionParser {
  private lines: string[];
  private currentLine = 0;
  private schema: Schema;

  constructor(desc: string) {
    this.lines = desc.split("\n");
    this.schema = new Schema({});

    console.log(this.lines);
  }

  public parse(): Schema {
    let line = this.parseNextLine();
    console.log("next line", line);

    while (line.isSchemaLine) {
      if(line.type === "object") {
        const subSchemaLines = this.parseAllSubSchemaLines();
        const subSchema = (new SchemaFromNodeDescriptionParser(removeOneNestingLevel(subSchemaLines).join("\n"))).parse();
        this.schema.setObjectProperty(line.propName, subSchema);
      } else {
        this.schema.setObjectProperty(line.propName, new Schema(line.type));
      }

      line = this.parseNextLine();
      console.log("next line", line)
    }

    return this.schema;
  }

  private parseAllSubSchemaLines(): string[] {
    let currentLine = this.currentLine;

    let line = this.parseNextLine();

    const subSchemaLines: string[] = [];

    while (line.isSubSchema) {
      subSchemaLines.push(line.raw);

      currentLine = this.currentLine;
      line = this.parseNextLine();
    }

    // Jump one position back
    this.currentLine = currentLine;

    return subSchemaLines;
  }

  private parseNextLine(): Line {
    const lineStr = this.getNextLine();

    console.log(lineStr);

    if(!lineStr) {
      return INVALID_LINE;
    }

    if(lineStr.trimLeft().charAt(0) !== "-") {
      return INVALID_LINE;
    }

    const [prop, type] = lineStr.split(":");

    console.log("line split", prop, typeof type, prop.charAt(0) !== "-");

    const isSubSchema = prop.charAt(0) !== "-";
    const propName = names(prop.trim().slice(1).trimLeft()).propertyName;
    let schemaType: JSONSchema7TypeName = propName.toLowerCase().match(/id$/) ? "string|format:uuid" as JSONSchema7TypeName : "string";

    if(typeof type === "string") {
      if(type.trim() === "") {
        schemaType = "object";
      } else if (isShorthand(type.trim())) {
        schemaType = type.trim() as JSONSchema7TypeName;
      }
    }

    return {
      isSchemaLine: true,
      isSubSchema,
      propName,
      type: schemaType,
      raw: lineStr,
    }
  }

  private getNextLine(): string | undefined {
    const line = this.lines[this.currentLine];
    this.currentLine++;
    return line;
  }
}
