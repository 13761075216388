import {getIdentifierCandidatesFromSchema} from "../../../components/CodyEngineWizard/Step/StateVO/IdentifierSelect";
import {Node} from "../../../model/Graph";
import {isAggregateEvent} from "../event/is-aggregate-event";
import {getConnectedEventIfPossible} from "./get-connected-event-if-possible";
import {getConnectedValueObjectSourceIfPossible} from "./get-connected-value-object-source-if-possible";
import {getVoMetadata} from "./get-vo-metadata";
import {isListVo} from "./is-list-vo";
import {isStateVo} from "./is-state-vo";
import { isVoStoredInDb } from "./is-vo-stored-in-db";
import {getConnectedUiTargetIfPossible} from "./get-connected-ui-target-if-possible";

export const isStateVoCandidate = (node: Node): boolean => {
  if(isStateVo(node)) {
    return true;
  }

  if(isListVo(node)) {
    return false;
  }

  const source = getConnectedValueObjectSourceIfPossible(node);

  if(source) {
    return false;
  }

  const meta = getVoMetadata(node);

  if(!isVoStoredInDb(meta)) {
    return false;
  }

  const event = getConnectedEventIfPossible(node);

  if(event) {
    if(!isAggregateEvent(event)) {
      return false;
    }
  }

  const ui = getConnectedUiTargetIfPossible(node);

  // Information connected to UI is not a state VO candidate by default
  if(ui) {
    return false;
  }

  return !meta.schema || !!getIdentifierCandidatesFromSchema(meta.schema).length;
}

