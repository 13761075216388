import {List, Record} from 'immutable';
import * as uuid from 'uuid';
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, isSuccess, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {BoardId} from "../../InspectioBoards/model/Board";
import {Email, UserId} from "../../User/model/UserInfo";
import {
    createOrganizationInvitation,
    createOrganizationInvitationFromServerData,
    OrganizationInvitation,
    OrganizationInvitationId
} from "./OrganizationInvitation";

export type OrganizationId = string;
export type OrganizationName = string;
export type OrganizationMemberQuota = number;
export type OrganizationBoardQuota = number;
export type OrganizationMembers = List<UserId>;
export type OrganizationAdmins = List<UserId>;
export type OrganizationGuests = List<UserId>;
export type OrganizationBoards = List<BoardId>;
export type OrganizationInvitations = List<OrganizationInvitation>;

export type OrganizationValidationError = string;

export const AGGREGATE_TYPE = 'Organization';


export interface OrganizationProps {
    uid: OrganizationId;
    ownerId: UserId;
    name: OrganizationName;
    explicit: boolean;
    memberQuota: OrganizationMemberQuota;
    boardQuota: OrganizationBoardQuota;
    members: OrganizationMembers;
    admins: OrganizationAdmins;
    guests: OrganizationGuests;
    boards: OrganizationBoards;
    invitations: OrganizationInvitations;
    aggregateType: AggregateType;
}

export const defaultOrganizationProps: OrganizationProps = {
    uid: '',
    ownerId: '',
    name: '',
    explicit: false,
    memberQuota: 0,
    boardQuota: 0,
    members: List(),
    admins: List(),
    boards: List(),
    guests: List(),
    invitations: List(),
    aggregateType: AGGREGATE_TYPE,
};

export const createOrganizationFromServerData = (data: any): Either<OrganizationValidationError, Organization> => {
    const validatedData: Either<string, any> = validate(data,
        [hasKey('organizationId'), "organizationId missing"],
        [hasKey('ownerId'), `ownerId missing in ${data.organizationId}`],
        [hasKey('name'), `name missing in ${data.organizationId}`],
        [hasKey('explicit'), `explicit missing in ${data.organizationId}`],
        [hasKey('memberQuota'), `memberQuota missing in ${data.organizationId}`],
        [hasKey('boardQuota'), `boardQuota missing in ${data.organizationId}`],
        [hasKey('members'), `members missing in ${data.organizationId}`],
        [hasKey('admins'), `admins missing in ${data.organizationId}`],
        [hasKey('boards'), `boards missing in ${data.organizationId}`],
        [hasKey('guests'), `guests missing in ${data.organizationId}`],
        [hasKey('invitations'), `invitations missing in ${data.organizationId}`],
    );

    if (isFailure(validatedData)) {
        return validatedData;
    }

    const invitations = [];
    let invitation: any;

    for(invitation of data.invitations) {
        const result = createOrganizationInvitationFromServerData(invitation);

        if(isFailure(result)) {
            return result;
        }

        if(isSuccess(result)) {
            invitations.push(result.value)
        }
    }

    return Success(new Organization({
        uid: data.organizationId,
        ownerId: data.ownerId,
        name: data.name,
        explicit: data.explicit,
        memberQuota: data.memberQuota,
        boardQuota: data.boardQuota,
        members: List(data.members),
        admins: List(data.admins),
        guests: List(data.guests),
        boards: List(data.boards),
        invitations: List(invitations),
        aggregateType: AGGREGATE_TYPE,
    }));
};

export class Organization extends Record(defaultOrganizationProps) implements OrganizationProps, Aggregate {
    public constructor(data: OrganizationProps) {
        if(data.uid === '') {
            data.uid = uuid.v4()
        }

        super(data);
    }

    public isAdmin(userId: UserId): boolean {
        return this.ownerId === userId || this.admins.includes(userId);
    }

    public setUpAsExplicit(name: OrganizationName): Organization {
        const orga = this.set('explicit', true);
        return orga.set('name', name);
    }

    public inviteUser(invitationId: OrganizationInvitationId, email: Email): Organization {
        return this.set('invitations', this.invitations.push(
            createOrganizationInvitation({invitationId, invitedUserEmail: email})
        ));
    }

    public promoteAdmin(userId: UserId): Organization {
        return this.set('admins', this.admins.push(userId));
    }

    public demoteToMember(userId: UserId): Organization {
        return this.set('admins', this.admins.filter(admin => admin !== userId));
    }

    public revokeInvitation(invitationId: OrganizationInvitationId): Organization {
        return this.set('invitations', this.invitations.filter(invitation => invitation.invitationId !== invitationId));
    }

    public removeUser(userId: UserId): Organization {
        let self = this;
        self = self.set('admins', self.get('admins', List()).filter(adminId => adminId !== userId));
        self = self.set('members', self.get('members', List()).filter(memberId => memberId !== userId));
        self = self.set('memberQuota', self.get('memberQuota', 0) + 1);
        return self;
    }

    public removeGuest(userId: UserId): Organization {
        let self = this;
        self = self.set('guests', self.get('guests', List()).filter(guestId => guestId !== userId));
        self = self.set('memberQuota', self.get('memberQuota', 0) + 1);
        return self;
    }

    public turnGuestIntoMember(guestId: UserId): Organization {
        const newGuests = this.guests.filter(guest => guest !== guestId);
        const newMembers = this.members.push(guestId);
        return this.set('guests', newGuests).set('members', newMembers);
    }

    public withNewName(name: OrganizationName): Organization {
        return this.set('name', name);
    }
}
