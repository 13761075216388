import React, {useEffect, useRef, useState} from "react";
import {Image} from "semantic-ui-react";
import WebSocketConnection from "../../../api/ConfiguredViduSocket";
import {OpenViduUser} from "../../model/OpenViduUser";
import './OpenViduVideo.css';

export interface Position {
    left: number;
    top: number;
}

export interface MousePoint {
    x: number;
    y: number;
}

export type NewPosition = Position;

export interface PositionChange {
    startPositionAvatar: Position,
    startPositionMouse: MousePoint,
    currentPositionMouse: MousePoint,
}

interface OpenViduVideoProps {
    user: OpenViduUser;
    sessionId: string;
    isLocalUser: boolean;
    onPositionChange?: (change: PositionChange) => NewPosition;
    volume: number;
    isSpeaking: boolean;
    isAnyMegaphoneActive: boolean;
}

const defaultPositionChange: PositionChange = {
    startPositionAvatar: {left: 0, top: 0},
    startPositionMouse: {x: 0, y: 0},
    currentPositionMouse: {x: 0, y: 0}
}

const emojiIconSettings: any =  { 
    "thumbs up": "green thumbs up thumbUp-indicator",
    "thumbs down": "red thumbs down thumbDown-indicator",
    "beer": "yellow beer beer-indicator",
}

const OpenViduVideo = (props: OpenViduVideoProps) => {
    const videoRef = useRef(null);
    const [isDragging, setIsDragging] = useState<boolean>();
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const positionChange = useRef<PositionChange>(defaultPositionChange);

    const changePosition = (x: number, y: number) => {
        positionChange.current.currentPositionMouse.x = x;
        positionChange.current.currentPositionMouse.y = y;

        if(props.onPositionChange) {
            const newPosition = props.onPositionChange(positionChange.current);
            WebSocketConnection.sendPositionUpdate(props.user.id, props.sessionId, newPosition.left, newPosition.top);
        }
    }

    useEffect(() => {
        props.user.streamManager.addVideoElement(videoRef.current);
    }, []);

    useEffect(() => {
        if (!videoRef.current) {
            return;
        }

        if(props.isLocalUser) {
            return;
        }

        let volume = props.volume * (props.isAnyMegaphoneActive ? 0.7 : 1.0);
        if (props.user.megaphoneOn) {
            volume = 1.0;
        }

        (videoRef.current as any).volume = volume;

        if(volume === 0 && !(videoRef.current as any).paused) {
            (videoRef.current as any).pause();
        }

        if(volume > 0 && (videoRef.current as any).paused) {
            (videoRef.current as any).play();
        }
    }, [props.volume, props.user.megaphoneOn, props.isAnyMegaphoneActive]);

    useEffect(() => {
        const mouseUpListener = () => {
            setIsDragging(false);
        }

        const mouseMoveListener = (e: MouseEvent) => {
            if (props.isLocalUser && isDragging) {
                changePosition(e.clientX, e.clientY);
            }
        }

        const touchMoveListener = (e: TouchEvent) => {
            if (props.isLocalUser && isDragging) {
                changePosition(e.touches[0].clientX, e.touches[0].clientY);
            }
        }

        document.addEventListener('mouseup', mouseUpListener);
        document.addEventListener('touchend', mouseUpListener);
        document.addEventListener('mousemove', mouseMoveListener);
        document.addEventListener('touchmove', touchMoveListener);

        return () => {
            document.removeEventListener('mouseup', mouseUpListener);
            document.removeEventListener('touchend', mouseUpListener);
            document.removeEventListener('mousemove', mouseMoveListener);
            document.removeEventListener('touchmove', touchMoveListener);
        }
    })

    const applyFocusedSize = (size: number): number => {
        return isFocused? size * 2 : size;
    }
    
    const fullSize = 150;
    const currentSize = applyFocusedSize(props.isLocalUser? fullSize : 50 + (props.volume * 100));
    const borderRadius = Math.round(currentSize / 2);
    const showVideo = props.user.cameraOn && (props.isLocalUser || props.volume > 0 || props.user.megaphoneOn);
    const darkenAvatar = !props.isLocalUser && props.volume === 0 && !props.user.megaphoneOn;
    const toggleIsFocused = () => {
        setIsFocused(!isFocused);
    }

    return (
        <div
            style={{
                width: currentSize,
                height: currentSize,
                borderRadius,
                position: 'absolute',
                left: props.user.position.left - currentSize / 2,
                top: props.user.position.top - currentSize / 2,
                pointerEvents: 'auto',
                cursor: props.isLocalUser? "move" : "default",
                zIndex: props.isLocalUser? 10 : 3,
                WebkitTransform: 'translateZ(0)',
            }}
            onMouseDown={(e) => {
                    positionChange.current.startPositionAvatar = props.user.position;
                    positionChange.current.startPositionMouse = {
                        x: e.clientX,
                        y: e.clientY,
                    }
                    setIsDragging(true);
                }
            }
            onTouchStart={(e) => {
                positionChange.current.startPositionAvatar = props.user.position;
                positionChange.current.startPositionMouse = {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY,
                }
                setIsDragging(true);
            }}
            onDoubleClick={toggleIsFocused}
        >
            <div style={{
                width: currentSize,
                height: currentSize,
                borderRadius,
                overflow: 'hidden',
                backgroundColor: '#9cc8f5',
                pointerEvents: 'auto',
                ...(props.isSpeaking ? { border: '5px solid #9cc8f5'} : {border: '5px solid #ffffff'}),
                WebkitTransform: 'translateZ(0)',
                zIndex: props.isLocalUser? 10 : 3,
            }}>
                {/*
                 // @ts-ignore */}
                <video
                    autoPlay={true}
                    disablePictureInPicture={true /* Not working in FF .... */}
                    ref={videoRef}
                    muted={props.isLocalUser}
                    style={{ width: currentSize, height: currentSize, objectFit: 'cover', objectPosition: 'center', pointerEvents: 'auto', display: showVideo? 'block' : 'none', zIndex: props.isLocalUser? 9 : 2 }}
                />
                {!showVideo && <Image
                    src={props.user.avatar}
                    avatar={true}
                    verticalAlign="middle"
                    style={{
                        width: currentSize,
                        height: currentSize,
                        objectFit: 'cover',
                        objectPosition: 'center',
                        pointerEvents: 'none',
                        filter: darkenAvatar? 'brightness(0.8)' : 'none'
                    }}
                />}
            </div>
            <div style={{ position: "absolute", bottom: '-26px', left: 0, width: '100%', textAlign: 'center', fontSize: '16px', pointerEvents: 'auto' }}>{props.user.name}</div>

            <i className={'icon bullhorn megaphone-indicator' + (props.user.megaphoneOn ? ' active' : '')} />

            <i className={'green icon hand paper handUp-indicator' + (props.user.handUpOn ? ' active' : '')} />

            <i className={'icon ' + (emojiIconSettings[props.user.showIconName]) + ' ' + (props.user.thumbUpOn ? ' active' : '')} />
        
        </div>
    );
};

export default OpenViduVideo;
