import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { ActionType } from 'typesafe-actions';
import {
    connectSocket,
    disconnectSocket,
    sendSocketCmd,
    socketConnected, socketConnectionLost, socketDisconnected,
} from '../actions/actions';
import { statusReducer, StatusState } from './applyStatusActions';

export type SocketAction = ActionType<typeof sendSocketCmd>
    | ActionType<typeof socketConnected>
    | ActionType<typeof socketDisconnected>
    | ActionType<typeof socketConnectionLost>
    | ActionType<typeof disconnectSocket>
    | ActionType<typeof connectSocket>
    ;

export interface SocketState {
    status: StatusState
}

const reducer = combineReducers<SocketState, SocketAction>({
    status: statusReducer,
}, () => fromJS({status: undefined}));


export { reducer as socketReducer };