import React, { useEffect } from 'react';

export function useOnBodyClick(onClick: () => void): void {
    useEffect(() => {
        document.getElementsByTagName('body')[0].addEventListener('click', onClick);

        return () => {
            document.getElementsByTagName('body')[0].removeEventListener('click', onClick);
        }
    }, []);
}