import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Card, Icon} from "semantic-ui-react";
import {Node, NodeType} from "../../../model/Graph";
import {dddActionFromNode} from "../../../service/cody-wizard/context/ddd-action";
import {policyContextFromNode} from "../../../service/cody-wizard/context/policy-context";
import {WizardContext, WizardContextType} from "../../../service/cody-wizard/context/wizard-context";
import CellIcon from "../../CellIcon";
import {WithWizardStepProps} from "../WizardModal";

interface OwnProps {
  contexts: WizardContextType[];
  node: Node
}

type SelectContextProps = OwnProps & WithWizardStepProps<WizardContext> & WithNamespaces;

interface ContextDescription {
  header: string;
  meta: string;
  description: string;
  type: WizardContextType;
  nodeType: NodeType;
  extra?: React.ReactElement
}

const SelectContext = (props: SelectContextProps) => {

  const handleContextSelected = (ctxType: WizardContextType) => {
    switch (ctxType) {
      case "dddAction":
        props.onCtxChanged(dddActionFromNode(props.node, props.ctx.board));
        break;
      case "policy":
        props.onCtxChanged(policyContextFromNode(props.node, props.ctx.board));
        break;
      default:
        throw new Error(`Cannot switch context to type ${ctxType}. This seems to be a developer bug. Please contact the prooph board team!`);
    }
  }

  const contexts = makeContextDescriptions(props.node, props.contexts, props.t)
    .map(c => <Card link={true} key={c.type} onClick={() => handleContextSelected(c.type)} style={{minWidth: '300px'}}>
      <Card.Content>
        <div style={{float: "right"}}><CellIcon cellType={c.nodeType} size="big" /></div>
        <Card.Header>{c.header}</Card.Header>
        <Card.Meta>{c.meta}</Card.Meta>
        <Card.Description>{c.description}</Card.Description>
      </Card.Content>
      {c.extra && <Card.Content extra={true}>{c.extra}</Card.Content>}
    </Card>)

  return <Card.Group itemsPerRow={3} stackable={true}>
    {contexts}
  </Card.Group>
};

export default withNamespaces()(SelectContext);

const makeContextDescriptions = (node: Node, contexts: WizardContextType[], t: (key: string) => string): ContextDescription[] => {
  const descriptions: ContextDescription[] = [];

  contexts.forEach(cType => {
    descriptions.push({
      header: t(`insp.cody_wizard.step_select_context.header_${cType}`),
      meta: t(`insp.cody_wizard.step_select_context.meta_${cType}`),
      description: t(`insp.cody_wizard.step_select_context.desc_${cType}`),
      type: cType,
      nodeType: getNodeTypeForContext(node, cType),
      extra: getExtraDescriptionForContext(node, cType),
    })
  })

  return descriptions;
}

const getNodeTypeForContext = (node: Node, ctx: WizardContextType): NodeType => {
  switch (ctx) {
    case "policy":
      return NodeType.policy;
    case "dddAction":
      return NodeType.command;
    default:
      return NodeType.freeText;
  }
}

const getExtraDescriptionForContext = (node: Node, ctx: WizardContextType): React.ReactElement | undefined => {
  switch (ctx) {
    case "dddAction":
      return <>
        <CellIcon cellType={NodeType.ui} size="normal" />
        <Icon name="arrow right" />
        <CellIcon cellType={NodeType.command} size="normal" />
        <Icon name="arrow right" />
        <CellIcon cellType={NodeType.aggregate} size="normal" />
        <Icon name="arrow right" />
        <CellIcon cellType={NodeType.event} size="normal" />
        <Icon name="arrow right" />
        <CellIcon cellType={NodeType.document} size="normal" />
      </>
    case "policy":
      return <>
        <CellIcon cellType={NodeType.event} size="normal" />
        <Icon name="arrow right" />
        <CellIcon cellType={NodeType.policy} size="normal" />
        <Icon name="arrow right" />
        <CellIcon cellType={NodeType.command} size="normal" />
        &nbsp;/&nbsp;
        <CellIcon cellType={NodeType.externalSystem} size="normal" />
      </>
    default:
      return undefined;
  }
}
