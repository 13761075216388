import {Map} from 'immutable';
import {Reducer} from 'redux';
import {upsertAggregates} from "../../core/reducer/applyAggregateChanged";
import {
    BOARD_ACCESS_REVOKED,
    BOARD_PERMISSIONS_CHANGED,
    BOARD_PERMISSIONS_FOR_USERS_FETCHED,
} from '../actions/constants';
import {BoardPermissionForUser} from "../model";
import {InspectioBoardsAction} from './index';

export interface BoardPermissionsForUsersState extends Map<string, BoardPermissionForUser.BoardPermissionForUser> {
}

export const initialState: BoardPermissionsForUsersState = Map<string, BoardPermissionForUser.BoardPermissionForUser>();

const reducer: Reducer<BoardPermissionsForUsersState, InspectioBoardsAction> = (state: BoardPermissionsForUsersState = initialState, action: InspectioBoardsAction): BoardPermissionsForUsersState => {
    switch (action.type) {
        case BOARD_PERMISSIONS_FOR_USERS_FETCHED:
            return upsertAggregates(state, action.payload.boardPermissions);
        case BOARD_PERMISSIONS_CHANGED: {
            const boardId = action.payload.boardId;
            const userId = action.payload.userId;
            const boardPermission: BoardPermissionForUser.BoardPermissionForUser | undefined = state.valueSeq().toArray()
                .find(permissionEntry => permissionEntry.boardId === boardId && permissionEntry.userId === userId);

            if (boardPermission) {
                return state.setIn([boardPermission.uid, 'permissions'], action.payload.permissions);
            }

            return state;
        }
        case BOARD_ACCESS_REVOKED: {
            const boardId = action.payload.boardId;
            const userId = action.payload.userId;
            const boardPermission: BoardPermissionForUser.BoardPermissionForUser | undefined = state.valueSeq().toArray()
                .find(permissionEntry => permissionEntry.boardId === boardId && permissionEntry.userId === userId);

            if (boardPermission) {
                return state.remove(boardPermission.uid);
            }

            return state;
        }
        default:
            return state;
    }
};

export { reducer as saveBoardPermissionsForUsersReducer };
