import {AxiosError, AxiosPromise, AxiosResponse} from "axios";
import api from './ConfiguredAxios';
import configuredAxios from "./ConfiguredAxios";
import configuredSocket from "./ConfiguredSocket";
import {setToken} from "../Auth/service/tokenCache";


export interface ResponseType {
    response?: AxiosResponse,
    error?: AxiosResponse,
}

export interface KeyCloakUserObject extends Object{
    uid: string,
    email: string,
    display_name: string,
    company_name: string,
    job_title: string,
    avatar_url: string,
}

/**
 * Provide axios and the socket connection with the new token and also update the token cookie
 *
 * @param token
 */
export const distributeNewToken = (token: string) => {
    configuredAxios.defaults.headers.Authorization = 'Bearer ' + token;
    // @ts-ignore
    configuredSocket.io.opts.transportOptions!.polling.extraHeaders.Authorization = 'Bearer ' + token;
    setToken(token);

    const expiresDate = new Date();
    expiresDate.setTime(expiresDate.getTime() + 10 * 60 * 1000);
    document.cookie = 'token=' + token + ';path=/buckets/boards;expires=' + expiresDate.toUTCString();
};

export const removeLoginToken = () => {
    const loc = new URL(window.location.toString());
    if(loc.searchParams.has('t')) {
        loc.searchParams.delete('t');
        window.history.replaceState('', '', loc.toString());
    }
}

export const retry = async (ctor: () => Promise<ResponseType>, maxRetries: number = 7, delay: number = 200) => {
    let response: ResponseType;
    let retries = 0;

    do {
        response = await ctor();

        if (response.response) {
            return response;
        }
        maxRetries--;

        if (maxRetries === 0) {
            return response;
        }

        retries++;
        await new Promise(res => setTimeout(res, retries * delay));
    } while (maxRetries > 0);

    return response;
}

export const axiosApiPromise = (messageName: string, payload: object): Promise<ResponseType> => {
    switch (messageName) {
        case 'InspectIO.BoardClient':
            return api.post(messageName, payload)
                .then((response: AxiosResponse) => ({response}))
                .catch((error: AxiosError) => ({error: error.response}));
        default:
            return retry(
                () => api.post(messageName, payload)
                    .then((response: AxiosResponse) => ({response}))
                    .catch((error: AxiosError) => ({error: error.response}))
            );
    }
};

export const userManagementApiPromise = (messageName: string, payload: object): Promise<ResponseType> => {
    return api.post(messageName, payload, { baseURL: '/board/api/v1/user-management' })
        .then((response: AxiosResponse) => ({response}))
        .catch((error: AxiosError) => ({error: error.response}));
};

export const avatarUploadApiPromise = (messageName: string, payload: any): AxiosPromise<any> => {
    return api.post(messageName, payload,{ baseURL: '/avatar/' });
};

export const avatarDeletionApiPromise = (messageName: string): AxiosPromise<any> => {
    return api.delete(messageName, { baseURL: '/avatar/' });
};

export const boardImageUploadApiPromise = (boardId: string, payload: string|ArrayBuffer, mimeType: string): AxiosPromise<any> => {
    return api.post(
        boardId,
        payload,
        { baseURL: '/buckets/boards/', headers: { 'Content-Type': mimeType } }
    );
};

export const boardImageReplacementApiPromise = (boardId: string, imageId: string, payload: string|ArrayBuffer, mimeType: string): AxiosPromise<any> => {
    return api.post(
        `${boardId}/${imageId}`,
        payload,
        { baseURL: '/buckets/boards/',  headers: { 'Content-Type': mimeType } }
    );
};

export const boardImageDeletionApiPromise = (imageUrl: string): AxiosPromise<any> => {
    return api.delete(imageUrl, { baseURL: `/` });
};
