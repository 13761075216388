import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../../InspectioBoards/index";
import {createStickyTemplateFromDefault, deriveStickTemplateId} from "../../InspectioBoards/model/StickyTemplate";
import {makeStickyTemplateSelector} from "../../InspectioBoards/selectors/StickyTemplate";
import {Action as NotifyAction} from "../../NotificationSystem";
import {StandaloneBoard} from "../storage/types";
import {standaloneStorageManager} from "../util";

type SetStickyCommand = ActionType<typeof Action.Command.setStickyTemplate>;
type UpdateStickyAdvancedConfigCommand = ActionType<typeof Action.Command.updateStickyAdvancedConfig>;
type Command = SetStickyCommand & UpdateStickyAdvancedConfigCommand;

function* handleSetStickyTemplate(command: SetStickyCommand) {
    let stickyTemplate = yield select(makeStickyTemplateSelector(deriveStickTemplateId(command.payload.boardId, command.payload.cellType)));

    const {boardId, cellType, template} = command.payload;

    if (!stickyTemplate) {
        stickyTemplate = createStickyTemplateFromDefault(
            boardId,
            cellType,
            template
        )
    }

    const board: StandaloneBoard|null = yield call(standaloneStorageManager.getBoard, command.payload.boardId);
    if (!board) {
        return;
    }

    const oldStickyTemplateData = board.stickyTemplates.find(
        stickyTemplateData => stickyTemplateData.cellType === stickyTemplate.cellType
    ) || {
        cellType,
        templateType: stickyTemplate.templateType,
        templateSchema: stickyTemplate.templateSchema || null,
    };

    yield call(standaloneStorageManager.updateBoard, {
        ...board,
        stickyTemplates: [
            ...board.stickyTemplates.filter(stickyTemplateData => stickyTemplateData.cellType !== stickyTemplate.cellType),
            {
                ...oldStickyTemplateData,
                template,
            },
        ]
    });

    yield put(NotifyAction.Command.info('Sticky Template set', 'Sticky template for ' + cellType + ' set'));
    yield put(Action.Event.stickyTemplateSet(stickyTemplate.setTemplate(template)));
}

function* handleUpdateStickyAdvancedConfig(command: UpdateStickyAdvancedConfigCommand) {yield null;
    let stickyTemplate = yield select(makeStickyTemplateSelector(deriveStickTemplateId(command.payload.boardId, command.payload.cellType)));

    if (!stickyTemplate) {
        stickyTemplate = createStickyTemplateFromDefault(
            command.payload.boardId,
            command.payload.cellType,
            ''
        );
    }

    const board: StandaloneBoard|null = yield call(standaloneStorageManager.getBoard, command.payload.boardId);
    if (!board) {
        return;
    }

    const oldStickyTemplateData = board.stickyTemplates.find(
        stickyTemplateData => stickyTemplateData.cellType === stickyTemplate.cellType
    ) || {
        cellType: stickyTemplate.cellType,
        template: stickyTemplate.template,
    };

    yield call(standaloneStorageManager.updateBoard, {
        ...board,
        stickyTemplates: [
            ...board.stickyTemplates.filter(stickyTemplateData => stickyTemplateData.cellType !== stickyTemplate.cellType),
            {
                ...oldStickyTemplateData,
                templateType: command.payload.templateType,
                templateSchema: command.payload.templateSchema || null
            },
        ]
    });

    yield put(NotifyAction.Command.info('Advanced Sticky Config updated', 'Advanced Sticky Config for ' + stickyTemplate.cellType + ' updated'));
    stickyTemplate = stickyTemplate.setTemplateType(command.payload.templateType);

    if (command.payload.templateSchema) {
        stickyTemplate = stickyTemplate.setTemplateSchema(command.payload.templateSchema);
    }

    yield put(Action.Event.stickyAdvancedConfigUpdated(stickyTemplate));
}

export function* standaloneUpdateStickyTemplateFlow() {
    while (true) {
        const command: Command = yield take([
            Action.Type.SET_STICKY_TEMPLATE,
            Action.Type.UPDATE_STICKY_ADVANCED_CONFIG
        ]);

        if (command.type === Action.Type.SET_STICKY_TEMPLATE) {
            yield fork(handleSetStickyTemplate, command);
        } else {
            yield fork(handleUpdateStickyAdvancedConfig, command);
        }
    }
}
