import {History} from "history";
import {List} from "immutable";
import { action } from 'typesafe-actions';
import {Model as TeamModel} from "../../InspectioTeams";
import {OrganizationId} from "../../Organization/model/Organization";
import {UserModel} from '../../User';
import { BoardModel, BoardSnapshot } from '../model';
import {BoardId} from "../model/Board";
import {BoardInvitationId} from "../model/BoardInvitation";
import {Permissions} from "../model/BoardPermissionForUser";
import {BoardVersion} from "../model/BoardVersion";
import {Graph, Node, NodeType} from "../model/Graph";
import {BoardHistory} from "../model/HistoryEntry";
import {StickyTemplate, TemplateSchema, TemplateType} from "../model/StickyTemplate";
import {
    ABORT_BOARD_DELETION,
    ADD_BOARD,
    ADMIN_CHANGE_TEAM_BOARD_PERMISSIONS,
    APPEND_HISTORY_ENTRIES,
    CHANGE_BOARD_ABBREVIATION,
    CHANGE_BOARD_PERMISSIONS,
    CHANGE_RESPONSIBLE_TEAM,
    CLEAR_BOARD_HISTORY,
    CONFIRM_DELETE_BOARD,
    CREATE_BOARD_CLIENT,
    DELETE_BOARD,
    DISMISS_MAINTENANCE_MESSAGE,
    FILTER_BOARD_OVERVIEW,
    FILTER_BOARD_OVERVIEW_BY_RESPONSIBLE_TEAM,
    FILTER_ELEMENTS_TREE,
    FORCE_UPDATE_BOARDS,
    HIDE_OWN_BOARDS_ON_OVERVIEW,
    HIDE_SHARED_BOARDS_ON_OVERVIEW,
    IMPORT_EXISTING_BOARD,
    IMPORT_NEW_BOARD,
    IMPORT_STICKY_TEMPLATES,
    LOAD_FIRST_BOARD_HISTORY_CHUNK,
    LOAD_NEXT_BOARD_HISTORY_CHUNK,
    MOVE_USER_BOARD_TO_ORGANIZATION,
    PREPEND_HISTORY_ENTRIES,
    REMOVE_BOARD_CLIENT,
    RENAME_BOARD,
    RESEND_BOARD_INVITATION,
    RESET_BOARD_CLIENT_SECRET,
    REVOKE_ACCESS_TO_BOARD,
    REVOKE_BOARD_INVITATION,
    SAVE_BOARD,
    SET_BOARD_DETAILS_FETCHED,
    SET_BOARD_TAGS,
    SET_BOARD_TO_SNAPSHOT,
    SET_IS_LOADING_HISTORY,
    SET_NO_MORE_HISTORY_AVAILABLE,
    SET_STICKY_TEMPLATE,
    SHARE_BOARD,
    SHARE_BOARD_WITH_ANYONE,
    SHARE_BOARD_WITH_ORGANIZATION_MEMBERS,
    SORT_BOARD_OVERVIEW,
    TOGGLE_BOARD_HISTORY,
    TOGGLE_CODY_CONSOLE,
    TOGGLE_CODY_SUGGEST_ENABLED,
    TOGGLE_ELEMENT_DETAILS,
    TOGGLE_EVENT_MODELING_ENABLED,
    TOGGLE_PLAYSHOT_MODAL,
    TOGGLE_TIMER_MODAL,
    TOGGLE_VIDEO_SESSION,
    TRIGGER_EXPORT_BOARD,
    TRIGGER_IMPORT_BOARD,
    UPDATE_BOARD_ELEMENTS_TREE,
    UPDATE_BOARDS,
    UPDATE_STICKY_ADVANCED_CONFIG
} from './constants';
import {SORT_OPTIONS} from "../components/BoardOverview/Sorting";
import {TeamId} from "../../InspectioTeams/model/Team";

export const addBoard = (board: BoardModel.Board, history: History<any>) => action(ADD_BOARD, {board, history});
export const updateBoards = (boards: BoardModel.Board[]) => action(UPDATE_BOARDS, boards);
export const forceUpdateBoards = (boards: BoardModel.Board[]) => action(FORCE_UPDATE_BOARDS, boards);
export const setBoardToSnapshot = (snapshot: BoardSnapshot.BoardSnapshot) => action(SET_BOARD_TO_SNAPSHOT, {snapshot});
export const setBoardDetailsFetched = (boardId: BoardModel.BoardId, writeAccess: boolean) => action(SET_BOARD_DETAILS_FETCHED, {boardId, writeAccess});

export const triggerImportBoard = (boardId: BoardModel.BoardId) => action(TRIGGER_IMPORT_BOARD, {boardId});
export const triggerExportBoard = (boardId: BoardModel.BoardId) => action(TRIGGER_EXPORT_BOARD, {boardId});
export const toggleBoardHistory = (boardId: BoardModel.BoardId, visible: boolean) => action(TOGGLE_BOARD_HISTORY, {boardId, visible});
export const toggleElementDetails = (boardId: BoardModel.BoardId, visible: boolean, focusEditor: boolean) => action(TOGGLE_ELEMENT_DETAILS, {boardId, visible, focusEditor});
export const toggleCodyConsole = (boardId: BoardModel.BoardId, visible: boolean) => action(TOGGLE_CODY_CONSOLE, {boardId, visible});
export const togglePlayshotModal = (boardId: BoardModel.BoardId, visible: boolean) => action(TOGGLE_PLAYSHOT_MODAL, {boardId, visible});
export const toggleTimerModal = (boardId: BoardModel.BoardId, visible: boolean) => action(TOGGLE_TIMER_MODAL, {boardId, visible});
export const toggleVideoSession = (boardId: BoardModel.BoardId, active: boolean) => action(TOGGLE_VIDEO_SESSION, {boardId, active});
export const appendHistoryEntries = (boardId: BoardModel.BoardId, entries: BoardHistory) => action(APPEND_HISTORY_ENTRIES, {boardId, entries});
export const prependHistoryEntries = (boardId: BoardModel.BoardId, entries: BoardHistory) => action(PREPEND_HISTORY_ENTRIES, {boardId, entries});
export const clearBoardHistory = (boardId: BoardModel.BoardId) => action(CLEAR_BOARD_HISTORY, {boardId});
export const loadNextBoardHistoryChunk = (boardId: BoardModel.BoardId, boardVersion: BoardVersion) => action(LOAD_NEXT_BOARD_HISTORY_CHUNK, {boardId, boardVersion});
export const loadFirstBoardHistoryChunk = (boardId: BoardModel.BoardId) => action(LOAD_FIRST_BOARD_HISTORY_CHUNK, {boardId});
export const setNoMoreHistoryAvailable = (boardId: BoardModel.BoardId) => action(SET_NO_MORE_HISTORY_AVAILABLE, {boardId});
export const setIsLoadingHistory = (boardId: BoardModel.BoardId, loading: boolean) => action(SET_IS_LOADING_HISTORY, {boardId, loading});


export const filterBoardOverview = (filter: string) => action(FILTER_BOARD_OVERVIEW, {filter});
export const sortBoardOverview = (sort: SORT_OPTIONS) => action(SORT_BOARD_OVERVIEW, {sort});
export const filterBoardOverviewByResponsibleTeam = (teamId: TeamId | undefined) => action(FILTER_BOARD_OVERVIEW_BY_RESPONSIBLE_TEAM, {teamId});
export const hideSharedBoardsOnOverview = (hide: boolean) => action(HIDE_SHARED_BOARDS_ON_OVERVIEW, {hide});
export const hideOwnBoardsOnOverview = (hide: boolean) => action(HIDE_OWN_BOARDS_ON_OVERVIEW, {hide});
export const filterElementsTree = (boardId: BoardId, filter: string, focus?: boolean) => action(FILTER_ELEMENTS_TREE, {boardId, filter, focus});

export const saveBoard = (board: BoardModel.Board, graph: Graph) => action(SAVE_BOARD, {board, graph});
export const renameBoard = (board: BoardModel.Board) => action(RENAME_BOARD, {board});
export const changeBoardAbbreviation = (board: BoardModel.Board) => action(CHANGE_BOARD_ABBREVIATION, {board});
export const setBoardTags = (board: BoardModel.Board) => action(SET_BOARD_TAGS, {board});
export const changeResponsibleTeam = (board: BoardModel.Board) => action(CHANGE_RESPONSIBLE_TEAM, {board});
export const importNewBoard = (boardName: string, boardXml: string, boardId?: BoardId, importTemporary?: boolean) => action(IMPORT_NEW_BOARD, {boardName, boardXml, boardId, importTemporary});
export const importExistingBoard = (board: BoardModel.Board) => action(IMPORT_EXISTING_BOARD, {board});
export const toggleCodySuggestEnabled = (board: BoardModel.Board) => action(TOGGLE_CODY_SUGGEST_ENABLED, {board});

export const toggleEventModelingEnabled = (board: BoardModel.Board) => action(TOGGLE_EVENT_MODELING_ENABLED, {board});

export const confirmDeleteBoard = (boardId: string) => action(CONFIRM_DELETE_BOARD, {boardId});

export const abortBoardDeletion = (boardId: string) => action(ABORT_BOARD_DELETION, {boardId});

export const deleteBoard = (boardId: BoardModel.BoardId) => action(DELETE_BOARD, {boardId});

export const shareBoard = (boardId: BoardModel.BoardId, email: UserModel.Email, permissions?: Permissions) => action(SHARE_BOARD, {boardId, email, permissions});
export const resendBoardInvitation = (invitationId: BoardInvitationId) => action(RESEND_BOARD_INVITATION,{invitationId});
export const revokeBoardInvitation = (invitationId: BoardInvitationId) => action(REVOKE_BOARD_INVITATION, {invitationId});
export const revokeAccessToBoard = (boardId: BoardModel.BoardId, userId: UserModel.UserId) => action(REVOKE_ACCESS_TO_BOARD, {boardId, userId});
export const changeBoardPermissions = (boardId: BoardModel.BoardId, userId: UserModel.UserId, permissions: Permissions) => action(CHANGE_BOARD_PERMISSIONS, {boardId, userId, permissions});
export const adminChangeTeamBoardPermissions = (boardId: BoardModel.BoardId, teamId: TeamModel.Team.TeamId, permissions: Permissions) => action(ADMIN_CHANGE_TEAM_BOARD_PERMISSIONS, {boardId, teamId, permissions});
export const shareBoardWithOrganizationMembers = (boardId: BoardId, permissions: Permissions) => action(SHARE_BOARD_WITH_ORGANIZATION_MEMBERS, {boardId, permissions});
export const shareBoardWithAnyone = (boardId: BoardId, permissions: Permissions) => action(SHARE_BOARD_WITH_ANYONE, {boardId, permissions});

export const updateBoardElementsTree = (boardId: BoardModel.BoardId, elements: List<Node>) => action(UPDATE_BOARD_ELEMENTS_TREE, {boardId, elements});

export const createBoardClient = (boardId: BoardModel.BoardId) => action(CREATE_BOARD_CLIENT, {boardId});
export const removeBoardClient = (boardId: BoardModel.BoardId) => action(REMOVE_BOARD_CLIENT, {boardId});
export const resetBoardClientSecret = (boardId: BoardModel.BoardId) => action(RESET_BOARD_CLIENT_SECRET, {boardId});

export const setStickyTemplate = (boardId: BoardModel.BoardId, cellType: NodeType, template: string, disableNotification?: boolean) => action(SET_STICKY_TEMPLATE, {boardId, cellType, template, disableNotification});
export const updateStickyAdvancedConfig = (boardId: BoardModel.BoardId, cellType: NodeType, templateType: TemplateType, templateSchema?: TemplateSchema, disableNotification?: boolean) => action(UPDATE_STICKY_ADVANCED_CONFIG, {boardId, cellType, templateType, templateSchema, disableNotification});
export const importStickyTemplates = (boardId: BoardModel.BoardId, stickyTemplates: List<StickyTemplate>, finishedCb: () => void) => action(IMPORT_STICKY_TEMPLATES, {boardId, stickyTemplates, finishedCb});

export const moveUserBoardToOrganization = (organizationId: OrganizationId, boardId: BoardId) => action(MOVE_USER_BOARD_TO_ORGANIZATION, {organizationId, boardId});

export const dismissMaintenanceMessage = () => action(DISMISS_MAINTENANCE_MESSAGE, {});
