import { call, fork, select, take } from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import * as Action from "../actions";
import {UserInfo} from "../model/UserInfo";
import {UserSelector} from "../selectors";

type FlowAction = ActionType<typeof Action.Command.signIn>;

function* onUserAuthenticated(action: FlowAction) {
    const user: UserInfo = yield select(UserSelector.currentUserSelector, undefined);

    if(user.displayName) {
         yield call(Action.Api.syncUsername, user.uid, user.displayName, user.email, user.avatarUrl, user.jobTitle);
    }
}

export function* syncUsername() {
    while (true) {
        const action: FlowAction = yield take([
            Action.Type.SIGN_IN
        ]);

        yield fork(onUserAuthenticated, action)
    }
}