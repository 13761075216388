import {List, Map} from 'immutable';
import {Reducer} from 'redux';
import {
    BOARD_CLIENT_FETCHED, BOARD_CLIENT_NOT_CONFIGURED, BOARD_CLIENT_SECRET_FETCHED,
} from '../actions/constants';
import {BoardClient} from "../model/BoardClient";
import {InspectioBoardsAction} from './index';

export interface BoardClientsState extends Map<string, BoardClient|null> {
}

export const initialState: BoardClientsState = Map<string, BoardClient|null>();

const reducer: Reducer<BoardClientsState, InspectioBoardsAction> = (state: BoardClientsState = initialState, action: InspectioBoardsAction): BoardClientsState => {
    switch (action.type) {
        case BOARD_CLIENT_FETCHED:
            return state.set(action.payload.boardId, action.payload.client);
        case BOARD_CLIENT_NOT_CONFIGURED:
            return state.set(action.payload.boardId, null);
        case BOARD_CLIENT_SECRET_FETCHED:
            return state.setIn([action.payload.boardId, 'clientSecret'], action.payload.clientSecret);
        default:
            return state;
    }
};

export { reducer as saveBoardClientsReducer };
