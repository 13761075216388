import {List} from "immutable";
import { action } from 'typesafe-actions';
import {BoardModel} from "../../InspectioBoards/model";
import {Permissions} from "../../InspectioBoards/model/BoardPermissionForTeam";
import {OrganizationId} from "../../Organization/model/Organization";
import * as TeamModel from "../model/Team";
import {Team, TeamDescription, TeamId, TeamName} from "../model/Team";
import {TeamInvitationId} from "../model/TeamInvitation";
import {
    ADD_TEAM,
    ADMIN_GRANT_TEAM_ACCESS_TO_BOARD,
    ADMIN_REVOKE_TEAM_ACCESS_TO_BOARD, CHANGE_TEAM_DESCRIPTION, DELETE_TEAM,
    INVITE_USER_TO_TEAM, MOVE_TEAM_TO_ORGANIZATION,
    REMOVE_MEMBER_FROM_TEAM, RENAME_TEAM, RESEND_TEAM_INVITATION, REVOKE_TEAM_INVITATION, UPDATE_TEAMS
} from "./constants";

export const addTeam = (teamId: TeamId, teamName: TeamName, organizationId: OrganizationId | null) => action(ADD_TEAM, { teamId, teamName, organizationId });
export const renameTeam = (teamId: TeamId, newName: TeamName) => action(RENAME_TEAM, {teamId, newName});
export const changeTeamDescription = (teamId: TeamId, newDescription: TeamDescription) => action(CHANGE_TEAM_DESCRIPTION, {teamId, newDescription});

export const inviteUserToTeam = (invitationId: TeamInvitationId, teamId: string, userEmail: string) => action(INVITE_USER_TO_TEAM, { invitationId, teamId, userEmail });
export const resendTeamInvitation = (invitationId: TeamInvitationId) => action(RESEND_TEAM_INVITATION, {invitationId});
export const revokeTeamInvitation = (invitationId: TeamInvitationId) => action(REVOKE_TEAM_INVITATION, {invitationId});

export const removeMemberFromTeam = (teamId: string, memberId: string) => action(REMOVE_MEMBER_FROM_TEAM, { teamId, memberId });

export const updateTeams = (teams: List<TeamModel.Team>) => action(UPDATE_TEAMS, teams);

export const deleteTeam = (teamId: TeamModel.TeamId) => action(DELETE_TEAM, {teamId});

export const adminGrantTeamAccessToBoard = (boardId: BoardModel.BoardId, teamIds: TeamModel.TeamId[], permissions?: Permissions) => action(ADMIN_GRANT_TEAM_ACCESS_TO_BOARD, {boardId, teamIds, permissions});
export const adminRevokeTeamAccessToBoard = (boardId: BoardModel.BoardId, teamId: TeamModel.TeamId) => action(ADMIN_REVOKE_TEAM_ACCESS_TO_BOARD, {boardId, teamId});

export const moveTeamToOrganization = (teamId: TeamId, organizationId: OrganizationId) => action(MOVE_TEAM_TO_ORGANIZATION, {teamId, organizationId});
