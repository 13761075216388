import { Record } from 'immutable';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducer';
import { UserModel } from '../model';
import {
    makeActiveUserOrganizationInfoSelector,
    userOrganizationInfoListSelector
} from "../../Organization/selectors/selectUserOrganizationInfo";
import {makeOrganizationListSelector} from "../../Organization/selectors/selectOrganization";
import {Organization} from "../../Organization/model/Organization";
import {isStandalone} from "../../standalone/util";

export const currentUserSelector = (state: Record<ApplicationState>, props: any) => state.getIn(['inspectioUsers', 'users', 'currentUser']);

export const makeGetCurrentUser = (): (state: Record<ApplicationState>, props?: any) => UserModel.UserInfo => {
    return createSelector(
        [currentUserSelector],
        (user: UserModel.UserInfo): UserModel.UserInfo => user,
    );
};

export const makeGetCurrentBoardQuota = (): (state: Record<ApplicationState>, props?: any) => number => {
    const organizationListSelector = makeOrganizationListSelector();

    return createSelector(
        [currentUserSelector, organizationListSelector],
        (user: UserModel.UserInfo, organizations): number => {
            if(isStandalone()) {
                return -1;
            }

            let activeOrganization: Organization | undefined;

            organizations.forEach(organization => {
                if(organization.uid === user.activeOrganization) {
                    activeOrganization = organization;
                }
            });

            if(!activeOrganization) {
                return 0;
            }

            if(activeOrganization.guests.includes(user.uid)) {
                return 0;
            }

            return activeOrganization.boardQuota;
        },
    );
};

