import * as React from 'react';
import {useState} from "react";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {Icon, Label, Menu, MenuItem, Popup} from "semantic-ui-react";
import {logout} from "../../index";
import * as Routes from "../../routes";
import {makeGetCurrentUser} from "../selectors/currentUser";
import SideMenuOrganizationSwitch from "./SideMenuOrganizationSwitch";

interface OwnProps {

}

type TopmenuAvatarProps = OwnProps;

const TopmenuAvatar = (props: TopmenuAvatarProps) => {
    const currentUserSelector = makeGetCurrentUser();
    const currentUser = useSelector(currentUserSelector);
    const [open, setOpen] = useState(false);

    return <>
            <Popup
            on="click"
            position='bottom center'
            open={open}
            onOpen={() => {setOpen(true)}}
            onClose={() => {setOpen(false)}}
            trigger={
                <MenuItem>
                    <Label circular={true} className="user" style={{backgroundImage: `url(${currentUser.avatarUrl})`, backgroundSize: 'cover'}} />
                </MenuItem>
            }
            content={
                <Menu vertical={true} secondary={true} compact={true}>
                    <MenuItem to={Routes.userProfile} as={NavLink} activeClassName="active" onClick={() => setOpen(false)}>
                        <Icon name="user" />
                        Profile
                    </MenuItem>
                    <MenuItem onClick={() => logout()}>
                        <Icon name="sign-out" />
                        Sign Out
                    </MenuItem>
                </Menu>
            }
            />
        </>
};

export default TopmenuAvatar;
