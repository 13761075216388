import * as React from 'react';
import {Button, Modal} from "semantic-ui-react";
import {withNamespaces, WithNamespaces} from "react-i18next";

interface OwnProps {
    open: boolean;
    onClose: () => void;
    headerKey: 'header_team' | 'header_history'
}

type CollaborationInfoModalProps = OwnProps & WithNamespaces;

const CollaborationInfoModal = (props: CollaborationInfoModalProps) => {
    return (
        <Modal open={props.open} closeIcon={false} onClose={props.onClose} size="tiny">
            <Modal.Header>{props.t('insp.collaboration_modal.'+props.headerKey)}</Modal.Header>
            <Modal.Content>
                <p>{props.t('insp.collaboration_modal.text')}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button className="positive" href="https://prooph-board.com#pricing" target="_blank">
                    Upgrade
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default withNamespaces()(CollaborationInfoModal);
