import {Board} from "../../../model/Board";
import {Node} from "../../../model/Graph";
import {WizardContext} from "./wizard-context";

export interface WithFeatureNode {
  feature: Node;
}

export type ImmutableFeatureContext = Readonly<FeatureContext>;

interface FeatureContext extends WizardContext<ImmutableFeatureContext>, WithFeatureNode {

}

export const featureContextFromNode = (feature: Node, board: Board): ImmutableFeatureContext => {
  const ctx: FeatureContext = {
    board,
    type: 'feature',
    feature,
    nextStep: currentStep => null,
    previousStep: currentStep => null,
    installCodyGraphSuggestions: () => { /* Do nothing */ },
  }

  return ctx;
}

export const isFeatureContext = (ctx: WizardContext): ctx is ImmutableFeatureContext => {
  return ctx.type === 'feature';
}

export const isEventModel = (feature: Node): boolean => {
  return feature.getTags().includes(ispConst.TAG_SLICE) || feature.getTags().includes(ispConst.TAG_EVENT_MODEL);
}
