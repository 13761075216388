import {List, Record} from 'immutable';
import moment from "moment";
import {UserModel} from "../../User";
import {IioUserInfo} from "../../User";
import {BoardId} from "./Board";
import {BoardChanged} from "./BoardChanged";
import {BoardVersion, defaultBoardVersionProps} from "./BoardVersion";
import {NodeType} from "./Graph";

export interface BoardHistory extends List<HistoryEntry>{

}

export enum HistoryAction {
    added = 'added',
    edited = 'edited',
    deleted = 'deleted',
    moved = 'moved',
}

enum HistoryCellTypeAddition {multiple = 'multiple'}

export const HistoryCellType = {...NodeType, ...HistoryCellTypeAddition}
export type HistoryCellType = NodeType | HistoryCellTypeAddition;

export interface HistoryEntryProps {
    userId: UserModel.UserId;
    username: UserModel.Username;
    boardId: BoardId;
    boardVersion: BoardVersion;
    action: HistoryAction;
    cellType: HistoryCellType;
    label: string;
    date: string;
    cellId?: string;
}

export const defaultHistoryEntryProps: HistoryEntryProps = {
    userId: '',
    username: '',
    boardId: '',
    boardVersion: new BoardVersion(defaultBoardVersionProps),
    action: HistoryAction.edited,
    cellType: HistoryCellType.misc,
    label: '',
    date: '',
    cellId: undefined,
};

export class HistoryEntry extends Record(defaultHistoryEntryProps) implements HistoryEntryProps {}

export const makeHistoryEntry = (boardChanged: BoardChanged, userInfo: IioUserInfo.IioUserInfo): HistoryEntry => {
    return new HistoryEntry({
        action: boardChanged.action!,
        cellType: boardChanged.cellType!,
        label: boardChanged.cellLabel!,
        userId: userInfo.userId,
        username: userInfo.username,
        boardId: boardChanged.board,
        boardVersion: boardChanged.toBoardVersion(),
        date: boardChanged.recordedAt,
        cellId: boardChanged.cellId,
    });
};

