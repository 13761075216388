import {List} from "immutable";
import * as React from 'react';
import {useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Button, Dropdown, DropdownProps, Modal} from "semantic-ui-react";
import {BoardModel} from "../../InspectioBoards/model";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {Team, TeamId} from "../model/Team";
import {makeTeamListSelector} from "../selectors/selectTeam";

interface OwnProps {
    board: BoardModel.Board;
    open: boolean;
    onClose: () => void;
    onSubmit: (selectedTeam: TeamId) => void;
}

type AddTeamToBoardModalProps = OwnProps & WithNamespaces;

const AddTeamToBoardModal = (props: AddTeamToBoardModalProps) => {
    const currentUserSelector = makeGetCurrentUser();
    const currentUser = useSelector(currentUserSelector);
    const teams = useSelector(makeTeamListSelector(true));
    const [selectedTeam, setSelectedTeam] = useState('');

    const ownTeamsOptions = teams.map(team => ({
        key: team.uid,
        value: team.uid,
        text: team.name
    })).toArray();

    const handleChange = (e: React.SyntheticEvent, dProps: DropdownProps) => setSelectedTeam(dProps.value as string);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        props.onSubmit(selectedTeam);
        setSelectedTeam('');
    }

    return (
        <Modal open={props.open} closeIcon={true} onClose={props.onClose}>
            <Modal.Header>{props.t('insp.team.add_to_board', {board_name: props.board.name})}</Modal.Header>
            <Modal.Content>
                <form onSubmit={handleSubmit}>
                    <Dropdown
                        placeholder={props.t('insp.team.choose') as string}
                        fluid={true}
                        search={true}
                        selection={true}
                        options={ownTeamsOptions}
                        onChange={handleChange}
                        value={selectedTeam}
                    />
                    <div style={{marginTop: '10px'}}>
                        <Button primary={true} type="submit" disabled={!validateTeamExists(selectedTeam, teams)}>
                            {props.t('app.form.confirm')}
                        </Button>
                    </div>
                </form>
            </Modal.Content>
        </Modal>
    );
};

const validateTeamExists = (teamId: TeamId, teams: List<Team>): boolean => {
    const matchingTeam = teams.find(team => team.uid === teamId);

    return !!matchingTeam;
}

export default withNamespaces()(AddTeamToBoardModal);
