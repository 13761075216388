import * as React from 'react';
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Icon, MenuItem} from "semantic-ui-react";
import {makeBoardByIdSelector} from "../selectors/board";
import {BoardTopMenuRouteMatchParams} from "./BoardTopMenu";
import * as Action from "../actions";
import InviteUserToBoardModal from "./InviteUserToBoardModal";
import {Permissions} from "../model/BoardPermissionForUser";
import {Email} from "../../User/model/UserInfo";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeOrganizationSelector} from "../../Organization/selectors/selectOrganization";

interface OwnProps {

}

type ShareBoardButtonProps = OwnProps & RouteComponentProps<BoardTopMenuRouteMatchParams>;

const ShareBoardButton = (props: ShareBoardButtonProps) => {
    const currentUser = useSelector(makeGetCurrentUser());
    const organization = useSelector(makeOrganizationSelector(currentUser.activeOrganization));
    const [userEmail, setUserEmail] = useState<string>('');
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<Permissions>({adminAccess: false, writeAccess: true, readAccess: true});
    const board = useSelector(makeBoardByIdSelector(props.match.params.uid));
    const dispatch = useDispatch();

    const isAdmin = organization ? organization.isAdmin(currentUser.uid) || (board && board.isCreator(currentUser.uid)) : false;

    const openAddUserModal = (event: React.MouseEvent) => {
        event.stopPropagation();
        setShowAddUserModal(true);
    };

    const closeAddUserModal = () => {
        setShowAddUserModal(false);
    };

    const handleShareBoardSubmit = () => {
        dispatch(Action.Command.shareBoard(board.uid, userEmail, permissions));

        setUserEmail('');
        closeAddUserModal();
    };

    const onEmailChange = (email: Email) => {
        setUserEmail(email);
    };

    return <>{board && (board.organization? isAdmin : !board.shared) &&
        <>
            <MenuItem>
                <Button icon={true} className="share" onClick={openAddUserModal}><Icon name="share alternate"/>&nbsp;share</Button>
            </MenuItem>
            <InviteUserToBoardModal
                open={showAddUserModal}
                onClose={closeAddUserModal}
                onSubmit={handleShareBoardSubmit}
                email={userEmail}
                onEmailChange={onEmailChange}
                permissions={permissions}
                onPermissionsChange={setPermissions}
            />
        </>
    }
    </>;
}

export default withRouter(ShareBoardButton);
