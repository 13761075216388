import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Route} from "react-router";
import BoardTreeView from "../../../../InspectioBoards/components/BoardTreeView";
import Feedback from "../../../../Layout/Sidebar/Feedback";
import Logo from "../../../../Layout/Sidebar/Logo";
import Search from "../../../../Layout/Sidebar/Search";
import Upgrade from "../../../../Layout/Sidebar/Upgrade";
import * as Routes from "../../../../routes";

interface OwnProps {
    collapsed: boolean;
}

type WorkspaceSidebarProps = OwnProps & WithNamespaces;

const WorkspaceSidebar = (props: WorkspaceSidebarProps) => {
    return <>
        {!props.collapsed && <Logo collapsed={props.collapsed} />}
        {!props.collapsed && <Upgrade />}
        <div className="item">
            <div id="boardSidebarContainer" />
        </div>
        {!props.collapsed && <>
            <Search />
            <Route exact={true} path={Routes.inspectioBoardWorkspace} component={BoardTreeView} />
            <Feedback/>
        </>}
    </>;
};

export default withNamespaces()(WorkspaceSidebar);
