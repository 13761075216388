import {List} from "immutable";
import { call, fork, put, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {ResponseType} from "../../api/util";
import {execForEach, execValidate, isFailure, isSuccess, pipe} from "../../core/validation/either";
import {isArray} from "../../core/validation/predicates";
import { Action as NotifyAction } from '../../NotificationSystem';
import * as Action from '../actions';
import {createBoardInvitationFromServerData} from "../model/BoardInvitation";

type FlowAction = ActionType<typeof Action.Query.fetchOpenBoardInvitations>;

const onFetchOpenBoardInvitations = function* (action: FlowAction) {
    const {response, error}: ResponseType = yield call(Action.Api.fetchOpenBoardInvitations, action.payload.boardId);

    if (error) {
        yield put(NotifyAction.Command.error("Request Error", "Failed to load open board invitations"));
    }

    if (response) {
        const result = pipe(
            response!.data,
            execValidate([isArray(), "Response data is not of type Array"]),
            execForEach((data: any) => {
                return createBoardInvitationFromServerData(data);
            }),
        );

        if (isFailure(result)) {
            // tslint:disable-next-line:no-console
            console.error("Failed to process open board invitations response from server", result.value, response.data);
            yield put(NotifyAction.Command.error('Request Error', 'Could not load open board invitations'));
        }

        if (isSuccess(result)) {
            const openBoardInvitations = List(result.value);
            yield put(Action.Event.openBoardInvitationsFetched(openBoardInvitations));
        }
    }
};

export function* fetchOpenBoardInvitationsFlow() {

    while (true) {
        const action: FlowAction = yield take([Action.Type.FETCH_OPEN_BOARD_INVITATIONS]);

        yield fork(onFetchOpenBoardInvitations, action);
    }
}