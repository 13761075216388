import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {Team} from "../model";
import {makeTeamSelector} from "../selectors/selectTeam";
import {loadUserTeams} from "./loadUserTeams";

type Command = ActionType<typeof Action.Command.deleteTeam>

function* handleDeleteTeam(command: Command) {
    const team: Team.Team = yield select(makeTeamSelector(command.payload.teamId));
    const {response, error}: ResponseType = yield call(Action.Api.deleteTeam, command.payload.teamId);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not delete team.'));
    }
    if (response) {
        yield put(NotifyAction.Command.info('Team deleted', 'Team ' + team.name  + 'deleted'));
        yield put(Action.Event.teamDeleted(team));
    }
}

export function* deleteTeam() {
    while (true) {
        const command: Command = yield take([
            Action.Type.DELETE_TEAM
        ]);

        yield fork(handleDeleteTeam, command);
    }
}