import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, Container, Grid, Header, Icon, Menu, MenuItem, Message} from "semantic-ui-react";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeTeamListSelector} from "../selectors/selectTeam";
import TeamTab from "./TeamOverview/TeamTab";

enum ActivePage {
    organization = 'organization',
    teams = 'teams'
}

interface OwnProps {

}

type TeamOverviewProps = OwnProps & WithNamespaces;

const TeamOverview = (props: TeamOverviewProps) => {
    const teams = useSelector(makeTeamListSelector());
    const currentUser = useSelector(makeGetCurrentUser());


    return <Container>
            <Grid stackable={true} padded={true}>
                <TeamTab teams={teams} currentUser={currentUser} />
            </Grid>
    </Container>;
};

export default withNamespaces()(TeamOverview);
