import {Map} from "immutable";
import {Reducer} from 'redux';
import {
    ACTIVE_GRAPH_ELEMENT_CHANGED,
    ACTIVE_GRAPH_ELEMENT_LABEL_CHANGED,
    ACTIVE_GRAPH_ELEMENT_METADATA_CHANGED,
} from '../actions/constants';
import {makeNodeFromJs, Node, NodeId, NodeType} from "../model/Graph";
import {InspectioBoardsAction} from './index';

export interface EdgeData {
    source: NodeId,
    target: NodeId,
}

export const makeNodeFromActiveGraphElement = (element: ActiveGraphElement): Node => {
    return makeNodeFromJs({
        id: element.id,
        type: element.type as NodeType,
        name: element.label,
        metadata: element.metadata,
        tags: element.tags,
    })
}

export interface ActiveGraphElement {
    id: string,
    type: string,
    label: string,
    metadata?: string,
    edgeData?: EdgeData,
    tags?: string[];
    updateMetadata: (schema: string, force?: boolean) => void;
    updateSimilarElements: (elementIds: string[], schema: string) => void;
    replaceTags: (tags: string[]) => void;
    locked?: boolean;
}

export interface ActiveGraphElementState extends Map<string, ActiveGraphElement | undefined> {
}

export const initialState: ActiveGraphElementState = Map<string, ActiveGraphElement>();

const reducer: Reducer<ActiveGraphElementState, InspectioBoardsAction> = (state: ActiveGraphElementState = initialState, action: InspectioBoardsAction): ActiveGraphElementState => {
    switch (action.type) {
        case ACTIVE_GRAPH_ELEMENT_CHANGED:
            if(!action.payload.element) {
                return state.set(action.payload.boardId, undefined);
            }

            return state.set(action.payload.boardId, {
                id: action.payload.element.id,
                type: action.payload.element.type,
                label: action.payload.element.label,
                metadata: action.payload.element.metadata || undefined,
                edgeData: action.payload.element.edgeData || undefined,
                tags: action.payload.element.tags || [],
                updateMetadata: action.payload.element.updateMetadata,
                updateSimilarElements: action.payload.element.updateSimilarElements,
                replaceTags: action.payload.element.replaceTags,
                locked: !!action.payload.element.locked,
            });
        case ACTIVE_GRAPH_ELEMENT_LABEL_CHANGED:
            if(state.getIn([action.payload.boardId, 'id']) !== action.payload.elementId) {
                return state;
            }

            return state.setIn([action.payload.boardId, 'label'], action.payload.label);
        case ACTIVE_GRAPH_ELEMENT_METADATA_CHANGED:
            if(state.getIn([action.payload.boardId, 'id']) !== action.payload.elementId) {
                return state;
            }

            return state.setIn([action.payload.boardId, 'metadata'], action.payload.metadata);
        default:
            return state;
    }
};

export { reducer as saveActiveGraphElementReducer };
