import {List} from "immutable";
import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {Icon, Menu} from "semantic-ui-react";
import CodeEditor from "../../../core/components/CodeEditor/CodeEditor";
import * as Routes from "../../../routes";
import CollaborationInfoModal from "../../../standalone/components/CollaborationInfoModal";
import {isStandalone} from "../../../standalone/util";
import {useElementsTree} from "../../hooks/useElementsTree";
import {Board, BoardId} from "../../model/Board";
import {Node, NodeType} from "../../model/Graph";
import {HistoryCellType} from "../../model/HistoryEntry";
import {StickyTemplate} from "../../model/StickyTemplate";
import {ActiveGraphElement} from "../../reducers/applyActiveGraphElementActions";
import {isConnected} from "../BoardElementDetails";
import BoardHistory from "../BoardHistory";
import CellIcon from "../CellIcon";
import {RANGE_BOARD_ELEMENT_DETAILS} from "../MxGraphBoard/clickRange";
import ElementConnections from "./ElementConnections";
import MetadataCodyWizard from "./MetadataCodyWizard";

export interface BoardElementMetadataRef {
    retrievePayload: () => string;
}

interface OwnProps {
    focusEditor: boolean;
    boardId: BoardId;
    board?: Board;
    activeNodeSources: List<Node>;
    activeNodeTargets: List<Node>;
    activeGraphElement: ActiveGraphElement;
    similarElements: List<string>;
    boardStickyTemplate: StickyTemplate;
    onTriggerCody: () => void;
    onUpdateMetadata: (value: string, force?: boolean) => void;
    onShowRunCody: (showRunCody: boolean) => void;
    onClose?: () => void;
    t: any; // Workaround for withNamespaces HOC not working with forwardRef
}

export type ActiveTab = 'wizard' | 'metadata' | 'activity';

type BoardElementMetadataProps = OwnProps;

const BoardElementMetadata = (props: BoardElementMetadataProps, ref: React.Ref<BoardElementMetadataRef>) => {
    const elementsTree = useElementsTree(props.boardId);
    const [clicks, setClicks] = useState(RANGE_BOARD_ELEMENT_DETAILS);
    const codeEditorRef = useRef<CodeEditor|null>(null);
    const [activeTab, setActiveTab] = useState<ActiveTab>(props.focusEditor ? 'metadata' : 'wizard');
    const [infoModalOpen, setInfoModalOpen] = useState(false);

    const isNodeWithMetadata = props.activeGraphElement.type !== NodeType.textCard
        && props.activeGraphElement.type !== NodeType.edge;

    useImperativeHandle<BoardElementMetadataRef, BoardElementMetadataRef>(ref, () => ({
        retrievePayload: (): string => codeEditorRef.current!.retrievePayload(),
    }));

    useEffect(() => {
        if(!props.board || !props.board.codySuggestEnabled) {
            setActiveTab('metadata');
        }
    }, [props.boardId, (props.board && props.board.codySuggestEnabled)]);

    useEffect(() => {
        if (!codeEditorRef.current) {
            return;
        }

        const disposable = codeEditorRef.current!.addKeyDownHandler((e: KeyboardEvent) => {
            if (e.ctrlKey && (e.code === 'KeyS' || e.code === 'KeyG')) {
                e.preventDefault();

                props.onUpdateMetadata(codeEditorRef.current!.retrievePayload(), true);
                props.onTriggerCody();
            }

            if(e.code === 'Escape' && props.onClose) {
                props.onUpdateMetadata(codeEditorRef.current!.retrievePayload());
                e.preventDefault();
                e.stopPropagation();
                props.onClose();
            }
        });

        return () => {
            disposable.dispose();
        }
    }, [props.activeGraphElement.id]);

    useEffect(() => {
        if(activeTab !== 'metadata') {
            return;
        }

        if (!isNodeWithMetadata || !codeEditorRef.current) {
            return;
        }

        if(!props.activeGraphElement.metadata && isConnected(props.activeGraphElement) && props.similarElements.count() > 0) {
            const firstSimilarElement = elementsTree.getElement(props.similarElements.first(''));

            if(firstSimilarElement && firstSimilarElement.getMetadata()) {
                props.onUpdateMetadata(firstSimilarElement.getMetadata() as string, true);
            }
        }

        codeEditorRef.current!.initializeModel({
            fileId: `graph_element_${props.activeGraphElement.id}.json`,
            language: props.boardStickyTemplate.templateType,
            value: props.activeGraphElement.metadata || props.boardStickyTemplate.template || '{\n  \n}\n',
            schema: props.boardStickyTemplate.templateSchema || undefined,
            onChange: e => {
                if (codeEditorRef.current) {
                    props.onUpdateMetadata(codeEditorRef.current.retrievePayload());
                }
            },
        });
    }, [props.activeGraphElement.id, props.activeGraphElement.metadata, props.similarElements, activeTab])

    useEffect(() => {
        if(props.focusEditor && activeTab !== 'metadata') {
            changeActiveTab('metadata');
        }
        if(codeEditorRef.current && props.focusEditor) {
            codeEditorRef.current!.focus();
        }
    }, [props.focusEditor, activeTab]);

    const changeActiveTab = (changedTab: ActiveTab) => {
        if (changedTab === 'activity' && isStandalone()) {
            setInfoModalOpen(true);
            return;
        }
        setActiveTab(changedTab);
        props.onShowRunCody(changedTab === "wizard");
    }

    return (<>
        <Link
            style={{borderRadius: '0', color: '#424242'}}
            onClick={() => setClicks(clicks + 1)}
            to={{pathname: Routes.compileInspectioBoardWorkspace(props.boardId), search: `?cells=${props.activeGraphElement.id}&clicks=${clicks}`}}>
            {props.activeGraphElement.locked && <Icon name="lock" title="Element is locked"/>}<CellIcon cellType={props.activeGraphElement.type as HistoryCellType} /> <strong>{props.t('app.stickyTypes.' + props.activeGraphElement.type)}</strong>
        </Link>
        {(props.activeNodeSources.size > 0 || props.activeNodeTargets.size > 0) && (
            <ElementConnections
                boardId={props.boardId}
                sources={props.activeNodeSources}
                targets={props.activeNodeTargets}
            />
        )}
        <Menu pointing={true} secondary={true} color="blue">
            <Menu.Item name="Cody" active={activeTab === 'wizard'} onClick={() => changeActiveTab('wizard')} />
            <Menu.Item name="Metadata" active={activeTab === 'metadata'} onClick={() => changeActiveTab('metadata')} />
            <Menu.Item name="Activity" active={activeTab === 'activity'} onClick={() => changeActiveTab('activity')} />
        </Menu>
        {isNodeWithMetadata && activeTab === 'wizard' && (
          <div style={{height: 'auto', overflowY: 'auto', flexGrow: 1}}>
            <MetadataCodyWizard boardId={props.boardId} activeGraphElement={props.activeGraphElement} onUpdateMetadata={props.onUpdateMetadata} />
          </div>
        )}
        {isNodeWithMetadata && activeTab === 'metadata' && (<>
            <CodeEditor
                ref={codeEditorRef}
                options={{
                    fontSize: 12,
                    folding: false,
                    glyphMargin: false,
                    lineNumbers: 'off',
                    lineDecorationsWidth: 3,
                    minimap: {
                        enabled: false
                    },
                    formatOnPaste: true,
                    scrollBeyondLastLine: false,
                    automaticLayout: true,
                    scrollbar: {
                        alwaysConsumeMouseWheel: false
                    },
                    readOnly: props.activeGraphElement.locked
                }}
                style={{ height: '500px' }}

            />
        </>)}
        {activeTab === 'activity' && <BoardHistory elementId={props.activeGraphElement.id} />}
        <CollaborationInfoModal open={infoModalOpen} onClose={() => setInfoModalOpen(false)} headerKey={'header_history'} />
    </>);
}

export default React.forwardRef(BoardElementMetadata);
