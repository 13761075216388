import { Reducer } from 'redux';
import {START_FETCH_USER_BOARDS, UPDATE_BOARDS} from "../actions/constants";
import {InspectioBoardsAction} from "./index";

const reducer: Reducer<boolean, InspectioBoardsAction> = (state: boolean = true, action: InspectioBoardsAction): boolean => {
    switch (action.type) {
        case START_FETCH_USER_BOARDS:
            return  true;
        case UPDATE_BOARDS:
            return false;
        default:
            return state;
    }
};

export { reducer as loadBoardsReducer };