import { call, put, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ResponseType } from '../../api/util';
import {Action as NotifyAction} from "../../NotificationSystem";
import * as Action from '../actions';

type FlowAction = ActionType<typeof Action.Command.renameBoard>

export function* renameBoardFlow() {

    while (true) {
        const action: FlowAction = yield take([Action.Type.RENAME_BOARD]);

        const {response, error}: ResponseType = yield call(Action.Api.renameBoard, action.payload.board.uid, action.payload.board.name);

        if (error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not rename board.'));
            yield put(Action.Query.fetchUserBoards());
        }

        if(response) {
            yield put(NotifyAction.Command.info('Board renamed', ''));
        }
    }
}