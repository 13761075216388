import * as React from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Header, Icon, Image, Label, Table} from "semantic-ui-react";
import {Action as TeamAction} from "../../../InspectioTeams";
import {makeTeamSelector} from "../../../InspectioTeams/selectors/selectTeam";
import * as Action from "../../actions";
import {Board} from "../../model/Board";
import {BoardPermissionForTeam} from "../../model/BoardPermissionForTeam";

interface PropsFromState {
}

interface PropsFromDispatch {

}

interface OwnProps {
    board: Board;
    boardPermission: BoardPermissionForTeam;
}

export type BoardPermissionsForTeamsTableEntryProps = PropsFromState & PropsFromDispatch & OwnProps & WithNamespaces;

const BoardPermissionsForTeamsTableEntry = (props: BoardPermissionsForTeamsTableEntryProps) => {

    const dispatch = useDispatch();
    const team = useSelector(makeTeamSelector(props.boardPermission.teamId));

    const hasReadAccess = props.boardPermission.permissions.readAccess;
    const hasWriteAccess = props.boardPermission.permissions.writeAccess;

    if (!hasReadAccess && !hasWriteAccess) {
        return null;
    }

    if (!team) {
        return null;
    }

    const handleRevokeAccessToBoard = () => {
        dispatch(TeamAction.Command.adminRevokeTeamAccessToBoard(props.board.uid, team.uid));
    };

    const handleChangeReadBoardPermissions = () => {
        if(!props.boardPermission.permissions.writeAccess) {
            return;
        }

        dispatch(Action.Command.adminChangeTeamBoardPermissions(
            props.board.uid,
            team.uid,
            { ...props.boardPermission.permissions, writeAccess: false }
        ));
    };

    const handleChangeWriteBoardPermissions = () => {
        dispatch(Action.Command.adminChangeTeamBoardPermissions(
            props.board.uid,
            team.uid,
            { ...props.boardPermission.permissions, writeAccess: !props.boardPermission.permissions.writeAccess }
        ));
    };

    const handleChangeResponsibleTeam = () => {
        const responsibleTeam = props.board.responsibleTeam === props.boardPermission.teamId? undefined : props.boardPermission.teamId;
        const changedBoard = props.board.merge({responsibleTeam})
        dispatch(Action.Command.changeResponsibleTeam(changedBoard));
    }

    return (
        <Table.Row>
            <Table.Cell style={{width: "310px"}}>
                <Header as='h4' image={true}>
                    <img className="ui avatar image" src={`/avatar/team-${team.uid}?displayName=${team.name}`} />
                    <Header.Content>
                        {team.name}
                        <Header.Subheader>{team.description}</Header.Subheader>
                    </Header.Content>
                </Header>
            </Table.Cell>
            <Table.Cell>
                <Button.Group>
                    <Button
                        className={'positive'}
                        children={'Read'}
                        onClick={handleChangeReadBoardPermissions}
                    />
                    <Button.Or text="+" />
                    <Button
                        className={hasWriteAccess ? 'positive' : ''}
                        children={'Write'}
                        onClick={handleChangeWriteBoardPermissions}
                    />
                </Button.Group>
                <Button
                    icon={true}
                    className="right floated basic circular noborder"
                    children={<Icon className="red close icon" />}
                    onClick={handleRevokeAccessToBoard}
                />
                <Button
                    icon={true}
                    className="right floated basic circular noborder responsible-team"
                    title={props.t('insp.board.form.responsible_team')}
                    children={<Icon className={props.board.responsibleTeam === props.boardPermission.teamId? 'yellow star icon' : 'star outline icon'} />}
                    onClick={handleChangeResponsibleTeam}
                />
            </Table.Cell>
        </Table.Row>
    );
};

export default withNamespaces()(BoardPermissionsForTeamsTableEntry);
