import * as React from 'react';
import {ReactNode} from "react";
import {useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Link} from "react-router-dom";
import {Breadcrumb} from "semantic-ui-react";
import {BoardId} from "../../InspectioBoards/model/Board";
import {makeBoardByIdSelector} from "../../InspectioBoards/selectors/board";
import * as Routes from "../../routes";

type BoardCrumbProps = RouteComponentProps<{uid: BoardId}>;

const BoardLink = (props: {children: ReactNode, boardId: BoardId, activeCrumb: boolean}) => {
    return <Link to={Routes.compileInspectioBoardWorkspace(props.boardId)} className={(props.activeCrumb? "active " : "")+"section"}>{props.children}</Link>
};

const BoarCrumb = (props: BoardCrumbProps) => {
    const getBoardById = makeBoardByIdSelector(props.match.params.uid);
    const board = useSelector(getBoardById);
    let boardName = 'Unknown';
    let readOnly = <></>;

    if(board) {
        boardName = board.name;

        if(!board.writeAccess) {
            readOnly = <Breadcrumb.Section>&nbsp;(read only)</Breadcrumb.Section>;
        }
    }



    return <>
        <Breadcrumb.Divider />
        <Breadcrumb.Section
            active={true}
            as={BoardLink}
            activeCrumb={true}
            boardId={props.match.params.uid}
        >{boardName}</Breadcrumb.Section>
        {readOnly}
    </>
};

export default withRouter(BoarCrumb);
