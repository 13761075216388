import * as React from 'react';
import {useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import {
    Card,
    Container,
    Divider,
    Grid, Header, Icon, Segment,
} from 'semantic-ui-react';
import {makeOrganizationSelector} from "../../Organization/selectors/selectOrganization";
import {UserSelector} from "../../User";
import {upsertUserSetting} from "../../User/actions/commands";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeUserSettingSelector, makeUserSettingsLoadedSelector} from "../../User/selectors/userSettings";
import {makeGetBoards, makeHasBoards, makeIsLoadingBoards} from "../selectors/boardList";
import AddBoardButton from "./AddBoardButton";
import {default as AddBoardCard} from "./AddBoardCard";
import {default as BoardCard} from "./BoardCard";
import HeaderBar from "./BoardOverview/HeaderBar";
import {LoadingBoardCard} from "./LoadingBoardCard";
import NewDomainModal from "./NewDomainModal";
import StandaloneExampleBoardCard from "./StandaloneExampleBoardCard";
import MaintenanceMessage from "./BoardOverview/MaintenanceMessage";

interface OwnProps {

}

export type EnhancedProps = OwnProps & WithNamespaces & RouteComponentProps;

const BoardOverview = (props: EnhancedProps) => {
    const boards = useSelector(makeGetBoards());
    const hasBoards = useSelector(makeHasBoards());
    const loading = useSelector(makeIsLoadingBoards());
    const boardQuota = useSelector(UserSelector.makeGetCurrentBoardQuota());
    const dispatch = useDispatch();
    const currentUser = useSelector(makeGetCurrentUser());
    const organization = useSelector(makeOrganizationSelector(currentUser.activeOrganization));
    const settingsLoaded = useSelector(makeUserSettingsLoadedSelector());
    const showHint = useSelector(makeUserSettingSelector('show_pb_domain_hint', false));
    const [popupOpen, setPopupOpen] = useState(false);

    useEffect(() => {
        if(settingsLoaded && showHint) {
            setPopupOpen(true);
        }
    }, [settingsLoaded, showHint])

    const isAdmin = organization ? organization.isAdmin(currentUser.uid) : false;

    const handleCloseNewDomainModal = () => {
        setPopupOpen(false);
        dispatch(upsertUserSetting('show_pb_domain_hint', false));
    }

    return <Container>
        <MaintenanceMessage />
        {(hasBoards || loading )&& <HeaderBar />}
    <Grid stackable={true} padded={true}>
    {(hasBoards || loading )&& <Grid.Row>
        <Grid.Column>
                <Card.Group itemsPerRow={3}>
                    <AddBoardCard disabled={boardQuota === 0} />
                    {loading && <LoadingBoardCard/>}
                    {boards.filter(board => !board.example)
                        .map(board =>
                        <BoardCard key={board.uid} board={board}
                                   isAdmin={board.organization? isAdmin  || board.isCreator(currentUser.uid) : board.owner === currentUser.uid}
                                    />
                    ).toArray()}
                </Card.Group>
        </Grid.Column>
    </Grid.Row>}
    {!hasBoards && !loading && <Grid.Row>
        <Grid.Column>
            <Segment placeholder={true}>
                <Header icon={true} style={{color: 'grey'}}>
                    <Icon name='lightbulb' color="yellow" />
                    {props.t('insp.overview.empty_space')}
                </Header>
                <Segment.Inline>
                    <a href="https://wiki.prooph-board.com" target="_blank" rel="noopener noreferrer" className="ui yellow button">Check Wiki</a>
                    <AddBoardButton disabled={boardQuota === 0} primary={false} />
                </Segment.Inline>
            </Segment>
        </Grid.Column>
    </Grid.Row>}
    <Grid.Row>
        <Grid.Column>
            <Divider horizontal={true} content="Example Boards" />
        </Grid.Column>
    </Grid.Row>
    <Grid.Row>
        <Grid.Column>
            <Card.Group itemsPerRow={3}>
                <StandaloneExampleBoardCard key='proophboard' board={{name: 'Design & Prototyping', link: '/inspectio/boards/import/https%3A%2F%2Fraw.githubusercontent.com%2Fevent-engine%2Finspectio%2Fmaster%2Fassets%2FExample%2520Boards%2Fprooph%20board%2520Example.xml'}} />
                <StandaloneExampleBoardCard key='Event_Centric_Workflow' board={{name: 'Holistic, Event-Centric Workflow', link: '/inspectio/boards/import/https%3A%2F%2Fraw.githubusercontent.com%2Fproophboard%2Fexample-boards%2Fmain%2FHolistic%252C%2520Event-Centric%2520Workflow.xml?temp=1'}} />
                <StandaloneExampleBoardCard key='Cody_Tutorial' board={{name: 'Cody Tutorial', link: '/inspectio/boards/import/https%3A%2F%2Fraw.githubusercontent.com%2Fproophboard%2Fexample-boards%2Fmain%2FCody%2520Tutorial.xml?temp=1'}} />
                <StandaloneExampleBoardCard key='Event_Storming' board={{name: 'Event Storming - The Picture', link: '/inspectio/boards/import/https%3A%2F%2Fraw.githubusercontent.com%2Fevent-engine%2Finspectio%2Fmaster%2Fassets%2FExample%2520Boards%2FThe%2520Picture.xml?temp=1'}} />
                <StandaloneExampleBoardCard key='Event_Modeling' board={{name: 'Event Modeling', link: '/inspectio/boards/import/https%3A%2F%2Fraw.githubusercontent.com%2Fevent-engine%2Finspectio%2Fmaster%2Fassets%2FExample%2520Boards%2FEvent%2520Modeling.xml?temp=1'}} />
            </Card.Group>
        </Grid.Column>
    </Grid.Row>
    </Grid>
        <NewDomainModal open={popupOpen} onClose={handleCloseNewDomainModal} />
    </Container>
};

export default withNamespaces()(withRouter(BoardOverview));
