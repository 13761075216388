import * as React from 'react';
import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Label} from "semantic-ui-react";
import {loadCodyConsoleHistory, saveCodyConsoleHistory} from "../../../core/localStorage";
import {makeGetCurrentUser} from "../../../User/selectors/currentUser";
import {toggleElementDetails} from "../../actions/commands";
import {useGraph} from "../../hooks/useGraph";
import {BoardId} from "../../model/Board";
import {CodyResponse, CodyResponseType} from "../../service/Cody";

interface OwnProps {
    boardId: BoardId;
    messageCount: number;
    lastCodyMsg?: CodyResponse;
    visible: boolean;
    onEnter: (val: string) => void;
    onEscape: () => void;
    onClose: () => void;
    showConnectDefault?: boolean;
}

type UserInputProps = OwnProps;

let history: string[] = loadCodyConsoleHistory();
let currentHistoryEntry: number|undefined;

const UserInput = (props: UserInputProps) => {
    const user = useSelector(makeGetCurrentUser());
    const inputEl = useRef<HTMLInputElement>(null);
    const [graph] = useGraph();
    const dispatch = useDispatch();

    useEffect(() => {
        window.setTimeout(() => {
            if(inputEl.current && ((props.lastCodyMsg && props.lastCodyMsg.type === CodyResponseType.Question) || props.showConnectDefault)) {
                inputEl.current.focus();
            }
        }, 100);
    }, [props.messageCount, props.lastCodyMsg, props.visible, props.showConnectDefault]);

    useEffect(() => {
        return () => {
            saveCodyConsoleHistory(history.slice(0,10));
        }
    });

    const defaultValue = props.showConnectDefault? '/connect http://localhost:3311' : '';

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if(e.key === 'Enter') {
            props.onEnter(inputEl.current!.value || '');

            if(inputEl.current!.value !== '') {
                if(currentHistoryEntry !== undefined && currentHistoryEntry !== -1) {
                    if(history[currentHistoryEntry] === inputEl.current!.value) {
                        history.splice(currentHistoryEntry, 1);
                    }

                }
                if(!history.length || history[0] !== inputEl.current!.value) {
                    history.unshift(inputEl.current!.value);
                }
            }
            inputEl.current!.value = '';
            currentHistoryEntry = undefined;

        } else if (e.key === 'Escape') {
            props.onEscape();
        } else if (e.ctrlKey && e.key === 'q') {
            props.onClose();
        } else if (e.key === 'ArrowUp') {
            if(currentHistoryEntry === undefined) {
                currentHistoryEntry = 0;
            } else {
                currentHistoryEntry++;
            }

            if(currentHistoryEntry + 1 > history.length) {
                currentHistoryEntry = history.length - 1;
            }

            if(currentHistoryEntry !== -1) {
                inputEl.current!.value = history[currentHistoryEntry];
            }
            e.preventDefault();
        } else if (e.key === 'ArrowDown') {
            if(currentHistoryEntry === undefined) {
                return;
            }

            currentHistoryEntry--;

            if(currentHistoryEntry > -1) {
                inputEl.current!.value = history[currentHistoryEntry];
                inputEl.current!.focus();
            }

            if(currentHistoryEntry === -1) {
                if(history.length && history[0] === inputEl.current!.value) {
                    inputEl.current!.value = '';
                    inputEl.current!.focus();
                }
                currentHistoryEntry = undefined;

            }
            e.preventDefault();
        } else if (e.ctrlKey && e.key === 'g') {
            graph.triggerCody();
            e.preventDefault();
        } else if (e.ctrlKey && e.key === 'm') {
            dispatch(toggleElementDetails(props.boardId, true, true));
            e.preventDefault();
        } else {
            currentHistoryEntry = undefined;
        }
    }

    return <div className="console-message">
        <Label circular={true} className="user" style={{backgroundImage: `url(${user.avatarUrl})`, backgroundSize: 'cover', marginLeft: '1px'}} />
        <span style={{marginLeft: '3px'}}>&nbsp;&nbsp;</span>
        <input type="text" defaultValue={defaultValue} ref={inputEl} onKeyDown={handleKeyDown} />
    </div>
};

export default UserInput;
