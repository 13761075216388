import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Dropdown, DropdownItem, DropdownMenu, Icon, Popup} from "semantic-ui-react";
import {OrganizationId} from "../../Organization/model/Organization";
import {makeUserOrganizationInfoListSelector} from "../../Organization/selectors/selectUserOrganizationInfo";
import * as Action from "../actions";
import {makeGetCurrentUser} from "../selectors/currentUser";
import {useEffect, useState} from "react";
import {useOnBodyClick} from "../../core/hooks/useOnBodyClick";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {keyUpdateViewed, makeUserSettingSelector, makeUserSettingsLoadedSelector} from "../selectors/userSettings";
import {upsertUserSetting} from "../actions/commands";

interface OwnProps {

}

type SideMenuOrganizationSwitchProps = OwnProps & WithNamespaces;

const SideMenuOrganizationSwitch = (props: SideMenuOrganizationSwitchProps) => {
    const currentUser = useSelector(makeGetCurrentUser());
    const organizations = useSelector(makeUserOrganizationInfoListSelector(currentUser.uid));
    const [open, setOpen] = useState(false);
    const [arrowClick, setArrowClick] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const settingsLoaded = useSelector(makeUserSettingsLoadedSelector());
    const showHint = useSelector(makeUserSettingSelector('show_orga_hint', false));

    useOnBodyClick(() => {
        setArrowClick(false);
    })

    useEffect(() => {
        if(showHint) {
            setPopupOpen(true);
        }
    }, [showHint, settingsLoaded])

    const dispatch = useDispatch();

    const handleOrganizationClick = (organizationId: OrganizationId | null) => {
        dispatch(Action.Command.switchActiveOrganization(organizationId));
        setOpen(false);
    }

    let activeOrgaName = 'My Workspace';

    let hasImplicitOrganization = false;
    let items = organizations.map(orga => {
        if(orga.ownOrganization && !orga.explicitOrganization) {
            hasImplicitOrganization = true;
        }

        const orgaName = orga.explicitOrganization? orga.organizationName : orga.ownOrganization? 'My Workspace' : orga.organizationName;

        const isActive = currentUser.activeOrganization === orga.organizationId;

        if(isActive) {
            activeOrgaName = orgaName;
        }

        return <DropdownItem
            active={isActive}
            onClick={() => !isActive && handleOrganizationClick(orga.organizationId)}
            key={orga.organizationId}>{orgaName}</DropdownItem>;
    })

    if(!hasImplicitOrganization) {
        items = items.push(<DropdownItem
            active={currentUser.activeOrganization === null}
            onClick={() => currentUser.activeOrganization !== null && handleOrganizationClick(null)}
            key="__implicit__own_orga__"
            >My Workspace</DropdownItem>)
    }

    const angleIcon = open ? 'angle up' : 'angle down';

    const handleClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setOpen(!open);
        setArrowClick(true);
    }

    const handleHintClick = () => {
        setPopupOpen(false);
        dispatch(upsertUserSetting('show_orga_hint', false));
    }

    return <Popup
        open={popupOpen}
        content={<>
            <p><strong>{props.t('insp.organisation.switch_hint_header')}</strong></p>
            <p>{props.t('insp.organisation.switch_hint')}</p>
            <p><span style={{"color": "#32c0e7", "cursor": "pointer", float: "right"}} onClick={handleHintClick}>{props.t('insp.organisation.switch_hint_ok')}</span></p>
        </>}
        position="right center"
        trigger={ <div className="organization switch"><Icon name={angleIcon} style={{cursor: "pointer"}} onClick={handleClick} /><Dropdown
            icon={false}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => {if(!arrowClick) {
                setOpen(false)
            }}}
            text={activeOrgaName}>
            <DropdownMenu>
                {items}
            </DropdownMenu>
        </Dropdown></div>
        } />
};

export default withNamespaces()(SideMenuOrganizationSwitch);
