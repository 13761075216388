import {cloneDeep} from "lodash";
import {Node, NodeType} from "../../../model/Graph";
import {fqcn} from "../node/fqcn";
import {names} from "../node/names";
import {RulesDependenciesMetadata} from "../rule-engine/types";

export const suggestDependenciesForNode = (node: Node, defaultServiceName: string, existingDependencies?: RulesDependenciesMetadata, includeEvents = true): RulesDependenciesMetadata => {
  const deps = node.getSources().filter(s => s.getType() === NodeType.document || s.getType() === NodeType.externalSystem || (includeEvents && s.getType() === NodeType.event));

  const existingDeps = existingDependencies ? cloneDeep(existingDependencies) : {};

  deps.forEach(dep => {
    switch (dep.getType()) {
      case NodeType.document:
        const queryName = fqcn(dep, defaultServiceName, true);

        if(!existingDeps[queryName]) {
          existingDeps[queryName] = {
            type: "query",
            alias: names(dep.getName()).propertyName
          }
        }
        break;
      case NodeType.externalSystem:
        const serviceName = names(dep.getName()).className;

        if(!existingDeps[serviceName]) {
          existingDeps[serviceName] = {
            type: "service",
            alias: names(dep.getName()).propertyName
          }
        }
        break;
      case NodeType.event:
        const eventsDep = existingDeps.events || {type: "events"};
        const options = eventsDep.options || {};
        const match = options.match || {};
        let eventNameMatch: string = match.$eventName || '[]';

        if(eventNameMatch.search(']') === -1) {
          eventNameMatch = `[${eventNameMatch}]`;
        }

        const eventFQCN = fqcn(dep, defaultServiceName);

        if(eventNameMatch.search(eventFQCN) === -1) {
          eventNameMatch = eventNameMatch.replace("]", `, '${eventFQCN}']`)
            .replace("[, ", "["); // If event was added to empty array [], the trailing comma can be removed
        }

        match.$eventName = eventNameMatch;
        options.match = match;
        eventsDep.options = options;
        existingDeps.events = eventsDep;
        break;
    }
  })

  return existingDeps;
}
