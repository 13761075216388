import {Map} from 'immutable';
import {Reducer} from 'redux';
import {upsertAggregates} from "../../core/reducer/applyAggregateChanged";
import {
    BOARD_GRAPH_ELEMENTS_FETCHED,
} from '../actions/constants';
import {BoardGraphElement} from "../model";
import {InspectioBoardsAction} from './index';

export interface BoardGraphElementsState extends Map<string, Map<string, BoardGraphElement.BoardGraphElement>> {
}

export const initialState: BoardGraphElementsState = Map<string, Map<string, BoardGraphElement.BoardGraphElement>>();

const reducer: Reducer<BoardGraphElementsState, InspectioBoardsAction> = (state: BoardGraphElementsState = initialState, action: InspectioBoardsAction): BoardGraphElementsState => {
    switch (action.type) {
        case BOARD_GRAPH_ELEMENTS_FETCHED:
            const boardElementsState = state.get(action.payload.boardId) || Map<string, BoardGraphElement.BoardGraphElement>();
            return state.set(action.payload.boardId, upsertAggregates(boardElementsState, action.payload.elements));
        default:
            return state;
    }
};

export { reducer as saveBoardGraphElementsReducer };
