import React, {useEffect, useRef, useState} from "react";
import WebSocketConnection from "../../../api/ConfiguredViduSocket";
import {OpenViduScreenShare as ScreenShare} from "../../model/OpenViduUser";
import './OpenViduVideo.css';

export interface Position {
    left: number;
    top: number;
}

export interface MousePoint {
    x: number;
    y: number;
}

export type NewPosition = Position;

export interface PositionChange {
    startPositionAvatar: Position,
    startPositionMouse: MousePoint,
    currentPositionMouse: MousePoint,
}

interface OpenViduScreenShareProps {
    screenShare: ScreenShare;
    sessionId: string;
    onPositionChange?: (change: PositionChange) => NewPosition|undefined;
}

const defaultPositionChange: PositionChange = {
    startPositionAvatar: {left: 0, top: 0},
    startPositionMouse: {x: 0, y: 0},
    currentPositionMouse: {x: 0, y: 0}
}

const OpenViduScreenShare = (props: OpenViduScreenShareProps) => {
    const videoRef = useRef(null);
    const [isDragging, setIsDragging] = useState<boolean>();
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const positionChange = useRef<PositionChange>(defaultPositionChange);

    const changePosition = (x: number, y: number) => {
        positionChange.current.currentPositionMouse.x = x;
        positionChange.current.currentPositionMouse.y = y;

        if (props.onPositionChange) {
            // Returns undefined for remote screen shares where we do not want to send out position changes ...
            const newPosition = props.onPositionChange(positionChange.current);

            if (newPosition) {
                WebSocketConnection.sendPositionUpdate('screen_' + props.screenShare.userId, props.sessionId, newPosition.left, newPosition.top);
            }
        }
    }

    useEffect(() => {
        props.screenShare.streamManager.addVideoElement(videoRef.current);
    }, []);

    useEffect(() => {
        const mouseUpListener = () => {
            setIsDragging(false);
        }

        const mouseMoveListener = (e: MouseEvent) => {
            if (isDragging) {
                changePosition(e.clientX, e.clientY);
            }
        }

        const touchMoveListener = (e: TouchEvent) => {
            if(isDragging) {
                changePosition(e.touches[0].clientX, e.touches[0].clientY);
            }
        }

        document.addEventListener('mouseup', mouseUpListener);
        document.addEventListener('touchend', mouseUpListener);
        document.addEventListener('mousemove', mouseMoveListener);
        document.addEventListener('touchmove', touchMoveListener);

        return () => {
            document.removeEventListener('mouseup', mouseUpListener);
            document.removeEventListener('touchend', mouseUpListener);
            document.removeEventListener('mousemove', mouseMoveListener);
            document.removeEventListener('touchmove', touchMoveListener);
        }
    })

    const toggleIsFocused = () => {
        setIsFocused(!isFocused);
    }

    return (
        <div
            style={{
                height: isFocused? 720 : 360,
                padding: 5,
                boxSizing: 'border-box',
                position: 'absolute',
                left: props.screenShare.position.left - 325,
                top: props.screenShare.position.top - 185,
                pointerEvents: 'auto',
                cursor: 'move',
                zIndex: 2,
                backgroundColor: 'black',
                borderRadius: 6,
            }}
            onMouseDown={(e) => {
                    positionChange.current.startPositionAvatar = props.screenShare.position;
                    positionChange.current.startPositionMouse = {
                        x: e.clientX,
                        y: e.clientY,
                    }
                    setIsDragging(true);
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
            onTouchStart={(e) => {
                positionChange.current.startPositionAvatar = props.screenShare.position;
                positionChange.current.startPositionMouse = {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY,
                }
                setIsDragging(true);
                e.stopPropagation();
                e.preventDefault();
            }}
            onClick={(e) => {
                e.preventDefault();
            }}
            onDoubleClick={toggleIsFocused}
        >
            {/*
             // @ts-ignore */}
            <video
                autoPlay={true}
                disablePictureInPicture={true /* Not working in FF .... */}
                ref={videoRef}
                muted={true}
                style={{ height: '100%', pointerEvents: 'auto', display: 'block', borderRadius: '3px' }}
            />
        </div>
    );
};

export default OpenViduScreenShare;
