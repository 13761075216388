import {List} from "immutable";
import { call, fork, put, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {ResponseType} from "../../api/util";
import {execForEach, execValidate, isFailure, isSuccess, pipe} from "../../core/validation/either";
import {isArray} from "../../core/validation/predicates";
import { Action as NotifyAction } from '../../NotificationSystem';
import * as Action from '../actions';
import {createBoardGraphElementFromServerData} from "../model/BoardGraphElement";

type FlowAction = ActionType<typeof Action.Query.fetchBoardGraphElements>;

const onFetchBoardGraphElements = function* (action: FlowAction) {
    const {response, error}: ResponseType = yield call(
        Action.Api.fetchBoardGraphElements,
        action.payload.boardId,
        action.payload.type
    );

    if (error) {
        yield put(NotifyAction.Command.error("Request Error", "Failed to load board graph elements"));
    }

    if (response) {
        const result = pipe(
            response!.data,
            execValidate([isArray(), "Response data is no array"]),
            execForEach((data: any) => {
                return createBoardGraphElementFromServerData(data);
            }),
        );

        if (isFailure(result)) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not load board graph elements'));
        }

        if (isSuccess(result)) {
            const boardGraphElements = List(result.value);
            yield put(Action.Event.boardGraphElementsFetched(action.payload.boardId, boardGraphElements));
        }
    }
};

export function* fetchBoardGraphElementsFlow() {

    while (true) {
        const action: FlowAction = yield take(Action.Type.FETCH_BOARD_GRAPH_ELEMENTS);

        yield fork(onFetchBoardGraphElements, action);
    }
}