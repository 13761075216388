import { Map, Record } from 'immutable';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducer';
import {UserSettingsValue, UserSettingsValues} from "../model/UserSettings";
import {BoardId} from "../../InspectioBoards/model/Board";

export const keyBoardHidden = (boardId: BoardId) => `board_${boardId}_hidden`;
export const keyUpdateViewed = (date: string) => `update_${date}_viewed`;
export const keyLiteMode = () => 'lite_mode';

export const userSettingsSelector = (state: Record<ApplicationState>, props: any) => state.getIn(['inspectioUsers', 'userSettings']);

export const makeUserSettingSelector = (key: string, defaultVal?: UserSettingsValue): (state: Record<ApplicationState>, props?: any) => UserSettingsValue => {
    return createSelector(
        [userSettingsSelector],
        (settings: Map<string, UserSettingsValue>): any => settings.has(key) ? settings.get(key) : defaultVal
    );
};

export const makeUserSettingsSelector = (): (state: Record<ApplicationState>, props?: any) => UserSettingsValues => {
    return createSelector(
        [userSettingsSelector],
        (settings: Map<string, UserSettingsValue>): UserSettingsValues => settings.toObject()
    );
};

export const makeUserSettingsLoadedSelector = (): (state: Record<ApplicationState>, props?: any) => boolean => {
    return createSelector(
        [userSettingsSelector],
        (settings: Map<string, UserSettingsValue>): boolean => !!settings.get('settings_loaded')
    );
};

