import {Record} from 'immutable';
import {BoardId, BoardXml} from "./Board";
import {BoardVersion, defaultBoardVersionProps} from "./BoardVersion";

export interface BoardSnapshotProps {
    boardId: BoardId,
    xml: BoardXml,
    boardVersion: BoardVersion
}

export const defaultBoardSnapshotProps: BoardSnapshotProps = {
    boardId: '',
    xml: '',
    boardVersion: new BoardVersion(defaultBoardVersionProps),
};

export class BoardSnapshot extends Record(defaultBoardSnapshotProps) implements BoardSnapshotProps {
    public constructor(data: BoardSnapshotProps) {
        super(data);
    }
}
