import BoardsIndexedDB from "./BoardsIndexedDB";
import BoardsStorageManager from "./BoardsStorageManager";

let manager: BoardsStorageManager;

export const useBoardsStorageManager = (): BoardsStorageManager => {
    if(!manager) {
        manager = new BoardsStorageManager(new BoardsIndexedDB());
    }

    return manager;
}
