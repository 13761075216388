import * as React from 'react';
import {Checkbox, FormField} from "semantic-ui-react";
import {Node} from "../../../../model/Graph";
import {WizardContext} from "../../../../service/cody-wizard/context/wizard-context";

interface OwnProps {
  command: Node | null;
  aggregateCommand: boolean;
  ctx: WizardContext;
  onAggregateCommandChanged: (aggregateCommand: boolean) => void;
  disabled?: boolean;
}

type AggregateCommandToggleProps = OwnProps;

const AggregateCommandToggle = (props: AggregateCommandToggleProps) => {
  const disabled = !props.command || props.disabled;

  return <FormField disabled={disabled} inline={true}>
    <Checkbox value={1}
              checked={props.aggregateCommand}
              disabled={disabled}
              toggle={true}
              onChange={() => props.onAggregateCommandChanged(!props.aggregateCommand)}
              label={'Aggregate Command'} />
  </FormField>
};

export default AggregateCommandToggle;
