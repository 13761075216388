import {JSONSchema7} from "json-schema";
import {makeAssignVariable, makeDefinitions, makeExecuteRules, makeForEach, makeRuleThenPropMapping, makeThenThrowError} from "./rule-engine-definitions";

export const makeResolveSchema = (possibleProperties: string[], expressionSuggests: string[]): string => {
  const resolve: JSONSchema7 = {
    type: "object",
    additionalProperties: false,
    properties: {
      rules: {
        type: "array",
        items: {
          $ref: "#/definitions/rule_definition"
        }
      },
      where: {
        $ref: "#/definitions/resolve_where_rule_definition"
      },
      orderBy: {
        oneOf: [
          {$ref: "#/definitions/resolve_order_by"},
          {type: "array", items: {$ref: "#/definitions/resolve_order_by"}}
        ]
      }
    },
    definitions: {
      resolve_where_rule_definition: {
        oneOf: [
          {
            type: "object",
            properties: {
              rule: {
                type: "string",
                enum: ["always"]
              },
              then: {
                $ref: "#/definitions/resolve_filter"
              }
            },
            required: ["rule", "then"],
            additionalProperties: false
          },
          {
            type: "object",
            properties: {
              rule: {
                type: "string",
                enum: ["condition"]
              },
              then: {
                $ref: "#/definitions/resolve_filter"
              },
              if: {$ref: "#/definitions/resolve_filter_expr"},
              else: {
                $ref: "#/definitions/resolve_filter_else"
              }
            },
            required: ["rule", "then", "if"],
            additionalProperties: false
          },
          {
            type: "object",
            properties: {
              rule: {
                type: "string",
                enum: ["condition"]
              },
              then: {
                $ref: "#/definitions/resolve_filter"
              },
              if_not: {$ref: "#/definitions/resolve_filter_expr"},
              else: {
                $ref: "#/definitions/resolve_filter_else"
              }
            },
            required: ["rule", "then", "if_not"],
            additionalProperties: false
          }
        ]
      },
      ...makeRuleThenPropMapping(),
      resolve_order_by: {
        type: "object",
        additionalProperties: false,
        required: ["prop", "sort"],
        properties: {
          prop: {type: "string", enum: possibleProperties},
          sort: {type: "string", enum: ["asc", "desc"]}
        }
      },
      resolve_filter: {
        type: "object",
        additionalProperties: false,
        required: ["filter"],
        properties: {
          filter: {$ref: "#/definitions/resolve_filter_types"}
        }
      },
      resolve_filter_else: {
        oneOf: [
          {$ref: "#/definitions/resolve_filter"},
          {
            type: "object",
            additionalProperties: false,
            required: ["execute"],
            properties: {
              execute: {
                type: "object",
                additionalProperties: false,
                required: ["rules"],
                properties: {
                  rules: {type: "array", items: {$ref: "#/definitions/rule_definition"}, minItems: 1, maxItems: 1}
                }
              }
            }
          }
        ]
      },
      resolve_filter_types: {
        oneOf: [
          {$ref: "#/definitions/resolve_filter_and"},
          {$ref: "#/definitions/resolve_filter_or"},
          {$ref: "#/definitions/resolve_filter_not"},
          {$ref: "#/definitions/resolve_filter_any"},
          {$ref: "#/definitions/resolve_filter_any_of_doc_id"},
          {$ref: "#/definitions/resolve_filter_any_of"},
          {$ref: "#/definitions/resolve_filter_doc_id"},
          {$ref: "#/definitions/resolve_filter_eq"},
          {$ref: "#/definitions/resolve_filter_exists"},
          {$ref: "#/definitions/resolve_filter_gte"},
          {$ref: "#/definitions/resolve_filter_gt"},
          {$ref: "#/definitions/resolve_filter_in_array"},
          {$ref: "#/definitions/resolve_filter_like"},
          {$ref: "#/definitions/resolve_filter_lte"},
          {$ref: "#/definitions/resolve_filter_lt"},
        ]
      },
      resolve_filter_expr: {
        anyOf: [
          {type: "string"},
          {type: "string", enum: expressionSuggests}
        ]
      },
      resolve_filter_and: {
        type: "object",
        additionalProperties: false,
        required: ["and"],
        properties: {
          and: {type: "array", items: {$ref: "#/definitions/resolve_filter_types"}, minItems: 2}
        }
      },
      resolve_filter_or: {
        type: "object",
        additionalProperties: false,
        required: ["or"],
        properties: {
          or: {type: "array", items: {$ref: "#/definitions/resolve_filter_types"}, minItems: 2}
        }
      },
      resolve_filter_not: {
        type: "object",
        additionalProperties: false,
        required: ["not"],
        properties: {
          not: {$ref: "#/definitions/resolve_filter_types"}
        }
      },
      resolve_filter_any: {
        type: "object",
        additionalProperties: false,
        required: ["any"],
        properties: {
          any: {type: "boolean", default: true}
        }
      },
      resolve_filter_any_of_doc_id: {
        type: "object",
        additionalProperties: false,
        required: ["anyOfDocId"],
        properties: {
          anyOfDocId: {$ref: "#/definitions/resolve_filter_expr"}
        }
      },
      resolve_filter_any_of: {
        type: "object",
        additionalProperties: false,
        required: ["anyOf"],
        properties: {
          anyOf: {
            type: "object",
            additionalProperties: false,
            required: ["prop", "valueList"],
            properties: {
              prop: {type: "string", enum: possibleProperties},
              valueList: {$ref: "#/definitions/resolve_filter_expr"}
            }
          }
        }
      },
      resolve_filter_doc_id: {
        type: "object",
        additionalProperties: false,
        required: ["docId"],
        properties: {
          docId: {$ref: "#/definitions/resolve_filter_expr"}
        }
      },
      resolve_filter_eq: {
        type: "object",
        additionalProperties: false,
        required: ["eq"],
        properties: {
          eq: {
            type: "object",
            additionalProperties: false,
            required: ["prop", "value"],
            properties: {
              prop: {type: "string", enum: possibleProperties},
              value: {$ref: "#/definitions/resolve_filter_expr"}
            }
          }
        }
      },
      resolve_filter_exists: {
        type: "object",
        additionalProperties: false,
        required: ["exists"],
        properties: {
          exists: {
            type: "object",
            additionalProperties: false,
            required: ["prop"],
            properties: {
              prop: {type: "string", enum: possibleProperties},
            }
          }
        }
      },
      resolve_filter_gte: {
        type: "object",
        additionalProperties: false,
        required: ["gte"],
        properties: {
          gte: {
            type: "object",
            additionalProperties: false,
            required: ["prop", "value"],
            properties: {
              prop: {type: "string", enum: possibleProperties},
              value: {$ref: "#/definitions/resolve_filter_expr"}
            }
          }
        }
      },
      resolve_filter_gt: {
        type: "object",
        additionalProperties: false,
        required: ["gt"],
        properties: {
          gt: {
            type: "object",
            additionalProperties: false,
            required: ["prop", "value"],
            properties: {
              prop: {type: "string", enum: possibleProperties},
              value: {$ref: "#/definitions/resolve_filter_expr"}
            }
          }
        }
      },
      resolve_filter_in_array: {
        type: "object",
        additionalProperties: false,
        required: ["inArray"],
        properties: {
          inArray: {
            type: "object",
            additionalProperties: false,
            required: ["prop", "value"],
            properties: {
              prop: {type: "string", enum: possibleProperties},
              value: {$ref: "#/definitions/resolve_filter_expr"}
            }
          }
        }
      },
      resolve_filter_like: {
        type: "object",
        additionalProperties: false,
        required: ["like"],
        properties: {
          like: {
            type: "object",
            additionalProperties: false,
            required: ["prop", "value"],
            properties: {
              prop: {type: "string", enum: possibleProperties},
              value: {$ref: "#/definitions/resolve_filter_expr"}
            }
          }
        }
      },
      resolve_filter_lte: {
        type: "object",
        additionalProperties: false,
        required: ["lte"],
        properties: {
          lte: {
            type: "object",
            additionalProperties: false,
            required: ["prop", "value"],
            properties: {
              prop: {type: "string", enum: possibleProperties},
              value: {$ref: "#/definitions/resolve_filter_expr"}
            }
          }
        }
      },
      resolve_filter_lt: {
        type: "object",
        additionalProperties: false,
        required: ["lt"],
        properties: {
          lt: {
            type: "object",
            additionalProperties: false,
            required: ["prop", "value"],
            properties: {
              prop: {type: "string", enum: possibleProperties},
              value: {$ref: "#/definitions/resolve_filter_expr"}
            }
          }
        }
      }
    }
  };

  resolve.definitions = {...resolve.definitions, ...makeDefinitions(
    makeThenThrowError(),
    makeAssignVariable(),
    makeForEach(),
    makeExecuteRules(),
  )}

  return JSON.stringify(resolve);
}
