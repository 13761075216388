import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Dropdown, FormField} from "semantic-ui-react";
import {FeatureStatus, POSSIBLE_STATUS} from "../Feature";

interface OwnProps {
  status: FeatureStatus | undefined;
  disabled?: boolean;
  onStatusChanged: (newStatus: FeatureStatus) => void;
}

type StatusSelectProps = OwnProps & WithNamespaces;

const StatusSelect = (props: StatusSelectProps) => {
  const options = POSSIBLE_STATUS.map(s => ({key: s, text: s, value: s}));

  return <FormField>
    <Dropdown inline={true}
              placeholder={props.t('insp.cody_wizard.feature_status_select.placeholder') as string}
              value={props.status}
              options={options}
              disabled={props.disabled}
              onChange={(e, data) => props.onStatusChanged(data.value as FeatureStatus)}
              className={'cody-wizard-inline-select'}
    />
  </FormField>
};

export default withNamespaces()(StatusSelect);
