import * as React from 'react';
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "semantic-ui-react";
import {sendUserFeedback} from "../../Feedback/actions/api";
import FeedbackModal from "../../Feedback/components/FeedbackModal";
import {Action as Notify} from "../../NotificationSystem";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";

interface OwnProps {

}

type FeedbackProps = OwnProps;

const ERROR_MSG = "Failed to send feedback. Please try again or send an email to contact@prooph.de";

const Feedback = (props: FeedbackProps) => {
    const dispatch = useDispatch();
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [resetFeedbackModal, setResetFeedbackModal] = useState(false);
    const user = useSelector(makeGetCurrentUser());

    const handleFeedbackBtnClick = () => {
        setResetFeedbackModal(false);
        setShowFeedbackModal(true);
    };
    const handleModalClose = () => setShowFeedbackModal(false);
    const handleFeedbackSubmit = (feedback: string) => {
        sendUserFeedback(feedback, user.email)
            .then(({response, error}) => {
                if(error) {
                    dispatch(Notify.Command.error("Request Error", ERROR_MSG));
                }

                if(response) {
                    setResetFeedbackModal(true);
                    setShowFeedbackModal(false);
                    dispatch(Notify.Command.info("Feedback sent", "We received your feedback. Thank you!"))
                }
            })
            .catch(() => {
                dispatch(Notify.Command.error("Request Error", ERROR_MSG));
            });
    };

    return <>
        <div className="ui item">
            <Button className="big" onClick={handleFeedbackBtnClick}>
                <div className="header">Feedback</div>
                <div className="sub header" style={{textTransform:"none", fontWeight:"normal"}}>Send Bugs & Feature Requests</div>
            </Button>
        </div>
        <FeedbackModal open={showFeedbackModal} onClose={handleModalClose} onSubmit={handleFeedbackSubmit} reset={resetFeedbackModal} />
    </>;
};

export default Feedback;