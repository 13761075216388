import React, {useEffect, useImperativeHandle, useRef} from "react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {StickyTemplate} from "../../../model/StickyTemplate";
import jsonSchemaMetaSchema from "./jsonSchemaMetaSchema.json";

export interface BoardStickySchemaEditorRef {
    retrievePayload: () => string;
}

interface OwnProps {
    boardStickyTemplate: StickyTemplate;
}

type BoardStickySchemaEditorProps = OwnProps;

const BoardStickySchemaEditor = (props: BoardStickySchemaEditorProps, ref: React.Ref<BoardStickySchemaEditorRef>) => {
    const codeEditorRef = useRef<CodeEditor|null>(null);

    useEffect(() => {
        if (!codeEditorRef.current) {
            return;
        }

        codeEditorRef.current.initializeModel({
            fileId: `board_${props.boardStickyTemplate.boardId}_sticky_${props.boardStickyTemplate.cellType}_schema.json`,
            language: 'json',
            value: props.boardStickyTemplate.templateSchema || '{\n\t"type": "object",\n\t"properties": {}\n}',
            schema: JSON.stringify(jsonSchemaMetaSchema)
        });
    }, [props.boardStickyTemplate.boardId, props.boardStickyTemplate.cellType, props.boardStickyTemplate.templateSchema]);

    useImperativeHandle<BoardStickySchemaEditorRef, BoardStickySchemaEditorRef>(ref, () => ({
        retrievePayload: (): string => codeEditorRef.current!.retrievePayload(),
    }));

    return (
        <CodeEditor
            ref={codeEditorRef}
            style={{ height: '280px', width: '100%', marginTop: '10px' }}
            options={{
                fontSize: 12,
                folding: false,
                glyphMargin: false,
                lineNumbers: 'off',
                lineDecorationsWidth: 3,
                minimap: {
                    enabled: false
                },
                formatOnPaste: true,
                scrollBeyondLastLine: false,
            }}
        />
    );
}

export default React.forwardRef(BoardStickySchemaEditor);
