import {List, Record} from 'immutable';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../reducer';
import {IioUserInfo} from "../model/IioUserInfo";
import {UserId} from "../model/UserInfo";

export const getIioUserInfoById = (state: Record<ApplicationState>, userId: UserId): IioUserInfo|undefined => state.getIn(['inspectioUsers', 'iioUsersInfo', userId]);

export const makeGetIioUserInfoByIdSelector = (userId: UserId): (state: Record<ApplicationState>, props?: any) => IioUserInfo|undefined => (state: Record<ApplicationState>) => getIioUserInfoById(state, userId);

export const iioUserListSelector = (state: Record<ApplicationState>, props: any): List<IioUserInfo> =>
    List(state.getIn(['inspectioUsers', 'iioUsersInfo']).toIndexedSeq().toArray()) || List();

export const makeIioUserListSelector = (): (state: Record<ApplicationState>, props?: any) => List<IioUserInfo> => {
    return createSelector(
        [iioUserListSelector],
        (users): List<IioUserInfo> =>  {
            return users;
        }
    )
};

export const makeIioFilteredUserListSelector = (userIdList: List<UserId>): (state: Record<ApplicationState>, props?: any) => List<IioUserInfo> => {
    return createSelector(
        [iioUserListSelector],
        (users): List<IioUserInfo> => {
            return users.filter((info) => {
                return userIdList.contains(info.userId);
            })
        }
    )
};
