import * as React from 'react';
import {SyntheticEvent} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Search} from "semantic-ui-react";
import {filterBoardOverview} from "../actions/commands";
import {makeGetBoardOverview} from "../selectors/boardList";
import {makeGetTags} from "../selectors/tags";
import {withNamespaces, WithNamespaces} from "react-i18next";

interface OwnProps {}

type BoardOverviewSearchProps = OwnProps & WithNamespaces;

const BoardOverviewSearch = (props: BoardOverviewSearchProps) => {
    const tags = useSelector(makeGetTags());
    const dispatch = useDispatch();

    const boardOverviewSelector = makeGetBoardOverview();
    const boardOverviewState = useSelector(boardOverviewSelector);
    const filter = boardOverviewState.filter;

    const handleChange = (val: string) => {
        if(val.charAt(0) === ' ') {
            val = val.slice(1);
        }
        dispatch(filterBoardOverview(val));
    };

    const handleResultSelect = (result: {title: string}) => {
        if(filter === '') {
            dispatch(filterBoardOverview(result.title))
        } else {
            const parts = filter.split(" ");
            parts.splice(-1, 1);
            const filterWithoutLastPart = parts.join(" ").trim();
            dispatch(filterBoardOverview(filterWithoutLastPart + ' ' + result.title))
        }
    }

    const tagsInFilter = filter.split(" ").map(part => part.trim()).filter(part => part.charAt(0) === '#');

    const handleClear = (e: SyntheticEvent) => {
        e.preventDefault();
        dispatch(filterBoardOverview(''));
    };


    let results = tags.filter(tag => !tagsInFilter.includes('#'+tag)).map(tag => ({title: '#'+tag})).toJS();

    let lastPart = filter.split(" ").pop();

    if(lastPart) {
        lastPart = lastPart.trim();
        if(lastPart !== '') {
            results = results.filter(result => result.title.toLowerCase().search(lastPart!.toLowerCase()) !== -1);
        }
    }

    return <Search
            placeholder={props.t('insp.overview.search.label')}
            results={results}
            value={filter}
            onSearchChange={(e,data) => handleChange(data.value as string)}
            onResultSelect={(e, data) => handleResultSelect(data.result)}
            icon={filter === '' ? <i className="search icon" /> : <i className="close link icon" style={{cursor: "pointer"}} onClick={handleClear} />}
            fluid={true}
            showNoResults={false}
        />
};

export default withNamespaces()(BoardOverviewSearch);
