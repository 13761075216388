import * as Action from "./actions";
import * as Model from "./model";
import * as Reducer from "./reducers";
import * as Saga from "./sagas";

const MODULE = 'inspectioOrganizations';

export {
    MODULE,
    Action,
    Model,
    Reducer,
    Saga,
};
