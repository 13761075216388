import { call, fork, put, take } from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as Action from "../actions";

type FlowAction = ActionType<typeof Action.Command.revokeAccessToBoard>;

function* onRevokeAccess(action: FlowAction) {
    const {response, error}: ResponseType = yield call(Action.Api.revokeAccessToBoard, action.payload.boardId, action.payload.userId);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Failed to revoke access to board.'));
    }

    if(response) {
        yield put(NotifyAction.Command.info('Access Revoked', 'Access to board revoked.'));

        yield put(Action.Event.boardAccessRevoked(action.payload.boardId, action.payload.userId));
    }
}

export function* revokeAccessToBoardFlow() {
    while (true) {
        const action: FlowAction = yield take([Action.Type.REVOKE_ACCESS_TO_BOARD]);

        yield fork(onRevokeAccess, action)
    }
}

