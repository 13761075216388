import {fromJS, List, Map} from "immutable";
import {BoardModel, BoardVersioning} from "../InspectioBoards";

const KEY_BOARDS = '@@Inspectio/Boards';
const KEY_BOARD = '@@Inspectio/Board/:id';
const KEY_CODY_CONSOLE_HISTORY = '@@Inspectio/CodyConsole';
const DEFAULT_BOARD_STATE_VERSION = 0;
export const CURRENT_BOARD_STATE_VERSION = 1;

const boardKey = (boardUid: string): string => {
    return KEY_BOARD.replace(":id", boardUid)
}

const loadBoardList = (): List<string> => {
    const boardsJson = localStorage.getItem(KEY_BOARDS);

    if(boardsJson === null) {
        return List();
    }

    return fromJS(JSON.parse(boardsJson));
}

export const loadInspectioBoards = (onErr: (err: any) => void): Map<string, BoardModel.Board> => {
    try {

        const boardsList: List<string> = loadBoardList();

        let boards = Map<string, BoardModel.Board>();

        boardsList.forEach(boardUid => {
           const boardJson = localStorage.getItem(boardKey(boardUid));

           if(boardJson !== null) {
               let boardData = JSON.parse(boardJson);
               let version = DEFAULT_BOARD_STATE_VERSION;

               if(boardData.hasOwnProperty('__state')) {
                   version = boardData.__version;
                   boardData = boardData.__state;
               }

               boardData = migrateBoardState(boardData, version);

               boards = boards.set(boardUid, new BoardModel.Board(boardData));
           }
        });

        return boards;
    } catch (err) {
        onErr(err);
    }

    return Map();
};

export const saveInspectioBoard = (board: BoardModel.Board, onErr: (err: any) => void): void => {
    try {
        let boardList = loadBoardList();

        if(!boardList.includes(board.uid)) {
            boardList = boardList.push(board.uid);

            localStorage.setItem(KEY_BOARDS, JSON.stringify(boardList.toJSON()));
        }

        const boardDoc = {
            __state: board.toJSON(),
            __version: CURRENT_BOARD_STATE_VERSION,
        }

        localStorage.setItem(boardKey(board.uid), JSON.stringify(boardDoc));
    } catch (err) {
        onErr(err);
    }
};

export const deleteInspectioBoard = (boardId: string, onErr: (err: any) => void): void => {
    try {
        let boardList = loadBoardList();

        if(boardList.includes(boardId)) {
            boardList = boardList.delete(boardList.indexOf(boardId));

            localStorage.setItem(KEY_BOARDS, JSON.stringify(boardList.toJSON()));
            localStorage.removeItem(boardKey(boardId));
        }
    } catch (err) {
        onErr(err);
    }
};

export const migrateBoardState = (rawBoardState: any, boardStateVersion: number): any => {
    if(boardStateVersion === CURRENT_BOARD_STATE_VERSION) {
        return rawBoardState;
    }

    if(boardStateVersion === 0) {
        rawBoardState = migrateFrom0to1(rawBoardState);
        boardStateVersion = 1;
    }

    if(boardStateVersion === CURRENT_BOARD_STATE_VERSION) {
        return rawBoardState;
    }

    throw new Error(`Missing board state migration from version ${boardStateVersion} to  ${CURRENT_BOARD_STATE_VERSION}`);
}

const migrateFrom0to1 = (rawBoardState: any): any => {
    rawBoardState.version = BoardVersioning.defaultBoardVersionProps;
    return rawBoardState;
}

// Cody Console cache
export const saveCodyConsoleHistory = (history: string[]): void => {
    localStorage.setItem(KEY_CODY_CONSOLE_HISTORY, JSON.stringify(history));
}

export const loadCodyConsoleHistory = (): string[] => {
    const historyJson = localStorage.getItem(KEY_CODY_CONSOLE_HISTORY);

    if(historyJson === null) {
        return [];
    }

    try {
        return JSON.parse(historyJson);
    } catch (e) {
        return [];
    }
}
