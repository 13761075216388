import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import * as UserModule from "../../User";
import {loadUserTeams} from "./loadUserTeams";

type Event = ActionType<typeof UserModule.Action.Event.activeOrganizationSwitched>

function* handleRefreshUserTeams(event: Event) {
    yield call(loadUserTeams);
}

export function* refreshUserTeams() {
    while (true) {
        const event: Event = yield take([
            UserModule.Action.Type.ACTIVE_ORGANIZATION_SWITCHED
        ]);

        yield fork(handleRefreshUserTeams, event);
    }
}
