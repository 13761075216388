import * as React from "react";
import {useState} from "react";
import Dropzone from "react-dropzone";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Modal} from "semantic-ui-react";

export interface CropData {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface UserAvatarUploadModalProps {
    t: any;
    open: boolean;
    onClose: () => void;
    onUpload: (file: any, crop: CropData) => void;
}

const INITIAL_CROP = { aspect: 1, unit: 'px' as 'px', x: 0, y: 0, width: 150, height: 150 };

const UserAvatarUploadModal = (props: UserAvatarUploadModalProps) => {
    const [crop, setCrop] = useState(INITIAL_CROP);
    const [imageScale, setImageScale] = useState<number>(1.0);
    const [file, setFile] = useState<undefined|File>(undefined);
    const [src, setSrc] = useState<undefined|string>(undefined);

    const onFileSelected = (acceptedFiles: File[]) => {
        setFile(acceptedFiles[0]);
        setSrc(URL.createObjectURL(acceptedFiles[0]));
    };

    const closeModal = () => {
        props.onClose();
        resetModal();
    };

    const resetModal = () => {
        setFile(undefined);
        setSrc(undefined);
        setCrop(INITIAL_CROP);
    };

    return (
        <Modal open={props.open} onClose={closeModal} closeIcon={true}>
            <Modal.Header>{props.t('insp.user.avatar_upload.select_photo')}</Modal.Header>
            <Modal.Content image={true}>
                <Dropzone onDrop={onFileSelected}>
                    {({getRootProps, getInputProps}) => (
                        <section style={{ height: '410px', width: '100%' }}>
                            <div {...getRootProps()} style={{ width: '100%', height: '100%', padding: '1px' }}>
                                {src ? (
                                    <ReactCrop
                                        style={{ maxWidth: "100%", maxHeight: "400px" }}
                                        imageStyle={{ maxWidth: "100%", maxHeight: "400px" }}
                                        src={src as any}
                                        crop={crop}
                                        onChange={(newCrop: any) => setCrop(newCrop)}
                                        onImageLoaded={image => setImageScale(image.width / image.naturalWidth)}
                                    />
                                ) : (
                                    <div>
                                        <input {...getInputProps()} />
                                        <p>{props.t('insp.user.avatar_upload.drag_and_drop_info')}</p>
                                    </div>
                                )}
                            </div>
                        </section>
                    )}
                </Dropzone>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    children={props.t('app.form.cancel')}
                    onClick={closeModal}
                />
                <Button
                    primary={true}
                    children={props.t('app.form.save')}
                    onClick={() => {
                        props.onUpload(file, {
                            x: crop.x / imageScale,
                            y: crop.y / imageScale,
                            width: crop.width / imageScale,
                            height: crop.height / imageScale
                        });
                        resetModal();
                    }}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default UserAvatarUploadModal;
