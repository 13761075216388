import {Map} from 'immutable';
import {Reducer} from 'redux';
import {updateAggregate, upsertAggregates} from "../../core/reducer/applyAggregateChanged";
import {BOARD_INVITATION_RESENT, BOARD_INVITATION_REVOKED, OPEN_BOARD_INVITATIONS_FETCHED} from '../actions/constants';
import {BoardInvitation} from "../model";
import {InspectioBoardsAction} from './index';

export interface BoardInvitationState extends Map<string, BoardInvitation.BoardInvitation> {
}

export const initialState: BoardInvitationState = Map<string, BoardInvitation.BoardInvitation>();

const reducer: Reducer<BoardInvitationState, InspectioBoardsAction> = (state: BoardInvitationState = initialState, action: InspectioBoardsAction): BoardInvitationState => {
    switch (action.type) {
        case OPEN_BOARD_INVITATIONS_FETCHED:
            return upsertAggregates(state, action.payload.boardInvitations);
        case BOARD_INVITATION_RESENT:
            return updateAggregate(state, action.payload.boardInvitation);
        case BOARD_INVITATION_REVOKED:
            return updateAggregate(state, action.payload.boardInvitation);
        default:
            return state;
    }
};

export { reducer as saveBoardInvitationReducer };
