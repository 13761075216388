import {call, fork, put, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";

type Command = ActionType<typeof Action.Command.toggleEventModelingEnabled>

function* handleToggleEventModelingEnabled(command: Command) {
  const {response, error}: ResponseType = yield call(Action.Api.toggleEventModeling, command.payload.board.uid, command.payload.board.eventModelingEnabled);

  if (error) {
    yield put(NotifyAction.Command.error('Request Error', 'Could not toggle event modeling.'));
    yield put(Action.Query.fetchUserBoards());
  }

  if(response) {
    yield put(NotifyAction.Command.info('Event Modeling ' + (command.payload.board.eventModelingEnabled ? 'enabled' : 'disabled'), ''));
  }
}

export function* toggleEventModelingEnabled() {
  while (true) {
    const command: Command = yield take([
      Action.Type.TOGGLE_EVENT_MODELING_ENABLED
    ]);

    yield fork(handleToggleEventModelingEnabled, command);
  }
}
