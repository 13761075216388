import * as React from 'react';
import {WithNamespaces, withNamespaces} from "react-i18next";
import {Button, Grid, GridColumn, GridRow, Input, Modal, Search, Table} from "semantic-ui-react";
import {Permissions} from "../model/BoardPermissionForUser";
import {useSelector} from "react-redux";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeOrganizationSelector} from "../../Organization/selectors/selectOrganization";
import {List} from "immutable";
import {Email, UserId} from "../../User/model/UserInfo";
import {makeIioFilteredUserListSelector} from "../../User/selectors/iioUserInfo";
import {useEffect, useState} from "react";

interface OwnProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    email: string;
    onEmailChange: (email: Email) => void;
    permissions: Permissions,
    onPermissionsChange: (permissions: Permissions) => void;
}

type InviteUserToBoardProps = OwnProps & WithNamespaces;

const InviteUserToBoardModal = (props: InviteUserToBoardProps) => {

    const [email, setEmail] = useState<string>(props.email);
    const currentUser = useSelector(makeGetCurrentUser());
    const organization = useSelector(makeOrganizationSelector(currentUser.activeOrganization));
    const organizationUserIds = List<UserId>(organization ? [organization.ownerId, ...organization.members.toJS()] : []);
    const organizationUsers = useSelector(makeIioFilteredUserListSelector(organizationUserIds));

    useEffect(() => {
        setEmail(props.email);
    }, [props.email])

    const filteredUsers = organizationUsers
        .filter(user => user.username.toLocaleLowerCase().includes(email.toLocaleLowerCase()) || user.email.toLocaleLowerCase().includes(email.toLocaleLowerCase()))
        .sortBy(user => user.username)
        .map(user => {
            return {
                id: user.userId,
                title: user.username,
                description: user.email,
                image: user.avatarUrl,
            }
        });

    const handleSearchChange = (emailVal: Email) => {
        setEmail(emailVal);
        if(isValidEmail(emailVal)) {
            props.onEmailChange(emailVal);
        }
    }

    return (
        <Modal open={props.open} closeIcon={true} onClose={props.onClose} size="tiny">
            <Modal.Header>{props.t('insp.board.invite_user')}</Modal.Header>
            <Modal.Content>
                <div style={{ marginBottom: '10px' }}>
                        <Grid container={true} stackable={true} doubling={true} >
                            <GridColumn mobile={16} tablet={8} computer={10}>
                                <Search
                                    fluid={true}
                                    value={email}
                                    results={filteredUsers.toArray()}
                                    placeholder={props.t('insp.board.form.email') as string}
                                    showNoResults={false}
                                    resultRenderer={data => {
                                        return <div key={data.id}>
                                            <div className="ui image avatar">
                                                <img src={data.image} alt={data.title} />
                                            </div>
                                            <div className="content">
                                                <div className="title">{data.title}</div>
                                                <div className="description">{data.description}</div>
                                            </div>
                                        </div>
                                    }}
                                    onSearchChange={(e, data) => handleSearchChange(data.value || '')}
                                    onResultSelect={(e, data) => {
                                        setEmail(data.result.description);
                                        props.onEmailChange(data.result.description);
                                    }}
                                />
                            </GridColumn>
                            <GridColumn mobile={16} tablet={8} computer={6}>
                                <Button.Group>
                                    <Button
                                        type={'button'}
                                        className={'positive'}
                                        children={'Read'}
                                        onClick={() => props.onPermissionsChange({...props.permissions, writeAccess: false})}
                                    />
                                    <Button.Or text="+" />
                                    <Button
                                        type={'button'}
                                        className={props.permissions.writeAccess ? 'positive' : ''}
                                        children={'Write'}
                                        onClick={() => props.onPermissionsChange({...props.permissions, writeAccess: !props.permissions.writeAccess})}
                                    />
                                </Button.Group>
                            </GridColumn>
                        </Grid>
                    </div>
            </Modal.Content>
            <Modal.Actions>
                <Button primary={true} type="submit" onClick={props.onSubmit} disabled={!isValidEmail(email)}>
                    {props.t('app.form.confirm')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

/**
 * Validates the basic email structure; not a perfect validation
 *
 * @param value
 */
const isValidEmail = (value: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};

export default withNamespaces()(InviteUserToBoardModal);
