import {JSONSchema7} from "json-schema";
import {
  makeAssignVariable, makeCallService,
  makeDefinitions, makeExecuteRules,
  makeForEach,
  makeThenRecordEvent,
  makeThenThrowError
} from "./rule-engine-definitions";

export const makeBusinessRulesSchema = (possibleEvents: string[], possibleServices: string[]): string => {
  const BusinessRulesSchema: JSONSchema7 = {
    type: "array",
    items: {
      $ref: "#/definitions/rule_definition"
    },
    definitions: makeDefinitions(
      makeThenRecordEvent(possibleEvents),
      makeThenThrowError(),
      makeAssignVariable(),
      makeCallService(possibleServices),
      makeForEach(),
      makeExecuteRules()
    )
  }

  return JSON.stringify(BusinessRulesSchema);
}
