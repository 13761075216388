import {List} from "immutable";
import * as React from 'react';
import {useState} from "react";
import {useEffect} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {Confirm, Icon} from "semantic-ui-react";
import * as Action from "../../InspectioBoards/actions";
import InviteUserToBoardModal from "../../InspectioBoards/components/InviteUserToBoardModal";
import {Board} from "../../InspectioBoards/model/Board";
import {Permissions} from "../../InspectioBoards/model/BoardPermissionForUser";
import {Action as TeamAction} from "../../InspectioTeams";
import AddTeamToBoardModal from "../../InspectioTeams/components/AddTeamToBoardModal";
import {Team, TeamId, TeamName} from "../../InspectioTeams/model/Team";
import {makeTeamListSelector} from "../../InspectioTeams/selectors/selectTeam";
import * as Route from "../../routes";
import {isStandalone} from "../../standalone/util";
import * as UserAction from "../../User/actions";
import {upsertUserSetting} from "../../User/actions/commands";
import {UserModel} from "../../User/model";
import {IioUserInfo} from "../../User/model/IioUserInfo";
import {Email} from "../../User/model/UserInfo";
import {makeIioUserListSelector} from "../../User/selectors/iioUserInfo";
import {keyBoardHidden, makeUserSettingSelector} from "../../User/selectors/userSettings";

interface OwnProps {
    board: Board;
}

type BoardListItemProps = OwnProps & WithNamespaces;

const BoardListItem = (props: BoardListItemProps) => {
    const [visible, setVisible] = useState(false);
    const [iconUp, setIconUp] = useState(false);
    const [userEmail, setUserEmail] = useState<string>('');
    const [permissions, setPermissions] = useState<Permissions>({adminAccess: false, writeAccess: true, readAccess: true});
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const [showAddTeamModal, setShowAddTeamModal] = useState<boolean>(false);
    const [showRemoveMemberConfirm, setShowRemoveMemberConfirm] = useState<boolean>(false);
    const [showRemoveTeamConfirm, setShowRemoveTeamConfirm] = useState<boolean>(false);
    const [memberToRemove, setMemberToRemove] = useState<UserModel.UserId|undefined>(undefined);
    const [teamToRemove, setTeamToRemove] = useState<TeamId|undefined>(undefined);
    const dispatch = useDispatch();
    const users = useSelector(makeIioUserListSelector());
    const teams = useSelector(makeTeamListSelector());
    const hidden = useSelector(makeUserSettingSelector(keyBoardHidden(props.board.uid), false));

    const angleIcon = iconUp ? 'angle up icon' : 'angle down icon';
    const contentClass = 'ui labels ' + (visible ? 'slidedown' : 'slideup');

    useEffect(() => {
        if (props.board.sharedWith.count() < 1) {
            return;
        }

        dispatch(UserAction.Query.loadUsersInfo(props.board.sharedWith.toArray()))
    }, [props.board.sharedWith]);

    const getUsernameByUserId = (userId: string) => {
        const user = users.find((userInfo: IioUserInfo) => userInfo.userId === userId);
        return user ? user.username : ''
    };

    const handleVisibleClick = () => {
        setVisible(!visible);
        if(iconUp) {
            window.setTimeout(() => {
                setIconUp(false);
            }, 150);
        } else {
            setIconUp(true);
        }
    };

    // Team Handling
    const openAddTeamModal = (event: React.MouseEvent) => {
        stopPropagation(event);
        setShowAddTeamModal(true);
    };

    const closeAddTeamModal = () => {
        setShowAddTeamModal(false);
    };

    const handleAddTeamSubmit = (teamId: TeamId) => {
        dispatch(TeamAction.Command.adminGrantTeamAccessToBoard(props.board.uid, [teamId]));
        setShowAddTeamModal(false);
    };

    const removeTeamFromBoard = () => {
        if(!teamToRemove) {
            return;
        }

        dispatch(TeamAction.Command.adminRevokeTeamAccessToBoard(props.board.uid, teamToRemove));
        setShowRemoveTeamConfirm(false);
    }

    // User Handling
    const openAddUserModal = (event: React.MouseEvent) => {
        stopPropagation(event);
        setShowAddUserModal(true);
    };

    const closeAddUserModal = () => {
        setShowAddUserModal(false);
    };

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const handleShareBoardSubmit = () => {
        dispatch(Action.Command.shareBoard(props.board.uid, userEmail, permissions));

        setUserEmail('');
        closeAddUserModal();
    };

    const onEmailChange = (email: Email) => {
        setUserEmail(email);
    };

    const removeMemberFromBoard = () => {
        if (!memberToRemove) {
            return;
        }

        dispatch(Action.Command.revokeAccessToBoard(props.board.uid, memberToRemove));
        setShowRemoveMemberConfirm(false);
    };

    const handleHideBoardClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        dispatch(upsertUserSetting(keyBoardHidden(props.board.uid), !hidden));
    };

    const eyeIcon = hidden ? "eye slash icon" : "eye icon";

    return <div className="ui item"  style={{cursor: "pointer"}}>
        {!isStandalone() && <Icon className={angleIcon} onClick={handleVisibleClick}/>}
        <NavLink className="board-list board" to={Route.compileInspectioBoardWorkspace(props.board.uid)}>{props.board.name}</NavLink>
        <Icon className={eyeIcon} onClick={handleHideBoardClick} />
        {!isStandalone() && <div className={contentClass}>
            <a className="ui blue basic label" onClick={openAddTeamModal}>
                <i className="white plus icon"/>
                Add Team
            </a>
            {props.board.assignedTeams.map((teamId) => {
                return <div key={teamId} className="ui basic label">
                    {getTeamNameById(teamId, teams)}
                    <i className="red delete icon" onClick={(event: React.MouseEvent) => {
                        stopPropagation(event);
                        setTeamToRemove(teamId);
                        setShowRemoveTeamConfirm(true);
                    }}/>
                </div>
            })}
            <a className="ui blue basic label" onClick={openAddUserModal}>
                <i className="white plus icon"/>
                Add User
            </a>
            {props.board.sharedWith.map((userId) => {
                return <div key={userId} className="ui basic label">
                    {getUsernameByUserId(userId)}
                    <i className="red delete icon" onClick={(event: React.MouseEvent) => {
                        stopPropagation(event);
                        setMemberToRemove(userId);
                        setShowRemoveMemberConfirm(true);
                    }}/>
                </div>;
            })}
        </div>}
        <div onClick={stopPropagation}>
            <AddTeamToBoardModal
                open={showAddTeamModal}
                onClose={closeAddTeamModal}
                onSubmit={handleAddTeamSubmit}
                board={props.board}
            />
            <InviteUserToBoardModal
                open={showAddUserModal}
                onClose={closeAddUserModal}
                onSubmit={handleShareBoardSubmit}
                email={userEmail}
                onEmailChange={onEmailChange}
                permissions={permissions}
                onPermissionsChange={setPermissions}
            />
            <Confirm
                open={showRemoveMemberConfirm}
                header={props.t('insp.board.remove_user_confirm_title')}
                content={props.t(
                    'insp.board.remove_user_confirm_text',
                    { user_name: memberToRemove ? getUsernameByUserId(memberToRemove) : '' }
                )}
                onCancel={() => setShowRemoveMemberConfirm(false)}
                onConfirm={removeMemberFromBoard}
            />
            <Confirm
                open={showRemoveTeamConfirm}
                header={props.t('insp.team.remove_from_board_confirm_title')}
                content={props.t(
                    'insp.team.remove_from_board_confirm_text',
                    { team_name: teamToRemove ? getTeamNameById(teamToRemove, teams) : '' }
                )}
                onCancel={() => setShowRemoveTeamConfirm(false)}
                onConfirm={removeTeamFromBoard}
            />
        </div>
    </div>;
};

const getTeamNameById = (teamId: TeamId, teams: List<Team>): TeamName => {
    const team = teams.find(t => t.uid === teamId);

    if(!team) {
        return 'Unknown Team';
    }

    return team.name;
}

export default withNamespaces()(BoardListItem);
