import {Node} from "../../../model/Graph";
import {parseNodeMetadata} from "../node/metadata";
import {
  AlwaysRule,
  ConditionRule,
  Rule, ThenDeleteProjection,
  ThenInsertProjection, ThenReplaceProjection,
  ThenType, ThenUpdateProjection,
  ThenUpsertProjection
} from "../rule-engine/configuration";
import { RulesDependenciesMetadata } from "../rule-engine/types";
import {Schema} from "../schema/schema";
import {UiSchema} from "../ui/ui-schema";
import {isVoStoredInDb} from "./is-vo-stored-in-db";
import {getIdentifierCandidatesFromSchema} from "../../../components/CodyEngineWizard/Step/StateVO/IdentifierSelect";

export type Sort = 'asc' | 'desc';

export interface SortOrderItem {
  prop: string;
  sort: Sort;
}
export type SortOrder = SortOrderItem[];

export interface ResolveConfig {
  where?: AlwaysRule | ConditionRule,
  orderBy?: SortOrderItem | SortOrder
}

export interface ProjectionConfig {
  name?: string,
  live?: boolean,
  cases: ProjectionConfigCase[]
}

export interface ProjectionConfigCase {
  given?: Rule[],
  when: string,
  then: ThenInsertProjection | ThenUpsertProjection | ThenUpdateProjection | ThenReplaceProjection | ThenDeleteProjection,
}

export interface RefTableColumn {
  data: string;
  value: Rule[] | string;
}

export interface PageLinkTableColumn {
  page: string;
  mapping: Record<string, string>;
}

export interface TableColumnUiSchema {
  field: string;
  headerName?: string;
  flex?: string | number;
  width?: string | number;
  value?: Rule[] | string;
  pageLink?: string | PageLinkTableColumn;
  ref?: {data: string; value: string};
  link?: string | Rule[];
}

export type TableGridDensity = 'compact' | 'standard' | 'comfortable';

interface TableUiConfig {
  columns?: TableColumnUiSchema[],
  pageSize?: number,
  pageSizeOptions?: number[],
  density?: TableGridDensity,
  // @TODO: show/hide specific grid toolbar options or hide entire grid toolbar
  // @TODO: support endless scroll, pagination, ... ?
}

export interface TableUiSchema {
  'ui:table'?: TableUiConfig;
  table?: TableUiConfig;
}


export interface ValueObjectMetadata {
  identifier?: string;
  shorthand?: boolean;
  schema?: Schema;
  querySchema?: Schema;
  queryDependencies?: RulesDependenciesMetadata;
  resolve?: ResolveConfig;
  ns?: string;
  collection?: string | false;
  initialize?: Rule[];
  uiSchema?: UiSchema & TableUiSchema;
  projection?: ProjectionConfig;
  entity?: boolean;
}

export const getVoMetadata = (node: Node): ValueObjectMetadata => {
  const metadata = parseNodeMetadata(node);

  if(metadata.schema) {
    metadata.schema = new Schema(metadata.schema);
  }

  if(metadata.querySchema) {
    metadata.querySchema = new Schema(metadata.querySchema);
  }

  return metadata;
}
