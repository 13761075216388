import {List, Record} from "immutable";
import { createSelector } from 'reselect';
import {Team} from "../../InspectioTeams/model";
import {TeamId} from "../../InspectioTeams/model/Team";
import {makeActiveUserOrganizationInfoSelector} from "../../Organization/selectors/selectUserOrganizationInfo";
import {ApplicationState} from "../../reducer";
import {UserModel, UserSelector} from '../../User';
import {UserSettingsState} from "../../User/reducers/applyUserSettingsActions";
import {currentUserSelector} from "../../User/selectors/currentUser";
import {keyBoardHidden, userSettingsSelector} from "../../User/selectors/userSettings";
import {SORT_OPTIONS} from "../components/BoardOverview/Sorting";
import {BoardModel} from "../model";
import {BoardOverviewState} from "../reducers/applyOverviewActions";

export const boardListSelector = (state: Record<ApplicationState>, props: any): List<BoardModel.Board> => List(state.getIn(['inspectioBoards', 'boards']).toIndexedSeq().toArray()) || List();

export const boardLoadingSelector = (state: Record<ApplicationState>, props: any): boolean => !!state.getIn(['inspectioBoards', 'loadingBoards']);

export const initialBoardListLoadedSelector = (state: Record<ApplicationState>, props: any): boolean => state.getIn(['inspectioBoards', 'initialBoardListLoaded']) || false;

export const boardOverviewSelector = (state: Record<ApplicationState>, props?: any): BoardOverviewState => state.getIn(['inspectioBoards', 'overview']);

export const makeGetAllBoards = (): (state: Record<ApplicationState>, props?: any) => List<BoardModel.Board> => {
    const activeUserOrganizationInfoSelector = makeActiveUserOrganizationInfoSelector();

    return createSelector(
        [boardListSelector,  activeUserOrganizationInfoSelector],
        (boards, activeOrganization) => {
            boards = boards.filter(board => {
                if(activeOrganization) {
                    if(!activeOrganization.explicitOrganization && board.organization === null) {
                        return true;
                    }

                    return board.organization === activeOrganization.organizationId;
                } else {
                    return board.organization === null;
                }
            })

            boards = boards.sortBy(board => board.name, (a, b) => a === b ? 0 : (a! > b! ? 1 : -1)  )

            return boards;
        }
    );
}

export const makeGetBoards = (returnHiddenBoards: boolean = false): (state: Record<ApplicationState>, props?: any) => List<BoardModel.Board> => {
    const activeUserOrganizationInfoSelector = makeActiveUserOrganizationInfoSelector();

    return createSelector(
        [boardListSelector, boardOverviewSelector, userSettingsSelector, currentUserSelector, activeUserOrganizationInfoSelector],
        (boards, boardOverview: BoardOverviewState, userSettings: UserSettingsState, currentUser: UserModel.UserInfo, activeOrganization): List<BoardModel.Board> =>  {

            boards = boards.filter(board => {
                if(activeOrganization) {
                    if(!activeOrganization.explicitOrganization && board.organization === null) {
                        return true;
                    }

                    return board.organization === activeOrganization.organizationId;
                } else {
                    return board.organization === null;
                }
            })

            if(boardOverview.filter !== '') {
                const parts = boardOverview.filter.split(" ").map(part => part.trim());
                const tags: string[] = parts.filter(part => part.charAt(0) === '#');
                if(tags.length) {
                    boards = boards.filter((board) => {
                        let allTagsMatch = true;
                        tags.forEach(tag => {
                            if(!board.tags.contains(tag.slice(1))) {
                                allTagsMatch = false;
                            }
                        })
                        return allTagsMatch;
                    })
                }

                const filterRest = parts.filter(part => part.charAt(0) !== '#').join(" ").toLowerCase();

                if(filterRest !== '') {
                    boards = boards.filter((board) => {
                        return board.name.toLowerCase().search(filterRest) !== -1;
                    })
                }
            }

            if(boardOverview.responsibleTeam) {
                boards = boards.filter((board) => {
                    return board.assignedTeams.contains(boardOverview.responsibleTeam as TeamId)
                })
            }

            if(!returnHiddenBoards && boardOverview.allSharedBoardsHidden) {
                boards = boards.filter(board => !board.shared);
            }

            if(!returnHiddenBoards && boardOverview.allOwnBoardsHidden) {
                boards = boards.filter(board => board.shared);
            }

            if(!returnHiddenBoards) {
                boards = boards.filter(board => {
                    return userSettings.get(keyBoardHidden(board.uid)) !== true
                })
            }

            switch (boardOverview.sortBy) {
                case SORT_OPTIONS.NAME_ASC:
                    boards = boards.sortBy(board => board.name, (a, b) => a === b ? 0 : (a! > b! ? 1 : -1)  )
                    break;
                case SORT_OPTIONS.NAME_DESC:
                    boards = boards.sortBy(board => board.name, (a, b) => a === b ? 0 : (a! > b! ? -1 : 1)  )
                    break;
                case SORT_OPTIONS.LAST_MODIFIED_ASC:
                    boards = boards.sortBy(board => board.lastModified, (a, b) => a === b ? 0 : (a! > b! ? 1 : -1)  )
                    break;
                case SORT_OPTIONS.LAST_MODIFIED_DESC:
                    boards = boards.sortBy(board => board.lastModified, (a, b) => a === b ? 0 : (a! > b! ? -1 : 1)  )
                    break;
                case SORT_OPTIONS.CREATED_AT_ASC:
                    boards = boards.sortBy(board => board.createdAt, (a, b) => a === b ? 0 : (a! > b! ? 1 : -1)  )
                    break;
                case SORT_OPTIONS.CREATED_AT_DESC:
                    boards = boards.sortBy(board => board.createdAt, (a, b) => a === b ? 0 : (a! > b! ? -1 : 1)  )
                    break;
            }

            return boards;
        }
    )
};

export const makeGetTeamBoards = (teamId: Team.TeamId): (state: Record<ApplicationState>, props?: any) => List<BoardModel.Board> => {
    return createSelector(
        [boardListSelector],
        (boards): List<BoardModel.Board> => boards.filter(board => board.assignedTeams.contains(teamId))
    )
}

export const makeIsLoadingBoards = (): (state: Record<ApplicationState>, props?: any) => boolean => {
    return  createSelector(
        [boardLoadingSelector],
        (isLoading): boolean => isLoading
    )
};

export const makeInitialBoardListLoaded = (): (state: Record<ApplicationState>, props?: any) => boolean => {
    return createSelector(
        [initialBoardListLoadedSelector],
        (listLoaded): boolean => listLoaded
    )
}

export const makeHasBoards = (): (state: Record<ApplicationState>, props?: any) => boolean => {
    const activeUserOrganizationInfoSelector = makeActiveUserOrganizationInfoSelector();

    return createSelector(
        [boardListSelector, activeUserOrganizationInfoSelector],
        (boards, activeOrganization): boolean => {
            boards = boards.filter(board => {
                if(activeOrganization) {
                    if(!activeOrganization.explicitOrganization && board.organization === null) {
                        return true;
                    }

                    return board.organization === activeOrganization.organizationId;
                } else {
                    return board.organization === null;
                }
            })

            return boards.count() > 0;
        }
    )
}

export const makeGetBoardOverview = (): (state: Record<ApplicationState>, props?: any) => BoardOverviewState => {
    return createSelector(
        [boardOverviewSelector],
        (boardOverview: BoardOverviewState): BoardOverviewState => boardOverview
    )
}

export const makeMapStateToPropsBoardOverview = () => {
    const getBoards = makeGetBoards();
    const getQuota = UserSelector.makeGetCurrentBoardQuota();
    const isLoadingBoards = makeIsLoadingBoards();

    return (state: Record<ApplicationState>, props: any) => {
        const boards = getBoards(state, props).filter(board => !board.tempBoard);
        const userQuota = getQuota(state, props);
        const loading = isLoadingBoards(state, props);

        return {
            boards,
            userQuota,
            loading,
        }
    }
}
