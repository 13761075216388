import {ServiceName} from "../../../model/Board";
import {Node, NodeType} from "../../../model/Graph";
import {WizardContext} from "../context/wizard-context";
import {findParentByType} from "./find-parent-by-type";
import {parseNodeMetadata} from "./metadata";
import {names} from "./names";

export const getDefaultServiceName = (ctx: WizardContext): string => {
  return names(ctx.board.name).className;
}

export const detectService = (node: Node, defaultServiceName: ServiceName): string => {
  const meta = node.getMetadata() ? parseNodeMetadata(node) : {};

  if(meta.service && typeof meta.service === 'string') {
    return names(meta.service).className;
  }

  const feature = findParentByType(node, NodeType.feature);

  if(feature) {
    const featureMeta = parseNodeMetadata(feature);

    if(featureMeta.service) {
      return names(featureMeta.service).className;
    }
  }

  const bc = findParentByType(node, NodeType.boundedContext);

  if(bc) {
    const bcMeta = parseNodeMetadata(bc);
    if(bcMeta.service) {
      return names(bcMeta.service).className;
    }
  }

  return defaultServiceName;
}
