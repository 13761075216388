import { call, fork, put, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {ResponseType} from "../../api/util";
import {execValidate, isFailure, isSuccess, pipe} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import { Action as NotifyAction } from '../../NotificationSystem';
import * as Action from '../actions';
import {BoardClient} from "../model/BoardClient";

type FlowAction = ActionType<typeof Action.Query.fetchBoardClient>;

const onFetchBoardClient = function* (action: FlowAction) {
    const {response, error}: ResponseType = yield call(Action.Api.fetchBoardClient, action.payload.boardId);

    if (error) {
        if (error.status === 404) {
            yield put(Action.Event.boardClientNotConfigured(action.payload.boardId));
        } else {
            yield put(NotifyAction.Command.error("Request Error", "Failed to load board client"));
        }
    }

    if (response) {
        const result = pipe(
            response!.data,
            execValidate([hasKey('clientId'), "Response data does not contain key clientId"])
        );

        if (isFailure(result)) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not load board client'));
        }

        if (isSuccess(result)) {
            yield put(Action.Event.boardClientFetched(action.payload.boardId, result.value as BoardClient));
        }
    }
};

const onFetchBoardClientSecret = function* (action: FlowAction) {
    const {response, error}: ResponseType = yield call(Action.Api.fetchBoardClientSecret, action.payload.boardId);

    if (error) {
        yield put(NotifyAction.Command.error("Request Error", "Failed to load board client secret"));
    }

    if (response) {
        const result = pipe(
            response!.data,
            execValidate([hasKey('clientSecret'), "Response data does not contain key clientSecret"])
        );

        if (isFailure(result)) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not load board client secret'));
        }

        if (isSuccess(result)) {
            yield put(Action.Event.boardClientSecretFetched(action.payload.boardId, (result.value as any).clientSecret));
        }
    }
};

const onCreateBoardClient = function* (action: FlowAction) {
    const {response, error}: ResponseType = yield call(Action.Api.createBoardClient, action.payload.boardId);

    if (error) {
        yield put(NotifyAction.Command.error("Request Error", "Failed to create board client"));
    }

    if (response) {
        yield call(onFetchBoardClient, action);
    }
};

const onRemoveBoardClient = function* (action: FlowAction) {
    const {response, error}: ResponseType = yield call(Action.Api.removeBoardClient, action.payload.boardId);

    if (error) {
        yield put(NotifyAction.Command.error("Request Error", "Failed to remove board client"));
    }

    if (response) {
        yield put(Action.Event.boardClientNotConfigured(action.payload.boardId));
    }
};

const onResetBoardClientSecret = function* (action: FlowAction) {
    const {response, error}: ResponseType = yield call(Action.Api.resetBoardClientSecret, action.payload.boardId);

    if (error) {
        yield put(NotifyAction.Command.error("Request Error", "Failed to reset board client secret"));
    }

    if (response) {
        yield call(onFetchBoardClientSecret, action);
    }
};

export function* fetchBoardClientFlow() {

    while (true) {
        const action: FlowAction = yield take([
            Action.Type.FETCH_BOARD_CLIENT,
            Action.Type.FETCH_BOARD_CLIENT_SECRET,
            Action.Type.CREATE_BOARD_CLIENT,
            Action.Type.REMOVE_BOARD_CLIENT,
            Action.Type.RESET_BOARD_CLIENT_SECRET
        ]);

        if (action.type === Action.Type.FETCH_BOARD_CLIENT) {
            yield fork(onFetchBoardClient, action);
        } else if(action.type === Action.Type.FETCH_BOARD_CLIENT_SECRET) {
            yield fork(onFetchBoardClientSecret, action);
        } else if(action.type === Action.Type.CREATE_BOARD_CLIENT) {
            yield fork(onCreateBoardClient, action);
        } else if(action.type === Action.Type.REMOVE_BOARD_CLIENT) {
            yield fork(onRemoveBoardClient, action);
        } else if(action.type === Action.Type.RESET_BOARD_CLIENT_SECRET) {
            yield fork(onResetBoardClientSecret, action);
        }
    }
}