import * as React from "react";
import {useSelector} from "react-redux";
import {Table} from "semantic-ui-react";
import {Board} from "../../model/Board";
import {makeBoardPermissionForTeamListSelector} from "../../selectors/boardPermission";
import BoardPermissionsForTeamsTableEntry from "./BoardPermissionsForTeamsTableEntry";

interface PropsFromState {
}

interface PropsFromDispatch {

}

interface OwnProps {
    board: Board;
}

export type BoardPermissionsForTeamsTableProps = PropsFromState & PropsFromDispatch & OwnProps;

export const BoardPermissionsForTeamsTable = (props: BoardPermissionsForTeamsTableProps) => {

    const boardPermissions = useSelector(makeBoardPermissionForTeamListSelector(props.board.uid));

    return (
        <Table unstackable={true} basic="very">
            <Table.Body>
                {boardPermissions.filter(p => p.teamId === props.board.responsibleTeam).map(boardPermission => (
                    <BoardPermissionsForTeamsTableEntry
                        board={props.board}
                        boardPermission={boardPermission}
                        key={boardPermission.uid}
                    />
                ))}
                {boardPermissions.filter(p => p.teamId !== props.board.responsibleTeam).map(boardPermission => (
                    <BoardPermissionsForTeamsTableEntry
                        board={props.board}
                        boardPermission={boardPermission}
                        key={boardPermission.uid}
                    />
                ))}
            </Table.Body>
        </Table>
    );
};
