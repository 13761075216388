import {JSONSchema7} from "json-schema";

export const makeShorthandSchemaValidationSchema = (possibleRefs: string[]): string => {
  const ShorthandSchema: JSONSchema7 = {
    $ref: "#/definitions/shorthand_object",
    definitions: {
      "shorthand_both": {
        oneOf: [
          {
            $ref: '#/definitions/shorthand_string'
          },
          {
            $ref: '#/definitions/shorthand_object'
          }
        ]
      },
      "shorthand_string": {
        anyOf: [
          {
            type: "string"
          },
          {
            type: "string",
            enum: [...[
              "string",
              "string|format:uuid",
              "string|format:email",
              "string|format:",
              "string|format:date",
              "string|format:date|formatMinimum:",
              "string|format:date|$formatMinimum:",
              "string|format:date|formatExclusiveMinimum:",
              "string|format:date|$formatExclusiveMinimum:",
              "string|format:date|formatMaximum:",
              "string|format:date|$formatMaximum:",
              "string|format:date|formatExclusiveMaximum:",
              "string|format:date|$formatExclusiveMaximum:",
              "string|format:date-time",
              "string|format:time",
              "string|format:uri",
              "string|format:uri-reference",
              "string|minLength:",
              "string|maxLength:",
              "string|title:",
              "integer",
              "integer|minimum:",
              "integer|exclusiveMinimum:",
              "integer|maximum:",
              "integer|exclusiveMaximum:",
              "integer|multipleOf:",
              "number",
              "number|minimum:",
              "number|exclusiveMinimum:",
              "number|maximum:",
              "number|exclusiveMaximum:",
              "number|multipleOf:",
              "number|title",
              "boolean",
              "enum:"
            ], ...possibleRefs]
          }
        ]
      },
      "shorthand_object": {
        type: "object",
        patternProperties: {
          '^[a-zA-Z]{1,}[a-zA-Z0-9]{0,}[?]{0,1}$': {
            $ref: "#/definitions/shorthand_both"
          },
          '^\\$title$': {
            type: "string"
          },
          '\\$ref$': {
            type: "string",
            enum: possibleRefs,
          },
          '\\$items': {
            $ref: "#/definitions/shorthand_both"
          }
        },
        additionalProperties: false
      }
    }
  }

  // return JSON.stringify({
  //   type: "object",
  //   properties: {
  //     test: {
  //       $ref: "#/definitions/test_string"
  //     }
  //   },
  //   definitions: {
  //     "test_string": {
  //       type: "string",
  //       minLength: 3
  //     }
  //   }
  // });
  return JSON.stringify(ShorthandSchema);
}


