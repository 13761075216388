import * as React from 'react';
import {useState} from "react";
import JoinMeetingDialog from "./JoinMeetingDialog";
import Meeting from "./Meeting";

interface OwnProps {
    sessionId: string;
    id: string;
    name: string;
    avatar: string;
    initialPosition: {left: number, top: number};
    loadingMeetingRoom: boolean;
}

type MeetingLobbyProps = OwnProps;

const MeetingLobby = (props: MeetingLobbyProps) => {
    const [modalOpen, setModalOpen] = useState(true);
    const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>('');
    const [selectedAudioDevice, setSelectedAudioDevice] = useState<string>('');

    const canJoin = () => {
        return selectedVideoDevice !== '' && selectedAudioDevice !== '';
    }

    const handleDevicesSelected = (devices: {audio: string, video: string}) => {
        setSelectedAudioDevice(devices.audio);
        setSelectedVideoDevice(devices.video);
        setModalOpen(false);
    }

    const handleCancel = () => {
        setModalOpen(false);
    }

    return (
        <>
            {canJoin() && <Meeting
                sessionId={props.sessionId}
                id={props.id}
                name={props.name}
                avatar={props.avatar}
                initialPosition={props.initialPosition}
                audioDevice={selectedAudioDevice}
                videoDevice={selectedVideoDevice}
            />}
            <JoinMeetingDialog open={modalOpen}
                onDevicesSelected={handleDevicesSelected}
                onCancel={handleCancel}
                loadingMeetingRoom={props.loadingMeetingRoom}
           />
        </>
    )
};

export default MeetingLobby;