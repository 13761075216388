import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button} from "semantic-ui-react";
import {WizardContext} from "../../../service/cody-wizard/context/wizard-context";
import {stepHeader} from "../../../service/cody-wizard/step/step-header";
import {WizardStep} from "../WizardModal";

interface OwnProps {
  activeStep: WizardStep | null;
  ctx: WizardContext;
  onNextStep: (step: WizardStep) => void;
}

type NextStepProps = OwnProps & WithNamespaces;

const NextStep = (props: NextStepProps) => {
  const nextStep = props.activeStep ? props.ctx.nextStep(props.activeStep, props.ctx) : null;

  if(!nextStep) {
    return <></>
  }

  return <Button icon="right chevron"
                 labelPosition="right"
                 basic={true}
                 className="noborder leftborder"
                 content={stepHeader(nextStep, props.t)}
                 onClick={() => props.onNextStep(nextStep)}
  />
};

export default withNamespaces()(NextStep);
