import {readFileArrayBuffer} from "./readFile";

export interface Crop {
    x: number; // Left margin; e.g. 0.1 => 10% margin from the left
    y: number; // Top margin
    width: number; // Image width; e.g. 0.5 => 50% of original width
    height: number; // Image height
}

/**
 * Takes the image in the HTMLImageElement provided and maps it into a canvas according to the crop value provided.
 * The returned canvas can then be exported as needed.
 *
 * @param image
 * @param crop
 */
const createCropCanvas = (image: HTMLImageElement, crop: Crop): HTMLCanvasElement => {
    const cropStartX = Math.round(crop.x * 0.01 * image.naturalWidth);
    const cropStartY = Math.round(crop.y * 0.01 * image.naturalHeight);
    const newWidth = Math.round(crop.width * 0.01 * image.naturalWidth);
    const newHeight = Math.round(crop.height * 0.01 * image.naturalHeight);

    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;

    const ctx = canvas.getContext('2d')!;
    ctx.drawImage(image, cropStartX, cropStartY, newWidth, newHeight, 0, 0, newWidth, newHeight);

    return canvas;
};

export const getCroppedImageBase64 = (image: HTMLImageElement, crop: Crop, mimeType: string): string => {
    return createCropCanvas(image, crop).toDataURL(mimeType);
};

export const getCroppedImageArrayBuffer = (image: HTMLImageElement, crop: Crop, mimeType: string): Promise<ArrayBuffer> => {
    return new Promise(async (resolve, reject) => {
        createCropCanvas(image, crop).toBlob(
            blob => {
                if (!blob) {
                    reject();
                }

                resolve(readFileArrayBuffer(blob as File));
            },
            mimeType
        );
    });
};
