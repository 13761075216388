import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Header, Icon, Image, Label, Table} from "semantic-ui-react";
import {IioUserInfo} from "../../../User/model/IioUserInfo";
import {makeGetIioUserInfoByIdSelector} from "../../../User/selectors/iioUserInfo";
import * as Action from "../../actions";
import {Board} from "../../model/Board";
import {BoardPermissionForUser} from "../../model/BoardPermissionForUser";

interface PropsFromState {
}

interface PropsFromDispatch {

}

interface OwnProps {
    board: Board;
    boardPermission: BoardPermissionForUser;
}

export type BoardPermissionsTableEntryProps = PropsFromState & PropsFromDispatch & OwnProps;

export const BoardPermissionTableEntry = (props: BoardPermissionsTableEntryProps) => {

    const dispatch = useDispatch();
    const userInfo: IioUserInfo|undefined = useSelector(makeGetIioUserInfoByIdSelector(props.boardPermission.userId));

    const isOwner = props.board.owner === props.boardPermission.userId || props.board.creator === props.boardPermission.userId;
    const hasReadAccess = props.boardPermission.permissions.readAccess;
    const hasWriteAccess = props.boardPermission.permissions.writeAccess;

    if (!hasReadAccess && !hasWriteAccess) {
        return null;
    }

    if (!userInfo) {
        return null;
    }

    const handleRevokeAccessToBoard = () => {
        dispatch(Action.Command.revokeAccessToBoard(props.board.uid, userInfo.userId));
    };

    const handleChangeReadBoardPermissions = () => {
        if(!props.boardPermission.permissions.writeAccess) {
            return;
        }

        dispatch(Action.Command.changeBoardPermissions(
            props.board.uid,
            userInfo.userId,
            { ...props.boardPermission.permissions, writeAccess: false }
        ));
    };

    const handleChangeWriteBoardPermissions = () => {
        dispatch(Action.Command.changeBoardPermissions(
            props.board.uid,
            userInfo.userId,
            { ...props.boardPermission.permissions, writeAccess: !props.boardPermission.permissions.writeAccess }
        ));
    };

    return (
        <Table.Row>
            <Table.Cell style={{width: "310px"}}>
                <Header as='h4' image={true}>
                    <img className="ui avatar image" src={userInfo.avatar()} alt={userInfo.username} />
                    <Header.Content>
                        {userInfo.username}
                        {isOwner && (
                            <Label color='blue' style={{marginBottom:"0.2rem", marginLeft:"0"}} basic={true}>Owner</Label>
                        )}
                        <Header.Subheader>{userInfo.position}</Header.Subheader>
                    </Header.Content>
                </Header>
            </Table.Cell>
            <Table.Cell>
                <Button.Group>
                    <Button
                        className={'positive'}
                        children={'Read'}
                        disabled={isOwner}
                        onClick={handleChangeReadBoardPermissions}
                    />
                    <Button.Or text="+" />
                    <Button
                        className={hasWriteAccess ? 'positive' : ''}
                        children={'Write'}
                        disabled={isOwner}
                        onClick={handleChangeWriteBoardPermissions}
                    />
                </Button.Group>
                {!isOwner && (
                    <Button
                        icon={true}
                        className="right floated basic noborder circular button"
                        children={<Icon className="red close icon" />}
                        onClick={handleRevokeAccessToBoard}
                    />
                )}
            </Table.Cell>
        </Table.Row>
    );
};
