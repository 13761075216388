import * as React from 'react';
import {useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button, Grid, Message, Modal} from "semantic-ui-react";

interface OwnProps {

}

type UpgradeProps = OwnProps & WithNamespaces;

const Upgrade = (props: UpgradeProps) => {

    return <>
        <div className="middle aligned item">
            <div className="ui big header">Free Version</div>
            <Grid className="stackable two column">
                <Grid.Column>
                    <Button className="positive" href="https://prooph-board.com#pricing" target="_blank">Upgrade</Button>
                </Grid.Column>
                <Grid.Column>
                    <div className="sub header">More Features, Organization & Teams, Realtime Collaboration</div>
                </Grid.Column>
            </Grid>
        </div>
    </>;
};

export default withNamespaces()(Upgrade);
