import { Reducer } from 'redux';
import * as actions from '../actions';
import { StatusModel } from '../model';
import { SocketAction } from './index';

export interface StatusState extends StatusModel.Status {
}

export const initialState = StatusModel.disconnectedStatus();

const reducer: Reducer<StatusState, SocketAction> = (state: StatusState = initialState, action: SocketAction): StatusState => {
    switch (action.type) {
        case actions.Type.CONNECT_SOCKET:
            return StatusModel.fromtString(StatusModel.STATUS_CONNECTING);
        case actions.Type.SOCKET_CONNECTED:
            return StatusModel.fromtString(StatusModel.STATUS_CONNECTED);
        case actions.Type.SOCKET_DISCONNECTED:
            return StatusModel.fromtString(StatusModel.STATUS_DISCONNECTED);
        case actions.Type.DISCONNECT_SOCKET:
            return StatusModel.fromtString(StatusModel.STATUS_DISCONNECTING);
        case actions.Type.SOCKET_CONNECTION_LOST:
            return StatusModel.fromtString(StatusModel.STATUS_CONNECTION_LOST);
        default:
            return state;
    }
};

export { reducer as statusReducer };