import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Dropdown, FormField} from "semantic-ui-react";
import {ElementsTree} from "../../../../model/ElementsTree";
import {Schema} from "../../../../service/cody-wizard/schema/schema";

interface OwnProps {
  identifier: string;
  schema: Schema;
  onIdentifierChanged: (identifier: string) => void;
  disabled?: boolean;
  identifierCandidates: string[];
}

type IdentifierSelectProps = OwnProps & WithNamespaces;

const IdentifierSelect = (props: IdentifierSelectProps) => {

  const options = props.identifierCandidates.map(c => ({key: c, text: c, value: c}));

  options.push({key: "- none -", text: "- none -", value: ""});

  return <FormField>
    <Dropdown inline={true}
              placeholder={props.t('insp.cody_wizard.identifier_select.placeholder') as string}
              value={props.identifier}
              options={options}
              disabled={options.length === 0 || props.disabled}
              onChange={(e, data) => props.onIdentifierChanged(data.value as string)}
              className={'cody-wizard-inline-select'}
    />
  </FormField>
};

export default withNamespaces()(IdentifierSelect);


export const getIdentifierCandidatesFromSchema = (schema: Schema, elementsTree?: ElementsTree): string[] => {
  const candidates: string[] = [];

  if(schema.isList()) {
    schema = schema.getListItemsSchema(new Schema({}));

    if(schema.isRef() && elementsTree) {
      schema = schema.resolveRef(elementsTree);
    }
  }

  // tslint:disable-next-line:forin
  for (const schemaKey of schema.getObjectProperties()) {
    const propSchema = schema.getObjectPropertySchema(schemaKey, Schema.fromString(''));

   if(propSchema.isString('uuid')) {
     candidates.push(schemaKey);
   }
  }

  return candidates;
}
