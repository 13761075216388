import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../index";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import {List} from "immutable";

type Command = ActionType<typeof Action.Command.importStickyTemplates>

function* handleImportStickyTemplates(command: Command) {
    const tpls = command.payload.stickyTemplates.toJS();
    const importedTpls = [];

    for (const tpl of tpls) {
        const {response, error}: ResponseType = yield call(Action.Api.setStickyTemplate,
            tpl.boardId,
            tpl.cellType,
            tpl.template
        );

        if (error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not set sticky template for ' + tpl.cellType));
            continue;
        }

        importedTpls.push(tpl);

        const advancedConfigResult: ResponseType = yield call(Action.Api.updateStickyAdvancedConfig,
            tpl.boardId,
            tpl.cellType,
            tpl.templateType,
            tpl.templateSchema || undefined
        );

        if (advancedConfigResult.error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not update sticky advanced config for ' + tpl.cellType));
        }
    }

    yield put(NotifyAction.Command.info('Card Templates imported', 'You can now use them on the board!'));
    yield put(Action.Event.stickyTemplatesImported(List(importedTpls)));
    command.payload.finishedCb();
}

export function* importStickyTemplates() {
    while (true) {
        const command: Command = yield take([
            Action.Type.IMPORT_STICKY_TEMPLATES
        ]);

        yield fork(handleImportStickyTemplates, command);
    }
}
