import {List} from "immutable";
import { map } from 'lodash';
import { call, put } from 'redux-saga/effects';
import {ResponseType} from "../../api/util";
import { execForEach, execValidate, isFailure, isSuccess, pipe, Success} from "../../core/validation/either";
import {isArray} from "../../core/validation/predicates";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as UserAction from "../../User/actions";
import {UserId} from "../../User/model/UserInfo";
import * as Action from "../actions";
import {teamsFetched} from "../actions/events";
import {Team} from "../model";
import {createTeamFromServerData} from "../model/Team";

function* loadMembersOfAllTeams(teams: List<Team.Team>) {
    let memberIds = List<UserId>();

    teams.forEach(team => {memberIds = memberIds.merge(team.members)});

    // Remove duplicate memberIds
    memberIds = memberIds.toSet().toList();

    yield put(UserAction.Query.loadUsersInfo(memberIds.toArray()));
}

export function* loadUserTeams(newTeam?: Team.TeamId) {
    const {response, error}: ResponseType = yield call(Action.Api.fetchUserTeams);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not load teams'));
    }

    if (response) {
        const result = pipe(
            response!.data,
            execValidate([isArray(), "Response data is not of type Array"]),
            execForEach((data: any) => {
                if(newTeam && data.teamId === newTeam) {
                    data.isNew = true;
                }

                return createTeamFromServerData(data);
            }),
        );

        if(isFailure(result)) {
            // tslint:disable-next-line:no-console
            console.error("Failed to process team response from server", result.value, response.data);
            yield put(NotifyAction.Command.error('Request Error', 'Could not load teams'));
        }

        if(isSuccess(result)) {
            const teams = List(result.value);
            yield put(teamsFetched(teams));
            yield call(loadMembersOfAllTeams, teams);
        }
    }
}
