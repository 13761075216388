import {List} from "immutable";
import * as React from 'react';
import {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {BoardModel} from "../../InspectioBoards/model";
import {makeGetBoards} from "../../InspectioBoards/selectors/boardList";
import {keyBoardHidden, makeUserSettingsSelector} from "../../User/selectors/userSettings";
import BoardListItem from "./BoardListItem";
import SharedBoardsItem from "./SharedBoardsItem";
import ShowMoreBoardsItem from "./ShowMoreBoardsItem";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeOrganizationSelector} from "../../Organization/selectors/selectOrganization";
import {isStandalone} from "../../standalone/util";

interface OwnProps {

}

type BoardListProps = OwnProps;

const BoardList = (props: BoardListProps) => {
    const currentUser = useSelector(makeGetCurrentUser());
    const organization = useSelector(makeOrganizationSelector(currentUser.activeOrganization));
    const isOrgaAdmin = organization? organization.isAdmin(currentUser.uid) : false;


    const [showAll, setShowAll] = useState(false);
    const getBoards = makeGetBoards(true);
    const boards = useSelector(getBoards);
    const userSettings = useSelector(makeUserSettingsSelector());
    const sharedBoardsCount = useMemo<number>(() => {
        return boards.filter(board => board.shared && !isOrgaAdmin).count();
    }, [boards]);

    const handleShowMoreClick = () => {
        setShowAll(!showAll);
    };

    let ownVisibleBoards = List<BoardModel.Board>();
    let ownHiddenBoards = List<BoardModel.Board>();

    boards
        .filter(board => !board.shared || isOrgaAdmin)
        .sortBy(board => board.name, (a, b) => a === b ? 0 : (a! < b! ? -1 : 1))
        .forEach(ownBoard => {
            if (userSettings[keyBoardHidden(ownBoard.uid)] === true) {
                ownHiddenBoards = ownHiddenBoards.push(ownBoard);
            } else {
                if(!showAll && ownVisibleBoards.count() >= 3) {
                    ownHiddenBoards = ownHiddenBoards.push(ownBoard);
                } else {
                    ownVisibleBoards = ownVisibleBoards.push(ownBoard);
                }
            }
        });

    if(showAll) {
        ownVisibleBoards = ownVisibleBoards.push(...ownHiddenBoards.toArray());
    }

    const boardListItems = ownVisibleBoards
        .map((board) => {
        return <BoardListItem key={board.uid} board={board} />
    }).toArray();

    if(ownHiddenBoards.count() > 0 || ownVisibleBoards.count() > 3) {
        boardListItems.push(<ShowMoreBoardsItem showAll={showAll} key="sidebar-show-more-boards" onClick={handleShowMoreClick} />);
    } else if (showAll) {
        setShowAll(false);
    }

    if(!isStandalone()) {
        boardListItems.push(<SharedBoardsItem count={sharedBoardsCount} key="sidebar-shared-boards"/>);
    }


    return <>{boardListItems}</>;
};

export default BoardList;
