import {axiosApiPromise, ResponseType} from '../../api/util';
import { SERVER_CONTEXT } from '../../InspectioBoards/actions/constants';
import {BoardModel} from "../../InspectioBoards/model";
import {Permissions} from "../../InspectioBoards/model/BoardPermissionForTeam";
import {OrganizationId} from "../../Organization/model/Organization";
import {Team} from "../model";
import {TeamId} from "../model/Team";
import {TeamInvitationId} from "../model/TeamInvitation";
import {BoardId} from "../../InspectioBoards/model/Board";

export const addTeam = (teamId: Team.TeamId, teamName: Team.TeamName,  organizationId: OrganizationId | null) => {
    return axiosApiPromise(SERVER_CONTEXT + 'AddTeam', { teamId, name: teamName, organizationId });
};

export const renameTeam = (teamId: Team.TeamId, name: Team.TeamName) => {
    return axiosApiPromise(SERVER_CONTEXT + 'RenameTeam', {teamId, name});
};

export const changeTeamDescription = (teamId: Team.TeamId, newDescription: Team.TeamDescription) => {
    return axiosApiPromise(SERVER_CONTEXT + 'ChangeTeamDescription', {teamId, newDescription});
};

export const inviteUserToTeam = (invitationId: string, teamId: string, userEmail: string) => {
    return axiosApiPromise(SERVER_CONTEXT + 'InviteUserToTeam', { invitationId, teamId, invitedUser: userEmail });
};

export const resendTeamInvitation = (invitationId: TeamInvitationId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'ResendTeamInvitation', {invitationId});
};

export const revokeTeamInvitation = (invitationId: TeamInvitationId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'RevokeTeamInvitation', {invitationId});
};

export const removeMemberFromTeam = (teamId: string, memberId: string) => {
    return axiosApiPromise(SERVER_CONTEXT + 'RemoveMemberFromTeam', { teamId, memberId });
};

export const deleteTeam = (teamId: string) => {
    return axiosApiPromise(SERVER_CONTEXT + 'DeleteTeam', {teamId});
};

export const adminGrantTeamAccessToBoard = (boardId: BoardModel.BoardId, teamId: Team.TeamId, permissions?: Permissions) => {
    return axiosApiPromise(SERVER_CONTEXT + 'AdminGrantTeamAccessToBoard', {
        boardId,
        teamId,
        boardPermissions: (permissions || {
            readAccess: true,
            writeAccess: true,
            adminAccess: false,
        })
    });
};

export const adminRevokeTeamAccessToBoard = (boardId: BoardModel.BoardId, teamId: Team.TeamId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'AdminRevokeTeamAccessToBoard', {
        boardId,
        teamId
    });
};

export const fetchUserTeams = () => {
    return axiosApiPromise(SERVER_CONTEXT + 'UserTeams', {});
};

export const ackTeamInvitation = (invitationId: string) => {
    return axiosApiPromise(SERVER_CONTEXT + 'AcknowledgeTeamInvitation', {invitationId})
};

export const fetchTeamOfInvitation = (invitationId: string) => {
    return axiosApiPromise(SERVER_CONTEXT + 'InvitationTeam', {invitationId});
};

export const fetchOpenTeamInvitations = (teamId: TeamId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'OpenTeamInvitations', {teamId});
};

export const moveTeamToOrganization = (teamId: TeamId, organizationId: OrganizationId) => {
    return axiosApiPromise(SERVER_CONTEXT + 'MoveTeamToOrganization', {teamId, organizationId});
}
