import * as React from 'react';
import {WithNamespaces, withNamespaces} from "react-i18next";
import {Checkbox, FormField} from "semantic-ui-react";
import {Node} from "../../../../model/Graph";

interface OwnProps {
  vo: Node | null;
  isEntity: boolean;
  onIsEntityChanged: (isEntity: boolean) => void;
  disabled?: boolean;
}

type EntityVoToggleProps = OwnProps & WithNamespaces;

const EntityVoToggle = (props: EntityVoToggleProps) => {
  const disabled = props.disabled || !props.vo;

  return <FormField disabled={disabled} inline={true}>
    <Checkbox value={1}
              checked={props.isEntity}
              disabled={disabled}
              toggle={true}
              onChange={() => props.onIsEntityChanged(!props.isEntity)}
              label={props.t('insp.cody_wizard.step_any_vo.is_entity_label') as string} />
  </FormField>
};

export default withNamespaces()(EntityVoToggle);
