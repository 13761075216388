import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../index";
import BoardsStorageManager from "../storage/BoardsStorageManager";
import {useBoardsStorageManager} from "../storage/useBoardsStorageManager";
import {AccordionContent} from "semantic-ui-react";

type Command = ActionType<typeof Action.Command.sortBoardOverview> | ActionType<typeof Action.Command.filterBoardOverviewByResponsibleTeam>;

function* handleSortBoardOverview(command: ActionType<typeof Action.Command.sortBoardOverview>, manager: BoardsStorageManager) {
    yield manager.setOverviewSorting(command.payload.sort);
}

function* handleFilterByResponsbileTeam(command: ActionType<typeof Action.Command.filterBoardOverviewByResponsibleTeam>, manager: BoardsStorageManager) {
    yield manager.setOverviewResponsibleTeam(command.payload.teamId);
}

export function* storeOverviewSettingsFlow() {
    const manager = useBoardsStorageManager();

    const sort = yield call(manager.getOverviewSorting);
    const team = yield call(manager.getOverviewResponsibleTeam);

    yield put(Action.Command.sortBoardOverview(sort));
    yield put(Action.Command.filterBoardOverviewByResponsibleTeam(team));

    while (true) {
        const command: Command = yield take([
            Action.Type.SORT_BOARD_OVERVIEW,
            Action.Type.FILTER_BOARD_OVERVIEW_BY_RESPONSIBLE_TEAM
        ]);

        if(command.type === Action.Type.SORT_BOARD_OVERVIEW && command.payload.sort !== sort) {
            yield fork(handleSortBoardOverview, command, manager);
        }

        if(command.type === Action.Type.FILTER_BOARD_OVERVIEW_BY_RESPONSIBLE_TEAM && command.payload.teamId !== team) {
            yield fork(handleFilterByResponsbileTeam, command, manager);
        }
    }
}
