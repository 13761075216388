import {func} from "prop-types";
import { buffers, eventChannel, EventChannel } from 'redux-saga';
import { call, cancel, delay, fork, put, race, select, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { default as socket } from '../../api/ConfiguredSocket';
import * as Auth from '../../Auth';
import { Action, Type } from '../actions';
import {
    connectSocket as connectSocketActionFactory, disconnectSocket as disconnectSocketActionFactory,
    listenOnSocketChannel,
    sendSocketCmd,
    stopListeningOnSocketChannel,
} from '../actions/actions';
import * as Socket from "../index";

interface ChannelRegistry {
    [key: string]: EventChannel<any>
}

type AuthFlowAction = ActionType<typeof Auth.Action.Event.userAuthenticated>;

type ConnectAction = ActionType<typeof connectSocketActionFactory>
    | ActionType<typeof disconnectSocketActionFactory>
    ;

export type SocketFlowAction = ActionType<typeof sendSocketCmd>
    | ActionType<typeof listenOnSocketChannel>
    | ActionType<typeof stopListeningOnSocketChannel>
    ;

export function* manageSocketConnection() {
    const authAction: AuthFlowAction = yield take([
        Auth.Action.Type.USER_AUTHENTICATED,
    ]);
    yield fork(flow);
}

function* flow() {
    console.log("[Socket Flow] Auto connect.");
    yield call(connectSocket);

    try {
        while (true) {
            const action: ConnectAction = yield take([Type.CONNECT_SOCKET, Type.DISCONNECT_SOCKET]);

            switch (action.type) {
                case Type.CONNECT_SOCKET:
                    console.log("[Socket Flow] Received ConnectSocket command.");
                    const success = yield call(connectSocket);

                    // success can also be NULL, which indicates that a socket connection exists, so we don't need to start background tasks
                    if (success === true || success === null) {
                        // Always answer with socket connected event, even in case socket connection exists already
                        yield put(Action.socketConnected());

                        if(success === true) {
                            // New socket connection established, so we need to start background tasks to handle IO
                            yield fork(runBackgroundTasks);
                        }
                    } else if (success === false) {
                        console.warn("[Socket Flow] ConnectSocket command failed. Calling socket.disconnect now.");
                        yield call([socket, socket.disconnect]);
                    }
                    break;
                case Type.DISCONNECT_SOCKET:
                    console.log("[Socket Flow] Received DisconnectSocket command.");
                    yield call([socket, socket.disconnect]);
                    break;

            }

        }
    } finally {
        yield put(Action.socketConnectionLost('Socket saga cancelled'));
    }

}

function* runBackgroundTasks(): any {
    console.log("[Socket Flow] Running backgound tasks: listenDisconnectSaga, listenReconnectSaga, handleIO");

    const disconnectTask = yield fork(listenDisconnectSaga);
    const reconnectTask = yield fork(listenReconnectSaga);
    const ioTask = yield fork(handleIO);

    // wait for disconnecting action
    const action: SocketFlowAction = yield take([Type.DISCONNECT_SOCKET]);

    console.warn("[Socket Flow] Received DisconnectSocket command. Canceling backgound tasks.");

    yield cancel(disconnectTask);
    yield cancel(reconnectTask);
    yield cancel(ioTask);
}

function* onHandleIO(action: SocketFlowAction, channelRegistry: ChannelRegistry) {
    console.log("[Socket Flow] Received action ", action);

    switch (action.type) {
        case Type.SEND_SOCKET_CMD:
            const socketStatus: Socket.Model.StatusModel.Status = yield select(Socket.Selector.StatusSelector.makeGetStatus());

            if(socketStatus.isConnected()) {
                yield call(sendCmd, action);
            } else {
                console.warn("[Socket Flow] Cannot send socket command: ", action.payload, "Socket is not connected");
            }
            break;
        case Type.LISTEN_ON_SOCKET_CHANNEL:
            if (channelRegistry.hasOwnProperty(action.payload.channelName)) {
                stopListeningOnChannel(action.payload.channelName, channelRegistry);
            }
            yield fork(listenOnChannel, action, channelRegistry);
            break;
        case Type.STOP_LISTENING_ON_SOCKET_CHANNEL:
            stopListeningOnChannel(action.payload.channelName, channelRegistry);
            break;
        default:
        // ignore
    }
}

function* handleIO() {
    const channelRegistry: ChannelRegistry = {};

    while (true) {
        const action: SocketFlowAction = yield take([Type.SEND_SOCKET_CMD, Type.LISTEN_ON_SOCKET_CHANNEL, Type.STOP_LISTENING_ON_SOCKET_CHANNEL]);

        yield fork(onHandleIO, action, channelRegistry);
    }
}

const listenOnChannel = function* (cmd: ActionType<typeof Action.listenOnSocketChannel>, registry: ChannelRegistry) {
    console.log("[Socket Flow] Start listening on channel: ", cmd.payload.channelName);

    const chan = eventChannel((emit) => {
        const handler = (data: any) => {
            emit(data);
        };

        const errorHandler = (err: Error) => {
            if (typeof err === 'undefined') {
                emit(new Error('Unknown error occurred.'));
                return;
            }
            // create an Error object and put it into the channel
            emit(new Error(err.message));
        };

        socket.on(cmd.payload.channelName, handler);

        socket.on('connect_error', errorHandler);
        socket.on('error', errorHandler);

        return () => {
            console.log("[Socket Flow] stop listening on channel, because redux-saga event channel was closed: ", cmd.payload.channelName);
            socket.off(cmd.payload.channelName);
        };
    }, buffers.expanding(10));

    registry[cmd.payload.channelName] = chan;

    while (true) {
        try {
            const event = yield take(chan);
            yield put(Action.emitChannelEvent(cmd.payload.emitActionType, event, cmd.payload.metadata));
        } catch (e) {
            chan.close();
            yield put(Action.socketConnectionLost(e.message));
            return;
        }
    }
};

const connectSocket = (): Promise<boolean|null> => {
    return new Promise<boolean|null>((resolve) => {
        if(socket.connected) {
            resolve(null);
            return;
        }

        socket.on('connect', () => resolve(true));
        socket.open();
    }).then(
        response => response,
    ).catch(
        error => false,
    );
};

const reconnectSocket = (): Promise<boolean> => {
    return new Promise((resolve) => {
        socket.io.on('reconnect', () => {
            resolve(true);
        });
    });
};

const disconnectSocket = (): Promise<string> => {
    return new Promise((resolve) => {
        socket.on('disconnect', (reason: string) => {
            resolve(reason);
        });
    });
};

// connection monitoring sagas
const listenDisconnectSaga = function* () {
    while (true) {
        const reason = yield call(disconnectSocket);
        console.warn("[Socket Flow] Socket connection lost: ", reason);
        yield put(Action.socketConnectionLost(reason));
    }
};

const listenReconnectSaga = function* () {
    while (true) {
        yield call(reconnectSocket);
        console.log("[Socket Flow] socket reconnected.");

        // Wait a moment before telling the app that the socket is available again.
        // This is needed, because otherwise the socket can still be flagged as disconnected
        // if the saga receives new cmds too fast
        yield delay(100);
        yield put(Action.socketConnected());
    }
};


const stopListeningOnChannel = (channelName: string, registry: ChannelRegistry) => {
    if (registry.hasOwnProperty(channelName)) {
        console.log("[Socket Flow] Stop listening on channel: ", channelName);
        registry[channelName].close();
        delete registry[channelName];
    }
};

const sendCmd = function* <D>(cmd: ActionType<typeof Action.sendSocketCmd>) {

    yield put(Action.notifySocketCmdSent(cmd.payload.responseActionType, {
        name: cmd.payload.name,
        type: 'pending',
        payload: cmd.payload.data,
    }, cmd.payload.metadata));

    try {
        const token = yield select(Auth.Selector.AuthSelector.makeTokenSelector());

        const {ack, timeout} = yield race<any>({
            ack: call(pushCmd, {
                name: cmd.payload.name,
                type: 'pending',
                payload: {
                    ...cmd.payload.data,
                    token,
                },
            }),
            timeout: delay(cmd.payload.timeout),
        });

        if (ack) {
            yield put(Action.ackSocketCmd(cmd.payload.responseActionType, {
                name: cmd.payload.name,
                type: 'success',
                payload: cmd.payload.data,
            }, cmd.payload.metadata));
        }

        if (timeout) {
            yield put(Action.rejectSocketCmd(cmd.payload.responseActionType, {
                name: cmd.payload.name,
                type: 'error',
                payload: cmd.payload.data,
                reason: 'Timed out after ' + cmd.payload.timeout + ' ms',
            }, cmd.payload.metadata));
        }
    } catch (e) {
        yield put(Action.rejectSocketCmd(cmd.payload.responseActionType, {
            name: cmd.payload.name,
            type: 'error',
            payload: cmd.payload.data,
            reason: e.toString(),
        }, cmd.payload.metadata));
    }


};

const pushCmd = <D>(cmd: Action.SocketCmd<D>): Promise<true> => {
    return new Promise((resolve, reject) => {
        try {
            if (socket.disconnected) {
                reject(new Error('Socket not connected'));
                return;
            }
            socket.emit(cmd.name, cmd.payload, () => resolve(true));
        } catch (e) {
            reject(e);
        }
    });
};

