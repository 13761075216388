import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Message, Modal} from "semantic-ui-react";
import {makeActiveUserOrganizationInfoSelector} from "../../Organization/selectors/selectUserOrganizationInfo";

interface OwnProps {
    open: boolean;
    onClose: () => void;
}

type BoardQuotaReachedModalProps = OwnProps & WithNamespaces;

const BoardQuotaReachedModal = (props: BoardQuotaReachedModalProps) => {
    const activeOrganization = useSelector(makeActiveUserOrganizationInfoSelector());


    return <Modal open={props.open} closeIcon={true} onClose={props.onClose}>
        <Modal.Header>{ props.t("insp.board.add") }</Modal.Header>
        <Modal.Content>
            <Message icon="dollar" warning={true}
                     content={
                         activeOrganization && activeOrganization.userIsGuest
                             ? props.t('insp.user.quota_reached_guest')
                             : activeOrganization && activeOrganization.explicitOrganization
                                ? props.t('insp.user.quota_reached')
                                : props.t('insp.user.quota_reached_user')
                     } />
        </Modal.Content>
    </Modal>
};

export default withNamespaces()(BoardQuotaReachedModal);
