import * as React from 'react';
import {Label} from "semantic-ui-react";
import {DEFAULT_COLOR, ERROR_COLOR, WARNING_COLOR} from "./definitions";
// tslint:disable-next-line:no-var-requires
const iioAvatar = require('../../../theme/img/inspect-avatar.png');

interface OwnProps {
    message: string;
    type?: 'default' | 'error' | 'warning';
}

type InspectIoMessageProps = OwnProps;

const InspectIoMessage = (props: InspectIoMessageProps) => {

    let msgColor = DEFAULT_COLOR;
    let fontWeight: 'normal' | 'bold' = 'normal';
    let backgroundColor = 'white';

    if(props.type === 'error') {
        msgColor = ERROR_COLOR;
        fontWeight = 'bold';
        backgroundColor = '#f1bec5';
    }

    if(props.type === 'warning') {
        msgColor = WARNING_COLOR;
        fontWeight = 'bold';
    }

    return <div className="console-message" style={{backgroundColor}}>
        <Label circular={true} className="user console-avatar" style={{backgroundImage: `url(${iioAvatar})`, backgroundSize: 'cover', marginLeft: '3px'}} />
        <div className="console-text">
            <span style={{color: msgColor, fontWeight}}>{props.message}</span>
        </div>
    </div>;
};

export default InspectIoMessage;
