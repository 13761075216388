import {fromJS} from "immutable";
import {SyntheticEvent, useState} from "react";
import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {reset} from "redux-form";
import {Container, Dropdown, Grid, Header, Message} from 'semantic-ui-react';
import {useOnBodyClick} from "../../core/hooks/useOnBodyClick";
import {UserProfileMatchParams} from "../../InspectioBoards/components/types";
import { Action as NotifyAction } from '../../NotificationSystem/index';
import * as Routes from '../../routes';
import {updatePassword, updateProfile} from "../actions/commands";
import {Api} from "../actions/index";
import {makeGetCurrentUser} from "../selectors/currentUser";
import UserAvatarUploadModal, {CropData} from "./UserAvatarUploadModal";
import {default as UserProfileEditForm, IFormData} from './UserProfileEditForm';
import {default as UserProfilePasswordForm, ImFormData} from './UserProfilePaswordForm';

interface PasswordObject{
    password1: string,
    password2: string,
}

interface OwnProps {
}


export type UserProfileProps = OwnProps & WithNamespaces & RouteComponentProps<UserProfileMatchParams>;


const UserProfile = (props: UserProfileProps) => {

    const dispatch = useDispatch();
    const [showAvatarActions, setShowAvatarActions] = useState<boolean>(false);
    const [showAvatarUploadModal, setShowAvatarUploadModal] = useState<boolean>(false);
    const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState<boolean>(false);
    const currentUser = useSelector(makeGetCurrentUser());

    useOnBodyClick(() => {
        setShowAvatarActions(false);
    });

    const submitForm = (data: IFormData, cProps: UserProfileProps): void => {
        const user = currentUser!.merge(data.toJS());
        dispatch(updateProfile(user, () => cProps.history.push(Routes.inspectio)));
    };

    const uploadAvatar = (file: any, crop: CropData) => {
        const data = new FormData();
        data.append('avatar_file', file);
        data.append('crop', JSON.stringify(crop));

        Api.uploadAvatar(currentUser.uid,data)
            .then(() => {
                updateProfileWithNewAvatarUrl();
            })
            .catch(() => {
                dispatch(NotifyAction.Command.error(
                    props.t('insp.user.avatar_upload.upload_failed_title'),
                    props.t('insp.user.avatar_upload.upload_failed_text')
                ));
            })
            .finally(() => {
                setShowAvatarUploadModal(false);
                setShowAvatarActions(false);
            });
    };

    const deleteAvatar = () => {
        Api.deleteAvatar(currentUser.uid)
            .then(() => {
                updateProfileWithNewAvatarUrl();
            })
            .catch(() => {
                dispatch(NotifyAction.Command.error(
                    props.t('insp.user.avatar_upload.deletion_failed_title'),
                    props.t('insp.user.avatar_upload.deletion_failed_text')
                ));
            })
            .finally(() => {
                setShowAvatarActions(false);
            })
    };

    /**
     * Updates the profile avatar url by changing the timestamp to prevent caching issues
     */
    const updateProfileWithNewAvatarUrl = () => {
        dispatch(updateProfile(
            currentUser!.merge({
                avatarUrl: '/avatar/' + currentUser.uid +
                    '?displayName=' + encodeURI(currentUser.displayName) + '&timestamp=' + Date.now(),
            }),
            () => { return; }
        ));
    };

    const submitFormPassword = (data: ImFormData): void => {
        const defaultPwdSet = {
            password1: "",
            password2: "",
        };

        const pws = {...defaultPwdSet, ...data.toJS()};

        if (pws.password1 === pws.password2) {
            setShowPasswordErrorMessage(false);
            dispatch(updatePassword(pws.password1));
            dispatch(reset('UserProfilePasswordForm'));
        } else {
            setShowPasswordErrorMessage(true);
        }
    };

    return <Container>
        <Grid stackable={true}>
            <Grid.Row>
                <Grid.Column width="2" className="tabletHidden" />
                <Grid.Column mobile="16" tablet="16" computer="12">
                    <Header as="h1" icon={true} textAlign='center'>
                        <Dropdown open={showAvatarActions} icon={false}>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    text={props.t('app.form.upload')}
                                    onClick={() => setShowAvatarUploadModal(true)}
                                />
                                <Dropdown.Item
                                    text={props.t('app.form.delete')}
                                    onClick={deleteAvatar}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                        <img
                            style={{ cursor: 'pointer' }}
                            onClick={(e: SyntheticEvent) => {e.stopPropagation(); setShowAvatarActions(!showAvatarActions);}}
                            className={"ui circular image"}
                            src={currentUser.avatarUrl}
                        />
                        <Header.Content>
                            {props.t('insp.user.profile_headline', {user_name: currentUser.displayName})}
                            <Header.Subheader>{ props.t("insp.user.profile_subline")}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width="2" className="tabletHidden" />
                <Grid.Column mobile="16" tablet="16" computer="12">
                    <div className="ui segments">
                        <div className="ui segment">
                            <UserProfileEditForm
                                initialValues={fromJS(currentUser.toJS())}
                                onSubmit={(data) => {submitForm(data, props)}}
                            />
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>

        <Grid stackable={true}>
            <Grid.Row>
                <Grid.Column width="2" className="tabletHidden" />
                <Grid.Column mobile="16" tablet="16" computer="12">
                    <div className="ui segments">
                        <div className="ui segment">
                            <UserProfilePasswordForm
                                initialValues={fromJS({password1: "",password2: ""})}
                                onSubmit={(data) => {submitFormPassword(data)}}
                                showErrorMessage={showPasswordErrorMessage}
                            />
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>

        <Grid stackable={true}>
            <Grid.Row>
                <Grid.Column width="2" className="tabletHidden" />
                <Grid.Column mobile="16" tablet="16" computer="12">
                    <div className="ui segments">
                        <div className="ui segment">
                            <Message>
                                <Message.Header>{props.t('insp.user.delete_account.headline')}</Message.Header>
                                <Message.Content>
                                    {props.t('insp.user.delete_account.message')}&nbsp;<a
                                  href={"mailto:support@prooph-board.com?subject=Please delete my account&body=Hi, can you please delete my account: " + currentUser.uid + ". Thank you, " + currentUser.displayName}>support@prooph-board.com</a>
                                </Message.Content>
                            </Message>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
       
        <UserAvatarUploadModal
            t={props.t}
            open={showAvatarUploadModal}
            onClose={() => {
                setShowAvatarUploadModal(false);
                setShowAvatarActions(false);
            }}
            onUpload={uploadAvatar}
        />
    </Container>
};

export default withNamespaces()(withRouter(UserProfile));

