import * as React from 'react';
import {FormField, Label} from "semantic-ui-react";
import EditableTextline from "../../../../../core/components/EditableTextline";
import {ADDITIONAL_CHAR_PADDING, CHAR_SIZE_UNIT, INPUT_CHAR_SIZE} from "../../WizardModal";

interface OwnProps {
  ns: string;
  onNsChanged: (ns: string) => void;
  disabled?: boolean;
}

type NamespaceInputProps = OwnProps;

const NamespaceInput = (props: NamespaceInputProps) => {

  const handleTextChanged = (text: string) => {
    if(text[0] !== '/') {
      text = '/' + text;
    }

    props.onNsChanged(text);
  }

  const style = props.ns === '' ? {color: 'rgba(51, 51, 51, 0.6)'} : undefined;

  return <FormField>
    <EditableTextline
      required={true}
      text={props.ns}
      minWidth={20}
      charSize={INPUT_CHAR_SIZE}
      widthUnit={CHAR_SIZE_UNIT}
      padding={ADDITIONAL_CHAR_PADDING}
      placeholder='/Namespace'
      textStyle={style}
      disabled={props.disabled}
      onTextChanged={handleTextChanged} />
  </FormField>
};

export default NamespaceInput;
