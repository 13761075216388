import {List} from "immutable";
import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {FormField, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {Node} from "../../../model/Graph";
import {ImmutableDDDWizardContext} from "../../../service/cody-wizard/context/ddd-action";
import {ImmutableInformationContext} from "../../../service/cody-wizard/context/information-context";
import {getDefaultServiceName} from "../../../service/cody-wizard/node/detect-service";
import {fqcn} from "../../../service/cody-wizard/node/fqcn";
import {ProjectionConfig} from "../../../service/cody-wizard/vo/get-vo-metadata";
import ExpandButton from "./ExpandButton";
import {makeProjectionSchema} from "./Validation/projection-schema";

interface OwnProps {
  projection: ProjectionConfig;
  possibleEvents: List<Node>;
  possibleServices: List<Node>;
  ctx: ImmutableDDDWizardContext | ImmutableInformationContext;
  onProjectionChanged: (projection: ProjectionConfig | undefined) => void;
  autofocus?: boolean;
  readonly?: boolean;
  sessionId?: string;
}

type ProjectionEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};

const ProjectionEditor = (props: ProjectionEditorProps) => {
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [editorExpanded, setEditorExpanded] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let value = JSON.stringify(props.projection, null, 2);
    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    const possibleServices = ["AuthService"];

    props.possibleServices.forEach(n => {
      const name = fqcn(n, getDefaultServiceName(props.ctx));

      if(!possibleServices.includes(name)) {
        possibleServices.push(name);
      }
    })

    codeEditorRef.current!.initializeModel({
      fileId: `projection.json`,
      language: "json",
      value,
      schema: makeProjectionSchema(
        props.possibleEvents.map(e => e.getName()).toArray(),
        possibleServices,
        props.ctx
      ),
    });

    setInvalidJson(isInvalidJson);
  }, [props.sessionId, props.projection, props.possibleEvents, props.possibleServices]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {
    if(editorStr === '') {
      props.onProjectionChanged(undefined);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedProjection = JSON.parse(editorStr);
      props.onProjectionChanged(changedProjection);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true;
    } catch (e) {
      console.error("[CodyWizard] Projection Editor invalid JSON: ", e);
      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorExpanded} onExpandChanged={expand => setEditorExpanded(expand)}/>
    <CodeEditor
      containerId={'cody-projection-editor'}
      ref={codeEditorRef}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorExpanded ? ' expanded' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedProjectionStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedProjectionStr);

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedProjectionStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(ProjectionEditor);
