import { cond, forEach } from "lodash";
//import {Device, OpenVidu, Publisher} from "openvidu-browser";
import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Button, DropdownProps, Form, Modal, Select} from "semantic-ui-react";
import { setCodyConsoleHeight } from "../../../Layout/actions/commands";
import {JitsiDevice as Device, JitsiTrack as Track} from "./JitsiMeetInterfaces";

interface OwnProps {
    onDevicesSelected: (devices: {audio: string, video: string}) => void;
    onCancel: () => void;
    onNameChange: (name: string) => void;
    open: boolean;
    loadingMeetingRoom: boolean;
    name: string;
    usageHints: string[];
}

type JoinMeetingDialogProps = OwnProps & WithNamespaces;

const randomIntFromInterval = (min: number, max: number) => { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const JoinMeetingDialog = (props: JoinMeetingDialogProps) => {
    /* 
    const openViduRef = useRef<OpenVidu>();
    const publisherRef = useRef<Publisher>();
    */
    const videoRef = useRef<HTMLVideoElement|null>(null);
    const [videoDevices, setVideoDevices] = useState<Device[]>([]);
    const [audioDevices, setAudioDevices] = useState<Device[]>([]);
    const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>('');
    const [selectedAudioDevice, setSelectedAudioDevice] = useState<string>('');

    // Jitsi - Start
    const JitsiMeetJS = (window as any).JitsiMeetJS;
    JitsiMeetJS.init({flags:{sendMultipleVideoStreams:true, sourceNameSignaling:true}});

    const audioRef = useRef<HTMLAudioElement|null>(null);
    const [newDeviceList, setNewDeviceList] = useState<Device[]>([]);
    const [localTrackMuted, setLocal] = useState<boolean|undefined>(true);
    const localAudioTrack = useRef<Track>();
    const localVideoTrack = useRef<Track>();
    const [localVideoSignal, setLocalVideoSignal] = useState<boolean>(false);

    const [customName, setCustomName] = useState<string>(props.name);
    const [isAllowedToJoin, setIsAllowedToJoin] = useState<boolean>(false);

    useEffect(() => {
        let tmpSelectedVideoDevice:Device|undefined;
        let tmpSelectedAudioDevice:Device|undefined;
        let tmpDeviceList:Device[] = [];

        JitsiMeetJS.createLocalTracks({devices:['video', 'audio'], maxFps:30, facingMode:'user'})
            .then((tracks: Track[]) => {
                for (let track of tracks){
                    track.dispose()
                }

                JitsiMeetJS.mediaDevices.enumerateDevices((devices: any) => {
                    for (let device of devices) {
                        tmpDeviceList.push({ label: device.label, deviceId: device.deviceId, kind: device.kind })
                    }
                    console.log("devicelist Start", tmpDeviceList); 
                    
                    const tmpSetVideoDevices = tmpDeviceList.filter(device => device.kind === 'videoinput');
                    tmpSetVideoDevices.push({deviceId: "no_camera_selected", label: "Join without a camera!", kind:"videoinput"});
                    const tmpSetAudioDevices = tmpDeviceList.filter(device => device.kind === 'audioinput');
                    if(tmpSetAudioDevices.length < 1){
                        tmpSetAudioDevices.push({deviceId: "no_microphone_detected", label: "You must connect a microphone to join!", kind:"audioinput"});
                    }
                    setVideoDevices(tmpSetVideoDevices);
                    setAudioDevices(tmpSetAudioDevices);
        
                    setNewDeviceList(tmpDeviceList);
                    setSelectedVideoDevice(tmpSetVideoDevices[0].deviceId);
                    setSelectedAudioDevice(tmpSetAudioDevices[0].deviceId);
                })
            });
    }, []);

    useEffect(() => {
        JitsiMeetJS.mediaDevices.addEventListener(JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, updateDeviceList);
        ///JitsiMeetJS.mediaDevices.addEventListener(JitsiMeetJS.events.mediaDevices.PERMISSION_PROMPT_IS_SHOWN, updateDeviceList);
        return () => { 
            JitsiMeetJS.mediaDevices.removeEventListener(JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, updateDeviceList);
            //JitsiMeetJS.mediaDevices.removeEventListener(JitsiMeetJS.events.mediaDevices.PERMISSION_PROMPT_IS_SHOWN, updateDeviceList);
        }
    }, [])

    const unload = () => {
        localVideoTrack.current ? localVideoTrack.current.dispose() : null ;
        localAudioTrack.current ? localAudioTrack.current.dispose() : null ;
    }
    window.addEventListener('beforeunload', unload);
    window.addEventListener('unload', unload);
    const closeModal = () => {
        unload;
        props.onCancel;
    }

    const updateDeviceList = () => {
        console.log("devicelistchanged");
        let tmpDeviceList:Device[] = [];
        JitsiMeetJS.mediaDevices.enumerateDevices((devices: any) => {
            for (let device of devices) {
                tmpDeviceList.push({ label: device.label, deviceId: device.deviceId, kind: device.kind })
            }
            const tmpSetVideoDevices = tmpDeviceList.filter(device => device.kind === 'videoinput');
            tmpSetVideoDevices.push({deviceId: "no_camera_selected", label: "Join without a camera!", kind:"placeholder"});
            const tmpSetAudioDevices = tmpDeviceList.filter(device => device.kind === 'audioinput');
            if(tmpSetAudioDevices.length < 1){
                tmpSetAudioDevices.push({deviceId: "no_microphone_detected", label: "You must connect a microphone to join!", kind:"mic_placeholder"});
            }
            setVideoDevices(tmpSetVideoDevices);
            setAudioDevices(tmpSetAudioDevices);
            setNewDeviceList(tmpDeviceList);
            if( (!tmpSetVideoDevices.find(devices => devices.deviceId === selectedVideoDevice))  || 
                (selectedVideoDevice == "no_camera_selected" && tmpSetVideoDevices.length > 1)) {
                setSelectedVideoDevice(tmpSetVideoDevices[0].deviceId)
            }
            if( (!tmpSetAudioDevices.find(audiotrack => audiotrack.deviceId === selectedAudioDevice))  || 
                (selectedAudioDevice == "no_microphone_detected" && tmpSetAudioDevices.length > 1)) {
                setSelectedAudioDevice(tmpSetAudioDevices[0].deviceId)
            }
        });
    }

    const firstUpdate = useRef(0);
    const isUpdating = useRef(false);
    useEffect(() => {
        if (firstUpdate.current < 2 || isUpdating.current ) {
            firstUpdate.current = firstUpdate.current + 1;
            console.log("try try return");
            return;
        }
        // isUpdating.current = true;
        // set new track
        if(localVideoTrack.current == undefined && selectedVideoDevice!=''){
            if(selectedVideoDevice != "no_camera_selected"){
                JitsiMeetJS.createLocalTracks({devices:['video'], cameraDeviceId:selectedVideoDevice, maxFps:30, facingMode:'user'})
                .then((tracks: Track[]) => {
                    localVideoTrack.current = tracks[0];
                    tracks[0].attach(videoRef.current!);
                    setLocalVideoSignal(true);
                    //console.log("try in new track");
                    // TEST
                    localVideoTrack.current!.detach(videoRef.current!);
                    localVideoTrack.current!.dispose();
                    localVideoTrack.current = undefined;
                    JitsiMeetJS.createLocalTracks({devices:['video'], cameraDeviceId:selectedVideoDevice, maxFps:30, facingMode:'user'})
                    .then((tracks: Track[]) => {
                        localVideoTrack.current = tracks[0];
                        tracks[0].attach(videoRef.current!);
                        setLocalVideoSignal(true);
                        //console.log("try in replace track");
                    }).catch(() => {
                        setLocalVideoSignal(false);
                        //console.log("ERROR try in replace track");
                    })
                    // TEST
                }).catch(() => {
                    setLocalVideoSignal(false);
                    //console.log("ERROR try in new track");
                });
            } else { setLocalVideoSignal(false); }
        }
        if(localAudioTrack.current == undefined && selectedAudioDevice!=''){
            if(selectedAudioDevice != "no_microphone_detected"){
                JitsiMeetJS.createLocalTracks({devices:['audio'], micDeviceId:selectedAudioDevice, maxFps:30, facingMode:'user'})
                .then((tracks: Track[]) => {
                    localAudioTrack.current = tracks[0];
                    tracks[0].attach(audioRef.current!);
                    setIsAllowedToJoin(true);
                    // TEST
                    localAudioTrack.current!.detach(audioRef.current!);
                    localAudioTrack.current!.dispose();
                    JitsiMeetJS.createLocalTracks({devices:['audio'], micDeviceId:selectedAudioDevice})
                    .then((tracks: Track[]) => {
                        localAudioTrack.current = tracks[0];
                        tracks[0].attach(audioRef.current!);
                        setIsAllowedToJoin(true);
                    }).catch (() => {
                        setIsAllowedToJoin(false);
                    })
                    // TEST END
                }).catch(() => {
                    setIsAllowedToJoin(false);
                })
            } else { setIsAllowedToJoin(false); }
        }
        // replace already set track
        if(localVideoTrack.current && selectedVideoDevice!='' &&  selectedVideoDevice != localVideoTrack.current!.deviceId){
            if(selectedVideoDevice == "no_camera_selected"){
                localVideoTrack.current!.detach(videoRef.current!);
                localVideoTrack.current!.dispose();
                localVideoTrack.current = undefined;
                setLocalVideoSignal(false);
                //console.log("try in replace track without camera");
            } else {
                localVideoTrack.current!.detach(videoRef.current!);
                localVideoTrack.current!.dispose();
                localVideoTrack.current = undefined;
                JitsiMeetJS.createLocalTracks({devices:['video'], cameraDeviceId:selectedVideoDevice, maxFps:30, facingMode:'user'})
                .then((tracks: Track[]) => {
                    localVideoTrack.current = tracks[0];
                    tracks[0].attach(videoRef.current!);
                    setLocalVideoSignal(true);
                    //console.log("try in replace track");
                }).catch (() => {
                    setLocalVideoSignal(false);
                    //console.log("ERROR try in replace track");
                })
            }
        }
        if(localAudioTrack.current && selectedAudioDevice!='' && selectedAudioDevice != localAudioTrack.current!.deviceId){
            if(selectedAudioDevice == "no_microphone_detected"){
                localAudioTrack.current!.detach(audioRef.current!);
                localAudioTrack.current!.dispose();
                localAudioTrack.current = undefined;
                setIsAllowedToJoin(false);
            } else {
                localAudioTrack.current!.detach(audioRef.current!);
                localAudioTrack.current!.dispose();
                localAudioTrack.current = undefined;
                JitsiMeetJS.createLocalTracks({devices:['audio'], micDeviceId:selectedAudioDevice})
                .then((tracks: Track[]) => {
                    localAudioTrack.current = tracks[0];
                    tracks[0].attach(audioRef.current!);
                    setIsAllowedToJoin(true);
                }).catch (() => {
                    setIsAllowedToJoin(false);
                })
            }
        }
        // isUpdating.current = false;
    }, [selectedVideoDevice, selectedAudioDevice])

    

    const videoDeviceChoices =  videoDevices.map(device => {
        return {key: /* "video_" + */ device.deviceId, value: device.deviceId, text: device.label}
    });;

    const audioDeviceChoices = audioDevices.map(device => {
        return {key: /* "audio_" + */ device.deviceId, value: device.deviceId, text: device.label}
    }); 
    
    const joinMeeting = () => {
        if (props.loadingMeetingRoom) {
            return;
        }

        props.onDevicesSelected({audio: selectedAudioDevice, video: localVideoSignal? selectedVideoDevice : "no_camera_selected"});
    };

    const alertNoMic = () => {
        alert("A microphone must be connected to join the video conference.")
    }

    const toggleLocalTrackMuted = () => {
        setLocal(!localTrackMuted);
    };

    const changeName = (event:any) => {
        //props.onNameChange(event.target.value);
        console.log("jitsitestfunction ", props.name);
        console.log("jitsitestfunction ", event);
        console.log("jitsitestfunction ", event.target);
        setCustomName(event.target.value);
        props.onNameChange(event.target.value);
    }

    const borderRadius = Math.round(150 / 2);

    const hintIndex = randomIntFromInterval(0, props.usageHints.length - 1);

    return (
        <Modal open={props.open} onClose={closeModal} closeIcon={true}>
            <Modal.Header>Join Meeting</Modal.Header>
            <Modal.Content>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Form>
                        <Form.Field>
                            <label>Camera</label>
                            <Select
                                required={true}
                                value={selectedVideoDevice}
                                options={videoDeviceChoices}
                                onChange={(e, data) => setSelectedVideoDevice(data.value as string)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Microphone</label>
                            <Select
                                required={true}
                                value={selectedAudioDevice}
                                options={audioDeviceChoices}
                                onChange={(e, data) => setSelectedAudioDevice(data.value as string)}
                            />
                        </Form.Field>
                        <Button
                            disabled={selectedAudioDevice == 'no_microphone_detected'}
                            children={localTrackMuted ? 'test microphone' : 'stop testing' }
                            onClick={toggleLocalTrackMuted}
                            color={localTrackMuted ? 'facebook' : 'youtube'}
                        />
                    </Form>
                    <div /* style={{
                                width: 150,
                                height: 150,
                                borderRadius,
                                overflow: 'hidden',
                                backgroundColor: '#9cc8f5',
                                pointerEvents: 'auto',
                                ...(!localTrackMuted ? {border: '5px solid #9cc8f5'} : {border: '5px solid #666666'} ),
                                WebkitTransform: 'translateZ(0)',
                                }} */>
                        <div >
                            {localVideoSignal && <video
                                autoPlay={true}
                                height={150}
                                muted={true}
                                ref={videoRef}
                            />}
                            {!localVideoSignal && <p style={{height:150, width:150, textAlign:"center"}}>
                                <br></br><br></br><br></br>
                                No camera signal!
                                <br></br>
                            </p>}
                            <audio 
                                autoPlay={true}
                                muted={localTrackMuted}
                                ref={audioRef}
                            />
                        </div>
                        <div>
                            <Form>
                                <Form.Field>
                                        <input type="text" name="Name" value={customName} onChange={changeName}/>
                                </Form.Field>
                            </Form>
                        </div>
                    </div>
                </div>
                {props.usageHints.length && props.usageHints[hintIndex] && <div style={{display: "flex", flexDirection: "row", paddingTop: "40px",}}>
                    💡 <i>Hint: {props.usageHints[hintIndex]}</i>
                </div>}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    children={props.t('app.form.cancel')}
                    onClick={props.onCancel}
                />
                <Button
                    loading={props.loadingMeetingRoom}
                    primary={true}
                    children={'Join'}
                    onClick={isAllowedToJoin ? joinMeeting : alertNoMic}
                />
            </Modal.Actions>
        </Modal>);
};

export default withNamespaces()(JoinMeetingDialog);
