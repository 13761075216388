import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as OrganizationModule from "../../Organization";
import * as Organization from "../../Organization";
import {Action} from "../index";
import {makeGetCurrentUser} from "../selectors/currentUser";

type Command = ActionType<typeof Action.Command.switchActiveOrganization>

function* handleSwitchActiveOrganization(command: Command) {
    let user = yield select(makeGetCurrentUser());
    const {response, error}: ResponseType = yield call(Action.Api.switchActiveOrganization, user.uid, command.payload.organizationId);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not switch active organization.'));
    }
    if (response) {
        yield put(Organization.Action.Query.fetchUserOrganizations());

        user = user.set('activeOrganization', command.payload.organizationId);
        yield put(Action.Event.activeOrganizationSwitched(user));
        if(command.payload.organizationId) {
            yield put(OrganizationModule.Action.Query.fetchOrganization(command.payload.organizationId));
        }
    }
}

export function* switchActiveOrganization() {
    while (true) {
        const command: Command = yield take([
            Action.Type.SWITCH_ACTIVE_ORGANIZATION
        ]);

        yield fork(handleSwitchActiveOrganization, command);
    }
}
