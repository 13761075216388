import {Map} from 'immutable';
import {Reducer} from 'redux';
import {
    addAggregate,
    removeAggregate,
    removeAllAggregates,
    updateAggregate,
    upsertAggregates
} from "../../core/reducer/applyAggregateChanged";
import {
    MEMBER_REMOVED_FROM_TEAM,
    TEAM_ADDED,
    TEAM_DELETED,
    TEAM_DESCRIPTION_CHANGED,
    TEAM_MOVED_TO_ORGANIZATION,
    TEAM_RENAMED,
    TEAMS_FETCHED
} from "../actions/constants";
import {Team} from '../model';
import {InspectioTeamEvent} from './index';

export interface TeamsState extends Map<string, Team.Team> {
}

export const initialState: TeamsState = Map<string, Team.Team>();

const reducer: Reducer<TeamsState, InspectioTeamEvent> = (state: TeamsState = initialState, event: InspectioTeamEvent): TeamsState => {
    switch (event.type) {
        case TEAM_ADDED:
            return addAggregate(state, event.payload);
        case TEAM_RENAMED:
        case TEAM_DESCRIPTION_CHANGED:
        case MEMBER_REMOVED_FROM_TEAM:
        case TEAM_MOVED_TO_ORGANIZATION:
            return updateAggregate(state, event.payload);
        case TEAMS_FETCHED:
            state = removeAllAggregates(state);
            return upsertAggregates(state, event.payload);
        case TEAM_DELETED:
            return removeAggregate(state, event.payload);
        default:
            return state;
    }
};

export { reducer as saveTeamReducer };
