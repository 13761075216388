/**
 * Code taken from: https://dev.to/polyov_dev/data-validation-in-typescript-using-the-either-pattern-4omk
 * But modified for better understanding (no need to know Haskell)
 */

export interface Failure<A> {
    value: A;
    tag: 'left'
}

export interface Success<B> {
    value: B;
    tag: 'right'
}

export type Either<A,B> = Failure<A> | Success<B>;

export function isFailure<A>(val: any): val is Failure<A> {
    if ((val as Failure<A>).tag === 'left') {
        return true;
    }
    return false;
}

export function isSuccess<B>(val: any): val is Success<B> {
    if ((val as Success<B>).tag === 'right') {
        return true;
    }

    return false;
}

export function Failure<A>(val: A) : Failure<A> {
    return { value: val, tag: 'left' };
}

export function Success<B>(val: B) : Success<B> {
    return { value: val, tag: 'right' };
}

export type Predicate<N> = (val: N) => boolean;

export type EitherFunction<A, B> = (val: B) => Either<A, B>;

export function predicateEither<A, B>(error: A, predicate: Predicate<B>) : (val: B) => Either<A, B> {
    return (value: B) => {
        if (!predicate(value)) {
            return Failure(error);
        }
        return Success(value);
    }
}


export function validate<A, B>(val: B, ...predicates: Array<[Predicate<B>, A]>) : Either<A, B> {
    for (const [p, error] of predicates) {
        if (!p(val)) {
            return Failure(error);
        }
    }
    return Success(val);
}

export function execValidate<A, B>(...predicates: Array<[Predicate<B>, A]>): (val: B) => Either<A, B> {
    return (val: B) => {
        return validate(val, ...predicates);
    }
}

export function validateEach<A, B>(list: B[], ...predicates: Array<[Predicate<B>, A]>) : Either<A, B[]> {
    for(const item of list) {
        const result = validate(item, ...predicates);

        if(isFailure(result)) {
            return result;
        }
    }

    return Success(list);
}

export function execValidateEach<A, B>(...predicates: Array<[Predicate<B>, A]>) : (list: B[]) => Either<A, B[]> {
    return (list: B[]) => validateEach(list, ...predicates);
}

export function exec<A, B>(func: EitherFunction<A, B>): (val: B) => Either<A, B> {
    return (val: B) => func(val);
}

export function execForEach<A, B, C>(func: EitherFunction<A, B>): (list: B[]) => Either<A, B[]> {
    return (list: B[]) => {
        const newList: B[] = [];

        for(const item of list) {
            const result = func(item);

            if(isFailure(result)) {
                return result;
            }

            newList.push(result.value);
        }

        return Success(newList);
    }
}

export function pipe<A, B>(val: B, ...funcs: Array<EitherFunction<A, B>>): Either<A, B> {
    for (const eitherFunc of funcs) {
        const result = eitherFunc(val);

        if(isFailure(result)) {
            return result;
        }

        val = result.value;
    }

    return Success(val);
}

