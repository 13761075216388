import {Node} from "../../../model/Graph";
import {names} from "../node/names";
import {Schema} from "../schema/schema";
import {UiSchema} from "../ui/ui-schema";
import {TableColumnUiSchema, TableUiSchema, ValueObjectMetadata} from "./get-vo-metadata";
import {isStateVo} from "./is-state-vo";

export const getDefaultUiTableSchema = (vo: Node, meta: ValueObjectMetadata): UiSchema & TableUiSchema => {
  const uiSchema: UiSchema & TableUiSchema = {};

  if(!isStateVo(vo)) {
    return uiSchema;
  }

  const columns: TableColumnUiSchema[] = [];

  meta.schema!.getObjectProperties().forEach(prop => {
    // we don't show the identifier in a table by default
    if(prop === meta.identifier) {
      return;
    }

    const propSchema = meta.schema!.getObjectPropertySchema(prop, new Schema({}));

    if(propSchema && propSchema.isPrimitive()) {
      columns.push({
        field: prop,
        headerName: names(prop).title,
        flex: 1
      })
    }
  })

  uiSchema['ui:table'] = {columns};

  return uiSchema;
}
