import {isFailure} from "../../core/validation/either";
import {isStandalone, standaloneStorageManager} from "../../standalone/util";
import {UserId} from "../../User/model/UserInfo";
import {fetchPlayshot, savePlayshot as apiSavePlayshot} from "../actions/api";
import {BoardId} from "../model/Board";
import {createPlayshotFromServerData, Playshot, PlayshotId} from "../model/Playshot";
import {Playshot as PlayServerPlayshot} from "./Cody/PlayServer";

const STORAGE_KEY = 'cody_playshots_';

type LocalPlayshot = PlayServerPlayshot & {savedAt: string, savedBy: UserId};

export const savePlayshot = async (playshot: PlayServerPlayshot): Promise<boolean> => {
  if(isStandalone()) {
    const storedPlayshots = loadLocalPlayshots(playshot.boardId);



    storedPlayshots.push({...playshot, savedAt: (new Date()).toISOString(), savedBy: standaloneStorageManager.getUser().userId});

    localStorage.setItem(STORAGE_KEY + playshot.boardId, JSON.stringify(storedPlayshots));
    return true;
  } else {
    return apiSavePlayshot(playshot).then(res => !res.error);
  }
}

export const loadPlayshot = async (boardId: BoardId, playshotId: PlayshotId): Promise<Playshot> => {
  if(isStandalone()) {
    const storedShots = loadLocalPlayshots(boardId);

    const shot = storedShots.find(s => s.playshotId === playshotId);

    if(!shot) {
      throw new Error(`Playshot with id ${playshotId} not found in local storage`);
    }

    return new Playshot(shot);
  } else {
    return fetchPlayshot(playshotId).then(res => {
      if(res.error) {
        console.error(res.error);
        throw new Error(`Playshot with id ${playshotId} could not be loaded`);
      }

      const playshotResult = createPlayshotFromServerData(res.response!.data);

      if(isFailure(playshotResult)) {
        throw new Error(`Failed to parse playshot response: ${playshotResult.value}`);
      }

      return playshotResult.value;
    })
  }
}

export const loadLocalPlayshots = (boardId: BoardId): LocalPlayshot[] => {
  const key = STORAGE_KEY + boardId;

  const shots = localStorage.getItem(key);

  if(shots) {
    try {
      return JSON.parse(shots);
    } catch (e) {
      console.error('Failed to parse playshots from local storage: ', e);
    }
  }

  return [];
}


