import {List} from "immutable";
import {WizardStep} from "../../../components/CodyEngineWizard/WizardModal";
import {Board} from "../../../model/Board";
import {Graph, Node} from "../../../model/Graph";

export type WizardContextType = 'dddAction' | 'crudAction' | 'policy' | 'projection' | 'apiIntegrationTest' | 'uiIntegrationTest' | 'ui' | 'empty' | 'information' | 'feature';

export interface WizardContext<T = any> {
  board: Board;
  type: WizardContextType;
  previousStep: (currentStep: WizardStep, ctx: T) => WizardStep | null;
  nextStep: (currentStep: WizardStep, ctx: T) => WizardStep | null;
  installCodyGraphSuggestions: (currentStep: WizardStep, ctx: T, graph: Graph) => void;
}

export const updateContext = <T extends WizardContext>(ctx: T, ctxPropsToUpdate: Partial<T>): T => {
  return {...ctx, ...ctxPropsToUpdate};
}

export const makeCodySession = <T extends WizardContext>(ctx: T, includedNode?: Node): T => {
  const session: any = {};

  // tslint:disable-next-line:forin
  for (const ctxKey in ctx) {
    if(typeof ctx[ctxKey] === 'function' || typeof ctx[ctxKey] === 'string') {
      session[ctxKey] = ctx[ctxKey];
      continue;
    }

    if(ctx[ctxKey] instanceof List) {
      if(includedNode && (ctx[ctxKey] as unknown as List<Node>).find(n => n.getId() === includedNode.getId())) {
        session[ctxKey] = List.of(includedNode);
      } else {
        session[ctxKey] = List();
      }
      continue;
    }

    if(includedNode && ctx[ctxKey] && typeof ctx[ctxKey] === "object" && (ctx[ctxKey] as any).getId && (ctx[ctxKey] as any).getId() === includedNode.getId()) {
      session[ctxKey] = includedNode;
    } else {
      session[ctxKey] = null;
    }

  }

  return session as T;
}





