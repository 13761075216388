import {History} from "history";
import {fromJS} from "immutable";
import * as React from 'react';
import {useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Message, Modal} from 'semantic-ui-react';
import {TeamId} from "../../InspectioTeams/model/Team";
import {makeActiveUserOrganizationInfoSelector} from "../../Organization/selectors/selectUserOrganizationInfo";
import {UserInfo} from "../../User/model/UserInfo";
import * as Action from "../actions";
import {BoardModel} from "../model";
import {default as AddBoardForm, ImFormData} from './AddBoardForm';
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {withRouter} from "react-router-dom";

interface OwnProps {
    open: boolean;
    onClose: () => void;
    disabled: boolean;
}

export type AddBoardProps = OwnProps & WithNamespaces & RouteComponentProps;

const submitForm = (data: ImFormData, user: UserInfo, dispatch: ReturnType<typeof useDispatch>, history: History<any>): void => {
    const board = BoardModel.createUserBoard(
        user.uid,
        data.get('name') as BoardModel.BoardName,
        data.get('nameAbbreviation') as string,
        data.get('tags') as string[],
        data.get('responsibleTeam') as TeamId | undefined,
        data.get('codySuggestEnabled') === '1',
      data.get('eventModelingEnabled') === '1'
    );

    dispatch(Action.Command.addBoard(board, history));
}

const AddBoardModal = (props: AddBoardProps) => {
    const [isAddingBoard, setIsAddingBoard] = useState(false);
    const activeOrganization = useSelector(makeActiveUserOrganizationInfoSelector());
    const user = useSelector(makeGetCurrentUser());
    const dispatch = useDispatch();

    return <Modal open={ props.open} closeIcon={true} onClose={ props.onClose} size="mini">
    <Modal.Header>{  props.t("insp.board.add") }</Modal.Header>
    <Modal.Content>
        { props.disabled && !isAddingBoard && <Message icon="dollar" warning={true}
                                                       content={activeOrganization && activeOrganization.userIsGuest
                                                           ? props.t('insp.user.quota_reached_guest')
                                                           : activeOrganization && activeOrganization.explicitOrganization
                                                               ? props.t('insp.user.quota_reached')
                                                               : props.t('insp.user.quota_reached_user')
                                                       } />}
        {! props.disabled && <AddBoardForm initialValues={fromJS(BoardModel.defaultBoardProps)}
                                           isAddingBoard={isAddingBoard}
                                           onSubmit={(data: ImFormData) => {setIsAddingBoard(true); submitForm(data,  user, dispatch, props.history)}}
        />}
        {isAddingBoard && <span>...</span>}
    </Modal.Content>
</Modal>};

export default withNamespaces()(withRouter(AddBoardModal));


