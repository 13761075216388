import { Map } from 'immutable';
import { Reducer } from 'redux';
import {
    AUTHENTICATE
} from "../actions/constants";
import { AuthAction } from './index';

export interface AuthState extends Map<string, any>{
}

export const initialState = Map<string, null>();

const reducer: Reducer<AuthState, AuthAction> = (state: AuthState = initialState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AUTHENTICATE:
            return state.set('token', action.payload.auth.token);
        default:
            return state;
    }
};

export { reducer as applyAuth };