import {List} from "immutable";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import * as React from 'react';
import {WithNamespaces, withNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Dimmer, Dropdown, Grid, Loader} from "semantic-ui-react";
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";
import {readFile} from "../../../core/readFile";
import * as NOTIFY from "../../../NotificationSystem/actions";
import * as Action from "../../actions";
import {BoardId} from "../../model/Board";
import {NodeType} from "../../model/Graph";
import {createStickyTemplateFromDefault, TemplateSchema} from "../../model/StickyTemplate";
import {makeBoardByIdSelector, makeGetBoard} from "../../selectors/board";
import {makeBoardStickyTemplateMapSelector} from "../../selectors/StickyTemplate";
import BoardStickyAdvancedConfig from "./BoardStickyConfiguration/BoardStickyAdvancedConfig";
import BoardStickySelection from "./BoardStickyConfiguration/BoardStickySelection";
import BoardStickyTemplateEditor, {BoardStickyTemplateEditorRef} from "./BoardStickyConfiguration/BoardStickyTemplateEditor";

interface OwnProps {
    boardId: BoardId;
}

type BoardStickyTemplatesProps = OwnProps & WithNamespaces;

const BoardStickyTemplates = (props: BoardStickyTemplatesProps) => {
    const dispatch = useDispatch();
    const [activeCellType, setActiveCellType] = useState<NodeType|undefined>();
    const [advancedConfigActive, setAdvancedConfigActive] = useState<boolean>(false);
    const boardStickyTemplates = useSelector(makeBoardStickyTemplateMapSelector(props.boardId));
    const board = useSelector(makeBoardByIdSelector(props.boardId));
    const stickyTemplateEditorRef = useRef<BoardStickyTemplateEditorRef|null>(null);
    const uploadEl = useRef<HTMLInputElement>(null);
    const [isImporting, setIsImporting] = useState(false);

    useEffect(() => {
        dispatch(Action.Query.fetchBoardStickyTemplates(props.boardId))
    }, []);

    const handleUpdateTemplate = () => {
        if (!activeCellType) {
            return;
        }

        if (stickyTemplateEditorRef && stickyTemplateEditorRef.current) {
            const editorValue = stickyTemplateEditorRef.current.retrievePayload();

            if (boardStickyTemplates.get(activeCellType)!.templateType === 'json') {
                try {
                    JSON.parse(editorValue);
                } catch {
                    dispatch(NOTIFY.Command.error(
                        props.t('insp.sidebar.element_details.json_invalid_title'),
                        props.t('insp.sidebar.element_details.json_invalid_text')
                    ));
                    return;
                }
            }

            dispatch(Action.Command.setStickyTemplate(props.boardId, activeCellType, editorValue));
        }
    };

    const handleSwitchToAdvancedConfig = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setAdvancedConfigActive(true);
    }

    const handleTemplateGenerated = (newTemplate: string) => {
        if (!activeCellType) {
            return;
        }

        setAdvancedConfigActive(false);
        dispatch(Action.Command.setStickyTemplate(props.boardId, activeCellType, newTemplate));

        window.setTimeout(() => {
            if(stickyTemplateEditorRef.current) {
                stickyTemplateEditorRef.current.updateModelValue(newTemplate);
            }
        }, 10)
    }

    const downloadTemplates = () => {
        const templatesStr = JSON.stringify(boardStickyTemplates.map(tpl => ({
            templateType: tpl.templateType,
            template: tpl.template,
            templateSchema: tpl.templateSchema
        })), null, 2);

        const jsonBlob = new Blob([templatesStr], {type: "application/json"});
        const url = URL.createObjectURL(jsonBlob);

        const a = document.createElement('a');
        a.download = board.name + ' ' + 'Card Templates.json';
        a.href = url;
        document.body.append(a);
        a.click();

        window.setTimeout(() => {
            a.remove();
            URL.revokeObjectURL(url);
        }, 150);
    }

    const uploadTemplates = () => {
        if(uploadEl.current) {
            uploadEl.current.click();
        }
    }

    const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0] || null;

        readFile(file).then(templatesStr => {
            let templates: {[cellType: string]: {templateType: string, template: string, templateSchema?: string}};
            try {
                templates = JSON.parse(templatesStr);
            } catch {
                dispatch(NOTIFY.Command.error(
                    props.t('insp.sidebar.element_details.json_invalid_title'),
                    props.t('insp.sidebar.element_details.json_invalid_text')
                ));
                return;
            }

            if(typeof templates !== 'object') {
                dispatch(NOTIFY.Command.error(
                    'Invalid Card Templates File',
                    props.t('Selected file does not contain a JSON object of card templates')
                ));
                return;
            }

            const cellTypes = Object.keys(boardStickyTemplates.toJSON());
            const tplCellTypes = Object.keys(templates);
            const importableStickyTemplates = [];

            for (const tplCellType of tplCellTypes) {
                if(!cellTypes.includes(tplCellType)) {
                    dispatch(NOTIFY.Command.error(
                        'Invalid Card Templates File',
                        props.t('File contains unknown cell type: ' + tplCellType)
                    ));
                    return;
                }

                let stickyTemplate = createStickyTemplateFromDefault(props.boardId, tplCellType as NodeType, templates[tplCellType].template || '');
                stickyTemplate = stickyTemplate.setTemplateType(templates[tplCellType].templateType || 'json' as any);
                if(templates[tplCellType].templateSchema) {
                    stickyTemplate = stickyTemplate.setTemplateSchema(templates[tplCellType].templateSchema as TemplateSchema);
                }

                importableStickyTemplates.push(stickyTemplate);
            }

            setIsImporting(true);
            dispatch(Action.Command.importStickyTemplates(props.boardId, List(importableStickyTemplates), () => {
                setIsImporting(false);
            }));
        })


    }

    return (
        <div className="ui segments">
            <div className="ui segment">
                <Dropdown item={true} icon="ellipsis vertical" style={{float: "right", marginTop: '30px', fontSize: '1.2rem'}}>
                    <Dropdown.Menu direction="left" >
                        <Dropdown.Item icon="download" text="Import Templates" onClick={uploadTemplates} />
                        <Dropdown.Item icon="upload" text="Export Templates" onClick={downloadTemplates} />
                    </Dropdown.Menu>
                </Dropdown>
                <h2 className="ui header">
                    {props.t('insp.board.sticky_templates.headline')}
                    <HeaderSubHeader>{props.t('insp.board.sticky_templates.description')}</HeaderSubHeader>
                </h2>
                <p>&nbsp;</p>
                <Grid columns={2}>
                    <Grid.Column mobile="6" tablet="6" computer="5" largeScreen="5" widescreen="5">
                        <BoardStickySelection
                            boardStickyTemplates={boardStickyTemplates}
                            activeCellType={activeCellType}
                            onSetActiveCellType={setActiveCellType}
                        />
                    </Grid.Column>
                    {activeCellType && (
                        <Grid.Column mobile="10" tablet="10" computer="11" largeScreen="11" widescreen="11" style={{borderLeft: "1px solid #d8dee2"}}>
                            {!advancedConfigActive && (<>
                                <BoardStickyTemplateEditor
                                    ref={stickyTemplateEditorRef}
                                    boardStickyTemplate={boardStickyTemplates.get(activeCellType)!}
                                    onSave={handleUpdateTemplate}
                                />
                                <p>&nbsp;</p>
                                <a href={'#'} onClick={handleSwitchToAdvancedConfig}>
                                    {props.t('insp.board.sticky_templates.advanced_config')}
                                </a>
                                <Button
                                    primary={true}
                                    onClick={handleUpdateTemplate}
                                    content={props.t('app.form.save')}
                                    floated="right"
                                />
                            </>)}
                            {advancedConfigActive && (
                                <BoardStickyAdvancedConfig
                                    boardId={props.boardId}
                                    boardStickyTemplate={boardStickyTemplates.get(activeCellType)!}
                                    onClose={() => setAdvancedConfigActive(false)}
                                    onTemplateGenerated={handleTemplateGenerated}
                                />
                            )}
                            <Dimmer inverted={true} active={isImporting}>
                                <Loader inverted={true}>Importing</Loader>
                            </Dimmer>
                        </Grid.Column>
                    )}
                </Grid>
                <input type="file" ref={uploadEl} style={{display: 'none'}} onChange={handleFileSelected} />
            </div>
        </div>
    );
};

export default withNamespaces()(BoardStickyTemplates);
