import { Reducer } from 'redux';
import {INITIAL_BOARD_LIST_LOADED} from "../actions/constants";
import {InspectioBoardsAction} from "./index";

const reducer: Reducer<boolean, InspectioBoardsAction> = (state: boolean = false, action: InspectioBoardsAction): boolean => {
    switch (action.type) {
        case INITIAL_BOARD_LIST_LOADED:
            return  true;
        default:
            return state;
    }
};

export { reducer as initialBoardListLoadedReducer };
