import {Record} from "immutable";
import {createSelector} from "reselect";
import {ApplicationState} from "../../reducer";
import {SidebarState} from "../reducers/applySidebarActions";

export const sidebarSelector = (state: Record<ApplicationState>, props: any): SidebarState =>
    state.getIn(['inspectioSidebar', 'sidebar']) || new SidebarState();

export const makeGetSidebarSelector = (): (state: Record<ApplicationState>, props?: any) => SidebarState => {
    return createSelector(
        [sidebarSelector],
        (sidebar): SidebarState => sidebar
    )
}