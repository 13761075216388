import * as React from 'react';
import {ReactNode} from "react";
import {Link} from "react-router-dom";
import {Breadcrumb} from "semantic-ui-react";
import * as Routes from "../../routes";

interface OwnProps {

}

type BoardOverviewCrumbProps = OwnProps;

const HomeLink = (props: {children: ReactNode}) => {
    return <Link to={Routes.inspectio}>{props.children}</Link>
};

const BoardOverviewCrumb = (props: BoardOverviewCrumbProps) => {
    return <Breadcrumb.Section as={HomeLink}>Boards</Breadcrumb.Section>;
};

export default BoardOverviewCrumb;