import * as React from 'react';
import {useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {NavLink} from "react-router-dom";
import {Icon} from "semantic-ui-react";
import {makeGetBoards} from "../../InspectioBoards/selectors/boardList";
import * as Routes from "../../routes";

interface OwnProps {}

type BoardOverviewProps = OwnProps & RouteComponentProps;

const BoardOverview = (props: BoardOverviewProps) => {
    const getBoards = makeGetBoards(true);
    const boards = useSelector(getBoards);

    let badgeClasses = "ui small label";

    if(props.location.pathname === Routes.inspectio) {
       badgeClasses += " teal";
    }

    return <NavLink exact={true} className="item" activeClassName="active" to={Routes.inspectio}>
        <Icon className="sticky note icon"/>
        <div className={badgeClasses}>{ boards.count() }</div>
        Boards
    </NavLink>
};

export default withRouter(BoardOverview);
