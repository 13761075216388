import {call,fork, put, take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {default as socket} from "../../api/ConfiguredSocket";
import { ResponseType } from '../../api/util';
import {saveInspectioBoard} from "../../core/localStorage";
import {Action as Notify} from "../../NotificationSystem";
import * as Action from "../actions";
import {useGraph} from "../hooks/useGraph";
import {BoardVersion} from "../model/BoardVersion";


type FlowAction = ActionType<typeof Action.Command.saveBoard> & ActionType<typeof Action.Event.boardImported>;

function* onSaveBoard(action: FlowAction, forceSnapshot: boolean) {
    const [graph] = useGraph();

    // console.log('saveBoardFlow');
    //
    // saveInspectioBoard(action.payload.board, (err) => {
    //     console.log('saveBoardFlowErr', err);
    //     localError = err;
    // })
    //
    // if(localError) {
    //     const errorNotification = Notify.Command.error('Failed to save board in local storage', localError);
    //     yield put(errorNotification);
    // }

    console.log('saveBoardFlow: ', action.payload.board.version.version, action.payload.board.version.version % 20 );
    // @TODO Check if socket connection is active, otherwise always take a snapshot
    if(action.payload.board.tempBoard) {
        console.log('saveBoardFlow: ', 'Skipping save of temp board');
        return;
    }

    if(action.payload.board.version.version % 20 === 0 || action.payload.board.version.version < 5 || forceSnapshot) {

        const snapshot = graph.exportXmlSnapshot();

        let retry = 3;
        do {
            const boardVersion = new BoardVersion({userId: action.payload.board.version.userId, version: action.payload.board.version.version + (3-retry)});
            const {error}: ResponseType = yield call(Action.Api.takeBoardSnapshot, action.payload.board.uid, boardVersion, snapshot);

            if(error) {
                retry--;
            } else {
                retry = 0;
            }

            if (retry === 0 && error) {
                yield put(Notify.Command.error('Server Error', 'Could not take board snapshot.'));
            }
        } while (retry > 0);
    }
}

export function* saveBoardFlow() {

    while (true) {
        const action: FlowAction = yield take([
            Action.Type.SAVE_BOARD,
            Action.Type.BOARD_IMPORTED
        ]);

        let forceSnapshot = false;

        if(action.type === Action.Type.BOARD_IMPORTED) {
            // Always make a snapshot on import
            forceSnapshot = true;
        }

        yield fork(onSaveBoard, action, forceSnapshot);
    }
}
