import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {FormField, Icon, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {BoardId} from "../../../model/Board";
import {ElementsTree} from "../../../model/ElementsTree";
import {NodeType} from "../../../model/Graph";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import {getSchemaRefName} from "../../../service/cody-wizard/vo/get-schema-ref-name";
import {getVoMetadata} from "../../../service/cody-wizard/vo/get-vo-metadata";
import ExpandButton from "./ExpandButton";
import {makeShorthandSchemaValidationSchema} from "./Validation/shorthand-schema";


interface OwnProps {
  boardId: BoardId;
  schema: Schema;
  onSchemaChanged: (schema: Schema | undefined) => void;
  autofocus?: boolean;
  readonly?: boolean;
  sessionId?: string;
}

type SchemaEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};

const SchemaEditor = (props: SchemaEditorProps) => {
  const elementsTree = useElementsTree(props.boardId);
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [editorExpanded, setEditorExpanded] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let value = props.schema.toString();
    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `schema.json`,
      language: "json",
      value,
      schema: props.schema.isShorthand()? makeShorthandSchemaValidationSchema(getPossibleRefs(elementsTree)) : undefined,
    });

    setInvalidJson(isInvalidJson);
  }, [props.schema]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {
    if(editorStr === '') {
      props.onSchemaChanged(undefined);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedSchema = JSON.parse(editorStr);
      props.onSchemaChanged(new Schema(changedSchema));

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true
    } catch (e) {
      console.error("[CodyWizard] Schema Editor invalid JSON: ", e);
      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  return <FormField>
    {invalidJson &&
        <Message error={true} size="small" content={props.t('insp.cody_wizard.schema_editor.invalid_json') as string}
                 icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorExpanded} onExpandChanged={expand => setEditorExpanded(expand)}/>
    <CodeEditor
      containerId={'cody-schema-editor'}
      ref={codeEditorRef}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorExpanded ? ' expanded' : '')}
      onBlur={() => {
        if (codeEditorRef.current) {
          const changedSchemaStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedSchemaStr);

          setEditorHasFocus(false);

          if (!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedSchemaStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(SchemaEditor);

export const getPossibleRefs = (elementsTree: ElementsTree): string[] => {
  const possibleRefs: string[] = [];

  elementsTree.getFilteredElementsByType(NodeType.document).forEach(ele => {
    const voMeta = getVoMetadata(ele);

    const possibleRef = getSchemaRefName(ele, voMeta);

    if (!possibleRefs.includes(possibleRef)) {
      possibleRefs.push(possibleRef);
    }

    if (voMeta.schema && !voMeta.schema.isList()) {
      const possibleRefAsList = possibleRef + '[]';
      if (!possibleRefs.includes(possibleRefAsList)) {
        possibleRefs.push(possibleRefAsList);
      }
    }
  });

  return possibleRefs;
}
