import * as React from 'react';
import BoardOverviewSearch from "../BoardOverviewSearch";

interface OwnProps {

}

type FilterProps = OwnProps;

const Filter = (props: FilterProps) => {
    return <BoardOverviewSearch />
};

export default Filter;
