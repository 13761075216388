import * as React from 'react';
import {Checkbox, FormField} from "semantic-ui-react";
import {Node} from "../../../../model/Graph";
import {WizardContext} from "../../../../service/cody-wizard/context/wizard-context";

interface OwnProps {
  command: Node | null;
  aggregateCommand: boolean;
  streamLocking: boolean;
  ctx: WizardContext;
  onStreamLockingChanged: (enabled: boolean) => void;
  disabled?: boolean;
}

type StreamLockingToggleProps = OwnProps;

const StreamLockingToggle = (props: StreamLockingToggleProps) => {
  const disabled = props.aggregateCommand;

  return <FormField disabled={props.disabled || disabled} inline={true}>
    <Checkbox value={1}
              checked={props.streamLocking || props.aggregateCommand}
              disabled={props.disabled || disabled}
              toggle={true}
              onChange={() => props.onStreamLockingChanged(!props.streamLocking)}
              label={'Optimistic Stream Locking'} />
  </FormField>
};

export default StreamLockingToggle;
