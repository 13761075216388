import * as React from 'react';
import {Icon, Step} from "semantic-ui-react";
import {CodyStep} from "./RunCody";

interface OwnProps {
  step: CodyStep;
  onStepClicked: () => void;
}

type CodyStepElementProps = OwnProps;

const CodyStepElement = (props: CodyStepElementProps) => {
  return <Step title={props.step.title}
               description={props.step.description}
               icon={props.step.error? <Icon name="x" color="red" /> : props.step.completed ? <Icon name="check" color="green" /> :  props.step.icon}
               completed={!props.step.error && props.step.completed}
               active={props.step.active}
               id={props.step.id}
               /* border needs to be set explicitly, otherwise out of view steps don't have a border (why ever?) */
               style={{border: '1px solid rgba(34, 36, 38, 0.15)'}}
               disabled={props.step.disabled}
               link={props.step.linkable}
               onClick={() => props.step.linkable ? props.onStepClicked() : undefined}
               />
};

export default CodyStepElement;
