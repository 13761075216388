import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Checkbox, FormField} from "semantic-ui-react";
import {Node} from "../../../../model/Graph";

interface OwnProps {
  command: Node | null;
  deleteState: boolean | undefined;
  newAggregate: boolean | undefined;
  onDeleteStateChanged: (deleteState: boolean) => void;
  disabled?: boolean;
}

type DeleteStateToggleProps = OwnProps & WithNamespaces;

const DeleteStateToggle = (props: DeleteStateToggleProps) => {
  const disabled = !props.command || props.newAggregate;
  return <FormField disabled={disabled} inline={true} className={disabled? 'fadeout' : 'fadein'}>
    <Checkbox value={1}
              checked={props.deleteState}
              disabled={props.disabled || disabled}
              toggle={true}
              onChange={() => props.onDeleteStateChanged(!props.deleteState)}
              label={props.t('insp.cody_wizard.step_command.delete_state_label') as string} />
  </FormField>
};

export default withNamespaces()(DeleteStateToggle);
