import { fromJS } from 'immutable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { initJwtVerify, TokenParsed } from './api/ConfiguredJwtVerify';
import {distributeNewToken, removeLoginToken} from "./api/util";
import App from './App';
import AppStandalone from './AppStandalone';
import { Action as Auth } from './Auth';
import configureStore from './configureStore';
import { history } from './history';
import { Action as Notify } from './NotificationSystem';
import notify from './notify';
import { ApplicationState } from './reducer';
import {fetchUserBoards} from "./InspectioBoards/actions/queries";
import {isStandalone} from "./standalone/util";
import Keycloak from 'keycloak-js';

const error: any = null;

const kc = Keycloak({
    url: process.env.REACT_APP_IG_KC_BASE_URL + 'auth',
    realm: process.env.REACT_APP_IG_KC_REALM || '',
    clientId: process.env.REACT_APP_IG_KC_CLIENT_ID || '',
});

if (isStandalone()) {
    const initialState: ApplicationState = fromJS({});

    const store = configureStore(history, initialState);
    store.dispatch(fetchUserBoards());

    ReactDOM.render(<AppStandalone store={store} history={history}/>, document.getElementById('root'));
}

if (!isStandalone()) {
    // TODO use promiseType: 'native'
    kc.init({onLoad: 'login-required'})
        .success((authenticated: any) => {
            if (authenticated) {
                const boards = null; /* loadInspectioBoards((err) => {
                error = err;
            });*/

                initJwtVerify(kc.token!, kc.tokenParsed as TokenParsed);
                distributeNewToken(kc.token!);
                removeLoginToken();

                const initialState: ApplicationState = boards !== null ? fromJS({
                    inspectioBoards: fromJS({boards}),
                }) : fromJS({});

                const store = configureStore(history, initialState);

                store.dispatch(Auth.Event.userAuthenticated(kc));

                if (error) {
                    const errorMsg: Error = error;
                    store.dispatch(Notify.Command.error('Failed to load inspectio board from local storage', errorMsg.message));
                }
                ReactDOM.render(<App store={store} history={history}/>, document.getElementById('root'));
            }
        })
        // TODO use error component
        .error((kcError: any) => console.warn({kcError}));

    notify();
}

export const logout = () => kc.logout();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// disabled due self signed certificate
// serviceWorker.register();
