import {List} from "immutable";
import * as React from 'react';
import {useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {Button, Grid, GridColumn, Message, Select} from "semantic-ui-react";
import {OrganizationId} from "../../../Organization/model/Organization";
import {UserOrganizationInfo} from "../../../Organization/model/UserOrganizationInfo";
import * as Action from "../../actions";
import {BoardId} from "../../model/Board";

interface OwnProps {
    boardId: BoardId;
    organizations: List<UserOrganizationInfo>
}

type MoveBoardMenuProps = OwnProps & WithNamespaces;

const MoveBoardMenu = (props: MoveBoardMenuProps) => {
    const dispatch = useDispatch();
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationId|undefined>();

    const options = props.organizations.map(orga => ({
        key: orga.uid,
        value: orga.uid,
        text: orga.organizationName
    }));

    const handleMoveBoard = () => {
        if(selectedOrganization) {
            dispatch(Action.Command.moveUserBoardToOrganization(selectedOrganization, props.boardId))
            setSelectedOrganization(undefined);
        }
    }

    return <>
        <Select options={options.toJS()}
                placeholder={props.t('insp.board.move_to_organization_select') as string}
                onChange={(e, data) => setSelectedOrganization(data.value as OrganizationId)}
        />
        <Button primary={true} floated="right" onClick={() => handleMoveBoard()}>{props.t('insp.board.move_to_organization_submit')}</Button>
        <Message size="small" info={true}>{props.t('insp.board.move_to_organization_desc')}</Message>
    </>;
};

export default withNamespaces()(MoveBoardMenu);
