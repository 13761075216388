import * as React from 'react';
import {Breadcrumb} from "semantic-ui-react";

interface OwnProps {

}

type TeamOverviewCrumbProps = OwnProps;

const TeamOverviewCrumb = (props: TeamOverviewCrumbProps) => {
    return <Breadcrumb.Section>Teams</Breadcrumb.Section>
};

export default TeamOverviewCrumb;