import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Icon} from "semantic-ui-react";
import {hideOwnBoardsOnOverview, hideSharedBoardsOnOverview} from "../../InspectioBoards/actions/commands";
import {BoardOverviewState} from "../../InspectioBoards/reducers/applyOverviewActions";
import {boardOverviewSelector} from "../../InspectioBoards/selectors/boardList";

interface OwnProps {
    count: number;
}

export enum DisplayMode {
    all = 'all',
    onlyShared = 'onlyShared',
    noShared = 'noShared',
}

type SharedBoardsItemProps = OwnProps;

const SharedBoardsItem = (props: SharedBoardsItemProps) => {

    const boardOverview = useSelector(boardOverviewSelector);
    const mode = getModeFromBoardOverviewSettingss(boardOverview);
    const dispatch = useDispatch();

    const nextMode = () => {
        switch (mode) {
            case DisplayMode.all:
                dispatch(hideOwnBoardsOnOverview(true));
                dispatch(hideSharedBoardsOnOverview(false));
                break;
            case DisplayMode.onlyShared:
                dispatch(hideOwnBoardsOnOverview(false));
                dispatch(hideSharedBoardsOnOverview(true));
                break;
            case DisplayMode.noShared:
                dispatch(hideOwnBoardsOnOverview(false));
                dispatch(hideSharedBoardsOnOverview(false));
                break;
        }
    };

    let badgeClasses = "ui small label";

    if(mode === DisplayMode.onlyShared) {
        badgeClasses += " teal";
    }

    return <div className="ui item" style={{cursor: "pointer"}} onClick={nextMode}>
        <Icon className="share icon"/>
        {mode !== DisplayMode.noShared && <div className={badgeClasses}>{props.count}</div>}
        Shared Boards
        {mode === DisplayMode.noShared && <Icon className="eye slash icon"/>}
    </div>
};

export default SharedBoardsItem;

const getModeFromBoardOverviewSettingss = (settings: BoardOverviewState): DisplayMode => {
    if(settings.allSharedBoardsHidden) {
        return DisplayMode.noShared;
    }

    if(settings.allOwnBoardsHidden) {
        return DisplayMode.onlyShared;
    }

    return DisplayMode.all;
};
