import { Record } from 'immutable';
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {AvatarUrl, Email, JobTitle, UserId, Username} from "./UserInfo";

export const AGGREGATE_TYPE = 'IioUserInfo';
export type IioUserInfoError = string;

export interface IioUserInfoProps {
    uid: UserId;
    userId: UserId;
    username: Username;
    avatarUrl: AvatarUrl;
    anonymous: boolean;
    aggregateType: AggregateType;
    position: JobTitle;
    email: Email;
}

export const defaultIioUserInfoProps: IioUserInfoProps = {
    uid: '',
    userId: '',
    username: '',
    avatarUrl: '',
    anonymous: true,
    aggregateType: AGGREGATE_TYPE,
    position: '',
    email: '',
};

export function createIioUserInfoFromServerData(data: any): Either<IioUserInfoError, IioUserInfo> {
    const validatedData: Either<string, any> = validate(data,
        [hasKey('userId'), "userId missing"],
        [hasKey('username'), "username missing in "+data.userId],
        [hasKey('avatarUrl'), "avatarUrl missing in "+data.userId],
        [hasKey('position'), "position missing in "+data.userId],
        [hasKey('email'), "email missing in "+data.userId],
    );

    if(isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new IioUserInfo({
        ...defaultIioUserInfoProps,
        ...data,
        uid: data.userId,
    }));
}

export function createAnonymous(): IioUserInfo {
    return new IioUserInfo(defaultIioUserInfoProps);
}

export class IioUserInfo extends Record(defaultIioUserInfoProps) implements Aggregate, IioUserInfoProps {
    public constructor(data: IioUserInfoProps) {
        if(data.userId !== '') {
            data.anonymous = false;
        }

        super(data);
    }

    public avatar(): AvatarUrl {
        if(this.anonymous) {
            return '';
        }

        if(this.avatarUrl === '') {
            return this.generateDefaultAvatarUrl();
        }

        return this.avatarUrl;
    }

    private generateDefaultAvatarUrl(): AvatarUrl {
        return `/avatar/${this.userId}?displayName=${this.username}`;
    }
}
