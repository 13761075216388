import {useSelector} from "react-redux";
import {BoardId} from "../model/Board";
import {ElementsTree} from "../model/ElementsTree";
import {makeElementsTreeByBoardIdSelector} from "../selectors/elementsTree";

export const useElementsTree = (boardId: BoardId): ElementsTree => {
  const selector = makeElementsTreeByBoardIdSelector(boardId);

  return useSelector(selector);
}
