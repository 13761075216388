import {call, fork, put, take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {Action as Notify} from "../../NotificationSystem";
import * as Action from "../../InspectioBoards/actions";
import {StandaloneBoard} from "../storage/types";
import {standaloneStorageManager} from "../util";

type FlowAction = ActionType<typeof Action.Command.importExistingBoard>;

function* onImportExistingBoard(action: FlowAction) {
    const board: StandaloneBoard|null = yield call(standaloneStorageManager.getBoard, action.payload.board.uid);
    if (board) {
        yield call(standaloneStorageManager.updateBoard, {
            ...board,
            xml: action.payload.board.xml!,
            lastModified: (() => new Date())().toISOString(),
        });

        yield put(Action.Command.forceUpdateBoards([action.payload.board]));
        yield put(Notify.Command.info('Board imported:', action.payload.board.name));
    }
}

export function* standaloneImportExistingBoardFlow() {
    while (true) {
        const action: FlowAction = yield take([Action.Type.IMPORT_EXISTING_BOARD]);

        yield fork(onImportExistingBoard, action);
    }
}
