import {axiosApiPromise, ResponseType} from "../../api/util";
import {SERVER_CONTEXT} from "../../User/actions/constants";
import {Email, UserId} from "../../User/model/UserInfo";
import {OrganizationId, OrganizationName} from "../model/Organization";
import {OrganizationInvitationId} from "../model/OrganizationInvitation";

export const installUserOrganization = (organizationId: OrganizationId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'InstallUserOrganization', { organizationId });
};

export const setUpImplicitOrganization = (organizationId: OrganizationId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'SetUpImplicitOrganization', { organizationId });
}

export const setUpExplicitOrganization = (organizationId: OrganizationId, name: OrganizationName): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'SetUpExplicitOrganization', {organizationId, name});
}

export const fetchOrganization = (organizationId: OrganizationId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'Organization', { organizationId });
}

export const fetchUserOrganizations = (): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'UserOrganizations', {});
}

export const inviteUserToOrganization = (organizationId: OrganizationId, invitationId: OrganizationInvitationId, invitedUserEmail: Email): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'InviteUserToOrganization', {organizationId, invitationId, invitedUserEmail});
}

export const revokeUserOrganizationInvitation = (organizationId: OrganizationId, invitedUserEmail: Email): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'RevokeUserOrganizationInvitation', {organizationId, invitedUserEmail});
}

export const resendUserOrganizationInvitation = (organizationId: OrganizationId, invitedUserEmail: Email): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'ResendUserOrganizationInvitation', {organizationId, invitedUserEmail});
}

export const acceptInvitationToOrganization = (organizationId: OrganizationId, invitationId: OrganizationInvitationId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'AcceptInvitationToOrganization', {organizationId, invitationId});
}

export const promoteUserToOrganizationAdmin = (organizationId: OrganizationId, userId: UserId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'PromoteUserToOrganizationAdmin', {organizationId, userId});
}

export const demoteUserToOrganizationMember = (organizationId: OrganizationId, userId: UserId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'DemoteUserToOrganizationMember', {organizationId, userId});
}

export const removeUserFromOrganization = (organizationId: OrganizationId, userId: UserId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'RemoveUserFromOrganization', {organizationId, userId});
}

export const renameOrganization = (organizationId: OrganizationId, newName: OrganizationName): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'RenameOrganization', {organizationId, newName});
}

export const removeGuestFromOrganization = (organizationId: OrganizationId, userId: UserId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'RemoveGuestFromOrganization', {organizationId, userId});
}

export const turnGuestIntoMember = (guestId: UserId, organizationId: OrganizationId): Promise<ResponseType> => {
    return axiosApiPromise(SERVER_CONTEXT + 'TurnGuestIntoMember', {guestId, organizationId});
}
