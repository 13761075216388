import React from "react";
import {JitsiUser as User, JitsiScreenShareUser as ScreenShareUser} from "../../model/JitsiUser";
import {JitsiDevice as Device, JitsiTrack as Track, JitsiConnection as Connection, JitsiConference as Conference} from "./JitsiMeetInterfaces";
import JitsiVideo, {NewPosition, PositionChange}  from "./JitsiVideo";

import WebSocketConnection from "../../../api/ConfiguredJitsiSocket";
import {makeGetSidebarSelector} from "../../../Layout/selectors/sidebar";
import {useSelector} from "react-redux";
import {useGraph} from "../../hooks/useGraph";
import {Graph, GraphPoint} from "../../model/Graph";
import { connectSocket } from "../../../Socket/actions/actions";

import JitsiScreenShare from "./JitsiScreenShare";

interface JitsiMeetingProps {
    sessionId: string;
    id: string;
    name: string;
    avatar: string;
    initialPosition: { left: number, top: number };
    videoDevice: string;
    audioDevice: string;
    graph:Graph;
    sidebar:any;
    timeoutRefEmoji:any;
    timeoutRefIsSpeaking:any;
}

interface JitsiMeetingState {
    localUser:User;
    remoteUsers:User[];
    localScreenShare?:ScreenShareUser;
    remoteScreenShares:ScreenShareUser[];
    graphTranslate:GraphPoint;
    graphScale:number;
    //audioLevel:number;
}

interface RemotePositions {
    [userId: string]: { position: { left: number, top: number } }
}

const JitsiMeetJS = (window as any).JitsiMeetJS;


class JitsiMeeting extends React.Component<JitsiMeetingProps> {
    private connection?:Connection;
    private room?:Conference;
    private localTracks?:Track[];
    private shiftKeyPressed: boolean = false;
    private shiftDownListener?: (e: KeyboardEvent) => void;
    private shiftUpListener?: (e: KeyboardEvent) => void;

    private screenShareConnection?:Connection;
    private screenShareRoom?:Conference;
    private screenShareTrack:Track|null = null;
    private screenShareFlag:boolean = false;
    private voiceIndicatorIsChanging:boolean = false;

    private confOptions = {
        openBridgeChannel: true,
        enableNoisyMicDetection: true,
        enableNoAudioDetection: true,
        p2p:{
            enabled:false,
        },
    };
    private voiceActivationBuffer:number = 0.025;
    private joinedWithoutCamera:boolean = this.props.videoDevice === 'no_camera_selected';

    public state:JitsiMeetingState = {
        localUser:{
            id: this.props.id,
            name: this.props.name,
            avatar: this.props.avatar,
            position: this.props.initialPosition,
            isSpeaking: false,

            showIconName: "",
            cameraOn: !this.joinedWithoutCamera,
            micOn: true,
            megaphoneOn: false,
            handUpOn: false,
            emojiOn: false,
        },
        remoteUsers:[],
        remoteScreenShares:[],
        graphTranslate:{x:0,y:0},
        graphScale:1,
        //audioLevel:0
    };

    constructor (props:JitsiMeetingProps) {
        super(props);
 
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.unload = this.unload.bind(this);
        this.onConnectionSuccess = this.onConnectionSuccess.bind(this);
        this.onConnectionFailed = this.onConnectionFailed.bind(this);
        this.onConnectionDisconnect = this.onConnectionDisconnect.bind(this);
        this.onDeviceListChanged = this.onDeviceListChanged.bind(this);
        this.onConferenceJoined = this.onConferenceJoined.bind(this);
        this.updateIsSpeaking = this.updateIsSpeaking.bind(this);
        this.onLocalTrack = this.onLocalTrack.bind(this);
        this.onRoomTrackAdded = this.onRoomTrackAdded.bind(this);
        this.onRoomTrackRemoved = this.onRoomTrackRemoved.bind(this);
        this.isAnyMegaphoneActive = this.isAnyMegaphoneActive.bind(this);
        this.onUserJoined = this.onUserJoined.bind(this);
        this.onUserLeft = this.onUserLeft.bind(this);
        this.updateRemoteUser = this.updateRemoteUser.bind(this);
        this.changeLocalUserPosition = this.changeLocalUserPosition.bind(this);
        this.handleLocalUserPositionChanged = this.handleLocalUserPositionChanged.bind(this);
        this.handleScreenSharePositionChanged = this.handleScreenSharePositionChanged.bind(this);
        this.handleScreenShareScalingChanged = this.handleScreenShareScalingChanged.bind(this);
        this.shareScreen = this.shareScreen.bind(this);
        this.shareScreenConnectionEstablished = this.shareScreenConnectionEstablished.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.toggleScreenShare = this.toggleScreenShare.bind(this);
        this.renderRemoteTracks = this.renderRemoteTracks.bind(this);
        this.renderRemoteScreenShares = this.renderRemoteScreenShares.bind(this);
        this.toggleCamera = this.toggleCamera.bind(this);
        this.toggleMic = this.toggleMic.bind(this);
        this.toggleMegaphone = this.toggleMegaphone.bind(this);
        this.toggleHandUp = this.toggleHandUp.bind(this);
        this.toggleEmoji = this.toggleEmoji.bind(this);
        this.render = this.render.bind(this);
        this.monitorShiftKeyPressed = this.monitorShiftKeyPressed.bind(this);
        this.stopShiftKeyMonitoring = this.stopShiftKeyMonitoring.bind(this);
        this.testfunction = this.testfunction.bind(this);
        this.testfunction2 = this.testfunction2.bind(this);
    }

    unload(){
        if (this.localTracks){
            for (let track of this.localTracks) {
                track.dispose();
            }
        }
        this.state.localUser.audioTrack ? this.state.localUser.audioTrack.dispose() : null ;
        this.state.localUser.videoTrack ? this.state.localUser.videoTrack.dispose() : null ;
        this.state.localScreenShare ? this.state.localScreenShare.screenShareTrack ? this.state.localScreenShare.screenShareTrack.dispose() : null : null ;
    }
    
    /* 
    window.addEventListener('beforeunload', unload);
    window.addEventListener('unload', JitsiMeeting.unload); */

    async componentDidMount() {
        window.addEventListener('beforeunload', this.unload);
        window.addEventListener('unload', this.unload);
        JitsiMeetJS.init({flags:{sendMultipleVideoStreams:true, sourceNameSignaling:true}});
        console.log("jitsitesttest : ", JitsiMeetJS);

        console.log("jitsitest : MOUNTED");
        console.log("jitsitestvariables : id", this.state.localUser.id, this.props.id, this.props.sessionId);
        console.log("jitsitest : MOUNTED");
        if(this.props.videoDevice === 'no_camera_selected'){
            this.localTracks = await JitsiMeetJS.createLocalTracks(
                { devices: [ 'audio'],
                micDeviceId: this.props.audioDevice}
            );
        } else {
            try {
                this.localTracks = await JitsiMeetJS.createLocalTracks(
                    { devices: [ 'audio', 'video'],
                    cameraDeviceId: this.props.videoDevice,
                    micDeviceId: this.props.audioDevice}
                );
            } catch {
                console.log("jitsitest : add audio + video failed, catch and try seperate audio");
                try {
                    this.localTracks = await JitsiMeetJS.createLocalTracks(
                        { devices: [ 'video'],
                        cameraDeviceId: this.props.videoDevice}
                    );
                } catch {console.log("jitsitest : add video failed");}
                try {
                    this.localTracks = await JitsiMeetJS.createLocalTracks(
                        { devices: [ 'audio'],
                        micDeviceId: this.props.audioDevice}
                    );
                } catch {console.log("jitsitest : add audiofailed");}
            }
        }
        console.log("track test: ", this.localTracks)

        const options = {
            hosts: {
                domain: 'jitsi-video.inseciacloud.com',
                muc: 'muc.jitsi-video.inseciacloud.com'
            },
            bosh: 'https://bosh.jitsi-video.inseciacloud.com:5280/http-bind',

        };
        this.connection = new JitsiMeetJS.JitsiConnection(null, null, options);
        
        console.log("jitsitest : ConectionSuccess", this.connection);
        
        this.connection!.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess);
        this.connection!.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this.onConnectionFailed);
        this.connection!.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this.onConnectionDisconnect);
        
        JitsiMeetJS.mediaDevices.addEventListener(
            JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
            this.onDeviceListChanged);

        this.connection!.connect();
        
        this.onLocalTrack();        
        console.log("JitsiPosition : Start before websocket listen");
        WebSocketConnection.listenForUpdates(
            this.props.id, this.props.sessionId, this.updateRemoteUser
        );
        console.log("JitsiPosition : Start after");

        ////////////// 
        const scale = this.props.graph.getCurrentScale();
        const translatedPoint = {
            left: (-1 * this.props.graph.getCurrentTranslate().x) + 
                    (this.props.initialPosition.left / scale),
            top: (-1 * this.props.graph.getCurrentTranslate().y) + 
                    (this.props.initialPosition.top / scale),
        }
        console.log("jitsigraph local user", translatedPoint, scale);
        this.setState({
            localUser: {
                ...this.state.localUser, 
                position: translatedPoint,
            }
        });
        console.log("jitsigraph local user", this.props.graph.getCurrentScale(), this.props.graph.getCurrentTranslate());
        const panningListener = (translate: GraphPoint) => {

            if(this.shiftKeyPressed) {
                const panX =  this.state.graphTranslate.x - translate.x;
                const panY = this.state.graphTranslate.y - translate.y;

                this.changeLocalUserPosition({
                    left: this.state.localUser.position.left + panX,
                    top: this.state.localUser.position.top + panY,
                });
            }

            this.setState({
                graphTranslate:{x: translate.x, y: translate.y}
            });
        };

        const zoomListener = (scale: number, translate: GraphPoint) => {
            this.setState({
                graphScale:scale,
                graphTranslate:translate
            });
        };

        const moveAvatarListener = (pos: {x: number, y: number}) => {
            this.changeLocalUserPosition({left: pos.x, top: pos.y});
        }

        if(this.props.graph) {
            const translate = this.props.graph.getCurrentTranslate();
            this.setState({
                graphTranslate: {x: translate.x, y: translate.y},
                graphScale: this.props.graph.getCurrentScale(),
            });
            this.props.graph.onPanning(panningListener);
            this.props.graph.onZoom(zoomListener);
            this.props.graph.setVideoAvatarEnabled(true);
            this.props.graph.onMoveVideoAvatarToPosition(moveAvatarListener);
        }

        this.monitorShiftKeyPressed();

        return () => {
            if(this.props.graph) {
                this.props.graph.offPanning(panningListener);
                this.props.graph.offZoom(zoomListener);
                this.props.graph.setVideoAvatarEnabled(false);
                this.props.graph.clearMoveVideoAvatarToPositionListener();
            }

            this.stopShiftKeyMonitoring();
        }
    }

    componentDidUpdate (prevProps:JitsiMeetingProps) { // TODO ERROR component did update if's never trigger (componentDidMount is enought? as it works)
        console.log("number of componentDidUpdate", this.props.graph !== prevProps.graph)
        // Component update on changed value of --- graph --- 
        if(this.props.graph !== prevProps.graph) { // || !!this.state.localUser !== !!this.state.localUser ) {
            const scale = this.props.graph.getCurrentScale();
            const translatedPoint = {
                left: (-1 * this.props.graph.getCurrentTranslate().x) + 
                      (this.props.initialPosition.left / scale),
                top: (-1 * this.props.graph.getCurrentTranslate().y) + 
                     (this.props.initialPosition.top / scale),
            }
            console.log("jitsigraph local user", translatedPoint, scale);
            this.setState({
                localUser: {
                    ...this.state.localUser, 
                    position: translatedPoint,
                }
            });
        }
        if (this.props.graph !== prevProps.graph) {
            console.log("jitsigraph local user", this.props.graph.getCurrentScale(), this.props.graph.getCurrentTranslate());
            const panningListener = (translate: GraphPoint) => {
                this.setState({
                    graphTranslate:{x: translate.x, y: translate.y}
                });
            };
    
            const zoomListener = (scale: number, translate: GraphPoint) => {
                this.setState({
                    graphScale:scale,
                    graphTranslate:translate
                });
            };
    
            if(this.props.graph) {
                const translate = this.props.graph.getCurrentTranslate();
                this.setState({
                    graphTranslate: {x: translate.x, y: translate.y},
                    graphScale: this.props.graph.getCurrentScale(),
                });
                this.props.graph.onPanning(panningListener);
                this.props.graph.onZoom(zoomListener);
            }
            return () => {
                if(this.props.graph) {
                    this.props.graph.offPanning(panningListener);
                    this.props.graph.offZoom(zoomListener);
                }
            }
        }
    
        // Component update on changed value of --- ... --- 
    }

    componentWillUnmount() {
        WebSocketConnection.stopListeningForPositionUpdates();
        if (this.localTracks){
            for (let track of this.localTracks) {
                track.dispose();
            }
        }
        this.state.localUser.audioTrack ? this.state.localUser.audioTrack.dispose() : null ;
        this.state.localUser.videoTrack ? this.state.localUser.videoTrack.dispose() : null ;
        this.state.localScreenShare ? this.state.localScreenShare.screenShareTrack ? this.state.localScreenShare.screenShareTrack.dispose() : null : null ;
        
        if(this.room){
            this.room.off(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined);
            this.room.off(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRoomTrackAdded);
            this.room.off(JitsiMeetJS.events.conference.TRACK_REMOVED, this.onRoomTrackRemoved);
            this.room.leave();
        }
        if(this.screenShareRoom){
            this.screenShareRoom.off(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {});
            this.screenShareRoom.off(JitsiMeetJS.events.conference.CONFERENCE_FAILED, this.screenConferenceEnded);
            this.screenShareRoom.off(JitsiMeetJS.events.conference.CONFERENCE_LEFT, this.screenConferenceEnded);
            this.screenShareRoom.leave();
        }
        JitsiMeetJS.mediaDevices.removeEventListener(
            JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
            this.onDeviceListChanged);
        this.screenConferenceEnded();
        if(this.connection){
            this.connection.removeEventListener(
                JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
                this.onConnectionSuccess);
            this.connection!.removeEventListener(
                JitsiMeetJS.events.connection.CONNECTION_FAILED,
                this.onConnectionFailed);
            this.connection!.removeEventListener(
                JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
                this.onConnectionDisconnect);
            this.connection.disconnect();
        }
    }

    async onConnectionSuccess() {
        console.log("jitsitest : ConectionSuccess", this.connection);
        console.log(this.connection);

        this.room = await this.connection!.initJitsiConference("swarply_"+this.props.sessionId, this.confOptions);
        console.log("jitsisession ID: swarply_",this.props.sessionId);
        this.room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined);
        this.room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRoomTrackAdded);
        this.room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, this.onRoomTrackRemoved);
        this.room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined);
        this.room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft); 
        
        this.room.join();
    }

    onConnectionFailed() {
        console.log("jitsitest : ConnectionFailed");
    }
    onConnectionDisconnect() {
        console.log("jitsitest : ConnectionDisconnect");
    }
    onDeviceListChanged() {
        console.log("jitsitest : DevicelistChanged");
    }
 

    /////////////
    async onConferenceJoined() {
        console.log("jitsisession ID: swarply_",this.props.sessionId);
        console.log("jitsitest : ConferenzJoined");
        this.localTracks!.forEach(track => {
            console.log("jitsitest : ConferenzAddTrackToRoom ", track);
            console.log("jitsiscreenshare : ConferenzAddTrackToRoom ", track);
            this.room!.addTrack(track);
        })    

        this.state.localUser.audioTrack && this.state.localUser.audioTrack.on(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, this.updateIsSpeaking);

        await new Promise(r => setTimeout(r, 500));
        WebSocketConnection.sendUserInitialInfo(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, {name: this.state.localUser.name, avatar: this.state.localUser.avatar});
        WebSocketConnection.sendPositionUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, this.state.localUser.position.left, this.state.localUser.position.top);
        await new Promise(r => setTimeout(r, 500));
        WebSocketConnection.sendPositionUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, this.state.localUser.position.left, this.state.localUser.position.top);
        WebSocketConnection.sendUserInitialInfo(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, {name: this.state.localUser.name, avatar: this.state.localUser.avatar});
        await new Promise(r => setTimeout(r, 500));
        WebSocketConnection.sendPositionUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, this.state.localUser.position.left, this.state.localUser.position.top);
        WebSocketConnection.sendUserInitialInfo(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, {name: this.state.localUser.name, avatar: this.state.localUser.avatar});
    }

    updateIsSpeaking(audioLevel:number){
        console.log("VADtest : iterate on talk");
        if(this.state.localUser.isSpeaking && audioLevel > this.voiceActivationBuffer){
            clearTimeout(this.props.timeoutRefIsSpeaking.current);
            this.voiceIndicatorIsChanging = false;
        } else if(!this.state.localUser.isSpeaking && audioLevel < this.voiceActivationBuffer){
            clearTimeout(this.props.timeoutRefIsSpeaking.current);
            this.voiceIndicatorIsChanging = false;
        }else if(this.state.localUser.isSpeaking && audioLevel < this.voiceActivationBuffer){
            if(this.voiceIndicatorIsChanging){
                console.log("VADtest : blocked");return;}
            clearTimeout(this.props.timeoutRefIsSpeaking.current);
            this.voiceIndicatorIsChanging = true;
            this.props.timeoutRefIsSpeaking.current = window.setTimeout(() => {
                clearTimeout(this.props.timeoutRefIsSpeaking.current);
                this.setState({
                    localUser: {
                        ...this.state.localUser,
                        isSpeaking: false,
                    }
                })
                WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
                    {
                        cameraOn:this.state.localUser.cameraOn, 
                        micOn:this.state.localUser.micOn, 
                        megaphoneOn:this.state.localUser.megaphoneOn, 
                        handUpOn:this.state.localUser.handUpOn, 
                        emojiOn:this.state.localUser.emojiOn, 
                        showIconName:this.state.localUser.showIconName,
                        isSpeaking:false,
                    }  
                );
                this.voiceIndicatorIsChanging = false;
            }, 300);
        } else if(!this.state.localUser.isSpeaking && audioLevel > this.voiceActivationBuffer){
            if(this.voiceIndicatorIsChanging){
                console.log("VADtest : blocked");return;}
            clearTimeout(this.props.timeoutRefIsSpeaking.current);
            this.voiceIndicatorIsChanging = true;
            this.props.timeoutRefIsSpeaking.current = window.setTimeout(() => {
                clearTimeout(this.props.timeoutRefIsSpeaking.current);
                this.setState({
                    localUser: {
                        ...this.state.localUser,
                        isSpeaking: true,
                    }
                })
                WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
                    {
                        cameraOn:this.state.localUser.cameraOn, 
                        micOn:this.state.localUser.micOn, 
                        megaphoneOn:this.state.localUser.megaphoneOn, 
                        handUpOn:this.state.localUser.handUpOn, 
                        emojiOn:this.state.localUser.emojiOn, 
                        showIconName:this.state.localUser.showIconName,
                        isSpeaking:true,
                    }  
                );
                this.voiceIndicatorIsChanging = false;
            }, 100);
        }
    }

    onLocalTrack() {
        console.log("jitsitest : LocalTracks", this.localTracks);
        if(!this.localTracks){return}
        this.localTracks.forEach(track => {
            if(track.type === 'video'){
                if(track.videoType === 'camera'){
                    this.setState({
                        localUser: {
                            ...this.state.localUser, 
                            videoTrack: track,
                        }
                    });
                }
            } else if(track.type === 'audio') {
                this.setState({
                    localUser: {
                        ...this.state.localUser, 
                        audioTrack: track,
                    }
                });
            }
        })
    }

    async onRoomTrackAdded(track:Track) {
        console.log("jitsiscreenshare : Track: ", track);
        console.log("screenshareuser : onRoomTrackAdded", track.isLocal(), this.room!.getLocalTracks().includes(track), track);
        if(this.screenShareRoom){console.log("screenshareuser : onRoomTrackAdded", track.isLocal(), this.screenShareRoom!.getLocalTracks());}
        if(track.isLocal() === true){return;}
        if(this.screenShareRoom && this.screenShareRoom.myUserId() === track.getParticipantId()){return;}
        
        console.log("jitsitestfunction : id own screen share ", this.screenShareRoom ? this.screenShareRoom.myUserId() === track.getParticipantId() : false);
        console.log("jitsitest : RemoteTracks added ConferenzTrackAdded", track.getType(), track.videoType, track);
        console.log("remoteUsers : add track");
        if(track.getUsageLabel() != 'desktop'){
            let tmpUser = this.state.remoteUsers.find(user => user.id === track.getParticipantId());
            if(tmpUser){
                let userIndex = this.state.remoteUsers.indexOf(tmpUser!);
                let items = [...this.state.remoteUsers];
                if(track.getType() === 'audio'){
                    let item = {
                        ...items[userIndex],
                        audioTrack: track,
                    }
                    items[userIndex] = item;
                    this.setState({remoteUsers: items});
                    console.log("JitsiUser : ",items)
                } else if(track.getType() === 'video'){
                    if(track.getUsageLabel() === 'default'){
                        let item = {
                            ...items[userIndex],
                            videoTrack: track,
                        }
                        items[userIndex] = item;
                        this.setState({remoteUsers: items});
                        console.log("JitsiUser : ",items)
                    } else if(track.getUsageLabel() === 'desktop'){
                        let item = {
                            ...items[userIndex],
                            screenShareTrack: track,
                        }
                        items[userIndex] = item;
                        this.setState({remoteUsers: items});
                        console.log("JitsiUser : ",items)
                    } else {
                        console.log("jitsitest: ERROR: Unhandled VideoType on onRoomTrackAdded");
                    }
                } else {
                    console.log("jitsitest: ERROR: trackType undefined")
                }
            } else{
                let left = this.props.initialPosition.left;
                let top = this.props.initialPosition.top;
                if(track.getType() === 'audio'){
                    this.setState({
                        remoteUsers: [
                            ...this.state.remoteUsers,
                            {
                                id: track.getParticipantId(),
                                name: this.props.name,
                                avatar: this.props.avatar,
                                position: {left:left,top:top},
                                isSpeaking: false,
                                showIconName: "",
                                audioTrack: track,
                                screenShareTrack: null,
                                cameraOn: true,
                                micOn: true,
                                megaphoneOn: false,
                                handUpOn: false,
                                emojiOn: false,
                            }
                        ]
                    });
                } else if(track.getType() === 'video'){
                    if(track.getUsageLabel() === 'default'){
                        this.setState({
                            remoteUsers: [
                                ...this.state.remoteUsers,
                                {
                                    id: track.getParticipantId(),
                                    name: this.props.name,
                                    avatar: this.props.avatar,
                                    position: {left:left,top:top},
                                    isSpeaking: false,
                                    showIconName: "",
                                    videoTrack: track,
                                    screenShareTrack: null,
                                    cameraOn: true,
                                    micOn: true,
                                    megaphoneOn: false,
                                    handUpOn: false,
                                    emojiOn: false,
                                }
                            ]
                        });
                    } else if(track.getUsageLabel() === 'desktop'){
                        this.setState({
                            remoteUsers: [
                                ...this.state.remoteUsers,
                                {
                                    id: track.getParticipantId(),
                                    name: this.props.name,
                                    avatar: this.props.avatar,
                                    position: {left:left,top:top},
                                    showIconName: "",
                                    screenShareTrack: track,
                                    cameraOn: true,
                                    micOn: true,
                                    megaphoneOn: false,
                                    handUpOn: false,
                                    emojiOn: false,
                                }
                            ]
                        });
                    }
                } else {
                    console.log("jitsitest : Error track undefined ")
                }
            } 
        } else {
            let tmpScreenShare = this.state.remoteScreenShares.find(user => user.id === track.getParticipantId());
            if(tmpScreenShare){
                let userIndex = this.state.remoteScreenShares.indexOf(tmpScreenShare!);
                let items = [...this.state.remoteScreenShares];
                let item = {
                    ...items[userIndex],
                    screenShareTrack: track,
                }
                items[userIndex] = item;
                this.setState({remoteScreenShares: items});
                console.log("JitsiScreenShares : ",items)
            } else {
                this.setState({
                    remoteScreenShares: [
                        ...this.state.remoteScreenShares,
                        {
                            id: track.getParticipantId(),
                            originId: null,
                            position: {
                                left: 0,
                                top: 0,
                            },
                            screenShareTrack: track,
                            height: 360,
                        }
                    ]
                })
            }
        }
        console.log("jitsitest : remoteUsers:", this.state.remoteUsers);
    }

    onRoomTrackRemoved(track:Track) {
        if (track.isLocal() === true) {console.log("jitsitest : OnRoomTrackRemoved - local Track");return}
        console.log("remoteUsers : remove track");
        if(track.getUsageLabel() != 'desktop'){
            let tmpUser = this.state.remoteUsers.find(user => user.id === track.getParticipantId());
            if(tmpUser){
                let userIndex = this.state.remoteUsers.indexOf(tmpUser!);
                let items = [...this.state.remoteUsers];
                if(track.getType() === 'audio'){
                    let item = {
                        ...items[userIndex],
                        audioTrack: null,
                    }
                    items[userIndex] = item;
                    this.setState({remoteUsers: items});
                    console.log("JitsiUser : ",items)
                } else if(track.getType() === 'video'){
                    if(track.getUsageLabel() === 'default'){
                        let item = {
                            ...items[userIndex],
                            videoTrack: null,
                        }
                        items[userIndex] = item;
                        this.setState({remoteUsers: items});
                        console.log("JitsiUser : ",items)
                    } else {
                        console.log("jitsitest: ERROR: Unhandled VideoType on onRoomTrackAdded");
                    }
                } else {
                    console.log("jitsitest: ERROR: trackType undefined");
                }
            } else {
                console.log("jitsitest: ERROR: no user with participantId of track found");         
            }
        } else { // track.getUsageLabel() == 'desktop'
            let tmpScreenShare = this.state.remoteScreenShares.find(user => user.id === track.getParticipantId());
            console.log("Error test :", track.getUsageLabel() === 'desktop', tmpScreenShare, this.state.remoteScreenShares, track)
            if(track.getUsageLabel() === 'desktop' && tmpScreenShare){
                let userIndex = this.state.remoteScreenShares.indexOf(tmpScreenShare);
                let items = [...this.state.remoteScreenShares];
                let item = {
                    ...items[userIndex],
                    screenShareTrack: null,
                }
                items[userIndex] = item;
                this.setState({remoteScreenShares: items});
                console.log("JitsiScreenShares : ",items)
            } else {
                console.log("jitsitest: ERROR: trackType undefined");
            }
        }
    }
    async onUserJoined(userId:string){
        await new Promise(r => setTimeout(r, 500));
        WebSocketConnection.sendPositionUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, this.state.localUser.position.left, this.state.localUser.position.top);
        WebSocketConnection.sendUserInitialInfo(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, {name: this.state.localUser.name, avatar: this.state.localUser.avatar});
        WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
            {   cameraOn:this.state.localUser.cameraOn, 
                micOn:this.state.localUser.micOn, 
                megaphoneOn:this.state.localUser.megaphoneOn, 
                handUpOn:this.state.localUser.handUpOn, 
                emojiOn:this.state.localUser.emojiOn, 
                showIconName:this.state.localUser.showIconName,
                isSpeaking:this.state.localUser.isSpeaking });
        if(this.screenShareRoom){
            WebSocketConnection.sendInitialiseScreenShareInfo(this.screenShareRoom!.myUserId(), this.props.sessionId, 
            {originId: this.room!.myUserId(), position: {left: this.state.localUser.position.left, top: this.state.localUser.position.top}});
        }
        await new Promise(r => setTimeout(r, 500));
        WebSocketConnection.sendPositionUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, this.state.localUser.position.left, this.state.localUser.position.top);
        WebSocketConnection.sendUserInitialInfo(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, {name: this.state.localUser.name, avatar: this.state.localUser.avatar});
        WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
            {   cameraOn:this.state.localUser.cameraOn, 
                micOn:this.state.localUser.micOn, 
                megaphoneOn:this.state.localUser.megaphoneOn, 
                handUpOn:this.state.localUser.handUpOn, 
                emojiOn:this.state.localUser.emojiOn, 
                showIconName:this.state.localUser.showIconName,
                isSpeaking:this.state.localUser.isSpeaking });
        if(this.screenShareRoom){
            WebSocketConnection.sendInitialiseScreenShareInfo(this.screenShareRoom!.myUserId(), this.props.sessionId, 
            {originId: this.room!.myUserId(), position: {left: this.state.localUser.position.left, top: this.state.localUser.position.top}});
        }
        await new Promise(r => setTimeout(r, 500));
        WebSocketConnection.sendPositionUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, this.state.localUser.position.left, this.state.localUser.position.top);
        WebSocketConnection.sendUserInitialInfo(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, {name: this.state.localUser.name, avatar: this.state.localUser.avatar});
        WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
            {   cameraOn:this.state.localUser.cameraOn, 
                micOn:this.state.localUser.micOn, 
                megaphoneOn:this.state.localUser.megaphoneOn, 
                handUpOn:this.state.localUser.handUpOn, 
                emojiOn:this.state.localUser.emojiOn, 
                showIconName:this.state.localUser.showIconName,
                isSpeaking:this.state.localUser.isSpeaking });
        if(this.screenShareRoom){
            WebSocketConnection.sendInitialiseScreenShareInfo(this.screenShareRoom!.myUserId(), this.props.sessionId, 
            {originId: this.room!.myUserId(), position: {left: this.state.localUser.position.left, top: this.state.localUser.position.top}});
        }
    }
    
    onUserLeft(userId:string){
        let tmpUser = this.state.remoteUsers.find(user => user.id === userId)
        if(tmpUser){
            this.setState({
                remoteUsers: this.state.remoteUsers.filter(user => user.id != userId)
            });
        }
        let tmpScreenUserOrigin = this.state.remoteScreenShares.find(user => user.originId === userId)
        if(tmpScreenUserOrigin){
            this.setState({
                remoteScreenShares: this.state.remoteScreenShares.filter(user => user.originId != userId)
            });
        }
        let tmpScreenUser = this.state.remoteScreenShares.find(user => user.id === userId)
        if(tmpScreenUser){
            this.setState({
                remoteScreenShares: this.state.remoteScreenShares.filter(user => user.id != userId)
            });
        }
    }
    
    isAnyMegaphoneActive():boolean{
        return (
            this.state.localUser && this.state.localUser.megaphoneOn ||
            this.state.remoteUsers.find(remoteUser => remoteUser.megaphoneOn) != undefined
        )
    };

     //////////////////////////////////////
    // websocket relatet comunication and handling
    updateRemoteUser(id: string, data: 
            { left: number, top: number } | 
            {micOn: boolean, megaphoneOn: boolean, cameraOn: boolean, handUpOn: boolean, emojiOn: boolean, showIconName: string} |
            {name:string , avatar:string} |
            {originId:string, position: { left: number, top: number }} |
            {originId:string, targetId: string, request: boolean, awnser: boolean}
        ){
        if(data.hasOwnProperty("left")){
            this.updateRemoteUserPosition(id, data as any);
        } else if(data.hasOwnProperty("micOn")){
            this.updateRemoteUserStatus(id, data as any);
        } else if(data.hasOwnProperty("name")){
            this.initialiseUserInfo(id, data as any);
        } else if(data.hasOwnProperty("request")){
            this.initiateOneOnOneCall(id, data as any);
        } else if(data.hasOwnProperty("originId")){
            this.initialiseScreenShareInfo(id, data as any);
        } else {
            console.log("ERROR in update RemoteUser")
        }
    }

    updateRemoteUserPosition(id: string, position: { left: number, top: number }) {
        let tmpUser = this.state.remoteUsers.find(user => user.id === id);
        if(tmpUser){
            let userIndex = this.state.remoteUsers.indexOf(tmpUser);
            let items = [...this.state.remoteUsers];
            let item = {
                ...items[userIndex],
                position: position,
            }
            items[userIndex] = item;
            this.setState({remoteUsers: items});
        }
    }

    updateRemoteUserStatus(id: string, data: {micOn: boolean, megaphoneOn: boolean, cameraOn: boolean, handUpOn: boolean, emojiOn: boolean, showIconName: string, isSpeaking:boolean}){
        let tmpUser = this.state.remoteUsers.find(user => user.id === id);
        if(tmpUser){
            let userIndex = this.state.remoteUsers.indexOf(tmpUser!);
            let items = [...this.state.remoteUsers];
            let item = {
                ...items[userIndex],
                micOn: data.micOn,
                megaphoneOn: data.megaphoneOn,
                cameraOn: data.cameraOn,
                handUpOn: data.handUpOn,
                emojiOn: data.emojiOn,
                showIconName: data.showIconName,
                isSpeaking: data.isSpeaking
            }
            items[userIndex] = item;
            this.setState({remoteUsers: items});
        }
    }

    initialiseUserInfo(id:string, data: {name:string, avatar:string}){
        let tmpUser = this.state.remoteUsers.find(user => user.id === id);
        if(tmpUser){
            let userIndex = this.state.remoteUsers.indexOf(tmpUser!);
            let items = [...this.state.remoteUsers];
            let item = {
                ...items[userIndex],
                name:data.name,
                avatar:data.avatar,
            }
            items[userIndex] = item;
            this.setState({remoteUsers: items});
        }
    }

    initialiseScreenShareInfo(id:string, data: {originId:string, position: { left: number, top: number }}){
        let tmpScreenShare = this.state.remoteScreenShares.find(user => user.id === id);
        if(tmpScreenShare && tmpScreenShare.originId == null){
            let userIndex = this.state.remoteScreenShares.indexOf(tmpScreenShare);
            let items = [...this.state.remoteScreenShares];
            let item = {
                ...items[userIndex],
                originId: data.originId,
                position: data.position,
            }
            items[userIndex] = item;
            this.setState({remoteScreenShares: items});
        }
    }

    initiateOneOnOneCall(id:string, data: {originId:string, targetId: string, request: boolean, awnser: boolean}){
        console.log("initiateOneOnOneCall: ", id, data);
    }

    public changeLocalUserPosition(newPosition: NewPosition): void {
        if(this.state.localUser) {
            this.setState({
                localUser: {
                    ...this.state.localUser,
                    position: newPosition,
                }
            })

            if(this.props.graph) {
                this.props.graph.trackVideoAvatarPosition(newPosition.left, newPosition.top);
            }

            WebSocketConnection.sendPositionUpdate(
                this.state.localUser.audioTrack!.getParticipantId(),
                this.props.sessionId,
                newPosition.left,
                newPosition.top
            );
        }
    }

    handleLocalUserPositionChanged(positionChange: PositionChange): NewPosition{
        const scale = this.props.graph.getCurrentScale();

        const mouseDeltaX = positionChange.currentPositionMouse.x - positionChange.startPositionMouse.x;
        const mouseDeltaY = positionChange.currentPositionMouse.y - positionChange.startPositionMouse.y;

        const newPosition: NewPosition = {
            left: positionChange.startPositionAvatar.left + (mouseDeltaX / scale),
            top: positionChange.startPositionAvatar.top + (mouseDeltaY / scale),
        }

        if(this.state.localUser) {
            this.setState({
                localUser: {
                    ...this.state.localUser, 
                    position: newPosition,
                }
            })
        }

        if(this.props.graph) {
            this.props.graph.trackVideoAvatarPosition(newPosition.left, newPosition.top);
        }

        return newPosition;
    };

     //---
    // Screenshare relatet comunication and handling
    handleScreenSharePositionChanged(positionChange: PositionChange, userId?: string): NewPosition|undefined{
        const scale = this.props.graph.getCurrentScale();

        const mouseDeltaX = positionChange.currentPositionMouse.x - positionChange.startPositionMouse.x;
        const mouseDeltaY = positionChange.currentPositionMouse.y - positionChange.startPositionMouse.y;

        const newPosition: NewPosition = {
            left: positionChange.startPositionAvatar.left + (mouseDeltaX / scale),
            top: positionChange.startPositionAvatar.top + (mouseDeltaY / scale),
        }

        if (!userId && this.state.localScreenShare) {
            this.setState({
                localScreenShare: {
                    ...this.state.localScreenShare,
                    position: newPosition,
                }
            })
            return newPosition;
        } else if(this.state.remoteScreenShares) {
            let tmpScreenShare = this.state.remoteScreenShares.find(user => user.id === userId);
            if(tmpScreenShare){
                let userIndex = this.state.remoteScreenShares.indexOf(tmpScreenShare!);
                let items = [...this.state.remoteScreenShares];
                let item = {
                    ...items[userIndex],
                    position: newPosition,
                }
                items[userIndex] = item;
                this.setState({remoteScreenShares: items});
            }
        }
    };

    handleScreenShareScalingChanged(positionChange: PositionChange, userId?: string): NewPosition|undefined{
        const scale = this.props.graph.getCurrentScale();

        const mouseDeltaX = positionChange.currentPositionMouse.x - positionChange.startPositionMouse.x;
        const mouseDeltaY = positionChange.currentPositionMouse.y - positionChange.startPositionMouse.y;

        let newHeight = 0;
        switch(positionChange.startPositionAvatar.left){
            case 1:
                newHeight = Math.round(positionChange.startPositionAvatar.top + (mouseDeltaY / scale) + 0.56 * (mouseDeltaX / scale))
                break;
            case 2:
                newHeight = Math.round(positionChange.startPositionAvatar.top + (mouseDeltaY / scale) - 0.56 * (mouseDeltaX / scale))
                break;
            case 3:
                newHeight = Math.round(positionChange.startPositionAvatar.top - (mouseDeltaY / scale) - 0.56 * (mouseDeltaX / scale))
                break;
            case 4:
                newHeight = Math.round(positionChange.startPositionAvatar.top - (mouseDeltaY / scale) + 0.56 * (mouseDeltaX / scale))
                break;
            default:
                return;
        }

        if (!userId && this.state.localScreenShare) {
            this.setState({
                localScreenShare: {
                    ...this.state.localScreenShare,
                    height: newHeight > 100 ? (newHeight < 1000 ? newHeight : 1000) : 100,
                }
            })
            console.log("scalingtest : ",this.state.localScreenShare);
            return {left:0, top:0};
        } else if(this.state.remoteScreenShares) {
            let tmpScreenShare = this.state.remoteScreenShares.find(user => user.id === userId);
            if(tmpScreenShare){
                let userIndex = this.state.remoteScreenShares.indexOf(tmpScreenShare!);
                let items = [...this.state.remoteScreenShares];
                let item = {
                    ...items[userIndex],
                    height: newHeight > 100 ? (newHeight < 1000 ? newHeight : 1000) : 100,
                }
                items[userIndex] = item;
                this.setState({remoteScreenShares: items});
            }
        }
    }

    async shareScreen() {
        if(!this.screenShareFlag){this.screenShareFlag = true} else {return;}
        let scrensharetracksTmp = [];
        try {
            scrensharetracksTmp = await JitsiMeetJS.createLocalTracks(
                { devices: [ 'desktop'] }
            );
        } catch (error) {
            console.log("Screenshare selcet Screen GUM canceld / error ", error)
            this.screenShareFlag = false;
            return;
        }
        
        this.screenShareTrack = scrensharetracksTmp[0];
        console.log("ScreenShareUser : Track created ", this.screenShareTrack);
        const options = {hosts: {domain: 'jitsi-video.inseciacloud.com', muc: 'muc.jitsi-video.inseciacloud.com'},
                         bosh: 'https://bosh.jitsi-video.inseciacloud.com:5280/http-bind', };
        this.screenShareConnection = await new JitsiMeetJS.JitsiConnection(null, null, options);
        this.screenShareConnection!.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.shareScreenConnectionEstablished);
        this.screenShareConnection!.connect();
    }
    async shareScreenConnectionEstablished() {
        console.log("ScreenShareUser : connection connected ", this.screenShareTrack);
        this.screenShareRoom = await this.screenShareConnection!.initJitsiConference("swarply_"+this.props.sessionId, this.confOptions);
        console.log("ScreenShareUser : room ", this.screenShareRoom);
        this.screenShareRoom.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
            console.log("ScreenShareUser : onJoin session with ScreenShare user ");
            this.screenShareRoom!.addTrack(this.screenShareTrack!);
            this.setState({
                localScreenShare: {
                    id: 'screen_' + this.props.id,
                    originId: this.room!.myUserId(),
                    screenShareTrack: this.screenShareTrack!,
                    position: {left:this.state.localUser.position.left, top:this.state.localUser.position.top + 50},
                    height: 360,
                }
            })
            this.screenShareFlag = false;
            this.sendScreenShareInfosToWebSocket();
        });
        this.screenShareRoom.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, this.screenConferenceEnded);
        this.screenShareRoom.on(JitsiMeetJS.events.conference.CONFERENCE_LEFT, this.screenConferenceEnded);
        this.screenShareRoom.join();
    }
    async sendScreenShareInfosToWebSocket(){
        await new Promise(r => setTimeout(r, 500));
        WebSocketConnection.sendInitialiseScreenShareInfo(this.screenShareRoom!.myUserId(), this.props.sessionId, 
            {originId: this.room!.myUserId(), position: {left: this.state.localUser.position.left, top: this.state.localUser.position.top}});
        await new Promise(r => setTimeout(r, 300));
        WebSocketConnection.sendInitialiseScreenShareInfo(this.screenShareRoom!.myUserId(), this.props.sessionId, 
            {originId: this.room!.myUserId(), position: {left: this.state.localUser.position.left, top: this.state.localUser.position.top}});
        await new Promise(r => setTimeout(r, 300));
        WebSocketConnection.sendInitialiseScreenShareInfo(this.screenShareRoom!.myUserId(), this.props.sessionId, 
            {originId: this.room!.myUserId(), position: {left: this.state.localUser.position.left, top: this.state.localUser.position.top}});
    }

    screenConferenceEnded() {
        if(this.state.localScreenShare && this.state.localScreenShare.screenShareTrack){
            this.state.localScreenShare.screenShareTrack.dispose();
        }
        if(this.screenShareRoom){this.screenShareRoom.leave()}
        this.setState({
            localScreenShare: null,
        })
        this.screenShareFlag = false;
    }

    stopScreenShare() {
        if(!this.screenShareFlag){this.screenShareFlag = true} else {return;}
        this.screenConferenceEnded();
    };

    toggleScreenShare() {
        console.log("ScreenShareUser : boolean ", this.screenShareFlag);
        if (!this.state.localScreenShare) {
            this.shareScreen();
        } else {
            this.stopScreenShare();
        }
    };

     //////////////////////////////////////
    // Start of UI, and UI interactions
    toggleCamera() {
        if (!this.state.localUser|| !this.state.localUser.videoTrack || this.joinedWithoutCamera) {
            return;
        }

        const isCameraNowActive = !this.state.localUser.cameraOn;
        if(isCameraNowActive) {
            this.state.localUser.videoTrack.unmute();
        } else {
            this.state.localUser.videoTrack.mute();
        }
        this.setState({
            localUser: {
                ...this.state.localUser,
                cameraOn: isCameraNowActive,
            }
        })
        WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
            {
                cameraOn:isCameraNowActive, 
                micOn:this.state.localUser.micOn, 
                megaphoneOn:this.state.localUser.megaphoneOn, 
                handUpOn:this.state.localUser.handUpOn, 
                emojiOn:this.state.localUser.emojiOn, 
                showIconName:this.state.localUser.showIconName,
                isSpeaking:this.state.localUser.isSpeaking
            } 
        );
    };

    toggleMic() {
        if (!this.state.localUser || !this.state.localUser.audioTrack) {
            return;
        }
        const isMicNowActive = this.state.localUser.audioTrack.isMuted();
        if(isMicNowActive) {
            this.state.localUser.audioTrack.unmute();
        } else {
            this.state.localUser.audioTrack.mute();
        }
        this.setState({
            localUser: {
                ...this.state.localUser,
                micOn: isMicNowActive,
            }
        })
        WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
        {
            cameraOn:this.state.localUser.cameraOn, 
            micOn:isMicNowActive, 
            megaphoneOn:this.state.localUser.megaphoneOn, 
            handUpOn:this.state.localUser.handUpOn, 
            emojiOn:this.state.localUser.emojiOn, 
            showIconName:this.state.localUser.showIconName,
            isSpeaking:this.state.localUser.isSpeaking
        } 
        );
    };

    toggleMegaphone() {
        if (!this.state.localUser) {
            return;
        }

        const isMegaphoneNowActive = !this.state.localUser.megaphoneOn;
        this.setState({
            localUser: {
                ...this.state.localUser,
                megaphoneOn: isMegaphoneNowActive,
            }
        })
        WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
        {
            cameraOn:this.state.localUser.cameraOn, 
            micOn:this.state.localUser.micOn, 
            megaphoneOn:isMegaphoneNowActive, 
            handUpOn:this.state.localUser.handUpOn, 
            emojiOn:this.state.localUser.emojiOn, 
            showIconName:this.state.localUser.showIconName,
            isSpeaking:this.state.localUser.isSpeaking
        } 
        );
    };

    toggleHandUp() {
        if (!this.state.localUser) {
            return;
        }

        const isHandUpNowActive = !this.state.localUser.handUpOn;
        this.setState({
            localUser: {
                ...this.state.localUser,
                handUpOn: isHandUpNowActive,
            }
        })
        WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
        {
            cameraOn:this.state.localUser.cameraOn, 
            micOn:this.state.localUser.micOn, 
            megaphoneOn:this.state.localUser.megaphoneOn, 
            handUpOn:isHandUpNowActive, 
            emojiOn:this.state.localUser.emojiOn, 
            showIconName:this.state.localUser.showIconName,
            isSpeaking:this.state.localUser.isSpeaking
        }  
        );
    };

    toggleEmoji(iconName: string) {
        if (!this.state.localUser) {
            return;
        }
        const iconInfo = (this.state.localUser.showIconName === '') ? iconName : '';
        const isEmojiNowActive = !this.state.localUser.emojiOn;

        this.setState({
            localUser: {
                ...this.state.localUser,
                emojiOn: isEmojiNowActive,
                showIconName: iconInfo,
            }
        })
        WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
        {
            cameraOn:this.state.localUser.cameraOn, 
            micOn:this.state.localUser.micOn, 
            megaphoneOn:this.state.localUser.megaphoneOn, 
            handUpOn:this.state.localUser.handUpOn, 
            emojiOn:isEmojiNowActive, 
            showIconName:iconName,
            isSpeaking:this.state.localUser.isSpeaking
        }  
        );

        clearTimeout(this.props.timeoutRefEmoji.current);
        this.props.timeoutRefEmoji.current = window.setTimeout(() => {
            this.setState({
                localUser: {
                    ...this.state.localUser,
                    emojiOn: false,
                    showIconName: "",
                }
            })
            WebSocketConnection.sendStatusUpdate(this.state.localUser.audioTrack!.getParticipantId(), this.props.sessionId, 
            {
                cameraOn:this.state.localUser.cameraOn, 
                micOn:this.state.localUser.micOn, 
                megaphoneOn:this.state.localUser.megaphoneOn, 
                handUpOn:this.state.localUser.handUpOn, 
                emojiOn:false, 
                showIconName:"",
                isSpeaking:this.state.localUser.isSpeaking
            }  
            );
        }, 3000);
    };

    
     //////////////////////////////////////
    // render of remote video components
    renderRemoteTracks() {
        // if(!this.state.remoteUsers){return}
        console.log("jitsitestVariables : ", this.state.localUser, this.props.id, this.props.sessionId, this.state.remoteUsers);
        return this.state.remoteUsers.map((user) => 
            <div key={"videoAvatar_"+user.id}>
                {<JitsiVideo
                    key={user.id}
                    user={user}
                    isLocalUser={false}
                    volume={ 
                        !this.state.localUser ? 0 : Math.max(
                            Math.min(
                                (700 - Math.sqrt(
                                Math.pow((user.position.top - this.state.localUser.position.top), 2) +
                                Math.pow((user.position.left - this.state.localUser.position.left), 2)
                                )) / 500, 
                                1
                            )
                            , 0
                        ) 
                    }
                    sessionId={this.props.sessionId}
                    isAnyMegaphoneActive={this.isAnyMegaphoneActive()}
                    isSpeaking={user.isSpeaking}
                />}
            </div>
        )
    }

    renderRemoteScreenShares() {
        const screenShareUsers = this.state.remoteScreenShares.filter(users => users.screenShareTrack != null && users.screenShareTrack != undefined);
        return screenShareUsers.map((user) =>
            <div>
                <JitsiScreenShare 
                    user={user}
                    originUser={this.state.remoteUsers.find(tmpuser => tmpuser.id == user.originId)}
                    /* userId={user.id} 
                    screenSharePosition={{left:user.position.left,
                    top:user.position.top}}
                    screenShareTrack={user.screenShareTrack!}  */
                    volume={ 
                        !this.state.localUser ? 0 : Math.max(
                            Math.min(
                                (1400 - Math.sqrt(
                                Math.pow((user.position.top - this.state.localUser.position.top), 2) +
                                Math.pow((user.position.left - this.state.localUser.position.left), 2)
                                )) / 500, 
                                1
                            )
                            , 0
                        ) 
                    }
                    sessionId={this.props.sessionId}
                    onPositionChange={positionChange => this.handleScreenSharePositionChanged(positionChange, user.id)}
                    onScaleScreenShare={positionChange => this.handleScreenShareScalingChanged(positionChange, user.id)}
                />
            </div>
        )
    }

    private monitorShiftKeyPressed() {
        this.shiftDownListener = (e: KeyboardEvent) => {
            if(e.key === 'Shift') {
                this.shiftKeyPressed = true;
            }

        }
        this.shiftUpListener = (e: KeyboardEvent) => {
            if(e.key === 'Shift') {
                this.shiftKeyPressed = false;
            }
        }
        window.addEventListener('keydown', this.shiftDownListener);
        window.addEventListener('keyup', this.shiftUpListener);
    }

    private stopShiftKeyMonitoring() {
        if(this.shiftDownListener) {
            window.removeEventListener('keydown', this.shiftDownListener);
        }

        if(this.shiftUpListener) {
            window.removeEventListener('keyup', this.shiftUpListener);
        }
    }

    testfunction(){
        console.log("testfunction \n\n\n");
        this.state.remoteScreenShares.forEach(user => {
            console.log("remoteuser testfunction: ", user);
        })
        console.log("localuser state testfunction: ", this.state.localUser);
        console.log("localuser room testfunction: ", this.room!.getLocalTracks());
        console.log("remoteusers via room testfunction, r, s :", this.room!.getParticipants(), this.state.remoteUsers, this.state.remoteScreenShares);
        console.log("ScreenShareUser testfunction :", this.state.remoteScreenShares);
    }
    
    testfunction2(){
        console.log("audio event triggert")
    }

    render() {
        console.log("jitsirender : test ");
        return (
            <div className="diagram-overlay" style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "none"
            }}>
                <div style={{
                    transform: `scale(${this.state.graphScale}) translate(${this.state.graphTranslate.x}px, ${this.state.graphTranslate.y}px)`,
                    transformOrigin: '0 0',
                }}>
                    {<div>
                        {<div id="private_swarply_avatar"><JitsiVideo
                            key={this.state.localUser.id}
                            user={this.state.localUser!}
                            isLocalUser={true}
                            volume={0}
                            sessionId={this.props.sessionId}
                            onPositionChange={this.handleLocalUserPositionChanged}
                            isAnyMegaphoneActive={this.isAnyMegaphoneActive()}
                            isSpeaking={this.state.localUser.isSpeaking}
                        /></div>}
                        {this.renderRemoteTracks()}
                    </div>}
                    {<div>
                        {this.state.localScreenShare && <JitsiScreenShare 
                            user={this.state.localScreenShare}
                            originUser={this.state.localUser}
                            volume={1}
                            sessionId={this.props.sessionId}     
                            onPositionChange={this.handleScreenSharePositionChanged}
                            onScaleScreenShare={this.handleScreenShareScalingChanged}
                        />}
                        {this.renderRemoteScreenShares()}
                    </div>}
                </div>

                {/* <button
                    onClick={this.testfunction}
                    style={{ position: 'absolute', bottom: 110, left: this.props.sidebar.width + 20 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={this.state.localUser.cameraOn? "video icon" : "icon-fas-video-slash-solid"}
                        style={this.state.localUser.cameraOn ? { color: '#110303' } : { color: '#878787' }}
                    />}
                /> */}
                <button
                    onClick={this.toggleCamera}
                    style={{ position: 'absolute', bottom: 20, left: this.props.sidebar.width + 20 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={this.state.localUser.cameraOn? "video icon" : "icon-fas-video-slash-solid"}
                        style={this.state.localUser.cameraOn ? { color: '#c10303' } : { color: '#878787' }}
                    />}
                />
                <button
                    onClick={this.toggleMic}
                    style={{ position: 'absolute', bottom: 20, left: this.props.sidebar.width + 70 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={this.state.localUser.micOn ? 'icon microphone' : 'icon microphone slash'}
                        style={this.state.localUser.micOn ? { color: '#c10303' } : { color: '#878787' }}
                    />}
                />
                <button
                    onClick={this.toggleMegaphone}
                    style={{ position: 'absolute', bottom: 20, left: this.props.sidebar.width + 120 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={'icon bullhorn'}
                        style={this.state.localUser.megaphoneOn ? { color: '#c10303' } : { color: '#878787' }}
                    />}
                />
                <button
                    onClick={this.toggleScreenShare}
                    style={{ position: 'absolute', bottom: 20, left: this.props.sidebar.width + 170 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={'icon desktop'}
                        style={this.state.localScreenShare ? { color: '#c10303' } : { color: '#878787' }}
                    />}
                />
                <button
                    onClick={this.toggleHandUp}
                    style={{ position: 'absolute', bottom: 20, left: this.props.sidebar.width + 220 + "px", pointerEvents: 'all' }}
                    className={'ui circular icon button big video-control'}
                    children={<i
                        className={'icon hand paper'}
                        style={this.state.localUser.handUpOn ? { color: '#21AA21' } : { color: '#878787'}}
                    />}
                />

                <div className={'show_buttons'}>
                    <button
                        onClick={() => this.toggleEmoji("thumbs up")}
                        style={{ position: 'absolute', bottom: 20, left: this.props.sidebar.width + 270 + "px", pointerEvents: 'all' }}
                        className={'ui circular icon button big video-control'}
                        children={<i
                            className={'icon thumbs up'}
                            style={this.state.localUser.showIconName == "thumbs up" ? { color: '#21AA21' } : { color: '#878787'}}
                        />}
                    />

                    <button
                        onClick={() => this.toggleEmoji("thumbs down")}
                        style={{ position: 'absolute', bottom: 60, left: this.props.sidebar.width + 270 + "px", pointerEvents: 'all' }}
                        className={'ui circular icon button big video-control icon_button_menue'}
                        children={<i
                            className={'icon thumbs down icon_button_menue'}
                            style={this.state.localUser.showIconName == "thumbs down" ? { color: '#CC1B1B' } : { color: '#878787'}}
                        />}
                    />

                    <button
                        onClick={() => this.toggleEmoji("beer")}
                        style={{ position: 'absolute', bottom: 100, left: this.props.sidebar.width + 270 + "px", pointerEvents: 'all' }}
                        className={'ui circular icon button big video-control icon_button_menue'}
                        children={<i
                            className={'icon beer icon_button_menue'}
                            style={this.state.localUser.showIconName == "beer" ? { color: '#FFFF00' } : { color: '#878787'}}
                        />}
                    />
                </div>

            </div>
        );
    };
}

export default JitsiMeeting;
