export const CONNECT_SOCKET = '@@api/ConnectSocket';
export const DISCONNECT_SOCKET = '@@api/DisconnectSocket';
export const SOCKET_CONNECTED = '@@api/SocketConnected';
export const SOCKET_DISCONNECTED = '@@api/SocketConnected';
export const SOCKET_CONNECTION_LOST = '@@api/SocketConnectionLost';
export const SEND_SOCKET_CMD = '@@api/SendSocketCmd';
export const LISTEN_ON_SOCKET_CHANNEL = '@@api/ListenOnSocketChannel';
export const STOP_LISTENING_ON_SOCKET_CHANNEL = '@@api/StopListeningOnSocketChannel';
export const DEFAULT_SOCKET_CMD_TIMEOUT = 2000;

