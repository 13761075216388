// Commands
export const ADD_TEAM = '@@InspectioTeams/AddTeam';
export const RENAME_TEAM = '@@InspectioTeams/RenameTeam';
export const CHANGE_TEAM_DESCRIPTION = '@@InspectioTeams/ChangeTeamDescription';
export const INVITE_USER_TO_TEAM = '@@InspectioTeams/InviteUserToTeam';
export const RESEND_TEAM_INVITATION = '@@InspectioTeams/ResendTeamInvitation';
export const REVOKE_TEAM_INVITATION = '@@InspectioTeams/RevokeTeamInvitation';
export const REMOVE_MEMBER_FROM_TEAM = '@@InspectioTeams/RemoveMemberFromTeam';
export const FETCH_USER_TEAMS = '@@InspectioTeams/FetchUserTeams';
export const UPDATE_TEAMS = '@@InspectioTeams/AddTeams';
export const ADMIN_GRANT_TEAM_ACCESS_TO_BOARD = '@@InspectioTeams/AdminGrantTeamAccessToBoard';
export const ADMIN_REVOKE_TEAM_ACCESS_TO_BOARD = '@@InspectioTeams/AdminRevokeTeamAccessToBoard';
export const DELETE_TEAM = '@@InspectioTeams/DeleteTeam';

// Events
export const TEAM_ADDED = '@@InspectioTeams/TeamAdded';
export const TEAM_RENAMED = '@@InspectioTeams/TeamRenamed';
export const TEAM_DESCRIPTION_CHANGED = '@@InspectioTeams/TeamDescriptionChanged';
export const TEAMS_FETCHED = '@@InspectioTeams/TeamsFetched';
export const MEMBER_REMOVED_FROM_TEAM = '@@InspectioTeams/MemberRemovedFromTeam';
export const TEAM_DELETED = '@@InspectioTeams/TeamDeleted';
export const TEAM_INVITATIONS_LOADED = '@@InspectioTeams/TeamInvitationsLoaded';
export const TEAM_INVITATION_RESENT = '@@InspectioTeams/TeamInvitationResent';
export const TEAM_INVITATION_REVOKED = '@@InspectioTeams/TeamInvitationRevoked';

// @TODO move to inspectioBoards context?
export const TEAM_ACCESS_TO_BOARD_GRANTED = '@InspectioBoards/TeamAccessToBoardGranted';
export const TEAM_ACCESS_TO_BOARD_REVOKED = '@InspectioBoards/TeamAccessToBoardRevoked';

export const MOVE_TEAM_TO_ORGANIZATION = '@@InspectioTeams/MoveTeamToOrganization';
export const TEAM_MOVED_TO_ORGANIZATION = '@@InspectioTeams/TeamMovedToOrganization';
