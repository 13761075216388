import {fromJS} from "immutable";
import {combineReducers} from "redux-immutable";
import {ActionType} from "typesafe-actions";
import {Command} from "../actions";
import {sidebarReducer, SidebarState} from "./applySidebarActions";


export type InspectioLayoutAction = ActionType<typeof Command>;

export interface InspectioSidebarState {
    sidebar: SidebarState
}

export const inspectioSidebarReducer = combineReducers<InspectioSidebarState, InspectioLayoutAction>({
    sidebar: sidebarReducer
}, () => fromJS({sidebar: undefined}));

