import {List, Record} from "immutable";
import {ApplicationState} from "../../reducer";
import {MODULE} from "../index";
import {BoardId} from "../model/Board";
import {PlayshotId, PlayshotSummary} from "../model/PlayshotSummary";

export const getPlayshotSummaryById = (state: Record<ApplicationState>, uid: PlayshotId): PlayshotSummary | undefined => state.getIn([MODULE, 'playshots', uid]);

export const makePlayshotSummarySelector = (uid: PlayshotId): (state: Record<ApplicationState>) => PlayshotSummary | undefined => (state: Record<ApplicationState>) => getPlayshotSummaryById(state, uid);

export const getPlayshotSummariesByBoardId = (state: Record<ApplicationState>, boardId: BoardId): List<PlayshotSummary> => {
  const summaries: List<PlayshotSummary> = List(state.getIn([MODULE, 'playshots']).toIndexedSeq().toArray());

  return summaries.filter(playshot => playshot.boardId === boardId);
}

export const makePlayshotSummaryListSelector = (boardId: BoardId): (state: Record<ApplicationState>) => List<PlayshotSummary> => {
  return (state: Record<ApplicationState>): List<PlayshotSummary> => {
    return getPlayshotSummariesByBoardId(state, boardId);
  }
};
