import { Record} from "immutable";
import {createSelector} from "reselect";
import {ApplicationState} from "../../reducer";
import {MODULE} from "../index";
import {BoardClient} from "../model/BoardClient";
import {BoardClientsState} from "../reducers/applyBoardClientActions";

export const boardClientsSelector = (state: Record<ApplicationState>, props: any): BoardClientsState =>
    state.getIn([MODULE, 'boardClients']);

export const makeBoardClientSelector = (boardId: string): (state: Record<ApplicationState>, props?: any) => BoardClient|null|undefined => {
    return createSelector(
        [boardClientsSelector],
        (boardClients): BoardClient|null|undefined =>  {
            return boardClients.has(boardId) ? boardClients.get(boardId) : undefined;
        }
    )
};
