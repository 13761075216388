import {List} from "immutable";
import * as React from 'react';
import {FormEvent, useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {useGraph} from "../../../hooks/useGraph";
import {
  Graph,
  GraphPoint,
  Node,
  NodeSize,
} from "../../../model/Graph";
import {ImmutableDDDWizardContext, isDDDAction} from "../../../service/cody-wizard/context/ddd-action";
import {ImmutableFeatureContext} from "../../../service/cody-wizard/context/feature-context";
import {ImmutablePolicyContext, isPolicyContext} from "../../../service/cody-wizard/context/policy-context";
import {updateContext, WizardContext} from "../../../service/cody-wizard/context/wizard-context";
import EditorDivider from "../Editor/EditorDivider";
import {WithWizardStepProps} from "../WizardModal";
import {suggestNewCommandName} from "./DddCommand";
import StatusSelect from "./Feature/StatusSelect";
import TaskLinkInput from "./Feature/TaskLinkInput";

interface OwnProps {

}

export type FeatureStatus = 'important' | 'planned' | 'ready' | 'deployed';
export const POSSIBLE_STATUS = ['important', 'planned', 'ready', 'deployed'];

type FeatureProps = OwnProps & WithWizardStepProps<ImmutableDDDWizardContext | ImmutablePolicyContext | ImmutableFeatureContext> & WithNamespaces;

const Feature = (props: FeatureProps) => {
  const [graph] = useGraph();
  const elementsTree = useElementsTree(props.ctx.board.uid);
  const [selectedFeature, setSelectedFeature] = useState<Node|null>(null);
  const [status, setStatus] = useState<FeatureStatus | undefined>(undefined);
  const [taskLink, setTaskLink] = useState<string | null>(null);

  useEffect(() => {
    resetForm();

    if(props.ctx.feature) {
      setSelectedFeature(props.ctx.feature);
      setStatus(getStatus(props.ctx.feature));
      setTaskLink(graph.getFeatureTaskLink(props.ctx.feature));
    } else {
      setStatus(undefined);
      setTaskLink(null);
    }

  }, [props.ctx.feature])

  const resetForm = () => {
    setSelectedFeature(null);
    setStatus(undefined);
    setTaskLink(null);
  }

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  const handleStatusChanged = (newStatus: FeatureStatus | undefined) => {
    setStatus(newStatus);

    if(props.mode === "sidebar" && selectedFeature && newStatus) {
      graph.changeFeatureStatus(selectedFeature, newStatus);
    }
  }

  const handleTaskLinkChanged = (newLink: string | null) => {
    setTaskLink(newLink);

    if(selectedFeature) {
      graph.setFeatureTaskLink(selectedFeature, newLink);
    }
  }

  return <form className='ui form' onSubmit={handleSubmit}>
    <EditorDivider content="Status" />
    <StatusSelect status={status} disabled={!selectedFeature || !selectedFeature.isEnabled()} onStatusChanged={handleStatusChanged} />
    <EditorDivider content="Task" />
    <TaskLinkInput link={taskLink} onLinkChanged={handleTaskLinkChanged} disabled={!selectedFeature || !selectedFeature.isEnabled()} />
    <div style={{marginTop: '100px'}} />
  </form>
};

export default withNamespaces()(Feature);

export const suggestNewFeatureName = (ctx: ImmutableDDDWizardContext | ImmutablePolicyContext | ImmutableFeatureContext): string | undefined => {
  if(isDDDAction(ctx)) {
    if(ctx.command) {
      return ctx.command.getName();
    }

    return suggestNewCommandName(ctx);
  }

  if(isPolicyContext(ctx)) {
    if(ctx.event) {
      return `On ${ctx.event.getName()}`;
    }

    if(ctx.policy) {
      return ctx.policy.getName();
    }
  }
}

export const repositionFeature = (feature: Node, ctx: WizardContext, graph: Graph): Node => {
  if(graph.isEventModelingEnabled()) {
    return feature;
  }

  const allNodes = getAllNodesFromCtx(ctx);

  if(allNodes.count() === 0) {
    return feature;
  }

  // tslint:disable-next-line:one-variable-per-declaration
  let mostLeft: number, mostTop: number, mostRight: number, mostBottom: number;

  allNodes.forEach(node => {
    const graphNode = graph.getNode(node.getId());

    if(graphNode) {
      node = graphNode;
    }

    let geo = node.getGeometry();

    if(node.getParent() && node.getParent()!.getGeometry()) {
      geo = {x: geo.x + node.getParent()!.getGeometry().x, y: geo.y + node.getParent()!.getGeometry().y};
    }

    const size = node.getSize();

    if(geo.x < mostLeft || typeof mostLeft === 'undefined') {
      mostLeft = geo.x;
    }

    if(geo.y < mostTop || typeof mostTop === 'undefined') {
      mostTop = geo.y;
    }

    if(geo.x + size.width > mostRight || typeof mostRight === 'undefined') {
      mostRight = geo.x + size.width;
    }

    if(geo.y + size.height > mostBottom || typeof mostBottom === 'undefined') {
      mostBottom = geo.y + size.height;
    }
  })

  const padding = 160;

  const featureGeo: GraphPoint = {x: mostLeft! - padding, y: mostTop! - padding};
  const featureSize: NodeSize = {width: Math.abs((mostRight! + padding) - (mostLeft! - padding)), height: Math.abs((mostTop! - padding) - (mostBottom! + padding))};

  return feature.changeGeometry(featureGeo).changeSize(featureSize);
}

export const getAllNodesFromCtx = (ctx: WizardContext): List<Node> => {
  switch (ctx.type) {
    case "dddAction":
      return getAllNodesFromDDDActionCtx(ctx as ImmutableDDDWizardContext);
    case "policy":
      return getAllNodesFromPolicyCtx(ctx as ImmutablePolicyContext);
    case "feature":
      return (ctx as ImmutableFeatureContext).feature.children();
    default:
      return List([]);
  }
}

const getAllNodesFromPolicyCtx = (ctx: ImmutablePolicyContext): List<Node> => {
  const allNodes: Node[] = [];

  if(ctx.event) {
    allNodes.push(ctx.event);
  }

  if(ctx.policy) {
    allNodes.push(ctx.policy);
  }

  allNodes.push(...ctx.policyTargets.toArray());

  return List(allNodes);
}

const getAllNodesFromDDDActionCtx = (ctx: ImmutableDDDWizardContext): List<Node> => {
  const allNodes: Node[] = [];

  if(ctx.uiViews) {
    allNodes.push(...ctx.uiViews.toArray());
  }

  if(ctx.parentUi) {
    allNodes.push(ctx.parentUi);
  }

  if(ctx.ui) {
    allNodes.push(ctx.ui);
  }

  if(ctx.command) {
    allNodes.push(ctx.command);
  }

  if(ctx.aggregate) {
    allNodes.push(ctx.aggregate);
  }

  allNodes.push(...ctx.events.toArray());

  if(ctx.state) {
    allNodes.push(ctx.state);
  }

  if(ctx.stateList) {
    allNodes.push(ctx.stateList)
  }

  return List(allNodes);
}

const getStatus = (feature: Node): FeatureStatus | undefined => {
  const tags = feature.getTags().toArray();

  for (const tag of tags) {
    if(POSSIBLE_STATUS.includes(tag)) {
      return tag as FeatureStatus;
    }
  }
}


