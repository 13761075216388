import {List, Record} from "immutable";
import {createSelector} from "reselect";
import {ApplicationState} from "../../reducer";
import * as User from "../../User";
import {UserId} from "../../User/model/UserInfo";
import {MODULE} from "../index";
import {OrganizationId} from "../model/Organization";
import {UserOrganizationInfo} from "../model/UserOrganizationInfo";

export const getUserOrganizationInfoById = (state: Record<ApplicationState>, uid: OrganizationId): UserOrganizationInfo | undefined => state.getIn([MODULE, 'UserOrganizationInfo', uid]);

export const makeUserOrganizationInfoSelector = (uid: OrganizationId): (state: Record<ApplicationState>) => UserOrganizationInfo | undefined => (state: Record<ApplicationState>) => getUserOrganizationInfoById(state, uid);

export const userOrganizationInfoListSelector = (state: Record<ApplicationState>, props: any): List<UserOrganizationInfo> =>
    List(state.getIn([MODULE, 'UserOrganizationInfo']).toIndexedSeq().toArray()) || List();

export const makeUserOrganizationInfoListSelector = (userId: UserId): (state: Record<ApplicationState>, props?: any) => List<UserOrganizationInfo> => {
    return createSelector(
        [userOrganizationInfoListSelector],
        (UserOrganizationInfoList): List<UserOrganizationInfo> => {
            return UserOrganizationInfoList.filter(info => info.userId === userId);
        }
    )
};

export const makeActiveUserOrganizationInfoSelector = (): (state: Record<ApplicationState>, props?: any) => UserOrganizationInfo | undefined => {
    return createSelector(
        [userOrganizationInfoListSelector, User.UserSelector.currentUserSelector],
        (userOrganizationInfoList, currentUser): UserOrganizationInfo | undefined => {
            if(currentUser.activeOrganization === null) {
                return;
            }

            return userOrganizationInfoList.find(orga => orga.uid === currentUser.activeOrganization);
        }
    )
}
