import { History } from 'history'
import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    RouteComponentProps, Switch, withRouter
} from "react-router-dom";
import { Store } from 'redux';
import {
  Button,
  Container,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Image,
  Menu,
  MenuItem,
  Message, MessageContent,
  Segment
} from 'semantic-ui-react';
import i18n from './i18n';
import { BoardOverview, EditBoard, MxGraphBoard, UserProfile} from "./InspectioBoards";
import BoardHistory from "./InspectioBoards/components/BoardHistory";
import BoardInvitation from "./InspectioBoards/components/BoardInvitation";
import TeamInvitation from "./InspectioTeams/components/TeamInvitation";
import TeamOverview from "./InspectioTeams/components/TeamOverview";
import * as Layout from './Layout';
import Sidebar from "./Layout/Sidebar";
import {NotificationContainer} from './NotificationSystem';
import { ApplicationState } from './reducer';
import * as Routes from './routes';
import UpdatePage from "./Updates/components/UpdatePage";
import BoardElementDetails from "./InspectioBoards/components/BoardElementDetails";
import AcceptOrganizationInvitation from "./Organization/components/AcceptOrganizationInvitation";
import CodyConsole from "./InspectioBoards/components/CodyConsole";
import OrganizationOverview from "./Organization/components/OrganizationOverview";
import BoardImportFromLink from "./InspectioBoards/components/BoardImportFromLink";
import MxGraphBoardRedirect from "./InspectioBoards/components/MxGraphBoardRedirect";
// tslint:disable-next-line
import './theme/semantic/semantic.css';
// tslint:disable-next-line
import './theme/css/style.css';
import BoardTimer from "./InspectioBoards/components/MxGraphBoard/BoardTimer";
import PlayshotModal from "./InspectioBoards/components/MxGraphBoard/PlayshotModal";




// The Main component renders one of provided
// Routes (provided that one matches).
const Main = () => (
    <Switch>
        <Redirect exact={true} path={Routes.root} to={Routes.inspectio}/>
        <Route exact={true} path={Routes.inspectio} component={BoardOverview}/>
        <Route exact={true} path={Routes.inspectioEditBoard} component={EditBoard}/>
        <Route exact={true} path={Routes.inspectioBoardWorkspace} component={MxGraphBoard}/>
        <Route exact={true} path={Routes.boardInvitation} component={BoardInvitation} />
        <Route exact={true} path={Routes.userProfile} component={UserProfile} />
        <Route exact={true} path={Routes.teamOverview} component={TeamOverview} />
        <Route exact={true} path={Routes.teamInvitation} component={TeamInvitation} />
        <Route exact={true} path={Routes.Updates} component={UpdatePage} />
        <Route exact={true} path={Routes.BoardRedirect} component={MxGraphBoardRedirect} />
        <Route exact={true} path={Routes.organizationOverview} component={OrganizationOverview} />
        <Route exact={true} path={Routes.organizationInvitation} component={AcceptOrganizationInvitation} />
        <Route exact={true} path={Routes.boardImportLink} component={BoardImportFromLink} />
    </Switch>
);

const RootFC = (props: RouteComponentProps) => (
    <Container fluid={true}>
        <Layout.TopMenu />
        <div style={{zIndex:103,position:"fixed",display:"flex",flexDirection:"column",top:"0",bottom:"0",left:"0",overflowX:"hidden",flex:"1"}}>
        <Sidebar />
        </div>
        <div style={{marginLeft:"250px", paddingTop:"90px"}}>
        <Main/>
        </div>
        <NotificationContainer/>
        <Route exact={true} path={Routes.inspectioBoardWorkspace} component={CodyConsole} />
        <Route exact={true} path={Routes.inspectioBoardWorkspace} component={BoardHistory} />
        <Route exact={true} path={Routes.inspectioBoardWorkspace} component={BoardElementDetails} />
        <Route exact={true} path={Routes.inspectioBoardWorkspace} component={BoardTimer} />
        <Route exact={true} path={Routes.inspectioBoardWorkspace} component={PlayshotModal} />
    </Container>
);

const Root = withRouter(RootFC);

// Separate props from state and props from dispatch to their own interfaces.

// Any additional component props go here.
interface OwnProps {
    store: Store<ApplicationState>
    history: History
}

// Create an intersection type of the component props and our Redux props.
type AllProps =  OwnProps

class App extends React.Component<AllProps> {
    public render() {
        const { store } = this.props;

        return (
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <Router basename="/">
                        <Root/>
                    </Router>
                </Provider>
            </I18nextProvider>
        )
    }
}

export default App;
