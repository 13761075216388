import {List} from "immutable";
import React from 'react'
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import EditableTextline from "../../core/components/EditableTextline";
import * as Action from "../actions";
import {Board, BoardName} from "../model/Board";

const INPUT_CHAR_SIZE = 9;
const CHAR_SIZE_UNIT = 'px';
const ADDITIONAL_CHAR_PADDING = 20;

export interface OwnProps {
    board: Board;
}


export type EditBoardFormProps = OwnProps & WithNamespaces;



const EditBoardForm = (props: EditBoardFormProps) => {
    const dispatch = useDispatch();

    const handleNameChanged = (newName: BoardName) => {
        const changedBoard = props.board.merge({name: newName});
        dispatch(Action.Command.renameBoard(changedBoard));
    }

    const handleAbbreviationChanged = (newAbbr: string) => {
        const changedBoard = props.board.merge({nameAbbreviation: newAbbr});
        dispatch(Action.Command.changeBoardAbbreviation(changedBoard));
    }

    return <form className="ui form">
        <h3 style={{margin: 0, cursor: "pointer"}}>
            <EditableTextline
                text={props.board.name}
                charSize={INPUT_CHAR_SIZE}
                widthUnit={CHAR_SIZE_UNIT}
                padding={ADDITIONAL_CHAR_PADDING}
                required={true}
                onTextChanged={handleNameChanged}
                placeholder={props.t('insp.board.form.board_name') as string}
            />
            &nbsp;/&nbsp;
            <EditableTextline
                text={props.board.nameAbbreviation}
                charSize={INPUT_CHAR_SIZE}
                widthUnit={CHAR_SIZE_UNIT}
                padding={ADDITIONAL_CHAR_PADDING}
                required={true}
                maxLength={3}
                onTextChanged={handleAbbreviationChanged}
                placeholder={props.t('insp.board.form.board_name') as string}
            />
        </h3>
    </form>
};

export default withNamespaces()(EditBoardForm);

