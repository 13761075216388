import * as React from "react";

export const monaco = () => (window as any).monaco;

export interface ModelData {
    fileId: string;
    language: 'json'|'xml'|'yaml';
    value: string;
    schema?: string|undefined;
    onChange?: (e: { isFlush: boolean, isRedoing: boolean, isUndoing: boolean }) => void;
}

interface OwnState {
    internalData: Record<string, { onChangeDisposable: any }>;
}

interface OwnProps {
    options?: object;
    style?: React.CSSProperties;
    className?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    containerId?: string;
}

type CodeEditorProps = OwnProps;

const globalSchemaRegistry: {[fileId: string]: {uri: string, fileMatch: string[], schema: any}} = {};

class CodeEditor extends React.Component<CodeEditorProps, OwnState> {

    private editor: any;
    private containerId: string;

    constructor(props: CodeEditorProps) {
        super(props);

        this.disposeOnChangeHandler = this.disposeOnChangeHandler.bind(this);

        this.containerId = props.containerId || 'monaco-container';

        this.state = {
            internalData: {}
        };
    }

    public componentDidMount() {
        this.editor = monaco().editor.create(document.getElementById(this.containerId), this.props.options || {});
    }

    public componentWillUnmount() {
        Object.keys(this.state.internalData).forEach(this.disposeOnChangeHandler);
        this.editor = undefined;
    }

    public render() {
        return (
            <div
                id={this.containerId}
                style={this.props.style}
                className={this.props.className}
                onFocus={() => {
                    if(this.props.onFocus) {
                        this.props.onFocus();
                    }
                }}
                onBlur={() => {
                    if(this.props.onBlur) {
                        this.props.onBlur();
                    }
                }}
            />
        );
    }

    public retrievePayload(): string {
        return this.editor.getValue();
    }

    public initializeModel(modelData: ModelData): void {
        const modelUri = monaco().Uri.parse(modelData.fileId);
        let model = monaco().editor.getModel(modelUri);

        this.disposeOnChangeHandler(modelData.fileId);

        if (null === model) {
            model = monaco().editor.createModel(modelData.value, modelData.language, modelUri);
            model.updateOptions({ tabSize: 2 });
        } else {
            monaco().editor.setModelLanguage(model, modelData.language);

            if (modelData.value !== model.getValue()) {
                model.setValue(modelData.value);
            }
        }

        if (modelData.onChange) {
            this.initializeOnChangeHandler(modelData.fileId, model, modelData.onChange);
        }

        if (modelData.language === 'json' && modelData.schema) {
            this.initializeJsonDiagnostics(modelData.fileId, modelUri, modelData.schema);
        }

        this.editor.setModel(model);
    }

    public addCommand(keybinding: number, handler: () => void): void {
        this.editor.addCommand(keybinding, handler);
    }

    public addKeyDownHandler(handler: (e: KeyboardEvent) => void): {dispose: () => void} {
        return this.editor.onKeyDown(handler);
    }

    public focus(): void {
        this.editor.focus();
    }

    private disposeOnChangeHandler(fileId: string) {
        if (this.state.internalData[fileId] && this.state.internalData[fileId].onChangeDisposable) {
            this.state.internalData[fileId].onChangeDisposable.dispose();
            this.updateInternalData(fileId, { onChangeDisposable: undefined });
        }
    }

    private initializeOnChangeHandler(fileId: string, model: any, onChange: (e: { isFlush: boolean, isRedoing: boolean, isUndoing: boolean }) => void): void {
        // Make sure that any existing handler is disposed upon setting a new one
        this.disposeOnChangeHandler(fileId);

        const newDisposable = model.onDidChangeContent((e: { isFlush: boolean, isRedoing: boolean, isUndoing: boolean }) => {
            onChange(e);
        });

        this.updateInternalData(fileId, { onChangeDisposable: newDisposable });
    }

    private initializeJsonDiagnostics(fileId: string, modelUri: any, schema: string): void {
        globalSchemaRegistry[fileId] = {
            uri: modelUri.toString(),
            fileMatch: [modelUri.toString()],
            schema: JSON.parse(schema)
        };

        monaco().languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            enableSchemaRequest: false,
            allowComments: true,
            schemas: Object.values(globalSchemaRegistry),
        });
    }

    private updateInternalData(fileId: string, data: { onChangeDisposable: any }): void {
        this.setState({
            internalData: {
                ...this.state.internalData,
                [fileId]: data
            }
        });
    }
}

export default CodeEditor;
