import {List} from "immutable";
import { action } from 'typesafe-actions';
import * as Socket from '../../Socket';
import {UserModel} from "../../User/model";
import {BoardGraphElement, BoardInvitation, BoardPermissionForTeam, BoardPermissionForUser} from "../model";
import { BoardSync } from '../model';
import {Board, BoardId} from "../model/Board";
import {BoardClient} from "../model/BoardClient";
import {Permissions} from "../model/BoardPermissionForUser";
import {Graph} from "../model/Graph";
import {StickyTemplate} from "../model/StickyTemplate";
import {ActiveGraphElement} from "../reducers/applyActiveGraphElementActions";
import {
    ACTIVE_GRAPH_ELEMENT_CHANGED,
    ACTIVE_GRAPH_ELEMENT_LABEL_CHANGED,
    ACTIVE_GRAPH_ELEMENT_METADATA_CHANGED,
    BOARD_ACCESS_REVOKED,
    BOARD_CLIENT_FETCHED,
    BOARD_CLIENT_NOT_CONFIGURED,
    BOARD_CLIENT_SECRET_FETCHED,
    BOARD_EXPORTED,
    BOARD_GRAPH_ELEMENTS_FETCHED,
    BOARD_IMPORT_STARTED,
    BOARD_IMPORTED,
    BOARD_INITIALIZED,
    BOARD_INVITATION_RESENT,
    BOARD_INVITATION_REVOKED,
    BOARD_INVITATION_SENT,
    BOARD_PERMISSIONS_CHANGED,
    BOARD_PERMISSIONS_FOR_TEAMS_FETCHED,
    BOARD_PERMISSIONS_FOR_USERS_FETCHED, BOARD_SHARED_WITH_ANYONE,
    BOARD_SHARED_WITH_ORGANIZATION_MEMBERS,
    BOARD_STICKY_TEMPLATES_FETCHED,
    INITIAL_BOARD_LIST_LOADED,
    LOCAL_BOARD_CHANGED,
    LOCAL_BOARD_UPDATED_WITH_REMOTE_CHANGE,
    OPEN_BOARD_INVITATIONS_FETCHED, PLAYSHOT_SUMMARY_LIST_FETCHED,
    REMOTE_BOARD_CHANGED, RUN_CODY_ON_CHANGED,
    STICKY_ADVANCED_CONFIG_UPDATED,
    STICKY_TEMPLATE_SET,
    STICKY_TEMPLATES_IMPORTED,
    TEAM_BOARD_PERMISSIONS_CHANGED,
    USER_BOARD_MOVED_TO_ORGANIZATION
} from './constants';
import {RunCodyOption} from "../reducers/RunCodyOn";
import {PlayshotSummary} from "../model/PlayshotSummary";

export const remoteBoardChanged = (boardChanged: BoardSync.BoardChanged) => action(REMOTE_BOARD_CHANGED, {boardChanged});

export const localBoardChanged = (boardChanged: BoardSync.BoardChanged) => action(LOCAL_BOARD_CHANGED, {boardChanged});

export const boardImportStarted = (boardId: BoardId) => action(BOARD_IMPORT_STARTED, {boardId});
export const boardImported = (boardId: BoardId, board: Board, graph: Graph) => action(BOARD_IMPORTED, {boardId, board, graph});
export const boardExported = (boardId: BoardId) => action(BOARD_EXPORTED, {boardId});
export const boardInitialized = (board: Board, graph: Graph) => action(BOARD_INITIALIZED, {board, graph});

export const initialBoardListLoaded = () => action(INITIAL_BOARD_LIST_LOADED);

export const localBoardUpdatedWithRemoteChange = (boardId: BoardId, board: Board, graph: Graph) => action(LOCAL_BOARD_UPDATED_WITH_REMOTE_CHANGE, {boardId, board, graph});

export const boardInvitationSent = (boardInvitation: BoardInvitation.BoardInvitation) => action(BOARD_INVITATION_SENT, {boardInvitation});
export const boardInvitationResent = (boardInvitation: BoardInvitation.BoardInvitation) => action(BOARD_INVITATION_RESENT, {boardInvitation});
export const boardInvitationRevoked = (boardInvitation: BoardInvitation.BoardInvitation) => action(BOARD_INVITATION_REVOKED, {boardInvitation});
export const boardAccessRevoked = (boardId: BoardId, userId: UserModel.UserId) => action(BOARD_ACCESS_REVOKED, {boardId, userId});
export const boardPermissionsChanged = (boardId: BoardId, userId: UserModel.UserId, permissions: Permissions) => action(BOARD_PERMISSIONS_CHANGED, {boardId, userId, permissions});
export const teamBoardPermissionsChanged = (boardPermission: BoardPermissionForTeam.BoardPermissionForTeam) => action(TEAM_BOARD_PERMISSIONS_CHANGED, boardPermission);
export const boardSharedWithOrganizationMembers = (boardId: BoardId, permissions: Permissions) => action(BOARD_SHARED_WITH_ORGANIZATION_MEMBERS, {boardId, permissions});
export const boardSharedWithAnyone = (boardId: BoardId, permissions: Permissions) => action(BOARD_SHARED_WITH_ANYONE, {boardId, permissions});

export const boardPermissionsForUsersFetched = (boardPermissions: List<BoardPermissionForUser.BoardPermissionForUser>) =>
    action(BOARD_PERMISSIONS_FOR_USERS_FETCHED, {boardPermissions});

export const boardPermissionsForTeamsFetched = (boardPermissions: List<BoardPermissionForTeam.BoardPermissionForTeam>) =>
    action(BOARD_PERMISSIONS_FOR_TEAMS_FETCHED, {boardPermissions});

export const openBoardInvitationsFetched = (boardInvitations: List<BoardInvitation.BoardInvitation>) => action(OPEN_BOARD_INVITATIONS_FETCHED, {boardInvitations});

export const playshotSummaryListFetched = (summaries: List<PlayshotSummary>) => action(PLAYSHOT_SUMMARY_LIST_FETCHED, {summaries});

export const activeGraphElementChanged = (boardId: BoardId, element: ActiveGraphElement | undefined) => action(ACTIVE_GRAPH_ELEMENT_CHANGED, {boardId, element});
export const activeGraphElementLabelChanged = (boardId: BoardId, elementId: string, label: string) => action(ACTIVE_GRAPH_ELEMENT_LABEL_CHANGED, {boardId, elementId, label});
export const activeGraphElementMetadataChanged = (boardId: BoardId, elementId: string, metadata: string | undefined) => action(ACTIVE_GRAPH_ELEMENT_METADATA_CHANGED, {boardId, elementId, metadata});
export const boardGraphElementsFetched = (boardId: BoardId, elements: List<BoardGraphElement.BoardGraphElement>) => action(BOARD_GRAPH_ELEMENTS_FETCHED, { boardId, elements });
export const boardClientFetched = (boardId: BoardId, client: BoardClient) => action(BOARD_CLIENT_FETCHED, { boardId, client });
export const boardClientNotConfigured = (boardId: BoardId) => action(BOARD_CLIENT_NOT_CONFIGURED, { boardId });
export const boardClientSecretFetched = (boardId: BoardId, clientSecret: string) => action(BOARD_CLIENT_SECRET_FETCHED, { boardId, clientSecret });

export const boardStickyTemplatesFetched = (stickyTemplates: List<StickyTemplate>) => action(BOARD_STICKY_TEMPLATES_FETCHED, {stickyTemplates});
export const stickyTemplateSet = (stickyTemplate: StickyTemplate) => action(STICKY_TEMPLATE_SET, {stickyTemplate});
export const stickyAdvancedConfigUpdated = (stickyTemplate: StickyTemplate) => action(STICKY_ADVANCED_CONFIG_UPDATED, {stickyTemplate});
export const stickyTemplatesImported = (stickyTemplates: List<StickyTemplate>) => action(STICKY_TEMPLATES_IMPORTED, {stickyTemplates});
export const userBoardMovedToOrganization = (board: Board) => action(USER_BOARD_MOVED_TO_ORGANIZATION, board);

export const runCodyOnChanged = (runCodyOn: RunCodyOption) => action(RUN_CODY_ON_CHANGED, {runCodyOn});
