export const Palette: {stickyColors: { [type: string]: string }} = {
    stickyColors: {
        event: '#FF9F4B',
        command: '#26C0E7',
        role: '#f7f727',
        projection: '#3acbc0',
        aggregate: '#F5E339',
        document: '#73dd8e',
        policy: '#EABFF1',
        hotSpot: '#f31d30',
        externalSystem: '#ec98ac',
        ui: '#000000',
        feature: '#cfcfcf',
        boundedContext: '#a2a2a2',
        edge: '#414141',
        textCard: '#ffdfd3'
    }
};
