import {Map} from "immutable";
import { Reducer } from 'redux';
import {ActionType} from "typesafe-actions";
import {upsertAggregates} from "../../core/reducer/applyAggregateChanged";
import * as Action from "../actions";
import * as Events from "../actions/events";
import {IioUserInfo} from "../model/IioUserInfo";

export interface IioUserInfoState extends Map<string, IioUserInfo> {

}

export const initialState = Map<string, IioUserInfo>();

type Event = ActionType<typeof Events>;

const reducer: Reducer<IioUserInfoState, Event> = (state: IioUserInfoState = initialState, event: Event): IioUserInfoState => {
    switch (event.type) {
        case Action.Type.IIO_USERS_INFO_LOADED:
            return upsertAggregates(state, event.payload.usersInfo);
        default:
            return  state;
    }
};

export {reducer as iioUserInfoReducer};