import {Map} from "immutable";
import { Reducer } from 'redux';
import {ActionType} from "typesafe-actions";
import * as Action from "../actions";
import * as Events from "../actions/events";

export interface UserSettingsState extends Map<string, number|boolean> {

}

export const initialState = Map<string, number|boolean>();

type Event = ActionType<typeof Events>;

const reducer: Reducer<UserSettingsState, Event> = (state: UserSettingsState = initialState, event: Event): UserSettingsState => {
    switch (event.type) {
        case Action.Type.USER_SETTINGS_LOADED:
            return state.merge(event.payload.userSettings.values, { settings_loaded: true });
        case Action.Type.USER_SETTING_UPSERTED:
            return state.set(event.payload.key, event.payload.value);
        default:
            return state;
    }
};

export {reducer as userSettingsReducer};