import * as React from 'react';
import {SyntheticEvent, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {Button, Confirm, Dropdown} from "semantic-ui-react";
import {useOnBodyClick} from "../../core/hooks/useOnBodyClick";
import * as UserModule from "../../User";
import {IioUserInfo} from "../../User/model";
import * as Action from "../actions";
import {Team} from "../model";

interface OwnProps {
    isOwner: boolean;
    teamId: Team.TeamId;
    member: IioUserInfo.IioUserInfo;
    readonly?: boolean;
}

type MemberEntryProps = OwnProps & WithNamespaces;

const MemberEntry = (props: MemberEntryProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [avatarLoaded, setAvatarLoaded] = useState(false);
    const [showRemoveMemberConfirm, setShowRemoveMemberConfirm] = useState(false);
    const dispatch = useDispatch();

    useOnBodyClick(() => {
        setShowDropdown(false);
    });

    const canBeRemoved = (userId: UserModule.UserModel.UserId): boolean => {
        return !props.isOwner && !props.readonly;
    }

    const handleMemberClick = (e: SyntheticEvent) => {
        if(canBeRemoved(props.member.userId)) {
            e.stopPropagation();
            setShowDropdown(!showDropdown);
        }
    };

    const removeMemberFromTeam = () => {
        if(!props.readonly) {
            dispatch(Action.Command.removeMemberFromTeam(props.teamId, props.member.userId));
        }
        setShowRemoveMemberConfirm(false);
    };

    const adminClass = props.isOwner? 'admin-member' : '';

    return <div className={"item " + adminClass} key={props.member.userId} style={canBeRemoved(props.member.userId) ? {cursor: "pointer"} : {}}>
        <Dropdown open={showDropdown} icon={false} pointing="top" disabled={props.readonly}>
            <Dropdown.Menu>
                <Dropdown.Item
                    text={props.t('app.form.remove')}
                    onClick={() => setShowRemoveMemberConfirm(true)}
                />
            </Dropdown.Menu>
        </Dropdown>
        {!avatarLoaded && <span className="ui avatar image placeholder" onClick={handleMemberClick} />}
        <img className="ui avatar image"
             src={props.member.avatar()}
             onClick={handleMemberClick}
             alt={props.member.username}
             onLoad={() => setAvatarLoaded(true)}
             style={{display: avatarLoaded ? 'inline-block' : 'none'}} />
        <div className="content" onClick={handleMemberClick}>
            <div className="header">{props.member.username}</div>
        </div>
        <Confirm
            open={!props.readonly && showRemoveMemberConfirm}
            header={props.t('insp.team.remove_user_confirm_title')}
            content={props.t(
                'insp.team.remove_user_confirm_text',
                { user_name: props.member.username }
            )}
            confirmButton={props.t('insp.team.remove_user_confirm_button')}
            cancelButton={<Button content={props.t('app.form.cancel')} className="basic noborder" />}
            onCancel={() => setShowRemoveMemberConfirm(false)}
            onConfirm={removeMemberFromTeam}
        />
    </div>
};

export default withNamespaces()(MemberEntry);
