import {List, Record} from 'immutable';
import * as uuid from 'uuid';
import {UserModel} from "../../User";
import {BoardId} from "./Board";
import {BoardVersion} from "./BoardVersion";
import {HistoryAction, HistoryCellType} from "./HistoryEntry";

export interface BoardQueue extends List<BoardChanged>{

}


export interface RawBoardChangedProps {
    uid: string;
    name: string;
    board: BoardId;
    changeSet: string[];
    version: number;
    recordedAt: string;
    userId: UserModel.UserId;
    action?: HistoryAction;
    cellType?: HistoryCellType;
    cellLabel?: string;
    cellId?: string;
}

export interface BoardChangedProps {
    uid: string;
    name: string;
    board: BoardId;
    changeSet: List<string>;
    version: number;
    recordedAt: string;
    userId: UserModel.UserId;
    action?: HistoryAction;
    cellType?: HistoryCellType;
    cellLabel?: string;
    cellId?: string;
}

export const defaultBoardChangedProps: BoardChangedProps = {
    uid: '',
    name: 'BoardChanged',
    board: '',
    changeSet: List(),
    version: 0,
    recordedAt: '',
    userId: '',
    action: HistoryAction.edited,
    cellType: HistoryCellType.misc,
    cellLabel: '',
    cellId: undefined,
}

export const remoteBoardChanged = (data: RawBoardChangedProps): BoardChanged => {
    const iData = {...data, changeSet: List(data.changeSet)}
    return new BoardChanged(iData);
}

export const localBoardChanged = (
    board: string,
    changeSet: List<string>,
    version: number,
    userId: string,
    action: HistoryAction,
    cellType: HistoryCellType,
    cellLabel: string,
    cellId?: string
): BoardChanged => {
    return new BoardChanged({
        uid: '',
        name: defaultBoardChangedProps.name,
        board,
        changeSet,
        version,
        recordedAt: '',
        userId,
        action,
        cellType,
        cellLabel,
        cellId
    })
}

export const mapServerPatchToBoardChanged = (serverPatch: any): BoardChanged => {
    return remoteBoardChanged({
        uid: serverPatch.patchId,
        board: serverPatch.boardId,
        name: defaultBoardChangedProps.name,
        changeSet: serverPatch.patch,
        version: serverPatch.boardVersion.version,
        userId: serverPatch.boardVersion.userId,
        recordedAt: serverPatch.recordedAt,
        action: serverPatch.action,
        cellId: serverPatch.cellId !== null? serverPatch.cellId : undefined,
        cellType: serverPatch.cellType,
        cellLabel: serverPatch.cellLabel,
    });
}

export class BoardChanged extends Record(defaultBoardChangedProps) implements BoardChangedProps {
    public constructor(data: BoardChangedProps) {
        if(data.uid === '') {
            data.uid = uuid.v4();
        }

        if(data.recordedAt === '') {
            data.recordedAt = (() => new Date())().toISOString();
        }

        super(data);
    }

    public toBoardVersion (): BoardVersion {
        return new BoardVersion({
            userId: this.userId,
            version: this.version
        })
    }
}
