import * as _ from "lodash";

export const hasKey = <O>(key: string): (obj: O) => boolean => {
    return (obj: O): boolean => {
        if(!_.isObject(obj)) {
            return false;
        }

        return _.has(obj, key);
    }
};

export const isArray = <O>(): (obj: O) => boolean => {
    return (obj: O) => {
        return _.isArray(obj);
    }
};

export const oneOf = <O>(key: string, choices: any[]): (obj: O) => boolean => {
    return (obj: O) => {
        const keyExists = (hasKey(key))(obj);

        if(!keyExists) {
            return false;
        }

        const val = _.get(obj, key);

        return choices.includes(val);
    }
}

export const isJSON = <O>(key: string): (obj: O) => boolean => {
    return (obj: O) => {
        const keyExists = (hasKey(key))(obj);

        if(!keyExists) {
            return false;
        }

        const val = _.get(obj, key);

        try {
            JSON.parse(val);
            return true;
        } catch (e) {
            return false;
        }
    }
}
