import {List} from "immutable";
import * as React from 'react';
import {WithNamespaces, withNamespaces} from "react-i18next";
import {Divider, List as SemanticList} from "semantic-ui-react";
import * as Routes from "../../../routes";
import {BoardId} from "../../model/Board";
import {Node} from "../../model/Graph";
import CellIcon from "../CellIcon";
import {Link} from "react-router-dom";
import {useState} from "react";
import {RANGE_ELEMENT_CONNECTIONS} from "../MxGraphBoard/clickRange";
import MaxLengthLabel from "../MaxLengthLabel";

interface OwnProps {
    boardId: BoardId,
    sources: List<Node>,
    targets: List<Node>,
}

type ElementConnectionsProps = OwnProps & WithNamespaces;

const ElementConnections = (props: ElementConnectionsProps) => {
    const [clicks, setClicks] = useState(RANGE_ELEMENT_CONNECTIONS);

    const links = props.sources.map(source => {
        return <SemanticList.Item key={source.getId()}
                                  style={{borderRadius:'0', color:'#424242'}}
                                  title="Source"
                                  as={ Link }
                                  to={{pathname: Routes.compileInspectioBoardWorkspace(props.boardId), search: `?cells=${source.getId()}&select=1&clicks=${clicks}`}}
                                  onClick={() => setClicks(clicks + 1)}  >
            <SemanticList.Icon name="arrow left" />
            <SemanticList.Content><CellIcon cellType={source.getType()} /><MaxLengthLabel label={source.getName()} maxLength={30} /></SemanticList.Content>
        </SemanticList.Item>;
    }).toArray();

    props.targets.forEach(target => {
        links.push(<SemanticList.Item key={target.getId()}
                                      style={{borderRadius:'0', color:'#424242'}}
                                      title="Target"
                                      as={ Link }
                                      to={{pathname: Routes.compileInspectioBoardWorkspace(props.boardId), search: `?cells=${target.getId()}&select=1&clicks=${clicks}`}}
                                      onClick={() => setClicks(clicks + 1)}  >
            <SemanticList.Icon name="arrow right" />
            <SemanticList.Content><CellIcon cellType={target.getType()} /><MaxLengthLabel label={target.getName()} maxLength={30} /></SemanticList.Content>
        </SemanticList.Item>);
    })

    return <>
        <Divider horizontal={true} content={'Connections'} />
        <SemanticList>{links}</SemanticList>
        </>;
};

export default withNamespaces()(ElementConnections);
