import {List} from "immutable";
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {FormField, Message} from "semantic-ui-react";
import CodeEditor from "../../../../core/components/CodeEditor/CodeEditor";
import {Node} from "../../../model/Graph";
import {Rule} from "../../../service/cody-wizard/rule-engine/configuration";
import {RulesDependencyMetadata} from "../../../service/cody-wizard/rule-engine/types";
import ExpandButton from "./ExpandButton";
import {makeBusinessRulesSchema} from "./Validation/business-rules-schema";


interface OwnProps {
  elementId: string;
  rules: Rule[];
  onRulesChanged: (rules: Rule[] | undefined) => void;
  autofocus?: boolean;
  possibleEvents: List<Node>;
  dependencies: Record<string, RulesDependencyMetadata>;
  readonly?: boolean;
  sessionId?: string;
}

type BusinessRulesEditorProps = OwnProps & WithNamespaces;

const unsavedSessions: Record<string, string> = {};

const BusinessRulesEditor = (props: BusinessRulesEditorProps) => {
  const codeEditorRef = useRef<CodeEditor|null>(null);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [editorExpanded, setEditorExpanded] = useState(false);
  const [invalidJson, setInvalidJson] = useState(false);

  const possibleServices: string[] = [];

  for (const dependenciesKey in props.dependencies) {
    if(props.dependencies[dependenciesKey].type === "service") {
      possibleServices.push(props.dependencies[dependenciesKey].alias || dependenciesKey);
    }
  }

  useEffect(() => {
    if (!codeEditorRef.current) {
      return;
    }

    let value = JSON.stringify(props.rules, null, 2);
    let isInvalidJson = false;

    if(props.sessionId && typeof unsavedSessions[props.sessionId] === "string") {
      value = unsavedSessions[props.sessionId];
      isInvalidJson = true;
    }

    codeEditorRef.current!.initializeModel({
      fileId: `business-rules.json`,
      language: "json",
      value,
      schema: makeBusinessRulesSchema(
        props.possibleEvents.map(e => e.getName()).toArray(),
        possibleServices
      ),
    });

    setInvalidJson(isInvalidJson);
  }, [props.elementId, props.dependencies]);

  useEffect(() => {
    if(props.autofocus && codeEditorRef.current) {
      codeEditorRef.current.focus();
    }
  }, [props.autofocus])

  const propagateChanges = (editorStr: string, silentError?: boolean): boolean => {

    if(editorStr === '') {
      props.onRulesChanged(undefined);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }
      return true;
    }

    try {
      const changedRules = JSON.parse(editorStr);
      props.onRulesChanged(changedRules);

      if(props.sessionId && unsavedSessions[props.sessionId]) {
        delete unsavedSessions[props.sessionId];
      }

      return true
    } catch (e) {
      console.error("[CodyWizard] Business Rules Editor invalid JSON: ", e);

      if(!silentError) {
        setInvalidJson(true);
      }
      return false;
    }
  }

  return <FormField>
    {invalidJson && <Message error={true} size="small" content={props.t('insp.cody_wizard.schema_editor.invalid_json') as string} icon="warning" style={{display: 'flex'}}/>}

    <ExpandButton expanded={editorExpanded} onExpandChanged={expand => setEditorExpanded(expand)}/>
    <CodeEditor
      containerId={'cody-business-rules-editor'}
      ref={codeEditorRef}
      options={{
        fontSize: 12,
        folding: true,
        glyphMargin: false,
        lineNumbers: true,
        lineDecorationsWidth: 3,
        minimap: {
          enabled: false
        },
        formatOnPaste: true,
        scrollBeyondLastLine: false,
        automaticLayout: true,
        scrollbar: {
          alwaysConsumeMouseWheel: false
        },
        readOnly: props.readonly,
        fixedOverflowWidgets: true,
      }}
      className={"code editor" + (editorHasFocus ? ' focus' : '') + (editorExpanded ? ' expanded' : '')}
      onBlur={() => {
        if(codeEditorRef.current) {
          const changedRulesStr = codeEditorRef.current.retrievePayload();

          const validJson = propagateChanges(changedRulesStr);

          setEditorHasFocus(false);

          if(!validJson && props.sessionId) {
            unsavedSessions[props.sessionId] = changedRulesStr;
          }
        }
      }}
      onFocus={() => {
        setEditorHasFocus(true);
      }}
    />
  </FormField>
};

export default withNamespaces()(BusinessRulesEditor);
