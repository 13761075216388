import {Map} from "immutable";
import React from 'react'
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Link} from "react-router-dom";
import {InjectedFormProps} from "redux-form";
import { Field, reduxForm } from 'redux-form/immutable';
import {Button} from "semantic-ui-react";
import {inspectio} from "../../routes";

interface FormData  {
    uid: string;
    displayName: string;
    companyName: string;
    email: string;
    jobTitle: string;
}

// tslint:disable-next-line:interface-name
export interface IFormData extends Map<string, string> {
    toJS(): FormData
}

export interface OwnProps {
}

export interface PropsFromDispatch { }

export type UserProfileEditFormProps = OwnProps & PropsFromDispatch & WithNamespaces & InjectedFormProps<IFormData, OwnProps & WithNamespaces>;

const UserProfileEditForm = (props: UserProfileEditFormProps) => (
    <form className="ui form" onSubmit={props.handleSubmit}>
        <div className="field">
            <label>{props.t('insp.user.form.labels.email')}</label>
            <Field name="email" component="input" type="text" placeholder={props.t('insp.user.form.fields.email')} />
        </div>

        <div className="field">
            <label>{props.t('insp.user.form.labels.display_name')}</label>
            <Field name="displayName" component="input" type="text" placeholder={props.t('insp.user.form.fields.display_name')} />
        </div>

        <div className="field">
            <label>{props.t('insp.user.form.labels.company')}</label>
            <Field name="companyName" component="input" type="text" placeholder={props.t('insp.user.form.fields.company')} />
        </div>

        <div className="field">
            <label>{props.t('insp.user.form.labels.position')}</label>
            <Field name="jobTitle" component="input" type="text" placeholder={props.t('insp.user.form.fields.position')} />
        </div>

        <div className="field">
            <Button primary={true} type="submit" className="bottom aligned">{props.t('app.form.save')}</Button>
            <Link to={inspectio} className="ui basic button noborder">{props.t('app.form.cancel')}</Link>
        </div>
    </form>
);

export default withNamespaces()(reduxForm<IFormData, OwnProps & WithNamespaces>({form: 'UserProfileEditForm', enableReinitialize: true})(UserProfileEditForm));

