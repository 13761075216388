import axios from "axios";
import * as React from 'react';
import {useEffect, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Icon, Loader, Message, Segment, StepGroup} from "semantic-ui-react";
import {UserInfo} from "../../../../User/model/UserInfo";
import {makeGetCurrentUser} from "../../../../User/selectors/currentUser";
import {useGraph} from "../../../hooks/useGraph";
import {BoardId} from "../../../model/Board";
import {Graph, Node, NodeType} from "../../../model/Graph";
import {RunCodyOption} from "../../../reducers/RunCodyOn";
import {makeRunCodyOnSelector} from "../../../selectors/RunCodyOn";
import {CodyResponse, CodyResponseType, IIO, useIIO} from "../../../service/Cody";
import {
  ImmutableDDDWizardContext,
  isDDDAction
} from "../../../service/cody-wizard/context/ddd-action";
import {ImmutableFeatureContext, isFeatureContext} from "../../../service/cody-wizard/context/feature-context";
import {
  ImmutableInformationContext,
  isInformationContext
} from "../../../service/cody-wizard/context/information-context";
import {
  ImmutablePolicyContext,
  isPolicyContext,
} from "../../../service/cody-wizard/context/policy-context";
import {ImmutableUiContext, isUiContext} from "../../../service/cody-wizard/context/ui";
import {WizardContext} from "../../../service/cody-wizard/context/wizard-context";
import CellIcon from "../../CellIcon";
import CodyEmoji from "../../CodyConsole/CodyEmoji";
import CodyMessage from "../../CodyConsole/CodyMessage";
import ActiveStepIcon from "../Step/ActiveStepIcon";
import {WizardStep} from "../WizardModal";
import CodyStepElement from "./CodyStepElement";

export type CodyStepAction = 'connect' | 'generate' | 'healthcheck';

export interface CodyStep {
  id: string;
  action: CodyStepAction;
  arguments: string[];
  icon: JSX.Element;
  title: string;
  description: string;
  active: boolean;
  completed: boolean;
  error: boolean;
  codyResponse?: CodyResponse;
  disabled?: boolean;
  linkable?: boolean;
}

export interface CodyStepGenerateNode extends CodyStep {
  node: Node,
  wizardStep: WizardStep;
}

export const isGenerateNodeAction = (step: Partial<CodyStepGenerateNode>): step is CodyStepGenerateNode => {
  return step.action === "generate";
}

interface OwnProps {
  ctx: WizardContext;
  onBackToStepChanged: (backToStep: WizardStep) => void;
  onCodyFinished: (success: boolean) => void;
}

type RunCodyProps = OwnProps & WithNamespaces & RouteComponentProps<{uid: BoardId}>;

const RunCody = (props: RunCodyProps) => {

  const user = useSelector(makeGetCurrentUser());
  const [graph,] = useGraph();
  const iio = useIIO();
  const [steps, setSteps] = useState<CodyStep[]>([]);
  const [leftScroll, setLeftScroll] = useState(0);
  const [connectError, setConnectError] = useState(false);
  const [healthcheckError, setHealthcheckError] = useState(false);
  const runCodyOn = useSelector(makeRunCodyOnSelector());

  const activeStep = steps.filter(s => s.active)[0];

  useEffect(() => {
    (async () => {
      setSteps(await determineRunSteps(props.ctx, props.t, user, graph, props.match.params.uid, iio, runCodyOn));
      setConnectError(false);
      setHealthcheckError(false);
    })().catch(e => {throw e});

  }, [props.ctx]);

  useEffect(() => {
    if(activeStep) {
      const ele = document.querySelector('#'+activeStep.id);
      if(ele) {
        ele.scrollIntoView({behavior: "smooth"});
      }

      if(!activeStep.completed && !activeStep.error) {
        execStep(activeStep, graph, iio).then(executedStep => {
          let updatedSteps = updateStep(executedStep, steps);

          if(executedStep.error) {
            if (isGenerateNodeAction(executedStep)) {
              props.onBackToStepChanged(executedStep.wizardStep);
            } else if (executedStep.action === "connect") {
              setConnectError(true);
            } else if (executedStep.action === "healthcheck") {
              setHealthcheckError(true);
            }

            updatedSteps = disableAllRemainingSteps(updatedSteps);
            props.onCodyFinished(false);
          } else {
            const nextStep = getNextStep(executedStep, updatedSteps);

            if(nextStep) {
              updatedSteps = markAsActive(nextStep, updatedSteps);
            } else {
              updatedSteps = markAllAsLinkable(updatedSteps);
              props.onCodyFinished(true);
            }
          }

          setSteps(updatedSteps);
        })
      }
    }
  }, [activeStep]);

  const handleContainerScroll = () => {
    const container = document.querySelector('#run-cody-step-container');

    if(container) {
      setLeftScroll(container.scrollLeft);
    }
  }


  return <div id="run-cody-step-container" style={{width: '100%', overflow: 'auto'}} onScroll={handleContainerScroll}>
      <StepGroup size={"mini"} fluid={true} className="noborder">
        {steps.map(step => <CodyStepElement step={step} key={step.id} onStepClicked={() => setSteps(markAsActive(step, steps))} />)}
      </StepGroup>
      <Segment style={{minHeight: '300px', width: 'auto', padding: 0, left: leftScroll + 'px'}}>
        {!activeStep || !activeStep.codyResponse && <Loader indeterminate={true} size="massive" />}
        {activeStep && activeStep.codyResponse && <div className="cody console" style={{position: 'relative', height: '100%'}}>
            <CodyMessage codyResponse={activeStep.codyResponse} codyName="Cody" />
        </div>}

      </Segment>
      <Message warning={true} hidden={!connectError} icon="exclamation" content={<div>
        <p>{props.t('insp.cody_wizard.step_run_cody.message_install_cody_engine')}</p>
        <p><a href="https://github.com/proophboard/cody-engine#readme">Cody Engine on Github</a></p>
      </div>} />
    <Message warning={true} hidden={!healthcheckError} icon="exclamation" content={<div>
      <p>{props.t('insp.cody_wizard.step_run_cody.message_restart_cody')}</p>
      <p>{props.t('insp.cody_wizard.step_run_cody.message_perform_cody_restart')}</p>
      <p>{props.t('insp.cody_wizard.step_run_cody.message_cody_still_failing')}</p>
      <p><a href="https://github.com/proophboard/cody-engine/issues">{props.t('insp.cody_wizard.step_run_cody.message_cody_failed_get_help')}</a></p>
    </div>} />
    </div>
};

export default withNamespaces()(withRouter(RunCody));

const markAsActive = (step: CodyStep, steps: CodyStep[]): CodyStep[] => {
  return steps.map(s => {
    return {...s, active: s.id === step.id, disabled: false};
  })
}

const markAllAsLinkable = (steps: CodyStep[]): CodyStep[] => {
  return steps.map(step => ({...step, linkable: true}));
}

const getNextStep = (currentStep: CodyStep, steps: CodyStep[]): CodyStep | undefined => {
  let returnNext = false;

  for (const step of steps) {
    if(returnNext) {
      return step;
    }

    if(step.id === currentStep.id) {
      returnNext = true;
    }
  }
}

const updateStep = (step: CodyStep, steps: CodyStep[]): CodyStep[] => {
  return steps.map(s => s.id === step.id ? step : s);
}

const disableAllRemainingSteps = (steps: CodyStep[]): CodyStep[] => {
  return steps.map(step => ({...step, disabled: !step.completed}));
}

const execStep = (step: CodyStep, graph: Graph, iio: IIO): Promise<CodyStep> => {
  const updatedStep = {...step};
  let syncRequired = false;

  const executable = (resolve: (codyStep: CodyStep | undefined) => void) => {
    let codyServerName = 'Cody';

    const timer = window.setTimeout(() => {
      updatedStep.error = true;
      updatedStep.completed = true;
      updatedStep.codyResponse = {
        cody: "Something went wrong. I could not finish the task.",
        details: "Try restarting the Cody Engine and check the logs for errors.",
        type: CodyResponseType.Error
      }

      dispose();
      iio.disconnect(codyServerName);
      resolve(updatedStep);
    }, 10000);

    const dispose = iio.attachCodyResponseListener((res: CodyResponse) => {
      if(res.type === CodyResponseType.SyncRequired) {
        syncRequired = true;
        // Wait for next response or timeout
        return;
      }

      if(res.type === CodyResponseType.Empty) {
        // Wait for next response or timeout
        return;
      }

      if(syncRequired && step.action !== "connect" && (res.type? res.type === CodyResponseType.Info : true)) {
        // Sync finished info. Still waiting for final answer
        syncRequired = false;
        return;
      }

      updatedStep.error =  res.type? res.type !== CodyResponseType.Info : false;
      updatedStep.completed = true;
      updatedStep.codyResponse = res;

      window.clearTimeout(timer);
      dispose();
      resolve(updatedStep);
    });

    switch (step.action) {
      case "connect":
        if(!iio.isConnected()) {
          if(step.arguments[0] === "play") {
            codyServerName = 'Cody Play';
          }
          iio.connect.Cody.apply(iio.connect.Cody, step.arguments as any);
        } else {
          iio.ping.Cody();
        }
        break;
      case "generate":
        if(isGenerateNodeAction(step)) {
          graph.selectNode(step.node);
          graph.triggerCody();
        }
        break;
      case "healthcheck":
        dispose();

        window.clearTimeout(timer);
        performHealthCheck(step.arguments).then(res => {
          updatedStep.error = res.type? res.type !== CodyResponseType.Info : false;
          updatedStep.completed = true;
          updatedStep.codyResponse = res;

          resolve(updatedStep);
        })
        break;
    }
  };

  return new Promise((resolve) => {
    // Give Cody a pause between steps
    window.setTimeout(() => {
      executable(resolve);
    }, 800);
  });
}

const performHealthCheck = (urls: string[]): Promise<CodyResponse> => {
  const fetch = axios.create();

  const retry = async (url: string, wait: number, triesLeft: number): Promise<boolean> => {
      return new Promise(resolve => {
        if(triesLeft === 0) {
          resolve(false);
          return;
        }

        window.setTimeout(async () => {
          fetch.get(url)
            .then(
              async (response) => {
                if(response.status === 200) {
                  resolve(true);
                } else {
                  console.error("[CodyHealthCheck] failed: ", "ResponseStatus: ", response.status, "message: ", response.data);
                  resolve(await retry(url, 3000, triesLeft - 1));
                }
              }
            )
            .catch(
              async (reason) => {
                console.error("[CodyHealthCheck] ", reason);
                resolve(await retry(url, 3000, triesLeft - 1))
              }
            )
        }, wait);
      })
  }

  return new Promise(resolve => {
    const requests = urls.map(url => retry(url, 3000, 14));
    const failedResponse: CodyResponse = {
      cody: 'At least one health check failed. Try to restart the Cody Engine app and check the logs for more information.',
      type: CodyResponseType.Error
    };

    Promise.all(requests).then(results => {
      let allGood = true;
      results.forEach(res => {
        if(!res) {
          allGood = false;
        }
      })

      if(allGood) {
        resolve({
          cody: 'All good! Cody Engine is responding normally.'
        })
        return;
      }

      resolve(failedResponse);
    }).catch(reason => {
      console.error("[CodyHealthCheck] error: ", reason);
      resolve(failedResponse);
    })
  })
}

type Translate = (key: string) => string;

const defaultGenerateProps = (t: Translate) => {
  return {
    description: t('insp.cody_wizard.step_run_cody.action_generate.desc'),
    action: 'generate' as CodyStepAction,
    arguments: [],
    active: false,
    completed: false,
    error: false,
    disabled: true,
  }
}

let lastAutoRun: RunCodyOption = 'auto';
const determineRunSteps = async (ctx: WizardContext, t: Translate, user: UserInfo, graph: Graph, boardId: BoardId, iio: IIO, runCodyOn: RunCodyOption): Promise<CodyStep[]> => {
  const steps: CodyStep[] = [];

  if(runCodyOn === 'auto') {
    const fetch = axios.create();
    try {
      const res = await fetch.post('http://localhost:3311/messages/IioSaidHello', {user: 'Connection Test'}, {timeout: 500});
      runCodyOn = res.status === 200 ? 'engine' : 'play';
    } catch (e) {
      runCodyOn = 'play';
    }

    if(lastAutoRun !== runCodyOn && iio.isConnected()) {
      iio.disconnect(lastAutoRun === 'engine'? 'Cody' : 'Cody Play');
    }

    lastAutoRun = runCodyOn;
  }

  const connectUrl = runCodyOn === 'play'? 'play' : 'http://localhost:3311';

  steps.push({
    id: 'cody-connect-step',
    title: t('insp.cody_wizard.step_run_cody.action_connect.title'),
    description: t('insp.cody_wizard.step_run_cody.action_connect.desc'),
    action: 'connect',
    arguments: [connectUrl, user.displayName, boardId],
    active: true,
    completed: false,
    error: false,
    icon: <CodyEmoji style={{position: "relative", marginRight: '20px'}} />
  })

  if(isDDDAction(ctx)) {
    determineDDDActionRunSteps(ctx, steps, t, graph);
  } else if (isInformationContext(ctx)) {
    determineInformationContextRunSteps(ctx, steps, t, graph);
  } else if (isPolicyContext(ctx)) {
    determinePolicyContextRunSteps(ctx, steps, t, graph);
  } else if (isUiContext(ctx)) {
    determineUiContextRunSteps(ctx, steps, t, graph);
  } else if (isFeatureContext(ctx)) {
    determineFeatureContextRunSteps(ctx, steps, t, graph);
  }

  if(runCodyOn !== 'play') {
    steps.push({
      id: 'healthcheck-step',
      title: 'Health Check',
      description: t('insp.cody_wizard.step_run_cody.action_healthcheck.desc'),
      action: "healthcheck",
      arguments: ["http://localhost:4200/api/health", "http://localhost:4200"],
      active: false,
      completed: false,
      error: false,
      disabled: true,
      icon: <Icon name="heartbeat" color="red" />
    })
  }

  return steps;
}

const determineInformationContextRunSteps = (ctx: ImmutableInformationContext, steps: CodyStep[], t: Translate, graph: Graph) => {
  const defaultProps = defaultGenerateProps(t);

  const vo = graph.getNode(ctx.vo.getId());

  if(vo) {
    steps.push({
      id: 'generate-' + ctx.vo.getId(),
      title: ctx.vo.getName(),
      icon: <ActiveStepIcon activeStep="anyVO" />,
      node: vo,
      wizardStep: 'anyVO',
      ...defaultProps
    } as CodyStepGenerateNode)
  }

}

const determineUiContextRunSteps = (ctx: ImmutableUiContext, steps: CodyStep[], t: Translate, graph: Graph) => {
  const defaultProps = defaultGenerateProps(t);

  if(ctx.ui) {
    const ui = graph.getNode(ctx.ui.getId());

    if(ui) {
      steps.push({
        id: 'generate-' + ctx.ui.getId(),
        title: ctx.ui.getName(),
        icon: <ActiveStepIcon activeStep="ui" />,
        node: ui,
        wizardStep: 'ui',
        ...defaultProps
      } as CodyStepGenerateNode)
    }
  }
}

const skipTypes = [NodeType.freeText, NodeType.edge, NodeType.icon, NodeType.misc];

const determineFeatureContextRunSteps = (ctx: ImmutableFeatureContext, steps: CodyStep[], t: Translate, graph: Graph) => {
  const defaultProps = defaultGenerateProps(t);

  const pushLater: CodyStepGenerateNode[] = [];

  ctx.feature.children().forEach(child => {

    if (skipTypes.includes(child.getType())) {
      return;
    }

    const refreshedChild = graph.getNode(child.getId());

    if(refreshedChild) {
      const step = {
        id: 'generate-' + child.getId(),
        title: child.getName(),
        icon: <CellIcon cellType={child.getType()} size="normal"/>,
        node: refreshedChild,
        wizardStep: 'feature',
        ...defaultProps
      } as CodyStepGenerateNode;

      if (child.getType() !== NodeType.document) {
        pushLater.push(step);
        return;
      }

      steps.push(step)
    }
  })

  steps.push(...pushLater);
}

const determinePolicyContextRunSteps = (ctx: ImmutablePolicyContext, steps: CodyStep[], t: Translate, graph: Graph) => {
  const defaultProps = defaultGenerateProps(t);

  if(ctx.event) {
    const evt = graph.getNode(ctx.event.getId());

    if(evt) {
      steps.push({
        id: 'generate-' + ctx.event.getId(),
        title: ctx.event.getName(),
        icon: <ActiveStepIcon activeStep="dddEvent" />,
        node: evt,
        wizardStep: 'policyEvent',
        ...defaultProps
      } as CodyStepGenerateNode)
    }
  }

  if(ctx.policy) {
    const policy = graph.getNode(ctx.policy.getId());

    if(policy) {
      steps.push({
        id: 'generate-' + ctx.policy.getId(),
        title: ctx.policy.getName(),
        icon: <ActiveStepIcon activeStep="policy" />,
        node: policy,
        wizardStep: 'policy',
        ...defaultProps
      } as CodyStepGenerateNode)
    }
  }

  determineAdditionalStepsIncludedInFeature(ctx, steps, t, graph);
}

const determineDDDActionRunSteps = (ctx: ImmutableDDDWizardContext, steps: CodyStep[], t: Translate, graph: Graph) => {
  const defaultProps = defaultGenerateProps(t);

  if(ctx.state) {
    const state = graph.getNode(ctx.state.getId());

    if(state) {
      steps.push({
        id: 'generate-' + ctx.state.getId(),
        title: ctx.state.getName(),
        icon: <ActiveStepIcon activeStep="state" />,
        node: state,
        wizardStep: 'state',
        ...defaultProps
      } as CodyStepGenerateNode)
    }
  }

  if(ctx.command) {
    const command = graph.getNode(ctx.command.getId());

    if(command) {
      steps.push({
        id: 'generate-' + ctx.command.getId(),
        title: ctx.command.getName(),
        icon: <ActiveStepIcon activeStep="command" />,
        node: command,
        wizardStep: 'command',
        ...defaultProps
      } as CodyStepGenerateNode)
    }
  }

  if(ctx.aggregate) {
    const aggregate = graph.getNode(ctx.aggregate.getId());

    if(aggregate) {
      steps.push({
        id: 'generate-' + ctx.aggregate.getId(),
        title: ctx.aggregate.getName(),
        icon: <ActiveStepIcon activeStep="aggregate" />,
        node: aggregate,
        wizardStep: 'aggregate',
        ...defaultProps
      } as CodyStepGenerateNode)
    }

  }

  if(ctx.events) {
    ctx.events.forEach(evt => {
      const refreshedEvt = graph.getNode(evt.getId());
      if(refreshedEvt) {
        steps.push({
          id: 'generate-' + evt.getId(),
          title: evt.getName(),
          icon: <ActiveStepIcon activeStep="dddEvent" />,
          node: refreshedEvt,
          wizardStep: 'dddEvent',
          ...defaultProps
        } as CodyStepGenerateNode)
      }
    })
  }

  if(ctx.ui) {
    const ui = graph.getNode(ctx.ui.getId());

    if(ui) {
      steps.push({
        id: 'generate-' + ctx.ui.getId(),
        title: ctx.ui.getName(),
        icon: <ActiveStepIcon activeStep="ui" />,
        node: ui,
        wizardStep: 'ui',
        ...defaultProps
      } as CodyStepGenerateNode)
    }
  }

  // State List is set when:
  // - a new state vo is added in DDD action -> StateVO step also adds a corresponding state list vo
  // - a state view UI is added in DDD action -> UI step checks parentUI, searches state list vo and adds a PageLink to first table column if not set
  if(ctx.stateList) {
    const stateList = graph.getNode(ctx.stateList.getId());

    if(stateList) {
      steps.push({
        id: 'generate-' + ctx.stateList.getId(),
        title: ctx.stateList.getName(),
        icon: <ActiveStepIcon activeStep="state" />,
        node: stateList,
        wizardStep: 'state',
        ...defaultProps
      } as CodyStepGenerateNode)
    }
  }

  determineAdditionalStepsIncludedInFeature(ctx, steps, t, graph);
}

const determineAdditionalStepsIncludedInFeature = (ctx: ImmutableDDDWizardContext | ImmutablePolicyContext, steps: CodyStep[], t: Translate, graph: Graph) => {
  if(!ctx.feature) {
    return;
  }

  const defaultProps = defaultGenerateProps(t);

  const stepsNames = steps.map(step => step.title);

  ctx.feature.children().forEach(child => {
    if(skipTypes.includes(child.getType())) {
      return;
    }

    if(stepsNames.includes(child.getName())) {
      return;
    }

    stepsNames.push(child.getName());

    const refreshedChild = graph.getNode(child.getId());

    if(refreshedChild) {
      steps.splice(1, 0, {
        id: 'generate-' + child.getId(),
        title: child.getName(),
        icon: <CellIcon cellType={child.getType()} size="normal" />,
        node: refreshedChild,
        wizardStep: 'feature',
        ...defaultProps
      } as CodyStepGenerateNode)
    }
  })
}
