import {suggestAggregateName, suggestEventRecordingRules} from "../../../../components/CodyEngineWizard/Step/Aggregate";
import {
  suggestAggregateCommandSchema,
  suggestAggregateCommandUiSchema,
  suggestCommandCreatesNewAggregate,
  suggestNewCommandName
} from "../../../../components/CodyEngineWizard/Step/DddCommand";
import {
  defaultApplyRule,
  suggestAggregateEventName,
  suggestEventSchema
} from "../../../../components/CodyEngineWizard/Step/DddEvent";
import {
  getAllNodesFromCtx,
  repositionFeature,
  suggestNewFeatureName
} from "../../../../components/CodyEngineWizard/Step/Feature";
import {
  createListFromState,
  suggestNewEntityName,
  suggestStateSchema
} from "../../../../components/CodyEngineWizard/Step/StateVO";
import {
  suggestPageConfig,
  suggestTopLevel,
  suggestUiName,
  suggestViewModels,
  updateViewModelsOnGraph
} from "../../../../components/CodyEngineWizard/Step/Ui";
import {
  API_TO_MODULE_MARGIN,
  DEFAULT_CARD_HEIGHT,
  DEFAULT_CONNECT_MARGIN,
  DOUBLE_CONNECT_MARGIN, SLICE_LANE_HEIGHT
} from "../../../../components/CodyEngineWizard/WizardModal";
import {createTreeFromGraph} from "../../../../model/ElementsTree";
import {Graph, makeNodeFromJs, NextToPosition, Node, NodeType} from "../../../../model/Graph";
import {markAsConnected} from "../../node/mark-as-connected";
import {markAsDisconnected} from "../../node/mark-as-disconnected";
import {names} from "../../node/names";
import {convertDependenciesToMetadataConfig} from "../../rule-engine/convert-dependencies-to-metadata-config";
import {Schema} from "../../schema/schema";
import {ShorthandObject} from "../../schema/shorthand";
import {getUiMetadata} from "../../ui/get-ui-metadata";
import {UiSchema} from "../../ui/ui-schema";
import {getVoMetadata} from "../../vo/get-vo-metadata";
import {dddActionFromNode, ImmutableDDDWizardContext, isDDDAction} from "../ddd-action";
import {isEventModel} from "../feature-context";

export const suggestFeature = (ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  const featureName = suggestNewFeatureName(ctx);

  let newFeature = makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.feature,
    name: featureName,
  });

  let currentContainerParent: Node | null = null;
  const allNodes = getAllNodesFromCtx(ctx);
  allNodes.forEach(node => {
    const parent = node.getParent();
    if(parent && parent.getType() === NodeType.boundedContext) {
      currentContainerParent = parent;
    }
  })

  if(currentContainerParent) {
    // Ensure all nodes have the same parent
    allNodes.forEach(node => {
      const parent = node.getParent();

      if(parent && parent.getId() !== currentContainerParent!.getId()) {
        graph.switchParent(node, parent);
      }
    })

    newFeature = newFeature.changeParent(currentContainerParent);
  }

  newFeature = repositionFeature(newFeature, ctx, graph);

  graph.addNode(newFeature);

  getAllNodesFromCtx(ctx).forEach(child => graph.switchParent(child, newFeature));

  graph.selectNode(newFeature);
}

export const suggestDDDActionUi = (ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  const topLevel = suggestTopLevel(ctx.parentUi, ctx);
  const uiName = suggestUiName(ctx, graph, topLevel);

  const tempUi = makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.ui,
    name: uiName,
    metadata: JSON.stringify({}, null, 2),
  });

  const tree = createTreeFromGraph(graph);

  const similarElements = tree.getSimilarElements(tempUi);

  const viewModels = suggestViewModels(topLevel, ctx);
  const metadata = similarElements.count() > 0
    ? getUiMetadata(similarElements.first())
    : suggestPageConfig({commands: [], views: []}, tempUi, ctx.parentUi, topLevel, viewModels, ctx);

  let newUi = makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.ui,
    name: uiName,
    metadata: JSON.stringify(metadata, null, 2),
  });

  let isAdded = false;
  if(isDDDAction(ctx) && ctx.command) {
    graph.addNodeNextToAnother(newUi, ctx.command, NextToPosition.above, graph.isEventModelingEnabled() ? SLICE_LANE_HEIGHT - DEFAULT_CARD_HEIGHT : DEFAULT_CONNECT_MARGIN);
    graph.connectNodes(newUi, ctx.command);
    isAdded = true;
  }

  if(ctx.parentUi) {
    if(!isAdded) {
      graph.addNodeNextToAnother(newUi, ctx.parentUi, NextToPosition.right, DEFAULT_CONNECT_MARGIN);
      isAdded = true;
    }

    graph.connectNodes(ctx.parentUi, newUi);
  }

  if(!isAdded) {
    alert("[CodyWizard] Unable to create UI. A parent UI or command card is missing to connect the UI card to.");
    return;
  }

  newUi = markAsConnected(newUi, graph);

  updateViewModelsOnGraph(newUi, ctx.parentUi, ctx, viewModels, graph);

  repositionFeatureIfNeeded(newUi, ctx, graph);

  window.setTimeout(() => {
    graph.selectNode(newUi);
  }, 200);
}

export const suggestCommand = (ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  const commandName = suggestNewCommandName(ctx);

  const newAggregate = suggestCommandCreatesNewAggregate(ctx, createTreeFromGraph(graph));

  const metadata = {
    aggregateCommand: newAggregate,
    newAggregate,
    schema: suggestAggregateCommandSchema(ctx, newAggregate),
    uiSchema: suggestAggregateCommandUiSchema(ctx),
    dependencies: ctx.commandDependencies,
  }

  let newCommand = makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.command,
    name: commandName,
    metadata: JSON.stringify(metadata, null, 2),
  });

  let isAdded = false;

  if(ctx.aggregate) {
    const aggregateToApiMargin = ctx.sliceApiLabel
      ? ctx.aggregate.getGeometry().y - ctx.sliceApiLabel.getGeometry().y - DEFAULT_CARD_HEIGHT
      : DEFAULT_CONNECT_MARGIN

    graph.addNodeNextToAnother(newCommand, ctx.aggregate, NextToPosition.above, aggregateToApiMargin);
    graph.connectNodes(newCommand, ctx.aggregate);
    isAdded = true
  }

  if(!isAdded && ctx.events.count()) {
    const firstEvent = ctx.events.first(undefined);

    if(firstEvent) {
      const eventToApiMargin = ctx.sliceApiLabel
        ? firstEvent.getGeometry().y - ctx.sliceApiLabel.getGeometry().y - DEFAULT_CARD_HEIGHT
        : API_TO_MODULE_MARGIN;

      graph.addNodeNextToAnother(newCommand, firstEvent, NextToPosition.above, eventToApiMargin);
      graph.connectNodes(newCommand, firstEvent);
      isAdded = true;
    }
  }


  if(!isAdded) {
    alert("[CodyWizard] Unable to create Command. An aggregate or event is missing to connect the command card to.");
    return;
  }

  newCommand = markAsConnected(newCommand, graph);

  repositionFeatureIfNeeded(newCommand, ctx, graph);

  window.setTimeout(() => {
    graph.selectNode(newCommand);
  }, 200);
}

export const suggestAggregate = (ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  const aggregateName = suggestAggregateName(ctx, graph) || '';

  const metadata = {
    rules: suggestEventRecordingRules(ctx)
  }

  let newAggregate = makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.aggregate,
    name: aggregateName,
    metadata: JSON.stringify(metadata, null, 2),
  });

  let isAdded = false;
  if(ctx.events.first(undefined)) {
    graph.addNodeNextToAnother(newAggregate, ctx.events.first(), NextToPosition.above, DEFAULT_CONNECT_MARGIN);
    graph.connectNodes(newAggregate, ctx.events.first());
    isAdded = true;
  }

  if(ctx.command) {
    if(!isAdded) {
      graph.addNodeNextToAnother(newAggregate, ctx.command, NextToPosition.below, graph.isEventModelingEnabled() ? API_TO_MODULE_MARGIN : DEFAULT_CONNECT_MARGIN);
      isAdded = true;
    }

    graph.connectNodes(ctx.command, newAggregate);
  }

  if(!isAdded) {
    alert("[CodyWizard] Unable to create Aggregate. A command or event card is missing to connect the aggregate card to.");
    return;
  }

  // Explicitly mark aggregate as disconnected, since each AR card defines its own business rules
  newAggregate = markAsDisconnected(newAggregate, graph);

  repositionFeatureIfNeeded(newAggregate, ctx, graph);

  window.setTimeout(() => {
    graph.selectNode(newAggregate);
  }, 200);
}

export const suggestEvent = (ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  const eventName = suggestAggregateEventName(ctx, graph);
  const metadata = {
    schema: suggestEventSchema(ctx),
    applyRules: [defaultApplyRule()]
  };

  let newEvent = makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.event,
    name: eventName,
    metadata: JSON.stringify(metadata, null, 2),
  });

  let isAdded = false;
  if(ctx.aggregate) {
    graph.addNodeNextToAnother(newEvent, ctx.aggregate, NextToPosition.below, DEFAULT_CONNECT_MARGIN);
    graph.connectNodes(ctx.aggregate, newEvent);
    isAdded = true;
  }

  if(ctx.state) {
    if(!isAdded) {
      if(ctx.eventModel) {
        graph.addNodeNextToAnother(newEvent, ctx.state, NextToPosition.belowLeft, {x: DEFAULT_CONNECT_MARGIN, y: API_TO_MODULE_MARGIN});
      } else {
        graph.addNodeNextToAnother(newEvent, ctx.state, NextToPosition.above, DEFAULT_CONNECT_MARGIN);
      }

      isAdded = true;
    }

    graph.connectNodes(newEvent, ctx.state);
  }

  if(ctx.eventModel && ctx.command) {
    if(!isAdded) {
      graph.addNodeNextToAnother(newEvent, ctx.command, NextToPosition.below, API_TO_MODULE_MARGIN);
      graph.connectNodes(ctx.command, newEvent);
      isAdded = true;
    }
  }

  if(!isAdded) {
    alert("[CodyWizard] Unable to create Event. An aggregate or state card is missing to connect the event card to.");
    return;
  }

  newEvent = markAsConnected(newEvent, graph);

  repositionFeatureIfNeeded(newEvent, ctx, graph);

  window.setTimeout(() => {
    graph.selectNode(newEvent);
  }, 200);
}

export const suggestState = (ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  const stateName = suggestNewEntityName(ctx) || 'Information';
  const voNames = names(stateName);
  const rawSchema: ShorthandObject = {};
  const rawQuerySchema: ShorthandObject = {};
  const uiSchema: UiSchema = {};
  const identifier = voNames.propertyName + 'Id';
  rawSchema[identifier] = 'string|format:uuid';
  rawQuerySchema[identifier] = 'string|format:uuid';
  uiSchema[identifier] = {
    "ui:widget": "hidden"
  };

  const suggestedSchema = suggestStateSchema(ctx);

  const tree = createTreeFromGraph(graph);

  const similarStateElements = tree.getSimilarElements(makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.document,
    name: stateName,
    metadata: JSON.stringify({ns: '/' + voNames.className}, null, 2)
  }));

  const metadata = (similarStateElements.count() > 0)
    ? getVoMetadata(similarStateElements.first())
    : {
    ns: ispConst.DEFAULT_DOC_NS,
    schema: !suggestedSchema.isEmpty() ? suggestedSchema : new Schema(rawSchema),
    querySchema: new Schema(rawQuerySchema),
    uiSchema,
    identifier,
  };

  let node = makeNodeFromJs({
    id: graph.createCellId(),
    type: NodeType.document,
    name: stateName,
    metadata: JSON.stringify(metadata, null, 2),
  });

  const firstEvent = ctx.events.first(undefined);

  if(!firstEvent) {
    alert("[CodyWizard] Unable to create Data Entity. An event is missing to connect the information card to.");
    return;
  }

  if(graph.isEventModelingEnabled()) {
    if(ctx.command) {
      graph.addNodeNextToAnother(node, ctx.command, NextToPosition.right, DEFAULT_CONNECT_MARGIN);
    } else {

      const eventToApiMargin = ctx.sliceApiLabel
        ? firstEvent.getGeometry().y - ctx.sliceApiLabel.getGeometry().y - DEFAULT_CARD_HEIGHT
        : API_TO_MODULE_MARGIN;
      graph.addNodeNextToAnother(node, firstEvent, NextToPosition.aboveRight, {x: DEFAULT_CONNECT_MARGIN, y: eventToApiMargin});
    }
  } else {
    graph.addNodeNextToAnother(node, firstEvent, NextToPosition.below, DEFAULT_CONNECT_MARGIN);
  }

  graph.connectNodes(firstEvent, node);
  node = markAsConnected(node, graph);

  if(ctx.feature) {
    repositionFeatureIfNeeded(node, ctx, graph);
  }

  window.setTimeout(() => {
    graph.selectNode(node);
  }, 200);
}

export const suggestStateList = (ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  if(!ctx.state) {
    alert(`Cannot suggest state list, because state is missing in DDDAction context. This seems to be a bug. Please contact the prooph board team.`);
    return;
  }

  const state = graph.getNode(ctx.state.getId());

  if(!state) {
    alert(`Cannot suggest state list, because state is missing in DDDAction context. This seems to be a bug. Please contact the prooph board team.`);
    return;
  }

  const stateMeta = getVoMetadata(state);

  const tree = createTreeFromGraph(graph);

  const listVo = createListFromState(state, stateMeta, graph, tree);
  if(listVo) {
    graph.addNodeNextToAnother(listVo, state, NextToPosition.right, DEFAULT_CONNECT_MARGIN);
    graph.connectNodes(state, listVo);
    markAsConnected(listVo, graph);

    if(ctx.feature) {
      repositionFeatureIfNeeded(state, ctx, graph);
    }

    window.setTimeout(() => {
      graph.selectNode(listVo);
    }, 200);
  }
}

const repositionFeatureIfNeeded = (newNode: Node, ctx: ImmutableDDDWizardContext, graph: Graph): void => {
  if(ctx.feature) {
    const repositionedFeature = repositionFeature(
      ctx.feature,
      dddActionFromNode(graph.getNode(newNode.getId())!, ctx.board),
      graph
    )

    graph.resizeNode(repositionedFeature, repositionedFeature.getSize());
  }
}
