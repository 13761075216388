export const SERVER_CONTEXT = 'InspectIO.';

export const SAVE_BOARD = '@@InspectioBoards/SaveBoard';
export const RENAME_BOARD = '@@InspectioBoards/RenameBoard';
export const CHANGE_BOARD_ABBREVIATION = '@@InspectioBoards/ChangeBoardAbbreviation';
export const SET_BOARD_TAGS = '@@InspectioBoards/SetBoardTags';
export const CHANGE_RESPONSIBLE_TEAM = '@@InspectioBoards/ChangeResponsibleTeam';
export const TOGGLE_CODY_SUGGEST_ENABLED = '@@InspectioBoards/ToggleCodySuggestEnabled';
export const TOGGLE_EVENT_MODELING_ENABLED = '@@InspectioBoards/ToggleEventModelingEnabled';
export const IMPORT_NEW_BOARD = '@@InspectioBoards/ImportNewBoard';
export const IMPORT_EXISTING_BOARD = '@@InspectioBoards/ImportExistingBoard';
export const ADD_BOARD = '@@InspectioBoards/AddBoard';
export const UPDATE_BOARDS = '@@InspectioBoards/AddBoards';
export const FORCE_UPDATE_BOARDS = '@@InspectioBoards/ForceUpdateBoards';
export const SET_BOARD_TO_SNAPSHOT = '@@InspectioBoards/SetBoardToSnapshot';
export const SET_BOARD_DETAILS_FETCHED = '@@InspectioBoards/SetBoardDetailsFetched';
export const CONFIRM_DELETE_BOARD = '@@InspectioBoards/ConfirmDeleteBoard';
export const ABORT_BOARD_DELETION = '@@InspectioBoards/AbortBoardDeletion';
export const DELETE_BOARD = '@@InspectioBoards/DeleteBoard';
export const SHARE_BOARD = '@@InspectioBoards/ShareBoard';
export const RESEND_BOARD_INVITATION = '@@InspectioBoards/ResendBoardInvitation';
export const REVOKE_BOARD_INVITATION = '@@InspectioBoards/RevokeBoardInvitation';
export const REVOKE_ACCESS_TO_BOARD = '@@InspectioBoards/RevokeAccessToBoard';
export const CHANGE_BOARD_PERMISSIONS = '@@InspectioBoards/ChangeBoardPermissions';
export const ADMIN_CHANGE_TEAM_BOARD_PERMISSIONS = '@@InspectioBoards/AdminChangeTeamBoardPermissions';
export const TRIGGER_IMPORT_BOARD = '@@InspectioBoards/TriggerImportBoard';
export const TRIGGER_EXPORT_BOARD = '@@InspectioBoards/TriggerExportBoard';
export const TOGGLE_BOARD_HISTORY = '@@IspectioBoards/ToggleBoardHistory';
export const TOGGLE_ELEMENT_DETAILS = '@@IspectioBoards/ToggleElementDetails';
export const TOGGLE_CODY_CONSOLE = '@@InspectioBoards/ToggleCodyConsole';
export const TOGGLE_PLAYSHOT_MODAL = '@@InspectioBoards/TogglePlayshotModal';
export const TOGGLE_TIMER_MODAL = '@@InspectioBoards/ToggleTimerModal';
export const TOGGLE_VIDEO_SESSION = '@@InspectioBoards/ToggleVideoSession';
export const APPEND_HISTORY_ENTRIES = '@@InspectioBoards/AppendHistoryEntries';
export const PREPEND_HISTORY_ENTRIES = '@@InspectioBoards/PrependHistoryEntries';
export const CLEAR_BOARD_HISTORY = '@@InspectioBoards/ClearBoardHistory';
export const LOAD_FIRST_BOARD_HISTORY_CHUNK = '@@InspectioBoards/LoadFirstBoardHistoryChunk';
export const LOAD_NEXT_BOARD_HISTORY_CHUNK = '@@InspectioBoards/LoadNextBoardHistoryChunk';
export const SET_NO_MORE_HISTORY_AVAILABLE = '@@InspectioBoards/SetNoMoreHistoryAvailable';
export const SET_IS_LOADING_HISTORY = '@@InspectioBoards/SetIsLoadingHistory';
export const UPDATE_BOARD_ELEMENTS_TREE = '@@InspectioBoards/UpdateBoardElementsTree';
export const CREATE_BOARD_CLIENT = '@@InspectioBoards/CreateBoardClient';
export const REMOVE_BOARD_CLIENT = '@@InspectioBoards/RemoveBoardClient';
export const RESET_BOARD_CLIENT_SECRET = '@@InspectioBoards/ResetBoardClientSecret';
export const SET_STICKY_TEMPLATE = '@@InspectioBoards/SetStickyTemplate';
export const UPDATE_STICKY_ADVANCED_CONFIG = '@@InspectioBoards/UpdateStickyAdvancedConfig';
export const IMPORT_STICKY_TEMPLATES = '@@InspectioBoards/ImportStickyTemplates';
export const SHARE_BOARD_WITH_ORGANIZATION_MEMBERS = '@@InspectioBoards/ShareBoardWithOrganizationMembers';
export const SHARE_BOARD_WITH_ANYONE = '@@InspectioBoards/ShareBoardWithAnyone';

export const OPEN_ELEMENTS_DETAILS = '@@InspectioBoards/OpenElementsDetails';

export const FILTER_BOARD_OVERVIEW = '@@InspectioBoards/FilterBoardOverview';
export const SORT_BOARD_OVERVIEW = '@@InspectioBoards/SortBoardOverview';
export const FILTER_BOARD_OVERVIEW_BY_RESPONSIBLE_TEAM = '@@InspectioBoards/FilterBoardOverviewByResponsibleTeam';
export const HIDE_SHARED_BOARDS_ON_OVERVIEW = '@@InspectioBoards/HideSharedBoardsOnOverview';
export const HIDE_OWN_BOARDS_ON_OVERVIEW = '@@InspectioBoards/HideOwnBoardsOnOverview';
export const FILTER_ELEMENTS_TREE = '@@InspectioBoards/FilterElementsTree';

export const MOVE_USER_BOARD_TO_ORGANIZATION = '@@InspectioBoards/MoveUserBoardToOrganization';

export const DISMISS_MAINTENANCE_MESSAGE = '@@InspectioBoards/DismissMaintenanceMessage';


// Board Sync
export const REMOTE_BOARD_CHANGED = '@@InspectioBoards/RemoteBoardChanged';
export const LOCAL_BOARD_CHANGED = '@@InspectioBoards/LocalBoardChanged';
export const BOARD_IMPORT_STARTED = '@@InspectioBoards/BoardImportStarted';
export const BOARD_IMPORTED = '@@InspectioBoards/BoardImported';
export const BOARD_EXPORTED = '@@InspectioBoards/BoardExported';
export const BOARD_INITIALIZED = '@@InspectioBoards/BoardInitialized';
export const LOCAL_BOARD_UPDATED_WITH_REMOTE_CHANGE = '@@InspectioBoards/LocalBoardUpdatedWithRemoteChange';

// Events
export const BOARD_INVITATION_SENT = '@@InspectioBoards/BoardInvitationSent';
export const BOARD_INVITATION_RESENT = '@@InspectioBoards/BoardInvitationResent';
export const BOARD_INVITATION_REVOKED = '@@InspectioBoards/BoardInvitationRevoked';
export const BOARD_ACCESS_REVOKED = '@InspectioBoards/BoardAccessRevoked';
export const BOARD_PERMISSIONS_CHANGED = '@@InspectioBoards/BoardPermissionsChanged';
export const TEAM_BOARD_PERMISSIONS_CHANGED = '@@InspectioBoards/TeamBoardPermissionsChanged';
export const BOARD_PERMISSIONS_FOR_USERS_FETCHED = '@@InspectioBoards/BoardPermissionsForUsersFetched';
export const BOARD_PERMISSIONS_FOR_TEAMS_FETCHED = '@@InspectioBoards/BoardPermissionsForTeamsFetched';
export const OPEN_BOARD_INVITATIONS_FETCHED = '@@InspectioBoards/OpenBoardInvitationsFetched';
export const PLAYSHOT_SUMMARY_LIST_FETCHED = '@@InspectioBoards/PlayshotSummaryListFetched';
export const ACTIVE_GRAPH_ELEMENT_CHANGED = '@@InspectioBoards/ActiveGraphElementChanged';
export const ACTIVE_GRAPH_ELEMENT_LABEL_CHANGED = '@@InspectioBoards/ActiveGraphElementLabelChanged';
export const ACTIVE_GRAPH_ELEMENT_METADATA_CHANGED = '@@InspectioBoards/ActiveGraphElementMetadataChanged';
export const BOARD_GRAPH_ELEMENTS_FETCHED = '@@InspectioBoards/BoardGraphElementsFetched';
export const BOARD_CLIENT_FETCHED = '@@InspectioBoards/BoardClientFetched';
export const BOARD_CLIENT_NOT_CONFIGURED = '@@InspectioBoards/BoardClientNotConfigured';
export const BOARD_CLIENT_SECRET_FETCHED = '@@InspectioBoards/BoardClientSecretFetched';
export const BOARD_STICKY_TEMPLATES_FETCHED = '@@InspectioBoards/BoardStickyTemplatesFetched';
export const STICKY_TEMPLATE_SET = '@@InspectioBoards/StickyTemplateSet';
export const STICKY_ADVANCED_CONFIG_UPDATED = '@@InspectioBoards/StickyAdvancedConfigUpdated';
export const STICKY_TEMPLATES_IMPORTED = '@@InspectioBoards/StickyTemplatesImported';
export const USER_BOARD_MOVED_TO_ORGANIZATION = '@@InspectioBoards/UserBoardMovedToOrganization';
export const INITIAL_BOARD_LIST_LOADED = '@@InspectioBoards/InitialBoardListLoaded';
export const BOARD_SHARED_WITH_ORGANIZATION_MEMBERS = '@@InspectioBoards/BoardSharedWithOrganizationMembers';
export const BOARD_SHARED_WITH_ANYONE = '@@InspectioBoards/BoardSharedWithAnyone';
export const RUN_CODY_ON_CHANGED = '@@InspectioBoards/RunCodyOnChanged';

// Queries
export const START_FETCH_USER_BOARDS = '@@InspectioBoards/StartFetchUserBoards';
export const FETCH_USER_BOARDS = '@@InspectioBoards/FetchUserBoards';
export const FETCH_USER_BOARD = '@@InspectioBoards/FetchUserBoard';
export const FETCH_BOARD_PERMISSIONS_FOR_USERS = '@@InspectioBoards/BoardPermissionsForUsers';
export const FETCH_BOARD_PERMISSIONS_FOR_TEAMS = '@@InspectioBoards/BoardPermissionsForTeams';
export const FETCH_OPEN_BOARD_INVITATIONS = '@@InspectioBoards/FetchOpenBoardInvitations';
export const FETCH_BOARD_GRAPH_ELEMENTS = '@@InspectioBoards/BoardGraphElements';
export const FETCH_BOARD_CLIENT = '@@InspectioBoards/FetchBoardClient';
export const FETCH_BOARD_CLIENT_SECRET = '@@InspectioBoards/FetchBoardClientSecret';
export const FETCH_BOARD_STICKY_TEMPLATES = '@@InspectioBoards/FetchBoardStickyTemplates';
export const FETCH_PLAYSHOT_SUMMARY_LIST = '@@InspectioBoards/FetchPlayshotSummaryList';
