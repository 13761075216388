import {Record} from 'immutable';
import {Reducer} from "redux";
import {TeamId} from "../../InspectioTeams/model/Team";
import {
    DISMISS_MAINTENANCE_MESSAGE,
    FILTER_BOARD_OVERVIEW,
    FILTER_BOARD_OVERVIEW_BY_RESPONSIBLE_TEAM,
    HIDE_OWN_BOARDS_ON_OVERVIEW,
    HIDE_SHARED_BOARDS_ON_OVERVIEW,
    SORT_BOARD_OVERVIEW
} from "../actions/constants";
import {SORT_OPTIONS} from "../components/BoardOverview/Sorting";
import {InspectioBoardsAction} from './index';

export interface BoardOverviewStateData {
    filter: string;
    sortBy: SORT_OPTIONS;
    responsibleTeam?: TeamId;
    allSharedBoardsHidden: boolean;
    allOwnBoardsHidden: boolean;
    showMaintenanceMessage: boolean;
}

const defaultBoardOverviewState: BoardOverviewStateData = {
    filter: '',
    sortBy: SORT_OPTIONS.LAST_MODIFIED_DESC,
    responsibleTeam: undefined,
    allSharedBoardsHidden: false,
    allOwnBoardsHidden: false,
    showMaintenanceMessage: false,
};

export class BoardOverviewState extends Record(defaultBoardOverviewState) implements BoardOverviewStateData {}

export const initialState = new BoardOverviewState();

const reducer: Reducer<BoardOverviewState, InspectioBoardsAction> = (state: BoardOverviewState = initialState, action: InspectioBoardsAction): BoardOverviewState => {
    switch (action.type) {
        case FILTER_BOARD_OVERVIEW:
            return state.set('filter', action.payload.filter);
        case SORT_BOARD_OVERVIEW:
            return state.set('sortBy', action.payload.sort);
        case FILTER_BOARD_OVERVIEW_BY_RESPONSIBLE_TEAM:
            return state.set('responsibleTeam', action.payload.teamId);
        case HIDE_SHARED_BOARDS_ON_OVERVIEW:
            return state.set('allSharedBoardsHidden', action.payload.hide);
        case HIDE_OWN_BOARDS_ON_OVERVIEW:
            return state.set('allOwnBoardsHidden', action.payload.hide);
        case DISMISS_MAINTENANCE_MESSAGE:
            return state.set('showMaintenanceMessage', false);
        default:
            return state;
    }
};

export { reducer as boardOverviewReducer };
