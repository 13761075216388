import * as React from 'react';
import {useState} from "react";
import {useEffect} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, RouteComponentProps} from "react-router";
import {Dimmer, Loader} from "semantic-ui-react";
import {ResponseType} from "../../api/util";
import {Action as Notify} from "../../NotificationSystem";
import {OrganizationId} from "../../Organization/model/Organization";
import * as Routes from "../../routes";
import * as UserModule from "../../User";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {ackBoardInvitation, fetchBoardOfInvitation} from "../actions/api";
import {BoardId, BoardName} from "../model/Board";

interface OwnProps {

}

interface RouteMatchParams {
    invitation: string;
}

type BoardInvitationProps = OwnProps & RouteComponentProps<RouteMatchParams> & WithNamespaces;

const BoardInvitation = (props: BoardInvitationProps) => {
    const invitationId = props.match.params.invitation;

    const currentUserSelector = makeGetCurrentUser();

    const currentUser = useSelector(currentUserSelector);
    const [doRedirect, setDoRedirect] = useState(false);
    const [redirectPath, setRedirectPath] = useState(Routes.inspectio);

    const dispatch = useDispatch();

    useEffect(() => {
        if(currentUser.signedIn) {
            fetchBoardOfInvitation(invitationId)
            .then(({response, error}) => {
                if(error) {
                    dispatch(Notify.Command.error('Request failed', 'Failed to process board invitation'));
                    setDoRedirect(true);
                    return;
                }

                if(response) {
                    const boardName: BoardName = response.data.name;
                    const boardId: BoardId = response.data.boardId;
                    const organizationId: OrganizationId | null = response.data.organizationId;

                    let retries = 0;

                    const handleAckResponse = (ackResponse: ResponseType) => {
                        if(ackResponse.error) {
                            // Invited user is new and username sync seems still be in progress
                            if(retries < 2) {
                                retries++;
                                window.setTimeout(() => {
                                    ackBoardInvitation(invitationId).then(handleAckResponse);
                                }, 100);
                                return;
                            }

                            dispatch(Notify.Command.error('Command failed', 'Failed to process board invitation'));
                            setDoRedirect(true);
                            return;
                        }

                        if(organizationId) {
                            dispatch(UserModule.Action.Command.switchActiveOrganization(organizationId));
                        }

                        dispatch(Notify.Command.info(
                            props.t('insp.board.joined.title'),
                            props.t('insp.board.joined.details', {name: boardName}))
                        );
                        setRedirectPath(Routes.compileInspectioBoardWorkspace(boardId));
                        setDoRedirect(true);
                    };

                    ackBoardInvitation(invitationId)
                        .then(handleAckResponse);
                }
            });
        }
    }, [currentUser.signedIn]);


    if(doRedirect) {
        return <Redirect to={redirectPath} />
    }

    return <Dimmer active={true} className="page"><Loader size="large">Processing Board Invitation</Loader></Dimmer>;
};

export default withNamespaces()(BoardInvitation);
