import * as React from 'react';
import {Button} from "semantic-ui-react";

interface OwnProps {
  hasSuggestions: boolean;
  onSuggest: () => void;
}

type CodySuggestButtonProps = OwnProps;

const CodySuggestButton = (props: CodySuggestButtonProps) => {
  return <Button basic={true}
                 compact={true}
                 icon="arrow alternate circle up"
                 className="noborder"
                 content={'Cody Suggest'} disabled={!props.hasSuggestions} onClick={() => props.onSuggest()} />
};

export default CodySuggestButton;
