import * as React from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import {NavLink} from "react-router-dom";
import {Button, Dropdown} from "semantic-ui-react";
import * as Routes from "../../routes";
import SideMenuOrganizationSwitch from "../../User/components/SideMenuOrganizationSwitch";
import {useSelector} from "react-redux";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeActiveUserOrganizationInfoSelector} from "../../Organization/selectors/selectUserOrganizationInfo";

interface OwnProps {

}

type OrganizationProps = OwnProps & RouteComponentProps;

const Organization = (props: OrganizationProps) => {
    const currentUser = useSelector(makeGetCurrentUser());
    const activeOrganization = useSelector(makeActiveUserOrganizationInfoSelector());

    return <div className="item">
        {activeOrganization && activeOrganization.userIsAdmin && <NavLink to={Routes.organizationOverview}><Button className="large basic"><i
            className="building icon"/> Organization</Button></NavLink>}
        {(!activeOrganization || !activeOrganization.userIsAdmin) && <Button className="large basic organization" disabled={true}>
            <i className="building icon"/> Organization
        </Button>}
        <SideMenuOrganizationSwitch />
    </div>
};

export default withRouter(Organization);
