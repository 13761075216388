import {Record} from 'immutable';
import * as uuid from "uuid";
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {BoardId} from "./Board";
import {NodeType} from "./Graph";

export const AGGREGATE_TYPE = 'StickyTemplate';

export type StickyTemplateId = string;
export type StickyTemplateError = string;
export type TemplateString = string;
export type TemplateType = 'json'|'xml'|'yaml';
export type TemplateSchema = string|null;

export interface StickyTemplateProps {
    uid: StickyTemplateId;
    aggregateType: AggregateType;
    cellType: NodeType;
    template: TemplateString;
    templateType: TemplateType;
    templateSchema?: TemplateSchema;
    boardId: BoardId;
}

export const defaultStickyTemplateProps: StickyTemplateProps = {
    uid: '',
    aggregateType: AGGREGATE_TYPE,
    cellType: NodeType.misc,
    template: '',
    templateType: 'json',
    templateSchema: undefined,
    boardId: '',
};

export const deriveStickTemplateId = (boardId: BoardId, cellType: NodeType): StickyTemplateId => {
    return `${boardId}__${cellType}`;
}

export const createStickyTemplateFromServerData = (data: any): Either<StickyTemplateError, StickyTemplate> => {
    const validatedData: Either<StickyTemplateError, any> = validate(data,
        [hasKey('boardId'), "boardId missing"],
        [hasKey('cellType'), "cellType missing"],
        [hasKey('template'), "template missing"],
        [hasKey('templateType'), "templateType is missing"]
    );

    if (isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new StickyTemplate({
        ...defaultStickyTemplateProps,
        ...data,
        uid: deriveStickTemplateId(data.boardId, data.cellType)
    }));
};

export const createStickyTemplateFromDefault = (boardId: BoardId, cellType: NodeType, template: string): StickyTemplate => {
    return new StickyTemplate({
        uid: deriveStickTemplateId(boardId, cellType),
        aggregateType: AGGREGATE_TYPE,
        cellType,
        boardId,
        template,
        templateType: 'json',
    });
};

export class StickyTemplate extends Record(defaultStickyTemplateProps) implements StickyTemplateProps, Aggregate {
    public constructor(data: StickyTemplateProps) {
        if (data.uid === '') {
            data.uid = uuid.v4()
        }

        if (!data.templateSchema) {
            data.templateSchema = undefined;
        }

        super(data);
    }

    public setTemplate(template: TemplateString): StickyTemplate {
        return this.set('template', template);
    }

    public setTemplateType(templateType: TemplateType): StickyTemplate {
        return this.set('templateType', templateType);
    }

    public setTemplateSchema(templateSchema: TemplateSchema): StickyTemplate {
        return this.set('templateSchema', templateSchema);
    }
}
