import {fromJS} from "immutable";
import {combineReducers} from "redux-immutable";
import {ActionType} from "typesafe-actions";
import * as Commands from "../actions/commands";
import * as Events from "../actions/events";
import {iioUserInfoReducer, IioUserInfoState} from "./applyIioUsersActions";
import {userReducer, UserState} from "./applyUserActions";
import {userSettingsReducer, UserSettingsState} from "./applyUserSettingsActions";

export type InspectioUserAction = ActionType<typeof Commands> & ActionType<typeof Events>;

export interface InspectioUsersState {
    users: UserState
    userSettings: UserSettingsState,
    iioUsersInfo: IioUserInfoState
}

export const inspectioUserReducer = combineReducers<InspectioUsersState, InspectioUserAction>({
    users: userReducer,
    userSettings: userSettingsReducer,
    iioUsersInfo: iioUserInfoReducer,
}, () => fromJS({users: undefined, userSettings: undefined, iioUsersInfo: undefined}));