import {List} from "immutable";
import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Dropdown} from "semantic-ui-react";
import {Node} from "../../../model/Graph";
import CellIcon from "../../CellIcon";
import {getNodeNameForNodeSelect} from "./NodeSelect";

interface OwnProps {
  placeholder?: string,
  nodeOptions: List<Node>,
  selected: List<Node>,
  onChange: (selected: List<Node>) => void,
  disabled?: boolean,
  uniqueName?: boolean,
  withNs?: boolean,
}

type MultipleNodeSelectProps = OwnProps & WithNamespaces;

const MultipleNodeSelect = (props: MultipleNodeSelectProps) => {
  const selectedIds = props.selected.map(s => s.getId()).toArray();

  const handleSelectNode = (nodeIds: string[]) => {
    const selectedNodes = props.nodeOptions.filter(node => nodeIds.includes(node.getId()));
    props.onChange(selectedNodes);
  }

  const existingNames: string[] = [];
  const namesOfSelectedNodes: string[] = props.selected.map(s => getNodeNameForNodeSelect(s, !!props.withNs)).toArray();
  const options = props.nodeOptions.filter(n => {
    // First we filter out all selected nodes
    if(selectedIds.includes(n.getId())) {
      return false
    }

    // If unique name is also set, we remove all similar nodes, too
    return !(props.uniqueName && namesOfSelectedNodes.includes(getNodeNameForNodeSelect(n, !!props.withNs)));
  }).push(...props.selected.toArray()).map(node => ({
    key: node.getId(),
    text: getNodeNameForNodeSelect(node, !!props.withNs),
    value: node.getId(),
    icon: <CellIcon cellType={node.getType()} />
  })).filter(o => {
    if(!props.uniqueName) {
      return true;
    }

    if(existingNames.includes(o.text)) {
      return false;
    }

    existingNames.push(o.text);
    return true;
  }).toArray();

  console.log("[CodyWizard] NodeSelect", props.selected, options);


  return <Dropdown
       placeholder={props.placeholder || props.t('insp.cody_wizard.node_select.default_placeholder') as string}
       disabled={props.disabled}
       selection={true}
       fluid={true}
       multiple={true}
       clearable={true}
       options={options}
       onChange={(evt, data) => handleSelectNode(data.value as string[])}
       value={selectedIds}
       search={true}
  />
};

export default withNamespaces()(MultipleNodeSelect);

