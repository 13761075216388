import {JSONSchema7} from "json-schema";
import {ImmutableDDDWizardContext} from "../../../../service/cody-wizard/context/ddd-action";
import {ImmutableInformationContext} from "../../../../service/cody-wizard/context/information-context";
import {
  makeAssignVariable,
  makeCallService,
  makeDefinitions, makeExecuteRules, makeForEach,
  makeThenThrowError, withFilterTypes, withProjectionOperations
} from "./rule-engine-definitions";

export const makeProjectionSchema = (possibleEvents: string[], possibleServices: string[], ctx: ImmutableDDDWizardContext | ImmutableInformationContext): string => {
  const schema: JSONSchema7 = {
    type: "object",
    additionalProperties: false,
    required: ["cases"],
    properties: {
      name: {
        type: "string"
      },
      live: {
        type: "boolean",
        default: true
      },
      cases: {
        type: "array",
        items: {
          type: "object",
          additionalProperties: false,
          required: ["when", "then"],
          properties: {
            given: {
              type: "array",
              items: {
                $ref: "#/definitions/rule_definition"
              }
            },
            when: {
              type: "string",
              enum: possibleEvents
            },
            then: {
              oneOf: [
                {
                  $ref: "#/definitions/rule_then_insert_projection"
                },
                {
                  $ref: "#/definitions/rule_then_upsert_projection"
                },
                {
                  $ref: "#/definitions/rule_then_update_projection"
                },
                {
                  $ref: "#/definitions/rule_then_replace_projection"
                },
                {
                  $ref: "#/definitions/rule_then_delete_projection"
                }
              ]
            }
          }
        }
      }
    },
    definitions: withProjectionOperations(withFilterTypes(makeDefinitions(
      makeThenThrowError(),
      makeAssignVariable(),
      makeCallService(possibleServices),
      makeForEach(),
      makeExecuteRules()
    )))
  }

  return JSON.stringify(schema);
}
