import {action} from "typesafe-actions";
import {Email, UserId} from "../../User/model/UserInfo";
import {OrganizationId, OrganizationName} from "../model/Organization";
import {OrganizationInvitationId} from "../model/OrganizationInvitation";
import {
    ACCEPT_INVITATION_TO_ORGANIZATION,
    DEMOTE_USER_TO_ORGANIZATION_MEMBER,
    INVITE_USER_TO_ORGANIZATION,
    PROMOTE_USER_TO_ORGANIZATION_ADMIN, REMOVE_GUEST_FROM_ORGANIZATION,
    REMOVE_USER_FROM_ORGANIZATION, RENAME_ORGANIZATION,
    RESEND_USER_ORGANIZATION_INVITATION,
    REVOKE_USER_ORGANIZATION_INVITATION,
    SET_UP_EXPLICIT_ORGANIZATION, TURN_GUEST_INTO_MEMBER
} from "./constants";

export const setUpExplicitOrganization = (organizationId: OrganizationId, name: OrganizationName) => action(SET_UP_EXPLICIT_ORGANIZATION, {organizationId, name});

export const inviteUserToOrganization = (organizationId: OrganizationId, invitedUserEmail: Email) => action(INVITE_USER_TO_ORGANIZATION, {organizationId, invitedUserEmail});
export const revokeUserOrganizationInvitation = (organizationId: OrganizationId, invitationId: OrganizationInvitationId, invitedUserEmail: Email) => action(REVOKE_USER_ORGANIZATION_INVITATION, {organizationId, invitationId, invitedUserEmail});
export const resendUserOrganizationInvitation = (organizationId: OrganizationId, invitationId: OrganizationInvitationId, invitedUserEmail: Email) => action(RESEND_USER_ORGANIZATION_INVITATION, {organizationId, invitationId, invitedUserEmail});
export const acceptInvitationToOrganization = (organizationId: OrganizationId, invitationId: OrganizationInvitationId) => action(ACCEPT_INVITATION_TO_ORGANIZATION, {organizationId, invitationId});
export const promoteUserToOrganizationAdmin = (organizationId: OrganizationId, userId: UserId) => action(PROMOTE_USER_TO_ORGANIZATION_ADMIN, {organizationId, userId});
export const demoteUserToOrganizationMember = (organizationId: OrganizationId, userId: UserId) => action(DEMOTE_USER_TO_ORGANIZATION_MEMBER, {organizationId, userId});
export const removeUserFromOrganization = (organizationId: OrganizationId, userId: UserId) => action(REMOVE_USER_FROM_ORGANIZATION, {organizationId, userId});
export const renameOrganization = (organizationId: OrganizationId, newName: OrganizationName) => action(RENAME_ORGANIZATION, {organizationId, newName});
export const removeGuestFromOrganization = (organizationId: OrganizationId, userId: UserId) => action(REMOVE_GUEST_FROM_ORGANIZATION, {organizationId, userId});
export const turnGuestIntoMember = (guestId: UserId, organizationId: OrganizationId) => action(TURN_GUEST_INTO_MEMBER, {guestId, organizationId});
