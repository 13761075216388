import {io} from "socket.io-client";
import {isStandalone} from "../standalone/util";

export default io({
    path: '/board/socket/',
    autoConnect: false,
    multiplex: true,
    reconnection: true,
    transportOptions: {
        polling: {
            extraHeaders: {}
        }
    }
});


