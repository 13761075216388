import {Node} from "../../../model/Graph";
import {parseNodeMetadata} from "../node/metadata";
import {RulesDependenciesMetadata} from "../rule-engine/types";
import {Schema} from "../schema/schema";
import {UiSchema} from "../ui/ui-schema";
import {Rule} from "../rule-engine/configuration";

export interface CommandMetadata {
  aggregateCommand: boolean;
  newAggregate?: boolean;
  shorthand?: boolean;
  schema?: Schema;
  uiSchema?: UiSchema;
  dependencies?: RulesDependenciesMetadata;
  rules?: Rule[];
  deleteState?: boolean;
  deleteHistory?: boolean;
  streamId?: string;
}

export const getCommandMetadata = (command: Node): CommandMetadata => {
  const metadata = parseNodeMetadata(command);

  if(!command.getMetadata()) {
    // New commands are non-aggregate commands by default
    metadata.aggregateCommand = false;
  }

  if(metadata.schema) {
    metadata.schema = new Schema(metadata.schema);
  }

  // Existing commands without flag, are aggregate commands by default to keep BC
  if(typeof metadata.aggregateCommand === "undefined") {
    metadata.aggregateCommand = true;
  }

  if(typeof metadata.newAggregate === "undefined") {
    metadata.newAggregate = metadata.aggregateCommand;
  }

  return metadata as CommandMetadata;
}
