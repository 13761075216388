import {Node, NodeType} from "../../../model/Graph";

export const getConnectedUiTargetIfPossible = (vo: Node): Node | undefined => {
  const targets = vo.getTargets();

  if(targets.count() === 1) {
    const target = targets.first(undefined);

    if(target && target.getType() === NodeType.ui) {
      return target;
    }
  }
}
