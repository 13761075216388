import {List} from "immutable";
import { call, fork, put, take } from 'redux-saga/effects';
import * as Action from '../../InspectioBoards/actions';
import {updateBoards} from "../../InspectioBoards/actions/commands";
import {initialBoardListLoaded} from "../../InspectioBoards/actions/events";
import {BoardModel} from "../../InspectioBoards/model";
import {deriveAbbreviationFromBoardName} from "../../InspectioBoards/model/Board";
import {BoardVersion, defaultBoardVersionProps} from "../../InspectioBoards/model/BoardVersion";
import {Action as UserAction, UserModel} from "../../User";
import {UserSettings, UserSettingsValue} from "../../User/model/UserSettings";
import {StandaloneBoard, StandaloneUserSetting} from "../storage/types";
import {standaloneStorageManager} from "../util";
import {DEFAULT_PERMISSIONS} from "../../InspectioBoards/model/BoardPermissionForUser";

function* onLoadUserSettings() {
    const userSettings: StandaloneUserSetting[] = yield call(standaloneStorageManager.getUserSettings);
    const values: Record<string, UserSettingsValue> = {};

    userSettings.forEach(setting => {
        values[setting.key] = setting.value;
    });

    yield put(UserAction.Event.userSettingsLoaded(new UserSettings({
        userId: standaloneStorageManager.getUser().userId,
        values,
    })));
}

function* onSignIn() {
    yield put(UserAction.Command.signIn({
        uid: standaloneStorageManager.getUser().userId,
        name: 'standalone',
        email: '',
        givenName: '',
        familyName: '',
        roles: {
            roleMap: {
                anonymous: {
                    type: 'anonymous'
                }
            } as any
        } as any,
        account: {
            accountType: 'free'
        },
        quota: 1000,
        teamQuota: 1,
        displayName: 'standalone',
        avatarUrl: '',
        companyName: '',
        jobTitle: '',
        signedIn: true
    } as UserModel.UserInfo));
}

export function* onFetchUserBoards() {
    yield put(Action.Query.startFetchUserBoards());

    const data: StandaloneBoard[] = yield call(standaloneStorageManager.getBoards);
    yield put(updateBoards(data.map(boardData => new BoardModel.Board({
        uid: boardData.boardId,
        name: boardData.name,
        nameAbbreviation: boardData.nameAbbreviation || deriveAbbreviationFromBoardName(boardData.name),
        tags: List(boardData.tags || []),
        createdAt: boardData.createdAt || boardData.lastModified,
        lastModified: boardData.lastModified,
        version: new BoardVersion(defaultBoardVersionProps),
        shared: false,
        sharedWith: List(),
        assignedTeams: List(),
        example: false,
        owner: standaloneStorageManager.getUser().userId,
        creator: null,
        organization: null,
        tempBoard: false,
        orgaMemberPermissions: DEFAULT_PERMISSIONS,
        anyonePermissions: DEFAULT_PERMISSIONS,
        codySuggestEnabled: typeof boardData.codySuggestEnabled !== 'undefined' ? boardData.codySuggestEnabled : true,
        eventModelingEnabled: typeof boardData.eventModelingEnabled !== 'undefined' ? boardData.eventModelingEnabled : true,
    }))));

    yield put(initialBoardListLoaded());
}

export function* standaloneFetchUserBoardsFlow() {
    while (true) {
        yield take(Action.Type.FETCH_USER_BOARDS);
        yield fork(onFetchUserBoards);
        yield fork(onSignIn);
        yield fork(onLoadUserSettings);
    }
}
