import * as React from 'react';
import {useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Button, Dropdown, DropdownProps, Modal} from "semantic-ui-react";
import * as BoardModule from "../../InspectioBoards";
import {TeamId} from "../model/Team";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeOrganizationSelector} from "../../Organization/selectors/selectOrganization";

interface OwnProps {
    teamId: TeamId;
    open: boolean;
    onClose: () => void;
    onSubmit: (selectedBoard: BoardModule.BoardModel.BoardId) => void;
}

type AddBoardToTeamModalProps = OwnProps & WithNamespaces;

const AddBoardToTeamModal = (props: AddBoardToTeamModalProps) => {

    const userBoards = useSelector(BoardModule.Selector.BoardListSelector.makeGetAllBoards());
    const [selectedBoard, setSelectedBoard] = useState('');
    const currentUser = useSelector(makeGetCurrentUser());
    const organization = useSelector(makeOrganizationSelector(currentUser.activeOrganization));

    const isAdmin = organization ? organization.isAdmin(currentUser.uid) : false;

    const boardChoices = userBoards.filter(board => (board.organization? isAdmin : !board.shared) && !board.assignedTeams.contains(props.teamId)).map(board => ({
        key: board.uid,
        value: board.uid,
        text: board.name,
    })).toArray();

    const handleChange = (e: React.SyntheticEvent, dProps: DropdownProps) => setSelectedBoard(dProps.value as string);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        props.onSubmit(selectedBoard);
        setSelectedBoard('');
    };

    return (
        <Modal open={props.open} closeIcon={true} onClose={props.onClose} size="mini">
            <Modal.Header>{props.t('insp.team.grant_access')}</Modal.Header>
            <Modal.Content>
                <form onSubmit={handleSubmit}>
                    <Dropdown
                        placeholder={props.t('insp.team.choose_board') as string}
                        fluid={true}
                        search={true}
                        selection={true}
                        options={boardChoices}
                        onChange={handleChange}
                        value={selectedBoard}
                    />
                    <div style={{marginTop: '10px'}}>
                        <Button primary={true} type="submit" disabled={selectedBoard === ''}>
                            {props.t('app.form.confirm')}
                        </Button>
                    </div>
                </form>
            </Modal.Content>
        </Modal>
    );
};

export default withNamespaces()(AddBoardToTeamModal);
