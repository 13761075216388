import {List} from "immutable";
import {call, fork, put, select, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as Action from '../../InspectioBoards/actions';
import {setBoardToSnapshot} from "../../InspectioBoards/actions/commands";
import {BoardSnapshot, BoardVersioning} from "../../InspectioBoards/model";
import {Board} from "../../InspectioBoards/model/Board";
import {defaultBoardVersionProps} from "../../InspectioBoards/model/BoardVersion";
import {makeBoardByIdSelector} from "../../InspectioBoards/selectors/board";
import {makeGetAllBoards} from "../../InspectioBoards/selectors/boardList";
import {StandaloneBoard} from "../storage/types";
import {standaloneStorageManager} from "../util";
import {onFetchUserBoards} from "./fetchUserBoardsFlow";

type FlowAction = ActionType<typeof Action.Query.fetchUserBoard>;

const onFetchUserBoard = function* (action: FlowAction) {
    // Make sure that the board list is loaded; Relevant for deep-links to a specific board
    const boards: List<Board> = yield select(makeGetAllBoards());
    if (boards.size < 1) {
        yield call(onFetchUserBoards);
    }

    const tempBoard = yield select(makeBoardByIdSelector(action.payload.boardId))

    if(tempBoard && tempBoard.tempBoard) {
        yield put(Action.Command.setBoardDetailsFetched(action.payload.boardId, false));
        return;
    }

    const boardData: StandaloneBoard|null = yield call(standaloneStorageManager.getBoard, action.payload.boardId);
    if (!boardData) {
        return;
    }

    const snapshot = new BoardSnapshot.BoardSnapshot({
        boardId: boardData.boardId,
        xml: boardData.xml,
        boardVersion: new BoardVersioning.BoardVersion(defaultBoardVersionProps)
    });

    yield put(setBoardToSnapshot(snapshot));
    yield put(Action.Command.setBoardDetailsFetched(action.payload.boardId, true));
}

export function* standaloneFetchUserBoardFlow() {

    while (true) {
        const action: FlowAction = yield take(Action.Type.FETCH_USER_BOARD);
        yield fork(onFetchUserBoard, action);
    }
}
