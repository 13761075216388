import {SORT_OPTIONS} from "../components/BoardOverview/Sorting";
import BoardsIndexedDB, {OBJECT_STORE_OVERVIEW} from "./BoardsIndexedDB";
import {TeamId} from "../../InspectioTeams/model/Team";

interface SortingSet {
    key: 'sorting';
    value: SORT_OPTIONS;
}

interface ResponsibleTeamSet {
    key: 'responsibleTeam';
    value: TeamId | undefined;
}

class BoardsStorageManager {
    private db: BoardsIndexedDB;

    constructor(db: BoardsIndexedDB) {
        this.db = db;
        this.getOverviewSorting = this.getOverviewSorting.bind(this);
        this.setOverviewSorting = this.setOverviewSorting.bind(this);
        this.getOverviewResponsibleTeam = this.getOverviewResponsibleTeam.bind(this);
        this.setOverviewResponsibleTeam = this.setOverviewResponsibleTeam.bind(this);
    }

    public async getOverviewSorting(): Promise<SORT_OPTIONS> {
        const sorting: SortingSet = await this.db.get(OBJECT_STORE_OVERVIEW, 'sorting') || {key: 'sorting', value: SORT_OPTIONS.LAST_MODIFIED_DESC};

        return sorting.value;
    }

    public async setOverviewSorting(sort: SORT_OPTIONS): Promise<void> {
        await this.db.put(OBJECT_STORE_OVERVIEW, {key: 'sorting', value: sort});
    }

    public async getOverviewResponsibleTeam(): Promise<TeamId | undefined> {
        const team: ResponsibleTeamSet = await this.db.get(OBJECT_STORE_OVERVIEW, 'responsibleTeam') || {key: "responsibleTeam", value: undefined};

        return team.value;
    }

    public async setOverviewResponsibleTeam(team: TeamId | undefined): Promise<void> {
        await this.db.put(OBJECT_STORE_OVERVIEW, {key: "responsibleTeam", value: team});
    }
}

export default BoardsStorageManager;
