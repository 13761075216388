import {List} from "immutable";
import * as React from 'react';
import {List as SemanticList} from "semantic-ui-react";
import {TeamInvitation} from "../model/TeamInvitation";
import OpenInvitation from "./OpenInvitation";

interface OwnProps {
    invitations: List<TeamInvitation>
}

type OpenInvitationListProps = OwnProps;

const OpenInvitationList = (props: OpenInvitationListProps) => {

    const items = props.invitations.map((invitation) => {
        return <OpenInvitation teamInvitation={invitation} key={invitation.uid}/>;
    });

    return <SemanticList relaxed={true} divided={true}>
        {items}
    </SemanticList>;
};

export default OpenInvitationList;