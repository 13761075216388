import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../../InspectioBoards";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as OrgaModule from "../../Organization";
import {makeBoardByIdSelector} from "../selectors/board";

type Command = ActionType<typeof Action.Command.moveUserBoardToOrganization>

function* handleMoveUserBoardToOrganization(command: Command) {
    const {response, error} = yield call(Action.Api.moveUserBoardToOrganization, command.payload.organizationId, command.payload.boardId);

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not move board to organization.'));
    }

    if(response) {
        yield put(Action.Query.fetchUserBoards());
        yield put(OrgaModule.Action.Query.fetchUserOrganizations());

        const board = yield select(makeBoardByIdSelector(command.payload.boardId));

        if(board) {
            yield put(Action.Event.userBoardMovedToOrganization(board.moveToOrganization(command.payload.organizationId)));
        }


        yield put(NotifyAction.Command.info('Board moved', 'The board has been moved to the organization.'));
    }
}

export function* moveUserBoardToOrganization() {
    while (true) {
        const command: Command = yield take([
            Action.Type.MOVE_USER_BOARD_TO_ORGANIZATION
        ]);

        yield fork(handleMoveUserBoardToOrganization, command);
    }
}
