import * as React from 'react';

interface OwnProps {

}

type CopyrightProps = OwnProps;

const Copyright = (props: CopyrightProps) => {
    return <div className="bottom aligned item">
        <p className="copyright">&copy; {(new Date()).getFullYear()} <a href="http://prooph-software.de">prooph software GmbH</a></p>
    </div>
};

export default Copyright;
