import * as React from "react";
import {useState} from "react";
import {useRef} from "react";
import {ChangeEvent} from "react";
import {FormEvent} from "react";
import {WithNamespaces, withNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Input, Message, Modal, Transition} from "semantic-ui-react";
import * as uuid from 'uuid';
import * as Action from "../../InspectioTeams/actions";
import * as UserSelector from "../../User/selectors/currentUser";
import {makeActiveUserOrganizationInfoSelector} from "../../Organization/selectors/selectUserOrganizationInfo";

type AddTeamProps = WithNamespaces & RouteComponentProps;

const AddTeam = (props: AddTeamProps) => {

    const [visible, setVisible] = useState(false);
    const [teamName, setTeamName] = useState('');
    const [hasError, setHasError] = useState(false);

    const activeOrganization = useSelector(makeActiveUserOrganizationInfoSelector());

    const inputEl = useRef<Input>(null);
    const dispatch = useDispatch();

    const handleClick = () => {
        setVisible(!visible);

        if (!visible) {
            window.setTimeout(() => {
                inputEl.current!.focus();
            }, 200);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (teamName === '') {
            setHasError(true);
            return;
        }

        dispatch(Action.Command.addTeam(uuid.v4(), teamName, activeOrganization? activeOrganization.uid : null));
        setTeamName('');
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTeamName(e.target.value);
        setHasError(e.target.value === '');
    };

    return (
        <div className="item">            
            <Button className="large basic" onClick={handleClick}><i className="plus icon" /> Add&nbsp;Team</Button>
            <Transition animation="fade down" visible={visible} duration={200} >
                <div className="ui icon input">
                    <form onSubmit={handleSubmit}>
                        <Input
                            placeholder={props.t("insp.team.team_name") + ' ...'}
                            fluid={true}
                            ref={inputEl}
                            error={hasError}
                            value={teamName}
                            onChange={handleChange}
                        />
                    </form>
                </div>
            </Transition>
        </div>
    );
};

export default withNamespaces()(withRouter(AddTeam));
