import {List, Record} from 'immutable';
import * as uuid from 'uuid';
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {UserId} from "../../User/model/UserInfo";
import {OrganizationId} from "../../Organization/model/Organization";

export type TeamId = string;
export type TeamName = string;
export type TeamDescription = string;
export type TeamMemberQuota = number;

export type TeamValidationError = string;

export const AGGREGATE_TYPE = 'Team';

export interface TeamProps {
    uid: TeamId;
    name: TeamName;
    description: TeamDescription;
    ownerId: UserId;
    members: List<UserId>;
    admins: List<UserId>;
    deleted: boolean;
    aggregateType: AggregateType;
    isNew: boolean;
    organizationId: OrganizationId | null;
}

export const defaultTeamProps: TeamProps = {
    uid: '',
    name: '',
    description: '',
    ownerId: '',
    members: List(),
    admins: List(),
    deleted: true,
    aggregateType: AGGREGATE_TYPE,
    isNew: false,
    organizationId: null,
};

export const createTeamFromServerData = (data: any): Either<TeamValidationError, Team> => {
    const validatedData: Either<string, any> = validate(data,
        [hasKey('teamId'), "teamId missing"],
        [hasKey('name'), "name missing in "+data.teamId],
        [hasKey('description'), "description missing in "+data.teamId],
        [hasKey('ownerId'), "ownerId missing in "+data.teamId],
        [hasKey('members'), "members missing in "+data.teamId],
        [hasKey('admins'), "admins missing in "+data.teamId],
        [hasKey('organizationId'), "organizationId missing in "+data.teamId],
        [hasKey('deleted'), "deleted missing in "+data.teamId],
    );

    if(isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new Team({
        uid: data.teamId,
        name: data.name,
        description: data.description,
        ownerId: data.ownerId,
        members: List(data.members),
        admins: List(data.admins),
        deleted: data.deleted,
        aggregateType: AGGREGATE_TYPE,
        isNew: data.isNew || false,
        organizationId: data.organizationId || null,
    }));
};

export const createNewTeam = (uid: TeamId): Team => {
    return new Team({...defaultTeamProps, uid, isNew: true});
};

export class Team extends Record(defaultTeamProps) implements TeamProps, Aggregate {
    public constructor(data: TeamProps) {
        if(data.uid === '') {
            data.uid = uuid.v4()
        }

        super(data);
    }

    public rename(newName: TeamName): Team {
        return this.set('name', newName);
    }

    public changeDescription(newDescription: TeamDescription): Team {
        return this.set('description', newDescription);
    }

    public removeMember(userId: UserId): Team {
        const state = this.set('members', this.members.filter(member => member !== userId));
        return state.set('admins', state.admins.filter(admin => admin !== userId));
    }

    public moveToOrganization(organizationId: OrganizationId): Team {
        return this.set('organizationId', organizationId);
    }

    public isAdmin(userId: UserId): boolean {
        return this.ownerId === userId || this.admins.contains(userId);
    }
}
