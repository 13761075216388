import {isStandalone} from "../standalone/util";

let client: WebSocket|null = null;
let reconnectTrys = 0;
let positionListener: (e: MessageEvent) => void = () => { /* no op */};
let currentUserId: string | null = null;
let currentSessionId: string | null = null;
let pingTimeout: number|null = null;
let heartbeatTimeout: number|null = null;

enum MessageType {
    GetUserPositions = 'GetUserPositions',
    GetUserPositionsResponse = 'GetUserPositionsResponse',
    UserPositionChanged = 'UserPositionChanged',
    UserStatusChanged = 'UserStatusChanged',
    UserInitialInfo = 'UserInitialInfo',
    InitialiseScreenShareInfo = 'InitialiseScreenShareInfo',
    InitiateOneOnOneCall = 'InitiateOneOnOneCall',
}

interface GetUserPositions {
    type: MessageType.GetUserPositions,
    id: string;
    sessionId: string;
}

interface GetUserPositionsResponse {
    type: MessageType.GetUserPositionsResponse,
    users: {[userId: string]: {
        position: {left: number, top: number},
    }}
}

interface GetUserPositions {
    type: MessageType.GetUserPositions,
}

interface UserPositionChanged {
    type: MessageType.UserPositionChanged,
    id: string;
    sessionId: string;
    left: number;
    top: number;
}

interface UserStatusChanged {
    type: MessageType.UserStatusChanged,
    id: string;
    sessionId: string;
    status: {micOn: boolean, megaphoneOn: boolean, cameraOn: boolean, handUpOn: boolean, emojiOn: boolean, showIconName: string, isSpeaking: boolean};
}

interface UserInitialInfo {
    type: MessageType.UserInitialInfo,
    id: string;
    sessionId: string;
    status: {name:string, avatar:string};
}

interface InitialiseScreenShareInfo {
    type: MessageType.InitialiseScreenShareInfo,
    id: string,
    sessionId: string,
    status: {originId: string, position: {left:number, top:number}}
}

interface InitiateOneOnOneCall {
    type: MessageType.InitiateOneOnOneCall,
    id: string,
    sessionId: string,
    status: {originId: string, targetId: string, request: boolean, awnser: boolean}
}

type Message = GetUserPositions | GetUserPositionsResponse | UserPositionChanged | UserStatusChanged | UserInitialInfo | InitialiseScreenShareInfo | InitiateOneOnOneCall;

const isConnected = () => {
    return client && client.readyState === client.OPEN;
}

const ping = () => {
    if(client && isConnected() && currentUserId && currentSessionId) {
        client.send(JSON.stringify({type: MessageType.GetUserPositions, id: currentUserId, sessionId: currentSessionId}));
    } else {
        reconnectTrys++;

        if(client) {
            client.close();
        }

        console.log("[vidu] trying to reconnect to socket. Attempt: " + reconnectTrys);
        connect();
    }

    pingTimeout = window.setTimeout(() => {
        ping();
    }, 30000);
}

const heartbeat = () => {
    if(heartbeatTimeout) {
        window.clearTimeout(heartbeatTimeout);
    }

    heartbeatTimeout = window.setTimeout(() => {
        if(client && isConnected()) {
            console.log("[vidu] Closing connection due to heartbeat timeout");
            client.close()
        }
        // ping interval + latency
    }, 30000 + 1000);
}



const connect = () => {
    client = new WebSocket('wss://mephisto-video.inseciacloud.com:3001');
    //client = new WebSocket('wss://localhost:3001');

    client.onopen = (evt) => {
        console.log("[vidu] heartbeat triggered on open")
        heartbeat();
        reconnectTrys = 0;
    }
    client.onclose = (evt) => {
        if(heartbeatTimeout) {
            console.log("[vidu] heartbeat timeout cleared on close")
            window.clearTimeout(heartbeatTimeout);
        }
    }

    client.onmessage = positionListener;
}

if(!isStandalone()) {
    connect();
}


const WebSocketConnection = {
    sendPositionUpdate: (id: string, sessionId: string, left: number, top: number) => {
        if(client && isConnected()) {
            client.send(JSON.stringify({ type: 'UserPositionChanged', id, sessionId, left, top, }));
        }
    },
    sendStatusUpdate: (id: string, sessionId: string, status: {micOn: boolean, megaphoneOn: boolean, cameraOn: boolean, handUpOn: boolean, emojiOn: boolean, showIconName: string, isSpeaking: boolean,}) => {
        if(client && isConnected()) {
            client.send(JSON.stringify({ type: 'UserStatusChanged', id, sessionId, status, }));
        }
    },
    sendUserInitialInfo: (id: string, sessionId: string, status: {name:string, avatar:string}) => {
        if(client && isConnected()) {
            client.send(JSON.stringify({ type: 'UserInitialInfo', id, sessionId, status, }));
        }
    },
    sendInitialiseScreenShareInfo: (id: string, sessionId: string, status: {originId:string, position: { left: number, top: number }}) => {
        if(client && isConnected()) {
            client.send(JSON.stringify({ type: 'InitialiseScreenShareInfo', id, sessionId, status, }));
        }
    },
    sendInitiateOneOnOneCall: (id: string, sessionId: string, status: {originId:string, targetId: string, request: boolean, awnser: boolean}) => {
        if(client && isConnected()) {
            client.send(JSON.stringify({ type: 'InitiateOneOnOneCall', id, sessionId, status, }));
        }
    },
    listenForUpdates: (id: string, sessionId: string, onChange: (
        id: string, 
        data: { 
            left: number, top: number } | 
            {micOn: boolean, megaphoneOn: boolean, cameraOn: boolean, handUpOn: boolean, emojiOn: boolean, showIconName: string, isSpeaking: boolean} | 
            {name:string, avatar:string} |
            {originId: string, position: {left:number, top:number}} |
            {originId: string, targetId: string, request: boolean, awnser: boolean}
    ) => void) => {

        currentUserId = id;
        currentSessionId = sessionId;

        positionListener = (e) => {
            const data: Message = JSON.parse(e.data);

            switch (data.type) {
                case MessageType.GetUserPositionsResponse:
                    console.log("[vidu] Got user positions from socket server: ", data);
                    heartbeat();

                    Object.keys(data.users).forEach(userId => {
                        if(id === userId) {
                            return;
                        }

                        const {position} = data.users[userId];

                        onChange(userId, position);
                    })
                    break;
                case MessageType.UserPositionChanged:
                    onChange(data.id, { left: data.left, top: data.top });
                    break;
                case MessageType.UserStatusChanged:
                    onChange(data.id, data.status)
                    break;
                case MessageType.UserInitialInfo:
                    onChange(data.id, data.status)
                    break;
                case MessageType.InitialiseScreenShareInfo:
                    onChange(data.id, data.status)
                    break;
                case MessageType.InitiateOneOnOneCall:
                    onChange(data.id, data.status)
                    break;
            }
        }


        if(client) {
            client.onmessage = positionListener;
            ping();
        }
    },
    stopListeningForPositionUpdates: () => {
        if(pingTimeout) {
            window.clearTimeout(pingTimeout);
        }

        currentUserId = null;
        currentSessionId = null;
    }
};

export default WebSocketConnection;
