import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {makeTeamInvitationSelector} from "../selectors/TeamInvitation";

type Command = ActionType<typeof Action.Command.revokeTeamInvitation>

function* handleRevokeTeamInvitation(command: Command) {
    const teamInvitation = yield select(makeTeamInvitationSelector(command.payload.invitationId));

    if(teamInvitation) {
        const {response, error} = yield call(Action.Api.revokeTeamInvitation, command.payload.invitationId);

        if(error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not revoke team invitation.'));
        }

        if(response) {
            yield put(NotifyAction.Command.info(
                'insp.team.notify.invitation_revoke.success.title',
                'insp.team.notify.invitation_revoke.success.msg',
                {email: teamInvitation.invitedUser}
            ));

            yield put(Action.Event.teamInvitationRevoked(teamInvitation.revoke()));
        }
    }
}

export function* revokeTeamInvitation() {
    while (true) {
        const command: Command = yield take([
            Action.Type.REVOKE_TEAM_INVITATION
        ]);

        yield fork(handleRevokeTeamInvitation, command);
    }
}