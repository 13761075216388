import {Node} from "../../../model/Graph";

export const parseNodeMetadata = (node: Node): Record<string, any> => {
  const meta = node.getMetadata();

  if(!meta) {
    return  {};
  }

  try {
    return JSON.parse(meta);
  } catch (e) {
    console.error("Failed to parse metadata of node: ", node, node.getMetadata());
    return {};
  }
}
