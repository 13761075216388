export const SET_UP_EXPLICIT_ORGANIZATION = '@@InspectioOrganizations/SetUpExplicitOrganization';
export const EXPLICIT_ORGANIZATION_SET_UP = '@@InspectioOrganizations/ExplicitOrganizationSetUp';
export const EXPLICIT_ORGANIZATION_SET_UP_FAILED = '@@InspectioOrganizations/ExplicitOrganizationSetUpFailed';

export const INVITE_USER_TO_ORGANIZATION = '@@InspectioOrganizations/InviteUserToOrganization';
export const USER_INVITED_TO_ORGANIZATION = '@@InspectioOrganizations/UserInvitedToOrganization';
export const REVOKE_USER_ORGANIZATION_INVITATION = '@@InspectioOrganizations/RevokeUserOrganizationInvitation';
export const RESEND_USER_ORGANIZATION_INVITATION = '@@InspectioOrganizations/ResendUserOrganizationInvitation';
export const USER_ORGANIZATION_INVITATION_REVOKED = '@@InspectioOrganizations/UserOrganizationInvitationRevoked';
export const USER_ORGANIZATION_INVITATION_RESENT = '@@InspectioOrganizations/UserOrganizationInvitationResent';
export const ACCEPT_INVITATION_TO_ORGANIZATION = '@@InspectioOrganizations/AcceptInvitationToOrganization';
export const INVITATION_TO_ORGANIZATION_ACCEPTED = '@@InspectioOrganizations/InvitationToOrganizationAccepted';
export const PROMOTE_USER_TO_ORGANIZATION_ADMIN = '@@InspectioOrganizations/PromoteUserToOrganizationAdmin';
export const USER_PROMOTED_TO_ORGANIZATION_ADMIN = '@@InspectioOrganizations/UserPromotedToOrganizationAdmin';
export const DEMOTE_USER_TO_ORGANIZATION_MEMBER = '@@InspectioOrganizations/DemoteUserToOrganizationMember';
export const USER_DEMOTED_TO_ORGANIZATION_MEMBER = '@@InspectioOrganizations/UserDemotedToOrganizationMember';
export const REMOVE_USER_FROM_ORGANIZATION = '@@InspectioOrganizations/RemoveUserFromOrganization';
export const USER_REMOVED_FROM_ORGANIZATION = '@@InspectioOrganizations/UserRemovedFromOrganization';

export const FETCH_ORGANIZATION = '@@InspectioOrganizations/FetchOrganization';
export const ORGANIZATION_FETCHED = '@@InspectioOrganizations/OrganizationFetched';

export const FETCH_USER_ORGANIZATIONS = '@@InspectioOrganizations/FetchUserOrganizations';
export const USER_ORGANIZATIONS_FETCHED = '@@InspectioOrganizations/UserOrganizationsFetched';

export const RENAME_ORGANIZATION = '@@InspectioOrganizations/RenameOrganization';
export const ORGANIZATION_RENAMED = '@@InspectioOrganizations/OrganizationRenamed';

export const REMOVE_GUEST_FROM_ORGANIZATION = '@@InspectioOrganizations/RemoveGuestFromOrganization';
export const GUEST_REMOVED_FROM_ORGANIZATION = '@@InspectioOrganizations/GuestRemovedFromOrganization';

export const TURN_GUEST_INTO_MEMBER = '@@InspectioOrganizations/TurnGuestIntoMember';
export const GUEST_TURNED_INTO_MEMBER = '@@InspectioOrganizations/GuestTurnedIntoMember';
