import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Link} from 'react-router-dom';
import {Icon, Popup} from 'semantic-ui-react';
import {printIsoDateString} from "../../core/dateTime";
import {compileInspectioBoardEdit, compileInspectioBoardWorkspace} from "../../routes";
import * as Action from "../actions";
import {BoardModel} from "../model";

export interface BoardCardProps {
    board: BoardModel.Board;
    isAdmin: boolean;
}

type EnhancedProps = BoardCardProps & WithNamespaces;

const BoardCard = (props: EnhancedProps) => {
    return <div className="ui card event">
        <Link to={compileInspectioBoardWorkspace(props.board.uid)}>
            <div className="content">
                <div className="header"/>
                <div className="description">
                    <span style={{ width: '100%', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        {props.board.name}
                    </span>
                </div>
            </div>
        </Link>
        <div className="extra">
            {(props.isAdmin || !props.board.shared) && <Link to={compileInspectioBoardEdit(props.board.uid)} className="ui right floated">
                <Icon name="edit outline" size={"large"} link={true} />
            </Link>}
            {(!props.isAdmin && props.board.shared) && <Popup trigger={<Icon name="share" size="large" className="ui right floated" />} content={props.t('insp.overview.shared_board')} />}
            <span style={{color: "rgb(160, 156, 156)", fontSize: "0.9em"}}>{ props.t('insp.overview.last_modified') + ": " + printIsoDateString(props.board.lastModified as string) }</span>
        </div>
    </div>
};

export default withNamespaces()(BoardCard);
