import React, {useState} from 'react'
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {Button, Label, Search, Table} from "semantic-ui-react";
import {Action as Notify} from "../../../NotificationSystem";
import {BoardId} from "../../model/Board";
import {Permissions} from "../../model/BoardPermissionForUser";
import {Email, UserId, Username} from "../../../User/model/UserInfo";
import {List} from "immutable";

export interface ShareBoardWithUserFormData  {
    boardId: BoardId,
    email: string,
    permissions: Permissions
}

export interface OwnProps {
    boardId: BoardId,
    email: string,
    permissions: Permissions,
    users: List<{ userId: UserId, name: Username, email: Email, avatar: string }>,
    handleSubmit: (data: ShareBoardWithUserFormData) => void,
}

export interface PropsFromDispatch {

}


export type ShareBoardFormProps = OwnProps & PropsFromDispatch & WithNamespaces;

const PAGE_EMAIL_INPUT = 1;
const PAGE_PERMISSIONS = 2;

const ShareBoardForm = (props: ShareBoardFormProps) => {

    const dispatch = useDispatch();
    const [email, setEmail] = useState<string>(props.email);
    const [writeAccess, setWriteAccess] = useState<boolean>(props.permissions.writeAccess);

    const filteredUsers = props.users
        .filter(user => user.name.toLocaleLowerCase().includes(email.toLocaleLowerCase()) || user.email.toLocaleLowerCase().includes(email.toLocaleLowerCase()))
        .sortBy(user => user.name)
        .map(user => {
        return {
            id: user.userId,
            title: user.name,
            description: user.email,
            image: user.avatar,
        }
    });

    const reset = () => {
        setWriteAccess(true);
        setEmail('');
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            dispatch(Notify.Command.error('Invalid email address', 'Invalid email address'));
            return;
        }

        props.handleSubmit({
            boardId: props.boardId,
            email,
            permissions: { ...props.permissions, writeAccess }
        });
        reset();
    };

    return (
        <form className="ui form" onSubmit={handleSubmit}>
            <div className="fields" style={{margin:"0"}}>
                <Table unstackable={true} basic="very">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{width: "310px"}}>
                                <Search
                                    fluid={true}
                                    value={email}
                                    results={filteredUsers.toArray()}
                                    placeholder={props.t('insp.board.form.email') as string}
                                    showNoResults={false}
                                    resultRenderer={data => {
                                        return <div key={data.id}>
                                            <div className="ui image avatar">
                                                <img src={data.image} alt={data.title} />
                                            </div>
                                            <div className="content">
                                                <div className="title">{data.title}</div>
                                                <div className="description">{data.description}</div>
                                            </div>
                                        </div>
                                    }}
                                    onSearchChange={(e, data) => setEmail(data.value || '')}
                                    onResultSelect={(e, data) => {
                                        setEmail(data.result.description)
                                    }}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Button.Group>
                                    <Button
                                        type={'button'}
                                        className={'positive'}
                                        children={'Read'}
                                        onClick={() => setWriteAccess(false)}
                                    />
                                    <Button.Or text="+" />
                                    <Button
                                        type={'button'}
                                        className={writeAccess ? 'positive' : ''}
                                        children={'Write'}
                                        onClick={() => setWriteAccess(!writeAccess)}
                                    />
                                </Button.Group>
                            </Table.Cell>
                            <Table.Cell>
                                <Button primary={true} type="submit" floated="right">
                                    {props.t('insp.board.form.btn_share')}
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        </form>
    );
};

export default withNamespaces()(ShareBoardForm);
