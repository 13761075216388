import * as React from 'react';
import {CSSProperties} from "react";
// tslint:disable-next-line:no-var-requires
const codyEmoji = require('../../../theme/img/cody.svg');

interface OwnProps {
    width?: number;
    style?: CSSProperties;
}

type CodyEmojiProps = OwnProps;

const CodyEmoji = (props: CodyEmojiProps) => {
    const width = props.width || 30;

    return <object className="console-avatar" data={codyEmoji} type="image/svg+xml" style={{width: width+'px', ...props.style}}/>;
};

export default CodyEmoji;
