import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useTranslation} from "react-i18next/hooks";

interface OwnProps {
    showAll: boolean;
    onClick: () => void;
}

type ShowMoreBoardsItemProps = OwnProps & WithNamespaces;

const ShowMoreBoardsItem = (props: ShowMoreBoardsItemProps) => {
    const handleClick = () => props.onClick();

    return <div className="ui item" style={{cursor: "pointer", textAlign: "center", color: "#00b5ad"}} onClick={handleClick}>
        {props.showAll? props.t('app.showLess') : props.t('app.showAll')}
    </div>
};

export default withNamespaces()(ShowMoreBoardsItem);