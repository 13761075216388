import {Record} from 'immutable';
import * as uuid from 'uuid';
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {TeamId} from "../../InspectioTeams/model/Team";
import {BoardId} from "./Board";

export type PermissionsId = string;
export interface Permissions {adminAccess: boolean; readAccess: boolean; writeAccess: boolean}

export type BoardPermissionValidationError = string;

export const AGGREGATE_TYPE = 'BoardPermission';

export interface BoardPermissionForTeamProps {
    uid: PermissionsId;
    aggregateType: AggregateType;
    permissions: Permissions;
    teamId: TeamId;
    boardId: BoardId;
}

export const defaultBoardPermissionProps: BoardPermissionForTeamProps = {
    uid: '',
    aggregateType: AGGREGATE_TYPE,
    permissions: {adminAccess: false, readAccess: false, writeAccess: false},
    teamId: '',
    boardId: '',
};

export const createBoardPermissionForTeamFromServerData = (data: any): Either<BoardPermissionValidationError, BoardPermissionForTeam> => {
    const validatedData: Either<string, any> = validate(data,
        [hasKey('permissionsId'), "permissionsId missing"],
        [hasKey('permissions'), "permissions missing in " + data.permissionsId],
        [hasKey('teamId'), "teamId missing in " + data.permissionsId],
        [hasKey('boardId'), "boardId missing in " + data.permissionsId],
    );

    if (isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new BoardPermissionForTeam({
        uid: data.permissionsId,
        aggregateType: AGGREGATE_TYPE,
        permissions: data.permissions,
        teamId: data.teamId,
        boardId: data.boardId
    }));
};

export class BoardPermissionForTeam extends Record(defaultBoardPermissionProps) implements BoardPermissionForTeamProps, Aggregate {
    public constructor(data: BoardPermissionForTeamProps) {
        if(data.uid === '') {
            data.uid = uuid.v4()
        }

        super(data);
    }

    public changePermissions(permissions: Permissions): BoardPermissionForTeam
    {
        return this.set('permissions', permissions);
    }
}
