import { Record } from 'immutable';

export const TYPE_ANONYMOUS = 'anonymous';
export const TYPE_USER = 'user';
export const TYPE_ADMIN = 'admin';

export type RoleType = 'anonymous' | 'user' | 'admin';

export interface RoleProps {
    type: RoleType;
}

export interface RoleMethods {
    isAnonymous(): boolean;
    isUser(): boolean;
    isAdmin(): boolean;
    equals(other: Role): boolean;
}

export interface RoleRecord extends RoleProps, RoleMethods {}

export const defaultUserProps: RoleProps = {
    type: TYPE_ANONYMOUS,
};

export const fromtString = (role: RoleType): Role => new Role({type: role});

export const anonymousRole = (): Role => new Role({type: TYPE_ANONYMOUS});

export const assertRoleType = (type: string): void => {
    switch (type) {
        case TYPE_ANONYMOUS:
        case TYPE_USER:
        case TYPE_ADMIN:
            break;
        default:
            throw new Error('Invalid role type "' + type + '" provided');
    }
};

export class Role extends Record(defaultUserProps) implements RoleRecord {
    public constructor(data: RoleProps) {
        assertRoleType(data.type);
        super(data);
    }

    public isAnonymous(): boolean {
        return this.type === TYPE_ANONYMOUS;
    }

    public isUser(): boolean {
        return this.type === TYPE_USER;
    }

    public isAdmin(): boolean {
        return this.type === TYPE_ADMIN;
    }

    public equals(other: Role): boolean {
        return this.type === other.type;
    }
}
