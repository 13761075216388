import {List} from "immutable";
import {action} from "typesafe-actions";
import {IioUserInfo} from "../model/IioUserInfo";
import {
    ACTIVE_ORGANIZATION_SWITCHED,
    IIO_USERS_INFO_LOADED,
    USER_SETTING_UPSERTED,
    USER_SETTINGS_LOADED
} from "./constants";
import {UserSettings, UserSettingsValue} from "../model/UserSettings";
import {UserInfo} from "../model/UserInfo";

export const iioUsersInfoLoaded = (usersInfo: List<IioUserInfo>) => action(IIO_USERS_INFO_LOADED, {usersInfo});

export const userSettingsLoaded = (userSettings: UserSettings) => action(USER_SETTINGS_LOADED, {userSettings});

export const userSettingUpserted = (key: string, value: UserSettingsValue) => action(USER_SETTING_UPSERTED, {key, value});

export const activeOrganizationSwitched = (user: UserInfo) => action(ACTIVE_ORGANIZATION_SWITCHED, user);
