import * as React from 'react';
import {useState} from "react";
import {AccordionContent, AccordionTitle, List} from "semantic-ui-react";

interface OwnProps {
    title: string;
    index: number;
    isActiveByDefault?: boolean;
    entries: React.ReactNode[];
}

const BoardHistoryGroup = (props: OwnProps) => {

    const [active, setActive] = useState(props.isActiveByDefault || false);

    const handleClick = () => setActive(!active);

    return <>
        <AccordionTitle content={props.title} index={props.index} active={active} onClick={handleClick} />
        <AccordionContent active={active}>
            <List selection={true}>
                { props.entries }
            </List>
        </AccordionContent>
    </>;
};

export default BoardHistoryGroup;