import * as React from 'react';
import {CSSProperties, ReactElement, useState} from 'react';
import {Icon} from "semantic-ui-react";
import {camelize} from "tslint/lib/utils";
import {CodyResponse, CodyResponseType} from "../../service/Cody";
import CodyEmoji from "./CodyEmoji";
import {DEFAULT_COLOR, ERROR_COLOR, INFO_COLOR} from "./definitions";

interface OwnProps {
    codyResponse: CodyResponse,
    codyName: string,
}

type CodyMessageProps = OwnProps;

const CodyMessage = (props: CodyMessageProps) => {

    const [showDetails, setShowDetails] = useState(false);

    let msgColor = DEFAULT_COLOR;
    let detailsColor = DEFAULT_COLOR;
    let fontWeight: 'normal' | 'bold' = 'normal';
    let detailsFontWeight: 'normal' | 'bold' = 'normal';
    let backgroundColor = 'white';

    if(props.codyResponse.type === CodyResponseType.Error) {
        msgColor = ERROR_COLOR;
        detailsColor = ERROR_COLOR;
        fontWeight = 'bold';
        detailsFontWeight = 'bold';
        backgroundColor = '#f1bec5';
    }

    if(props.codyResponse.type === CodyResponseType.Warning
        || props.codyResponse.type === CodyResponseType.Question
        || props.codyResponse.type === CodyResponseType.SyncRequired)
    {
        msgColor = '#653a12';
        detailsColor = '#653a12';
        fontWeight = 'bold';
        backgroundColor = '#fdd299';
    }

    if(!props.codyResponse.type || props.codyResponse.type === CodyResponseType.Info) {
        msgColor = INFO_COLOR;
        fontWeight = 'bold';
    }

    const msg = (!Array.isArray(props.codyResponse.cody))? [props.codyResponse.cody] : props.codyResponse.cody;
    let details;

    if(props.codyResponse.details) {
        details = props.codyResponse.details;

        if(!Array.isArray(details)) {
            details = [details];
        }
    }

    const toggleShowDetails = (e: React.MouseEvent) => {
        e.preventDefault();

        setShowDetails(!showDetails);
    }

    const defaultStyle = {color: msgColor, fontWeight};
    const detailsStyle = {color: detailsColor, fontWeight: detailsFontWeight};
    const showDetailsIcon = showDetails? <Icon name="caret down" size="large" /> : <Icon name="caret right" size="large" />;

    return <>
        <div className="console-message" style={{backgroundColor}}>
            <CodyEmoji />
            <div className="console-text">
                {applyMessageStyle(msg, defaultStyle, props.codyName)}
                {details && <a href="#" onClick={toggleShowDetails}>{showDetailsIcon}</a>}
            </div>
        </div>
        {details && showDetails && <div className="console-message" style={{backgroundColor}}>
            <CodyEmoji />
            <div className="console-text">
                {applyMessageStyle(details, detailsStyle)}
            </div>
        </div>}
    </>
};

export default CodyMessage;

const applyMessageStyle = (msgAndStyles: string[], defaultStyle: CSSProperties, codyName?: string): ReactElement[] => {
    let msg = msgAndStyles[0] || '';

    if(typeof msg !== 'string') {
        msg = msg as any;
        if(typeof msg.toString === 'function') {
            msg = msg.toString();
        } else {
            return [];
        }
    }

    const msgParts = msg.split('%c');

    const styles = msgAndStyles.slice(1);

    const spans: ReactElement[] = [];

    let nextStyle: CSSProperties = defaultStyle;

    let firstPart = true;

    for(const partI in msgParts) {
        if(typeof msgParts[partI] !== 'undefined') {
            let part = msgParts[partI];
            let firstCharIsWhitespace = false;
            let lastCharIsWhitespace = false;
            const length = part.length;

            if(length > 0) {
                if(part.charAt(length - 1) === ' ') {
                    lastCharIsWhitespace = true;
                    part = part.slice(0, length - 1)
                }

                if(part.charAt(0) === ' ') {
                    firstCharIsWhitespace = true;
                    part = part.slice(1);
                }
            }

            if(length > 0) {
                if(firstPart && codyName) {
                    part = `[${codyName}]: ` + part;
                    firstPart = false;
                }
                spans.push(<span key={spans.length + 1} style={nextStyle}>
                    {firstCharIsWhitespace && <>&nbsp;</>}{<>{part}</>}{lastCharIsWhitespace && <>&nbsp;</>}
                </span>);
            }

            if(styles[partI]) {
                nextStyle = parseStyleString(styles[partI]);
            } else {
                nextStyle = defaultStyle;
            }
        }
    }

    return spans;
}

const parseStyleString = (style: string): CSSProperties => {
    const cssProps: {[cssProp: string]: string} = {};

    const propStrs = style.split(';');

    for(const propStr of propStrs) {
        const [prop, val] = propStr.split(':');

        if(typeof prop !== 'string' || typeof val !== 'string') {
            continue;
        }

        cssProps[camelize(prop.trim())] = val;
    }

    return cssProps as CSSProperties;
}
