import {map} from "lodash";
import {ResponseType} from "../../api/util";
import {getToken} from "../../Auth/service/tokenCache";
import {UserId, Username} from "../../User/model/UserInfo";
import {Api} from "../actions";
import { BoardSync } from '../model';
import {Board} from "../model/Board";
import {BoardChanged} from "../model/BoardChanged";
import {BoardVersion} from "../model/BoardVersion";
import {marshalBoardChanged} from "../sagas/loadBoardHistoryFlow";
import ConfiguredSocket from '../../api/ConfiguredSocket';

type ConfiguredSocket = typeof ConfiguredSocket;

export class RealtimeBoardSync {
    private socket: ConfiguredSocket;
    private board: Board;
    private userId: UserId;
    private username: Username;
    private localQueue: BoardSync.BoardChanged[] = [];
    private incomingQueue: BoardSync.BoardChanged[] = [];
    private lastProcessedVersion: number;
    private lastRemoteVersion: number;
    private reconnectListener: (attempts?: number) => void;
    private isFetchingLatestPatches: boolean = false;
    private onHandledRemoteEvent: (event: BoardSync.BoardChanged, syncInitialized: boolean) => void;
    private onPublished: () => void;
    private locked: boolean = false;
    private initialized: boolean = false;

    public constructor(socket: ConfiguredSocket, board: Board, userId: UserId, username: Username, onHandledRemoteEvent: (event: BoardSync.BoardChanged, syncInitialized: boolean) => void, onPublished: () => void) {
        this.socket = socket;
        this.board = board;
        this.userId = userId;
        this.username = username;
        this.lastProcessedVersion = board.version.version;
        this.lastRemoteVersion = board.version.version;
        this.onHandledRemoteEvent = onHandledRemoteEvent;
        this.onPublished = onPublished;
        this.reconnectListener = (attempts?: number) => {
            console.log("[BoardSync] Socket reconnected");

            if(this.socket.connected) {
                this.restartSync();
            } else if (!attempts || attempts < 10) {
                console.log("[BoardSync] Socket not fully reconnected yet. Waiting another 100ms");
                window.setTimeout(() => {
                    if(!attempts) {
                        attempts = 1;
                    }

                    this.reconnectListener(attempts + 1);
                }, 100);
            } else {
                console.log("[BoardSync] Socket is not coming back. Trying to force a reconnect");
                this.socket.disconnect();
                this.socket.connect();
                window.setTimeout(() => {
                    this.reconnectListener();
                }, 1000);
            }
        }
    }

    public isInitialized(): boolean {
        return this.initialized;
    }

    public restartSync() {
        const token = getToken();
        this.socket.emit('BoardOpened', {
            boardId: this.board.uid,
            userId: this.userId,
            username: this.username,
            token,
        });
        this.loadAndProcessLatestPatchesFromServer();

        if(this.localQueue.length >= 2) {
            alert("Backend service is available again. Your changes will be saved now! Sorry for any inconvenient caused.");
        }

        this.processLocalQueue();
    }

    public startSync() {
        const init = () => {
            const token = getToken();
            console.log("[BoardSync] Socket connected")
            this.socket.emit('BoardOpened', {
                boardId: this.board.uid,
                userId: this.userId,
                username: this.username,
                token,
            });
        }

        this.socket.on('BoardChanged', (event: BoardSync.RawBoardChangedProps) => {
            this.handleBoardChanged(BoardSync.remoteBoardChanged(event));
        })

        this.socket.io.on('reconnect', this.reconnectListener);
        this.socket.on('error', () => console.error('[BoardSync] Socket error occurred'));

        if(this.socket.connected) {
            init();
        } else {
            console.log("[BoardSync] Awaiting socket connect")
            this.socket.once('connect', init);
        }

        this.loadAndProcessLatestPatchesFromServer();
    }

    public stopSync() {
        console.log("[BoardSync] Stopping sync");
        this.socket.off('BoardChanged');
        this.socket.io.off('reconnect', this.reconnectListener);
    }

    public currentVersion(): number {
        return this.lastProcessedVersion;
    }

    public publishChanges(event: BoardSync.BoardChanged, retry?: number) {

        if(this.locked && !retry) {
            console.log("[BoardSync] Sync is locked. Pushing event to local queue for later processing", event.toBoardVersion().version);
            this.localQueue.push(event);
            return;
        }

        if(this.socket.connected) {
            if(this.localQueue.length && !retry) {
                console.log("[BoardSync] Processing local queue before publishing new change")
                this.processLocalQueue();
                return;
            }

            if(!retry) {
                this.locked = true;

                window.setTimeout(() => {
                    this.locked = false;
                    this.processLocalQueue();
                }, 500);
            }

            const eventData = event.toJS();
            console.log("[BoardSync] Emitting new local changes", eventData);
            const token = getToken();
            this.socket.emit('BoardChanged', {...eventData, token}, (res: {success: boolean, boardVersion?: number, error?: any}) => {
                // @todo: handle negative ack received from server
                if(!res.success) {
                    console.error("[BoardSync] Local change was not saved by socket server. BE returned error: ", res.error)
                    if(retry !== 3) {
                        if(!retry) {
                            retry = 0;
                        }
                        retry++;
                        console.log("[BoardSync] Retrying event: ", eventData, " retry count: ", retry);
                        this.publishChanges(event, retry);
                    }
                } else {
                    if(res.boardVersion && res.boardVersion === (this.lastProcessedVersion + 1) || res.boardVersion === (this.lastRemoteVersion + 1)) {
                        this.lastProcessedVersion = res.boardVersion;
                        if(res.boardVersion === (this.lastRemoteVersion + 1)) {
                            this.lastRemoteVersion = res.boardVersion;
                            console.log("[BoardSync] Remote version is up to date with processed version.");
                        }
                        console.log("[BoardSync] Patch saved successfully. Last processed version set to: ", this.lastProcessedVersion);
                    } else if (res.boardVersion && res.boardVersion > this.lastProcessedVersion) {
                        this.lastProcessedVersion = res.boardVersion;
                        console.log("[BoardSync] Patch saved successfully, but remote version is behind: ", this.lastProcessedVersion, this.lastRemoteVersion);
                        console.log("[BoardSync] Only setting last processed version to keep gap detection active");
                    }
                    this.onPublished();
                }
            })
        } else {
            console.log("[BoardSync] Socket not connected. Pushing change to local queue");
            this.localQueue.push(event);

            if(this.localQueue.length === 2) {
                alert("Connection to server lost. A reload might fix the problem. Otherwise, you can work offline and backup your work at the end by making an export (see top menu). We try to reconnect you as soon as possible!")
            }
        }
    }

    private async loadAndProcessLatestPatchesFromServer(done?: () => void, err?: () => void) {
        this.isFetchingLatestPatches = true;
        console.log("[BoardSync] Going to fetch latest patches from server. Last remote version is: ", this.lastRemoteVersion);
        const {response, error}: ResponseType = await Api.fetchNewerPatches(this.board.uid, new BoardVersion({userId: this.userId, version: this.lastRemoteVersion}));
        this.isFetchingLatestPatches = false;

        if(response) {
            console.log("[BoardSync] Successfully loaded latest patches");
            const patches = map(response.data as any, (data: any) => marshalBoardChanged(data));

            // On init all patches should be processed, after init only remote patches
            patches.filter(patch => !this.initialized || patch.userId !== this.userId).forEach(patch => this.handleBoardChanged(patch, true));

            // If called from init function, we can now set initialized: true
            this.initialized = true;

            console.log("[BoardSync] Processing incomingQueue now");
            const prevEvents = [...this.incomingQueue];
            this.incomingQueue = [];
            prevEvents.forEach(prevEvent => this.handleBoardChanged(prevEvent, true));

            if(done) {
                done();
            }
        }

        if(error) {
            console.error("[BoardSync] Failed to load latest patches: ", error);

            if(err) {
                err();
            }

            this.initialized = true;
        }
    }

    private handleBoardChanged(event: BoardSync.BoardChanged, skipGapDetection?: boolean) {
        if(this.isFetchingLatestPatches) {
            console.log("[BoardSync] Fetching latest patches ATM. BoardChanged event is pushed to incomingQueue: ", event.toBoardVersion().version);
            this.incomingQueue.push(event);
            return;
        }

        if(this.lastRemoteVersion + 1 === event.toBoardVersion().version || (skipGapDetection && event.toBoardVersion().version > this.lastRemoteVersion)) {
            this.lastRemoteVersion = event.toBoardVersion().version;
            console.log("[BoardSync] Increased last remote version to: ", this.lastRemoteVersion);
            if(this.lastRemoteVersion > this.lastProcessedVersion) {
                console.log("[BoardSync] Last remote version is greater than last processed version. Setting remote version as processed version. ", this.lastRemoteVersion, this.lastProcessedVersion);
                this.lastProcessedVersion = this.lastRemoteVersion;
            }
        } else if (this.lastRemoteVersion + 1 < event.toBoardVersion().version) {
            // Gap detected
            console.log("[BoardSync] Gap detected. Last remote version is: ", this.lastRemoteVersion, " but received event with version: ", event.toBoardVersion().version);
            console.log("[BoardSync] Fetching latest patches from server first. Event is pushed to incoming queue");
            this.incomingQueue.push(event);
            this.loadAndProcessLatestPatchesFromServer();
        } else {
            // outdated event, ignore
            console.warn("[BoardSync] Skipped outdated event. Last remote version is: ", this.lastRemoteVersion, " but event has version: ", event.toBoardVersion().version);
            return;
        }

        this.onHandledRemoteEvent(event, this.initialized);
    }

    private processLocalQueue(done?: () => void) {
        console.log("[BoardSync] Processing local queue: ", this.localQueue.length);
        const queue = [...this.localQueue];
        this.localQueue = [];

        queue.forEach(event => {
            console.log("[BoardSync] Publishing event from local queue.", event.toBoardVersion().version);
            this.publishChanges(event);
        })

        if(done) {
            done();
        }
    }
}
