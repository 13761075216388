import {Map} from "immutable";
import React, {InputHTMLAttributes, MouseEvent} from 'react'
import {InjectedFormProps, WrappedFieldProps} from "redux-form";
import { Field, reduxForm } from 'redux-form/immutable';
import {Button, Form, Icon, Message, Segment} from "semantic-ui-react";
import {CONFIRM_BOARD_DELETION_FORM, EDIT_BOARD_FORM} from "./constants";

interface FormData  {
    uid: string;
    confirmedName: string;
}

export interface ImFormData extends Map<string, string> {
    confirmedName: string;
    toJS(): FormData;
}

export interface OwnProps {
    boardName: string;
    t: (val: string) => string;
    onCancel: () => void;
}

export interface PropsFromDispatch {

}


export type ConfirmBoardDeletionFormProps = OwnProps & PropsFromDispatch & InjectedFormProps<ImFormData, OwnProps>;

const renderConfirm = (componentProps: WrappedFieldProps & InputHTMLAttributes<HTMLInputElement>) => {
    const { input, placeholder, meta: { touched, error } } = componentProps;

    return <Form.Input {...input} placeholder={placeholder} error={(touched && error)? true : false}/>
};

const validateName = (boardName: string): (confirmedName: string) => undefined | string => confirmedName => confirmedName === boardName? undefined : 'Name is not correct';

const handleAbortClick = (e: MouseEvent<HTMLButtonElement>, props: ConfirmBoardDeletionFormProps) => {
    e.preventDefault();

    props.onCancel();
}

const ConfirmBoardDeletionForm = (props: ConfirmBoardDeletionFormProps) => <Message error={true}>
    <Icon name="warning sign" />
    {props.t('insp.board.really_delete')}
    <Segment>
        <form className="ui form" onSubmit={props.handleSubmit}>
            <div className="field">
                <Field name="confirmedName" type="text" component={renderConfirm} placeholder={props.t('insp.board.form.board_name')} />
            </div>
            <Button color="red" type="submit" disabled={props.pristine || !props.valid}>{props.t('app.form.confirm')}</Button>
            <Button basic={true} className="noborder" onClick={(e) => handleAbortClick(e, props)}>{ props.t('app.form.cancel') }</Button>
        </form>
    </Segment>
</Message>;

export default reduxForm<ImFormData, OwnProps>({
    form: CONFIRM_BOARD_DELETION_FORM,
    validate: (values, props) => {
        if(values) {
            const error = validateName(props.boardName)(values.get('confirmedName') || '');
            if(error) {
                return {confirmedName: error};
            }
        }

        return {};
    }
})(ConfirmBoardDeletionForm);

