import * as React from 'react';
import {Divider} from "semantic-ui-react";

interface OwnProps {
  content: string;
}

type EditorDividerProps = OwnProps;

const EditorDivider = (props: EditorDividerProps) => {
  return <Divider horizontal={true} content={props.content} section={true} />
};

export default EditorDivider;
