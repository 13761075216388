import {Node, NodeType} from "../../../model/Graph";
import {isJsonSchemaArray} from "../json-schema/is-json-schema-array";
import {isList} from "../schema/shorthand";
import {getVoMetadata} from "./get-vo-metadata";

export const isListVo = (node: Node): boolean => {
  if(node.getType() !== NodeType.document) {
    return false;
  }

  const meta = getVoMetadata(node);

  if(!meta.schema) {
    return false;
  }

  return meta.schema.isList();
}

export const isListForUICandidate = (node: Node): boolean => {
  if(!isListVo(node)) {
    return false;
  }

  const targets = node.getTargets();

  if(targets.count() === 0) {
    return true;
  }

  if(targets.count() > 1) {
    return false;
  }

  const target = targets.toArray()[0];

  return target && target.getType() === NodeType.ui;
}
