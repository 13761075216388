import {List} from "immutable";
import {action} from "typesafe-actions";
import {BoardModel} from "../../InspectioBoards/model";
import {Team} from "../model";
import {TeamInvitation} from "../model/TeamInvitation";
import {
    MEMBER_REMOVED_FROM_TEAM,
    TEAM_ACCESS_TO_BOARD_GRANTED,
    TEAM_ACCESS_TO_BOARD_REVOKED,
    TEAM_ADDED,
    TEAM_DELETED,
    TEAM_DESCRIPTION_CHANGED,
    TEAM_INVITATION_RESENT,
    TEAM_INVITATION_REVOKED,
    TEAM_INVITATIONS_LOADED, TEAM_MOVED_TO_ORGANIZATION,
    TEAM_RENAMED,
    TEAMS_FETCHED
} from "./constants";

export const teamAdded = (team: Team.Team) => action(TEAM_ADDED, team);
export const teamRenamed = (team: Team.Team) => action(TEAM_RENAMED, team);
export const teamDescriptionChanged = (team: Team.Team) => action(TEAM_DESCRIPTION_CHANGED, team);
export const memberRemovedFromTeam = (team: Team.Team) => action(MEMBER_REMOVED_FROM_TEAM, team);
export const teamDeleted = (team: Team.Team) => action(TEAM_DELETED, team);
export const teamInvitationsLoaded = (teamInvitations: List<TeamInvitation>) => action(TEAM_INVITATIONS_LOADED, teamInvitations);
export const teamInvitationResent = (teamInvitation: TeamInvitation) => action(TEAM_INVITATION_RESENT, teamInvitation);
export const teamInvitationRevoked = (teamInvitation: TeamInvitation) => action(TEAM_INVITATION_REVOKED, teamInvitation);

export const teamsFetched = (teams: List<Team.Team>) => action(TEAMS_FETCHED, teams);

export const teamAccessToBoardGranted = (boardId: BoardModel.BoardId, teamId: Team.TeamId) => action(TEAM_ACCESS_TO_BOARD_GRANTED, {boardId, teamId});
export const teamAccessToBoardRevoked = (boardId: BoardModel.BoardId, teamId: Team.TeamId) => action(TEAM_ACCESS_TO_BOARD_REVOKED, {boardId, teamId});

export const teamMovedToOrganization = (team: Team.Team) => action(TEAM_MOVED_TO_ORGANIZATION, team);
