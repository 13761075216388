import * as React from 'react';
import {PropsWithChildren, useEffect, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import {Button, Icon, List, Menu, MenuItem, Popup} from "semantic-ui-react";
import EditableTextline from "../../../core/components/EditableTextline";
import {useGraph} from "../../hooks/useGraph";
import {BoardId} from "../../model/Board";
import {Node, NodeType} from "../../model/Graph";
import {
    EDITABLE_LAYER_ADDITIONAL_CHAR_PADDING,
    EDITABLE_LAYER_CHAR_SIZE_UNIT,
    EDITABLE_LAYER_INPUT_CHAR_SIZE
} from "../BoardTreeView";
import LeafIcon from "../LeafIcon";
import MaxLengthLabel from "../MaxLengthLabel";

interface OwnProps {
    name: string;
    boardId: BoardId;
    expand: boolean;
    deletable: boolean;
    active: boolean;
    layer: Node;
    onLayerVisibilityChanged?: (visible: boolean) => void;
    onActiveLayerChanged?: () => void;
    copyToLayerSuccess?: boolean;
}

type BoardLayerProps = PropsWithChildren<OwnProps> & WithNamespaces;

const btnWidth = 20;
let dblClickTimer: any = null;

const BoardLayer = (props: BoardLayerProps) => {
    const [showChildren, setShowChildren] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [graph] = useGraph();
    // Force component rerender after click to read changes from graph
    const [clicks, setClicks] = useState(0);
    const [copyToLayerSuccess, setCopyToLayerSuccess] = useState<boolean|null>(null);
    const [nameEditMode, setNameEditMode] = useState(false);
    const [layerDeleteMode, setLayerDeleteMode] = useState(false);
    const dispatch = useDispatch();

    const visible =  graph.isVisible(props.layer);
    const locked = graph.isLocked(props.layer);

    useEffect(() => {
        setShowChildren(props.expand)
    }, [props.expand]);


    const handleLeafIconClick = (leafOpen: boolean, e: React.MouseEvent) => {
        if(null !== dblClickTimer) {
            window.clearTimeout(dblClickTimer);
            dblClickTimer = null;
            if(visible) {
                graph.highlightLayer(props.layer);
                setClicks(clicks + 1);
            }
            return;
        }

        dblClickTimer = window.setTimeout(() => {
            if(!leafOpen && !graph.isActiveLayer(props.layer)) {
                graph.setAsActiveLayer(props.layer);

                if(props.onActiveLayerChanged) {
                    props.onActiveLayerChanged();
                }
            }

            if(leafOpen && !props.layer.isDefaultLayer()) {
                graph.setDefaultLayerAsActive();
                if(props.onActiveLayerChanged) {
                    props.onActiveLayerChanged();
                }
            }

            setShowChildren(!leafOpen);
            if(showSettings) {
                setShowSettings(false);
            }
            dblClickTimer = null;
        }, 200);

        e.preventDefault();
    };

    const handleItemContextMenu = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setShowSettings(true);
    }

    const handleLockClick = () => {
        graph.setLocked(props.layer, !graph.isLocked(props.layer));
        setClicks(clicks + 1);
    }

    const handleVisibleClick = () => {
        const willBeVisible = !graph.isVisible(props.layer);
        graph.setUserVisible(props.layer, willBeVisible);
        if(props.onLayerVisibilityChanged) {
            props.onLayerVisibilityChanged(willBeVisible);
        }
        setClicks(clicks + 1);
    }

    const handleEditNameClick = () => {
        setNameEditMode(true);
    }

    const handleCancelEditLayer = () => {
        setNameEditMode(false);
        setShowSettings(false);
    }

    const handleEditLayer = (newLayerName: string) => {
        graph.changeNodeName(props.layer, newLayerName);
        setNameEditMode(false);
        setShowSettings(false);
    }

    const handleDeleteLayerClick = (e: React.MouseEvent) => {
        setLayerDeleteMode(true);
    }

    const handleAbortDeleteLayer = () => {
        setLayerDeleteMode(false);
        setShowSettings(false);
    }

    const handleConfirmDeleteLayer = () => {
        graph.deleteLayer(props.layer);
        setLayerDeleteMode(false);
    }

    const visibleIcon = visible ? 'eye' : 'eye slash';
    const lockIcon = locked? 'lock' : 'lock open';
    const showCopySuccess = props.copyToLayerSuccess? props.copyToLayerSuccess : copyToLayerSuccess;

    return <>
        {!nameEditMode && <List.Item style={{padding: "5px", background: 'rgba(0,0,0,0.05)'}}
                      as={ NavLink }
                      to="#"
                      isActive={() => false}
                      onClick={(e) => {handleLeafIconClick(showChildren, e)}}
                      onContextMenu={handleItemContextMenu}
                      className={visible? '' : 'hidden'}
        >
            <LeafIcon type={NodeType.layer}
                      open={showChildren}
                      title={props.t('insp.board.layers.folder_title') as string}
                      className={(props.active? 'active-layer' : 'layer') + (showCopySuccess !== null? (showCopySuccess? ' copy-success' : ' copy-failed') : '')} />
            {locked && <Icon name={lockIcon} className="corner left" size="mini" fitted={true}/>}
            {!visible && <Icon name={visibleIcon} className="corner right" size="mini" fitted={true}/>}
            <List.Content>
                <Popup
                    position='bottom center'
                    closeOnTriggerBlur={false}
                    closeOnTriggerMouseLeave={false}
                    hideOnScroll={true}
                    open={showSettings}
                    onClose={() => setShowSettings(false)}
                    style={{padding: "0px"}}
                    trigger={
                        <div>
                            <MaxLengthLabel label={props.name} maxLength={50} />
                        </div>
                    }
                    content={
                        <>
                        {!layerDeleteMode && <Menu secondary={true} compact={true}>
                            <MenuItem
                                title={props.t('insp.board.layers.edit_name') as string}
                                onClick={e => {
                                e.stopPropagation();
                                handleEditNameClick();
                            }}>
                                <Icon name="edit" />
                            </MenuItem>
                            <MenuItem
                                title={props.t('insp.board.layers.lock_layer') as string}
                                onClick={e => {
                                e.stopPropagation();
                                handleLockClick();
                            }}>
                               <Icon name={lockIcon} />
                            </MenuItem>
                            <MenuItem
                                title={props.t('insp.board.layers.visibility') as string}
                                onClick={e => {
                                e.stopPropagation();
                                handleVisibleClick();
                            }}>
                                <Icon name={visibleIcon} />
                            </MenuItem>
                            <MenuItem className="delete-ele" onClick={e => {
                                e.stopPropagation();
                                handleDeleteLayerClick(e);
                            }} title={props.t('insp.board.layers.delete_layer') as string} disabled={!props.deletable}>
                                <Icon name="trash" />
                            </MenuItem>
                        </Menu>}
                        {layerDeleteMode && <Menu secondary={true} compact={true}>
                            <MenuItem>
                                {props.t('insp.board.layers.delete_confirmation')}
                            </MenuItem>
                            <MenuItem
                                onClick={e => {
                                    e.stopPropagation();
                                    handleConfirmDeleteLayer();
                                }}
                            >
                                {props.t('app.form.yes')}
                            </MenuItem>
                            <MenuItem
                                onClick={e => {
                                    e.stopPropagation();
                                    handleAbortDeleteLayer();
                                }}
                            >
                                {props.t('app.form.no')}
                            </MenuItem>
                        </Menu>}
                        </>
                    }
                />
                {showChildren && props.children}
            </List.Content>
        </List.Item>
        }
        {nameEditMode &&
        <List.Item className="add-layer">
            <Button icon={true} basic={true} size="medium">
                <Icon name="folder outline" />
            </Button>
            <EditableTextline
                text={props.layer.getName()}
                charSize={EDITABLE_LAYER_INPUT_CHAR_SIZE}
                widthUnit={EDITABLE_LAYER_CHAR_SIZE_UNIT}
                padding={EDITABLE_LAYER_ADDITIONAL_CHAR_PADDING}
                minWidth={120}
                required={true}
                onTextChanged={handleEditLayer}
                focus={true}
                onCancel={handleCancelEditLayer}
                placeholder={'Board'}
            />
        </List.Item>
        }
    </>;
};

export default withNamespaces()(BoardLayer);
