import {Record} from 'immutable';
import * as uuid from 'uuid';
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";

export type GraphElementId = string;
export type GraphElementName = string;
export type GraphElementParentId = string|null;
export type GraphElementChildren = string[];
export type GraphElementSources = string[];
export type GraphElementTargets = string[];

export enum GraphElementType {
    event = 'event'
}

export type BoardGraphElementValidationError = string;

export const AGGREGATE_TYPE = 'BoardGraphElement';

export interface BoardGraphElementProps {
    uid: GraphElementId;
    aggregateType: AggregateType;
    name: GraphElementName
    parentId: GraphElementParentId;
    type: GraphElementType;
    children: GraphElementChildren|undefined;
    sources: GraphElementSources|undefined;
    targets: GraphElementTargets|undefined;
}

export const defaultBoardGraphElementProps: BoardGraphElementProps = {
    uid: '',
    aggregateType: AGGREGATE_TYPE,
    name: '',
    parentId: null,
    type: GraphElementType.event,
    children: undefined,
    sources: undefined,
    targets: undefined,
};

export const createBoardGraphElementFromServerData = (data: any): Either<BoardGraphElementValidationError, BoardGraphElement> => {
    const validatedData: Either<string, any> = validate(data,
        [hasKey('id'), "id missing"],
        [hasKey('name'), "name missing in " + data.id],
        [hasKey('parentId'), "parentId missing in " + data.id],
        [hasKey('type'), "type missing in " + data.id]
    );

    if (isFailure(validatedData)) {
        return validatedData;
    }

    return Success(new BoardGraphElement({
        uid: data.id,
        aggregateType: AGGREGATE_TYPE,
        name: data.name,
        parentId: data.parentId,
        type: data.type,
        sources: data.sources || undefined,
        targets: data.targets || undefined,
        children: data.children || undefined
    }));
};

export class BoardGraphElement extends Record(defaultBoardGraphElementProps) implements BoardGraphElementProps, Aggregate {
    public constructor(data: BoardGraphElementProps) {
        if(data.uid === '') {
            data.uid = uuid.v4()
        }

        super(data);
    }
}
