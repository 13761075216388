import * as React from 'react';
import {useEffect, useMemo, useRef, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import {Button, ButtonGroup, Icon, Input, Modal} from "semantic-ui-react";
import {makeGetSidebarSelector} from "../../../Layout/selectors/sidebar";
import {PublicSounds} from "../../../routes";
import {makeGetCurrentUser} from "../../../User/selectors/currentUser";
import {toggleTimerModal} from "../../actions/commands";
import {useMouseSync} from "../../hooks/useMouseSync";
import {BoardId} from "../../model/Board";
import {BoardTimer as BoardTimerAR, createBoardTimer} from "../../model/BoardTimer";
import {makeBoardByIdSelector} from "../../selectors/board";
import {UserJoinedListener, UserLeftListener} from "../../service/RemoteMouseSync";

interface BoardTimerRouteMatchParams {
    uid: BoardId;
}

interface OwnProps {

}

const convertToTimeNumber = (val: string, max: number, emptyZeros: boolean = false): string => {
    if (val.length === 1) {
        val = '0'+val;
    }

    if(val.length === 3) {
        val = val.slice(1);
    }

    if (parseInt(val, undefined) === 0) {
        val = emptyZeros? '00' : '';
    }

    if(parseInt(val, undefined) > max) {
        val = `${max}`;
    }

    return val;
}

const convertDurationToHms = (d: number): [number, number, number] => {
    d = Math.floor(Number(d) / 1000);

    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    if(h < 0) {
        h = 0;
    }

    if(m < 0) {
        m = 0;
    }

    if(s < 0) {
        s = 0;
    }

    return [h, m, s];
}

const convertHmsToDuration = (h: string, m: string, s: string): number => {
    const hoursMillis = parseInt(h === ''? '0' : h, undefined) * 60 * 60 * 1000;
    const minutesMillis = parseInt(m === ''? '0' : m, undefined) * 60 * 1000;
    const secondsMillis = parseInt(s === ''? '0' : s, undefined) * 1000;

    return hoursMillis + minutesMillis + secondsMillis;
}

const calculateCurrentTime = (
    deadline: number,
    duration: number,
    hourglass: HourglassState,
    setHourglass: (hourglass: HourglassState) => void,
    setCurrentTime: (time: TimeTuple) => void,
    stop: () => void
) => {
    let timerHours = 0;
    let timerMinutes = 0;
    let timerSeconds = 0;
    let timeLeft = 0;

    timeLeft = deadline - Date.now();

    const leftPercentage = Math.floor(timeLeft * 100 / duration);

    if(leftPercentage > 50 && hourglass !== 'start') {
        setHourglass('start');
    }

    if(leftPercentage <= 50 && leftPercentage > 5 && hourglass !== 'half') {
        setHourglass('half');
    }

    if(leftPercentage <= 5 && hourglass !== 'end') {
        setHourglass('end');
    }

    if(timeLeft > 0) {
        [timerHours, timerMinutes, timerSeconds] = convertDurationToHms(timeLeft);
    }

    if (deadline > 0 && timeLeft < -10000) {
        stop();
    }

    setCurrentTime([timerHours, timerMinutes, timerSeconds]);
}

type HourglassState = 'start' | 'half' | 'end';
type TimeTuple = [number, number, number];

let intervalRef: number|null = null;

type BoardTimerProps = OwnProps & RouteComponentProps<BoardTimerRouteMatchParams> & WithNamespaces;

const BoardTimer = (props: BoardTimerProps) => {
    const dispatch = useDispatch();
    const minRef = useRef<Input>(null);
    const sidebar = useSelector(makeGetSidebarSelector());
    const getBoardByIdSelector = useMemo(() => makeBoardByIdSelector(props.match.params.uid), []);
    const board = useSelector(getBoardByIdSelector);
    const visible = board && board.shouldShowTimerModal;
    const user = useSelector(makeGetCurrentUser());
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState<string>('');
    const [seconds, setSeconds] = useState('');
    const [deadline, setDeadline] = useState(0);
    const [duration, setDuration] = useState(0);
    const [moderator, setModerator] = useState<string>();
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState<TimeTuple>([0,0,0]);
    const [pausedTime, setPausedTime] = useState<TimeTuple>();
    const [hourglass, setHourglass] = useState<HourglassState>('start');
    const [moderatorHasLeft, setModeratorHasLeft] = useState(false);
    const [gongPlayed, setGongPlayed] = useState(true);
    const [mouseSync, ] = useMouseSync();

    useEffect(() => {
        if(visible) {
            window.setTimeout(() => {
                if(minRef && minRef.current) {
                    minRef.current.focus();
                }
            }, 200);
        }
    }, [visible])

    const handleHoursChanged = (hoursVal: string) => {
        setHours(convertToTimeNumber(hoursVal, 23));
    }

    const handleMinutesChanged = (minutesVal: string) => {
        setMinutes(convertToTimeNumber(minutesVal, 59));
    }

    const handleSecondsChanged = (secondsVal: string) => {
        setSeconds(convertToTimeNumber(secondsVal, 59));
    }

    const handleClose = () => {
        dispatch(toggleTimerModal(props.match.params.uid, false));
    }

    const play = (ignorePausedTime: boolean = false) => {
        let hoursMillis = parseInt(hours === ''? '0' : hours, undefined) * 60 * 60 * 1000;
        let minutesMillis = parseInt(minutes === ''? '0' : minutes, undefined) * 60 * 1000;
        let secondsMillis = parseInt(seconds === ''? '0' : seconds, undefined) * 1000;

        if(pausedTime && !ignorePausedTime) {
            [hoursMillis, minutesMillis, secondsMillis] = pausedTime;
            hoursMillis = hoursMillis * 60 * 60 * 1000;
            minutesMillis = minutesMillis * 60 * 1000;
            secondsMillis = secondsMillis * 1000;
        }

        const calculatedDuration = hoursMillis + minutesMillis + secondsMillis;

        if(!pausedTime || ignorePausedTime) {
            setDuration(calculatedDuration);
        } else {
            setPausedTime(undefined);
        }

        const newDeadline = Date.now() + calculatedDuration;

        setDeadline(newDeadline);
        setIsPlaying(true);
        setCurrentTime(convertDurationToHms(calculatedDuration));
        setModerator(user.uid);
        setGongPlayed(false);
        mouseSync.startBoardTimer(createBoardTimer(
            props.match.params.uid,
            newDeadline,
            convertHmsToDuration(hours, minutes, seconds),
            user.uid,
        ))
        dispatch(toggleTimerModal(props.match.params.uid, false))
        calculateCurrentTime(
            newDeadline,
            calculatedDuration,
            hourglass,
            setHourglass,
            setCurrentTime,
            stop
        );
    }

    const pause = (triggerMouseSync: boolean = true) => {
        setIsPlaying(false);
        setPausedTime([currentHours, currentMinutes, currentSeconds]);
        if(triggerMouseSync) {
            mouseSync.pauseBoardTimer(createBoardTimer(
                props.match.params.uid,
                deadline,
                convertHmsToDuration(hours, minutes, seconds),
                user.uid,
                [currentHours, currentMinutes, currentSeconds]
            ))
        }

    }

    const deadlineReached = () => {
        const gong = new Audio(PublicSounds + '/gong.wav');
        gong.play();
        setGongPlayed(true);
    }

    const stop = (triggerMouseSync: boolean = true) => {
        setDuration(0);
        setDeadline(0);
        setIsPlaying(false);
        setPausedTime(undefined);
        setModerator(undefined);
        setHourglass('start');
        setGongPlayed(true);
        if(triggerMouseSync) {
            mouseSync.stopBoardTimer(createBoardTimer(
                props.match.params.uid,
                0,
                0,
                ''
            ))
        }
    }

    const reset = () => {
        setPausedTime(undefined);
        setIsPlaying(false);
        play(true);
    }

    const handleRemoteTimerStarted = (timer: BoardTimerAR) => {
        setDeadline(timer.deadline);
        setDuration(timer.duration);
        setModerator(timer.moderator);
        setIsPlaying(true);
        setGongPlayed(false);
        calculateCurrentTime(
            timer.deadline,
            timer.duration,
            hourglass,
            setHourglass,
            setCurrentTime,
            stop
        );

        const [remoteHours, remoteMinutes, remoteSeconds] = convertDurationToHms(timer.duration);

        console.log("remote time started: ", timer.toJSON());

        setHours(convertToTimeNumber(remoteHours.toString(), 23));
        setMinutes(convertToTimeNumber(remoteMinutes.toString(), 59));
        setSeconds(convertToTimeNumber(remoteSeconds.toString(), 59));
    }

    const handleRemoteTimerStopped = (timer: BoardTimerAR) => {
        console.log("Received remote timer stopped: ", timer.toJSON());
        stop(false);
    }

    const handleRemoteTimerPaused = (timer: BoardTimerAR) => {
        setIsPlaying(false);

        console.log("remote paused time: ", timer.toJSON());

        if(timer.pausedTime) {
            setCurrentTime(timer.pausedTime);
            setPausedTime(timer.pausedTime);
        }
    }

    const handleRemoteUserJoined: UserJoinedListener = (userId: string, name: string, avatar: string, color: string) => {
        if(userId === moderator) {
            setModeratorHasLeft(false);
        }
    }

    const handleRemoteUserLeft: UserLeftListener = (userId: string) => {
        if(userId === moderator) {
            setModeratorHasLeft(true);
        }
    }

    useEffect(() => {
        if(isPlaying) {
            intervalRef = window.setInterval(() => {
                calculateCurrentTime(
                    deadline,
                    duration,
                    hourglass,
                    setHourglass,
                    setCurrentTime,
                    stop
                );
            }, 1000);

            calculateCurrentTime(
                deadline,
                duration,
                hourglass,
                setHourglass,
                setCurrentTime,
                stop
            );
        }

        return () => {
            if(intervalRef) {
                clearInterval(intervalRef);
            }
        }
    }, [isPlaying, hourglass, duration, deadline])

    useEffect(() => {
        console.log("attach listeners");
        mouseSync.onBoardTimerStarted(handleRemoteTimerStarted);
        mouseSync.onBoardTimerStopped(handleRemoteTimerStopped);
        mouseSync.onBoardTimerPaused(handleRemoteTimerPaused);
        mouseSync.onUserJoined(handleRemoteUserJoined);
        mouseSync.onUserLeft(handleRemoteUserLeft);

        return () => {
            console.log("detach listeners");
            mouseSync.offBoardTimerStarted(handleRemoteTimerStarted);
            mouseSync.offBoardTimerPaused(handleRemoteTimerPaused);
            mouseSync.offBoardTimerStopped(handleRemoteTimerStopped);
            mouseSync.offUserJoined(handleRemoteUserJoined);
            mouseSync.offUserLeft(handleRemoteUserLeft);
        }
    }, [props.match.params.uid, hours, minutes, seconds, moderator])

    useEffect(() => {
        stop();
    }, [props.match.params.uid])

    const [currentHours, currentMinutes, currentSeconds] = currentTime;
    const isTimeLeft = (currentHours + currentMinutes + currentSeconds) > 0;
    const isModerator = moderator === user.uid;
    const canModerate = !moderator || isModerator || moderatorHasLeft;

    if(!isTimeLeft && !gongPlayed) {
        deadlineReached();
    }

    return (<>
        {deadline > 0 && <div style={{
            position: "absolute",
            left: sidebar.width + 20 + "px",
            top: "60px",
            backgroundColor: 'rgb(249 251 253 / 52%)',
            cursor: 'pointer'
        }}
              onClick={() => dispatch(toggleTimerModal(props.match.params.uid, true))}
        >
            <p style={{padding: '20px', fontWeight: 'bold', fontSize: '20px', color: isTimeLeft? '#939393' : '#c10303'}} className={isTimeLeft? '' : 'blink'}>
                <Icon name={'hourglass ' + (isTimeLeft? hourglass : 'end') as any}/>&nbsp;&nbsp;
                {convertToTimeNumber(currentHours.toString(), 23, true)}&nbsp;:&nbsp;
                {convertToTimeNumber(currentMinutes.toString(), 59, true)}&nbsp;:&nbsp;
                {convertToTimeNumber(currentSeconds.toString(), 59, true)}
            </p>
        </div>}
        <Modal open={visible} closeIcon={true} onClose={handleClose} size="tiny">
            <Modal.Header>{props.t('insp.board.timer.modal_header')}</Modal.Header>
            <Modal.Content>
                <p style={{textAlign: "center", fontWeight: "bold", fontSize: "24px"}}>
                    <Input name="hours"
                           transparent={true}
                           type="number"
                           min={0}
                           max={23}
                           placeholder="00"
                           size="large"
                           style={{width: "55px"}}
                           value={deadline > 0? convertToTimeNumber(currentHours.toString(), 23) : hours}
                           onKeyDown={(e: KeyboardEvent) => e.key === 'Enter'? play() : true}
                           onChange={(e, data) => handleHoursChanged(data.value)}
                           disabled={deadline > 0}
                    />
                    <span style={{paddingRight: "20px"}}>&nbsp;:&nbsp;</span>
                    <Input name="minutes"
                           ref={minRef}
                           transparent={true}
                           type="number"
                           min={0}
                           max={59}
                           placeholder="00"
                           size="large"
                           style={{width: "55px"}}
                           value={deadline > 0? convertToTimeNumber(currentMinutes.toString(), 59) : minutes}
                           onKeyDown={(e: KeyboardEvent) => e.key === 'Enter' ? play() : true}
                           onChange={(e, data) => handleMinutesChanged(data.value)}
                           disabled={deadline > 0}
                    />
                    <span style={{paddingRight: "20px"}}>&nbsp;:&nbsp;</span>
                    <Input name="seconds"
                           transparent={true}
                           type="number"
                           min={0}
                           max={59}
                           placeholder="00"
                           size="large"
                           style={{width: "55px"}}
                           value={deadline > 0? convertToTimeNumber(currentSeconds.toString(), 59) : seconds}
                           onKeyDown={(e: KeyboardEvent) => e.key === 'Enter'? play() : true}
                           onChange={(e, data) => handleSecondsChanged(data.value)}
                           disabled={deadline > 0}
                    />
                </p>
            </Modal.Content>
            <Modal.Actions style={{textAlign: "center"}}>
                <ButtonGroup>
                    {!isPlaying && <Button icon="play" onClick={() => play()} disabled={!canModerate}/>}
                    {isPlaying && <Button icon="pause" onClick={() => pause()} disabled={!canModerate}/>}
                    <Button icon="stop" onClick={() => stop()} disabled={!isPlaying || !canModerate} />
                    <Button icon="undo" onClick={() => reset()} disabled={deadline === 0 || !canModerate} />
                </ButtonGroup>
            </Modal.Actions>
        </Modal>
    </>);
};

export default withRouter(withNamespaces()(BoardTimer));
