import { call, fork, put, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as Action from '../actions';

type FlowAction = ActionType<typeof Action.Command.changeBoardPermissions>;

const onChangeBoardPermissions = function* (action: FlowAction) {
    const {response, error}: ResponseType = yield call(
        Action.Api.changeBoardPermissions,
        action.payload.boardId,
        action.payload.userId,
        action.payload.permissions
    );

    if(error) {
        yield put(NotifyAction.Command.error('Request Error', 'Failed changing the board permissions.'));
    }

    if(response) {
        yield put(NotifyAction.Command.info(
            'Permissions Changed',
            'Board permissions were changed successfully.'
        ));

        yield put(Action.Event.boardPermissionsChanged(
            action.payload.boardId,
            action.payload.userId,
            action.payload.permissions)
        );
    }
};

export function* changeBoardPermissionsFlow() {

    while (true) {
        const action: FlowAction = yield take([
            Action.Type.CHANGE_BOARD_PERMISSIONS
        ]);

        yield fork(onChangeBoardPermissions, action);
    }
}