import * as React from 'react';
import {useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Icon, MenuItem, Popup} from "semantic-ui-react";
import * as Routes from "../../routes";
import {upsertUserSetting} from "../../User/actions/commands";
import {makeUserSettingSelector, makeUserSettingsLoadedSelector} from "../../User/selectors/userSettings";

interface OwnProps {
    collapsed: boolean;
}

type LogoProps = OwnProps & WithNamespaces;

const Logo = (props: LogoProps) => {

    const dispatch = useDispatch();
    const [popupOpen, setPopupOpen] = useState(false);
    const [logoLoaded, setLogoLoaded] = useState(false);
    const settingsLoaded = useSelector(makeUserSettingsLoadedSelector());
    const showHint = useSelector(makeUserSettingSelector('show_logo_hint', false));


    useEffect(() => {
        if(settingsLoaded && logoLoaded && showHint) {
            setPopupOpen(true);
        }
    }, [settingsLoaded, logoLoaded, showHint])

    const handleHintClick = () => {
        setPopupOpen(false);
        dispatch(upsertUserSetting('show_logo_hint', false));
    }

    const handleLogoLoaded = () => {
        setLogoLoaded(true);
    }

    return <Popup
                open={popupOpen}
                position="bottom center"
                content={<>
                    <p><strong>{props.t('insp.hint.new_logo_header')}</strong></p>
                    <p>{props.t('insp.hint.new_logo_text')}!</p>
                    <p><Icon name="arrow right"/>&nbsp;<a href="https://github.com/event-engine/inspectio/wiki/Rebranding" target="_blank">{props.t('insp.hint.learn_more')}</a></p>
                    <p><span style={{"color": "#9cc8f5", "cursor": "pointer", float: "right"}} onClick={handleHintClick}>{props.t('insp.hint.got_it')}</span></p>
                </>}
                trigger={
                    <MenuItem className="logo-item">
                        <Link to={Routes.inspectio}>
                            <img className="logo" src="img/prooph-board-logo.png" alt="prooph board logo"  onLoad={handleLogoLoaded} />
                        </Link>
                    </MenuItem>
                }
            />
};

export default withNamespaces()(Logo);
