import * as React from 'react';
import {useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Icon} from 'semantic-ui-react';
import AddBoardModal from "./AddBoardModal";

export interface AddBoardCardProps {
    disabled: boolean;
}

type EnhancedProps = AddBoardCardProps & WithNamespaces;

const AddBoardCard = (props: EnhancedProps) => {
    const [addBoard, setAddBoard] = useState(false);

    return  <>
        <a href="#" className="ui card event add board" onClick={e => {e.preventDefault(); setAddBoard(true)}}>
            <div className="content" style={{display: "flex", flexDirection: "column", verticalAlign: "middle",
                textAlign: "center", justifyContent: "center", alignItems: "center"}}>
                <div><button className="ui big circular icon button">
                    <Icon name="plus" size="large" />
                </button></div>
                <button className="ui big basic button noborder">
                { props.t('insp.overview.add_board') }
                </button>
            </div>
        </a>
        <AddBoardModal open={addBoard} onClose={() => {setAddBoard(false)}} disabled={props.disabled} />
    </>;
};

export default withNamespaces()(AddBoardCard);
