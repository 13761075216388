import {fork, put,take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import * as SidebarAction from "../../Layout/actions";
import * as Action from "../actions";
import {BoardModel} from "../model";
import {Graph} from "../model/Graph";

type FlowAction = ActionType<typeof Action.Command.saveBoard
    & typeof Action.Event.localBoardUpdatedWithRemoteChange
    & typeof Action.Event.boardImported
    & typeof Action.Event.boardInitialized>;

type SidebarToggleAction = ActionType<typeof SidebarAction.Command.toggleSidebarCollapsed>;

function* onSaveBoard(boardId: BoardModel.BoardId, graph: Graph) {
    yield put(Action.Command.updateBoardElementsTree(boardId, graph.getRoot().children()));
}

export function* syncElementsTreeFlow() {

    let sidebarCollapsed = false;
    let latestGraph: Graph;
    let  latestBoardId: BoardModel.BoardId;
    let initialized = false;

    while (true) {
        const action: FlowAction & SidebarToggleAction = yield take([
            Action.Type.SAVE_BOARD,
            Action.Type.LOCAL_BOARD_UPDATED_WITH_REMOTE_CHANGE,
            Action.Type.BOARD_IMPORTED,
            Action.Type.BOARD_INITIALIZED,
            SidebarAction.Type.TOGGLE_SIDEBAR_COLLAPSED,
        ]);

        if(initialized && action.type === SidebarAction.Type.TOGGLE_SIDEBAR_COLLAPSED) {
            sidebarCollapsed = action.payload.collapsed;

            if(!sidebarCollapsed && latestGraph! && latestBoardId!) {
                yield fork(onSaveBoard, latestBoardId, latestGraph);
            }
        }

        switch (action.type) {
            case Action.Type.SAVE_BOARD:
            case Action.Type.LOCAL_BOARD_UPDATED_WITH_REMOTE_CHANGE:
            case Action.Type.BOARD_IMPORTED:
            case Action.Type.BOARD_INITIALIZED:
                if(initialized && latestBoardId !== action.payload.board.uid) {
                    initialized = false;
                }

                latestGraph = action.payload.graph;
                latestBoardId = action.payload.board.uid;

                if(!sidebarCollapsed) {
                    initialized = true;
                    yield fork(onSaveBoard, latestBoardId, latestGraph);
                }
            break;
        }
    }
}
