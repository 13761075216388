import * as React from 'react';
import {FormEvent, useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useElementsTree} from "../../../hooks/useElementsTree";
import {useGraph} from "../../../hooks/useGraph";
import {convertNodeToJs, makeNodeFromJs, NextToPosition, Node, NodeMetadata} from "../../../model/Graph";
import {CommandMetadata, getCommandMetadata} from "../../../service/cody-wizard/command/get-command-metadata";
import {ImmutablePolicyContext} from "../../../service/cody-wizard/context/policy-context";
import {Schema} from "../../../service/cody-wizard/schema/schema";
import EditorDivider from "../Editor/EditorDivider";
import SchemaEditor from "../Editor/SchemaEditor";
import {WithWizardStepProps} from "../WizardModal";
import {cloneDeep} from "lodash";

interface OwnProps {
  command: Node
}

type PolicyCommandProps = OwnProps & WithWizardStepProps<ImmutablePolicyContext> & WithNamespaces;

const PolicyCommand = (props: PolicyCommandProps) => {
  const [graph] = useGraph();
  const elementsTree = useElementsTree(props.ctx.board.uid);
  const [selectedCommand, setSelectedCommand] = useState<Node|null>(null);
  const [metadata, setMetadata] = useState<CommandMetadata>({aggregateCommand: false});
  const [autoFocusSchema, setAutoFocusSchema] = useState(false);

  const updateMetadata = (meta: CommandMetadata) => {
    if(props.onUpdateMetadata) {
      props.onUpdateMetadata(JSON.stringify(meta, null, 2), true);
    }

    setMetadata(meta);
  }

  useEffect(() => {
    resetForm();

    if(props.command) {
      setSelectedCommand(props.command);
      const commandMeta = getCommandMetadata(props.command);


      setMetadata(commandMeta);
    }
  }, [props.command])

  const resetForm = () => {
    setSelectedCommand(null);
    setMetadata({aggregateCommand: false});
    setAutoFocusSchema(false);
  }

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  const handleSchemaChanged = (newSchema: Schema | undefined) => {
    if(!newSchema) {
      const clonedMeta = cloneDeep(metadata);
      delete clonedMeta.schema;
      updateMetadata(clonedMeta);
      return;
    }

    updateMetadata({...metadata, schema: newSchema});
  }

  return <form className='ui form' onSubmit={handleSubmit}>
    <EditorDivider content="Schema" />
    <SchemaEditor boardId={props.ctx.board.uid}
                  sessionId={ selectedCommand? selectedCommand.getId() : undefined }
                  schema={metadata.schema || Schema.fromString('{}')}
                  readonly={!!(selectedCommand && !selectedCommand.isEnabled())}
                  onSchemaChanged={handleSchemaChanged} autofocus={autoFocusSchema}
    />
    <div style={{marginTop: '100px'}} />
  </form>
};

export default withNamespaces()(PolicyCommand);
