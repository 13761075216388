import {Record} from 'immutable';
import * as uuid from "uuid";
import {Aggregate, AggregateType} from "../../core/model/Aggregate";
import {Either, isFailure, Success, validate} from "../../core/validation/either";
import {hasKey} from "../../core/validation/predicates";
import {UserId} from "../../User/model/UserInfo";

export const AGGREGATE_TYPE = 'PlayshotSummary';

export type PlayshotId = string;
export type PlayshotSummaryError = string;

export interface PlayshotSummaryProps {
  uid: PlayshotId;
  aggregateType: AggregateType;
  boardId: string;
  name: string;
  savedAt: string;
  savedBy: UserId;
}

export const defaultPlayshotSummaryProps: PlayshotSummaryProps = {
  uid: '',
  aggregateType: AGGREGATE_TYPE,
  boardId: '',
  name: '',
  savedAt: '',
  savedBy: '',
};

export const createPlayshotSummaryFromServerData = (data: any): Either<PlayshotSummaryError, PlayshotSummary> => {
  const validatedData: Either<PlayshotSummaryError, any> = validate(data,
    [hasKey('playshotId'), "playshotId missing"],
    [hasKey('boardId'), "boardId missing"],
    [hasKey('name'), "name missing"],
    [hasKey('savedAt'), "savedAt missing"],
    [hasKey('savedBy'), "savedBy missing"],
  );

  if (isFailure(validatedData)) {
    return validatedData;
  }

  return Success(new PlayshotSummary({
    ...defaultPlayshotSummaryProps,
    ...data,
    uid: data.playshotId,
  }));
};

export class PlayshotSummary extends Record(defaultPlayshotSummaryProps) implements PlayshotSummaryProps, Aggregate {
  public constructor(data: PlayshotSummaryProps) {
    if (data.uid === '') {
      data.uid = uuid.v4()
    }

    super(data);
  }

  // @TODO add use case methods to change state
}
