import {List} from "immutable";
import { call, fork, put, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {ResponseType} from "../../api/util";
import {execForEach, execValidate, isFailure, isSuccess, pipe} from "../../core/validation/either";
import {isArray} from "../../core/validation/predicates";
import { Action as TeamAction } from '../../InspectioTeams';
import { Action as NotifyAction } from '../../NotificationSystem';
import * as Action from '../actions';
import {FETCH_BOARD_PERMISSIONS_FOR_TEAMS, FETCH_BOARD_PERMISSIONS_FOR_USERS} from "../actions/constants";
import {BoardId} from "../model/Board";
import {createBoardPermissionForTeamFromServerData} from "../model/BoardPermissionForTeam";
import {createBoardPermissionForUserFromServerData} from "../model/BoardPermissionForUser";

type FlowAction = ActionType<typeof Action.Query.fetchBoardPermissionsForTeams>
    | ActionType<typeof Action.Query.fetchBoardPermissionsForUsers>
    | ActionType<typeof TeamAction.Event.teamAccessToBoardGranted>
    | ActionType<typeof TeamAction.Event.teamAccessToBoardRevoked>;

const onFetchBoardPermissionsForUsers = function* (boardId: BoardId) {
    const {response, error}: ResponseType = yield call(Action.Api.fetchBoardPermissionsForUsers, boardId);

    if (error) {
        yield put(NotifyAction.Command.error("Request Error", "Failed to load board permissions"));
    }

    if (response) {

        const result = pipe(
            response!.data,
            execValidate([isArray(), "Response data is not of type Array"]),
            execForEach((data: any) => {
                return createBoardPermissionForUserFromServerData(data);
            }),
        );

        if (isFailure(result)) {
            // tslint:disable-next-line:no-console
            console.error("Failed to process board permissions response from server", result.value, response.data);
            yield put(NotifyAction.Command.error('Request Error', 'Could not load board permissions'));
        }

        if (isSuccess(result)) {
            const boardPermissions = List(result.value);
            yield put(Action.Event.boardPermissionsForUsersFetched(boardPermissions));
        }
    }
};

const onFetchBoardPermissionsForTeams = function* (boardId: BoardId) {
    const {response, error}: ResponseType = yield call(Action.Api.fetchBoardPermissionsForTeams, boardId);

    if (error) {
        yield put(NotifyAction.Command.error("Request Error", "Failed to load board permissions"));
    }

    if (response) {

        const result = pipe(
            response!.data,
            execValidate([isArray(), "Response data is not of type Array"]),
            execForEach((data: any) => {
                return createBoardPermissionForTeamFromServerData(data);
            }),
        );

        if (isFailure(result)) {
            // tslint:disable-next-line:no-console
            console.error("Failed to process board permissions response from server", result.value, response.data);
            yield put(NotifyAction.Command.error('Request Error', 'Could not load board permissions'));
        }

        if (isSuccess(result)) {
            const boardPermissions = List(result.value);
            yield put(Action.Event.boardPermissionsForTeamsFetched(boardPermissions));
        }
    }
};

export function* fetchBoardPermissionsFlow() {

    while (true) {
        const action: FlowAction = yield take([
            Action.Type.FETCH_BOARD_PERMISSIONS_FOR_USERS,
            Action.Type.FETCH_BOARD_PERMISSIONS_FOR_TEAMS,
            TeamAction.Type.TEAM_ACCESS_TO_BOARD_GRANTED,
            TeamAction.Type.TEAM_ACCESS_TO_BOARD_REVOKED,
        ]);

        switch (action.type) {
            case FETCH_BOARD_PERMISSIONS_FOR_USERS:
                yield fork(onFetchBoardPermissionsForUsers, action.payload.boardId);
                break;
            case FETCH_BOARD_PERMISSIONS_FOR_TEAMS:
            case TeamAction.Type.TEAM_ACCESS_TO_BOARD_GRANTED:
            case TeamAction.Type.TEAM_ACCESS_TO_BOARD_REVOKED:
                yield fork(onFetchBoardPermissionsForTeams, action.payload.boardId);
                break;
        }
    }
}
