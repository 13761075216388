import * as React from 'react';
import {useEffect, useState} from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, withRouter} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import {Button, Dimmer, Icon, Loader, Message, Modal} from "semantic-ui-react";
import * as uuid from "uuid";
import {ImportMode} from "../../Layout/Sidebar/ImportBoard";
import {Action as Notify} from "../../NotificationSystem";
import * as Routes from "../../routes";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import * as Action from "../actions";
import {fetchBoardXmlFromLink, fetchPlayshotFromLink} from "../actions/api";
import {BoardModel} from "../model";
import {BoardId, BoardXml} from "../model/Board";
import {makeGetAllBoards, makeInitialBoardListLoaded} from "../selectors/boardList";
import {savePlayshot} from "../service/playshot-storage";
import {togglePlayshotModal} from "../actions/commands";

interface RouteProps {
    link: string;
}

interface OwnProps {

}

type BoardImportFromLinkProps = OwnProps & RouteComponentProps<RouteProps> & WithNamespaces;

const extractBoardName = (link: string): string => {
    const parts = link.split('/');
    const boardFile = parts[parts.length - 1];
    return boardFile.split('.').slice(0, -1).join('.');
}


const BoardImportFromLink = (props: BoardImportFromLinkProps) => {
    const [doRedirect, setDoRedirect] = useState(false);
    const [redirectPath, setRedirectPath] = useState(Routes.inspectio);
    const [askForImportMode, setAskForImportMode] = useState(false);
    const [boardXml, setBoardXml] = useState<BoardXml|undefined>(undefined);
    const [boardId, setBoardId] = useState<BoardId|undefined>(undefined);
    const initialBoardListLoaded = useSelector(makeInitialBoardListLoaded());
    const user = useSelector(makeGetCurrentUser());
    const getBoards = makeGetAllBoards();
    const boards = useSelector(getBoards);
    const [existingBoardName, setExistingBoardName] = useState('undefined');
    const dispatch = useDispatch();
    const link = decodeURIComponent(props.match.params.link);
    const [playshotLoaded, setPlayshotLoaded] = useState(false);

    const queryParams = new URLSearchParams(props.location.search);

    const shouldSave = !queryParams.has('temp') || parseInt(queryParams.get('temp')+'', undefined) !== 1;
    const withPlayshot = queryParams.has('playshot');

    useEffect(() => {
        if(!initialBoardListLoaded) {
            return;
        }

        setPlayshotLoaded(false);
        fetchBoardXmlFromLink(link)
            .then(loadedBoardXml => {
                setBoardXml(loadedBoardXml);

                const boardName = extractBoardName(link);

                const existingBoardList = boards.filter((board) => {
                    return board.name === boardName;
                });

                if(shouldSave && existingBoardList.count() !== 0) {
                    setExistingBoardName(boardName);
                    setAskForImportMode(true);
                    return;
                }

                processFile(boardName, loadedBoardXml, shouldSave? ImportMode.newBoard : ImportMode.tempBoard);
            })
            .catch(err => {
                console.error(err);
                dispatch(Notify.Command.error('Request failed', 'Failed to load board. Please check if import link points to a valid prooph board XML file!'));
                setDoRedirect(true);
                return;
            })
    }, [link, initialBoardListLoaded]);

    useEffect(() => {
        if(!initialBoardListLoaded) {
            return;
        }

        if(withPlayshot && boardId) {
            const playshotLink = decodeURIComponent(queryParams.get('playshot') || '');

            if(playshotLink) {
                fetchPlayshotFromLink(playshotLink)
                  .then(playshot => savePlayshot({...playshot, boardId}))
                  .then(success => {
                      if(!success) {
                          dispatch(Notify.Command.error('Failed to save playshot', 'Failed to save playshot. Please check if import link points to a valid prooph board playshot file!'));
                          setDoRedirect(true);
                          return;
                      }
                      setPlayshotLoaded(true);
                  })
                  .catch(error => {
                      console.error(error);
                      dispatch(Notify.Command.error('Failed to load playshot', 'Failed to load playshot. Please check if import link points to a valid prooph board playshot file!'));
                      setDoRedirect(true);
                      return;
                  })
            }
        }
    }, [boardId]);

    const handleImportModalClose = () => {
        setAskForImportMode(false);
    }

    const processFile = (boardName: string, xml: BoardXml, importMode: ImportMode) => {

        if(importMode === ImportMode.newBoard) {
            const newBoardId = uuid.v4();
            dispatch(Action.Command.importNewBoard(boardName, xml, newBoardId));

            setBoardId(newBoardId);
        } else if (importMode === ImportMode.tempBoard) {
            const newBoardId = uuid.v4();
            dispatch(Action.Command.importNewBoard(boardName, xml, newBoardId, true));

            setBoardId(newBoardId);
        } else {
            const existingBoardList = boards.filter((board) => {
                return board.name === boardName;
            });

            if(existingBoardList.count() > 0) {
                const existingBoard: BoardModel.Board = existingBoardList.first();

                const updatedBoard = existingBoard.updateXml(xml, user.uid);
                dispatch(Action.Command.importExistingBoard(updatedBoard));

                // wait a moment, so that board xml gets updated before redirect to board workspace happens
                window.setTimeout(() => {
                    setBoardId(updatedBoard.uid);
                }, 50);
            }
        }
    };

    const handleImportCopyClick = () => {
        setAskForImportMode(false);
        if(boardXml) {
            processFile(extractBoardName(link) + " (Copy)", boardXml, ImportMode.newBoard);
        }
    };

    const handleOverrideClick = () => {
        setAskForImportMode(false);
        if(boardXml) {
            processFile(extractBoardName(link), boardXml, ImportMode.replaceBoard);
        }
    }

    if(boardId && boards.filter(board => board.uid === boardId).count() === 1 && (!withPlayshot || playshotLoaded)) {
        if(withPlayshot) {
            return <Redirect to={Routes.compileWorkspacePlayshotDeeplink(boardId)} />;
        }
        return <Redirect to={Routes.compileInspectioBoardWorkspace(boardId)} />;
    }

    if(doRedirect) {
        return <Redirect to={redirectPath} />;
    }

    return <>
            <Dimmer active={true} className="page"><Loader size="large">Loading board from {link}</Loader></Dimmer>
            <Modal open={askForImportMode} closeIcon={true} onClose={handleImportModalClose}>
                <Modal.Header>{ props.t("insp.board.import") }</Modal.Header>
                <Modal.Content>
                    <Message icon="warning" warning={true} content={props.t('insp.board.import_existing_board').replace('%name%', existingBoardName)} />
                </Modal.Content>
                <Modal.Actions>
                    <Button primary={true} onClick={handleImportCopyClick}><Icon name='plus' /> { props.t('insp.board.btn_import_as_new') }</Button>
                    <Button basic={true} onClick={handleOverrideClick}>{ props.t('insp.board.btn_replace_board') }</Button>
                </Modal.Actions>
            </Modal>
        </>;
};

export default withRouter(withNamespaces()(BoardImportFromLink));
