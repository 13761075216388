import * as React from 'react';
import {Icon} from "semantic-ui-react";
import {NodeType} from "../../../model/Graph";
import CellIcon from "../../CellIcon";
import {isSelectContextStep, WizardStep} from "../WizardModal";
import CodyEmoji from "../../CodyConsole/CodyEmoji";

interface OwnProps {
  activeStep: WizardStep | null;
}

type ActiveStepIconProps = OwnProps;

const nodeTypeFromStep = (step: WizardStep): NodeType => {
  switch (step) {
    case "aggregate":
      return NodeType.aggregate;
    case "dddEvent":
    case "publicEvent":
    case "policyEvent":
      return NodeType.event;
    case "feature":
      return NodeType.feature;
    case "command":
      return NodeType.command;
    case "state":
      return NodeType.document;
    case "ui":
      return NodeType.ui;
    case "anyVO":
      return NodeType.document;
    case "policy":
      return NodeType.policy;
    default:
      return NodeType.freeText;
  }
}

const ActiveStepIcon = (props: ActiveStepIconProps) => {


  return <>
    {!props.activeStep && <Icon name="question circle outline" />}
    {props.activeStep && props.activeStep !== 'runCody' && !isSelectContextStep(props.activeStep) && <CellIcon cellType={nodeTypeFromStep(props.activeStep)} size="normal" />}
    {props.activeStep && isSelectContextStep(props.activeStep) && <Icon name="code branch" />}
    {props.activeStep && props.activeStep === 'runCody' && <CodyEmoji width={40} style={{top: '7px', position: 'relative', marginLeft: '5px'}} />}
  </>
};

export default ActiveStepIcon;
