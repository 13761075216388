import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action} from "../index";
import {makeOrganizationSelector} from "../selectors/selectOrganization";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";

type Command = ActionType<typeof Action.Command.turnGuestIntoMember>

function* handleTurnGuestIntoMember(command: Command) {
    const organization = yield select(makeOrganizationSelector(command.payload.organizationId));

    if(organization) {
        const {response, error}: ResponseType = yield call(Action.Api.turnGuestIntoMember, command.payload.guestId, command.payload.organizationId);

        if(error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not turn guest into member.'));
        }

        if(response) {
            yield put(NotifyAction.Command.info('Member added', 'Guest turned into member.'));
            yield put(Action.Event.guestTurnedIntoMember(organization.turnGuestIntoMember(command.payload.guestId)));
        }
    }
}

export function* turnGuestIntoMember() {
    while (true) {
        const command: Command = yield take([
            Action.Type.TURN_GUEST_INTO_MEMBER
        ]);

        yield fork(handleTurnGuestIntoMember, command);
    }
}
