
export const getMessageBoxRequestConfig = <P> (messageName: string, payload: P) => {
    return {
        url: messageName,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(payload),
        responseType: 'json'
    }
}
