import { call, fork, put, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {ResponseType} from "../../api/util";
import {exec, isFailure, isSuccess, pipe} from "../../core/validation/either";
import { Action as NotifyAction } from '../../NotificationSystem';
import { Action as UserAction } from '../../User';
import * as Action from '../actions';
import {createOrganizationFromServerData} from "../model/Organization";

type FlowAction = ActionType<typeof Action.Query.fetchOrganization>;

const onFetchOrganization = function* (action: FlowAction) {
    const {response, error}: ResponseType = yield call(Action.Api.fetchOrganization, action.payload.organizationId);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not fetch organization'));
    }

    if (response) {
        const result = pipe(
            response.data,
            exec((data: any) => createOrganizationFromServerData(data)),
        );

        if (isFailure(result)) {
            console.error("Invalid organization response: ", result.value);
            yield put(NotifyAction.Command.error('Request Error', 'Could not load organization'));
        }

        if (isSuccess(result)) {
            yield put(Action.Event.organizationFetched(result.value));
            yield put(UserAction.Query.loadUsersInfo([result.value.ownerId, ...result.value.members.toJS(), ...result.value.guests.toJS()]));
        }
    }
};

export function* fetchOrganizationFlow() {
    while (true) {
        const action: FlowAction = yield take([
            Action.Type.FETCH_ORGANIZATION
        ]);

        yield fork(onFetchOrganization, action);
    }
}
