import {StandaloneBoard, StandaloneUser, StandaloneUserSetting} from "./types";
import StandaloneIndexedDB, {OBJECT_STORE_BOARDS, OBJECT_STORE_USER_SETTINGS} from "./StandaloneIndexedDB";

class StandaloneStorageManager {
    private db: StandaloneIndexedDB;

    constructor(db: StandaloneIndexedDB) {
        this.db = db;
        this.getBoards = this.getBoards.bind(this);
        this.getBoard = this.getBoard.bind(this);
        this.updateBoard = this.updateBoard.bind(this);
        this.addBoard = this.addBoard.bind(this);
        this.deleteBoard = this.deleteBoard.bind(this);
        this.getUserSettings = this.getUserSettings.bind(this);
        this.setUserSetting = this.setUserSetting.bind(this);
    }

    public getUser(): StandaloneUser {
        return {
            userId: 'f1c1512d-e1b9-44c2-940c-3f7cade64a29',
        }
    }

    public async getBoards(): Promise<StandaloneBoard[]> {
        return await this.db.getAll(OBJECT_STORE_BOARDS);
    }

    public async getBoard(boardId: string): Promise<StandaloneBoard|null> {
        return await this.db.get(OBJECT_STORE_BOARDS, boardId) || null;
    }

    public async updateBoard(board: StandaloneBoard): Promise<void> {
        await this.db.put(OBJECT_STORE_BOARDS, board);
    }

    public async addBoard(board: StandaloneBoard): Promise<void> {
        await this.db.add(OBJECT_STORE_BOARDS, board);
    }

    public async deleteBoard(boardId: string): Promise<void> {
        await this.db.delete(OBJECT_STORE_BOARDS, boardId);
    }

    public async getUserSettings(): Promise<StandaloneUserSetting[]> {
        return await this.db.getAll(OBJECT_STORE_USER_SETTINGS);
    }

    public async setUserSetting(setting: StandaloneUserSetting): Promise<void> {
        if (await this.db.get(OBJECT_STORE_USER_SETTINGS, setting.key)) {
            await this.db.put(OBJECT_STORE_USER_SETTINGS, setting);
        } else {
            await this.db.add(OBJECT_STORE_USER_SETTINGS, setting);
        }
    }
}

export default StandaloneStorageManager;
