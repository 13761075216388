import {List} from "immutable";
import * as React from 'react';
import {List as SemanticList} from 'semantic-ui-react';
import {BoardId} from "../../model/Board";
import {Node, sortNodesByType} from "../../model/Graph";
import {isValidLeaf} from "../BoardTreeView";
import BoardTreeLeaf from "./BoardTreeLeaf";
import BoardLayer from "./BoardLayer";

interface OwnProps {
    leafs: List<Node>;
    boardId: BoardId;
    expandLeafs: boolean;
}

type BoardTreeProps = OwnProps;

const BoardTree = (props: BoardTreeProps) => {
    const leafs = props.leafs.filter(isValidLeaf)
        .sort(sortNodesByType)
        .map(ele => <BoardTreeLeaf expand={props.expandLeafs} element={ele} key={ele.getId()} boardId={props.boardId}/>);

    return <SemanticList className="elements tree">{ leafs }</SemanticList>;
};

export default BoardTree;
