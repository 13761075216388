import {isSelectContextStep, WizardStep} from "../../../components/CodyEngineWizard/WizardModal";
import {names} from "../node/names";

export const stepHeader = (step: WizardStep, t: (k: string) => string): string => {
  switch (step) {
    case "state":
      return 'Information';
    case "dddEvent":
      return 'Domain Event';
    case "publicEvent":
      return 'Public Domain Event';
    case "policyEvent":
      return 'Domain Event';
    case "anyVO":
      return 'Information';
    case "runCody":
      return 'Run Cody';
    default:
      if(isSelectContextStep(step)) {
        return t('insp.cody_wizard.step_header');
      }

      return names(step).className;
  }
}
