import * as React from 'react';
import {Breadcrumb} from "semantic-ui-react";

interface OwnProps {

}

type UpdatesCrumbProps = OwnProps;

const UpdatesCrumb = (props: UpdatesCrumbProps) => {
    return <Breadcrumb.Section>Updates</Breadcrumb.Section>
};

export default UpdatesCrumb;