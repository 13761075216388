import {List} from "immutable";
import * as React from 'react';
import {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown, DropdownItemProps} from "semantic-ui-react";
import * as Action from "../../actions";
import {Board} from "../../model/Board";
import {makeGetTags} from "../../selectors/tags";

interface OwnProps {
    board: Board;
}

type BoardTagsProps = OwnProps;

const BoardTags = (props: BoardTagsProps) => {
    const dispatch = useDispatch();
    const existingTags = useSelector(makeGetTags());
    const availableTags = useRef<DropdownItemProps[]>(existingTags.map(tag => ({key: tag,text: '#'+tag,value: tag})).toJS());

    const addTagOption = (tag: string) => {
        if(tag.length === 0) {
            return;
        }

        if(tag.charAt(0) !== '#') {
            tag = '#'+tag;
        }

        const tagVal = tag.slice(1);

        availableTags.current.push({
            key: tagVal,
            text: tag,
            value: tagVal
        })
    }

    const setTags = (newTags: string[]) => {
        const changedBoard = props.board.merge({tags: List(newTags)});
        dispatch(Action.Command.setBoardTags(changedBoard));
    }

    const tags = props.board.tags.toJS();

    const currentTagList = availableTags.current.map(tag => tag.value);

    tags.forEach(tag => {
        if(!currentTagList.includes(tag.value)) {
            availableTags.current.push({key: tag, text: '#'+tag, value: tag})
        }
    });

    return <div className="field">
        <Dropdown
            multiple={true}
            allowAdditions={true}
            name="tags"
            placeholder="Tags"
            search={true}
            selection={true}
            options={availableTags.current}
            value={tags}
            onAddItem={(evt, data) => addTagOption(data.value as string)}
            onChange={(evt, data) => setTags(data.value as string[])}
        />
    </div>
};

export default BoardTags;
