import { all, call, delay, spawn, takeEvery } from 'redux-saga/effects';
import { sendHttpRequestFlow } from './api/WebData';
import * as Auth from './Auth';
import { Saga as IbSaga } from "./InspectioBoards";
import { Saga as TeamsSaga } from "./InspectioTeams";
import { Saga as SocketSaga } from './Socket';
import {Saga as UserSaga} from "./User";
import { Saga as OrganizationSaga } from "./Organization";
import {isStandalone} from "./standalone/util";
import StandaloneSaga from "./standalone/sagas";

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*

const makeRestartable = (saga: any) => {
    return function* () {
        yield spawn(function* () {
            while (true) {
                try {
                    yield call(saga);
                    console.error('unexpected root saga termination. The root sagas are supposed to be sagas that live during the whole app lifetime!', saga);
                } catch (e) {
                    console.error('Saga error, the saga will be restarted', e);
                }
                yield delay(1000); // Workaround to avoid infinite error loops
            }
        });
    };
};

const sagas = isStandalone() ? Object.values(StandaloneSaga) : [
    Auth.Saga.tokenRefreshFlow,
    IbSaga.deleteBoardFlow,
    IbSaga.fetchUserBoardsFlow,
    IbSaga.fetchUserBoardFlow,
    IbSaga.fetchBoardPermissionsFlow,
    IbSaga.fetchOpenBoardInvitationsFlow,
    IbSaga.fetchPlayshotSummaryListFlow,
    IbSaga.installUserBoardFlow,
    IbSaga.saveBoardFlow,
    IbSaga.renameBoardFlow,
    IbSaga.toggleCodySuggestEnabled,
    IbSaga.toggleEventModelingEnabled,
    IbSaga.changeBoardAbbreviationFlow,
    IbSaga.changeResponsibleTeamFlow,
    IbSaga.setBoardTagsFlow,
    IbSaga.shareBoardFlow,
    IbSaga.resendBoardInvitationFlow,
    IbSaga.revokeBoardInvitationFlow,
    IbSaga.prepareBoardHistoryFlow,
    IbSaga.loadBoardHistoryFlow,
    IbSaga.importNewBoardFlow,
    IbSaga.importExistingBoardFlow,
    IbSaga.syncElementsTreeFlow,
    IbSaga.revokeAccessToBoardFlow,
    IbSaga.changeBoardPermissionsFlow,
    IbSaga.adminChangeTeamBoardPermissions,
    IbSaga.fetchBoardClientFlow,
    IbSaga.fetchBoardGraphElementsFlow,
    IbSaga.fetchBoardStickyTemplates,
    IbSaga.updateStickyTemplate,
    IbSaga.importStickyTemplates,
    IbSaga.moveUserBoardToOrganization,
    IbSaga.storeOverviewSettingsFlow,
    IbSaga.shareBoardWithOrganizationMembersFlow,
    IbSaga.shareBoardWithAnyoneFlow,
    TeamsSaga.addTeam,
    TeamsSaga.renameTeam,
    TeamsSaga.changeTeamDescription,
    TeamsSaga.inviteUserToTeam,
    TeamsSaga.removeMemberFromTeam,
    TeamsSaga.adminGrantTeamAccessToBoard,
    TeamsSaga.deleteTeam,
    TeamsSaga.resendTeamInvitation,
    TeamsSaga.revokeTeamInvitation,
    TeamsSaga.refreshUserTeams,
    TeamsSaga.moveTeamToOrganization,
    SocketSaga.manageSocketConnection,
    sendHttpRequestFlow,
    UserSaga.syncUsername,
    UserSaga.lazyLoadInspectioUserInfo,
    UserSaga.updateProfile,
    UserSaga.updatePassword,
    UserSaga.upsertUserSetting,
    UserSaga.switchActiveOrganization,
    OrganizationSaga.fetchOrganizationFlow,
    OrganizationSaga.fetchUserOrganizations,
    OrganizationSaga.setUpExplicitOrganization,
    OrganizationSaga.inviteUserToOrganization,
    OrganizationSaga.revokeUserOrganizationInvitation,
    OrganizationSaga.resendUserOrganizationInvitation,
    OrganizationSaga.promoteUserToOrganizationAdmin,
    OrganizationSaga.demoteUserToOrganizationMember,
    OrganizationSaga.removeUserFromOrganization,
    OrganizationSaga.renameOrganization,
    OrganizationSaga.removeGuestFromOrganization,
    OrganizationSaga.turnGuestIntoMember,
];

const rootSagas = sagas.map(makeRestartable);

export default function* rootSaga() {
    console.log('root saga started');
    yield all(rootSagas.map((saga: any) => call(saga)));
}
