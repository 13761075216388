import {Reducer} from "redux";
import {ActionType} from "typesafe-actions";
import {RUN_CODY_ON_CHANGED} from "../actions/constants";
import * as Events from "../actions/events";
import {InspectioBoardsAction} from "./index";

export type RunCodyOption = 'play' | 'engine' | 'auto';

type Event = ActionType<typeof Events>;

export const initialState: RunCodyOption = 'auto';

const reducer: Reducer<RunCodyOption, InspectioBoardsAction> = (state: RunCodyOption = initialState, event: InspectioBoardsAction): RunCodyOption => {
  switch (event.type) {
    case RUN_CODY_ON_CHANGED:
      return event.payload.runCodyOn;
    default:
      return state;
  }
}

export {reducer as saveRunCodyOnReducer};
