import {ResponseType} from "../../api/util";
import * as Action from "../../User/actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import { call, put } from 'redux-saga/effects';
import {createUserSettingsFromServerData} from "../model/UserSettings";
import {isFailure, isSuccess} from "../../core/validation/either";

export function* loadUserSettings() {
    const {response, error}: ResponseType = yield call(Action.Api.getUserSettings);

    if (error) {
        yield put(NotifyAction.Command.error('Request Error', 'Could not load user settings'));
    }

    if (response) {
        const result = createUserSettingsFromServerData(response.data);

        if (isFailure(result)) {
            // tslint:disable-next-line:no-console
            console.error("Failed to process user settings response from server", result.value, response.data);
            yield put(NotifyAction.Command.error('Request Error', 'Could not load user settings'));
        }

        if (isSuccess(result)) {
            yield put(Action.Event.userSettingsLoaded(result.value));
        }
    }
}
