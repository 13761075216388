import {Map} from "immutable";
import React from 'react'
import {withNamespaces, WithNamespaces} from "react-i18next";
import {InjectedFormProps} from "redux-form";
import { Field, reduxForm } from 'redux-form/immutable';
import {Button, Message} from "semantic-ui-react";

interface FormData  {
    uid: string;
    passwordSet: {
        password1: string,
        password2: string,
    }
}

export interface ImFormData extends Map<string, string> {
    toJS(): FormData
}

export interface OwnProps {
    showErrorMessage: boolean;
}

export interface PropsFromDispatch { }

export type UserProfilePasswordFormProps = OwnProps & PropsFromDispatch & WithNamespaces & InjectedFormProps<ImFormData, OwnProps & WithNamespaces>;

const UserProfilePasswordForm = (props: UserProfilePasswordFormProps) => {

    return (
        <form className={'ui form' + (props.showErrorMessage ? ' error' : '')} onSubmit={props.handleSubmit}>
            <div className={'field'}>
                <label>{props.t('insp.user.form.labels.password1')}</label>
                <Field name={'password1'} component={'input'} type={'password'}/>
            </div>

            <div className={'field'}>
                <label>{props.t('insp.user.form.labels.password2')}</label>
                <Field name={'password2'} component={'input'} type={'password'}/>
            </div>

            <Message
                error={true}
                header={props.t('insp.user.change_password.passwords_not_matching_title')}
                content={props.t('insp.user.change_password.passwords_not_matching_text')}
            />

            <div className={'field'}>
                <Button primary={true} type={'submit'} className={'bottom aligned'}>{props.t('app.form.change')}</Button>
            </div>
        </form>
    );
};

export default withNamespaces()(reduxForm<ImFormData, OwnProps & WithNamespaces>({form: 'UserProfilePasswordForm', enableReinitialize: false})(UserProfilePasswordForm));

