import * as React from 'react';
import {Icon} from "semantic-ui-react";

interface OwnProps {
  expanded: boolean;
  onExpandChanged: (expand: boolean) => void;
}

type ExpandButtonProps = OwnProps;

const ExpandButton = (props: ExpandButtonProps) => {

  const handleClick = () => {
    props.onExpandChanged(!props.expanded);
  }

  return <div style={{display: "flex", marginBottom: "10px"}}>
    <button className="ui basic compact button noborder" style={{marginLeft: "auto", padding: 0}} onClick={handleClick}>
      <Icon name="expand" style={{margin: 0, color: props.expanded? '#2185d0' : 'inherit', opacity: 1}} />
    </button>
  </div>
};

export default ExpandButton;
