import * as React from 'react';
import {Breadcrumb} from "semantic-ui-react";

interface OwnProps {

}

type UserProfileCrumbProps = OwnProps;

const UserProfileCrumb = (props: UserProfileCrumbProps) => {
    return <Breadcrumb.Section>Profile</Breadcrumb.Section>
};

export default UserProfileCrumb;