import {UserModel} from "../../User";
import {BoardTimer} from "../model/BoardTimer";
import {
    AutoScrollEvent,
    GestureChanged,
    MouseDelta,
    UserJoinedListener, UserLeftListener,
    UserMouseJoined,
    UserMouseLeft,
    UserMouseMove,
    UserMouseScroll
} from "../service/RemoteMouseSync";

export type BoardTimerListener = (timer: BoardTimer) => void;

export abstract class MouseSync {
    public abstract startSync(joined: UserMouseJoined): void;
    public abstract stopSync(left: UserMouseLeft): void;
    public abstract keepMousePositionsInPlaceOnPanning(move: UserMouseMove, mouseDelta: MouseDelta): void;
    public abstract syncLastKnownAbsolutePointsAfterPanning(): void;
    public abstract emitUserMouseMove(move: UserMouseMove, mouseDelta?: MouseDelta): void;
    public abstract emitUserMouseScroll(scroll: UserMouseScroll): void;
    public abstract emitUserGestureChanged(gestureChanged: GestureChanged): void;
    public abstract emitUserIsAutoScrolling(autoScrollEvent: AutoScrollEvent): void;
    public abstract trackMouseOfRemoteUser(userId: UserModel.UserId, userName: UserModel.Username, avatar: UserModel.AvatarUrl): void;
    public abstract stopMouseTrackingOfUser(userId: UserModel.UserId): void;
    public abstract observeUser(userId: UserModel.UserId): void;
    public abstract hideObservingFrame(): void;
    public abstract stopObservingUser(): void;
    public abstract onUserJoined(listener: UserJoinedListener): void;
    public abstract offUserJoined(listener: UserJoinedListener): void;
    public abstract onUserLeft(listener: UserLeftListener): void;
    public abstract offUserLeft(listener: UserLeftListener): void;
    public abstract startBoardTimer(timer: BoardTimer): void;
    public abstract onBoardTimerStarted(listener: BoardTimerListener): void;
    public abstract offBoardTimerStarted(listener: BoardTimerListener): void;
    public abstract pauseBoardTimer(timer: BoardTimer): void;
    public abstract onBoardTimerPaused(listener: BoardTimerListener): void;
    public abstract offBoardTimerPaused(listener: BoardTimerListener): void;
    public abstract stopBoardTimer(timer: BoardTimer): void;
    public abstract onBoardTimerStopped(listener: BoardTimerListener): void;
    public abstract offBoardTimerStopped(listener: BoardTimerListener): void;
}

// tslint:disable-next-line:max-classes-per-file
class MouseSyncRef implements MouseSync {

    private mouseSyncRef: MouseSync | undefined;

    private cachedUserJoinedListeners: UserJoinedListener[] = [];
    private cachedUserLeftListeners: UserLeftListener[] = [];
    private cachedBoardTimerStartedListeners: BoardTimerListener[] = [];
    private cachedBoardTimerPausedListeners: BoardTimerListener[] = [];
    private cachedBoardTimerStoppedListeners: BoardTimerListener[] = [];

    public setMouseSync(mouseSync: MouseSync | undefined): void {
        this.mouseSyncRef = mouseSync;

        if(this.mouseSyncRef) {
            this.cachedUserJoinedListeners.forEach(l => this.mouseSyncRef!.onUserJoined(l))
            this.cachedUserLeftListeners.forEach(l => this.mouseSyncRef!.onUserLeft(l))
            this.cachedBoardTimerStartedListeners.forEach(l => this.mouseSyncRef!.onBoardTimerStarted(l))
            this.cachedBoardTimerPausedListeners.forEach(l => this.mouseSyncRef!.onBoardTimerPaused(l))
            this.cachedBoardTimerStoppedListeners.forEach(l => this.mouseSyncRef!.onBoardTimerStopped(l))
        }

        this.cachedUserJoinedListeners = [];
        this.cachedUserLeftListeners = [];
        this.cachedBoardTimerStartedListeners = [];
        this.cachedBoardTimerPausedListeners = [];
        this.cachedBoardTimerStoppedListeners = [];
    }

    public emitUserGestureChanged(gestureChanged: GestureChanged): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.emitUserGestureChanged(gestureChanged);
        }

    }

    public emitUserIsAutoScrolling(autoScrollEvent: AutoScrollEvent): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.emitUserIsAutoScrolling(autoScrollEvent);
        }
    }

    public emitUserMouseMove(move: UserMouseMove, mouseDelta?: MouseDelta): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.emitUserMouseMove(move, mouseDelta);
        }
    }

    public emitUserMouseScroll(scroll: UserMouseScroll): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.emitUserMouseScroll(scroll);
        }
    }

    public hideObservingFrame(): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.hideObservingFrame();
        }
    }

    public keepMousePositionsInPlaceOnPanning(move: UserMouseMove, mouseDelta: MouseDelta): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.keepMousePositionsInPlaceOnPanning(move, mouseDelta);
        }
    }

    public observeUser(userId: UserModel.UserId): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.observeUser(userId);
        }
    }

    public onUserJoined(listener: UserJoinedListener): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.onUserJoined(listener);
        } else {
            this.cachedUserJoinedListeners.push(listener);
        }
    }

    public offUserJoined(listener: UserJoinedListener) {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.offUserJoined(listener);
        } else {
            this.cachedUserJoinedListeners = this.cachedUserJoinedListeners.filter(l => l !== listener);
        }
    }

    public onUserLeft(listener: UserLeftListener): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.onUserLeft(listener);
        } else {
            this.cachedUserLeftListeners.push(listener);
        }
    }

    public offUserLeft(listener: UserLeftListener) {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.offUserLeft(listener);
        } else {
            this.cachedUserLeftListeners = this.cachedUserLeftListeners.filter(l => l !== listener);
        }
    }

    public startSync(joined: UserMouseJoined): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.startSync(joined);
        }
    }

    public stopMouseTrackingOfUser(userId: UserModel.UserId): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.stopMouseTrackingOfUser(userId);
        }
    }

    public stopObservingUser(): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.stopObservingUser();
        }
    }

    public stopSync(left: UserMouseLeft): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.stopSync(left);
        }
    }

    public syncLastKnownAbsolutePointsAfterPanning(): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.syncLastKnownAbsolutePointsAfterPanning();
        }
    }

    public trackMouseOfRemoteUser(userId: UserModel.UserId, userName: UserModel.Username, avatar: UserModel.AvatarUrl): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.trackMouseOfRemoteUser(userId, userName, avatar);
        }
    }

    public startBoardTimer(timer: BoardTimer): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.startBoardTimer(timer);
        }
    }

    public onBoardTimerPaused(listener: BoardTimerListener): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.onBoardTimerPaused(listener);
        } else {
            this.cachedBoardTimerPausedListeners.push(listener);
        }
    }

    public offBoardTimerPaused(listener: BoardTimerListener) {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.offBoardTimerPaused(listener);
        } else {
            this.cachedBoardTimerPausedListeners = this.cachedBoardTimerPausedListeners.filter(l => l !== listener);
        }
    }

    public onBoardTimerStarted(listener: BoardTimerListener): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.onBoardTimerStarted(listener);
        } else {
            this.cachedBoardTimerStartedListeners.push(listener);
        }
    }

    public offBoardTimerStarted(listener: BoardTimerListener) {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.offBoardTimerStarted(listener);
        } else {
            this.cachedBoardTimerStartedListeners = this.cachedBoardTimerStartedListeners.filter(l => l !== listener);
        }
    }

    public onBoardTimerStopped(listener: BoardTimerListener): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.onBoardTimerStopped(listener);
        } else {
            this.cachedBoardTimerStoppedListeners.push(listener);
        }
    }

    public offBoardTimerStopped(listener: BoardTimerListener) {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.offBoardTimerStopped(listener);
        } else {
            this.cachedBoardTimerStoppedListeners = this.cachedBoardTimerStoppedListeners.filter(l => l !== listener);
        }
    }

    public pauseBoardTimer(timer: BoardTimer): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.pauseBoardTimer(timer);
        }
    }

    public stopBoardTimer(timer: BoardTimer): void {
        if(this.mouseSyncRef) {
            this.mouseSyncRef.stopBoardTimer(timer);
        }
    }
}

let currentMouseSync: MouseSyncRef | undefined;

export function useMouseSync(): [MouseSync, (mouseSync: MouseSync | undefined) => void] {
    if(!currentMouseSync) {
        currentMouseSync = new MouseSyncRef();
    }

    return [currentMouseSync, (mouseSync => currentMouseSync!.setMouseSync(mouseSync))]
}
