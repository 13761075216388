import * as React from "react";
import {useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ButtonGroup, ListItem, Table} from "semantic-ui-react";
import SecondaryButton from "../../../core/components/SecondaryButton";
import {isSuccess} from "../../../core/validation/either";
import * as UserModule from "../../../User";
import UserAvatar from "../../../User/components/UserAvatar";
import {createAnonymous} from "../../../User/model/IioUserInfo";
import * as Action from "../../actions";
import {Board} from "../../model/Board";
import {BoardInvitation} from "../../model/BoardInvitation";
import {makeBoardInvitationSelector} from "../../selectors/boardInvitation";

interface PropsFromState {
}

interface PropsFromDispatch {

}

interface OwnProps {
    board: Board;
    boardInvitation: BoardInvitation;
}

export type OpenBoardInvitationTableEntryProps = PropsFromState & PropsFromDispatch & OwnProps & WithNamespaces;

const OpenBoardInvitationsTableEntry = (props: OpenBoardInvitationTableEntryProps) => {

    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState(createAnonymous());
    const [isResending, setIsResending] = useState<boolean>(false);
    const [isRevoking, setIsRevoking] = useState<boolean>(false);
    const invitation = useSelector(makeBoardInvitationSelector(props.boardInvitation.uid));

    useEffect(() => {
        UserModule.Action.Api.getInspectioUserInfoByEmail(props.boardInvitation.invitedUserEmail).then(({response, error}) => {
            if(response) {
                const result = UserModule.IioUserInfo.createIioUserInfoFromServerData(response.data);

                if(isSuccess(result)) {
                    setUserInfo(result.value);
                }
            }
        })
    }, [props.boardInvitation.invitedUserEmail]);

    useEffect(
        () => {
            setIsResending(false);
            setIsRevoking(false);
        },
        [invitation]
    );

    const handleResendInvitation = () => {
        if (isResending || isRevoking) {
            return;
        }

        setIsResending(true);
        dispatch(Action.Command.resendBoardInvitation(props.boardInvitation.uid));
    };

    const handleRevokeInvitation = () => {
        if (isResending || isRevoking) {
            return;
        }

        setIsRevoking(true);
        dispatch(Action.Command.revokeBoardInvitation(props.boardInvitation.uid));
    };

    return (
        <Table.Row>
            <Table.Cell style={{ width: "250px" }}>
                <UserAvatar user={userInfo} size="mini" />
                <span style={{paddingLeft: "10px"}}>{props.boardInvitation.invitedUserEmail}</span>
            </Table.Cell>
            <Table.Cell>
                <ButtonGroup floated="right">
                    <SecondaryButton
                        content={props.t('app.form.resend')}
                        icon="paper plane"
                        loading={isResending}
                        onClick={handleResendInvitation}
                    />
                    <SecondaryButton
                        content={props.t('app.form.revoke')}
                        icon="delete"
                        loading={isRevoking}
                        onClick={handleRevokeInvitation}
                    />
                </ButtonGroup>
            </Table.Cell>
        </Table.Row>
    );
};

export default withNamespaces()(OpenBoardInvitationsTableEntry);
