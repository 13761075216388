import * as React from "react";
import {StickyTemplate} from "../../../model/StickyTemplate";
import CodeEditor, {monaco} from "../../../../core/components/CodeEditor/CodeEditor";
import {Ref, useEffect, useImperativeHandle, useRef} from "react";

export interface BoardStickyTemplateEditorRef {
    retrievePayload: () => string;
    updateModelValue: (value: string) => void;
}

interface OwnProps {
    boardStickyTemplate: StickyTemplate;
    onSave: () => void;
}

type BoardStickyTemplateEditorProps = OwnProps;

const BoardStickyTemplateEditor = (props: BoardStickyTemplateEditorProps, ref: Ref<BoardStickyTemplateEditorRef>) => {
    const codeEditorRef = useRef<CodeEditor|null>(null);

    const initializeModelWithValue = (value: string) => {
        codeEditorRef.current!.initializeModel({
            fileId: `board_${props.boardStickyTemplate.boardId}_sticky_${props.boardStickyTemplate.cellType}.json`,
            language: props.boardStickyTemplate.templateType,
            value,
            schema: props.boardStickyTemplate.templateSchema || undefined
        });
    }

    useEffect(
        () => {
            initializeModelWithValue(props.boardStickyTemplate.template);

            codeEditorRef.current!.addCommand(
                /* tslint:disable-next-line:no-bitwise */
                monaco().KeyMod.CtrlCmd | monaco().KeyCode.KEY_S,
                props.onSave
            );
        },
        [
            props.boardStickyTemplate.boardId,
            props.boardStickyTemplate.cellType,
            props.boardStickyTemplate.templateType,
            props.boardStickyTemplate.template,
            props.boardStickyTemplate.templateSchema
        ]
    );

    useImperativeHandle<BoardStickyTemplateEditorRef, BoardStickyTemplateEditorRef>(ref, () => ({
        retrievePayload: (): string => codeEditorRef.current!.retrievePayload(),
        updateModelValue: (value: string): void => initializeModelWithValue(value)
    }));

    return (
        <CodeEditor
            ref={codeEditorRef}
            style={{ height: '300px', width: '100%' }}
            options={{
                fontSize: 12,
                folding: false,
                glyphMargin: false,
                lineNumbers: 'off',
                lineDecorationsWidth: 3,
                minimap: {
                    enabled: false
                },
                formatOnPaste: true,
                scrollBeyondLastLine: false,
            }}
        />
    );
};

export default React.forwardRef(BoardStickyTemplateEditor);
