import * as React from 'react';
import EditableTextline from "../../../../../core/components/EditableTextline";

interface OwnProps {
  link: string | null;
  onLinkChanged: (link: string | null) => void;
  disabled?: boolean;
}

type TaskLinkInputProps = OwnProps;

const validateUrl = (url: string): boolean => {
  if(url === "") {
    return true;
  }
  return url.match(/^https?:\/\/.+$/) !== null;
};

const TaskLinkInput = (props: TaskLinkInputProps) => {

  const handleTextChanged = (link: string) => {
    if(link === '') {
      props.onLinkChanged(null);
    } else {
      props.onLinkChanged(link);
    }
  }

  return <EditableTextline text={props.link || ''}
                           placeholder={'https://issue.tracker.com/tasks/1234'}
                           charSize={1}
                           fixedWidth={true}
                           widthUnit={'%'}
                           minWidth={100}
                           onTextChanged={handleTextChanged}
                           disabled={props.disabled}
                           validate={validateUrl}
                           textStyle={props.link === null ? {color: '#909090'} : undefined}
  />
};

export default TaskLinkInput;
