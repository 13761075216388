import * as React from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import {Dimmer, Loader} from "semantic-ui-react";

interface OwnProps {

}

type MxGraphBoardRedirectProps = OwnProps & RouteComponentProps;

// Special redirect component to force remounting of MxGraphBoard when a linked board is clicked
// See mxgraph Graph.js Graph.prototype.labelLinkClicked
const MxGraphBoardRedirect = (props: MxGraphBoardRedirectProps) => {
    const {href} = props.history.location.state;
    window.setTimeout(() => {
        props.history.replace(href);
    }, 500);
    return <Dimmer active={true} className="page"><Loader size="large">Loading Board</Loader></Dimmer>;
};

export default withRouter(MxGraphBoardRedirect);
