import {List} from "immutable";
import * as React from "react";
import {useEffect, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Icon, Input, Modal, Search} from "semantic-ui-react";
import * as uuid from "uuid";
import {
    execForEach,
    execValidate,
    execValidateEach,
    isFailure,
    isSuccess,
    pipe, predicateEither, validate
} from "../../core/validation/either";
import {isArray} from "../../core/validation/predicates";
import * as Action from "../actions";
import {fetchOpenTeamInvitations} from "../actions/api";
import {Team} from "../model";
import {createTeamInvitationFromServerData, TeamInvitation} from "../model/TeamInvitation";
import OpenInvitationList from "./OpenInvitationList";
import {makeGetCurrentUser} from "../../User/selectors/currentUser";
import {makeOrganizationSelector} from "../../Organization/selectors/selectOrganization";
import {UserId} from "../../User/model/UserInfo";
import {makeIioFilteredUserListSelector} from "../../User/selectors/iioUserInfo";

interface OwnProps {
    team: Team.Team;
    open: boolean;
    onClose: () => void;
}

type InviteUserToTeamModalProps = WithNamespaces & RouteComponentProps & OwnProps;

const InviteUserToTeamModal = (props: InviteUserToTeamModalProps) => {
    const [email, setEmail] = useState('');
    const [loadingInvitations, setLoadingInvitations] = useState(true);
    const [invitations, setInvitations] = useState(List<TeamInvitation>());
    const [failedToLoad, setFailedToLoad] = useState(false);

    const currentUser = useSelector(makeGetCurrentUser());
    const organization = useSelector(makeOrganizationSelector(currentUser.activeOrganization));
    const organizationUserIds = List<UserId>(organization ? [organization.ownerId, ...organization.members.toJS()] : []);
    const organizationUsers = useSelector(makeIioFilteredUserListSelector(organizationUserIds));

    const dispatch = useDispatch();

    useEffect(() => {
        if(props.open) {
            setLoadingInvitations(true);
            fetchOpenTeamInvitations(props.team.uid).then(({response, error}) => {
                setLoadingInvitations(false);

                if(error) {
                    // tslint:disable-next-line:no-console
                    console.error(error);
                    setFailedToLoad(true);
                }

                if(response) {
                    const result = pipe(
                        response.data,
                        execValidate([isArray(), "Response data is not of type Array"]),
                        execForEach(createTeamInvitationFromServerData)
                    );

                    if(isFailure(result)) {
                        // tslint:disable-next-line:no-console
                        console.error("Failed to process open team invitations loaded from server", result.value, response.data);
                        setFailedToLoad(true);
                    }

                    if(isSuccess(result)) {
                        setInvitations(List(result.value));
                        dispatch(Action.Event.teamInvitationsLoaded(List(result.value)));
                    }
                }
            });
        }

        if(!props.open) {
            setInvitations(List());
        }
    }, [props.open]);

    const handleSubmit = () => {
        dispatch(Action.Command.inviteUserToTeam(uuid.v4(), props.team.uid, email));
        setEmail('');
        props.onClose();
    };

    const hasInvitations = !(!loadingInvitations && invitations.count() === 0);

    const filteredUsers = organizationUsers
        .filter(user => user.username.toLocaleLowerCase().includes(email.toLocaleLowerCase()) || user.email.toLocaleLowerCase().includes(email.toLocaleLowerCase()))
        .sortBy(user => user.username)
        .map(user => {
            return {
                id: user.userId,
                title: user.username,
                description: user.email,
                image: user.avatarUrl,
            }
        });

    return (
        <Modal open={props.open} closeIcon={true} onClose={props.onClose}>
            <Modal.Header>{props.t('insp.team.invite_user')}</Modal.Header>
            <Modal.Content>
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: '10px' }}>
                        <Search
                            style={{ width: '100%' }}
                            fluid={true}
                            value={email}
                            results={filteredUsers.toArray()}
                            placeholder={props.t('insp.team.user_email')}
                            showNoResults={false}
                            resultRenderer={data => {
                                return <div key={data.id}>
                                    <div className="ui image avatar">
                                        <img src={data.image} alt={data.title} />
                                    </div>
                                    <div className="content">
                                        <div className="title">{data.title}</div>
                                        <div className="description">{data.description}</div>
                                    </div>
                                </div>
                            }}
                            onSearchChange={(e, data) => setEmail(data.value || '')}
                            onResultSelect={(e, data) => {
                                setEmail(data.result.description);
                            }}
                            autoFocus={true}
                        />
                    </div>
                    <Button primary={true} type="submit" disabled={!isValidEmail(email)}>
                        {props.t('insp.team.send_invitation')}
                    </Button>
                </form>
                <div className="ui horizontal divider">{ props.t('insp.team.open_invitations') }</div>
                {loadingInvitations && <Icon loading={true} name="spinner" />}
                {hasInvitations && <OpenInvitationList invitations={invitations} />}
                {!hasInvitations && !failedToLoad && <p className="infotextSecondary">{ props.t('insp.team.empty_invitations') }</p>}
                {failedToLoad && <p className="infotextSecondary" style={{color: 'red'}}>{ props.t('insp.team.error_loading_invitations') }</p>}
            </Modal.Content>
        </Modal>
    );
};

/**
 * Validates the basic email structure; not a perfect validation
 *
 * @param value
 */
const isValidEmail = (value: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};

export default withNamespaces()(withRouter(InviteUserToTeamModal));
