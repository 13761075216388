import {call, put, take} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {ResponseType} from "../../api/util";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as Action from "../actions";

type FlowAction = ActionType<typeof Action.Command.changeBoardAbbreviation>

export function* changeBoardAbbreviationFlow() {

    while (true) {
        const action: FlowAction = yield take([Action.Type.CHANGE_BOARD_ABBREVIATION]);

        const {response, error}: ResponseType = yield call(Action.Api.changeBoardAbbreviation, action.payload.board.uid, action.payload.board.nameAbbreviation);

        if (error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not change abbr.'));
            yield put(Action.Query.fetchUserBoards());
        }

        if(response) {
            yield put(NotifyAction.Command.info('Abbreviation changed', ''));
        }
    }
}
