import {List} from "immutable";
import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import uuid from "uuid";
import {ResponseType} from "../../api/util";
import * as Auth from '../../Auth';
import {execForEach, execValidate, isFailure, isSuccess, pipe} from "../../core/validation/either";
import {isArray} from "../../core/validation/predicates";
import {Action as NotifyAction} from "../../NotificationSystem";
import * as UserModule from "../../User";
import {Action} from "../index";
import {createUserOrganizationInfoFromServerData} from "../model/UserOrganizationInfo";
import v4 from "uuid/v4";

type Query = ActionType<typeof Action.Query.fetchUserOrganizations> | ActionType<typeof Auth.Action.Type.USER_AUTHENTICATED>;

let implicitOrgaSetup = false;

function* handleFetchUserOrganizations(query: Query) {
    const {response, error}: ResponseType = yield call(Action.Api.fetchUserOrganizations);

    if (error) {
        if(error.status === 404) {
            const installResponse = yield call(Action.Api.setUpImplicitOrganization, v4());

            if(installResponse.error) {
                yield put(NotifyAction.Command.error('Request Error', 'Could not install user organization'));
            }

            if(installResponse.response) {
                yield put(Action.Query.fetchUserOrganizations());
            }
        } else {
            yield put(NotifyAction.Command.error('Request Error', 'Could not fetch user organizations'));
        }

    }

    if (response) {
        const result = pipe(
            response.data,
            execValidate([isArray(), "Response data is not of type Array"]),
            execForEach((data: any) => {
                return createUserOrganizationInfoFromServerData(data);
            }),
        );

        if (isFailure(result)) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not load user organizations'));
        }

        if (isSuccess(result)) {
            if(result.value.length) {
                const organizations = List(result.value);

                yield put(Action.Event.userOrganizationsFetched(organizations));

                const currentUser = yield select(UserModule.UserSelector.makeGetCurrentUser());

                // Automatically switch to personal workspace if only organization is own implicit one
                // Can be the case: a) after migration; b) member removed from organization
                if(currentUser.activeOrganization === null
                    && organizations.count() === 1
                    && !organizations.first({explicitOrganization: false}).explicitOrganization) {
                    yield put(UserModule.Action.Command.switchActiveOrganization(organizations.first({organizationId: null}).organizationId));
                }

                const updatedCurrentUser = yield select(UserModule.UserSelector.makeGetCurrentUser());

                if(updatedCurrentUser.activeOrganization) {
                    yield put(Action.Query.fetchOrganization(updatedCurrentUser.activeOrganization));
                }
            } else if(!implicitOrgaSetup) {
                implicitOrgaSetup = true;
                const organizationId = uuid.v4();
                const installUserOrganization: ResponseType = yield call(Action.Api.installUserOrganization, organizationId);

                if (installUserOrganization.error) {
                    yield put(NotifyAction.Command.error('Request Error', 'Could not initialize organization.'));
                }

                if (installUserOrganization.response) {
                    yield put(Action.Query.fetchUserOrganizations());
                }
            }
        }
    }
}

export function* fetchUserOrganizations() {
    while (true) {
        const query: Query = yield take([
            Action.Type.FETCH_USER_ORGANIZATIONS
        ]);

        yield fork(handleFetchUserOrganizations, query);
    }
}
