import * as React from 'react';
import {SyntheticEvent, useMemo, useState} from "react";
import {withNamespaces, WithNamespaces} from "react-i18next";
import {useSelector} from "react-redux";
import {Message, MessageContent, MessageItem} from "semantic-ui-react";
import uuid from "uuid";
import * as UserSelector from "../../User/selectors/currentUser";
import {createNewTeam} from "../model/Team";
import {makeTeamListSelector} from "../selectors/selectTeam";
import TeamCard from "./TeamCard";
import {List} from "immutable";

interface OwnProps {
    disabled: boolean;
}

type AddTeamCardProps = OwnProps & WithNamespaces;

const AddTeamCard = (props: AddTeamCardProps) => {
    const [showCard, setShowCard] = useState(false);
    const [checkExistingTeams, setCheckExistingTeams] = useState(false);
    const team = useMemo(() => {
        return createNewTeam(uuid.v4());
    }, [showCard]);

    // Poll team list to check if newly added team is there
    const existingTeams = useSelector(makeTeamListSelector());
    if(checkExistingTeams && existingTeams.find(existingTeam => existingTeam.uid === team.uid)) {
        setCheckExistingTeams(false);
        setShowCard(false);
    }

    const handleClick = (e: SyntheticEvent) => {e.preventDefault(); setShowCard(!showCard)};
    const handleTeamAdded = () => setCheckExistingTeams(true);
    const handleCancelName = () => setShowCard(false);

    return <>
        <div>
            <button className="ui huge basic button" onClick={handleClick}>
                <i className="plus icon" />
                { props.t('insp.team.overview_add') }
            </button>
        </div>
        {showCard && <TeamCard team={team} blueprint={true} onTeamAdded={handleTeamAdded} onCancelName={handleCancelName} isMovable={false} targetOrganizations={List()} />}
    </>
};

export default withNamespaces()(AddTeamCard);
