import { Record } from 'immutable';

export const TYPE_FREE = 'free';
export const TYPE_BASIC = 'basic';
export const TYPE_PREMIUM = 'premium';
export const TYPE_CUSTOM = 'custom';

export type AccountType = 'free' | 'basic' | 'premium' | 'custom';

export interface AccountProps {
    accountType: AccountType;
}

export interface AccountMethods {
    isEqualOrBetterThan(other: Account): boolean;
    isFreeAccount(): boolean;
    isBasicAccount(): boolean;
    isPremiumAccount(): boolean;
    isCustomAccount(): boolean;
    equals(other: Account): boolean;
}

export interface AccountRecord extends AccountProps, AccountMethods {
}

export const defaultUserProps: AccountProps = {
    accountType: TYPE_FREE,
};

export const assertAccountType = (accountType: string): void => {
    switch (accountType) {
        case TYPE_FREE:
        case TYPE_BASIC:
        case TYPE_PREMIUM:
        case TYPE_CUSTOM:
            break;
        default:
            throw new Error('Invalid accountType type "' + accountType + '" provided');
    }
};

export const fromString = (accountType: AccountType) => new Account({accountType});

export const fromUserToken = (roles: string[]) => {
    let accountType = freeAccount();
    let otherAccount = freeAccount();

    roles.forEach((value: string) => {
        if (value.match(/-account/) !== null) {
            otherAccount = new Account({accountType: value.replace(/-account/, '') as AccountType});

            if (otherAccount.isEqualOrBetterThan(accountType)) {
                accountType = otherAccount;
            }
        }
    });
    return accountType;
};

export const freeAccount = (): Account => new Account({accountType: TYPE_FREE});

export class Account extends Record(defaultUserProps) implements AccountRecord {
    public constructor(data: AccountProps) {
        assertAccountType(data.accountType);
        super(data);
    }

    public isFreeAccount(): boolean {
        return this.accountType === TYPE_FREE;
    }

    public isBasicAccount(): boolean {
        return this.accountType === TYPE_BASIC;
    }

    public isPremiumAccount(): boolean {
        return this.accountType === TYPE_PREMIUM;
    }

    public isCustomAccount(): boolean {
        return this.accountType === TYPE_CUSTOM;
    }

    public isEqualOrBetterThan(other: Account): boolean {
        if (this.accountType === other.accountType) {
            return true;
        }

        if (this.accountType === TYPE_FREE) {
            return false;
        }

        if (other.accountType === TYPE_FREE) {
            return true;
        }

        if (this.accountType === TYPE_CUSTOM) {
            return true;
        }

        if (other.accountType === TYPE_CUSTOM) {
            return false;
        }

        if (this.accountType === TYPE_PREMIUM) {
            return true;
        }

        return false;
    }

    public equals(other: Account): boolean {
        return this.accountType === other.accountType;
    }
}
