import {call, fork, put, select, take} from 'redux-saga/effects';
import {ActionType} from "typesafe-actions";
import {Action as NotifyAction} from "../../NotificationSystem";
import {Action} from "../index";
import {makeTeamInvitationSelector} from "../selectors/TeamInvitation";

type Command = ActionType<typeof Action.Command.resendTeamInvitation>

function* handleResendTeamInvitation(command: Command) {
    const teamInvitation = yield select(makeTeamInvitationSelector(command.payload.invitationId));

    if(teamInvitation) {
        const {response, error} = yield call(Action.Api.resendTeamInvitation, command.payload.invitationId);

        if(error) {
            yield put(NotifyAction.Command.error('Request Error', 'Could not resend team invitation.'));
        }

        if(response) {
            yield put(NotifyAction.Command.info(
                'insp.team.notify.invitation_resend.success.title',
                'insp.team.notify.invitation_resend.success.msg',
                {email: teamInvitation.invitedUser}
            ));
        }

        yield put(Action.Event.teamInvitationResent(teamInvitation.resend()));
    }
}

export function* resendTeamInvitation() {
    while (true) {
        const command: Command = yield take([
            Action.Type.RESEND_TEAM_INVITATION
        ]);

        yield fork(handleResendTeamInvitation, command);
    }
}