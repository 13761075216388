import * as React from 'react';
import {withNamespaces, WithNamespaces} from "react-i18next";
import {Checkbox} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {Board} from "../../model/Board";
import {useDispatch} from "react-redux";
import {toggleCodySuggestEnabled} from "../../actions/commands";

interface OwnProps {
  board: Board;
}

type CodySuggestEnabledProps = OwnProps & WithNamespaces;

const CodySuggestEnabled = (props: CodySuggestEnabledProps) => {
  const [codySuggestEnabled, setCodySuggestEnabled] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setCodySuggestEnabled(props.board.codySuggestEnabled);
  }, [props.board.uid]);

  const handleToggle = (enabled: boolean) => {
    setCodySuggestEnabled(enabled);
    dispatch(toggleCodySuggestEnabled(props.board.merge({codySuggestEnabled: enabled})))
  }


  return <Checkbox toggle={true} checked={codySuggestEnabled} value={codySuggestEnabled? '1' : ''} onChange={(e, data) => handleToggle(data.value !== '1')} />
};

export default withNamespaces()(CodySuggestEnabled);
